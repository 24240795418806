import { synchronize } from "@pw/services/sync.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const syncChangesThunk = createAsyncThunk('sync/syncChanges',
  async (changes, { rejectWithValue }) => {
    try {
      return await synchronize(changes);
    } catch (error) {
      return rejectWithValue(error.message);
    }
	}
);