import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import UsedInRedistillationReport from './UsedInRedistillationReport';

export default function UsedInRedistillation({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<UsedInRedistillationReport name='1_' title='1.Select Kind' />
					<UsedInRedistillationReport name='2_' title='2.Select Kind' />
					<UsedInRedistillationReport name='3_' title='3.Select Kind' />
					<UsedInRedistillationReport name='4_' title='4.Select Kind' />
					<UsedInRedistillationReport name='5_' title='5.Select Kind' />
					<UsedInRedistillationReport name='6_' title='6.Select Kind' />
					<UsedInRedistillationReport name='7_' title='7.Select Kind' />
					<UsedInRedistillationReport name='8_' title='8.Select Kind' />
				</>
			)}
		</FormikProvider>
	);
}
