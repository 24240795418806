function SvgCrate(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 29.03 29.03'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				className='crate_svg__cls-1'
				d='M28.38 0H.65C.13 0 0 .14 0 .67v27.69c0 .53.14.67.65.67h27.72c.52 0 .65-.14.65-.67V.66c.01-.52-.13-.66-.64-.66zm-1.03.97c-.08.09-.13.15-.19.21-.83.83-1.67 1.66-2.5 2.5-.13.13-.25.19-.44.19H4.82c-.17 0-.29-.05-.42-.17-.89-.9-1.79-1.79-2.72-2.73h25.66zM9.46 11.34C7.98 9.86 6.5 8.37 5.01 6.89a.545.545 0 01-.18-.42c.01-.48 0-.97 0-1.45 0-.14.04-.19.18-.19.32.01.64 0 1.11 0 .65-.21 1.15.2 1.67.74 5.37 5.4 10.76 10.79 16.15 16.17.17.17.24.34.23.58-.02.54-.02 1.09 0 1.63 0 .21-.06.25-.25.24-.45-.01-.91-.01-1.36 0a.6.6 0 01-.47-.2c-4.21-4.22-8.43-8.44-12.65-12.66zM15.97 24c0 .16-.04.2-.2.2h-2.51c-.12 0-.2 0-.2-.17v-7.61s0-.01.04-.05c.92.93 1.83 1.84 2.75 2.75.09.09.12.17.12.29V24zm-3.87-8.43v8.4c0 .15 0 .23-.2.23-.84-.01-1.67 0-2.51 0-.16 0-.2-.04-.2-.2V12.58c0-.02.01-.03.03-.09.92.93 1.83 1.83 2.73 2.73.1.1.14.21.14.35zm-3.87 8.4c0 .19-.06.23-.24.23H5.06c-.15 0-.23-.01-.23-.2V8.23c0-.02.01-.04.03-.09l3.23 3.23c.09.09.13.19.13.32v12.3zM12.1 8.48l-3.6-3.6c.06-.07.15-.03.22-.03h3.14c.12 0 .25-.04.25.18-.01 1.14 0 2.27 0 3.46zm3.87 3.89c-.96-.96-1.88-1.88-2.8-2.79a.3.3 0 01-.1-.26V5.06c0-.18.05-.21.22-.21h2.48c.13 0 .22 0 .22.19v7.34zm.96-7.29c0-.21.06-.24.25-.24.81.01 1.61 0 2.42 0 .17 0 .24.01.24.22v11.06c0 .02 0 .04-.02.1-.94-.94-1.86-1.86-2.78-2.77-.09-.09-.1-.18-.1-.3V5.08zM20.8 17V5.09c0-.18.03-.26.24-.25.98.01 1.95 0 2.93 0 .16 0 .22.02.22.21v15.41c0 .03 0 .05-.04.19-.08-.13-.11-.2-.17-.25-1-1.01-2-2.01-3.01-3.01a.507.507 0 01-.17-.39zM.99 1.66c.96.96 1.88 1.89 2.8 2.81.08.08.08.17.08.27v19.53c0 .1 0 .19-.08.27-.92.92-1.85 1.85-2.8 2.81V1.66zm.7 26.38c.95-.95 1.86-1.86 2.78-2.79.09-.09.18-.1.3-.1h19.52c.1 0 .19 0 .27.08.92.92 1.85 1.85 2.81 2.8H1.68zm26.36-.7c-.94-.94-1.86-1.86-2.78-2.77-.09-.09-.1-.18-.1-.3V4.75c0-.1 0-.19.08-.27.92-.92 1.85-1.85 2.8-2.81v25.67z'
			/>
			<path
				className='crate_svg__cls-2'
				d='M16.93 20.23c1.33 1.32 2.62 2.62 3.92 3.91-.03.09-.12.04-.18.04h-3.5c-.12 0-.25.04-.25-.18.01-1.25 0-2.5 0-3.78z'
			/>
			<path
				className='crate_svg__cls-1'
				d='M4.35 2.4q0-.46.48-.46t.49.51-.48.46-.49-.51zM23.68 2.9q-.46 0-.46-.45t.51-.52.46.45-.51.52zM2.42 4.35q.48 0 .48.49t-.49.48-.48-.49.49-.48zM2.39 24.68q-.46 0-.46-.48t.48-.49.49.48-.51.49zM26.62 4.35q.47 0 .47.47t-.5.5-.47-.47.5-.5zM26.61 23.71q.48 0 .48.49t-.49.48-.48-.49.49-.48zM4.83 27.09q-.47 0-.47-.47t.5-.5.47.47-.5.5zM24.67 26.63q0 .47-.47.47t-.5-.5.47-.47.5.5z'
			/>
		</svg>
	);
}
export default SvgCrate;
