import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import OutlinedButton from '@pw/components/Buttons/OutlinedButton';

const ToggleMenuButton = ({ showGlobalMenu, setShowGlobalMenu }) => (
	<OutlinedButton
		className='toggle-global-menu'
		color='primary'
		size='small'
		handleClick={() => setShowGlobalMenu((prev) => !prev)}
	>
		{showGlobalMenu ? (
			<>
				<MenuOpenIcon />
				Hide menu
			</>
		) : (
			<>
				<MenuIcon />
				Menu
			</>
		)}
	</OutlinedButton>
);
export default ToggleMenuButton;
