import * as yup from 'yup';

export default function section2ReportFields(fields = {}) {
	const { a, b, c } = fields;

	return {
		a: [a, yup.number().min(0, 'Transaction value cannot be less than 0')],
		b: [
			b,
			yup
				.number()
				.min(0, 'Recovered Denatured Spirits value cannot be less than 0'),
		],
		c: [
			c,
			yup.number().min(0, 'Recovered Articles value cannot be less than 0'),
		],
	};
}
