import { pageHeaderHeight } from '../../Layout/consts';

const styles = {
	'&.toolbox-root': {
		position: 'fixed',
		boxSizing: 'border-box',
		zIndex: 5,
		top: pageHeaderHeight,
		bottom: 'auto',
		height: 'auto',
		maxHeight: `calc(100vh - (${pageHeaderHeight.sm} + 1rem + 1rem ))`,
		marginTop: '1rem',
		width: '18rem',
		paddingTop: '1.5rem',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'white',
		borderRadius: '0.5rem',
		overflow: 'hidden',
		boxShadow: '0px 3px 3px 0px #00000012',
		transition: 'all 0.3s',

		'&.left': {
			left: '1rem',
			'&.with-global-menu': {
				transform: {
					xs: 'translateX(150px)',
					md: 'translateX(250px)',
				},
			},
		},
		'&.right': { right: '1rem' },
		overflowY: 'auto',
	},

	'.toggle-global-menu': {
		margin: '0 0 1rem',
		color: '#888',
		borderColor: 'currentColor',
	},

	'.toolbox_header': {
		padding: '0 1.5rem 1rem'
	},
	'.toolbox_heading': { fontWeight: 600 },
	'.toolbox_content': {
		position: 'relative',
		scrollbarWidth: 'thin',
		overflowY: 'auto',
	},

	'.name-field': {
		position: 'relative',
		marginTop: '1rem',

		div: {
			width: '100%',
		},

		label: {
			position: 'absolute',
			top: '0.5rem',
			left: '0.75rem',
			fontSize: '14px',
		},

		input: {
			position: 'relative',
			width: '100%',
			paddingRight: '5rem',
			paddingTop: '25px',
			paddingBottom: '4px',
			lineHeight: 1,
		},

		button: {
			position: 'absolute',
			right: '0.5rem',
			top: '0.5rem',
			lineHeight: '28px',
			backgroundColor: '#C7DA65',
		},
	},

	'.toolbar-section': {
		position: 'relative',
		left: 0,
		right: 0,
		px: '1.5rem',
		paddingBottom: '1.5rem',
		height: 'auto',
		maxHeight: '33%',
		overflowY: 'auto',
		display: 'flex',
		flexBasis: `${100 / 3}%`,
		height: '33%',
		flexGrow: 1,
		flexDirection: 'column',

		'.expand': {
			position: 'absolute',
			right: '1rem',
			top: 0,
			margin: '-0.5rem',

			svg: {
				fontSize: '2rem',
				height: '1em',
				lineHeight: 0.5,
				color: '#00A89D',
				transition: 'transform 0.3s',
				transform: 'rotate(180deg)',
			},

			'&.open-false svg': {
				transform: 'rotate(0deg)',
			},
		},
	},

	'* + .toolbar-section': {
		paddingTop: '1.5rem',

		'.expand': { top: '1rem' },
	},

	'.section-background': {
		backgroundColor: '#EEEDEC',
	},
	'.section_heading': {
		fontSize: '1rem',
		lineHeight: 1.25,
		textTransform: 'uppercase',
	},
	'.section_body': {
		fontSize: `${14 / 16}rem`,
		marginTop: 0,
		marginBottom: '1rem',
		paddingRight: '1.5rem',
	},

	'.children-container': {
		paddingTop: '1rem',
		minWidth: '100%',
		mx: '-1.5rem',
		overflow: 'hidden',
		transition: 'opacity 0.3s, max-height 0.3s 0.3s',
		transitionBehavior: 'allow-discrete',
		maxHeight: '90vh',

		'&.open-false': {
			maxHeight: 0,
			opacity: 0,
			transition: 'opacity 0.3s, max-height 0.3s',
		},
	},
	'.tiles-list': {
		display: 'flex',
		minWidth: '100%',
		height: 'auto',
		maxHeight: '100%',
		marginRight: '-1.5rem',
		px: '1.5rem',
		minHeight: 'fit-content',
		flexFlow: 'row wrap',
		flexGrow: 1,
		flexShrink: 1,
		columnGap: '1.5rem',
		rowGap: '0.75rem',
		transition: 'max-height 0.6s allow-discrete',
	},

	'.batch-item': {
		width: 'calc(100% - 1.5rem)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '0.5rem',
		borderRadius: '0.25rem',
		transition: 'background-color 0.3s',
		backgroundColor: '#fff',

		'&:hover': { backgroundColor: '#eee' },
		'&:not(:first-of-type)': { marginTop: '0.5rem' },
		'&.active': { backgroundColor: '#eee' },
	},
};

export default styles;
