import MuiAvatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';

function Avatar({ src = null, alt = '', sx = {}, loading = false, ...rest }) {
	const [showLoadingAnim, setShowLoadingAnim] = useState(true);

	useEffect(() => {
		setTimeout(() => setShowLoadingAnim(false), 1200);
	}, []);

	const getInitials = (fullName) => {
		const names = fullName.split(' ');

		if (names.length === 2) {
			return names.map((name) => name.substring(0, 1).toUpperCase());
		}
		return names[0].substring(0, 1).toUpperCase();
	};

	return showLoadingAnim || loading ? (
		<MuiAvatar sx={sx}>
			<CircularProgress size='30%' thickness={3.5} sx={{ opacity: '.65' }} {...rest} />
		</MuiAvatar>
	) : (
		<MuiAvatar sx={{ ...sx, position: 'relative', background: 'rgba(0, 0, 0, 0.2)' }} src={src} {...rest}>
			{alt && getInitials(alt)}
		</MuiAvatar>
	);
}

export default Avatar;
