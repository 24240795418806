import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import ObservationForm from '../ScheduledObservation';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';
import { WEIGHT_UNIT_OPTIONS } from '@pw/consts/measures';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import * as yup from 'yup';
import { useContext } from 'react';
import DesignerContext from '@pw/context/DesignerContext';
import NodeConfigForm from './NodeConfigForm';

const WeightForm = () => {
  const { mode } = useContext(DesignerContext);
  const { nodeData = {} } = useNodeDataContext();
  const { weight = { value: '', unit: '' } } = nodeData;
  const changeSet = {
    weight: unitField(weight),
    observation_name: ['WeightForm', yup.string()],
  };
  const fieldNames = Object.keys(changeSet);

  if (mode === 'design') {
    return <NodeConfigForm changeSet={changeSet} />;
  }

  return (
    <NodeModalForm changeSet={changeSet}>
      <ObservationForm
        nodeData={nodeData}
        fieldNames={fieldNames}
      >
        <FlexBox>
          <UnitField
            label='Weight'
            name='weight'
            options={WEIGHT_UNIT_OPTIONS}
          />
        </FlexBox>
      </ObservationForm>

    </NodeModalForm>
  )
}

export default WeightForm;
