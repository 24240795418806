import { useState } from 'react';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

export default function BasicTimePicker(props) {
  const { sx, label = 'Set a time', ...rest } = props;
  const [value, setValue] = useState();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        renderInput={(params) => {
          return (
            <TextField {...params} sx={sx} />
          )
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}
