import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import LiquidIcon from '@pw/components/icons/Liquid';
import i18n from '@pw/consts/i18n';
import { UNIT } from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';

function StrengthDisplay({ label = i18n.t('strength'), value = null, tcf = null }) {
	const converter = useConverter();

	const abv = converter.from(value, UNIT.ALC);

	if (!abv) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LiquidIcon style={{ width: '12px', height: '14px' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{abv?.toFixed(2)}
				<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>
					{converter.unit(UNIT.ALC)}
				</Bolder>
				{tcf && (
					<>
						{' | '}
						<Bolder>TCF:</Bolder>
						&nbsp;
						{Number(tcf).toFixed(3)}
					</>
				)}
			</Body3>
		</Box>
	);
}

export default StrengthDisplay;
