import {
	AccountCircle,
	Archive,
	Cancel,
	Done,
	Payment,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DeliveryIcon from '@pw/components/icons/Delivery';
import Pick from '@pw/components/icons/Pick';
import Regauge from '@pw/components/icons/Regauge';
import Sample from '@pw/components/icons/Sample';
import Ship from '@pw/components/icons/Ship';
import Transfer from '@pw/components/icons/Transfer';
import { ASSET_TYPES, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { REQUEST_TYPES, REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import { TASK_TABLE_STATUS } from '@pw/consts/tasks';
import {
	useOnlineHook,
	usePageTitleHook,
} from '@pw/redux/containers/App/hooks';
import {
	useAccountPermissions,
	useCompanyUsers,
} from '@pw/redux/containers/User/hooks';
import { toTasksCardEntity } from '@pw/utilities/format';
import { useRequestsListLazyQuery } from '@pw/utilities/hooks/service/useRequestsListQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TaskGroup from './TaskGroup';
import styles from './styles';

const toDoFilter = (task) => task.status === TASK_TABLE_STATUS.PENDING;
const inProgressFilter = (task) =>
	task.status === TASK_TABLE_STATUS.IN_PROGRESS;
const readyForApprovalFilter = (task) =>
	task.status === TASK_TABLE_STATUS.AWAITING_APPROVAL;
const doneFilter = (task) => task.status === TASK_TABLE_STATUS.DONE;

export const processAssetList = (assets) => {
	const list = [];
	if (assets && assets.items) {
		const assetCards = assets.items.map((asset) => {
			const type = ASSET_TYPES_REVERSE[asset.asset_type] ?? ASSET_TYPES.cask;
			return toTasksCardEntity(asset, type, true);
		});
		list.push(...assetCards);
	}

	return list;
};

export const processRequestList = (requests) => {
	const list = [];
	if (requests) {
		const requestCards = requests.map((request) => {
			const type =
				REQUEST_TYPES_REVERSE[request.request_type ?? REQUEST_TYPES.sample];
			return toTasksCardEntity(request, type);
		});
		list.push(...requestCards);
	}
	return list;
};

function Tasks() {
	const navigate = useNavigate();
	const { canApprove = false, assignTask = false } = useAccountPermissions();
	const { online } = useOnlineHook();

	usePageTitleHook('Tasks');

	const [fetchRequest, { data: requests }] = useRequestsListLazyQuery([]);

	const users = useCompanyUsers();

	const tasks = useMemo(() => [...processRequestList(requests)], [requests]);

	const refresh = useCallback(() => {
		// fetchAsset();
		fetchRequest().finally();
	}, [fetchRequest]);

	const toDo = useMemo(() => tasks.filter(toDoFilter), [tasks]);
	const inProgress = useMemo(() => tasks.filter(inProgressFilter), [tasks]);
	const readyForApproval = useMemo(
		() => tasks.filter(readyForApprovalFilter),
		[tasks],
	);
	const done = useMemo(() => tasks.filter(doneFilter), [tasks]);

	// Fetch the tasks
	useEffect(() => {
		refresh();
	}, [refresh]);

	const adminTaskItems = assignTask
		? [
				{ key: 'div_1', d: true },
				{
					key: 'assign_selected',
					e: !online,
					label: (
						<FlexBox spacing='0.5' justifyContent='start'>
							<AccountCircle />
							<span>Assign Selected</span>
						</FlexBox>
					),
				},
				canApprove && { key: 'div_2', d: true },
				canApprove && {
					key: 'cancel_selected',
					e: !online,
					label: (
						<FlexBox spacing='0.5' justifyContent='start'>
							<Cancel />
							<span>Cancel Selected</span>
						</FlexBox>
					),
				},
			]
		: [];

	const taskMenuItems = [
		{
			key: 'new_sample',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Sample />
					<span>Sample</span>
				</FlexBox>
			),
			click: () => navigate('/app/sample'),
		},
		{
			key: 'new_regauge',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Regauge />
					<span>Regauge</span>
				</FlexBox>
			),
			click: () => navigate('/app/regauge'),
		},
		{
			key: 'new_transfer',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Transfer />
					<span>Transfer</span>
				</FlexBox>
			),
			click: () => navigate('/app/transfer'),
		},
		{ key: 'div_1', d: true },
		{
			key: 'new_pick',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Pick />
					<span>Pick</span>
				</FlexBox>
			),
			click: () => navigate('/app/pick'),
		},
		{
			key: 'new_delivery',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<DeliveryIcon height={16} width={16} />
					<span>Delivery</span>
				</FlexBox>
			),
			click: () => navigate('/app/delivery'),
		},
		{
			key: 'new_shipment',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Ship />
					<span>Ship</span>
				</FlexBox>
			),
			click: () => navigate('/app/ship'),
		},
		{
			key: 'new_duty_transfer',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Payment />
					<span>Duty Transfer</span>
				</FlexBox>
			),
			click: () => navigate('/app/duty-transfer'),
		},
		{
			key: 'new_duty_submission',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Payment />
					<span>Duty Submission</span>
				</FlexBox>
			),
			click: () => navigate('/app/duty-submission'),
		},
		{
			key: 'new_invoice',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Payment />
					<span>Invoice</span>
				</FlexBox>
			),
			click: () => navigate('/app/invoice'),
		},
		{
			key: 'new_purchase_order',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Payment />
					<span>Purchase order</span>
				</FlexBox>
			),
			click: () => navigate('/app/purchase-order'),
		},
		{
			key: 'new_expense_report',
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Payment />
					<span>Expense report</span>
				</FlexBox>
			),
			click: () => navigate('/app/expense-report'),
		},
		...adminTaskItems,
	];

	const approvalMenuItems = [
		{
			key: 'approve_selected',
			e: !online,
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Done />
					<span>Approve Selected</span>
				</FlexBox>
			),
		},
		{ key: 'div_1', d: true },
		{
			key: 'reject_selected',
			e: !online,
			label: (
				<FlexBox spacing='0.5' justifyContent='start'>
					<Cancel />
					<span>Reject Selected</span>
				</FlexBox>
			),
		},
	];

	const archiveMenuItems = canApprove
		? [
				{
					key: 'archive_selected',
					e: !online,
					label: (
						<FlexBox spacing='0.5' justifyContent='start'>
							<Archive />
							<span>Archive Selected</span>
						</FlexBox>
					),
				},
			]
		: [];

	return (
		<>
			<Box sx={styles} className='root'>
				<Box className='tasks'>
					<TaskGroup
						key='To Do'
						title='To Do'
						tasks={toDo}
						users={users}
						refresh={refresh}
						menuItems={taskMenuItems}
					/>
					<TaskGroup
						key='In Progress'
						title='In Progress'
						tasks={inProgress}
						users={users}
						refresh={refresh}
					/>
					<TaskGroup
						key='Awaiting Approval'
						title='Awaiting Approval'
						tasks={readyForApproval}
						users={users}
						refresh={refresh}
						menuItems={canApprove ? approvalMenuItems : null}
					/>
					<TaskGroup
						key='Done'
						title='Done'
						tasks={done}
						users={users}
						refresh={refresh}
						menuItems={canApprove ? archiveMenuItems : null}
					/>
				</Box>
			</Box>
		</>
	);
}

export default Tasks;
