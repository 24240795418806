import section1ReportFields from './section1ReportFields';

export default function section1Fields(fields = {}) {
	const {
		On_hand_first_month = '',
		Produced = '',
		Recd_by_transfer_in_bond = '',
		Specify_3 = '',
		Trans_to_dlrs_users_exec_Line_7_10 = '',
		Federal_Government_SDS_except_line_10 = '',
		Transferred_in_bond = '',
		For_use_in_mfg_of_articles_on_my_bonded_premises = '',
		Withdrawn_for_fuel_use_AFP_Premises = '',
		Losses = '',
		On_hand_end_of_month = '',
		Specify_13 = '',
	} = fields;

	return {
		On_hand_first_month: section1ReportFields(On_hand_first_month),
		Produced: section1ReportFields(Produced),
		Recd_by_transfer_in_bond: section1ReportFields(Recd_by_transfer_in_bond),
		Specify_3: section1ReportFields(Specify_3),
		Trans_to_dlrs_users_exec_Line_7_10: section1ReportFields(
			Trans_to_dlrs_users_exec_Line_7_10,
		),
		Federal_Government_SDS_except_line_10: section1ReportFields(
			Federal_Government_SDS_except_line_10,
		),
		Transferred_in_bond: section1ReportFields(Transferred_in_bond),
		For_use_in_mfg_of_articles_on_my_bonded_premises: section1ReportFields(
			For_use_in_mfg_of_articles_on_my_bonded_premises,
		),
		Withdrawn_for_fuel_use_AFP_Premises: section1ReportFields(
			Withdrawn_for_fuel_use_AFP_Premises,
		),
		Losses: section1ReportFields(Losses),
		On_hand_end_of_month: section1ReportFields(On_hand_end_of_month),
		Specify_13: section1ReportFields(Specify_13),
	};
}
