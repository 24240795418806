import { Stack } from '@mui/material';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import Part1Report from './Part1Report';
import { H4 } from '@pw/components/Typography';
/* eslint-disable react/prop-types */
export default function Part1({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Part1Report
						name='On_Hand_First_of_Month'
						title='On Hand First of Month'
					/>
					<Part1Report
						name='Received_Other_than_line_3'
						title='Received Other than line 3'
					/>
					<Part1Report
						name='Alcohol_for_Fuel_Use_Received_From_Customs_Custody'
						title='Alcohol for Fuel Use Received From Customs Custody'
						wine={true}
					/>
					<Part1Report
						name='Alcohol_Flavor_Materials_Dumped'
						title='Alcohol Flavor Materials Dumped'
						wine={true}
					/>
					<Part1Report
						name='Wine_Mixed_with_Spirits'
						title='Wine Mixed with Spirits'
						wine={true}
					/>
					<Part1Report
						name='Dumped_for_Further_Processing'
						title='Dumped for Further Processing'
						wine={true}
					/>
					<Part1Report name='Gains' title='Gains' />
					<Part1Report
						name='Bottled_or_Package'
						title='Bottled or Package'
						wine={true}
					/>
					<Part1Report
						name='Wine_Mixed_With_Spirits'
						title='Wine Mixed With Spirits'
					/>
					<Part1Report
						name='Used_for_Denaturation'
						title='Used for Denaturation'
						wine={true}
					/>
					<Part1Report name='Transferred_in_Bond' title='Transferred in Bond' />
					<Part1Report
						name='Withdrawn_Tax_Determined'
						title='Withdrawn Tax Determined'
						wine={true}
					/>
					<Part1Report
						name='Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use'
						title='Withdrawn Free of Tax For US Hospital Scientific or Educational Use'
						wine={true}
					/>
					<Part1Report
						name='Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine'
						title='Withdrawn Without Payment of Tax For Addition to Wine'
						wine={true}
					/>
					<Part1Report
						name='For_Exportation_Transfer_to_CBW'
						title='For Exportation Transfer to CBW'
						wine={true}
					/>
					<Part1Report
						name='Transferred_to_Production_Account_for_Redistillation'
						title='Transferred to Production Account for Redistillation'
						wine={true}
					/>
					<Part1Report
						name='Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples'
						title='Withdrawn for Research Development or Testing Including Gov samples'
					/>
					<Part1Report name='Destroyed' title='Destroyed' />
					<Part1Report
						name='Used_for_Redistillation'
						title='Used for Redistillation'
					/>
					<Part1Report name='Specify_21' title='Specify 21' specify={true} />
					<Part1Report name='Specify_22' title='Specify 22' specify={true} />
					<Part1Report name='Specify_23' title='Specify 23' specify={true} />
					<Part1Report name='Losses' title='Losses' />
					<Part1Report
						name='On_Hand_End_of_Month'
						title='On Hand End of Month'
					/>
				</>
			)}
		</FormikProvider>
	);
}
