import {
	AccessTime,
	Archive,
	Cancel,
	CheckCircle,
	FactCheck,
	PendingActionsTwoTone,
} from '@mui/icons-material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Box, Skeleton } from '@mui/material';
import IdIcon from '@pw/components/icons/Id';
import { Body3 } from '@pw/components/Typography';
import { REQUEST_STATUS } from '@pw/consts/requests';

function RequestStatusDisplay({ status }) {
	// console.log('RequestStatusDisplay', status);
	if (!status) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			{status === REQUEST_STATUS.PENDING && (
				<>
					<PendingActionsTwoTone
						color='disabled'
						style={{ width: '12px', height: 'auto' }}
					/>
					<Body3>Pending</Body3>
				</>
			)}
			{status === REQUEST_STATUS.IN_PROGRESS && (
				<>
					<AccessTime
						color='secondary'
						style={{ width: '12px', height: 'auto' }}
					/>
					<Body3>In Progress</Body3>
				</>
			)}
			{status === REQUEST_STATUS.AWAITING_APPROVAL && (
				<>
					<FactCheck color='info' style={{ width: '12px', height: 'auto' }} />
					<Body3>Awaiting Approval</Body3>
				</>
			)}
			{status === REQUEST_STATUS.DONE && (
				<>
					<CheckCircle
						color='success'
						style={{ width: '12px', height: 'auto' }}
					/>
					<Body3>Done</Body3>
				</>
			)}
			{status === REQUEST_STATUS.CANCELLED && (
				<>
					<Cancel color='error' style={{ width: '12px', height: 'auto' }} />
					<Body3>Cancelled</Body3>
				</>
			)}
			{status === REQUEST_STATUS.REJECTED && (
				<>
					<UnpublishedIcon
						color='error'
						style={{ width: '12px', height: 'auto' }}
					/>
					<Body3>Rejected</Body3>
				</>
			)}
			{status === REQUEST_STATUS.ARCHIVED && (
				<>
					<Archive
						color='secondary'
						style={{ width: '12px', height: 'auto' }}
					/>
					<Body3>Archived</Body3>
				</>
			)}
		</Box>
	);
}

export function RequestStatusDisplaySkeleton() {
	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<IdIcon style={{ width: '12px', height: 'auto' }} />
			<Skeleton variant={'text'} width={100} />
		</Box>
	);
}

export default RequestStatusDisplay;
