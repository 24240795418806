import { AddCircle, CopyAllOutlined, Print } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import CreatorProps from '@pw/components/CreatorProps';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { useCompanyName, useIsAccountActive } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import printElement from '@pw/utilities/printElement';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { POLICY_EDIT } from '@pw/consts/policy';
import PolicyQRCode from '../QRImage/PolicyQRCode';

function PolicySummary({ entity, showControls = true }) {
	const navigate = useNavigate();

	const readonly = useCheckCompany(entity);
	const userIsActive = useIsAccountActive();
    const companyName = useCompanyName()

	const printRef = useRef();

	const showActions = useMemo(
		() => userIsActive && !readonly,
		[readonly, userIsActive],
	);

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
	};

	const handleNew = () => {
		navigate(`${POLICY_EDIT}`);
	};

	const handleClone = () => {
		const str = JSON.stringify(entity);
		const base = Buffer.from(str).toString('base64');
		navigate(`${POLICY_EDIT}?clone=${base}`);
	};

	return (
		<Stack sx={styles} className='root' spacing={3}>
			{showControls && <H5>Policy</H5>}

			<Box className='summary-qr'>
				<Box ref={printRef} className='qr'>
					<PolicyQRCode companyName={companyName} policy={entity} fullScreen={false} withDetails={false} />
				</Box>

				{showActions && (
					<IconCircleButton
						onClick={() => handlePrint(printRef)}
						variant='outlined'
						color='secondary'
					>
						<Print height={24} width={24} />
					</IconCircleButton>
				)}
			</Box>

			<FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
				{showControls && (
					<>
						<TitledButton
							handleClick={handleNew}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>

						{showActions && (
							<TitledButton
								handleClick={handleClone}
								label='Clone'
							>
								<CopyAllOutlined height={24} width={24} />
							</TitledButton>
						)}
					</>
				)}
			</FlexBox>

			{showActions && <CreatorProps entity={entity} />}
		</Stack>
	);
}

export default withSmallDialogCard(PolicySummary);
