import { createRole, listRoles, updateRole } from "@pw/services/company.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const refetchRolesThunk = createAsyncThunk(
  'roles/refetch',
  async (_, { rejectWithValue }) => {
    try {
      return await listRoles();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);  

export const upsertRoleThunk = createAsyncThunk('roles/upsert', async (values, { rejectWithValue }) => {
  try {
    return values.path ? await updateRole(values) : await createRole(values);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});