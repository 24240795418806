import * as yup from 'yup';

export default function transactionsRumReportFields(fields = {}) {
	const { a, b, c, d } = fields;

	return {
		a: [a, yup.number().min(0, 'Transaction value cannot be less than 0')],
		b: [b, yup.number().min(0, 'Rum value cannot be less than 0')],
		c: [c, yup.number().min(0, 'Gin value cannot be less than 0')],
		d: [d, yup.number().min(0, 'Vodka value cannot be less than 0')],
	};
}
