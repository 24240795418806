import * as yup from 'yup';

export default function transactionsWhiskeyReportFields(fields = {}) {
	const { a, b, c, d, e } = fields;

	return {
		a: [a, yup.number().min(0, 'Transaction value cannot be less than 0')],
		b: [
			b,
			yup.number().min(0, 'Whiskey 160 and Under value cannot be less than 0'),
		],
		c: [c, yup.number().min(0, 'Whiskey Over 160 value cannot be less than 0')],
		d: [
			d,
			yup.number().min(0, 'Brandy 170 and Under value cannot be less than 0'),
		],
		e: [e, yup.number().min(0, 'Brandy Over 170 value cannot be less than 0')],
	};
}
