function SvgQrScan(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 33 48.948'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<clipPath id='QRScan_svg__a'>
					<path data-name='Rectangle 1088' fill='#fff' d='M0 0h26v28.156H0z' />
				</clipPath>
			</defs>
			<g data-name='Group 674'>
				<g data-name='Group 649'>
					<g
						data-name='Group 566'
						transform='translate(3)'
						clipPath='url(#QRScan_svg__a)'
						fill='#fff'
					>
						<path
							data-name='Path 9468'
							d='M25.493.071q.426.016.441.478a.484.484 0 01.026.235v6.689a.482.482 0 01-.026.235c-.058.349-.26.547-.552.542-.261-.005-.449-.22-.477-.547a1.189 1.189 0 01-.02-.374V1.611a.814.814 0 00-.029-.37.645.645 0 00-.342-.031H19.17a.924.924 0 01-.345-.022.5.5 0 01-.436-.573c.006-.289.127-.51.431-.54a.387.387 0 01.217-.028h6.24a.388.388 0 01.217.028'
						/>
						<path
							data-name='Path 9469'
							d='M18.825 28.155a.6.6 0 01-.44-.556c-.046-.357.175-.506.439-.61a.93.93 0 01.345-.022h5.344a.646.646 0 00.342-.031.814.814 0 00.029-.37v-5.787a1.189 1.189 0 01.02-.373c.035-.379.285-.41.558-.41.294 0 .452.154.47.474a.484.484 0 01.026.235v6.689a.486.486 0 01-.026.235.524.524 0 01-.384.53.471.471 0 00-.218-.023h-6.29a.475.475 0 00-.218.023'
						/>
						<path
							data-name='Path 9470'
							d='M0 .486A.451.451 0 01.426.071.388.388 0 01.643.043h6.24A.387.387 0 017.1.071c.292.021.437.188.437.506a.544.544 0 01-.442.607.91.91 0 01-.344.022H1.168c-.132.088-.066.228-.066.341v5.777a1.183 1.183 0 01-.02.373c-.1.068-.047.209-.123.295-.141.157-.3.308-.5.243A.569.569 0 010 7.769a.608.608 0 00.022-.236V.722A.6.6 0 000 .486'
						/>
						<path
							data-name='Path 9471'
							d='M7.099 26.986a.618.618 0 01.063 1.166.476.476 0 00-.218-.023H.649a.471.471 0 00-.218.023.6.6 0 01-.432-.466.6.6 0 00.021-.236v-6.811a.605.605 0 00-.021-.235.445.445 0 01.474-.412c.251.005.479.034.539.367a.39.39 0 00.064.112 1.561 1.561 0 01.02.441v5.707c0 .114-.063.253.064.343 1.862.008 3.724 0 5.586 0a.915.915 0 01.344.022'
						/>
						<path
							data-name='Path 9472'
							d='M20.096 11.742c.476.3.538.5.326.941a.335.335 0 01-.327.226 1.869 1.869 0 00-.47-.023c-.088-.006-.142.03-.133.137-.013.952.027 1.9-.022 2.856-.081.284-.18.549-.515.557-.516.049-1.033 0-1.549.023-.124.073-.064.2-.068.3-.018.483.022.968-.022 1.451-.085.28-.179.551-.516.557a6.523 6.523 0 01-1.21 0 .546.546 0 01-.442-.607c0-.321.14-.491.437-.507.208-.082.434.032.64-.067a12.081 12.081 0 00.014-1.854c0-.362.278-.379.5-.467a9.322 9.322 0 001.648-.012l-.006-.005a.686.686 0 00.036-.4c-.009-.874.031-1.749-.02-2.623.014-.344.273-.4.5-.488a6.649 6.649 0 011.207 0'
						/>
						<path
							data-name='Path 9473'
							d='M13.562 12.908c.459.237.527.345.442.748a.462.462 0 01-.472.431c-.729 0-1.458-.009-2.187 0a.462.462 0 01-.5-.4c-.09-.421 0-.582.33-.711.057-.023.113-.05.17-.075.54-.06.535-.06.541-.685.007-.752-.027-1.5.019-2.257a.534.534 0 01.452-.562 25.624 25.624 0 012.352 0 .606.606 0 01.431.595.579.579 0 01-.432.571 8.778 8.778 0 00-1.709.021q0 1.128.006 2.257c.18.092.378-.01.56.058'
						/>
						<path
							data-name='Path 9474'
							d='M16.237 20.404a.457.457 0 01.473-.414.54.54 0 01.6.482 7.559 7.559 0 010 1.242c-.083.28-.174.551-.512.556a1.3 1.3 0 01-.393.024c-.07.005-.149-.01-.152.106-.013.573.026 1.145-.02 1.717-.121.208-.179.478-.45.537-.777-.058-1.555-.016-2.333-.022a6.885 6.885 0 00-1.1.022.485.485 0 01-.439-.491c-.036-.33.042-.585.375-.675.838-.057 1.678-.008 2.516-.027.112 0 .231.039.337-.039l.009.007a13.682 13.682 0 00.008-1.857.461.461 0 01.425-.419c.208-.076.432.035.638-.065a1.8 1.8 0 00.011-.689'
						/>
						<path
							data-name='Path 9475'
							d='M12.987 18.752c0-.556.009-1.112 0-1.669 0-.281.041-.518.334-.587a.531.531 0 01.7.4.374.374 0 00.054.081c.036 1.19.033 2.381 0 3.571-.107.2-.125.455-.385.556a.529.529 0 01-.7-.486c-.006-.2-.008-.41-.012-.615a5.055 5.055 0 00-1.027-.039c-.178.168 0 .408-.1.591-.026.435-.112.522-.561.56-.2.017-.411-.205-.466-.5a8.15 8.15 0 010-1.307.45.45 0 01.384-.485c.48-.063.963-.009 1.443-.03.111 0 .231.044.334-.042'
						/>
						<path
							data-name='Path 9476'
							d='M11.414 15.319c.3.011.438.185.437.507a.548.548 0 01-.444.607c-.566.05-1.133.021-1.7.019-.893 0-1.786.029-2.678-.018a.615.615 0 01-.565-.577c.013-.286.123-.515.432-.538.486-.069.974-.008 1.46-.033.1 0 .21.055.288-.06-.008-.612.037-1.225-.023-1.836.1-.3.3-.5.592-.451.243.038.5.154.485.52.059.474.008.951.028 1.427 0 .121-.038.25.037.363a7.6 7.6 0 001.188.039 1.429 1.429 0 01.467.029'
						/>
						<path
							data-name='Path 9477'
							d='M4.31 15.879c-.033.337-.192.533-.517.544a.485.485 0 01-.56-.476c.052-.978.012-1.957.021-2.936a3.937 3.937 0 00-.021-.782c.075-.3.314-.519.558-.471.269.052.514.171.519.539a1.644 1.644 0 01.022.425c0 .076-.009.162.1.165.528.014 1.057-.029 1.584.023.243.13.483.248.449.627-.03.333-.2.542-.507.548-.528.01-1.056.008-1.584.012a7.708 7.708 0 00-.04 1.21 2.993 2.993 0 01-.022.573'
						/>
						<path
							data-name='Path 9478'
							d='M12.982 7.007c0-.577.01-1.155.009-1.732a.485.485 0 01.437-.537 6.755 6.755 0 011.281 0 .5.5 0 01.411.589c-.016.212-.078.533-.411.525a1.668 1.668 0 01-.453.025c-.069.005-.149-.015-.157.1-.012.551.024 1.1-.02 1.652-.116.233-.145.541-.452.607a22.768 22.768 0 00-2.354 0 .479.479 0 01-.438-.454.571.571 0 01.374-.711c.479-.054.958-.007 1.437-.026.111 0 .23.04.334-.04'
						/>
						<path
							data-name='Path 9479'
							d='M18.824 18.768a.5.5 0 01-.436-.573c.006-.289.127-.51.431-.54.673-.064 1.347-.022 2.021-.025a10.322 10.322 0 011.419.025c.279.017.376.231.443.478.039.829.041 1.658 0 2.486a.573.573 0 01-.551.528.521.521 0 01-.495-.382c-.026-.073.031-.1.018-.147-.052-.475-.008-.951-.024-1.426a.9.9 0 00-.028-.368 4.349 4.349 0 00-.937-.034c-.618-.02-1.237.027-1.855-.025'
						/>
						<path
							data-name='Path 9480'
							d='M19.47 21.568a.511.511 0 01.594-.407.52.52 0 01.482.476c.051.506.007 1.014.025 1.521 0 .1-.055.229.067.3.539.021 1.079-.03 1.617.027a.519.519 0 01.437.544.54.54 0 01-.372.571c-.535.06-1.072.012-1.607.026-.631.017-1.262-.031-1.892.025a.623.623 0 01-.438-.645.521.521 0 01.438-.52c.208-.073.431.032.638-.062a12.876 12.876 0 00.009-1.861'
						/>
						<path
							data-name='Path 9481'
							d='M21.045 16.435a.543.543 0 01-.5-.6c.019-.276.135-.494.432-.515a1.742 1.742 0 01.5-.03c.068-.007.154.027.175-.089-.013-.6.03-1.206-.024-1.808a.526.526 0 01.581-.455c.242.034.511.151.5.523q.059 1.209 0 2.419a.548.548 0 01-.45.552 6.419 6.419 0 01-1.207 0'
						/>
						<path
							data-name='Path 9482'
							d='M15.159 12.232c-.04-.349.227-.414.428-.464a.465.465 0 01.525.232 2.669 2.669 0 01.126.3 7.728 7.728 0 010 1.24c-.149.428-.317.58-.627.546a.47.47 0 01-.451-.479 8.787 8.787 0 000-1.375'
						/>
						<path
							data-name='Path 9483'
							d='M10.825 4.059c.073-.4.158-.476.529-.482a.431.431 0 01.5.475 6.349 6.349 0 010 1.32.566.566 0 01-.513.542.6.6 0 01-.514-.549 8.191 8.191 0 010-1.307'
						/>
						<path
							data-name='Path 9484'
							d='M18.825 28.156c.088-.1.2-.055.3-.055h6.126c.1 0 .212-.039.3.055h-6.726'
						/>
						<path
							data-name='Path 9485'
							d='M.431 28.156c.077-.088.177-.056.269-.056h6.189c.091 0 .192-.033.268.056H.431'
						/>
						<path
							data-name='Path 9486'
							d='M-.001 20.404c.089.095.051.216.051.325v6.634c0 .109.037.23-.051.325v-7.284'
						/>
						<path
							data-name='Path 9487'
							d='M0 .489C.082.572.051.681.051.78v6.7c0 .1.032.207-.051.29V.489'
						/>
						<path
							data-name='Path 9488'
							d='M7.101.071H.426C.481-.036.576.009.652.009h6.222c.077 0 .172-.045.227.062'
						/>
						<path
							data-name='Path 9489'
							d='M25.494.071h-6.675c.055-.106.15-.062.227-.062h6.222c.077 0 .172-.045.226.062'
						/>
						<path
							data-name='Path 9490'
							d='M25.934 27.626v-7.159c.1.06.057.162.057.246v6.668c0 .083.041.186-.057.245'
						/>
						<path
							data-name='Path 9491'
							d='M25.934 7.708V.549c.1.06.057.162.057.245v6.668c0 .083.04.186-.057.246'
						/>
						<path
							data-name='Path 9492'
							d='M24.854 26.933c-.03.089-.1.065-.159.065l-5.871-.009c.089-.09.2-.057.3-.057h5.729'
						/>
						<path
							data-name='Path 9493'
							d='M18.824 1.185l5.871-.009c.058 0 .13-.023.159.065h-5.729c-.1 0-.213.033-.3-.057'
						/>
						<path
							data-name='Path 9494'
							d='M1.077 7.702q0-3.176-.008-6.352c0-.059-.032-.142.062-.164.022.013.03.027.023.041s-.014.022-.021.022v6.059c0 .132.044.275-.052.394'
						/>
						<path
							data-name='Path 9495'
							d='M1.131 26.988c-.094-.022-.062-.1-.062-.164q0-3.176.008-6.352c.1.119.051.262.052.394q.006 3.029 0 6.059c.021.015.028.029.02.043s-.015.02-.022.02'
						/>
						<path
							data-name='Path 9496'
							d='M24.908 20.403q0 3.179.009 6.358c0 .063.022.141-.06.173v-6.2c0-.11-.031-.23.052-.327'
						/>
						<path
							data-name='Path 9497'
							d='M1.133 1.25v-.063h5.964c-.11.1-.242.056-.364.057q-2.8.008-5.6.009'
						/>
						<path
							data-name='Path 9498'
							d='M24.854 1.241c.082.032.06.11.06.173q0 3.145-.009 6.289c-.08-.084-.053-.193-.053-.292v-6.17'
						/>
						<path
							data-name='Path 9499'
							d='M1.131 26.988v-.063q2.8 0 5.6.008c.122 0 .254-.047.364.057H1.131'
						/>
						<path
							data-name='Path 9500'
							d='M3.663 24.657c.04-.073.107-.058.168-.058l5.488.007c-.039.075-.107.06-.168.06l-5.488-.009'
						/>
						<path
							data-name='Path 9501'
							d='M3.233 18.066c.076.056.053.142.053.219v5.686c0 .076.025.163-.053.218v-6.123'
						/>
						<path
							data-name='Path 9502'
							d='M3.666 10.571c.065-.084.155-.055.236-.055h5.176c.081 0 .17-.027.236.056H3.666'
						/>
						<path
							data-name='Path 9503'
							d='M16.668 10.573c.066-.082.155-.056.236-.056h5.176c.081 0 .17-.029.236.055h-5.648'
						/>
						<path
							data-name='Path 9504'
							d='M16.237 3.989c.076.071.051.168.051.256v5.6c0 .088.023.185-.052.256V3.985'
						/>
						<path
							data-name='Path 9505'
							d='M3.234 3.986c.087.067.052.166.052.252v5.618c0 .086.033.185-.052.253V3.986'
						/>
						<path
							data-name='Path 9506'
							d='M9.256 3.572H3.661c.06-.1.153-.059.231-.06h5.132c.078 0 .172-.039.231.06'
						/>
						<path
							data-name='Path 9507'
							d='M9.256 17.654h-5.6c.055-.106.15-.062.227-.062h5.144c.077 0 .171-.045.226.062'
						/>
						<path
							data-name='Path 9508'
							d='M22.258 3.572h-5.6c.06-.1.153-.059.232-.059h5.133c.079 0 .172-.038.231.06'
						/>
						<path
							data-name='Path 9509'
							d='M22.702 10.043v-5.99c.09.065.055.165.055.251v5.489c0 .085.035.186-.055.25'
						/>
						<path
							data-name='Path 9510'
							d='M9.692 24.123v-5.991c.1.059.057.162.057.245v5.5c0 .083.04.186-.057.245'
						/>
						<path
							data-name='Path 9511'
							d='M9.697 10.043V4.052c.1.06.057.162.057.245v5.5c0 .083.041.186-.057.245'
						/>
						<path
							data-name='Path 9512'
							d='M7.029 16.436h4.378c-.068.075-.156.057-.239.057h-3.9c-.083 0-.171.02-.24-.055'
						/>
						<path
							data-name='Path 9513'
							d='M18.821 24.652c.04-.072.107-.058.168-.058l3.331.007c-.039.073-.108.06-.168.06l-3.331-.009'
						/>
						<path
							data-name='Path 9514'
							d='M12.352 24.654c.042-.07.109-.058.17-.058h3.094c.061 0 .128-.012.17.058z'
						/>
						<path
							data-name='Path 9515'
							d='M22.259 17.654h-3.44c.034-.082.1-.063.163-.063h3.114c.059 0 .129-.019.163.063'
						/>
						<path
							data-name='Path 9516'
							d='M3.233 12.228c.074.057.053.143.053.219v3.281c0 .076.022.162-.053.219v-3.718'
						/>
						<path
							data-name='Path 9517'
							d='M14.079 20.543v-3.571a.292.292 0 01.054.224v3.122a.3.3 0 01-.053.225'
						/>
						<path
							data-name='Path 9518'
							d='M11.902 9.966c0 .915 0 1.83.013 2.744 0 .163-.04.229-.195.211-.125-.014-.253-.01-.379-.014.008-.007.015-.019.024-.021.483-.121.483-.121.483-.692v-1.9c0-.11-.031-.229.052-.325'
						/>
						<path
							data-name='Path 9519'
							d='M15.142 23.422c-.031.112-.119.072-.183.072-.89 0-1.78 0-2.67-.006.1-.1.219-.058.33-.059.841-.006 1.682-.006 2.523-.008'
						/>
						<path
							data-name='Path 9520'
							d='M18.392 12.229c.061.047.053.119.053.186v2.63c0 .074.024.164-.068.205l.016-3.021'
						/>
						<path
							data-name='Path 9521'
							d='M18.824 18.768q1.319 0 2.638-.009c.057 0 .13-.025.154.068h-2.46c-.112 0-.233.038-.332-.057'
						/>
						<path
							data-name='Path 9522'
							d='M19.47 15.878q0-1.474-.006-2.948c.022-.034.044-.035.066 0 0 .864 0 1.729-.008 2.593 0 .119.047.251-.052.355'
						/>
						<path
							data-name='Path 9523'
							d='M11.275 8.239c.107-.11.24-.054.359-.055a80.28 80.28 0 011.7 0c.1 0 .211-.046.3.055H11.28'
						/>
						<path
							data-name='Path 9524'
							d='M14.705 9.409h-2.352c.153-.11.324-.051.486-.054.522-.008 1.045 0 1.567 0 .1 0 .211-.034.3.058'
						/>
						<path
							data-name='Path 9525'
							d='M22.701 20.619v-2.486c.1.076.054.189.055.284q.006.959 0 1.918c0 .1.04.208-.055.285'
						/>
						<path
							data-name='Path 9526'
							d='M22.702 15.881v-2.419c.1.088.053.212.054.318q.008.891 0 1.782c0 .106.05.23-.054.318'
						/>
						<path
							data-name='Path 9527'
							d='M12.982 7.007c-.017.1-.092.073-.147.073-.541 0-1.083 0-1.624-.007a2.954 2.954 0 01.988-.057c.26-.017.522-.007.784-.009'
						/>
						<path
							data-name='Path 9528'
							d='M12.986 18.749c-.048.109-.141.076-.219.076h-1.559c.215-.134.448-.049.672-.06.368-.018.737-.01 1.105-.013'
						/>
						<path
							data-name='Path 9529'
							d='M8.675 15.247c-.076.119-.191.075-.291.076H6.896c.192-.135.4-.052.606-.061.369-.018.739-.011 1.109-.015a.037.037 0 01.064 0'
						/>
						<path
							data-name='Path 9530'
							d='M12.996 10.593c.061-.115.166-.08.253-.075a7.219 7.219 0 011.456.054l-1.709.021'
						/>
						<path
							data-name='Path 9531'
							d='M16.236 15.734a5.863 5.863 0 01-.014 1.854l.014-1.854'
						/>
						<path
							data-name='Path 9532'
							d='M20.606 23.485c-.137-.128-.055-.3-.058-.443-.011-.468 0-.937 0-1.405a3.34 3.34 0 01.053 1c.015.26 0 .522.005.783q.031.023.02.044t-.022.021'
						/>
						<path
							data-name='Path 9533'
							d='M15.159 21.573a6.247 6.247 0 01-.008 1.857q0-.928.008-1.857'
						/>
						<path
							data-name='Path 9534'
							d='M21.625 13.394a2.669 2.669 0 01.052.931c.017.306 0 .613 0 .92q-.032.059-.067 0l.014-1.852'
						/>
						<path
							data-name='Path 9535'
							d='M8.675 15.246h-.064q0-.926.01-1.852a1.548 1.548 0 01.053.725c.015.376 0 .753 0 1.129'
						/>
						<path
							data-name='Path 9536'
							d='M19.471 21.569a6.072 6.072 0 01-.009 1.861q0-.93.009-1.861'
						/>
						<path
							data-name='Path 9537'
							d='M16.236 24.117v-1.782q.033-.048.059 0c0 .485 0 .97-.007 1.455 0 .109.03.228-.052.323'
						/>
						<path
							data-name='Path 9538'
							d='M17.315 18.212l-.011-1.653c0-.052-.011-.116.064-.125.021.013.028.026.022.04s-.012.022-.019.022c0 .464 0 .928-.007 1.392 0 .109.035.228-.05.323'
						/>
						<path
							data-name='Path 9539'
							d='M20.602 23.485v-.065c.452 0 .9 0 1.355.01.1 0 .212-.047.3.058h-1.654'
						/>
						<path
							data-name='Path 9540'
							d='M18.382 15.255c-.2.14-.424.067-.634.062a2.956 2.956 0 01-1.014-.05l1.648-.012'
						/>
						<path
							data-name='Path 9541'
							d='M11.413 15.32H10.02c-.091 0-.193.031-.263-.071.457 0 .915 0 1.372.007.094 0 .209-.064.283.062'
						/>
						<path
							data-name='Path 9542'
							d='M4.311 15.879c0-.53-.007-1.059-.006-1.589 0-.069-.038-.165.068-.194 0 .488 0 .976-.009 1.464 0 .107.044.229-.053.319'
						/>
						<path
							data-name='Path 9543'
							d='M9.763 15.251c-.135-.13-.062-.3-.066-.452-.01-.446 0-.892 0-1.338.127.232.043.484.055.727.018.354.008.709.01 1.063'
						/>
						<path
							data-name='Path 9544'
							d='M6.015 12.91H4.372q-.034-.023-.02-.043t.025-.018h1.341c.1 0 .21-.033.3.057'
						/>
						<path
							data-name='Path 9545'
							d='M21.621 18.823c.125.216.048.454.056.681.012.37 0 .742 0 1.113a3.72 3.72 0 01-.053-1.076c-.014-.239 0-.479 0-.719'
						/>
						<path
							data-name='Path 9546'
							d='M17.373 16.498a.29.29 0 010-.063h1.582a4.958 4.958 0 01-1.174.055c-.135.01-.271 0-.406.006'
						/>
						<path
							data-name='Path 9547'
							d='M14.081 7.631V5.922c.021-.033.04-.033.057 0v1.383c0 .109.031.227-.051.322'
						/>
						<path
							data-name='Path 9548'
							d='M15.158 12.232a3.715 3.715 0 010 1.375v-1.375'
						/>
						<path
							data-name='Path 9549'
							d='M14.705 4.739h-1.281a2.76 2.76 0 011.281 0'
						/>
						<path
							data-name='Path 9550'
							d='M11.853 5.372v-1.32a2.957 2.957 0 010 1.32'
						/>
						<path
							data-name='Path 9551'
							d='M10.824 19.306v1.307a3.223 3.223 0 010-1.307'
						/>
						<path
							data-name='Path 9552'
							d='M10.825 4.059v1.307a3.232 3.232 0 010-1.307'
						/>
						<path
							data-name='Path 9553'
							d='M21.046 16.433h1.207a2.55 2.55 0 01-1.207 0'
						/>
						<path
							data-name='Path 9554'
							d='M20.096 11.741h-1.207a2.585 2.585 0 011.207 0'
						/>
						<path
							data-name='Path 9555'
							d='M15.589 18.768h1.21a2.636 2.636 0 01-1.21 0'
						/>
						<path
							data-name='Path 9556'
							d='M17.314 21.714v-1.242a2.963 2.963 0 010 1.242'
						/>
						<path data-name='Path 9557' d='M16.236 13.54V12.3a2.986 2.986 0 010 1.24' />
						<path
							data-name='Path 9558'
							d='M11.923 19.929c.352.039.715-.1 1.06.071h-1.059c-.023-.015-.031-.031-.023-.047s.015-.024.023-.024'
						/>
						<path
							data-name='Path 9559'
							d='M21.613 15.246h.067c-.221.162-.468.043-.7.076.2-.14.426-.022.635-.074'
						/>
						<path
							data-name='Path 9560'
							d='M16.237 20.404a.908.908 0 01-.011.689l.011-.689'
						/>
						<path
							data-name='Path 9561'
							d='M14.135 5.926l-.028-.007h-.029c.2-.166.422-.041.632-.068-.18.134-.387.017-.575.073'
						/>
						<path
							data-name='Path 9562'
							d='M16.222 21.088c-.2.158-.425.04-.638.065.2-.127.427-.026.638-.065'
						/>
						<path
							data-name='Path 9563'
							d='M16.224 17.587c-.2.148-.427.042-.64.067.2-.138.429-.025.64-.067'
						/>
						<path
							data-name='Path 9564'
							d='M19.459 23.426c-.2.152-.425.042-.638.062.2-.124.426-.031.638-.062'
						/>
						<path
							data-name='Path 9565'
							d='M19.53 12.93h-.066a.661.661 0 01.63-.022l-.565.021'
						/>
						<path
							data-name='Path 9566'
							d='M16.298 22.338l-.029-.006h-.03c.176-.152.378-.043.567-.065-.157.124-.341.024-.508.069'
						/>
						<path
							data-name='Path 9567'
							d='M11.922 19.929v.07c-.05.18.061.384-.07.553.026-.207-.075-.431.069-.623'
						/>
						<path
							data-name='Path 9568'
							d='M13.562 12.909c-.187-.016-.385.087-.56-.059.186.028.382-.058.56.059'
						/>
						<path
							data-name='Path 9569'
							d='M4.376 12.849a.123.123 0 000 .061c-.144-.19-.041-.409-.062-.614.115.171.02.372.066.553'
						/>
						<path
							data-name='Path 9570'
							d='M4.369 9.403c-.106-.033-.065-.13-.065-.2V4.888c0-.069-.041-.165.062-.2a.332.332 0 01.067.112 2.078 2.078 0 01.032.542v3.477a1.86 1.86 0 01-.029.475.334.334 0 01-.066.112'
						/>
						<path
							data-name='Path 9571'
							d='M4.369 4.754v-.065l4.115-.011c.052 0 .106 0 .139.055l-.042.05a.763.763 0 01-.432.071H4.826a.607.607 0 01-.454-.1'
						/>
						<path
							data-name='Path 9572'
							d='M8.619 4.733c.085.049.058.138.058.212v4.2c0 .074.031.163-.055.213l-.045-.047a.748.748 0 01-.062-.365V5.153a.533.533 0 01.094-.385z'
						/>
						<path
							data-name='Path 9573'
							d='M4.369 9.401a.216.216 0 010-.066.5.5 0 01.39-.1q1.723-.005 3.446 0a.529.529 0 01.392.093l.022.024c-.028.064-.083.06-.135.06l-4.119-.01'
						/>
						<path
							data-name='Path 9574'
							d='M4.366 23.485c-.1-.038-.061-.133-.061-.2V18.97c0-.069-.041-.166.065-.2a.34.34 0 01.065.113 4.074 4.074 0 01.029.815q.005 1.6 0 3.2a1.769 1.769 0 01-.031.474.326.326 0 01-.068.111'
						/>
						<path
							data-name='Path 9575'
							d='M8.622 18.818c.074.04.055.115.055.179v4.267c0 .066.013.14-.059.178l-.043-.048a.626.626 0 01-.057-.3c0-1.323-.007-2.647 0-3.97a.7.7 0 01.029-.2.232.232 0 01.073-.106'
						/>
						<path
							data-name='Path 9576'
							d='M4.363 23.486v-.065a.519.519 0 01.391-.1h3.487a.515.515 0 01.363.09.051.051 0 01.008.033c-.033.059-.087.054-.139.054l-4.114-.011'
						/>
						<path
							data-name='Path 9577'
							d='M4.373 18.834a.213.213 0 010-.066l4.118-.01c.052 0 .107 0 .135.06l-.022.024a.529.529 0 01-.392.093H4.766a.5.5 0 01-.39-.1'
						/>
						<path
							data-name='Path 9578'
							d='M17.314 4.75c.031-.115.12-.07.184-.071h3.926c.07 0 .15-.024.2.057l-.042.049a.535.535 0 01-.306.067q-1.821.006-3.641 0a.684.684 0 01-.219-.03.3.3 0 01-.1-.072'
						/>
						<path
							data-name='Path 9579'
							d='M17.314 4.75a.17.17 0 01.061 0 .463.463 0 01.09.32c0 1.35.009 2.7 0 4.05a.635.635 0 01-.026.169.329.329 0 01-.069.113c-.076-.012-.063-.077-.063-.129q0-2.263.009-4.526'
						/>
						<path
							data-name='Path 9580'
							d='M21.628 4.736c.064.046.052.119.052.185v4.247c0 .065.016.139-.05.185l-.047-.046a.948.948 0 01-.066-.467V5.25a.747.747 0 01.088-.491z'
						/>
						<path
							data-name='Path 9581'
							d='M17.373 9.403v-.064a.558.558 0 01.422-.1h3.387a.524.524 0 01.42.1l.03.009c-.036.079-.1.064-.165.064l-4.1-.011'
						/>
						<path
							data-name='Path 9582'
							d='M9.724 4.283a.481.481 0 00-.026-.235c-.013-.305-.214-.406-.441-.48q-2.8-.065-5.595 0a.533.533 0 00-.427.414.411.411 0 01.023.168v5.788a.41.41 0 01-.023.168c.089.215.186.42.432.463a.951.951 0 01.345-.022h4.959a.928.928 0 01.344.022.523.523 0 00.384-.529.484.484 0 00.026-.235V4.284M8.601 9.333l-4.228.008V4.754l4.242.011z'
						/>
						<path
							data-name='Path 9583'
							d='M9.723 23.887v-5.521a.484.484 0 00-.026-.235q-.014-.461-.441-.478a.388.388 0 00-.217-.028H3.877a.385.385 0 00-.217.028c-.255 0-.342.2-.427.412a.466.466 0 01.023.2v5.719a.48.48 0 01-.022.2.671.671 0 00.429.465.892.892 0 01.345-.023h4.967a.81.81 0 00.344-.025.518.518 0 00.378-.48.481.481 0 00.026-.235m-5.355-.467v-4.587l4.228.008q0 2.284.01 4.567l-4.242.011'
						/>
						<path
							data-name='Path 9584'
							d='M22.702 4.053a.547.547 0 00-.444-.481.268.268 0 00-.123-.026h-5.349a.277.277 0 00-.123.025c-.2.064-.39.151-.426.418a1.006 1.006 0 01.021.339v5.438a1 1 0 01-.021.339.6.6 0 00.431.467.924.924 0 01.345-.022h4.959a.955.955 0 01.345.022.789.789 0 00.386-.529q.06-2.995 0-5.99m-1.1 5.291h-4.23V4.756h4.232q0 2.292-.006 4.585'
						/>
						<path
							data-name='Path 9585'
							d='M7.098 5.905a.55.55 0 01.444.482 7.032 7.032 0 010 1.32.572.572 0 01-.38.479c-.424.077-.854-.021-1.279.052-.257-.072-.486-.172-.448-.534a8.758 8.758 0 010-1.385c0-.276.184-.354.384-.414a6.756 6.756 0 011.279 0'
						/>
						<path
							data-name='Path 9586'
							d='M7.098 5.909H5.819a2.769 2.769 0 011.279 0'
						/>
						<path data-name='Path 9587' d='M5.435 6.32v1.385a3.532 3.532 0 010-1.385' />
						<path
							data-name='Path 9588'
							d='M5.884 8.239a5.177 5.177 0 011.279-.052 4.578 4.578 0 01-1.279.052'
						/>
						<path data-name='Path 9589' d='M7.542 7.708v-1.32a3.135 3.135 0 010 1.32' />
						<path
							data-name='Path 9590'
							d='M7.099 19.988a.54.54 0 01.443.48 7.027 7.027 0 010 1.32c-.064.252-.167.461-.444.482-.4.074-.813-.025-1.216.052-.247-.082-.47-.188-.446-.535-.065-.46.023-.927-.046-1.387a.538.538 0 01.429-.411 6.334 6.334 0 011.283 0'
						/>
						<path data-name='Path 9591' d='M7.1 19.988H5.817a2.689 2.689 0 011.283 0' />
						<path
							data-name='Path 9592'
							d='M5.389 20.399a6.177 6.177 0 01.046 1.387 6.188 6.188 0 01-.046-1.387'
						/>
						<path
							data-name='Path 9593'
							d='M7.542 21.788v-1.32a3.133 3.133 0 010 1.32'
						/>
						<path
							data-name='Path 9594'
							d='M5.881 22.321a4.376 4.376 0 011.216-.052 4.388 4.388 0 01-1.216.052'
						/>
						<path
							data-name='Path 9595'
							d='M18.393 6.322a.535.535 0 01.428-.417 6.788 6.788 0 011.279 0 .561.561 0 01.448.483 7.887 7.887 0 010 1.317.678.678 0 01-.449.533 6.807 6.807 0 00-1.276 0 .662.662 0 01-.43-.466 9.539 9.539 0 000-1.45'
						/>
						<path
							data-name='Path 9596'
							d='M18.393 6.322a4.132 4.132 0 010 1.45v-1.45'
						/>
						<path
							data-name='Path 9597'
							d='M18.822 8.237a2.886 2.886 0 011.276 0h-1.276'
						/>
						<path
							data-name='Path 9598'
							d='M20.1 5.909h-1.279a2.773 2.773 0 011.279 0'
						/>
						<path
							data-name='Path 9599'
							d='M20.546 7.706V6.389a3.272 3.272 0 010 1.317'
						/>
					</g>
				</g>
				<text
					transform='translate(0 44.948)'
					fill='#fff'
					fontSize={12}
					fontFamily='Poppins-Regular, Poppins'
				>
					<tspan x={0} y={0}>
						SCAN
					</tspan>
				</text>
			</g>
		</svg>
	);
}
export default SvgQrScan;
