import { Google } from '@mui/icons-material';
import { Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { verifySocialsThunk } from '@pw/redux/thunks/register';
import { useDispatch } from 'react-redux';

function SocialRegister({ invite, showRegistration }) {
	const dispatch = useDispatch();
	const login = async (type) => {
		dispatch(verifySocialsThunk({ type, invite }))
			.unwrap()
			.then((response) => {
				showRegistration(response);
			});
	};

	return (
		<Stack spacing={2}>
			<FilledButton
				icon={<Google />}
				iconPosition='start'
				label='Google'
				handleClick={() => login('google')}
			/>
		</Stack>
	);
}

export default SocialRegister;
