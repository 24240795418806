import { clientFields } from '@pw/components/Client/clientFields';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import InvoiceSKUModal from '@pw/components/SKUSelector/modals/InvoiceSKUModal';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useContext, useMemo } from 'react';
import * as yup from 'yup';

function Properties() {
	const { readonly } = useContext(FormikContext);
	return (
		!readonly && (
			<FormikProvider path='properties'>
				<FormikTextField name='invoice_id' label='Invoice Id' />
			</FormikProvider>
		)
	);
}

function propertyFields(fields = {}) {
	const { invoice_id = '' } = fields;

	return {
		invoice_id: [invoice_id, yup.string().required('Please enter invoice id')],
	};
}

function InvoiceForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();
	const filter = {
		sku_types: [SKU_TYPES.FINISHED, SKU_TYPES.SERVICE, SKU_TYPES.CONSUMABLE],
	};

	const [[skus], , initSkus, SKUInventory, SKUModal] =
		useInventorySelectorHook({
			title: 'Goods & Services',
			filter: filter,
			submitForm,
			SKUModal: InvoiceSKUModal,
		});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
			properties: propertyFields(initialValues?.properties),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSkus({
				...entity,
				skus: entity?.sku_destinations,
			});
		},
		[initSkus],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_destinations: skus,
			};
		},
		[skus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Invoice'
				requestType={REQUEST_TYPES.invoice}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SKUInventory />
				<Properties />
				<FormSubmitter />
			</RequestForm>
			<SKUModal />
		</>
	);
}

export default withAppLayout(InvoiceForm, { title: 'Invoice Form' });
