import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box, Divider, Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { H5 } from '@pw/components/Typography';
import { REQUEST_EDIT_URL, REQUEST_NAMES } from '@pw/consts/requests';
import { useNavigate } from 'react-router-dom';


function SKUConsumptionItem({ item, unit, onItemClick }) {
	const {
		amount,
		request_name,
		request_type,
		request_id,
		duty_paid,
	} = item;

	return (
		<Box className='card'>
			<Box className={`card-tab card-tab-confirmed`} />
			<Stack
				className={`card-content ${duty_paid ? 'card-highlight' : ''}`}
				spacing={0.25}
			>
				<IDDisplay value={request_name} />
				<SourceDisplay
					type={request_type}
					name={REQUEST_NAMES[request_type]}
				/>
				<Divider variant='middle' sx={{ opacity: 0.6 }} />
				<AmountDisplay label="Amount" amount={amount} unit={unit} />
			</Stack>
			<Stack
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					maxWidth: 'min-content',
					paddingRight: '1rem',
				}}
			>
				<IconCircleButton onClick={()=>onItemClick({request_id, request_type})}>
					<ArrowForward />
				</IconCircleButton>				
			</Stack>
		</Box>
	);
}

function SKUConsumptionItemInfo({ item, unit }) {
	const navigate = useNavigate();
	const goToItem = ({
		request_id, 
		request_type, 
	}) => {
		const id = request_id ?? 'new';
		const route = REQUEST_EDIT_URL?.[request_type];
		navigate(`${route}/${id}`, { replace: false });
	};
	const {
		reserved = [],
		consumed = []
	} = item ?? {};

	return (
		<Stack spacing={0.5}>
			{!!reserved.length && (
				<>
					<H5>Reserved</H5>
					<FlexBox
						className='asset-container'
						justifyContent='start'
						gap='0.75rem'
					>
						{reserved.map((_item) => (
							<SKUConsumptionItem key={_item.path} item={_item} unit={unit} onItemClick={goToItem} />
						))}
					</FlexBox>
				</>
				
			)}
			{
				(!!consumed.length && !!reserved.length) && <Divider />
			}
			{!!consumed.length && (
				<>
					<H5>Consumed</H5>
					<FlexBox
						className='asset-container'
						justifyContent='start'
						gap='0.75rem'
					>
						{consumed.map((_item) => (
							<SKUConsumptionItem key={_item.path} item={_item} unit={unit} onItemClick={goToItem} />
						))}
					</FlexBox>
				</>
				
			)}
		</Stack>
	);
}

export default SKUConsumptionItemInfo;
