import Stack from '@mui/material/Stack';
import {
	FormikDatePicker,
	FormikSelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKUField from '@pw/components/SKUSelector/SKUField';
import { LIQUID_ACCOUNT_TYPE_OPTIONS } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import { mt2 } from '@pw/styles/margins';

export default function LiquidSource({
	disabled = false,
	useLiquidAccount = false,
	useLiquidSelector = true,
}) {
	return (
		<Stack spacing={2}>
			{useLiquidSelector && (
				<SKUField title='Liquid' skuTypes={[SKU_TYPES.LIQUID]} tags='liquid' />
			)}
			{!!useLiquidAccount && (
				<FormikSelect
					disabled={false}
					label='Liquid Account'
					name='liquid_account'
					options={LIQUID_ACCOUNT_TYPE_OPTIONS}
					fullWidth
				/>
			)}
			<FlexBox alignItems='baseline'>
				<FormikTextField
					disabled={disabled}
					name='batch'
					label='Batch'
					fullWidth
				/>
				<FormikDatePicker
					disabled={disabled}
					sx={{ ...mt2 }}
					name='date'
					label='Production Date'
					fullWidth
					required
				/>
			</FlexBox>
			<TagSelect
				disabled={disabled}
				name='liquid'
				type='liquid'
			/>
		</Stack>
	);
}
