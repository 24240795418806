import { createOwner, inviteOwner, listOwners, resetOwnerPassword, updateOwner, uploadOwners } from "@pw/services/company.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const refetchOwnersThunk = createAsyncThunk(
  'owners/refetch',
  async (params, { rejectWithValue }) => {
    try {
      return await listOwners({...params, force: true});
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);  

export const upsertOwnerThunk = createAsyncThunk(
  'owners/upsert',
  async (params, { rejectWithValue }) => {
    try {
      return await (params.path ? updateOwner(params) : createOwner(params));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadOwnersThunk = createAsyncThunk(
  'owners/upload',
  async (params, { rejectWithValue }) => {
    try {
      return await uploadOwners(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);  

export const inviteOwnerThunk = createAsyncThunk(
  'owners/invite',
  async (params, { rejectWithValue }) => {
    try {
      return await inviteOwner(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);  
  
export const resetOwnerPasswordThunk = createAsyncThunk(
  'owners/resetPassword',
  async (params, { rejectWithValue }) => {
    try {
      return await resetOwnerPassword(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);  
