/* eslint-disable react/prop-types */
import { AddCircle, QrCode2 } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import CachedIcon from '@mui/icons-material/Cached';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';
import formatCompanyAddress from '@pw/components/Administration/formatCompanyAddress';
import { QrScannerDialog } from '@pw/components/ScanQR';
import { Status } from '@pw/consts/admin';
import { CLIENT_TYPE } from '@pw/consts/contacts';
import { useCompanyId, useCompanyPartners } from '@pw/redux/containers/User';
import { refetchPartnersThunk } from '@pw/redux/thunks/partners';
import useModalState from '@pw/utilities/hooks/components/useModalState';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import match from '@pw/utilities/match';
import { useSnackbar } from 'notistack';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ModalWithClose } from '../../../Dialogs/ModalWithClose';
import { GridToolbarButton } from '../Owners/components/GridToolbarButton';
import OwnerAssets from '../Owners/components/OwnerAssets';
import { ConfirmPartnersInvite } from './components/ConfirmPartnersInvite';
import { InvitePartnerDialog } from './components/InvitePartnerDialog';
import PartnerForm from './components/PartnerForm';
import { ShareWithPartnerDialogQR } from './components/ShareWithPartnerDialogQR';

const MODALS = {
  partner_form: 'partner_form',
  scan_qr: 'scan_qr',
  share_qr: 'share_qr',
  email_invite: 'email_invite',
  view_partner_assets: 'view_partner_assets',
  confirm_invite: 'confirm_invite',
};

const CustomToolbar = forwardRef(function CustomToolbar(
  {
    showQuickFilter = false,
    quickFilterProps = {},
    onClickRefetch,
    openModal,
    ...other
  },
  ref,
) {
  const isMobile = useIsMobile();
  // const rootProps = useGridRootProps();
  return (
    <GridToolbarContainer ref={ref} {...other}>
      {!!isMobile && <GridToolbarColumnsButton />}
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarButton
        button={{
          children: 'refetch',
          onClick: onClickRefetch,
          startIcon: <CachedIcon />,
        }}
        tooltip={{ title: 'refetch' }}
      />
      <GridToolbarButton
        button={{
          children: 'Share',
          onClick: () => openModal(MODALS.share_qr, {}),
          startIcon: <QrCode2 />,
        }}
        tooltip={{ title: 'Share' }}
      />
      <GridToolbarButton
        button={{
          children: 'Scan',
          onClick: () => openModal(MODALS.scan_qr, {}),
          startIcon: <QrCodeScannerIcon />,
        }}
        tooltip={{ title: 'Scan' }}
      />
      {/* <GridToolbarButton
        button={{
          children: 'Invite',
          onClick: () => openModal(MODALS.email_invite, {}),
          startIcon: <ScheduleSendIcon />,
        }}
        tooltip={{ title: 'Invite' }}
      /> */}
      <GridToolbarButton
        button={{
          children: 'add',
          onClick: () => openModal(MODALS.partner_form, {}),
          startIcon: <AddCircle />,
        }}
        tooltip={{ title: 'add' }}
      />
      <Box sx={{ flex: 1 }} />
      {showQuickFilter && <GridToolbarQuickFilter {...quickFilterProps} />}
    </GridToolbarContainer>
  );
});

function Partners() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // selectors
  const company_id = useCompanyId();
  // states
  const [searchParams, setSearchParams] = useSearchParams();
  const { modal, openModal, closeModal } = useModalState();

  // apis
  const partners = useCompanyPartners();

  // callback
  const handleForceRefetch = useCallback(() => {
    dispatch(refetchPartnersThunk({ force: true }));
  }, [dispatch]);

  const onScan = useCallback(
    ({ text }, extra = {}) => {
      const urlObj = new URL(text);

      console.log('Scan url', text, extra);

      const connect = urlObj.searchParams.get('qq');
      const request = JSON.parse(atob(connect));
      console.log('Connection request', connect);

      // See if we have this partner already..
      const existingPartner = partners.find((p) => p.company_id === request?.c);
      if (existingPartner) {
        enqueueSnackbar('Partner connected already!', { variant: 'info' });
      } else {
        // const existing_contact = extra?.path;
        openModal(MODALS.confirm_invite, request);
      }
    },
    [openModal],
  );

  const columns = useMemo(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        width: 70,
        renderCell: (params) => {
          return match(params.row.status, {
            [Status.APPROVED]: () => <BusinessIcon />,
            [Status.REJECTED]: () => <DomainDisabledIcon />,
            [Status.CREATED]: () => <SettingsSuggestIcon />,
            _: () => null,
          });
        },
      },
      {
        field: 'company_name',
        headerName: 'Name',
        width: 200,
        filterable: true,
      },
      {
        field: 'address',
        headerName: 'Address',
        width: 200,
        filterable: true,
        renderCell: (params) => formatCompanyAddress(params.row),
      },
      {
        field: 'created',
        headerName: 'Created',
        width: 160,
        filterable: true,
        renderCell: (params) => {
          return new Date(params.row.created).toLocaleString();
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params) => (
          <BasicMenu
            menuOptions={[
              {
                title:
                  params?.row?.status === Status.REJECTED ? 'Approve' : 'Edit',
                onClick: () =>
                  params?.row?.status === Status.REJECTED
                    ? openModal(MODALS.confirm_invite, {
                        company_id: btoa(params?.row?.company_id),
                        partner_id: btoa(params?.row?.my_partner_id),
                        existing_contact: params.row?.path,
                      })
                    : openModal(MODALS.partner_form, params.row),
              },
              {
                title: 'Display Connect QR Code',
                onClick: () => openModal(MODALS.share_qr, params.row),
                disabled: params.row?.my_partner_id,
              },
              {
                title: 'Request Connection',
                onClick: () => openModal(MODALS.email_invite, params.row),
                disabled: params.row?.my_partner_id,
              },
              {
                title: 'View Assets', // only status approved can click
                onClick: () =>
                  openModal(MODALS.view_partner_assets, params.row),
                disabled: !(params?.row?.status === Status.APPROVED),
              },
            ]}
          />
        ),
      },
    ],
    [company_id, openModal],
  );

  // effects
  useEffect(() => {
    const connect = searchParams.get('qq');
    console.log('Connection request', connect);

    if (connect) {
      const request = JSON.parse(atob(connect));
      console.log('Connection request', request);


      // See if we have this partner already..
      const existingPartner = partners.find((p) => p.company_id === request?.c);
      if (existingPartner) {
        enqueueSnackbar('Partner connected already!', { variant: 'info' });
      } else {
        // const existing_contact = extra?.path;
        openModal(MODALS.confirm_invite, request);
      }
      // Reset this
      setSearchParams({});
    }
  }, []);

  return (
    <Card>
      <CardHeader
        title='Manage Partners'
        subheader='Manage Partners who have access to assets managed by your organization.'
      />
      <CardContent>
        <Box style={{ height: '69vh', width: '100%' }}>
          <DataGrid
            autoPageSize
            rows={partners ?? []}
            columns={columns}
            getRowId={(row) => row.path}
            disableSelectionOnClick
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                onClickRefetch: handleForceRefetch,
                openModal,
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#f4f4f4',
                zIndex: 1,
              },
            }}
          />
        </Box>
        {modal.fold({
          [MODALS.partner_form]: (state) => (
            <ModalWithClose open={!!state.data} onClose={closeModal} title=''>
              <PartnerForm
                partner={state.data}
                readonly={
                  state?.data?.status &&
                  [Status.REJECTED, Status.APPROVED].includes(
                    state?.data?.status,
                  )
                }
                onCancel={closeModal}
                onSubmit={closeModal}
              />
            </ModalWithClose>
          ),
          [MODALS.email_invite]: (state) => (
            <InvitePartnerDialog
              open={!!state?.data}
              onClose={closeModal}
              partnerId={state?.data?.partner_id ?? ''}
            />
          ),
          [MODALS.share_qr]: (state) => (
            <ShareWithPartnerDialogQR
              open={!!state?.data}
              onClose={closeModal}
              partnerId={state?.data?.partner_id ?? ''}
            />
          ),
          [MODALS.scan_qr]: (state) => (
            <QrScannerDialog
              closeDialog={closeModal}
              closeOnSuccess
              onSuccess={(event) => onScan(event, state.data)}
            />
          ),
          [MODALS.view_partner_assets]: (state) => (
            <ModalWithClose
              open={!!state.data}
              onClose={closeModal}
              title='Partner Assets'
            >
              <OwnerAssets
                type={CLIENT_TYPE.COMPANY}
                id={state.data?.account_id}
              />
            </ModalWithClose>
          ),
          [MODALS.confirm_invite]: (state) => (
            <ConfirmPartnersInvite open={!!state?.data} company={state?.data} onClose={closeModal} />
          ),
        })}
      </CardContent>
    </Card>
  );
}

export default Partners;
