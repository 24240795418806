import { Add, Autorenew } from '@mui/icons-material';
import { Box } from '@mui/material';
import CountdownTimer from '../Timer/Countdown';

const styles = {
  '.progress-container': {
    marginTop: '0.5px'
  },
  '.progress': {
    fontSize: '2rem',
    width: '1em !important',
    height: '1em !important',

    svg: {
      fontSize: '1em'
    },
  },
};

const RepeatBadge = ({ mode, id, repeat, interval }) => (
  <Box className="node-info repeating" sx={styles}>
    <Autorenew className="repeat-icon pulse" />
    {mode === 'design' ? (
      <Box className="token">{interval?.value}{interval?.unit}</Box>
    ) : (
      <CountdownTimer id={id} offset={interval} repeat={repeat} >
        {/* <Box className="token">{interval?.value}{interval?.unit}</Box> */}
      </CountdownTimer>
    )}
  </Box>
);

export default RepeatBadge;