function SvgMinus(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<rect
				className='minus_svg__cls-1'
				x={1.81}
				y={26.72}
				width={56.39}
				height={5.78}
				rx={2.89}
				ry={2.89}
			/>
		</svg>
	);
}
export default SvgMinus;
