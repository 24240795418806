import productionOfWhiskeyReportFields from './productionOfWhiskeyReportFields';

export default function productionOfWhiskeyFields(fields = {}) {
	const {
		bourbonFields = {},
		cornFields = {},
		ryeFields = {},
		lightFields = {},
		otherSpecifyFields1 = {},
		otherSpecifyFields2 = {},
		otherSpecifyFields3 = {},
		otherSpecifyFields4 = {},
	} = fields;

	return {
		'1_Bourbon': productionOfWhiskeyReportFields(bourbonFields),
		'2_Corn': productionOfWhiskeyReportFields(cornFields),
		'3_Rye': productionOfWhiskeyReportFields(ryeFields),
		'4_Light': productionOfWhiskeyReportFields(lightFields),
		'5_Other_Specify': productionOfWhiskeyReportFields(otherSpecifyFields1),
		'6_Other_Specify': productionOfWhiskeyReportFields(otherSpecifyFields2),
		'7_Other_Specify': productionOfWhiskeyReportFields(otherSpecifyFields3),
		'8_Other_Specify': productionOfWhiskeyReportFields(otherSpecifyFields4),
	};
}
