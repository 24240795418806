import { AddCircle, CheckBox, Edit, PendingActions } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withDialogCard from '@pw/components/Cards/DialogCard';
import Instructions from '@pw/components/Instructions';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { Body3, H5, Overline } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import Facility from '@pw/components/admin/Facilities/Facility';
import LocationIcon from '@pw/components/icons/Location';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyFacilities, useCompanyUsers } from '@pw/redux/containers/User';
import styles from '@pw/styles/content.styles';
import { pl1 } from '@pw/styles/padding';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useCallback, useEffect, useState } from 'react';

function BayContent({ bays }) {
	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LocationIcon style={{ width: '12px', height: '14px' }} />
			<Body3 sx={{ ...pl1, flexGrow: 1 }}>
				<Bolder>Bays:</Bolder>
				{` ${bays}`}
			</Body3>
		</Box>
	);
}

function FacilityItem({ entry, editFacility }) {
	const { facility_status, facility_manager_name, facility_name, facility_type, bays } = entry;
	console.log('Facility', entry);

	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<Stack direction='row' spacing='0.75rem'>
					{facility_status === 'pending' && (
						<PendingActions />
					)}
					{facility_status === 'active' && <CheckBox />}
					<Box>
						<H5>{facility_name}</H5>
						<Overline>{facility_type}</Overline>
						<Body3>{facility_manager_name}</Body3>
						<BayContent bays={bays?.length} />
					</Box>
				</Stack>
			</Box>

			<Stack className='listButtons'>
				<IconCircleButton onClick={() => editFacility(entry)}>
					<Edit />
				</IconCircleButton>
			</Stack>
		</Box>
	);
}

function FacilityManagement() {
	const users = useCompanyUsers();
	const [facility, setFacility] = useState(null);
	const [target, scroll] = useScrollTarget();
	const facilities = useCompanyFacilities();


	const onClose = useCallback(() => {
		setFacility(null);
		// setList((xs = []) => {
		// 	const index = xs.findIndex(
		// 		(a) =>
		// 			a.facility_name === mutatedFacility.facility_name &&
		// 			a.facility_type === mutatedFacility.facility_type,
		// 	);
		// 	if (index >= 0) {
		// 		console.log('Updating', index, mutatedFacility);
		// 		xs[index] = { ...xs[index], ...mutatedFacility };
		// 	} else {
		// 		console.log('Inserting', mutatedFacility);
		// 		xs.push(mutatedFacility);
		// 	}
		// 	return [...xs];
		// });
		scroll();
	}, [scroll]);

	const editFacility = useCallback((s) => {
		setFacility(s);
		scroll();
	}, [scroll]);

	usePageTitleHook('Facilities');

	useEffect(() => {
		scroll();
	}, [scroll]);

	return (
		<Stack sx={styles} className='root' spacing='1.25rem'>
			<Box className='header' ref={target}>
				<H5>Facilities</H5>

				<TitledButton
					handleClick={() => editFacility({})}
					label='New'
				>
					<AddCircle height={24} width={24} />
				</TitledButton>
			</Box>

			<Instructions>You can manage the facilities within your company here.</Instructions>

			<Collapse in={!!facility} onExited={scroll}>
				<Stack spacing={2}>
					<Divider />
					<Facility
						entity={facility}
						users={users}
						onClose={onClose}
					/>
					<Divider />
				</Stack>
			</Collapse>

			{Object.entries(facilities).length === 0 && <Alert severity='warning'>No facilities configured.</Alert>}

			<Stack spacing={0} className='list'>
				{Object.entries(facilities).map(([v, f]) => (
					<FacilityItem key={v} entry={f} editFacility={editFacility} />
				))}
			</Stack>
		</Stack>
	);
}

export default withAppLayout(withDialogCard(FacilityManagement));
