import { FormikDatePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

export default function RequestID({ label }) {
	const { edit } = useContext(FormikContext);
	return (
		<>
			<FormikTextField
				name='rw_request_id'
				label={label}
				fullWidth
				disabled={edit}
				required
				autoFocus
			/>
			<FormikDatePicker name='request_created' label='Create Date' />
			<FormikDatePicker name='request_due' label='Due Date' />
		</>
	);
}
