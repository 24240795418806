import Register from '@pw/components/Auth/Register';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withEmptyLayout } from '@pw/components/Layout/EmptyLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function RegisterPage() {
	usePageTitleHook('Register');
	return (
		<Register />
	);
}

export default withEmptyLayout(withDialogCard(RegisterPage));
