import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	FormikCheckBox,
	FormikDatePicker,
	FormikMeasuresField,
	FormikNumberField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import StrengthDisplay from '@pw/components/properties/StrengthDisplay';
import { UNIT } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsUnitFormatImperial } from '@pw/redux/containers/User';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';

function LiquidLevelImpl({ title, disabled = false }) {
	const { values, setFieldValue } = useContext(FormikContext);
	const { t } = useTranslation();

	const [normalisedAbv, setNormalisedAbv] = useState(null);

	const converter = useConverter();
	const imperial = useIsUnitFormatImperial();

	useEffect(() => {
		const { bl, abv, tcf = '1.000' } = values;

		// console.log('Values', values);

		if (bl && abv && tcf) {
			const cabv = converter.to(abv, UNIT.ALC);
			const nabv = Number(cabv) * Number(tcf);
			const nbl = converter.to(bl, UNIT.BL);
			const nla = Number(nbl) * (nabv / 100.0);

			setNormalisedAbv(Number(abv) * Number(tcf));
			debounce(() => setFieldValue('la', converter.from(nla, UNIT.LA)), 50);
		} else {
			setNormalisedAbv(null);
			debounce(() => setFieldValue('la', '0'), 50);
		}
	}, [values]);

	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				disabled={disabled}
				label={<H5>{title}</H5>}
			/>

			<Collapse in={!!values?.enable}>
				<Stack spacing={2}>
					<FlexBox gap={2}>
						<FormikMeasuresField
							name='bl'
							unit={UNIT.BL}
							label={t('bulk')}
							fullWidth
							required
							disabled={disabled}
						/>
						<FormikDatePicker
							name='action_date'
							label='Action Date'
							fullWidth
							required
							disabled={disabled}
						/>
					</FlexBox>

					<FlexBox gap={2}>
						<FormikMeasuresField
							name='abv'
							unit={UNIT.ALC}
							label={t('strength')}
							fullWidth
							required
							disabled={disabled}
						/>
						<FormikNumberField
							name='tcf'
							label='TCF'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>

					<FlexBox>
						<StrengthDisplay
							label={
								imperial ? `${t('strength')} @ 60°F` : `${t('strength')} @ 20°C`
							}
							value={converter.to(normalisedAbv, UNIT.ALC)}
						/>
						<LiquidDisplay label={t('alcohol')} la={converter.to(values['la'], UNIT.LA)} />
					</FlexBox>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function BulkLiquidLevel({
	name,
	title = 'Liquid Level',
	disabled = false,
}) {
	return (
		<FormikProvider path={name}>
			<LiquidLevelImpl title={title} disabled={disabled} />
		</FormikProvider>
	);
}
