import { TextSnippetOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function DescriptionDisplay({ value }) {
	if (!value) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<TextSnippetOutlined style={{ width: '12px', height: 'auto' }} />
			<Body3>
				<Bolder>Description:</Bolder>
				&nbsp;
				{value}
			</Body3>
		</Box>
	);
}

export default DescriptionDisplay;
