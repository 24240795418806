import * as yup from 'yup';

export default function signatureFields(fields = {}) {
	const { PROPRIETOR = '', TITLE = '', Perjury = true } = fields;

	return {
		Perjury: [Perjury, yup.boolean()],
		PROPRIETOR: [PROPRIETOR, yup.string().required('PROPRIETOR is required!')],
		TITLE: [TITLE, yup.string().required('TITLE is required!')],
	};
}
