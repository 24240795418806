import { useCallback } from 'react';
import { useRef, useEffect } from 'react';

/**
 * A custom React hook that returns a debounced version of the provided callback function.
 *
 * The debounced callback will only be executed after the specified delay has elapsed since the last time it was called.
 * This is useful for reducing the frequency of expensive operations, such as API calls or DOM updates, in response to rapid user input.
 *
 * @param {function} callback - The callback function to be debounced.
 * @param {number} delay - The delay in milliseconds to wait before executing the callback.
 * @returns {function} - The debounced callback function.
 */
export function useDebouncedCallback(callback, delay) {
	const callbackRef = useRef(callback);
	const timeoutRef = useRef(null);

	// Update the callback reference when the callback changes
	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	// Return the debounced function
	const debouncedCallback = useCallback(
		(...args) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
			timeoutRef.current = setTimeout(() => {
				callbackRef.current(...args);
			}, delay);
		},
		[delay],
	);

	// Clean up the timeout if the component unmounts or delay changes
	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [delay]);

	return debouncedCallback;
}

export default useDebouncedCallback;
