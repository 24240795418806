import { FINISHED_GOODS_TYPES, SKU_TYPES } from '@pw/consts/sku';
import { UNITS } from '@pw/consts/units';

// Calculate liquid volume and alcohol content for a single SKU
const calculateLiquidForSku = (converter, amount = 0, sku) => {
	const { size, abv } = sku.properties ?? {};
	if (!size) return { };

	const totalVolume = Number(amount) * Number(size.value);
	const volumeInLiters = converter.cx(totalVolume, size.unit, UNITS.liters);
	const alcoholPercentage =
		converter.cx(abv?.value ?? 0, abv?.unit, UNITS.abv) / 100;

	return {
		bl: Number(volumeInLiters).toFixed(3),
		la: (Number(volumeInLiters) * alcoholPercentage).toFixed(3),
	};
};

// Calculate liquid volume and alcohol content for a pack of SKUs
export const calculatePackLiquid = (converter, amount, packSkus, skuList) => {
	return packSkus.reduce(
		(acc, { amount: s_amount, sku_id }) => {
			const packItemSku = skuList.find((s) => s?.sku_id === sku_id);
			if (packItemSku) {
				const totalAmount = Number(amount ?? 1) * Number(s_amount);
				const { bl, la } = calculateLiquidForSku(
					converter,
					totalAmount,
					packItemSku,
				);
				acc.bl += Number(bl);
				acc.la += Number(la);
			}
			return acc;
		},
		{ bl: 0, la: 0 },
	);
};

// Main function to calculate liquid volume and alcohol content for SKU storage
const calculateSkuStorageLiquid = (converter, amount, sku, skuList) => {
	// Only calculate for finished goods
	if (sku?.sku_type !== SKU_TYPES.FINISHED) {
		return { };
	}

	let bl = 0, la = 0;

	// Calculate based on SKU type (bottle or pack)
	if (sku?.properties?.type === FINISHED_GOODS_TYPES.bottle) {
		({ bl, la } = calculateLiquidForSku(converter, amount, sku));
	} else if (sku.properties?.type === FINISHED_GOODS_TYPES.pack) {
		const packSkus = sku.properties?.skus ?? [];
		({ bl, la } = calculatePackLiquid(converter, amount, packSkus, skuList));
	}

	// Calculate ABV (Alcohol By Volume)
	const abv = la && bl && bl > 0 ? (la / bl) * 100 : undefined;
	return { bl, la, abv };
};

export default calculateSkuStorageLiquid;
