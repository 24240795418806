import Box from '@mui/material/Box';
import { useCallback } from 'react';
import {
  useStore,
  internalsSymbol,

  getSmoothStepPath,
  getStraightPath,
  getBezierPath,

  SmoothStepEdge,
  StraightEdge,
  BezierEdge,
  StepEdge,
} from 'reactflow';

import styles from './CustomEdge.styles';
import EdgeForm from './EdgeForm';

import { EdgeLabelRenderer } from 'reactflow';

export const Position = {
  // process -> process source:source pairs
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left',
  // material -> process, process -> observation, process -> operation
  TopRight: 'top',
  BottomRight: 'right',
  BottomLeft: 'bottom',
  TopLeft: 'left',
};

// offsets of handles
const calcOffset = (node, position, handleType) => {
  const handleBounds = node[internalsSymbol].handleBounds;
  const handle = (handleBounds[handleType] || handleBounds['source']).find((h) => h.position === position);

  let offsetX = (handle.width / 2);
  let offsetY = (handle.height / 2);

  switch (position) {
    case Position.Left:
      offsetX = 1 * handle.width;
      break;
    case Position.Right:
      offsetX = -1 * handle.width;
      break;
    case Position.Top:
      offsetY = 1 * handle.height;
      break;
    case Position.Bottom:
      offsetY = -1 * handle.height;
      break;
  }
  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function CustomEdge({ id, source, target, className, selected, children, ...rest }) {
  const sourceNode = useStore(useCallback(
    (store) => store.nodeInternals.get(source)
    , [source]));
  const targetNode = useStore(useCallback(
    (store) => store.nodeInternals.get(target)
    , [target]));

  if (!(sourceNode && targetNode)) return null;

  const showOffsetForm = targetNode.data.category !== 'observation';
  const category = (sourceNode.data.category === 'process' &&
    targetNode.data.category === 'process')
    ? 'process'
    : 'default';

  const classNames = [
    category === 'process' ? 'process-edge' : 'default-edge',
    selected ? 'selected' : ''
  ].join(' ');

  const { sourcePosition, targetPosition } = rest;
  const [sourceX, sourceY] = calcOffset(sourceNode, sourcePosition, 'source');
  const [targetX, targetY] = calcOffset(targetNode, targetPosition, 'target');

  const [edgePath,
    labelX, labelY, offsetX, offsetY
  ] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetPosition,
    targetX,
    targetY,
    borderRadius: 0,
    strokeWidth: 3
  });

  // const labelX = (sourceX + targetX) / 2;
  // const labelY = (sourceY + targetY) / 2;

  return (
    <Box
      as="g"
      sx={styles}
      className={`custom-edge ${className || ''} ${classNames || ''}`}
    >
      <StepEdge
        id={id}
        edgePath={edgePath}
        {...rest}
      />
      {showOffsetForm && (
        <EdgeLabelRenderer>
          <EdgeForm
            labelX={labelX}
            labelY={labelY}
            sourcePosition={sourcePosition}
            targetPosition={targetPosition}
            id={id}
          />
          {/* <Box>
            {JSON.stringify(targetNode, null, 2)}
          </Box> */}
        </EdgeLabelRenderer>
      )}
    </Box>
  );
}

export default CustomEdge;
