const materials = [
  { label: 'Ethyl Sulfate', value: 'ethyl_sulfate' },
  { label: 'Ethylene Gas', value: 'ethylene_gas' },
  { label: 'Sulfite Liquors', value: 'sulfite_liquors' },
  {label: 'Butane Gas', value: 'butane_gas'},
  {label: 'Propane Gas', value: 'propane_gas'},
  {label: 'Other', value: 'other'}
];

export default materials;
