export const styles = {
	'&.root': {
		position: 'relative',
		borderRadius: '5px',
	},
	'.scanIcon': {
		fontSize: '1rem',
		width: '1.75em',
		height: '1.75em',
		padding: '0.125em',
	},
	'.MuiDialog-paper': {
		borderRadius: '2rem',
	},
	'.scanner': {
		position: 'relative',
		margin: 0,
		width: '100%',
		maxWidth: '600px',
		background: '#fff',
	},
	'.modalButton': {
		position: 'absolute',
		zIndex: 1,
		top: '1rem',
		padding: '1rem',
		borderRadius: '9999px',
		background: 'rgba(255,255,255, 0.2)',
		backdropFilter: 'blur(10px)',
	},
	'.dialogClose': {
		left: '1rem',
	},
	'.zoomContainer': {
		right: '1rem',
		top: '40%',
		position: 'absolute',
		zIndex: 1,
		// background: 'rgba(255,255,255, 0.2)',
		display: 'flex',
		flexDirection: 'column',
	},
	'.zoomIn': {
		position: 'relative',
	},
	'.zoomOut': {
		position: 'relative',
	},
	'.capture': {
		bottom: '1rem',
		right: '40%',
		top: 'auto',
	},
	'.toggleFacingMode': {
		right: '1rem',
	},
	containerStyle: {},
	videoContainerStyle: {},
	videoStyle: {
		objectFit: 'cover',
	},
	container: {
		width: '100%',
		paddingTop: '100%',
		overflow: 'hidden',
		position: 'relative',
	},
	video: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		overflow: 'hidden',
		position: 'absolute',
		transform: undefined,
	},
};
