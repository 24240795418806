import Loading from '@pw/components/Loading';
import LoadingContext from '@pw/context/LoadingContext';
import { usePageLoadingHook } from '@pw/redux/containers/App';

function LoadingProvider({ children }) {
	const { loading, setLoading } = usePageLoadingHook();

	return (
		<>
			<Loading loading={loading} />
			<LoadingContext.Provider value={[loading, setLoading]}>{children}</LoadingContext.Provider>
		</>
	);
}

export default LoadingProvider;
