import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { CircularProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { AssetListComponentV2, AssetListItemComponent } from '@pw/components/AssetListItem';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetSKUStorageItem from '@pw/components/SKUSelector/items/AssetSKUStorageItem';
import { Body3 } from '@pw/components/Typography';
import debounce from '@pw/utilities/debounce';
import useAlertView from '@pw/utilities/hooks/components/useAlertView';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useState } from 'react';

export default function PalletListItem({
	item,
	buttonIcon = <ArrowForward />,
	onClick = undefined,
}) {
	const [AlertView, { setAlert }] = useAlertView();

	const [showDetails, setShowDetails] = useState(false);
	const[loadAsset, { data: entity, isError, isLoading }] = useAssetLazyQuery();

	const onChange = useCallback(() => {
		setShowDetails((v) => {
			if (v) {
				return false;
			}
			// See if we have not the entity
			if (Object.keys(entity).length) {
				// Trigger a load
				debounce(() => loadAsset(item?.path ?? item?.asset_id), 50);
			}
			return true;
		});
	}, [entity, item?.asset_id, item?.path, loadAsset]);

	useEffect(() => {
		if (isError) {
			setAlert({
				title: 'Failed to load',
				content: 'Failed to load pallet contents',
				severity: 'error',
			});
		}
	}, [setAlert, isError]);

	const { child_assets = [], sku_storage = [] } = entity ?? {};

	return (
		<Stack spacing={1.25} className='listItem listCol'>
			<FlexBox>
				<Box className='listContent'>
					<AssetListItemComponent item={item} />
				</Box>
				<Stack className='listButtons'>
					<IconCircleButton onClick={onChange} disabled={isLoading}>
						{showDetails ? <ArrowDropUp /> : <ArrowDropDown />}
					</IconCircleButton>
					{onClick && (
						<IconCircleButton onClick={() => onClick(item)}>
							{buttonIcon}
						</IconCircleButton>
					)}
				</Stack>
			</FlexBox>
			{showDetails && (entity || isError || isLoading) && (
				<Collapse in={showDetails} className='listExtended'>
					<Stack spacing={1.5}>
						{child_assets.length === 0 && sku_storage.length === 0 && (
							<Stack className='listContent'>
								<Body3>Empty</Body3>
							</Stack>
						)}

						{child_assets.length > 0 && (
							<Stack className='listContent'>
								{child_assets.map((result) => (
									<AssetListComponentV2
										key={result.path ?? result.asset_id}
										item={result}
										buttonIcon={buttonIcon}
										onClick={onClick}
									/>
								))}
							</Stack>
						)}

						{sku_storage.length > 0 && (
							<FlexBox
								className='asset-container'
								justifyContent='start'
								gap='0.75rem'
							>
								{sku_storage.map((result) => (
									<AssetSKUStorageItem
										key={result.sku_entry_id}
										item={result}
										buttonIcon={buttonIcon}
										onClick={onClick}
									/>
								))}
							</FlexBox>
						)}

						<AlertView />

						{isLoading && <CircularProgress color='primary' size={30} />}
					</Stack>
				</Collapse>
			)}
		</Stack>
	);
}

//
// <Accordion
//   expanded={showDetails}
//   onChange={onChange}
//   sx={styles.pallet}
// >
//   <AccordionSummary
//     expandIcon={<ExpandMoreIcon />}
//     aria-controls="search-results-content"
//     id="search-results"
//   >
//     <AssetListComponentV2 key={item.path} item={item} />
//   </AccordionSummary>
//
//   {entity?.child_assets?.length > 0 && (
//     <AccordionDetails sx={styles.palletContent}>
//       {entity?.child_assets.map((result) => (
//         <AssetListComponentV2
//           key={result.path}
//           item={result}
//           onClick={goToItem}
//         />
//       ))}
//     </AccordionDetails>
//   )}
//   {isLoading && (
//     <AccordionDetails sx={styles.palletContent}>
//       <CircularProgress color="primary" size={30} />
//     </AccordionDetails>
//   )}
// </Accordion>
