const styles = {
  '.title': {
    fontWeight: 400,
    marginRight: '0.5rem'
  },
  '.timer': {
    whiteSpace: 'nowrap',
    fontWeight: 800,
  },
  '.icon': {
    verticalAlign: 'middle',
  },
  '.segment': {
    display: 'inline',
  },
  '.number': {
    display: 'inline-block',
    textAlign: 'right',
  },
  '.digit': {
    padding: 0,
    display: 'inline-block',
    minWidth: '1ch',
    textAlign: 'center',
  },
  '.unit': {
    display: 'inline',
    minWidth: '1.5ch',
  },
  '.textEnded': {
    display: 'inline-block',

  },
};
export default styles;
