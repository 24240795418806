import * as yup from 'yup';

export default function skuFields(sku_id = '', sku = {}, field = 'sku', fieldId='sku_id') {
	return {
		[fieldId]: [sku_id, yup.string().nullable()],
		[field]: [
			sku,
			yup
				.object({
					sku_id: yup.string(),
					sku_name: yup.string(),
					sku_type: yup.string(),
					sku_description: yup.string(),
				})
				.optional(),
		],
	};
}
