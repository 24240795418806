import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import {
	FormikNumberField,
	FormikSelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { ASSET_OPTIONS, ASSET_TYPES } from '@pw/consts/asset';
import { UNITS_OPTIONS,MASS_UNIT_OPTIONS } from '@pw/consts/units';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const {
		capacity = {},
		gross_weight = {},
		net_weight = {},
		asset_type = ASSET_TYPES.cask,
		prefix = '',
		last_number = '0',
	} = fields;

	return {
		capacity: unitField(capacity),
		gross_weight: unitField(gross_weight),
		net_weight: unitField(net_weight),
		asset_type: [asset_type, yup.string().required('Asset type is required!')],
		prefix: [prefix, yup.string().required('Prefix is required!')],
		last_number: [
			last_number,
			yup.string().required('Last Number is required!'),
		],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<H5>Asset Properties</H5>
				<FlexBox>
					<FormikSelect
						fullWidth
						options={ASSET_OPTIONS}
						label='Asset Type'
						name='asset_type'
					/>
				</FlexBox>
				<FlexBox spacing={2}>
					<FormikTextField
						label='Prefix'
						name='prefix'
						fullWidth
						required
						autoComplete='prefix'
					/>
					<FormikNumberField
						label='Last Number'
						name='last_number'
						required
						fullWidth
					/>
				</FlexBox>
				<H5>Capacity</H5>
				<FlexBox>
					<FormikUnitField label='Capacity' name='capacity' options={UNITS_OPTIONS} fullWidth />
				</FlexBox>
				<H5>Weight</H5>
				<FlexBox spacing={2}>
					<FormikUnitField
						label='Gross Weight'
						name='gross_weight'
						options={MASS_UNIT_OPTIONS}
						fullWidth
					/>
					<FormikUnitField
						label='Net Weight'
						name='net_weight'
						options={MASS_UNIT_OPTIONS}
						fullWidth
					/>
				</FlexBox>
			</Stack>
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.TRACKED),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Tracked Asset'
			skuType={SKU_TYPES.TRACKED}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, { title: 'Tracked Asset SKUs' });
