import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FormikCheckBox, FormikNumberField } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext } from 'react';

function LiquidWeightImpl({ title, disabled = false }) {
	const { values } = useContext(FormikContext);
	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				label={<H5>{title}</H5>}
				disabled={disabled}
			/>
			<Collapse in={!!values?.enable} unmountOnExit>
				<FormikNumberField
					name='weightFactor'
					label='Weight Factor'
					fullWidth
					required
					disabled={disabled}
				/>
			</Collapse>
		</Stack>
	);
}

export default function LiquidWeight({
	name,
	title = 'Weight Settings',
	...props
}) {
	return (
		<FormikProvider path={name}>
			<LiquidWeightImpl title={title} {...props} />
		</FormikProvider>
	);
}
