// export const merge = (l, r, seen = new Set()) =>
// 	l.concat(r).filter((x) => {
// 		const k = `${x?.id}${x?.path}`;
// 		return seen.has(k) ? false : seen.add(k);
// 	});

export const merge = (l, r) => {
	// Map the contents of r, and replace with matching item from l if it exists..
	return r.map((i) => {
		const f = l.find((v) => v.id === i.id && v.path === i.path);
		return f ? f : i;
	});
}

export const groupBy = (list, key) =>
	list.reduce((acc, item) => {
		const k = item[key];
		if (!acc[k]) {
			acc[k] = [];
		}
		acc[k].push(item);
		return acc;
	}, {});

export const groupArrayByProperty = (arr, key) =>
	Object.values(groupBy(arr, key));

// const downloadAll = async (url, headers, params = {}, list = []) => {
// 	const response = await fetch(url, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			...headers,
// 		},
// 		body: JSON.stringify(params),
// 	});
// 	if (!response.ok) {
// 		const text = await response.text();
// 		throw new Error(text);
// 	}
// 	const { items = [], page } = await response.json();
// 	if (page && Object.keys(page).length && items && items.length > 0) {
// 		return downloadAll(url, headers, { ...params, page }, list.concat(items));
// 	}
// 	return list.concat(items);
// };
// const downloadAllRaw = async (url, headers, params = {}) => {
// 	const response = await fetch(url, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			...headers,
// 		},
// 		body: JSON.stringify(params),
// 	});
// 	if (!response.ok) {
// 		const text = await response.text();
// 		throw new Error(text);
// 	}
// 	return response.json();
// };

// export const downloadAllGetRaw = async (url, headers, params = {}) => {
// 	const response = await fetch(`${url}${objectToQueryString(params)}`, {
// 		method: 'GET',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			...headers,
// 		},
// 	});
// 	if (!response.ok) {
// 		const text = await response.text();
// 		throw new Error(text);
// 	}
// 	return response.json();
// };

// const downloadAllGet = async (url, headers, params = {}, list = []) => {
// 	const response = await fetch(`${url}${objectToQueryString(params)}`, {
// 		method: 'GET',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			...headers,
// 		},
// 	});
// 	if (!response.ok) {
// 		const text = await response.text();
// 		throw new Error(text);
// 	}
// 	const { items = [], page } = await response.json();
// 	if (page && Object.keys(page).length && items && items.length > 0) {
// 		return downloadAllGet(url, headers, { ...params, page }, list.concat(items));
// 	}
// 	return list.concat(items);
// };

// export const saveAllFacilities = async (
// 	facilityRepository,
// 	originalUrl,
// 	headers = {},
// 	body = {},
// ) => {
// 	// Download all the facilities
// 	const list = await downloadAllGetRaw(originalUrl, headers, body);
// 	// clear db
// 	await facilityRepository.clear();
// 	// store inside db
// 	await facilityRepository.bulkAdd(
// 		Object.entries(list).map(([v, k]) => ({
// 			path: v,
// 			...k,
// 		})),
// 	);
// 	return list;
// };

// export const saveAllRequests = async (
// 	requestRepository,
// 	originalUrl,
// 	headers = {},
// ) => {
// 	// clear db
// 	await requestRepository.clear();
// 	// Download all the request
// 	const { items = [] } = await downloadAllGetRaw(originalUrl, headers, {});
// 	// store inside db
// 	await requestRepository.bulkAdd(items);
// 	return items;
// };
// export const saveAllSkuList = async (
// 	skuRepository,
// 	originalUrl,
// 	headers = {},
// ) => {
// 	// clear db
// 	await skuRepository.clear();
// 	// Download all the request
// 	const response = await fetch(`${originalUrl}${objectToQueryString({})}`, {
// 		method: 'GET',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			...headers,
// 		},
// 	});
// 	if (!response.ok) {
// 		const text = await response.text();
// 		throw new Error(text);
// 	}
// 	const list = await response.json();
// 	// store inside db
// 	await skuRepository.bulkAdd(list);
// 	return list;
// };

// export const saveAllAssets = async (
// 	assetRepository,
// 	originalUrl,
// 	headers = {},
// 	body = {},
// 	saveEmptyAssets = false,
// ) => {
// 	// clear db
// 	await assetRepository.clear();
// 	// Download all the assets

// 	const list = await downloadAllRaw(originalUrl, headers, { ...body });
// 	// download unlocated assets
// 	if (saveEmptyAssets) {
// 		const emptyList = await downloadAllRaw(originalUrl, headers, {
// 			...body,
// 			limit: 200,
// 			facility_id: '0/0/0',
// 			row: '0',
// 			level: '0',
// 		});
// 		list.push(...emptyList);
// 	}
// 	// store inside db
// 	await assetRepository.bulkAdd(list);
// 	return list;
// };
