import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { useState } from 'react';

const styles = {
	'.menu-button': {
		color: 'inherit',
		fill: 'inherit',
	},
};

function DDMenu({
	items = [],
	idSuffix = 'myMenu',
	label = 'Menu',
	openLabel = label,
	sx,
	onClick,
	...restProps
}) {
	const [anchorEl, setAnchorEl] = useState(null);

	if (!items.length) return null;

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box sx={{ ...styles, ...sx }} className='root'>
			<IconCircleButton
				className='menu-button'
				id={`button-${idSuffix}`}
				aria-controls={open ? `menu-${idSuffix}` : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				{open ? openLabel : label}
			</IconCircleButton>
			<Menu
				id={`menu-${idSuffix}`}
				aria-labelledby={`button-${idSuffix}`}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				{...restProps}
			>
				{items.map(({ key, label: l, d, e, click, ...rest }) => {
					if (d) {
						return <Divider key={key} />;
					}
					return (
						<MenuItem
							key={key}
							onClick={() => {
								if (click) {
									click();
								} else {
									onClick(key);
								}
								handleClose();
							}}
							{...rest}
							disabled={!!e}
						>
							{l}
						</MenuItem>
					);
				})}
			</Menu>
		</Box>
	);
}

export default DDMenu;
