import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikDatePicker, FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { LIQUID_UNIT_OPTIONS, UNITS } from '@pw/consts/units';
import { useIsUnitFormatImperial } from '@pw/redux/containers/User';
import { mt2 } from '@pw/styles/margins';
import styles from '@pw/styles/modal.styles';
import generateTokenId from '@pw/utilities/generateTokenId';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function PriceModal({ title, item, open, onClose }) {
	const imperial = useIsUnitFormatImperial();

	const label = item ? `Update ${title}` : `New ${title}`;

	// Properties from the vendor config..
	const { id, date, price, unit } = item ?? {};

	const changeSet = useMemo(
		() => ({
			date: [date ?? Date.now(), yup.number().required('Date required!')],
			price: [price ?? '0', yup.number().required('Price required!')],
			unit: [unit ?? (imperial ? UNITS.gallons : UNITS.liters), yup.string().required('Unit is required!')],
		}),
		[date, price, unit, imperial],
	);

	const handleSubmit = useCallback((price) => {
		onClose({
			id: id ?? generateTokenId(),
			...price
		});
	}, [id]);

	return (
		<Modal open={open} onClose={() => onClose()}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{label}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<FormikForm changeSet={changeSet} onSubmit={handleSubmit} enableReinitialize>
						<Stack spacing={1}>
							<FormikDatePicker
								sx={{ ...mt2 }}
								name='date'
								label='Price From'
								fullWidth
								required
							/>
							<FlexBox>
								<FormikTextField label='Price' name='price' fullWidth />
								<FormikSelect
									label='Unit'
									name='unit'
									options={LIQUID_UNIT_OPTIONS}
								/>
							</FlexBox>

							<Errors />

							<Box className='action-buttons'>
								<TextButton
									size='small'
									handleClick={() => onClose()}
									color='secondary'
								>
									Cancel
								</TextButton>
								<FilledButton type='submit' size='small'>
									Save
								</FilledButton>
							</Box>
						</Stack>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default PriceModal;
