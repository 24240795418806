import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import base64url from 'base64url';
import usePartnerConnectLinkProcess from './usePartnerConnectLinkProcess';

const useInviteHook = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const qq = searchParams.get('qq');

  const defaultAccount = location?.state?.account;

  // console.log('Location', location, defaultAccount, qq);

  const { account: invitedAccount, invite } = qq ? JSON.parse(base64url.decode(qq)) : {};

  const { setInvitedAccount } = usePartnerConnectLinkProcess();
  useEffect(() => {
    setInvitedAccount(invitedAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [account, setAccount] = useState(invitedAccount ?? defaultAccount ?? {});

  const [signed, setSigned] = useState(undefined);

  return {
    account,
    invite,
    setAccount,
    signed,
    setSigned,
  };
};

export default useInviteHook;
