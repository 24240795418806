import {
	ExpandLess,
	ExpandMore,
	Group,
	LogoutOutlined,
	RefreshOutlined,
	SellOutlined,
	SupervisedUserCircle,
	SwapVerticalCircleOutlined, SwitchAccount,
	VerifiedOutlined,
	WidgetsOutlined,
} from '@mui/icons-material';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@pw/components/Avatar';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { ShareWithPartnerDialogQR } from '@pw/components/Company/admin/Partners/components/ShareWithPartnerDialogQR';
import CompanySelect from '@pw/components/CompanySelect';
import Admin from '@pw/components/icons/Admin';
import Liquid from '@pw/components/icons/Liquid';
import Overview from '@pw/components/icons/Overview';
import Production from '@pw/components/icons/Production';
import QrScan3 from '@pw/components/icons/QrScan3';
import Reports from '@pw/components/icons/Reports';
import Shipments from '@pw/components/icons/Shipments';
import Sku from '@pw/components/icons/Sku';
import Warehousing from '@pw/components/icons/Warehousing';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Menu from '@pw/components/Layout/SideBar/Menu/Menu';
import MenuGroup from '@pw/components/Layout/SideBar/Menu/MenuGroup';
import SwitchCompanyModal from '@pw/components/Layout/SideBar/SwitchCompanyModal';
import { CountdownIndicator } from '@pw/components/Timer/Countdown';
import { Body2, Body3, H4, Overline } from '@pw/components/Typography';
import { COMPANY_TYPE, COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import CountdownContext from '@pw/context/CountdownContext';
import CountdownProvider from '@pw/providers/CountdownProvider';
import TimerProvider from '@pw/providers/TimerProvider';
import { useOnlineHook } from '@pw/redux/containers/App/hooks';
import {
	useAccountName,
	useAccountPermissions,
	useAccountTokenActive,
	useCurrentCompany,
} from '@pw/redux/containers/User/hooks';
import { refreshTokenThunk } from '@pw/redux/thunks/user';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import isEmpty from 'lodash.isempty';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { NetworkSwitchMobile } from '../AppHeader/NetworkSwitch';
import { MenuHeader } from './Menu/MenuHeader';

function ActiveMenu({ active, companyName, companyType, companyFeatures }) {
	const {
		companyAdmin = false,
		canMangeSensors = false,
		userAdmin = false,
		facilityAdmin = false,
		skuAdmin = false,
        policyAdmin = false
	} = useAccountPermissions();

	const { online } = useOnlineHook();

	const [switchCompany, setSwitchCompany] = useState(false);

	const features =
		companyFeatures?.warehousing ||
		companyFeatures?.production ||
		companyFeatures?.bottling;
	const warehousing = companyFeatures?.warehousing ?? false;
	const production = companyFeatures?.production ?? false;

	const menu = useMemo(
		() => ({
			overview: [
				{ tag: '/app/overview', label: 'Dashboard', disabled: !online },
				{ tag: '/app/tasks', label: 'Tasks', hidden: !active || !features },
				{ tag: '/app/sync', label: 'Sync', hidden: !active || !features },
				{ tag: '/app/explorer', label: 'Explorer', hidden: !active },
			],
			assets: [
				{ tag: '/app/cask', label: 'Casks' },
				{ tag: '/app/container', label: 'Containers', hidden: !warehousing },
				{ tag: '/app/tank', label: 'Filling Tanks', hidden: !warehousing },
				{ tag: '/app/ibc', label: 'IBCs' },
				{ tag: '/app/pallet', label: 'Pallets', hidden: !warehousing },
				{ tag: '/app/tanker', label: 'Tankers', hidden: !warehousing },
			],
			warehousing: [
				{ tag: '/app/inventory-audit', label: 'Audit' },
				{ tag: '/app/delivery', label: 'Delivery' },
				{ tag: '/app/pick', label: 'Pick', disabled: true },
				{ tag: '/app/pack', label: 'Pack' },
				{ tag: '/app/restock', label: 'Restock' },
				{ tag: '/app/change-ownership', label: 'Transfer Ownership' },
			],
			transfer_ownership: [
				{ tag: '/app/change-ownership', label: 'Transfer Ownership' },
			],
			liquid: [
				{ tag: '/app/regauge', label: 'Regauge' },
				{ tag: '/app/sample', label: 'Sample' },
				{ tag: '/app/transfer', label: 'Transfer', hidden: !features },
			],
			production: [
				{ tag: '/app/production-run', label: 'Production', disabled: true },
				{ tag: '/app/production-scheduler', label: 'Scheduler', disabled: true },
			],
			fulfilment: [
				{ tag: '/app/duty-transfer', label: 'Tax Payment' },
				{ tag: '/app/ship', label: 'Shipment' },
			],
			reporting: [
				// { tag: '/app/reports', label: 'Reports', disabled: !online },
				{
					tag: '/app/duty-submission',
					label: 'Tax Submissions',
					disabled: !online,
				},
			],
			accounting: [
				{
					tag: '/app/invoice',
					label: 'Invoice',
					disabled: !online,
				},
				{
					tag: '/app/purchase-order',
					label: 'Purchase Order',
					disabled: !online,
				},
				{
					tag: '/app/expense-report',
					label: 'Expense Report',
					disabled: !online,
				},
			],
			crm: [
				{
					disabled: !online,
					tag: '/admin/owners',
					label: 'Owners',
				},
				{
					disabled: !online,
					tag: '/admin/partners',
					label: 'Partners',
				},
			],
			things: [
				{
					disabled: !online,
					tag: '/admin/skus/cons',
					label: 'Consumables',
				},
				{
					disabled: !online,
					tag: '/admin/skus/exp',
					label: 'Expiring Materials',
				},
				{
					disabled: !online,
					tag: '/admin/skus/finished',
					label: 'Finished Goods',
				},
				{
					disabled: !online,
					tag: '/admin/skus/liquid',
					label: 'Liquids',
				},
				{
					disabled: !online,
					tag: '/admin/skus/raw',
					label: 'Raw Material',
				},
				{
					disabled: !online,
					tag: '/admin/skus/services',
					label: 'Services',
				},
				{
					disabled: !online,
					tag: '/admin/skus/tracked',
					label: 'Tracked Assets',
				},
				{
					disabled: !online,
					tag: '/admin/skus/waste',
					label: 'Waste Material',
				},
				{
					disabled: !online,
					tag: '/admin/skus/expenses',
					label: 'Expenses',
				},
			],
			marketplace: [
				{
					disabled: !online,
					tag: '/admin/skus/market',
					label: 'Sales Link',
				},
			],
			settings: [
				{
					disabled: !online,
					tag: '/admin/company',
					label: 'Information',
				},
				// { disabled: !online || !skuAdmin, tag: '/admin/sku', label: 'SKUs' },
				{
					hidden: !facilityAdmin || !features,
					disabled: !online,
					tag: '/admin/facilities',
					label: 'Facilities',
				},
                {
					hidden: !policyAdmin || !features,
					disabled: !online,
					tag: '/admin/policies',
					label: 'Policies',
				},
				{
					tag: '/admin/sensors',
					label: 'Sensors',
					disabled: !online,
					hidden: !canMangeSensors || !features,
				},
				// {
				// 	tag: '/admin/contracts',
				// 	label: 'Contracts',
				// 	disabled: true,
				// },
			],
			users: [
				{
					disabled: !online,
					tag: '/admin/users',
					label: 'Users',
				},
				{
					disabled: !online,
					tag: '/admin/teams',
					label: 'Teams',
				},
				{
					disabled: !online,
					tag: '/admin/roles',
					label: 'Roles',
				},
			],
		}),
		[
			active,
			canMangeSensors,
			companyAdmin,
			facilityAdmin,
			online,
			skuAdmin,
			userAdmin,
		],
	);

	const [showCompanyQr, setShowCompanyQr] = useState(false);

	return (
		<>
			<Stack spacing={0} className='company-header'>
				<Box className='company-name'>
					<Box className='avatar'>
						<Avatar alt={companyName} onClick={() => setShowCompanyQr(true)} />
					</Box>
					<Box className='label'>
						<Stack className='label-content' spacing={0.25}>
							<H4 className='text'>{companyName}</H4>
							<Overline>{COMPANY_TYPE_REVERSE[companyType]}</Overline>
						</Stack>
						{online && (
							<IconCircleButton onClick={() => setSwitchCompany(true)} size="small" >
								<SwitchAccount sx={{ width: '16px', height: 'auto', color: '#C6DB5C' }} />
							</IconCircleButton>
						)}
					</Box>
				</Box>
			</Stack>
			{showCompanyQr && (
				<ShareWithPartnerDialogQR
					open={showCompanyQr}
					onClose={() => setShowCompanyQr(false)}
				/>
			)}
			<Menu>
				<NetworkSwitchMobile />
				<MenuGroup
					title='Overview'
					show
					icon={<Overview />}
					options={menu.overview}
				/>

				<MenuGroup
					title='Assets'
					show={active}
					icon={<QrScan3 />}
					options={menu.assets}
				/>

				<MenuGroup
					title='Warehousing'
					show={active && warehousing}
					icon={<Warehousing />}
					options={menu.warehousing}
				/>

				<MenuGroup
					title='Transfer Ownership'
					show={active && companyType === COMPANY_TYPE.BROKER}
					icon={<Warehousing />}
					options={menu.transfer_ownership}
				/>

				<MenuGroup
					title='Liquid Operations'
					show={active}
					icon={<Liquid />}
					options={menu.liquid}
				/>

				<MenuGroup
					title='Production'
					show={active && production}
					icon={<Production />}
					options={menu.production}
				/>

				<MenuGroup
					title='Fulfilment'
					show={active && warehousing}
					icon={<Shipments />}
					options={menu.fulfilment}
				/>

				<MenuGroup
					title='Compliance'
					show={active && features}
					icon={<Reports />}
					options={menu.reporting}
				/>

				<MenuGroup
					title='Accounting'
					show={active && features}
					icon={<SwapVerticalCircleOutlined />}
					options={menu.accounting}
				/>

				<MenuGroup
					title='CRM'
					show={active && companyAdmin}
					icon={<SupervisedUserCircle />}
					options={menu.crm}
				/>

				<MenuGroup
					title='Things'
					show={active && skuAdmin && features}
					icon={<WidgetsOutlined />}
					options={menu.things}
				/>

				{/*<MenuGroup*/}
				{/*	title='Marketplace'*/}
				{/*	show={active && skuAdmin}*/}
				{/*	icon={<SellOutlined />}*/}
				{/*	options={menu.marketplace}*/}
				{/*/>*/}

				<MenuGroup
					title='Users'
					show={active && userAdmin}
					icon={<Group />}
					options={menu.users}
				/>

				<MenuGroup
					title='Company'
					show={active && companyAdmin}
					icon={<Sku />}
					options={menu.settings}
				/>
			</Menu>
			{switchCompany && (
				<SwitchCompanyModal open={switchCompany} onClose={() => setSwitchCompany(false)} />
			)}
		</>
	);
}

function RefreshToken() {
	const dispatch = useDispatch();
	const { done } = useContext(CountdownContext);
	const { i18n } = useTranslation();

	const handleRefresh = () => {
		dispatch(refreshTokenThunk({ i18n}))
			.unwrap()
			.then((response) => {
				console.log('Refresh Token Success', response);
			});
	};

	return (
		<>
			{!done && (
				<FlexBox>
					<Body3><CountdownIndicator /></Body3>
					<IconCircleButton onClick={handleRefresh} size="small" className="refresh-button">
						<RefreshOutlined sx={{ width: '16px', height: '16px' }} />
					</IconCircleButton>
				</FlexBox>
			)}
			{done && (
				<Body3>
					<Link to="/signin">Login</Link>
				</Body3>
			)}
		</>
	);
}


function AccountDisplay() {
	const currentUser = useAccountName();
	const authTokenStart = useAccountTokenActive();
	const endTime = useMemo(
		() =>  new Date(((authTokenStart ?? 0) * 1000) + (10 * 60 * 60 * 1000)),
		[authTokenStart]
	);

	console.log('Auth Token Start: ', authTokenStart, endTime);

	return (
		<Box>
			<Stack spacing={0} className='account-header'>
				<Box className='account-name'>
					<Box className='avatar'>
						<Avatar alt={currentUser} />
					</Box>
					<Box className='label'>
						<Stack className='label-content' spacing={0.25}>
							<H4 className='text'>{currentUser}</H4>

							<TimerProvider>
								<CountdownProvider end={endTime}>
									<RefreshToken />
								</CountdownProvider>
							</TimerProvider>

						</Stack>
					</Box>
				</Box>
			</Stack>
		</Box>
	);
}

function AccountMenu() {
	const navigate = useNavigate();
	const isMobile = useIsMobile();
	const { online } = useOnlineHook();

	const menu = {
		account: [
			{ tag: '/account/settings', label: 'Settings', disabled: !online },
			{
				tag: '/account/organizations',
				label: 'Organizations',
				disabled: !online,
			},
		],
	};

	return (
		<Menu>
			<MenuGroup title='Account' show icon={<Admin />} options={menu.account} />
			{isMobile && (
				<Stack className='menu-group' spacing={0.15}>
					<MenuHeader
						icon={<LogoutOutlined />}
						label='Logout'
						xs={false}
						onClick={() => navigate('/logout')}
					/>
				</Stack>
			)}
		</Menu>
	);
}

function SideBar({ active, companyName, toggleMenu }) {
	const currentCompany = useCurrentCompany();

	return (
		<Stack className={`menu ${toggleMenu ? 'menu-show' : 'menu-hide'}`}>
			<Stack className='menu-box'>
				<AccountDisplay />
				{!isEmpty(companyName) && (
					<ActiveMenu
						active={active}
						companyName={companyName}
						companyType={currentCompany?.company_type ?? COMPANY_TYPE.VIEW}
						companyFeatures={currentCompany?.company_features}
					/>
				)}
				<AccountMenu />
			</Stack>
			<Stack className='menu-footer'>
				<Body2 className='copy-right'><strong>v1.2.3&nbsp;</strong>{`© ${new Date().getFullYear()} CaskStore AG. ALL RIGHTS RESERVED`}</Body2>
			</Stack>
		</Stack>
	);
}

export default SideBar;
