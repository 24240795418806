const states = [
  { "value": "AL", "label": "Alabama (AL)" },
  { "value": "ID", "label": "Idaho (ID)" },
  { "value": "IA", "label": "Iowa (IA)" },
  { "value": "ME", "label": "Maine (ME)" },
  { "value": "MI", "label": "Michigan (MI)" },
  { "value": "MS", "label": "Mississippi (MS)" },
  { "value": "MT", "label": "Montana (MT)" },
  { "value": "MD", "label": "Montgomery Co. (MD)" },
  { "value": "NH", "label": "New Hampshire (NH)" },
  { "value": "NC", "label": "North Carolina (NC)" },
  { "value": "OH", "label": "Ohio (OH)" },
  { "value": "OR", "label": "Oregon (OR)" },
  { "value": "PA", "label": "Pennsylvania (PA)" },
  { "value": "UT", "label": "Utah (UT)" },
  { "value": "VT", "label": "Vermont (VT)" },
  { "value": "VA", "label": "Virginia (VA)" },
  { "value": "WV", "label": "West Virginia (WV)" },
  { "value": "WY", "label": "Wyoming (WY)" }
];

export default states;
