import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { useCallback, useEffect, useState } from 'react';
import withExtendedDialogCard from '@pw/components/Cards/ExtendedDialogCard';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { Body1 } from '@pw/components/Typography';
import { CONTRACT_STATUS_REVERS } from '@pw/consts/contract';
import { bn, br3 } from '@pw/styles/border';
import { bg_light_gray } from '@pw/styles/colors';
import { vh_75 } from '@pw/styles/heights';
import { pa2, pl2 } from '@pw/styles/padding';
import { useContractsLazyQuery } from '@pw/utilities/hooks/service/useContractsQuery';
import useCopyToClipboard from '@pw/utilities/hooks/logic/useCopyToClipboard';

const styles = {
	'&.grid-container': {
		...vh_75,
		...br3,
		width: '100%',
		backgroundColor: '#fff',
		...pa2,
		'.facility-management-data-grid': {
			...bn,
			'.MuiDataGrid-virtualScrollerContent.MuiDataGrid-virtualScrollerContent--overflowed, .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor':
				{
					...pl2,
				},
			'.regular-cell': {
				'&:nth-of-type(even)': {
					...bg_light_gray,
				},
			},
		},
	},
};

const PAGE_SIZE = 5;
function CompanyContracts() {
	const [refetch, { data = {}, isLoading }] = useContractsLazyQuery({});
	const { items: rows = [], totalCount = 0 } = data;
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: PAGE_SIZE,
	});
	const onPaginationModelChange = useCallback(
		(model) => {
			if (model.pageSize !== paginationModel.pageSize) {
				// page size changed will start from beginning
				refetch({
					limit: model.pageSize,
					direction: 1,
				});
			} else if (model.page > paginationModel.page) {
				// next clicked
				refetch({
					page: data?.page,
					limit: model.pageSize,
					direction: 1,
				});
			} else {
				// prev clicked
				const firstItem = rows[0];
				refetch({
					page: { id: firstItem.id, path: firstItem.path },
					limit: model.pageSize,
					direction: 2,
				});
			}
			setPaginationModel(model);
		},
		[data?.page, paginationModel.page, paginationModel.pageSize, refetch, rows],
	);
	useEffect(()=>{
		refetch(paginationModel);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const [handleCopy] = useCopyToClipboard();
	const columns = [
		{
			field: 'contract_type',
			cellClassName: 'regular-cell',
			headerName: 'Contract Name',
			editable: false,
			width: 200,
		},
		{
			field: 'install_status',
			cellClassName: 'regular-cell',
			headerName: 'Current Status',
			editable: false,
			width: 200,
			renderCell: (params) => (
				<Body1>
					{CONTRACT_STATUS_REVERS[params.value] ||
						params?.row?.install_error ||
						'Unknown'}
				</Body1>
			),
		},
		{
			field: 'address',
			cellClassName: 'regular-cell',
			headerName: 'Contract Address',
			editable: false,
			width: 300,
			renderCell: (params) => (
				<>
					<GridActionsCellItem
						icon={<ContentCopyIcon />}
						label='Copy'
						key='Copy'
						className='textPrimary'
						onClick={() => handleCopy(params.value)}
						color='inherit'
					/>
					<Body1>{params.value}</Body1>
				</>
			),
		},
	];

	return (
		<Box sx={styles} className='grid-container'>
			<DataGrid
				className='facility-management-data-grid'
				rows={rows}
				columns={columns}
				getRowId={(row) => `${row?.contract_type}-${row?.path}`}
				onClipboardCopy={(copiedString) => handleCopy(copiedString)}
				rowCount={totalCount}
				loading={isLoading}
				pageSizeOptions={[5, 10, 25]}
				paginationModel={paginationModel}
				paginationMode='server'
				onPaginationModelChange={onPaginationModelChange}
			/>
		</Box>
	);
}

export default withAppLayout(withExtendedDialogCard(CompanyContracts), {
	title: 'Company Contracts',
});
