function SvgHeart(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<g data-name='Group 148'>
				<path
					className='heart_svg__cls-1'
					d='M30 58l-.66-.29c-1.08-.48-2.08-1.11-2.98-1.88-3.32-2.65-6.27-5.22-8.98-7.82-3.91-3.67-7.5-7.73-10.66-12.07A39.74 39.74 0 011.2 25.7a19.045 19.045 0 01-.96-9.67C1.8 7.32 9.57 1.3 18.39 2.06c4.5.31 8.72 2.49 11.62 5.92 2.89-3.44 7.12-5.62 11.64-5.93C50.41 1.33 58.21 7.32 59.76 16c.53 3.28.2 6.63-.95 9.71a39.574 39.574 0 01-5.53 10.23 87.87 87.87 0 01-10.66 12.07c-2.71 2.61-5.67 5.18-9.01 7.85-.85.73-1.86 1.37-2.93 1.84l-.66.29zM16.95 5.28c-6.62 0-12.3 4.68-13.48 11.3-.43 2.66-.15 5.43.81 7.99 1.23 3.38 2.94 6.55 5.08 9.43 3.05 4.19 6.51 8.1 10.28 11.64 2.65 2.54 5.53 5.05 8.81 7.67.5.43 1.01.78 1.55 1.08.53-.29 1.04-.64 1.5-1.04 3.31-2.64 6.19-5.15 8.84-7.7 3.78-3.55 7.24-7.46 10.28-11.64 2.15-2.89 3.86-6.05 5.09-9.42.96-2.56 1.23-5.34.8-8.03A13.63 13.63 0 0041.88 5.33c-4.17.29-8.02 2.53-10.3 6l-1.32 1.91-1.81-1.84c-2.33-3.54-6.17-5.79-10.32-6.07-.4-.03-.79-.05-1.19-.05z'
					data-name='Path 2773'
				/>
			</g>
		</svg>
	);
}
export default SvgHeart;
