import {
	FormikCountrySelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';

export default function Address({ required = true }) {
	return (
		<>
			<FormikTextField title='line2' name='line2' label='Building' fullWidth />
			<FormikTextField
				title='line1'
				name='line1'
				label='Street/Number'
				fullWidth
				required={required}
			/>

			<FlexBox>
				<FormikTextField
					title='city'
					name='city'
					label='City'
					fullWidth
					required={required}
				/>
				<FormikTextField title='state' name='state' label='State' fullWidth />
			</FlexBox>

			<FlexBox>
				<FormikTextField
					title='postCode'
					name='postCode'
					label='Postal Code'
					fullWidth
					required={required}
				/>
				<FormikCountrySelect
					name='country'
					label='Country'
					fullWidth
					required={required}
				/>
			</FlexBox>
		</>
	);
}
