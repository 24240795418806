import { Box } from '@mui/material';

import { H3 } from '@pw/components/Typography';
import styles from './ToolBox.styles';
import ToggleMenuButton from './ToggleMenuButton';

const LHSBase = ({
	titleText,
	className,
	showGlobalMenu,
	setShowGlobalMenu,
}) => {

	const title = titleText && (<H3 className='toolbox_heading'>{titleText}</H3>);

	return (
		<Box className={`toolbox-root toolbar-root ${className || ''}`} sx={styles}>
			<Box className="toolbox_header">
				<ToggleMenuButton {...{ showGlobalMenu, setShowGlobalMenu }} />
				{title}
			</Box>
		</Box>
	);
};

export default LHSBase;
