export const pa0 = { padding: '0' };
export const pa1 = { padding: '.25rem' };
export const pa2 = { padding: '.5rem' };
export const pa3 = { padding: '1rem' };
export const pa4 = { padding: '2rem' };
export const pa5 = { padding: '4rem' };
export const pa6 = { padding: '8rem' };
export const pa7 = { padding: '16rem' };
export const pl0 = { paddingLeft: '0' };
export const pl1 = { paddingLeft: '.25rem' };
export const pl2 = { paddingLeft: '.5rem' };
export const pl3 = { paddingLeft: '1rem' };
export const pl4 = { paddingLeft: '2rem' };
export const pl5 = { paddingLeft: '4rem' };
export const pl6 = { paddingLeft: '8rem' };
export const pl7 = { paddingLeft: '16rem' };
export const pr0 = { paddingRight: '0' };
export const pr1 = { paddingRight: '.25rem' };
export const pr2 = { paddingRight: '.5rem' };
export const pr3 = { paddingRight: '1rem' };
export const pr4 = { paddingRight: '2rem' };
export const pr5 = { paddingRight: '4rem' };
export const pr6 = { paddingRight: '8rem' };
export const pr7 = { paddingRight: '16rem' };
export const pb0 = { paddingBottom: '0' };
export const pb1 = { paddingBottom: '.25rem' };
export const pb2 = { paddingBottom: '.5rem' };
export const pb3 = { paddingBottom: '1rem' };
export const pb4 = { paddingBottom: '2rem' };
export const pb5 = { paddingBottom: '4rem' };
export const pb6 = { paddingBottom: '8rem' };
export const pb7 = { paddingBottom: '16rem' };
export const pt0 = { paddingTop: '0' };
export const pt1 = { paddingTop: { xs: '0.125rem', md: '0.25rem' } };
export const pt2 = { paddingTop: { xs: '0.25rem', md: '0.5rem' } };
export const pt3 = { paddingTop: { xs: '0.5rem', md: '1rem' } };
export const pt4 = { paddingTop: { xs: '1rem', md: '2rem' } };
export const pt5 = { paddingTop: { xs: '2rem', md: '4rem' } };
export const pt6 = { paddingTop: { xs: '4rem', md: '8rem' } };
export const pt7 = { paddingTop: { xs: '8rem', md: '16rem' } };
export const pv0 = { paddingTop: '0', paddingBottom: '0' };
export const pv1 = { paddingTop: '.25rem', paddingBottom: '.25rem' };
export const pv2 = { paddingTop: '.5rem', paddingBottom: '.5rem' };
export const pv3 = { paddingTop: '1rem', paddingBottom: '1rem' };
export const pv4 = { paddingTop: '2rem', paddingBottom: '2rem' };
export const pv5 = { paddingTop: '4rem', paddingBottom: '4rem' };
export const pv6 = { paddingTop: '8rem', paddingBottom: '8rem' };
export const pv7 = { paddingTop: '16rem', paddingBottom: '16rem' };
export const ph0 = { paddingLeft: '0', paddingRight: '0' };
export const ph1 = { paddingLeft: '.25rem', paddingRight: '.25rem' };
export const ph2 = { paddingLeft: '.5rem', paddingRight: '.5rem' };
export const ph3 = { paddingLeft: '1rem', paddingRight: '1rem' };
export const ph4 = { paddingLeft: '2rem', paddingRight: '2rem' };
export const ph5 = { paddingLeft: '4rem', paddingRight: '4rem' };
export const ph6 = { paddingLeft: '8rem', paddingRight: '8rem' };
export const ph7 = { paddingLeft: '16rem', paddingRight: '16rem' };
