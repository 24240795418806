import productionOfBrandyReportFields from './productionOfBrandyReportFields';

export default function productionOfBrandyFields(fields = {}) {
	const {
		grapeBrandyFields = {},
		allOtherBrandyFields = {},
		naturalGrapeBrandyFields = {},
		allOtherNaturalBrandyFields = {},
		specifyFields1 = {},
		specifyFields2 = {},
		specifyFields3 = {},
		specifyFields4 = {},
	} = fields;

	return {
		'1_GrapeBrandy': productionOfBrandyReportFields(grapeBrandyFields),
		'2_AllOtherBrandy': productionOfBrandyReportFields(allOtherBrandyFields),
		'3_NaturalGrapeBrandy': productionOfBrandyReportFields(
			naturalGrapeBrandyFields,
		),
		'4_AllOtherNaturalBrandy': productionOfBrandyReportFields(
			allOtherNaturalBrandyFields,
		),
		'5_Specify': productionOfBrandyReportFields(specifyFields1),
		'6_Specify': productionOfBrandyReportFields(specifyFields2),
		'7_Specify': productionOfBrandyReportFields(specifyFields3),
		'8_Specify': productionOfBrandyReportFields(specifyFields4),
	};
}
