import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchPost from '@pw/utilities/fetchPost';

const BASE = `${APP_API_GATEWAY}/pw`;

export const search = async (request, headers = {}) =>
	fetchPost(`${BASE}/search`, headers, request);

export const searchAsset = async (request, headers = {}) =>
	fetchPost(`${BASE}/asset/search`, headers, request);

export const searchRequest = async (request, headers = {}) =>
	fetchPost(`${BASE}/request/search`, headers, request);
