import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withEmptyLayout } from '@pw/components/Layout/EmptyLayout';
import { Body2, H4 } from '@pw/components/Typography';

const styles = {
	'&.root': {
		padding: '2rem',
	},
};

function Custom404() {
	return (
		<Stack spacing={2} sx={styles} className='root'>
			<H4 color='error'>Page Not Found</H4>
			<Body2>The page you are looking for could not be found, please check the URL.</Body2>
			<Body2>
				If you think this is an error, please contact{' '}
				<Link to='mailto:support@metacask.com'>support@metacask.com.</Link>
			</Body2>
		</Stack>
	);
}

export default withEmptyLayout(withDialogCard(Custom404));
