import CircularProgress from '@mui/material/CircularProgress';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body3 } from '@pw/components/Typography';
import { ml4 } from '@pw/styles/margins';

function LoadingSmall({ message = 'Loading', mode = 'warning' }) {
	return (
		<FlexBox justifyContent='flex-start' gap={0.5}>
			<CircularProgress size={32} color={mode} />
			{message && (
				<Body3 sx={{ ...ml4 }}>
					{`${message}`}
					...
				</Body3>
			)}
		</FlexBox>
	);
}

export default LoadingSmall;
