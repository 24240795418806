/* eslint-disable react/no-array-index-key */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { Body3 } from '@pw/components/Typography';
import DragIcon from '@pw/components/icons/Drag';
import debounce from '@pw/utilities/debounce';
import { useCallback, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getFirstNDataForFieldPreview } from '../utils';

const styles = {
	'&.root': {
		width: '100%',
		height: '100%',
		maxHeight: '60vh',
		overflowY: 'auto',
		overflowX: 'hidden',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',

		'.drag-item': {
			borderRadius: '.25rem',
			borderWidth: '1px',
			padding: '.5rem',
			borderStyle: 'dashed',
			borderColor: '#b7b7b7',
			backgroundColor: '#f0f0f0',

			'.drag-item-content': {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',

				'.drag-item-title': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
					gap: '0.25rem',

					'.drag-item-icon': {
						height: '15px',
						width: '15px',
					},
					'.drag-item-text': {
						textWrap: 'nowrap',
					},
				},
			},
		},
	},

	'&.title': {
		fontSize: '13px',
		fontWeight: 'bold',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: '1.46',
		letterSpacing: 'normal',
		textAlign: 'left',
		color: '#1a3352',
	},
	'.Drag-me': {
		marginRight: '5px',
	},
	'.body': {},
	'.up-down-icon': {
		fontSize: '22px',
	},
	'.Drag-handle': {
		display: 'flex',
		alignItems: 'center',
	},
};

function DataItem({ fieldName, data, disabled, onUpsert }) {
	const [isOpen, setOpen] = useState(false);
	const toggle = useCallback(() => {
		setOpen(!isOpen);
	}, [isOpen]);

	const [{ isDragging }, drag, preview] = useDrag(() => ({
		type: 'test',
		item: { fieldName },
		canDrag: !disabled,
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult) {
				console.log('Dropped into', dropResult.fieldName, item.fieldName);
				debounce(() => onUpsert({ key: dropResult.fieldName, value: item.fieldName }), 25);
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
			handlerId: monitor.getHandlerId(),
		}),
	}));

	const opacity = isDragging ? 0.4 : 1;
	const style = { opacity };

	const cursor = useMemo(() => {
		let c = isDragging ? 'grabbing' : 'grab';
		if (disabled) {
			c = 'not-allowed';
		}
		return c;
	}, [disabled, isDragging]);

	return (
		<Box
			className='drag-item'
			py={1}
			style={{ ...style, cursor, ...{ zIndex: isDragging ? 2 : 0 } }}
			ref={preview}
		>
			<Box className='drag-item-content' ref={drag}>
				<Box className='drag-item-title'>
					<DragIcon className='drag-item-icon' />
					<Body3 className='drag-item-text'>{fieldName}</Body3>
				</Box>
				{isOpen ? (
					<ArrowDropUpIcon onClick={toggle} />
				) : (
					<ArrowDropDownIcon onClick={toggle} />
				)}
			</Box>
			{isOpen && (
				<Collapse in={isOpen}>
					<Stack spacing={0.25}>
						{data.map((d, index) => (
							<Body3 key={`${d}+${index}`}>{`${d}`}</Body3>
						))}
					</Stack>
				</Collapse>
			)}
		</Box>
	);
}

export default function PaginatedDraggableList({ rawData, mappings, upsertMapping }) {
	const { data: fileData } = rawData ?? {};
	const {
		data,
		meta: { fields: list },
	} = fileData ?? {};

	const filteredList = list.filter((i) => !Object.values(mappings).flat().includes(i));

	return (
		<Stack sx={styles} className='root'>
			{filteredList.map((field) => (
				<DataItem
					key={field}
					fieldName={field}
					onUpsert={upsertMapping}
					data={getFirstNDataForFieldPreview([field], 5, data)}
					disabled={Object.values(mappings).includes(field)}
				/>
			))}
		</Stack>
	);
}
