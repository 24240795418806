import { AddCircle, CheckBox, Edit, PendingActions } from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import { Divider } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Gateway from '@pw/components/admin/Sensors/Gateway';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withDialogCard from '@pw/components/Cards/DialogCard';
import Monitoring from '@pw/components/icons/Monitoring';
import Instructions from '@pw/components/Instructions';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyId, useCompanySensors } from '@pw/redux/containers/User/hooks';
import { refetchSensors } from '@pw/redux/thunks/sensors';
import styles from '@pw/styles/content.styles';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function SensorContent({ sensors }) {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        gap: '0.25rem',
      }}
    >
      <Monitoring style={{ width: '12px', height: '14px' }} />
      <Body3>
        <Bolder>Sensors:</Bolder>
        {` ${sensors}`}
      </Body3>
    </Box>
  );
}

function GatewayItem({ entry, onEditClick }) {
  const { status, path, sensors = [] } = entry;
  return (
    <Stack className="listItem">
      <Box className="listContent">
        <Stack direction="row" spacing="0.75rem">
          {status === 'inactive' && (
            <PendingActions />
          )}
          {status === 'active' && <CheckBox />}
          <Stack spacing={0.25}>
            <H5>{path.replace('/gateway/', '')}</H5>
            <SensorContent sensors={sensors.length} />
          </Stack>
        </Stack>
      </Box>
      <Stack className="listButtons">
        <IconCircleButton onClick={() => onEditClick(entry)}>
          <Edit />
        </IconCircleButton>
      </Stack>
    </Stack>
  );
}

function SensorsManagement() {
  const dispatch = useDispatch();
  const [target, scroll] = useScrollTarget();

	const sensors = useCompanySensors();
  const companyId = useCompanyId();

  console.log('Sensors', sensors);

	const [gateway, setGateway] = useState(null);

  const onClose = useCallback(() => {
    setGateway(null);
    scroll();
  }, [scroll]);

  const editGateway = useCallback((s) => {
    setGateway(s);
    scroll();
  }, [scroll]);

  usePageTitleHook('Sensors');

  useEffect(() => {
    scroll();
  }, [scroll]);

  const reloadSensors = () => dispatch(refetchSensors({ id: companyId }));

  return (
    <Stack sx={styles} className="root" spacing="1.25rem">
      <Box className="header" ref={target}>
        <H5>Sensors</H5>

        <TitledButton
          handleClick={() => editGateway({})}
          label="New"
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={reloadSensors}
          label="Refetch"
        >
          <CachedIcon height={24} width={24} />
        </TitledButton>
      </Box>

      <Instructions>You can manage the sensor setup here.</Instructions>

      <Collapse
        in={!!gateway}
        onExited={() => scroll()}
      >
        <Stack spacing={2}>
          <Divider />
          <Gateway
            key={JSON.stringify(gateway)}
            entity={gateway}
            onClose={onClose}
          />
          <Divider />
        </Stack>
      </Collapse>

      {sensors.length === 0 && <Alert severity="warning">No gateway configured.</Alert>}

      <Stack spacing={0} className="list">
        {sensors.map((gw) => (
          <GatewayItem key={gw.path} entry={gw} onEditClick={editGateway} />
        ))}
      </Stack>
    </Stack>
  );
}

export default withAppLayout(withDialogCard(SensorsManagement));
