import { ArrowForward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { SKU_EDIT_URL, SKU_TYPE_REVERSE } from '@pw/consts/sku';
import { useCallback } from 'react';

export function SKUListItemComponent({ item }) {
	const {
		sku_id, sku_name, sku_type, sku_description
	} = item ?? {};

	const skuType =
		SKU_TYPE_REVERSE[sku_type] ?? sku_type ?? '';

	const openSku = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			const route = SKU_EDIT_URL?.[sku_type];
			console.log('Routing to', route, sku_id);
			window.open(`${route}/${sku_id}`, '_blank').focus();
		},
		[item],
	);

	return (
		<Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: 'left' }}>
			<Stack spacing={0.5}>
				<IDDisplay value={`${sku_id} [${skuType}]`} onClick={openSku} />
				<NameDisplay name={sku_name} />
				<DescriptionDisplay value={sku_description} />
			</Stack>
		</Stack>
	);
}

function SKUListComponent({item, onClick, buttonIcon = <ArrowForward />}) {
	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<Stack spacing={0.75}>
					<SKUListItemComponent item={item} />
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onClick && (
					<IconCircleButton onClick={() => onClick(item)}>
						{buttonIcon}
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

export default SKUListComponent;
