import generalInformationFields from './generalInformationFields';
import part1Fields from './part1Fields';
import part2Fields from './part2Fields';
import part3Fields from './part3Fields';
import part4Fields from './part4Fields';
import signatureFields from './signatureFields';
import * as yup from 'yup';

export default function TTBReport_28Fields(fields = {}, company, companyname) {
	const { part2, part3, part4, signature, formUrlId = '1736916' } = fields;

	const defaultValues = {
		partyCompanyName: companyname ?? '',
		DBA: company?.account_name ?? '',
		STREET: company?.company_address.address_1 ?? '',
		CITY: company?.company_address.city ?? '',
		STATE: company?.company_address.state ?? '',
		ZIP: company?.company_address.post_code ?? '',
	};

	return {
		formUrlId: [formUrlId, yup.string()],
		general_information: generalInformationFields(defaultValues),
		part1: part1Fields(defaultValues),
		part2: part2Fields(part2),
		part3: part3Fields(part3),
		part4: part4Fields(part4),
		signature: signatureFields(signature),
	};
}
