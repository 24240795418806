import {
  LIQUID_UNIT_OPTIONS,
  TEMPERATURE_UNIT_OPTIONS,
} from '@pw/consts/measures';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';

const WaterForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const {
    quantity = { value: '', unit: '' },
    temperature = { value: '', unit: '' }
  } = nodeData;
  const changeSet = {
    quantity: unitField(quantity),
    temperature: unitField(temperature),
  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <UnitField
        label='Quantity'
        name='quantity'
        options={LIQUID_UNIT_OPTIONS}
      />

      <UnitField
        label='Temperature'
        name='temperature'
        options={TEMPERATURE_UNIT_OPTIONS}
      />
    </NodeModalForm>
  )
}

export default WaterForm;
