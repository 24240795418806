import { TIME_UNITS } from '@pw/consts/measures';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getUnitFromShort = (unit) => Object.entries(TIME_UNITS).find(([k, v]) => v === unit);

const createTimeOffset = ({ start: _start = null, value: v, unit }) => {
  const value = Number(v);
  const allowedUnits = Object.values(TIME_UNITS);

  if (!allowedUnits.includes(unit)) {
    console.error(`bad 'unit' passed to getEndTimeFromOffset: ${unit}
      must be one of ${allowedUnits.join(',')}`);
    return new Date(''); // Invalid Date
  }

  const start = _start ? new Date(_start) : new Date();
  console.log({ start });
  // convert min => minutes etc
  let unitName = capitalizeFirstLetter(
    getUnitFromShort(unit)[0]
  );
  // JS date operator uses single for Month, Year
  if (unitName === 'Months') unitName = 'Month';

  const end = new Date(start);
  // could make this safer with a switch to call the acutal fns
  end[`set${unitName}`](end[`get${unitName}`]() + value);
  console.log({ start, offset: { unit, value }, end });

  return end;
}

export default createTimeOffset;
