function SvgBackarrow(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 58.65 26.89'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M0 13.45l.87-.91 7-7.27 4.71-4.9A1.16 1.16 0 0114.09.2a1.2 1.2 0 01.23 1.85c-.41.45-.83.88-1.25 1.31l-8.41 8.75-.1.12H57.4a1.22 1.22 0 01.13 2.43h-53l.69.73 8.16 8.49c.3.32.61.63.9.95a1.21 1.21 0 01-1.73 1.7c-.59-.59-1.16-1.2-1.74-1.8l-6.77-7-4-4.15z' />
		</svg>
	);
}
export default SvgBackarrow;
