import Box from '@mui/material/Box';
import LocationIcon from '@pw/components/icons/Location';
import { Body3 } from '@pw/components/Typography';

function LocationContent({ location }) {
	const [type = '0', facility = '0', bay = '0', row = '0', level = '0'] = location ? location.split('/') : [];

	// if (!type || type === '0') return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LocationIcon style={{ width: '12px', height: '14px' }} />
			{type !== '0' && (
				<Body3>
					{`${type}/${facility}/${bay}`}
					{row && (`/${row}`)}
					{level && (`/${level}`)}
				</Body3>
			)}
			{(!type || type === '0') && (
				<Body3>
          No Location
				</Body3>
			)}
		</Box>
	);
}

export default LocationContent;
