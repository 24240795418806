import { AccountCircleOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';

function AccountDisplay({ name }) {
	if (!name) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<AccountCircleOutlined style={{ width: '12px', height: 'auto' }} />
			<Body3>{name}</Body3>
		</Box>
	);
}

export default AccountDisplay;
