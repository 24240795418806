import Box from '@mui/material/Box';
import styles from './styles';

function EmptyCard({ children }) {
	return (
		<Box sx={styles} className='empty-card'>
			{children}
		</Box>
	);
}

const withEmptyCard = (WrappedComponent) =>
	function WithEmptyCard(props) {
		return (
			<EmptyCard>
				<WrappedComponent {...props} />
			</EmptyCard>
		);
	};

export default withEmptyCard;
