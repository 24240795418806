import * as yup from 'yup';

export function unitField(params = {}, required = null) {
	const { value = '', unit = '' } = params;

	const valueSchema = required ? yup.string().required(required) : yup.string();
	const unitSchema = required ? yup.string().required('Unit is required!') : yup.string();

	return {
		value: [value, valueSchema],
		unit: [unit, unitSchema],
	};
}
