import { FormikDatePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

export default function AssetID({ label }) {
	const { edit } = useContext(FormikContext);

	return (
		<>
			<FormikTextField
				name='rw_asset_id'
				label={label}
				fullWidth
				disabled={edit}
				required
			/>
			<FormikDatePicker name='asset_checkin_date' label='Date' required />
		</>
	);
}
