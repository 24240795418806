import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';

export function ModalWithClose({ title, open, onClose, children }) {
	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{title}</H5>
					<IconButton
						onClick={() => onClose()}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: '90vh',
					}}
				>
					<Stack spacing="1.5rem">
						{children}
					</Stack>
				</Box>
			</Stack>
		</Modal>
	);
}
