function SvgTrakrLogo(props) {
	return (
		<svg
			id='TrakrLogo_svg__Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			x={0}
			y={0}
			viewBox='0 0 269.8 124.54'
			xmlSpace='preserve'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<style>
				{
					'.TrakrLogo_svg__st0{fill:#0a2b4c}.TrakrLogo_svg__st1{fill:#fd3b02}.TrakrLogo_svg__st2{fill:#f0aa29}'
				}
			</style>
			<path
				className='TrakrLogo_svg__st0'
				d='M54.26 29.7v11.69c0 .55-.45 1-1 1H36.68c-.55 0-1 .45-1 1v54.46c0 .55-.45 1-1 1H19.59c-.55 0-1-.45-1-1V43.39c0-.55-.45-1-1-1H1c-.55 0-1-.45-1-1V29.7c0-.55.45-1 1-1h52.26c.55 0 1 .44 1 1zM87.44 45.13c2.72-1.6 5.7-2.47 8.96-2.62.56-.03 1.03.44 1.03 1v16.06c0 .55-.45 1-1 1h-3.7c-4.26 0-7.46.92-9.59 2.75s-3.2 5.05-3.2 9.64v24.88c0 .55-.45 1-1 1H63.85c-.55 0-1-.45-1-1V44.09c0-.55.45-1 1-1h15.09c.55 0 1 .45 1 1v8.29c2-3.07 4.5-5.48 7.5-7.25zM105.47 55.78c2.16-4.33 5.11-7.66 8.84-9.99 3.73-2.33 7.89-3.5 12.49-3.5 3.93 0 7.38.8 10.34 2.4 2.96 1.6 5.25 3.7 6.84 6.3V44.1c0-.55.45-1 1-1h15.09c.55 0 1 .45 1 1v53.76c0 .55-.45 1-1 1h-15.09c-.55 0-1-.45-1-1v-6.89c-1.67 2.6-3.98 4.7-6.95 6.3-2.96 1.6-6.41 2.4-10.34 2.4-4.53 0-8.66-1.18-12.39-3.55-3.73-2.36-6.68-5.73-8.84-10.09-2.17-4.36-3.25-9.41-3.25-15.14.01-5.75 1.1-10.78 3.26-15.11zm34.98 5.09c-3.11-3.24-7.12-4.35-12.02-3.33-.07.01-.14.03-.2.06-5.74 2.2-8.61 6.62-8.61 13.26 0 4.26 1.18 7.64 3.55 10.14 2.36 2.5 5.25 3.75 8.64 3.75s6.28-1.23 8.64-3.7c2.36-2.46 3.55-5.83 3.55-10.09-.01-4.26-1.19-7.62-3.55-10.09zM207.25 98.43l-16.69-22.97v22.38c0 .55-.45 1-1 1h-15.09c-.55 0-1-.45-1-1V25.9c0-.55.45-1 1-1h15.09c.55 0 1 .45 1 1v39.87l16.59-22.28c.19-.25.49-.4.8-.4h18.45c.85 0 1.31.99.77 1.64l-21.29 25.7c-.31.37-.31.91 0 1.28l21.46 25.5c.55.65.09 1.64-.77 1.64h-18.53a1 1 0 01-.79-.42zM259.81 45.13c2.72-1.6 5.7-2.47 8.96-2.62.56-.03 1.03.44 1.03 1v16.06c0 .55-.45 1-1 1h-3.7c-4.26 0-7.46.92-9.59 2.75s-3.2 5.05-3.2 9.64v24.88c0 .55-.45 1-1 1h-15.09c-.55 0-1-.45-1-1V44.09c0-.55.45-1 1-1h15.09c.55 0 1 .45 1 1v8.29c2-3.07 4.5-5.48 7.5-7.25z'
			/>
			<path d='M2.01 17.1H0V0h1.66l.25.6L7.8 13.63 13.65.6l.26-.6h1.66v1.01l.03 15.09v1.01h-2.01V16.1l-.04-10.42L8.7 16.5l-.25.6H7.14l-.25-.6L2.01 5.68V17.1zM32.69 0v2.01H22.63v5.53h7.35v2.01h-7.35v5.53h10.06v2.01H20.62V0h12.07zM46.77 0h1.01v2.01h-5.51V17.1h-2.01V2.01H34.7V0h12.07zM50.45 17.1h-2.19l.6-1.41L55.4.6l.28-.6h1.31l.28.6 6.54 15.09.6 1.41h-2.19l-.28-.6-5.61-12.95-5.61 12.95-.27.6zM79.34 13.48c-.35.6-.84 1.17-1.46 1.71-1.51 1.27-3.17 1.91-4.98 1.91-2.01-.02-3.75-.81-5.21-2.39-1.54-1.68-2.31-3.73-2.31-6.16 0-2.45.78-4.51 2.34-6.19C69.16.79 70.89 0 72.9 0c1.12 0 2.24.28 3.34.83 1.44.74 2.46 1.69 3.07 2.87l-1.81.9c-.2-.4-.51-.8-.93-1.18-1.07-.94-2.3-1.41-3.67-1.41-1.41.02-2.64.58-3.7 1.68C68 5 67.39 6.62 67.39 8.55c0 1.66.48 3.13 1.43 4.4 1.09 1.43 2.45 2.14 4.07 2.14.69 0 1.37-.14 2.06-.43 1.21-.5 2.08-1.23 2.62-2.19l1.77 1.01zM82.63 17.1h-2.19l.6-1.41L87.59.6l.27-.6h1.31l.28.6 6.54 15.09.6 1.41H94.4l-.28-.6-5.6-12.95-5.61 12.95-.28.6zM107.18 4.78c0-.59-.21-1.11-.63-1.58-.72-.79-1.7-1.18-2.94-1.18-.72 0-1.4.17-2.04.5-1.02.54-1.53 1.29-1.53 2.26 0 .17.04.34.13.53.29.55 1.23 1.14 2.84 1.76 1.99.79 3.19 1.27 3.6 1.46 2.03 1.02 3.04 2.29 3.04 3.8-.02 1.07-.47 2.06-1.36 2.97-1.22 1.21-2.78 1.81-4.68 1.81-1.64 0-3.06-.46-4.25-1.38-1.19-.96-1.79-2.09-1.79-3.4h2.01c.02.59.27 1.12.75 1.61.82.77 1.91 1.16 3.27 1.16.85 0 1.65-.18 2.39-.55 1.09-.55 1.64-1.29 1.64-2.21 0-.18-.05-.37-.15-.55-.29-.57-1.24-1.17-2.87-1.81-1.98-.77-3.16-1.25-3.55-1.43-2.03-1.01-3.04-2.25-3.04-3.75 0-1.09.4-2.08 1.21-2.97C100.34.6 101.8 0 103.61 0c1.44 0 2.7.42 3.77 1.26 1.21.96 1.81 2.13 1.81 3.52h-2.01zM115.17 9.56v7.55h-2.01V0h2.01v7.55h1.11l7.24-7.24.28-.31h2.87l-1.74 1.71-6.81 6.84 6.81 6.84 1.74 1.71h-2.87l-.28-.3-7.24-7.24h-1.11zM26.4 114.99h214.67v2H26.4z' />
			<circle className='TrakrLogo_svg__st1' cx={182.01} cy={115.99} r={8.55} />
			<circle className='TrakrLogo_svg__st2' cx={243.78} cy={115.99} r={8.55} />
			<circle className='TrakrLogo_svg__st2' cx={152.54} cy={115.99} r={8.55} />
			<circle cx={71.39} cy={115.99} r={8.55} fill='#00a89d' />
			<circle className='TrakrLogo_svg__st1' cx={27.14} cy={115.99} r={8.55} />
		</svg>
	);
}
export default SvgTrakrLogo;
