import { Divider } from '@mui/material';
import GeneralInformation from './GeneralInformation';
import Signature from './Signature';
import Part1 from './Part1';
import Part2 from './Part2';
import Part3 from './Part3';
import Part4 from './Part4';

export default function TTBReport_28() {
	return (
		<>
			<Divider />
			<GeneralInformation
				name='general_information'
				title='General Information'
			/>
			<Divider />
			<Part1 name='part1' title='Part I - Bulk Ingredients' />
			<Divider />
			<Part2 name='part2' title='Part II - Finished Products' />
			<Divider />
			<Part3
				name='part3'
				title='Part III - Puerto Rican and Virgin Islands Spirits (Rum) and "Other" Imported Rum'
			/>
			<Divider />
			<Part4
				name='part4'
				title='Part IV - Processing of Beverage (Nonindustrial use) Spirits'
			/>
			<Divider />
			<Signature name='signature' title='Signature' />
			<Divider />
		</>
	);
}
