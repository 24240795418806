export function editDistance(s1, s2) {
	s1 = s1.toLowerCase();
	s2 = s2.toLowerCase();
	const costs = [];
	for (let i = 0; i <= s1.length; i++) {
		let lastValue = i;
		for (let j = 0; j <= s2.length; j++) {
			if (i === 0) {
				costs[j] = j;
			} else if (j > 0) {
				let newValue = costs[j - 1];
				if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
					newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
				}
				costs[j - 1] = lastValue;
				lastValue = newValue;
			}
		}
		if (i > 0) {
			costs[s2.length] = lastValue;
		}
	}
	return costs[s2.length];
}
/**
 * a function to check get similarity percentage of two string
 * @param {String} s1 mapping of yup validation
 * @param {String} s2 single item of data to validate
 * @returns float.
 */
function clcSimilarity(s1, s2) {
	let longer = s1;
	let shorter = s2;
	if (s1.length < s2.length) {
		longer = s2;
		shorter = s1;
	}
	const longerLength = longer.length;
	if (longerLength === 0) {
		return 1.0;
	}
	return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

export function getMaxMatchingString(str, list) {
	let result = '';
	const similarity = list.reduce((acc, value) => {
		const currentSimilarity = clcSimilarity(str, value);
		if (acc < currentSimilarity) {
			result = value;
			return currentSimilarity;
		}
		return acc;
	}, 0);
	return {
		result,
		similarity,
	};
}
