import { useContext } from 'react';
import { useNodeDataContext } from '../NodeDataContext';
import DesignerContext from '@pw/context/DesignerContext';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import ObservationForm from '../ScheduledObservation';
import { ABV_UNIT_OPTIONS } from '@pw/consts/measures';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import NodeModalForm from './NodeModalForm';
import NodeConfigForm from './NodeConfigForm';
import * as yup from 'yup';

const OgSgForm = () => {
  const { mode } = useContext(DesignerContext);
  const { nodeData = {} } = useNodeDataContext();

  const {
    og = { value: '', unit: '' },
    sg = { value: '', unit: '' },
  } = nodeData;
  const changeSet = {
    og: unitField(og),
    sg: unitField(sg),
    observation_name: ['OgSgForm', yup.string()],
  };
  const fieldNames = Object.keys(changeSet);

  return mode === 'design'
    ? (
      <NodeConfigForm changeSet={changeSet} />
    )
    : (
      <NodeModalForm changeSet={changeSet}>
        <ObservationForm
          nodeData={nodeData}
          fieldNames={fieldNames}
        >
          <FlexBox>
            <UnitField
              label='Original Gravity'
              name='og'
              options={ABV_UNIT_OPTIONS}
            />
            <UnitField
              label='Specific Gravity'
              name='sg'
              options={ABV_UNIT_OPTIONS}
            />
          </FlexBox>
        </ObservationForm>
      </NodeModalForm>
    )
}

export default OgSgForm;
