import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import Section1Report from './Section1Report';

export default function Section1({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Section1Report
						name='On_hand_first_month'
						title='On hand first month'
					/>
					<Section1Report name='Produced' title='Produced' />
					<Section1Report
						name='Recd_by_transfer_in_bond'
						title="Rec'd by transfer in bond"
					/>
					<Section1Report name='Specify_3' title='Specify 3' specify={true} />
					<Section1Report
						name='Trans_to_dlrs_users_exec_Line_7_10'
						title='Trans to dlrs users exec Line 7-10'
					/>
					<Section1Report
						name='Federal_Government_SDS_except_line_10'
						title='Federal Government SDS except line 10'
					/>
					<Section1Report
						name='Transferred_in_bond'
						title='Transferred in bond'
					/>
					<Section1Report
						name='For_use_in_mfg_of_articles_on_my_bonded_premises'
						title='For use in mfg of articles on my bonded premises'
					/>
					<Section1Report
						name='Withdrawn_for_fuel_use_AFP_Premises'
						title='Withdrawn for fuel use AFP Premises'
						withdrawn={true}
					/>
					<Section1Report name='Losses' title='Losses' />
					<Section1Report
						name='On_hand_end_of_month'
						title='On hand end of month'
					/>
					<Section1Report name='Specify_13' title='Specify 13' specify={true} />
				</>
			)}
		</FormikProvider>
	);
}
