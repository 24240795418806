import { Divider } from '@mui/material';
import GeneralInformation from './GeneralInformation';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Signature from './Signature';

export default function TTBReport_43() {
	return (
		<>
			<Divider />
			<GeneralInformation
				name='general_information'
				title='General Information'
			/>
			<Divider />
			<Section1 name='section1' title='Section I' />
			<Divider />
			<Section2 name='section2' title='Section II' />
			<Divider />
			<Section3 name='section3' title='Section III' />
			<Divider />
			<Signature name='signature' title='Signature' />
			<Divider />
		</>
	);
}
