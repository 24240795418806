import { AutofpsSelect } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { clientFields } from '@pw/components/Client/clientFields';
import Instructions from '@pw/components/Instructions';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SKUAuditConfirmModal from '@pw/components/SKUSelector/modals/SKUAuditConfirmModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { useAuditListLazyQuery } from '@pw/utilities/hooks/service/useAuditListQuery';
import useLoadingHook from '@pw/utilities/hooks/useLoadingHook';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

function propertyFields(properties = {}) {
	const { location = { enable: false } } = properties;
	return {
		location: locationFields(location),
	};
}

function AuditAssetSelector({ assets, updateAssets, location }) {
	const { enqueueSnackbar } = useSnackbar();

	const [facility_id, bay, row, level] = location ?? [];
	const [fetchAuditList, { isLoading }] = useAuditListLazyQuery();

	const [, setLoading] = useLoadingHook();

	const locationId = useMemo(() => {
		if (!facility_id && !bay) {
			return null;
		}
		if (!row) {
			return `${facility_id}/${bay}`;
		}
		if (!level) {
			return `${facility_id}/${bay}/${row}`;
		}
		return `${facility_id}/${bay}/${row}/${level}`;
	}, [bay, facility_id, level, row]);

	const handleGenerateAssets = useCallback(() => {
		setLoading(true);
		fetchAuditList({ location: locationId })
			.then((res) => updateAssets(res.map((t) => toTaggedAsset(t))))
			.catch((err) => {
				console.log('Generate audit list', err);
				enqueueSnackbar(`Failed to generate audit asset list!`, {
					variant: 'error',
				});
			})
			.finally(() => setLoading(false));
	}, [fetchAuditList, locationId, updateAssets, enqueueSnackbar]);

	if (!locationId) return;

	return (
		<Stack spacing='2rem'>
			{assets.length === 0 && (
				<Instructions>
					Generate a list of assets to audit for this location
				</Instructions>
			)}
			{assets.length > 0 && (
				<Instructions>List of assets to audit</Instructions>
			)}

			<FlexBox>
				{assets.length === 0 && (
					<>
						<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
						{!isLoading && (
							<TitledButton
								handleClick={handleGenerateAssets}
								label='Find'
							>
								<AutofpsSelect />
							</TitledButton>
						)}
						{isLoading && <LoadingSmall />}
					</>
				)}
			</FlexBox>
		</Stack>
	);
}

function InventoryAudit(props) {
	const [location, setLocation] = useState(null);

	const assetFilter = useCallback((asset) => {
		return asset?.sku_storage?.length;
	}, []);

	const [
		[sourceSkus],
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Audit Inventory',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.container,
				ASSET_TYPES.filling_tank,
			],
		},
		assetFilter,
		AssetModal: SKUAuditConfirmModal,
	});

	const [
		[destinationSkus],
		[],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Services',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
		},
		skuModalProps: { calculateSkuLiquid: true },
		SKUModal: DestinationSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties),
			client: clientFields(initialValues.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({ ...entity, skus: entity?.sku_destinations });
		},
		[initSources, initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
			sku_destinations: destinationSkus,
		}),
		[sourceSkus, sourceAssets, destinationSkus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Audit'
				requestType={REQUEST_TYPES.inventory_audit}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<FormikProvider path='properties'>
					<Location name='location' setLocation={setLocation} />
				</FormikProvider>

				<AuditAssetSelector
					location={location}
					assets={sourceAssets}
					updateAssets={initSourceAssets}
				/>

				<SourceInventory />
				<DestinationInventory />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
		</>
	);
}

export default withAppLayout(InventoryAudit, { title: 'Audit' });
