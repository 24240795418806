
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ProductionSchedule from '@pw/components/ProductionSchedule';

export default withAppLayout(() => {

	return (
		<ProductionSchedule />
	);

}, { title: null });
