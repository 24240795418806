function SvgSample(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 150.09 213.05'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M148.54 131.94c-4.56-23.6-18.13-41.35-40.33-52.78-7-3.6-10.6-9.18-10.68-16.57-.1-8.82-.09-17.79-.07-26.47 0-4.99.02-9.99 0-14.98 0-.46.06-.56.06-.56.01 0 .12-.1.63-.19 5.56-1.01 9.06-5.62 8.51-11.22-.52-5.28-4.85-9.13-10.31-9.14-15.04-.04-29-.04-42.67 0-5.4.02-9.81 3.98-10.27 9.23-.48 5.51 3.07 10.17 8.45 11.09q.8.14.8 1.03c-.04 12.68-.03 25.57-.02 38.04v2.82c0 1.65-.21 3.3-.64 4.91-1.74 6.55-6.62 10.01-11.62 12.77C14.61 94.14 1.04 116.12.04 145.26c-.63 18.47 5.56 35.39 18.4 50.3 3.51 4.07 8.42 9.02 15.4 11.14 1.25.38 2.54.69 3.78.99.56.14 1.13.27 1.69.41l71.65.03.14-.04c.15-.04.3-.09.45-.14.27-.09.53-.17.76-.21 6.1-1.04 11.17-3.57 15.07-7.53 19.06-19.34 26.18-42.31 21.17-68.26zm-73.52 67.92c-3.18 0-6.36 0-9.54-.01-6.97-.02-14.17-.03-21.26.05h-.34c-6.96 0-12.52-2.41-16.97-7.35-18.41-20.44-23.14-44.02-14.05-70.1.26-.75.41-1.01.83-1.16 10.17-3.62 19.36-5.41 28.14-5.41 4.61 0 9.1.49 13.55 1.47 4.09.9 7.45 2.1 10.28 3.69 18.34 10.28 38.56 11.44 61.83 3.55 3.06-1.04 6.03-2.31 8.82-3.78.14-.08.25-.12.31-.14.04.07.09.18.17.35 10.36 25.51 4.5 54.23-14.93 73.17-3.87 3.77-8.8 5.69-14.64 5.69h-32.2zM95.01 12.32H55.2c-.72 0-1.46 0-2.07-.17-1.01-.28-1.5-.94-1.45-1.97.05-.96.57-1.55 1.53-1.77.53-.12 1.12-.12 1.75-.12h40.08c.52 0 1 0 1.45.06 1.15.15 1.7.74 1.74 1.86.04 1.21-.49 1.84-1.72 2.05-.47.08-.98.08-1.52.07zM60.73 62.21c.06-12.27.05-25.51-.02-40.46 0-.92.12-1.08.14-1.11 0 0 .13-.15 1.03-.14 8.64.11 17.45.11 26.19 0 .86-.01 1.01.14 1.02.14.03.03.15.22.14 1.12-.04 7.38-.04 14.76-.03 22.14 0 5.84 0 11.67-.01 17.51-.04 11.58 5.25 20.12 15.71 25.37 11.67 5.85 20.99 14.56 27.71 25.89l.11.19c.09.15.22.37.29.51-.06.04-.16.11-.33.18-19.37 8.81-37.07 10.29-54.13 4.52-2.83-.96-5.54-2.37-7.87-3.63-8.47-4.57-17.85-6.8-28.59-6.8-1.05 0-2.12.02-3.2.06-6.55.26-13.24 1.35-20.38 3.33 6.09-9.62 13.78-17.02 23.43-22.58.85-.49 1.75-.95 2.61-1.4.54-.28 1.08-.56 1.62-.85 9.61-5.18 14.51-13.25 14.57-23.99z' />
		</svg>
	);
}
export default SvgSample;
