import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import {
	FormikCheckBox,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { H4, H5 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';

export default function Signature({
	name,
	title = 'Signature',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<Stack direction='row' alignItems='center'>
						<FormikCheckBox
							name='Perjury'
							label={
								<H5 style={{ maxWidth: '500px', whiteSpace: 'normal' }}>
									UNDER PENALTIES OF PERJURY, I DECLARE that I have examined
									this report and, to the best of my knowledge and belief, it is
									a true and complete report.
								</H5>
							}
							disabled={true}
						/>
					</Stack>
					<FormikTextField
						name='PROPRIETOR'
						label='Proprietor'
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='TITLE'
						label='Title'
						required
						fullWidth
						disabled={disabled}
					/>
				</>
			)}
		</FormikProvider>
	);
}
