import usedInRedistillationReportFields from './usedInRedistillationReportFields';

export default function usedInRedistillationFields(fields = {}) {
	const {
		selectKind1 = {},
		selectKind2 = {},
		selectKind3 = {},
		selectKind4 = {},
		selectKind5 = {},
		selectKind6 = {},
		selectKind7 = {},
		selectKind8 = {},
	} = fields;

	return {
		'1_': usedInRedistillationReportFields(selectKind1),
		'2_': usedInRedistillationReportFields(selectKind2),
		'3_': usedInRedistillationReportFields(selectKind3),
		'4_': usedInRedistillationReportFields(selectKind4),
		'5_': usedInRedistillationReportFields(selectKind5),
		'6_': usedInRedistillationReportFields(selectKind6),
		'7_': usedInRedistillationReportFields(selectKind7),
		'8_': usedInRedistillationReportFields(selectKind8),
	};
}
