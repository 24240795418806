import * as yup from 'yup';

function templateFields(params = {}) {
	const { saveTemplate = false, templateName = '', templateType = '' } = params;

	return {
		saveTemplate: [saveTemplate, yup.bool()],
		templateName: [
			templateName,
			yup.string().when('saveTemplate', {
				is: true,
				then: (schema) => schema.required('Template name is required!'),
				otherwise: (schema) => schema,
			}),
		],
		templateType: [
			templateType,
			yup.string().when('saveTemplate', {
				is: true,
				then: (schema) => schema.required('Template type is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
export default templateFields;
