import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import debounce from '@pw/utilities/debounce';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

const useAssetApproveHook = (assets, upsert) => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    const newAssets = assets.map((item) => ({
      ...item,
      processed: ASSET_PROCESSED_STATUS.CONFIRMED,
    }));
    debounce(() => upsert(newAssets), 50);
    enqueueSnackbar('All assets confirmed!', { variant: 'success' });
  }, [assets, upsert]);
};

export default useAssetApproveHook;
