import { CheckCircle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetTag from '@pw/components/Tasks/AssetTag';
import { H5 } from '@pw/components/Typography';
import { ASSET_STATUS } from '@pw/consts/asset';
import { REQUEST_STATUS } from '@pw/consts/requests';
import { assetApproveRejectThunk } from '@pw/redux/thunks/asset';
import { requestApproveRejectThunk } from '@pw/redux/thunks/request';
import styles from '@pw/styles/modal.styles';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

function ApproveModal({ actionKey, tasks, onClose }) {
	const dispatch = useDispatch();

	const approve = useMemo(() => actionKey === 'approve_selected', [actionKey]);
	const title = useMemo(
		() =>
			actionKey === 'approve_selected' ? 'Approve Changes' : 'Reject Changes',
		[actionKey],
	);
	const assetStatus = useMemo(
		() =>
			actionKey === 'approve_selected'
				? ASSET_STATUS.ACTIVE
				: ASSET_STATUS.REJECT_UPDATE,
		[actionKey],
	);
	const requestStatus = useMemo(
		() =>
			actionKey === 'approve_selected'
				? REQUEST_STATUS.DONE
				: REQUEST_STATUS.REJECTED,
		[actionKey],
	);

	const saveAssetChanges = useCallback(() => {
		const request_changes = tasks
			.filter((task) => !task.isAsset)
			.map((t) => ({ path: t.path, batch: t.batch }));
		const asset_changes = tasks
			.filter((task) => task.isAsset)
			.map((t) => ({ path: t.path, batch: t.batch }));

		if (asset_changes.length) {
			dispatch(assetApproveRejectThunk({ assets: asset_changes, asset_status: assetStatus }))
				.unwrap()
				.then(() => onClose(true, true));
		}

		if (request_changes.length) {
			dispatch(requestApproveRejectThunk({ requests: request_changes, request_status: requestStatus }))
				.unwrap()
				.then(() => onClose(true, true));		
		}
	}, [
		approve,
		assetStatus,
		onClose,
		requestStatus,
		tasks,
	]);

	return (
		<Modal open onClose={() => onClose(false, false)}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>{title}</H5>
					<IconButton
						onClick={() => onClose(false, false)}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<FlexBox
					className='asset-container'
					justifyContent='start'
					gap='0.75rem'
				>
					{tasks.map((task) => (
						<AssetTag
							key={task.id}
							id={task.id}
							action={task.action}
							type={task.type}
						/>
					))}
				</FlexBox>
				<FlexBox justifyContent='end'>
					<TextButton
						size='small'
						handleClick={() => onClose(false, true)}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton
						size='small'
						icon={<CheckCircle />}
						iconPosition='start'
						handleClick={() => saveAssetChanges()}
					>
						Confirm
					</FilledButton>
				</FlexBox>
			</Stack>
		</Modal>
	);
}

export default ApproveModal;

