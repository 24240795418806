import CountdownContext from '@pw/context/CountdownContext';
import TimerContext from '@pw/context/TimerContext';
import { useContext, useEffect, useState } from 'react';

export const splitTime = (total) => {
  if (total > 0) {
    return {
      time: {
        days: Math.floor(total / (1000 * 60 * 60 * 24)),
        hours: Math.floor((total / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((total / 1000 / 60) % 60),
        seconds: Math.floor((total / 1000) % 60),
      },
      finished: false,
    };
  }
  return {
    time: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    finished: true,
  };
};

function CountdownProvider({ end, children, initial }) {
  const nowTime = useContext(TimerContext);

  const [done, setDone] = useState(nowTime >= end.getTime());
  const [{
    days, hours, minutes, seconds,
  }, setState] = useState(initial ?? {});

  useEffect(() => {
    const total = end.getTime() - nowTime;
    const { time, finished } = splitTime(total);
    setState(time);
    setDone(finished);
  }, [end, nowTime]);

  return (
    // eslint-disable-next-line
    <CountdownContext.Provider value={{
      days, hours, minutes, seconds, done,
    }}
    >
      {children}
    </CountdownContext.Provider>
  );
}

export default CountdownProvider;
