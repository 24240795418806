import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import PolicyEditor from '@pw/components/PolicyEditor';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import debounce from '@pw/utilities/debounce';
import { useCallback, useMemo } from 'react';

function PackForm() {

	const [
		[policies, initializePolicies],
		PolicyComponent,
		PolicyModals,
		loadPolicies
	] = usePolicySelectorHook({
		requestType: REQUEST_TYPES.pack,
		title: 'Policies',
		PolicyModal: PolicyEditor,
	});

	const sourceProps = useMemo(
		() => ({
			title: 'Original Goods',
			filter: {
				request_types: [REQUEST_TYPES.pick],
				sku_types: [
					SKU_TYPES.CONSUMABLE,
					SKU_TYPES.FINISHED,
				],
			},
			assetFilter: (a) => false,
			// submitForm,
			SKUModal: SourceSKUModal,
		}),
		[],
	);

	const [
		[sourceSkus, , , upsertSourceSkus, removeSourceSkus],
		[],
		initSources,
		SourceInventory,
		SourceModals,
	] = useInventorySelectorHook(sourceProps);

	const onDestinationSKUAdded = useCallback(
		(sku) => {
			console.log('Destination SKU added', sku?.properties?.skus?.length, sku?.properties?.releases?.length, sku?.sku_type, sku);
			if (
				sku?.sku_type === SKU_TYPES.FINISHED &&
				(sku?.properties?.skus?.length > 0 || sku?.properties?.releases?.length > 0)
			) {
				const amount = sku?.entries?.reduce(
					(acc, curr) => acc + Number(curr.amount),
					0,
				);
				console.log('Amount', amount);

				// which skus to use
				let skus = [];

				// Find the release
				const release_ids = sku?.entries?.map((e) => e.release).filter((s) => s);
				console.log('Releases IDs', release_ids);

				// Find the release
				const release_list = sku?.properties?.releases?.filter((r) => release_ids.includes(r.id));

				console.log('Releases', release_list);

				if (release_list && release_list.length > 0) {
					// See if there is a skus list in the releases, if so, add all of them in there..
					release_list.map((r) => r.skus).flat().filter((s) => [SKU_TYPES.CONSUMABLE, SKU_TYPES.FINISHED].includes(s.sku_type)).forEach((s) => {
						const existing = skus.find((k) => k.sku_id === s.sku_id);
						if (!existing) {
							console.log(' --> adding sku', s);
							skus.push({
								...s,
								amount: Number(amount) * Number(s?.amount),
							});
						}
					});
				}

				if (skus.length === 0) {
					skus = sku?.properties?.skus.filter((s) => [SKU_TYPES.CONSUMABLE, SKU_TYPES.FINISHED].includes(s.sku_type)).map((s) => ({
						...s,
						amount: Number(amount) * Number(s?.amount),
					}));
				}

				console.log('SKUs', skus);

				debounce(() => {
					upsertSourceSkus(skus);
				}, 25);
			}

			loadPolicies(sku?.policies);
		},
		[upsertSourceSkus, loadPolicies],
	);

	const onDestinationSKURemoved = useCallback(
		(sku) => {
			console.log('Destination SKU removed', sku);
			// if (
			// 	sku?.sku_type === SKU_TYPES.FINISHED &&
			// 	sku?.properties?.skus?.length > 0
			// ) {
			// 	removeSourceSkus(sku?.properties?.skus);
			// }
		},
		[removeSourceSkus],
	);

	const destProps = useMemo(
		() => ({
			title: 'Packed Goods & Services',
			filter: {
				sku_types: [
					SKU_TYPES.FINISHED,
					SKU_TYPES.SERVICE,
				],
			},
			assetFilter: (a) => false,
			// submitForm,
			skuModalProps: { calculateSkuLiquid: true },
			SKUModal: DestinationSKUModal,
			onSKUAdded: onDestinationSKUAdded,
			onSKURemove: onDestinationSKURemoved,
		}),
		[onDestinationSKUAdded, onDestinationSKURemoved],
	);

	const [
		[destinationSkus],
		[],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook(destProps);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				skus: entity?.sku_sources,
			});
			initDestinations({
				...entity,
				skus: entity?.sku_destinations,
			});
			initializePolicies(entity?.policies);
		},
		[initSources, initDestinations, initializePolicies],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_sources: sourceSkus,
				sku_destinations: destinationSkus,
				policies,
			};
		},
		[sourceSkus, destinationSkus, policies],
	);

	return (
		<>
			<RequestForm
				requestLabel='Pack'
				requestType={REQUEST_TYPES.pack}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
			>
				<SourceInventory />
				<DestinationInventory enableFill enableUpload />

				<PolicyComponent />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
			<PolicyModals />
		</>
	);
}

export default withAppLayout(PackForm, { title: 'Pack' });
