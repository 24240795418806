import { Stack } from '@mui/material';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import Part2Report from './Part2Report';

export default function Part2({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Part2Report
						name='On_Hand_First_of_Month'
						title='On Hand First of Month'
					/>
					<Part2Report name='Bottled_or_Packaged' title='Bottled or Packaged' />
					<Part2Report name='Received' title='Received' />
					<Part2Report name='Inventory_Overages' title='Inventory Overages' />
					<Part2Report name='Transferred_in_Bond' title='Transferred in Bond' />
					<Part2Report
						name='Withdrawn_Tax_Determined'
						title='Withdrawn Tax Determined'
					/>
					<Part2Report
						name='Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use'
						title='Withdrawn Free of Tax For US Hospital Scientific or Educational Use'
					/>
					<Part2Report
						name='Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine'
						title='Withdrawn Without Payment of Tax For Addition to Wine'
					/>
					<Part2Report
						name='For_Exportation_Vessels_and_Aircraft_and_Transfer_to_CBW'
						title='For Exportation Vessels and Aircraft and Transfer to CBW'
					/>
					<Part2Report
						name='Transferred_to_Production_Account_for_Redistillation'
						title='Transferred to Production Account for Redistillation'
					/>
					<Part2Report
						name='Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples'
						title='Withdrawn for Research Development or Testing Including Gov samples'
					/>
					<Part2Report name='Destroyed' title='Destroyed' />
					<Part2Report
						name='Dumped_for_Further_Processing'
						title='Dumped for Further Processing'
					/>
					<Part2Report name='Specify_41' title='Specify 41' specify={true} />
					<Part2Report name='Specify_42' title='Specify 42' specify={true} />
					<Part2Report name='Specify_43' title='Specify 43' specify={true} />
					<Part2Report name='Recorded_Losses' title='Recorded Losses' />
					<Part2Report name='Inventory_Shortages' title='Inventory Shortages' />
					<Part2Report
						name='On_Hand_End_of_Month'
						title='On Hand End of Month'
					/>
				</>
			)}
		</FormikProvider>
	);
}
