/* Height Scale */
export const h1 = { height: '1rem' };
export const h2 = { height: '2rem' };
export const h3 = { height: '4rem' };
export const h4 = { height: '8rem' };
export const h5 = { height: '16rem' };
/* Height Percentages - Based off of height of parent */
export const h_25 = { height: '25%' };
export const h_50 = { height: '50%' };
export const h_75 = { height: '75%' };
export const h_100 = { height: '100%' };
export const min_h_100 = { minHeight: '100%' };
/* Screen Height Percentage */
export const vh_25 = { height: '25vh' };
export const vh_50 = { height: '50vh' };
export const vh_75 = { height: '75vh' };
export const vh_100 = { height: '100vh' };
export const min_vh_100 = { minHeight: '100vh' };
export const min_vh_75 = { minHeight: '75vh' };
