import * as yup from 'yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';
import { LIQUID_UNIT_OPTIONS, TEMPERATURE_UNIT_OPTIONS } from '@pw/consts/measures';
import { FormikRadioGroup } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';

const MashCoolerForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const {
    temperature_in_target = { value: '', unit: '' },
    temperature_out_target = { value: '', unit: '' },
    temperature_in_actual = { value: '', unit: '' },
    temperature_out_actual = { value: '', unit: '' },
    transfer_temperature = { value: '', unit: '' },
    flow_rate = { value: '', unit: '' },
    process_type = '',
  } = nodeData;
  const changeSet = {
    temperature_in_target: unitField(temperature_in_target),
    temperature_out_target: unitField(temperature_out_target),
    temperature_in_actual: unitField(temperature_in_actual),
    temperature_out_actual: unitField(temperature_out_actual),
    transfer_temperature: unitField(transfer_temperature),
    flow_rate: unitField(flow_rate),
    process_type: [process_type, yup.string()],
  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <Box className="section">
        <H5 className="section-header">Targets</H5>
        <Stack gap="1.5rem">
          <UnitField
            label='Temperature In (Target)'
            name='temperature_in_target'
            options={TEMPERATURE_UNIT_OPTIONS}
          />
          <UnitField
            label='Temperature Out (Target)'
            name='temperature_out_target'
            options={TEMPERATURE_UNIT_OPTIONS}
          />
        </Stack>
      </Box>
      <Stack className="section">
        <H5 className="section-header">Measured</H5>
        <UnitField
          label='Temperature In (Actual)'
          name='temperature_in_actual'
          options={TEMPERATURE_UNIT_OPTIONS}
        />
        <UnitField
          label='Temperature Out (Actual)'
          name='temperature_out_actual'
          options={TEMPERATURE_UNIT_OPTIONS}
        />
      </Stack>

      <Box
        sx={{ paddingBottom: '0.5rem', marginBottom: '0.5rem', borderBottom: '1px solid #eee' }}
      />
      <UnitField
        label='Transfer Temperature'
        name='transfer_temperature'
        options={TEMPERATURE_UNIT_OPTIONS}
      />

      <UnitField
        label='Flow Rate (per Minute)'
        name='flow_rate'
        options={LIQUID_UNIT_OPTIONS}
      />

      <FormikRadioGroup
        label='Process type'
        name='process_type'
        options={[
          { label: 'Auto', value: 'automatic' },
          { label: 'Manual', value: 'manual' }
        ]}
      />

    </NodeModalForm>
  )
}

export default MashCoolerForm;
