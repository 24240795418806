import * as yup from 'yup';

export default function reportAdjustmentFields(fields = {}) {
	const { cases = 0, casks = 0, litres = 0, reason = '' } = fields;

	return {
		cases: [cases, yup.number().required('Cases count is required!')],
		casks: [casks, yup.number().required('Casks count is required!')],
		litres: [litres, yup.number().required('Litres is required!')],
		reason: [
			reason,
			yup.string().when('litres', {
				is: (l) => l !== 0,
				then: (schema) => schema.required('Reason is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
