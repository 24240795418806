import { FilterAltOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import SearchButton from '@pw/components/Search/SearchButton';
import debounce from '@pw/utilities/debounce';
import { useCallback, useEffect, useState } from 'react';

export function SearchFieldSmall(props) {
	const { searching = false, initValue, onSearch, sx, ...rest } = props;

	const [value, setValue] = useState(initValue ?? '');

	useEffect(() => {
		debounce(() => onSearch(value), 25);
	}, [value]);

	return (
		<TextField
			size="small"
			variant="outlined"
			value={value}
			onChange={(e) => {
				setValue(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter') e.preventDefault();
			}}
			InputProps={{
				sx: { ...(sx ?? {}), fontSize: '0.75rem', paddingLeft: '0.1rem' },
				startAdornment: (
					<InputAdornment position='start'>
						<FilterAltOutlined sx={{ fontSize: '1.1rem', fontWeight: 'light' }} />
					</InputAdornment>
				)
			}}
			{...rest}
		/>
	);
}


function SearchField(props) {
	const { label, id, searching = false, disabled, initValue, onClear, onSearch, ...rest } = props;

	const [value, setValue] = useState(initValue ?? '');

	const search = useCallback(() => {
		onSearch(value);
	}, [value]);

	const clear = useCallback(() => {
		setValue('');
		onClear();
	}, [setValue]);

	return (
		<TextField
			label={label}
			id={id}
			variant='filled'
			value={value}
			disabled={disabled}
			onChange={(e) => {
				setValue(e.target.value);
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter') e.preventDefault();
			}}
			InputLabelProps={{ shrink: true }}
			InputProps={{
				disableUnderline: true,
				endAdornment: (
					<InputAdornment position='end'>
						{!disabled && (
							<FlexBox>
								{searching && <LoadingSmall message='' />}
								{!searching && (
									<>
										{value && (
											<IconCircleButton
												aria-label='Remove value'
												onClick={clear}
												edge='end'
											>
												<CloseIcon />
											</IconCircleButton>
										)}
										<SearchButton onClick={search} color='secondary' />
									</>
								)}
							</FlexBox>
						)}
					</InputAdornment>
				),
			}}
			{...rest}
		/>
	);
}

export default SearchField;
