import { Delete, Edit, TaskOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import CostDisplay from '@pw/components/properties/CostDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';

function Price ({
  item,
  onRemove,
  onEdit,
}) {
  const { date, price, unit } = item ?? {};

  return (
    <Box className='listItem'>
      <Box className='listContent'>
        <Stack spacing={0.5}>
          <DateDisplay label="From" date={date} />
          <CostDisplay label={`Price per ${unit?.toLocaleUpperCase()}`} value={price} />
        </Stack>
      </Box>

      <Stack className='listButtons'>
        {onEdit && (
          <IconCircleButton onClick={() => onEdit(item)}>
            <Edit />
          </IconCircleButton>
        )}
        {onRemove && (
          <IconCircleButton onClick={() => onRemove(item)}>
            <Delete />
          </IconCircleButton>
        )}
      </Stack>
    </Box>
  );
}

function PriceList({ items, onEdit, onRemove }) {
  return (
    <Box className='inventory-contents'>
      <Stack className='list'>
        {items.map((px) => (
          <Price
            item={px}
            onEdit={onEdit}
            onRemove={onRemove}
            key={px?.id}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default PriceList;
