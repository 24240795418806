import part4ReportFields from './part4ReportFields';

export default function part4Fields(fields = {}) {
	const {
		Alcohol_and_Neutral_Spirits_Other_than_vodka = '',
		Blended_Straight_Whiskey = '',
		Blended_Whiskey_with_Neutral_Spirits = '',
		Blended_Whiskey_with_Light_Whiskey = '',
		Blended_Light_Whiskey = '',
		Any_Other_Blends_of_100_Whiskey = '',
		Imported_Whiskey_Scotch = '',
		Imported_Whiskey_Canadian = '',
		Imported_Whiskey_Irish_and_Others = '',
		Domestic_Whiskey_Distilled_at_160_and_Under = '',
		Domestic_Whiskey_Distilled_at_Over_160 = '',
		Brandy_Distilled_at_170_and_Under = '',
		Brandy_Distilled_at_Over_170 = '',
		Spirits_Rum_Puerto_Rican = '',
		Spirits_Rum_Virgin_Islands = '',
		Rum_Domestic = '',
		Rum_Other_Imported = '',
		Gin = '',
		Vodka = '',
		Cordials_Liqueurs_and_Specialties = '',
		Cocktails_and_Mixed_Drinks = '',
		Tequila = '',
		Specify_66 = '',
	} = fields;

	return {
		Alcohol_and_Neutral_Spirits_Other_than_vodka: part4ReportFields(
			Alcohol_and_Neutral_Spirits_Other_than_vodka,
		),
		Blended_Straight_Whiskey: part4ReportFields(Blended_Straight_Whiskey),
		Blended_Whiskey_with_Neutral_Spirits: part4ReportFields(
			Blended_Whiskey_with_Neutral_Spirits,
		),
		Blended_Whiskey_with_Light_Whiskey: part4ReportFields(
			Blended_Whiskey_with_Light_Whiskey,
		),
		Blended_Light_Whiskey: part4ReportFields(Blended_Light_Whiskey),
		Any_Other_Blends_of_100_Whiskey: part4ReportFields(
			Any_Other_Blends_of_100_Whiskey,
		),
		Imported_Whiskey_Scotch: part4ReportFields(Imported_Whiskey_Scotch),
		Imported_Whiskey_Canadian: part4ReportFields(Imported_Whiskey_Canadian),
		Imported_Whiskey_Irish_and_Others: part4ReportFields(
			Imported_Whiskey_Irish_and_Others,
		),
		Domestic_Whiskey_Distilled_at_160_and_Under: part4ReportFields(
			Domestic_Whiskey_Distilled_at_160_and_Under,
		),
		Domestic_Whiskey_Distilled_at_Over_160: part4ReportFields(
			Domestic_Whiskey_Distilled_at_Over_160,
		),
		Brandy_Distilled_at_170_and_Under: part4ReportFields(
			Brandy_Distilled_at_170_and_Under,
		),
		Brandy_Distilled_at_Over_170: part4ReportFields(
			Brandy_Distilled_at_Over_170,
		),
		Spirits_Rum_Puerto_Rican: part4ReportFields(Spirits_Rum_Puerto_Rican),
		Spirits_Rum_Virgin_Islands: part4ReportFields(Spirits_Rum_Virgin_Islands),
		Rum_Domestic: part4ReportFields(Rum_Domestic),
		Rum_Other_Imported: part4ReportFields(Rum_Other_Imported),
		Gin: part4ReportFields(Gin),
		Vodka: part4ReportFields(Vodka),
		Cordials_Liqueurs_and_Specialties: part4ReportFields(
			Cordials_Liqueurs_and_Specialties,
		),
		Cocktails_and_Mixed_Drinks: part4ReportFields(Cocktails_and_Mixed_Drinks),
		Tequila: part4ReportFields(Tequila),
		Specify_66: part4ReportFields(Specify_66),
	};
}
