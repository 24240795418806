const widgetStyles = {
	'.widget': {
		margin: '1rem',
		borderRadius: '5px',
		overflow: 'hidden',
		'--shadow-color': '0deg 0% 63%',
		boxShadow: `
      0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
      0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
      1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
		border: '1px solid rgba(0, 0, 0, 0.15)',
		backgroundColor: 'white',
		color: '#41424c',
		fill: '#41424c',

		'&.stats': {
			boxShadow: 'none',
			border: '0',
			margin: '0',
		},

		'.widget-content': {
			borderBottom: '1px dashed rgba(0, 0, 0, 0.2)',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '1rem',
			gap: '1rem',

			'&.stats-content': {
				justifyContent: 'space-between',
			},

			'.widget-text': {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '0.25rem',
			},

			'.widget-text-primary': {
				fontWeight: '400',
			},

			'.widget-text-secondary': {
				fontWeight: '200',
			},
		},

		'.widget-footer': {
			backgroundColor: 'rgba(0, 0, 0, 0.02)',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			color: '#41424c',
			fill: '#41424c',
			padding: '0.75rem',
		},
	},

	'.widget-fixed': {
		width: '125px',
	},
};

export default widgetStyles;
