import Button from '@mui/material/Button';
import { usePageLoadingHook } from '@pw/redux/containers/App/hooks';

function TextButton({
	label=null,
	icon=null,
	iconPosition='start',
	size='medium',
	handleClick=()=>{},
	disabled = false,
	type='button',
	className='',
	color = 'primary',
	children=null,
	...props
}) {
	const { loading: isLoading } = usePageLoadingHook();

	return (
		<Button
			variant='text'
			disabled={disabled || !!isLoading}
			onClick={handleClick}
			className={className}
			size={size}
			{...(iconPosition === 'start' && { startIcon: icon })}
			{...(iconPosition === 'end' && { endIcon: icon })}
			type={type}
			color={color}
			{...props}
		>
			{label || children}
		</Button>
	);
}

export default TextButton;
