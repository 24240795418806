import * as yup from 'yup';

function requestIdFields(params = {}) {
	const { rw_request_id = '', request_created = Date.now(), request_due = Date.now() } = params;
	return {
		rw_request_id: [rw_request_id, yup.string().required('Request ID is required!')],
		request_created: [request_created, yup.number().required('Date is required!')],
		request_due: [request_due, yup.number().required('Due Date is required!')],
	};
}
export default requestIdFields;
