import { useMemo, createContext, useContext } from 'react';
import { timeframes } from './functions';

const TimeLineContext = createContext({});

function TimeLineContextProvider({ children, date, dateTime, viewMode, window, positioningCtxRef }) {
  const value = useMemo(() => ({
    date,
    dateTime,
    viewMode,
    window,
    timeframes: /* useMemo(() => */ timeframes(date), /* [date]) */
    positioningCtxRef,
  }), [date, viewMode, dateTime, window, positioningCtxRef]);

  return (
    <TimeLineContext.Provider value={value}>
      {children}
    </TimeLineContext.Provider>
  );
}

export default TimeLineContextProvider;

export const useTimeLineContext = () => useContext(TimeLineContext);