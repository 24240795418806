const processLiquid = (item) => {
	const { properties = {} } = item ?? {};
	const { liquid = {} } = properties;
	const { enable, level = {}, weight = {} } = liquid;
	const { enable: levelEnable } = level;
	const { enable: weightEnable } = weight;

	if (!enable) {
		return {
			...item,
			properties: {
				...properties,
				liquid: {
					enable: false,
					level: {
						enable: false,
					},
					weight: {
						enable: false,
					},
				},
				reference: undefined,
				duty_paid: false,
				imported: false,
			},
		};
	}

	return {
		...item,
		properties: {
			...properties,
			liquid: {
				...liquid,
				level: {
					...(levelEnable ? level : { enable: false }),
				},
				weight: {
					...(weightEnable ? weight : { enable: false }),
				},
			},
		},
	};
};

export default processLiquid;
