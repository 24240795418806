import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikMeasuresField, FormikNumberField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { SKU_TYPES } from '@pw/consts/sku';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import { useMemo } from 'react';
import * as yup from 'yup';

function MashSKUModal({ item, open, onClose }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_id, sku_name, sku_description, percent = '', age = '', amount } = item ?? {};

	const changeSet = useMemo(
		() => ({
			percent: [
				percent,
				yup.number().positive('Must be positive!'),
			],
			age: [
				age,
				yup.number().positive('Must be positive!'),
			],
			amount: [
				amount,
				yup.number().positive('Must be positive!'),
			],
		}),
		[percent, age],
	);

	const handleSubmit = (values) => {
		const entry = {
			sku_id: item.sku_id,
			sku_name: item.sku_name,
			sku_description: item.sku_description,
			sku_type: item.sku_type,
			...values,
		};
		console.log('Updating sku..', entry);
		onClose(entry);
	};

	return (
		<ModalWithClose title={sku_name} open={open} onClose={() => onClose()}>
			<Instructions>{sku_description}</Instructions>

			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={1}>
					{item.sku_type === SKU_TYPES.LIQUID && (
						<FlexBox>
							<FormikNumberField label='Percent' name='percent' fullWidth />
							<FormikNumberField label='Age' name='age' fullWidth />
						</FlexBox>
					)}
					{item.sku_type === SKU_TYPES.CONSUMABLE && (
						<FlexBox>
							<FormikMeasuresField label='Items' name='amount' fullWidth />
						</FlexBox>
					)}

					<AlertView />

					<Box className='action-buttons'>
						<TextButton size='small' handleClick={() => onClose()} color='secondary'>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default MashSKUModal;
