// Icons
// Ingredients
import Grain from '../icons/Grain';
import Water from '../icons/Water';
import Yeast from '../icons/Yeast';
import Peat from '../icons/Peat';
import Enzyme from '../icons/Enzyme';
import DeFoamer from '../icons/DeFoamer';
import Nutrients from '../icons/Nutrients';
import AntiMicrobial from '../icons/AntiMicrobial';
// Processes
import Steep from '../icons/Steep';
import Cask from '../icons/Cask2';
import KilnOven from '../icons/KilnOven';
import HammerMill from '../icons/HammerMill';
import ContinuousMill from '../icons/ContinuousMill';
import Mash from '../icons/Mash';
import MashCooler from '../icons/MashCooler';
import BeerWell from '../icons/BeerWell';
import LiquidSolidSeparator from '../icons/LiquidSolidSeparator';
import Washback from '../icons/Washback';
import WashStill from '../icons/WashStill';
import SpiritStill from '../icons/SpiritStill';
import Receiver from '../icons/Receiver';
import ColumnStill from '../icons/ColumnStill';
import PotStill from '../icons/PotStill';
// Observations
import Ph from '../icons/Ph';
import Temperature from '../icons/Temperature';
import Brix from '../icons/Brix';
import OG_SG from '../icons/OG_SG';
import Weight from '../icons/Weight';

// Operations
import Combine from '../icons/Combine';
import Split from '../icons/Split';
import Throttle from '../icons/Throttle';
import Start from '../icons/Start';
import Stop from '../icons/Stop';
import Route from '../icons/Route';

// Forms
// Ingredients
import WaterForm from './Diagram/NodeModal/Forms/WaterForm';
import GrainForm from './Diagram/NodeModal/Forms/GrainForm';
import YeastForm from './Diagram/NodeModal/Forms/YeastForm';
import PeatForm from './Diagram/NodeModal/Forms/PeatForm';
// Processes
import ContinuousMillForm from './Diagram/NodeModal/Forms/ContinuousMillForm';
import HammerMillForm from './Diagram/NodeModal/Forms/HammerMillForm';
import MashCoolerForm from './Diagram/NodeModal/Forms/MashCoolerForm';
import MashForm from './Diagram/NodeModal/Forms/MashForm';
import BeerWellForm from './Diagram/NodeModal/Forms/BeerWellForm';
import StillForm from './Diagram/NodeModal/Forms/StillForm';
import WashbackForm from './Diagram/NodeModal/Forms/WashbackForm';
// Observations
import PhForm from './Diagram/NodeModal/Forms/PhForm';
import BrixForm from './Diagram/NodeModal/Forms/BrixForm';
import TemperatureForm from './Diagram/NodeModal/Forms/TemperatureForm';
import OgSgForm from './Diagram/NodeModal/Forms/OgSgForm';
import WeightForm from './Diagram/NodeModal/Forms/WeightForm';
// Operations (TODO)

import { HelpCenter } from '@mui/icons-material';

// type measuredByType = 'weightt' | 'volume' | 'unit';
export let materials = [];
export let processes = [];
export let observations = [];
export let operations = [];
export const createKey = ({ type, variant = null }) =>
  [type, variant || null].filter((v) => Boolean(v)).join('-') +
  `-${new Date().getTime()}`;

export const iconFromName = (name) => {
  switch (name) {
    // Ingredients
    case 'Grain':
      return <Grain />;
    case 'Water':
      return <Water />;
    case 'Peat':
      return <Peat />;
    case 'Yeast':
      return <Yeast />;
    case 'Enzyme':
      return <Enzyme />;
    case 'DeFoamer':
      return <DeFoamer />;
    case 'Nutrients':
      return <Nutrients />;
    case 'AntiMicrobial':
      return <AntiMicrobial />;

    // Processes
    case 'KilnOven':
      return <KilnOven />;
    case 'HammerMill':
      return <HammerMill />;
    case 'ContinuousMill':
      return <ContinuousMill />;
    case 'Mash':
      return <Mash />;
    case 'MashCooler':
      return <MashCooler />;
    case 'BeerWell':
      return <BeerWell />;
    case 'LiquidSolidSeparator':
      return <LiquidSolidSeparator />;
    case 'Washback':
      return <Washback />;
    case 'WashStill':
      return <WashStill />;
    case 'SpiritStill':
      return <SpiritStill />;
    case 'ColumnStill':
      return <ColumnStill />;
    case 'PotStill':
      return <PotStill />;
    case 'Receiver':
      return <Receiver />;
    case 'Cask':
      return <Cask />;
    case 'Steep':
      return <Steep />;

    // Observations
    case 'Ph':
      return <Ph />;
    case 'Temperature':
      return <Temperature />;
    case 'Brix':
      return <Brix />;
    case 'OG_SG':
      return <OG_SG />;
    case 'Weight':
      return <Weight />;

    // Operations
    case 'Combine':
      return <Combine />;
    case 'Split':
      return <Split />;
    case 'Throttle':
      return <Throttle />;
    case 'Start':
      return <Start />;
    case 'Stop':
      return <Stop />;
    case 'Route':
      return <Route />;

    // Fallback
    default:
      return <HelpCenter />;
  }
};


export const formLookup = (form) => {
  switch (form) {
    // Materials
    // case 'CaskForm':
    // return <CaskForm />;
    case 'GrainForm':
      return <GrainForm />;
    // case 'SteepForm':
    // return <SteepForm />;
    case 'WaterForm':
      return <WaterForm />;
    case 'PeatForm':
      return <PeatForm />;
    case 'YeastForm':
      return <YeastForm />;

    // Processes
    // case 'KilnOvenForm':
    // return <KilnOvenForm />;
    case 'HammerMillForm':
      return <HammerMillForm />;
    case 'ContinuousMillForm':
      return <ContinuousMillForm />;
    case 'MashCoolerForm':
      return <MashCoolerForm />;
    case 'MashForm':
      return <MashForm />;
    case 'BeerWellForm':
      return <BeerWellForm />;
    case 'WashbackForm':
      return <WashbackForm />;
    case 'WashStillForm':
      return <StillForm />;
    case 'SpiritStillForm':
      return <StillForm />;
    case 'ColumnStillForm':
      return <StillForm />;
    case 'PotStillForm':
      return <StillForm />;
    // case 'ReceiverForm':
    // return <ReceiverForm />;

    // Observations
    case 'PhForm':
      return <PhForm />;
    case 'TemperatureForm':
      return <TemperatureForm />;
    case 'BrixForm':
      return <BrixForm />;
    case 'OgSgForm':
      return <OgSgForm />;
    case 'WeightForm':
      return <WeightForm />;

    // Operations
    // case 'CombineForm':
    //   return <CombineForm />;
    // case 'SplitForm':
    //   return <SplitForm />;
    // case 'ThrottleForm':
    //   return <ThrottleForm />;
    // case 'StartForm':
    //   return <StartForm />;
    // case 'StopForm':
    //   return <StopForm />;
    // case 'RouteForm':
    //   return <RouteForm />;

    // Fallback
    default:
      return null;
  }
};


export const sourceTypes = ['materialNode', 'processNode', 'operationNode'];
export const targetTypes = ['observationNode', 'processNode', 'operationNode']

materials = [
  // raw ingredients
  {
    category: 'material',
    icon: 'Grain',
    form: 'GrainForm',
    type: 'grain',
    label: 'Grain',
    measuredBy: 'weight',
    outputType: ['grain'],
    variants: [
      { label: 'Barley', type: 'barley' },
      { label: 'Corn', type: 'corn' },
      { label: 'Rhye', type: 'rhye' },
      { label: 'Wheat', type: 'wheat' },
    ],
  },
  {
    category: 'material',
    icon: 'Water',
    form: 'WaterForm',
    type: 'water',
    label: 'Water',
    measuredBy: 'volume',
    outputType: ['water'],
  },
  {
    category: 'material',
    icon: 'Yeast',
    form: 'YeastForm',
    type: 'yeast',
    label: 'Yeast',
    measuredBy: 'weight',
    outputType: ['yeast'],
    variants: [], //TODO ...
  },
  {
    category: 'material',
    icon: 'Peat',
    form: 'PeatForm',
    type: 'peat',
    label: 'Peat',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['peat'],
  },
  {
    category: 'material',
    icon: 'Enzyme',
    // form: 'EnzymesForm',
    type: 'enzymes',
    label: 'Enzymes',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['enzymes'],
  },
  {
    category: 'material',
    icon: 'DeFoamer',
    // form: 'DefoamerForm',
    type: 'defoamer',
    label: 'Defoamer',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['defoamer'],
  },
  {
    category: 'material',
    icon: 'Nutrients',
    // form: 'NutrientsForm',
    type: 'nutrients',
    label: 'Nutrients',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['nutrients'],
  },
  {
    category: 'material',
    icon: 'AntiMicrobial',
    // form: 'AntiMicrobialForm',
    type: 'anti-microbial',
    label: 'Anti-Microbial',
    measuredBy: 'weight',
    variants: [], // TODO...
    outputType: ['anti-microbial'],
  },
  // derived states of materials
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'grain_milled',
  //   label: 'Milled Grain',
  //   measuredBy: 'weight',
  //   variants: materialLookup('grain')?.variants,
  //   outputType: ['milled_grain'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'grain_malted',
  //   label: 'Malted Grain',
  //   measuredBy: 'weight',
  //   variants: materialLookup('grain')?.variants,
  //   outputType: ['malted_grain'],
  // },

  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'wort',
  //   label: 'Wort',
  //   measuredBy: 'weight',
  //   outputType: ['wort'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'low_wine',
  //   label: 'Low Wine',
  //   measuredBy: 'volume',
  //   outputType: ['low_wine'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit',
  //   label: 'Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'distillant',
  //   label: 'Distillant',
  //   measuredBy: 'volume',
  //   outputType: ['distillant'],

  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_new',
  //   label: 'New Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_new'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_newmake',
  //   label: 'Newmake Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_newmake'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_casked',
  //   label: 'Casked Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_casked'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_aged',
  //   label: 'Aged Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_aged'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_blended',
  //   label: 'Blended Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_blended'],
  // },
  // {
  //   category: 'material',
  //   icon: 'Cask',
  //   type: 'spirit_bottled',
  //   label: 'Bottled Spirit',
  //   measuredBy: 'volume',
  //   outputType: ['spirit_bottled'],
  // },
];

processes = [
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'milling',
  //   label: 'Milling Grain',
  //   inputType: ['grain'],
  //   outputType: ['grain_milled'],
  //   variants: materialLookup('grain')?.variants,
  //   equipment: ['mill'],
  //   transforms: [
  //     () => { },
  //   ]
  // },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'malting',
  //   label: 'Malting Grain',
  //   inputType: ['grain_milled', 'water'],
  //   outputType: ['grain_malted'],
  //   equipment: [
  //     'malting_room'
  //   ]
  // },
  {
    category: 'process',
    icon: 'Mash',
    form: 'MashForm',
    type: 'mash_tun',
    label: 'Mash Tun',
    inputType: ['grain_milled', 'water'],
    outputType: ['wort'],
  },
  {
    category: 'process',
    icon: 'MashCooler',
    form: 'MashCoolerForm',
    type: 'mash_cooler',
    label: 'Mash Cooler',
    inputType: ['wort'],
    outputType: ['wort_cooled'],
  },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'fermenting',
  //   label: 'Fermenting',
  //   inputType: ['wort', 'yeast'],
  //   outputType: ['low_wine'],
  //   equipment: [
  //     'washback'
  //   ]
  // },
  {
    type: 'still',
    category: 'process',
    icon: 'WashStill',
    form: 'WashStillForm',
    label: 'Wash Still',
    variant: 'wash',
    inputType: ['low_wine'],
    outputType: ['spirit'],
  },
  {
    type: 'still',
    category: 'process',
    icon: 'SpiritStill',
    form: 'SpiritStillForm',
    label: 'Spirit Still',
    variant: 'spirit',
    inputType: ['spirit'],
    outputType: ['distillant'],
  },
  {
    type: 'still',
    category: 'process',
    icon: 'PotStill',
    form: 'PotStillForm',
    label: 'Pot Still',
    variant: 'pot',
    inputType: ['spirit'],
    outputType: ['distillant'],
  },
  {
    type: 'still',
    category: 'process',
    icon: 'ColumnStill',
    form: 'ColumnStillForm',
    label: 'Column Still',
    variant: 'column',
    inputType: ['spirit'],
    outputType: ['distillant'],
  },
  {
    category: 'process',
    icon: 'Receiver',
    // form: 'ReceiverForm',
    type: 'receiver',
    label: 'Receiver',
    inputType: ['distillant', 'water'],
    outputType: ['spirit_newmake'],
  },
  {
    category: 'process',
    icon: 'BeerWell',
    form: 'BeerWellForm',
    type: 'beer_well',
    label: 'Beer Well',
    inputType: ['distillant', 'water', 'spirit', 'low_wine'],
    outputType: ['spirit_newmake'],
  },
  // {
  // category: 'process',
  // icon: 'Cask',
  // type: 'dilution',
  //   label: 'Dilution',
  //   inputType: ['spirit_new', 'water'],
  //   outputType: ['spirit_newmake'],
  //   equipment: [
  //     'spirit_receiver'
  //   ]
  // },
  {
    category: 'process',
    icon: 'Cask',
    // form: 'CaskForm',
    type: 'cask',
    label: 'Cask',
    inputType: ['spirit_newmake'],
    outputType: ['spirit_casked'],
  },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'maturation',
  //   label: 'Maturation',
  //   inputType: ['spirit_casked'],
  //   outputType: ['spirit_aged'],
  // },
  // {
  //   category: 'process',
  //   icon: 'Cask',
  //   type: 'blending',
  //   label: 'Blending',
  //   inputType: ['spirit_aged'],
  //   outputType: ['spirit_blended'],
  //   equipment: [
  //     'tank'
  //   ]
  // },
  {
    category: 'process',
    icon: 'HammerMill',
    form: 'HammerMillForm',
    type: 'mill_hammer',
    label: 'Hammer Mill',
    inputType: ['_steep_out', 'peat'],
    outputType: ['_mill_hammer_out'],
  },
  {
    category: 'process',
    icon: 'ContinuousMill',
    form: 'ContinuousMillForm',
    type: 'mill_continous',
    label: 'Continuous Mill',
    inputType: ['_mill_hammer_out'],
    outputType: ['_mill_continous_out'],
  },
  //
  //
  //
  {
    category: 'process',
    icon: 'Steep',
    // form: 'SteepForm',
    type: 'steep',
    label: 'Steeps',
    measuredBy: 'weight',
    outputType: ['_steep_out'],
  },
  {
    category: 'process',
    icon: 'KilnOven',
    // form: 'KilnOvenForm',
    type: 'kiln_oven',
    label: 'Kiln Oven',
    inputType: ['_steep_out', 'peat'],
    outputType: ['_kiln_oven_out'],
  },
  {
    category: 'process',
    icon: 'Washback',
    form: 'WashbackForm',
    type: 'washback',
    label: 'Washback',
    inputType: ['wort', 'yeast'],
    outputType: ['_washback_out'],
  },
  {
    category: 'process',
    icon: 'LiquidSolidSeparator',
    // form: 'SeparatorForm',
    type: 'separator',
    label: 'Liquid/Solid Separator',
    inputType: ['wort', 'mash', '_other'],
    outputType: ['_liquid', '_solid'],
  },
];

observations = [
  {
    category: 'observation',
    icon: 'Ph',
    form: 'PhForm',
    type: 'ph',
    label: 'PH',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'Temperature',
    form: 'TemperatureForm',
    type: 'temperature',
    label: 'Temperature',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'Brix',
    form: 'BrixForm',
    type: 'brix',
    label: 'Brix',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'OG_SG',
    form: 'OgSgForm',
    type: 'og_sg',
    label: 'OG/SG',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'observation',
    icon: 'Weight',
    form: 'WeightForm',
    type: 'weight',
    label: 'Weight',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
];

operations = [
  {
    category: 'operation',
    icon: 'Combine',
    // form: 'CombineForm',
    type: 'combine',
    label: 'Combine',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Split',
    // form: 'SplitForm',
    type: 'split',
    label: 'Split',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Throttle',
    // form: 'ThrottleForm',
    type: 'throttle',
    label: 'Throttle',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Start',
    // form: 'StartForm',
    type: 'start',
    label: 'Start',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Stop',
    // form: 'StopForm',
    type: 'stop',
    label: 'Stop',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
  {
    category: 'operation',
    icon: 'Route',
    // form: 'RouteForm',
    type: 'route',
    label: 'Route',
    // inputType: ['wort', 'yeast'],
    // outputType: ['_washback_out'],
  },
];
