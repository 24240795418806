import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikForm,
	FormikSelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import WithHelp from '@pw/components/Instructions/WithHelp';
import { H5 } from '@pw/components/Typography';
import {
	ABV_UNIT_OPTIONS,
	LIQUID_UNIT_OPTIONS,
	MASS_UNIT_OPTIONS,
	TEMPERATURE_UNIT_OPTIONS,
	TIME_UNIT_OPTIONS,
} from '@pw/consts/units';
import { useAccount, usePreferredUnits } from '@pw/redux/containers/User/hooks';
import { updateProfileThunk } from '@pw/redux/thunks/user';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function Settings() {
	const dispatch = useDispatch();

	const pref_units = usePreferredUnits();
	const { account_name = '', account_email = '', notifications } = useAccount();
	const { events = true, marketing = true } = notifications ?? {};
	const { liq, mas, alc, tmp, tim, bl, la } = pref_units ?? {};

	const changeSet = useMemo(
		() => ({
			name: [account_name, yup.string()],
			email: [
				account_email,
				yup.string().email('Invalid email!').required('Email required!'),
			],
			notify_events: [events, yup.boolean()],
			notify_marketing: [marketing, yup.boolean()],
			liq: [liq, yup.string()],
			mas: [mas, yup.string()],
			alc: [alc, yup.string()],
			tmp: [tmp, yup.string()],
			tim: [tim, yup.string()],
			bl: [bl, yup.string()],
			la: [la, yup.string()],
		}),
		[
			account_name,
			account_email,
			events,
			marketing,
			liq,
			mas,
			alc,
			tmp,
			tim,
			bl,
			la,
		],
	);

	const handleSubmit = (values) => dispatch(updateProfileThunk(values));

	return (
		<Stack spacing='1.25rem'>
			<H5>Profile</H5>

			<FormikForm
				// readonly
				changeSet={changeSet}
				onSubmit={(values) => handleSubmit(values)}
			>
				<Stack className='form' spacing='1.2rem'>
					<FormikTextField
						name='name'
						label='Full name'
						autoComplete='name'
						disabled
					/>
					<FormikTextField
						name='email'
						label='Contact email'
						autoComplete='email'
						disabled
					/>
					<FormikSelect
						label='Volume Unit'
						name='liq'
						options={LIQUID_UNIT_OPTIONS}
					/>
					<FormikSelect
						label='Mass Unit'
						name='mas'
						options={MASS_UNIT_OPTIONS}
					/>
					<FormikSelect
						label='Strength Unit'
						name='alc'
						options={ABV_UNIT_OPTIONS}
					/>
					<FormikSelect
						label='Temperature Unit'
						name='tmp'
						options={TEMPERATURE_UNIT_OPTIONS}
					/>
					<FormikSelect
						label='Time Unit'
						name='tim'
						options={TIME_UNIT_OPTIONS}
					/>
					<FormikSelect
						label='Bulk Volume Unit'
						name='bl'
						options={LIQUID_UNIT_OPTIONS}
					/>
					<FormikSelect
						label='Alcohol Volume Unit'
						name='la'
						options={LIQUID_UNIT_OPTIONS}
					/>
					<WithHelp instruction='Uncheck this box if you do not want to receive any system notifications (updates to assets, assignments etc.)'>
						<FormikCheckBox
							name='notify_events'
							label='Platform notifications'
						/>
					</WithHelp>

					<WithHelp instruction='Uncheck this box if you do not want to receive any marketing communications from us or our carefully curated partners.'>
						<FormikCheckBox
							name='notify_marketing'
							label='Marketing communications'
						/>
					</WithHelp>

					<Errors />

					<Box className='action-buttons'>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</Stack>
	);
}

export default Settings;
