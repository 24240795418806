const Yeast = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <path d="M19.688 42.0004C19.4277 41.9337 19.163 41.8768 18.906 41.7981C16.8913 41.1845 15.5471 39.8753 15.0297 37.8343C14.5004 35.7464 15.0636 33.9143 16.62 32.417C16.8574 32.1884 16.8902 32.0221 16.7918 31.7214C14.3243 24.2117 19.3107 16.154 27.1321 14.9968C30.0863 14.5593 32.8666 15.0712 35.4654 16.539C35.7016 16.6725 35.8493 16.6681 36.0768 16.5128C37.2952 15.6859 38.9107 15.8511 39.9486 16.8814C40.9833 17.9095 41.1594 19.5348 40.3369 20.7511C40.1718 20.9961 40.1761 21.1514 40.3161 21.3964C41.2097 22.9561 41.7479 24.6339 41.9283 26.4254C41.9393 26.5315 41.9765 26.6354 42.0016 26.7404V28.7092C41.9765 28.8404 41.9557 28.9728 41.925 29.1029C41.6691 30.189 41.5291 31.3189 41.1343 32.3525C38.6547 38.8461 31.6536 42.1634 25.0375 40.0251C24.7947 39.9464 24.6711 40.0076 24.4775 40.1651C23.8891 40.6431 23.2919 41.132 22.6313 41.4929C22.1424 41.7609 21.5485 41.8364 21.0016 41.9971H19.6891L19.688 42.0004ZM28.8525 16.0687C28.555 16.0928 28.0071 16.1081 27.4679 16.1857C20.014 17.2696 15.5274 24.8428 18.148 31.9051C18.369 32.5012 18.3274 32.6106 17.8177 33.012C16.3827 34.1418 15.803 35.6261 16.1924 37.399C16.5752 39.1414 17.7094 40.269 19.4507 40.6518C21.2258 41.0423 22.7068 40.4517 23.8366 39.02C24.2369 38.5125 24.3397 38.472 24.9446 38.6973C26.7711 39.3765 28.6535 39.6106 30.585 39.3546C39.0496 38.2303 43.5 28.754 38.9632 21.5221C38.611 20.96 38.6241 20.8418 39.0911 20.3562C39.8119 19.607 39.801 18.4596 39.066 17.7411C38.3485 17.0378 37.2165 17.0454 36.4804 17.7575C36.0068 18.2157 35.8755 18.2289 35.3122 17.8745C33.4146 16.6801 31.3408 16.0917 28.8525 16.0676V16.0687Z" fill="currentColor" />
    <path d="M11.7296 0C12.3957 0.135625 13.0716 0.231875 13.7246 0.412344C18.5721 1.75656 21.8271 6.28578 21.593 11.3334C21.3612 16.3209 17.696 20.5231 12.7818 21.4244C10.4073 21.8597 8.13336 21.5392 5.97101 20.4641C5.74789 20.3525 5.59914 20.3547 5.38914 20.5034C4.13133 21.397 2.48414 21.2548 1.41992 20.1797C0.352422 19.1023 0.232109 17.4694 1.1093 16.1973C1.18914 16.0814 1.23945 15.8594 1.18258 15.7456C0.577734 14.5206 0.183984 13.2388 0.0582031 11.8759C0.0538281 11.8256 0.0188281 11.7775 -0.00195312 11.7294C-0.00195312 11.1278 -0.00195312 10.5262 -0.00195312 9.92469C0.0232031 9.80766 0.0560156 9.69172 0.0735156 9.57359C0.731953 5.13188 3.17211 2.12188 7.38195 0.581875C8.19242 0.285469 9.07508 0.189219 9.92492 0C10.5265 0 11.128 0 11.7296 0ZM20.403 10.8084C20.3604 10.3469 20.3374 9.88203 20.2729 9.42266C19.5477 4.21313 14.5624 0.510781 9.3868 1.33219C2.72039 2.39094 -0.809141 9.6425 2.47539 15.5291C2.80352 16.1164 2.7882 16.2236 2.31242 16.695C1.59164 17.4103 1.56867 18.5544 2.25883 19.2817C2.9643 20.0233 4.13023 20.0528 4.87508 19.3473C5.44164 18.8103 5.48648 18.8048 6.17883 19.1898C12.5248 22.7128 20.3801 18.0775 20.4009 10.8084H20.403Z" fill="currentColor" />
    <path d="M35.2292 7.76797C35.1559 9.78375 34.165 11.6158 32.1295 12.785C30.1093 13.9444 28.0389 13.8941 25.999 12.7773C25.8393 12.6898 25.6851 12.5509 25.59 12.3978C25.4314 12.143 25.5178 11.8892 25.7311 11.6978C25.9793 11.4736 26.2571 11.5053 26.5262 11.6661C27.2809 12.1178 28.0968 12.3639 28.9751 12.3814C31.505 12.4317 33.745 10.4783 33.9648 8.04031C34.2142 5.27422 32.4598 3.01781 29.7232 2.5825C27.3039 2.1975 24.8604 3.88515 24.2917 6.35375C24.0139 7.55797 24.1725 8.71515 24.7587 9.80781C24.8036 9.89203 24.8561 9.97297 24.8987 10.0583C25.0825 10.4203 24.9971 10.7736 24.6865 10.9431C24.3912 11.105 24.05 11.0066 23.8432 10.6795C23.3707 9.92812 23.0743 9.10781 22.9792 8.22843C22.5636 4.39484 25.4598 1.23609 29.3054 1.31593C32.5484 1.38265 35.216 4.14437 35.2314 7.77015L35.2292 7.76797Z" fill="currentColor" />
    <path d="M10.5065 39.5585C10.3041 39.5279 10.1007 39.5071 9.90055 39.4655C9.45977 39.3747 9.2268 39.0904 9.29243 38.7415C9.36462 38.3597 9.68399 38.1793 10.1423 38.2624C11.1245 38.4396 12.0257 37.9135 12.2991 37.0024C12.5201 36.2696 12.2751 35.493 11.6746 35.0162C11.0774 34.5426 10.256 34.4868 9.59868 34.8751C8.9479 35.259 8.59571 36.0126 8.71383 36.7629C8.73243 36.8843 8.77508 37.0068 8.76633 37.1249C8.74337 37.453 8.55087 37.651 8.23149 37.7068C7.91977 37.7615 7.64852 37.546 7.55008 37.1905C7.18368 35.8594 7.88477 34.3501 9.16337 33.7146C10.3096 33.1448 11.6582 33.3307 12.5988 34.1882C13.5581 35.0632 13.8665 36.3932 13.3885 37.5985C12.9226 38.7743 11.784 39.541 10.5076 39.5574L10.5065 39.5585Z" fill="currentColor" />
    <path d="M29.0914 31.4571C30.0145 31.4636 30.7988 32.1593 30.9147 33.0736C30.9705 33.5133 30.7845 33.8272 30.4302 33.8918C30.0397 33.9629 29.7761 33.7474 29.6963 33.2727C29.6361 32.9118 29.4359 32.6919 29.0706 32.6985C28.7042 32.7039 28.5183 32.9325 28.4636 33.2935C28.3958 33.7419 28.1333 33.9519 27.7516 33.895C27.4027 33.8425 27.2091 33.5538 27.2419 33.1349C27.3152 32.1975 28.1398 31.4505 29.0914 31.4571Z" fill="currentColor" />
    <path d="M25.38 27.6503C25.38 28.066 25.1733 28.3285 24.8507 28.3733C24.5357 28.4171 24.2611 28.2071 24.1769 27.8571C24.155 27.7641 24.1714 27.6328 24.1146 27.5825C23.9341 27.4239 23.7263 27.1822 23.5327 27.1866C23.3402 27.1899 23.1433 27.4425 22.9661 27.6033C22.918 27.6471 22.9322 27.7586 22.9136 27.8385C22.8294 28.1994 22.5724 28.4083 22.2541 28.3744C21.9161 28.3394 21.6996 28.0638 21.7072 27.6799C21.7313 26.3641 23.1958 25.5066 24.3804 26.1246C25.0125 26.4538 25.3407 26.9908 25.38 27.6514V27.6503Z" fill="currentColor" />
    <path d="M12.6431 10.7451C12.642 11.1203 12.4429 11.3696 12.1159 11.4079C11.7637 11.4484 11.4826 11.2515 11.4312 10.8611C11.3787 10.464 11.2015 10.1971 10.7968 10.2015C10.3889 10.2059 10.2259 10.4957 10.1734 10.8807C10.1231 11.245 9.8584 11.4386 9.51934 11.4101C9.1945 11.3828 8.967 11.1214 8.95934 10.767C8.93856 9.8198 9.82997 8.95246 10.8176 8.95902C11.7998 8.96558 12.6475 9.79355 12.6431 10.7451Z" fill="currentColor" />


  </svg>
);

export default Yeast;
