import transactionsReportFields from './transactionsWhiskeyReportFields';

export default function transactionsFields(fields = {}) {
	const {
		taxPaymentFields = {},
		useOfUSFields = {},
		hospital_Scientific_orEducationalUseFields = {},
		exportFields = {},
		transfertoForeignTradeZoneFields = {},
		transfertoCMBWFields = {},
		useAsSuppliesOnVesselsOrAircraftFields = {},
		useInWineProductionFields = {},
		enteredInProcessingAccountFields = {},
		enteredForTransferInBondFields = {},
		enteredInStorageAccountFields = {},
		withdrawnForResearchDevelopmentOrTestingFields = {},
		specifyFields = {},
		producedFields = {},
		receivedForRedistillationFields = {},
		specify2Fields = {},
		physicalInventoryEOQ_ReceivedForRedistillationFields = {},
		physicalInventoryEOQ_UnfinishedSpiritsFields = {},
	} = fields;
	return {
		'1_TaxPayment': transactionsReportFields(taxPaymentFields),
		'2_UseOfUS': transactionsReportFields(useOfUSFields),
		'3_HospitalScientificorEducationalUse': transactionsReportFields(
			hospital_Scientific_orEducationalUseFields,
		),
		'4_Export': transactionsReportFields(exportFields),
		'5_TransfertoForeignTradeZone': transactionsReportFields(
			transfertoForeignTradeZoneFields,
		),
		'6_TransfertoCMBW': transactionsReportFields(transfertoCMBWFields),
		'7_UseAsSuppliesOnVesselsOrAircraft': transactionsReportFields(
			useAsSuppliesOnVesselsOrAircraftFields,
		),
		'8_UseInWineProduction': transactionsReportFields(
			useInWineProductionFields,
		),
		'9_EnteredInProcessingAccount': transactionsReportFields(
			enteredInProcessingAccountFields,
		),
		'10_EnteredForTransferInBond': transactionsReportFields(
			enteredForTransferInBondFields,
		),
		'11_EnteredInStorageAccount': transactionsReportFields(
			enteredInStorageAccountFields,
		),
		'12_WithdrawnForResearchDevelopmentOrTesting': transactionsReportFields(
			withdrawnForResearchDevelopmentOrTestingFields,
		),
		'13_Specify': transactionsReportFields(specifyFields),
		'14_Produced': transactionsReportFields(producedFields),
		'15_ReceivedForRedistillation': transactionsReportFields(
			receivedForRedistillationFields,
		),
		'16_Specify': transactionsReportFields(specify2Fields),
		'17a_PhysicalInventoryEOQReceivedForRedistillation':
			transactionsReportFields(
				physicalInventoryEOQ_ReceivedForRedistillationFields,
			),
		'17b_PhysicalInventoryEOQUnfinishedSpirits': transactionsReportFields(
			physicalInventoryEOQ_UnfinishedSpiritsFields,
		),
	};
}
