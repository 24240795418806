import { Edit, TaskOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import { useMemo } from 'react';

function AssetSKUStorageListItem({ item, onEdit, display, selected = false }) {
	const { sku_entry_id, sku_id, sku_name, entry_quantity, expiry, duty_paid } = item;
	const skus = useCompanySKUs();

	const unit = useMemo(() => {
		const sku = skus.find((s) => s.sku_id === sku_id);
		return sku?.properties?.unit;
	}, [sku_id, skus]);

	return (
		<Box className='listItem'>
			{selected && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack>
					<IDDisplay value={sku_entry_id} />
					<NameDisplay label='SKU' name={sku_name} />
					<AmountDisplay amount={entry_quantity} unit={unit} />
					<DutyPaidDisplay value={duty_paid} />
					<DateDisplay date={expiry} />
					{display && display(item)}
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

export default AssetSKUStorageListItem;
