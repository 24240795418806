import {
  setPartnerConnectLink,
  useAccountEmail,
  useCompanyId,
  usePartnerConnectLink,
} from '@pw/redux/containers/User';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// used to link partner invitation for new users.
function usePartnerConnectLinkProcess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const company_id = useCompanyId();
  const account_email = useAccountEmail();
  const partnerConnectLink = usePartnerConnectLink();

  const _navigateToNewCompanyForm = useCallback(() => {
    navigate('/account/organizations', { state: { create: true }, replace: true });
  }, [navigate]);

  const _navigateToPartnerConnectLink = useCallback(() => {
    partnerConnectLink?.link &&
      navigate(partnerConnectLink?.link, { replace: true });
  }, [partnerConnectLink?.link, navigate]);

  const onAccountCreated = useCallback(
    (res) => {
      if (partnerConnectLink?.link) {
        // [√] check auth and is invited user
        if (
          res.account_email.trim().toLowerCase() === partnerConnectLink?.ident
        ) {
          // [√] check company setup is complete
          if (res?.company_id) {
            // partner confirmation popup
            _navigateToPartnerConnectLink();
          } else {
            // [√] navigate to register company flow
            _navigateToNewCompanyForm();
          }
        }
      } else {
        navigate('/account/settings', { replace: true });
      }
    },
    [
      partnerConnectLink?.link,
      account_email,
      partnerConnectLink?.ident,
      _navigateToPartnerConnectLink,
      _navigateToNewCompanyForm,
      navigate,
    ],
  );

  const setInvitedAccount = useCallback(
    (invitedAccount = {}) => {
      // [√] check invitedAccount url
      if (invitedAccount?.partnerConnectLink && invitedAccount?.ident) {
        // [√] propagate to redux
        dispatch(
          setPartnerConnectLink({
            link: invitedAccount?.partnerConnectLink,
            ident: invitedAccount?.ident,
          }),
        );
        // [√] check auth and is invited user
        if (account_email.trim().toLowerCase() === invitedAccount?.ident) {
          // [√] check company setup
          if (company_id) {
            // partner confirmation popup
            navigate(invitedAccount?.partnerConnectLink, { replace: true });
          } else {
            // [√] navigate to register company flow
            _navigateToNewCompanyForm();
          }
        }
      }
    },
    [account_email, company_id, dispatch, navigate, _navigateToNewCompanyForm],
  );

  return {
    setInvitedAccount,
    onCompanyCreated: _navigateToPartnerConnectLink, // [√] listen for company creation success
    onAccountCreated,
  };
}

export default usePartnerConnectLinkProcess;
