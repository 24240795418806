import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import PolicyEditor from '@pw/components/PolicyEditor';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationAssetModal from '@pw/components/SKUSelector/modals/DestinationAssetModal';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceAssetModal from '@pw/components/SKUSelector/modals/SourceAssetModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import StorageLossGain from '@pw/components/StorageGainLoss';
import { ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS, REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS } from '@pw/consts/units';
import { useCompanyPolicies, useCompanySKUs } from '@pw/redux/containers/User/hooks';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { summarizeTransferLiquids } from '@pw/utilities/calculateTransferLiquidSummary';
import debounce from '@pw/utilities/debounce';
import fround from '@pw/utilities/fround';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import uniq from 'lodash.uniq';
import { useCallback, useMemo } from 'react';

export function convertSourceAsset(item) {
  const { properties = {} } = item ?? {};
  const { liquid = {} } = properties;
  const { level = {} } = liquid;
  const { bl = 0, enable: le = false, expected_bl } = level;

  // Only add non-empty assets
  if (bl && le) {
    return toTaggedAsset({
      ...item,
      processed: ASSET_PROCESSED_STATUS.PENDING,
      properties: {
        ...properties,
        liquid: {
          ...liquid,
          level: {
            ...level,
            expected_bl: expected_bl ?? bl,
          },
        },
      },
    });
  }
  return null;
}

function TransferForm() {
  const skuList = useCompanySKUs();
  const converter = useConverter();

  const [
    [policies, initializePolicies],
    PolicyComponent,
    PolicyModals,
    loadPolicies,
  ] = usePolicySelectorHook({
    requestType: REQUEST_TYPES.transfer,
    title: 'Policies',
    PolicyModal: PolicyEditor,
  });

  const sourceProps = useMemo(
    () => ({
      title: 'Sources',
      filter: {
        asset_types: [
          ASSET_TYPES.pallet,
          ASSET_TYPES.cask,
          ASSET_TYPES.ibc,
          ASSET_TYPES.tanker,
          ASSET_TYPES.filling_tank,
        ],
        request_types: [REQUEST_TYPES.pick],
        sku_types: [
          SKU_TYPES.TRACKED,
          SKU_TYPES.RAW,
          SKU_TYPES.EXPIRING,
          SKU_TYPES.CONSUMABLE,
          SKU_TYPES.FINISHED,
        ],
      },
      assetFilter: (a) =>
        [
          ASSET_TYPES.ibc,
          ASSET_TYPES.cask,
          ASSET_TYPES.tanker,
          ASSET_TYPES.filling_tank,
          ASSET_TYPES.pallet,
        ].includes(a.asset_type),
      // submitForm,
      assetAdapter: convertSourceAsset,
      SKUModal: SourceSKUModal,
      skuModalProps: { calculateSkuLiquid: true },
      AssetModal: SourceAssetModal,
    }),
    [],
  );

  const [
    [sourceSkus, , , upsertSourceSkus, removeSourceSkus],
    [sourceAssets, , , upsertSourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useInventorySelectorHook(sourceProps);

  const sourceWeightProperties = useMemo(() => {
    // Find the first asset with weight properties enabled and level properties disabled
    const assetWithWeight = sourceAssets?.find(
      (item) => item?.properties?.liquid?.weight?.enable,
    );
    console.log(
      'Weight properties',
      assetWithWeight?.properties?.liquid?.weight,
    );

    // Return weight properties if found, otherwise return null
    return assetWithWeight
      ? { weightFactor: assetWithWeight?.properties?.liquid?.weight }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceAssets, sourceAssets.length]);

  const sourcesAbv = useMemo(() => {
    // If weight properties exist or there are no source assets, return 0
    if (sourceAssets.length === 0) return [];

    let totalBulk = 0;
    let totalAbv = 0; // Total baseline
    let totalTcf = 0; // TCF
    // Do a weighted average of the ABV and TCF
    sourceAssets
    .filter((asset) => asset?.properties?.liquid?.level?.bl)
    .forEach((asset) => {
      const bl = Number(asset?.properties?.liquid?.level?.bl);
      totalBulk += bl;
      totalAbv += Number(asset?.properties?.liquid?.level?.abv ?? 0) * bl;
      totalTcf += Number(asset?.properties?.liquid?.level?.tcf || 1.0) * bl;
    });

    console.log('ABV', totalAbv, totalTcf, totalBulk);

    if (totalBulk > 0) {
      return [
        fround(totalAbv / totalBulk, 2),
        fround(totalTcf / totalBulk, 3),
      ];
    }
    return [];
  }, [sourceAssets]);

  const sourcesLiquidSku = useMemo(() => {
    // If there are no source assets, return an empty string
    if (sourceAssets.length === 0) return {};
    // Check if the liquid SKU is the same for all source assets
    const liquidSkus = sourceAssets
    .map((s) => s.properties?.liquid?.sku_id ?? '')
    .filter((y) => y !== '')
    .sort()
    .filter((x, i, a) => !i || x !== a[i - 1]);

    console.log('Liquid SKUs', liquidSkus);

    // Grab the batch, the date and the liquid from the propertiess..
    const props = sourceAssets.map((s) => [
      s.properties?.liquid?.batch,
      s.properties?.liquid?.date,
      s.properties?.liquid?.liquid,
    ]);
    const uniqueProps = uniq(props.sort());
    console.log('Unique properties', uniqueProps);
    let [batch, date, liquid] = uniqueProps[0] ?? [];

    // If same, then use that..
    if (liquidSkus.length === 1) {
      const skuId = liquidSkus[0];
      // Find this sku
      const fs = skuList.find((s) => s.sku_id === skuId);
      if (fs) {
        return {
          batch,
          liquid,
          date,
          sku_id: skuId,
          sku: {
            sku_name: fs.sku_name,
            sku_description: fs.sku_description,
            sku_type: fs.sku_type,
          },
        };
      }
    }
    return {};
  }, [sourceAssets, skuList]);

  const convertDestinationAsset = useCallback(
    (item) => {
      // Extract the max capacity and set that as the expected..
      const { properties = {}, sku } = item ?? {};

      // Default capacity in liters
      const capacity = sku?.properties?.capacity ?? {
        value: '200',
        unit: UNITS.liters,
      };

      // Update the properties
      const { liquid = {} } = properties;
      const { level = {} } = liquid;
      const { bl = '0' } = level;

      console.log(
        'Adding dest',
        item,
        sourceWeightProperties,
        sourcesAbv,
        sourcesLiquidSku,
      );

      const updatedAbv = Number(sourcesAbv[0] ?? level.updated_abv ?? '0');
      const updatedTcf = Number(
        sourcesAbv[1] ?? level.updated_tcf ?? '1.000',
      );

      // Factor in existing bulk and capacity to compute what we can fill in this container
      return toTaggedAsset({
        ...item,
        properties: {
          ...properties,
          liquid: {
            ...liquid,
            ...sourcesLiquidSku,
            level: {
              ...level,
              enableWeight: !!sourceWeightProperties,
              ...(sourceWeightProperties ?? {}),
              expected_bl:
                Number(capacity.value) > 0
                  ? Math.min(
                    Number(capacity.value) - Number(bl),
                    Number(capacity.value),
                  )
                  : null,
              updated_abv: updatedAbv,
              updated_tcf: updatedTcf,
            },
          },
        },
      });
    },
    [sourceWeightProperties, sourcesAbv, sourcesLiquidSku],
  );

  const onDestinationSKUAdded = useCallback(
    (sku) => {
      console.log(
        'Destination SKU added',
        sku?.properties?.skus?.length,
        sku?.properties?.releases?.length,
        sku?.sku_type,
        sku,
      );
      if (sku?.sku_type === SKU_TYPES.FINISHED) {
        const amount = sku?.entries?.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0,
        );
        console.log('Amount', amount);

        // which skus to use
        let skus = [];
        let assets = [];

        // Find the release
        const releases = sku?.entries?.flatMap((e) => e.release?.id);
        console.log('Releases', releases?.length);

        // Find the release
        const release_list = sku?.properties?.releases?.filter((r) =>
          releases.includes(r.id),
        );

        console.log('Releases', release_list);

        if (release_list && release_list.length > 0) {
          // See if there is a skus list in the releases, if so, add all of them in there..
          release_list
          .map((r) => r.skus)
          .flat()
          .filter((s) => s.sku_type === SKU_TYPES.CONSUMABLE)
          .forEach((s) => {
            const existing = skus.find((k) => k.sku_id === s.sku_id);
            if (!existing) {
              console.log(' --> adding sku', s);
              skus.push({
                ...s,
                amount: Number(amount) * Number(s?.amount),
              });
            }
          });
          // If there are assets, pull that in as well
          release_list
          .map((r) => r.assets)
          .flat()
          .forEach((a) => {
            const existing = assets.find((k) => k.asset_id === a.asset_id);
            if (!existing) {
              console.log(' --> adding asset', a);
              assets.push({
                ...a,
              });
            }
          });
        }

        // Deprecated, need to get rid of this eventually, all should come from release
        if (skus.length === 0) {
          skus = sku?.properties?.skus
          .filter((s) => s.sku_type === SKU_TYPES.CONSUMABLE)
          .map((s) => ({
            ...s,
            amount: Number(amount) * Number(s?.amount),
          }));
        }
        if (assets.length === 0) {
          assets = sku?.properties?.assets ?? [];
        }

        debounce(() => {
          upsertSourceSkus(skus);
          upsertSourceAssets(assets);
        }, 25);
      }

      loadPolicies(sku?.policies);
    },
    [upsertSourceSkus, upsertSourceAssets, loadPolicies],
  );

  const onDestinationSKURemoved = useCallback(
    (sku) => {
      console.log('Destination SKU removed', sku);
      // if (
      // 	sku?.sku_type === SKU_TYPES.FINISHED &&
      // 	sku?.properties?.skus?.length > 0
      // ) {
      // 	removeSourceSkus(sku?.properties?.skus);
      // }
    },
    [removeSourceSkus],
  );

  const destProps = useMemo(
    () => ({
      title: 'Destinations',
      filter: {
        asset_types: [
          ASSET_TYPES.pallet,
          ASSET_TYPES.cask,
          ASSET_TYPES.ibc,
          ASSET_TYPES.tanker,
          ASSET_TYPES.filling_tank,
        ],
        request_types: [REQUEST_TYPES.pick, REQUEST_TYPES.change_ownership],
        sku_types: [
          SKU_TYPES.TRACKED,
          SKU_TYPES.FINISHED,
          SKU_TYPES.WASTE,
          SKU_TYPES.SERVICE,
        ],
      },
      assetFilter: (a) =>
        [
          ASSET_TYPES.ibc,
          ASSET_TYPES.cask,
          ASSET_TYPES.tanker,
          ASSET_TYPES.filling_tank,
          ASSET_TYPES.pallet,
        ].includes(a.asset_type),
      // submitForm,
      assetAdapter: convertDestinationAsset,
      SKUModal: DestinationSKUModal,
      skuModalProps: { calculateSkuLiquid: true },
      AssetModal: DestinationAssetModal,
      parentAssetSupport: true,
      onSKUAdded: onDestinationSKUAdded,
      onSKURemove: onDestinationSKURemoved,
    }),
    [convertDestinationAsset, onDestinationSKUAdded, onDestinationSKURemoved],
  );

  const [
    [destinationSkus],
    [destinationAssets],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook(destProps);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources,
        skus: entity?.sku_sources,
      });
      initDestinations({
        ...entity,
        assets: entity?.destinations,
        skus: entity?.sku_destinations,
      });
      initializePolicies(entity?.policies);
    },
    [initSources, initDestinations, initializePolicies],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      const liquidSummary = summarizeTransferLiquids({
        sourceAssets,
        destinationAssets,
        sourceSkus,
        destinationSkus,
        converter,
        skuList,
      });
      return {
        ...entity,
        liquid_summary: liquidSummary,
        sku_sources: sourceSkus,
        sources: sourceAssets,
        destinations: destinationAssets,
        sku_destinations: destinationSkus,
        policies,
      };
    },
    [sourceSkus, sourceAssets, destinationSkus, destinationAssets, policies],
  );

  return (
    <>
      <RequestForm
        requestLabel="Transfer"
        requestType={REQUEST_TYPES.transfer}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
      >
        <SourceInventory />
        <DestinationInventory enableFill enableUpload />

        <PolicyComponent />

        <StorageLossGain
          sources={sourceAssets}
          destinations={destinationAssets}
        />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
      <PolicyModals />
    </>
  );
}

export default withAppLayout(TransferForm, { title: 'Liquid Transfer' });
