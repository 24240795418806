import { Box } from "@mui/material";

// import { timeframes } from "./functions";

const DividerGroup = ({
  rootViewMode, viewMode, date, index = 0, width, offset = 0, childrenCount, children, classNames = '', sx, ...rest
}) => {

  // const tf = timeframes(date);
  // const thisTf = tf[viewMode];
  // const [count, seconds] = thisTf;
  const parentWidth = width; //|| (childrenCount || count) * seconds;
  const parentOffset = offset;
  // const childWidth = seconds;

  return (
    <Box
      className={`dividers-root dividers-root-${viewMode} ${classNames}`}
      sx={{
        '--width': parentWidth,
        '--offset': parentOffset,
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
export default DividerGroup;
