import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import TransactionsSpiritsReport from './TransactionsSpiritsReport';

export default function TransactionsSpirits({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<TransactionsSpiritsReport name='1_TaxPayment' title='Tax Payment' />
					<TransactionsSpiritsReport name='2_UseOfUS' title='Use Of US' />
					<TransactionsSpiritsReport
						name='3_HospitalScientificorEducationalUse'
						title='Hospital, Scientific or Educational Use'
					/>
					<TransactionsSpiritsReport name='4_Export' title='Export' />
					<TransactionsSpiritsReport
						name='5_TransfertoForeignTradeZone'
						title='Transfer to Foreign Trade Zone'
					/>
					<TransactionsSpiritsReport
						name='6_TransfertoCMBW'
						title='Transfer to CMBW'
					/>
					<TransactionsSpiritsReport
						name='7_UseAsSuppliesOnVesselsOrAircraft'
						title='Use As Supplies On Vessels Or Aircraft'
					/>
					<TransactionsSpiritsReport
						name='8_UseInWineProduction'
						title='Use In Wine Production'
					/>
					<TransactionsSpiritsReport
						name='9_EnteredInProcessingAccount'
						title='Entered In Processing Account'
					/>
					<TransactionsSpiritsReport
						name='10_EnteredForTransferInBond'
						title='Entered For Transfer In Bond'
					/>
					<TransactionsSpiritsReport
						name='11_EnteredInStorageAccount'
						title='Entered In Storage Account'
					/>
					<TransactionsSpiritsReport
						name='12_WithdrawnForResearchDevelopmentOrTesting'
						title='Withdrawn For Research, Development Or Testing'
					/>
					<TransactionsSpiritsReport
						name='13_Specify'
						title='Specify'
						specify={true}
					/>
					<TransactionsSpiritsReport name='14_Produced' title='Produced' />
					<TransactionsSpiritsReport
						name='15_ReceivedForRedistillation'
						title='Received For Redistillation'
					/>
					<TransactionsSpiritsReport
						name='16_Specify'
						title='Specify'
						specify={true}
					/>
					<TransactionsSpiritsReport
						name='17a_PhysicalInventoryEOQReceivedForRedistillation'
						title='Physical Inventory EOQ - Received For Redistillation'
					/>
					<TransactionsSpiritsReport
						name='17b_PhysicalInventoryEOQUnfinishedSpirits'
						title='Physical Inventory EOQ - Unfinished Spirits'
					/>
				</>
			)}
		</FormikProvider>
	);
}
