import AccountSettings from '@pw/components/Account';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function AccountSettingsPage() {
	usePageTitleHook('Account Settings');

  return (
    <AccountSettings />
  );
}

export default withAppLayout(withDialogCard(AccountSettingsPage));
