import { Stack } from '@mui/material';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import Part1Report from './Part1Report';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FlexBox } from '@pw/components/Layout/FlexBox';

export default function Part1({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Part1Report
						name='On_Hand_First_of_Month'
						title='On Hand First of Month'
					/>

					<Part1Report
						name='Deposited_in_bulk_storage'
						title='Deposited in Bulk Storage'
					/>

					<Part1Report
						name='Received_from_customs_custody'
						title='Received from Customs Custody'
					/>

					<Part1Report
						name='Returned_to_bulk_storage'
						title='Returned to Bulk Storage'
					/>

					<FlexBox
						spacing={2}
						alignItems='top'
						sx={{ flexDirection: 'column' }}
						gap={1}
					>
						<Part1Report
							name='InventorOther_5'
							title='Other or Inventory Gain 5'
							inventory5={true}
						/>
						<Part1Report
							name='Inventory_gain_or_other_5'
							title='Inventory Gain or Other_5'
							bonded={true}
						/>
					</FlexBox>

					<Part1Report name='Taxpaid' title='Taxpaid' />
					<Part1Report
						name='Use_of_the_United_States'
						title='Use of the United States'
					/>

					<Part1Report name='Export' title='Export' />
					<Part1Report
						name='Transfer_to_foreign_trade_zone'
						title='Transfer to Foreign Trade Zone'
					/>

					<Part1Report name='Transfer_to_CMBW' title='Transfer to CMBW' />
					<Part1Report
						name='Use_as_supplies_on_vessels_and_aircraft'
						title='Use as Supplies on Vessels and Aircraft'
					/>

					<Part1Report
						name='Transfer_to_bonded_winery'
						title='Transfer to Bonded Winery'
					/>

					<Part1Report name='Transfer_to_CBW' title='Transfer to CBW' />
					<Part1Report
						name='Research_development_or_testing'
						title='Research Development or Testing'
					/>

					<Part1Report
						name='Transferred_to_Processing_account'
						title='Transferred to Processing Account'
					/>

					<Part1Report
						name='Transferred_to_production_account'
						title='Transferred to Production Account'
					/>

					<Part1Report
						name='Transferred_to_other_bonded_premises'
						title='Transferred to Other Bonded Premises'
					/>

					<Part1Report name='Destroyed' title='Destroyed' />
					<FlexBox
						spacing={2}
						alignItems='top'
						sx={{ flexDirection: 'column' }}
						gap={1}
					>
						<Part1Report
							name='InventorOther_21'
							title='Other or Inventory Gain 21'
							inventory21={true}
						/>
						<Part1Report
							name='Inventory_gain_or_other_21'
							title='Inventory Gain or Other_21'
							bonded={true}
						/>
					</FlexBox>
					<Part1Report name='Other_losses' title='Other Losses' />
					<Part1Report
						name='On_hand_end_of_month'
						title='On Hand End of Month'
					/>
				</>
			)}
		</FormikProvider>
	);
}
