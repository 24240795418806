function SvgNutrients(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 36 36'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d="M14.1154 17.0816C14.3727 17.0816 14.6309 17.0621 14.8861 17.0864C15.215 17.1175 15.3319 16.966 15.3918 16.668C15.7314 14.9719 16.0859 13.2778 16.4364 11.5836C16.7525 10.0555 17.0696 8.52644 17.3867 6.99833C17.4289 6.79542 17.479 6.59446 17.6616 6.46145C18.0229 6.19738 18.4883 6.40805 18.5924 6.89833C18.8437 8.08179 19.0852 9.2672 19.3297 10.4526C19.7538 12.5021 20.1848 14.5506 20.5942 16.6039C20.6678 16.9738 20.8279 17.1087 21.2078 17.0903C21.7321 17.0641 22.2603 17.0825 22.7856 17.0961C23.0801 17.1039 23.3432 17.1864 23.463 17.4971C23.5729 17.7806 23.4276 17.9835 23.2539 18.1835C21.6732 19.9989 20.0955 21.8164 18.5128 23.6299C18.1859 24.0047 17.8393 24.0047 17.4869 23.6086C16.6298 22.6455 15.7874 21.6707 14.9401 20.6999C14.2283 19.8844 13.5195 19.0669 12.8067 18.2534C12.6094 18.0281 12.4169 17.8029 12.5456 17.4767C12.682 17.1301 12.9952 17.1 13.3074 17.0883C13.5764 17.0777 13.8454 17.0864 14.1154 17.0864C14.1154 17.0854 14.1154 17.0845 14.1154 17.0835V17.0816ZM18.0455 10.0196C18.019 10.0157 17.9915 10.0128 17.965 10.0089C17.7834 10.8817 17.6017 11.7535 17.4211 12.6263C17.0745 14.2972 16.7309 15.968 16.3824 17.6378C16.2852 18.1048 16.1055 18.2475 15.6244 18.2543C15.3309 18.2582 15.0373 18.2514 14.7438 18.2582C14.6534 18.2602 14.5258 18.2116 14.4846 18.3281C14.4541 18.4126 14.5494 18.4767 14.6044 18.5398C15.6578 19.7504 16.7152 20.9572 17.7628 22.1727C17.9405 22.3795 18.0445 22.4008 18.236 22.1785C19.2884 20.9513 20.3566 19.7378 21.4169 18.5174C21.4749 18.4514 21.5839 18.4019 21.5357 18.2563C21.1617 18.2563 20.7837 18.2592 20.4057 18.2563C19.9109 18.2524 19.741 18.1301 19.637 17.6582C19.4661 16.8903 19.313 16.1194 19.153 15.3496C18.7838 13.5739 18.4156 11.7972 18.0475 10.0215L18.0455 10.0196Z" fill="currentColor" />
			<path d="M2.6862 30.4514C2.11087 30.4514 1.53555 30.4514 0.959243 30.4514C0.861065 30.4514 0.762886 30.4544 0.66569 30.4476C0.272977 30.4194 -0.00388588 30.1699 4.12502e-05 29.8534C0.00495016 29.5262 0.271013 29.2825 0.675508 29.2767C1.50806 29.2651 2.34061 29.2777 3.17316 29.2699C4.05578 29.2612 4.93546 29.2709 5.80532 29.4495C7.2721 29.7495 8.58082 30.365 9.73736 31.3145C11.2827 32.5844 12.9684 33.6203 14.8809 34.2795C17.4787 35.1746 20.0058 34.9698 22.4927 33.8795C24.0154 33.2116 25.387 32.3067 26.6662 31.2602C28.2891 29.932 30.1673 29.3097 32.2555 29.2748C33.2353 29.2583 34.2152 29.2719 35.194 29.2719C35.3403 29.2719 35.4885 29.2651 35.6279 29.3243C35.8665 29.4253 35.9961 29.6058 35.9991 29.8592C36.002 30.1 35.8754 30.2709 35.6554 30.3728C35.4836 30.4524 35.2991 30.4514 35.1155 30.4514C34.062 30.4514 33.0086 30.4301 31.9561 30.4602C30.2488 30.5097 28.727 31.0728 27.4055 32.1553C25.762 33.4999 23.982 34.6193 21.9615 35.3329C18.5665 36.5319 15.3394 36.0532 12.2281 34.3873C11.0284 33.7446 9.92292 32.9688 8.87045 32.1126C7.52442 31.0194 5.96535 30.4874 4.22956 30.4544C3.71511 30.4446 3.20065 30.4524 2.6862 30.4524C2.6862 30.4524 2.6862 30.4514 2.6862 30.4505V30.4514Z" fill="currentColor" />
			<path d="M23.4458 6.11973C23.4458 6.9799 23.437 7.8391 23.4497 8.69927C23.4556 9.06916 23.3133 9.32061 22.9824 9.49051C22.6682 9.65264 22.3688 9.8439 22.0566 10.0109C21.7002 10.2021 21.3546 10.1245 21.1916 9.83128C21.0257 9.53226 21.1131 9.16625 21.4891 9.01965C22.1675 8.75364 22.2991 8.28666 22.2706 7.62357C22.2196 6.42651 22.251 5.22655 22.2598 4.02755C22.2618 3.7693 22.1813 3.6062 21.9447 3.47417C20.7302 2.7936 19.5236 2.10042 18.3199 1.40141C18.0912 1.2684 17.9115 1.27423 17.6808 1.4082C16.4781 2.10916 15.2685 2.79652 14.0541 3.47805C13.8234 3.60717 13.7301 3.76348 13.7321 4.02755C13.7429 5.41975 13.7419 6.81291 13.7321 8.20511C13.7301 8.4517 13.8008 8.61869 14.0344 8.72645C14.222 8.81286 14.4006 8.92256 14.5744 9.03615C14.8876 9.24003 14.9779 9.53323 14.8267 9.8138C14.6697 10.106 14.3339 10.2089 14.004 10.0371C13.6152 9.83322 13.2382 9.60507 12.8583 9.38469C12.6197 9.24586 12.547 9.01674 12.547 8.76432C12.5441 6.9964 12.5451 5.22849 12.5451 3.46058C12.5451 3.13825 12.6992 2.91787 12.98 2.75768C14.5302 1.87809 16.0795 0.995594 17.6238 0.105326C17.937 -0.0752514 18.2031 0.00144556 18.4839 0.161635C19.6483 0.830549 20.8156 1.49461 21.982 2.16061C22.3109 2.34798 22.6309 2.55283 22.9677 2.7237C23.3152 2.89943 23.4566 3.15573 23.4497 3.54116C23.433 4.40036 23.4448 5.26053 23.4448 6.1207L23.4458 6.11973Z" fill="currentColor" />
			<path d="M28.2585 28.3931C28.2437 28.8232 27.8078 29.0591 27.3631 28.863C26.8604 28.6407 26.3734 28.3815 25.8659 28.1728C23.6794 27.2728 21.4135 26.7107 19.0375 26.5922C15.6543 26.4233 12.4861 27.2203 9.44455 28.6203C9.26685 28.7019 9.097 28.8009 8.91635 28.8756C8.5796 29.0154 8.28212 28.9145 8.13289 28.6242C7.99348 28.3524 8.073 28.0562 8.37441 27.8737C8.8761 27.5689 9.42001 27.3427 9.96195 27.1204C12.9299 25.9019 16.0107 25.2447 19.2349 25.4291C21.9024 25.5816 24.4462 26.2631 26.88 27.3475C27.2364 27.5067 27.583 27.6883 27.9315 27.865C28.1475 27.9737 28.2496 28.1572 28.2575 28.3951L28.2585 28.3931Z" fill="currentColor" />
			<path d="M32.9927 9.5496C32.9927 10.0341 32.9888 10.5185 32.9937 11.003C32.9966 11.3107 32.8739 11.5311 32.599 11.6864C31.7488 12.167 30.9015 12.6534 30.0572 13.1437C29.7764 13.3068 29.5103 13.3126 29.2266 13.1476C28.3823 12.6563 27.536 12.169 26.6848 11.6894C26.4099 11.535 26.2803 11.3214 26.2813 11.0117C26.2852 10.0428 26.2852 9.07389 26.2813 8.10595C26.2803 7.79917 26.3991 7.57781 26.6759 7.42345C27.5389 6.93997 28.397 6.44872 29.2531 5.95358C29.5123 5.80407 29.7548 5.79922 30.014 5.94776C30.8711 6.44095 31.7292 6.93317 32.5912 7.41665C32.8808 7.57878 32.9996 7.81179 32.9937 8.13217C32.9858 8.604 32.9917 9.0768 32.9917 9.54863L32.9927 9.5496ZM27.4771 9.37485C27.4771 9.8593 27.4839 10.1865 27.4751 10.5137C27.4692 10.7185 27.5458 10.8525 27.7314 10.9515C28.0858 11.1428 28.4608 11.3146 28.774 11.5593C29.367 12.0224 29.9207 12.0631 30.4921 11.5496C30.5451 11.502 30.6158 11.467 30.6846 11.4447C31.6202 11.1301 31.847 10.4991 31.844 9.53795C31.8411 8.60497 31.5593 8.11566 30.7415 7.64771C29.9266 7.18074 29.3503 7.15452 28.5443 7.64966C27.7736 8.12343 27.1855 8.53992 27.478 9.37485H27.4771Z" fill="currentColor" />
			<path d="M10.4369 14.5382C10.4369 15.0101 10.4281 15.4819 10.4408 15.9537C10.4487 16.2634 10.3358 16.4916 10.0648 16.6469C9.18515 17.1488 8.30645 17.6527 7.42776 18.1556C7.20686 18.2818 6.98399 18.2779 6.76505 18.1537C5.8628 17.642 4.96152 17.1304 4.06123 16.6149C3.81087 16.4712 3.73429 16.2304 3.73331 15.9644C3.72938 14.9965 3.73037 14.0285 3.73135 13.0606C3.73135 12.7703 3.858 12.5538 4.11719 12.4072C4.98705 11.9131 5.85396 11.4131 6.72087 10.915C6.96534 10.7743 7.2098 10.7752 7.4523 10.9131C8.33296 11.414 9.21166 11.916 10.0913 12.4169C10.3397 12.5587 10.4408 12.7723 10.4369 13.0509C10.431 13.547 10.435 14.0431 10.4359 14.5392L10.4369 14.5382ZM9.24798 14.5479C9.24798 14.2334 9.23817 13.9179 9.25093 13.6033C9.25976 13.3713 9.17828 13.2169 8.96916 13.1023C8.42329 12.8033 7.88232 12.4946 7.34431 12.181C7.1666 12.0771 7.01345 12.0762 6.8328 12.183C6.28693 12.5062 5.73713 12.8247 5.17948 13.1276C4.97723 13.2373 4.91243 13.38 4.91734 13.5955C4.92716 14.1043 4.95268 14.6149 4.91047 15.1198C4.87218 15.5897 4.99785 15.9081 5.46321 16.0867C5.68902 16.1731 5.90992 16.2984 6.09646 16.4498C6.74444 16.976 7.38063 17.0702 8.03548 16.4712C8.08752 16.4236 8.15919 16.3887 8.22791 16.3663C9.08108 16.077 9.42569 15.4984 9.24798 14.6208C9.24307 14.5975 9.24798 14.5722 9.247 14.5479H9.24798Z" fill="currentColor" />
			<path d="M18.3655 33.2279C16.6857 33.2114 15.2984 32.8425 13.9465 32.3153C12.7615 31.8532 11.6206 31.2949 10.4759 30.7445C10.1323 30.5794 10.0164 30.259 10.1597 29.962C10.2982 29.6765 10.5937 29.5746 10.9403 29.7008C11.0777 29.7513 11.2093 29.8163 11.3428 29.8775C12.141 30.2455 12.9333 30.6289 13.7393 30.9784C15.3112 31.659 16.9272 32.1473 18.6757 32.0367C20.1425 31.9435 21.4954 31.46 22.8188 30.8775C23.6121 30.5289 24.3936 30.1523 25.181 29.7882C25.236 29.7629 25.291 29.7348 25.3479 29.7125C25.6866 29.5785 25.993 29.6688 26.1402 29.9445C26.3002 30.2425 26.207 30.5639 25.8624 30.7377C25.2527 31.0454 24.6302 31.3299 24.0088 31.6163C22.5862 32.2726 21.141 32.8648 19.576 33.1056C19.1048 33.1774 18.6315 33.2396 18.3655 33.2279Z" fill="currentColor" />
			<path d="M33.434 28.2883C32.4188 28.2844 31.4036 28.3291 30.3993 28.1087C28.9227 27.7844 27.6267 27.1272 26.4653 26.168C25.0181 24.9738 23.4247 24.0108 21.6486 23.3613C21.2333 23.2098 21.0645 22.9108 21.1921 22.5875C21.3237 22.2525 21.6339 22.1331 22.0492 22.2817C23.9362 22.9545 25.6357 23.9632 27.1574 25.2389C28.7077 26.5379 30.4808 27.1437 32.4875 27.1563C33.4182 27.1621 34.348 27.1563 35.2787 27.1582C35.7245 27.1592 35.9895 27.367 36.0003 27.7087C36.0111 28.0621 35.7353 28.2854 35.2709 28.2883C34.6592 28.2922 34.0466 28.2883 33.4349 28.2883H33.434Z" fill="currentColor" />
			<path d="M2.7187 28.2893C2.09428 28.2893 1.47085 28.2893 0.846439 28.2893C0.272096 28.2883 -0.00378498 28.099 0.00406928 27.7136C0.0109418 27.3398 0.277987 27.1583 0.829748 27.1583C1.7968 27.1573 2.76288 27.1583 3.72993 27.1583C5.73081 27.1563 7.52649 26.6107 9.07868 25.3059C10.4267 24.1729 11.916 23.2438 13.5556 22.5642C13.6342 22.5312 13.7117 22.4924 13.7932 22.4681C14.1417 22.3652 14.4314 22.4836 14.56 22.7788C14.6876 23.0691 14.5718 23.3555 14.233 23.5234C13.7304 23.7729 13.211 23.9914 12.7152 24.2525C11.6205 24.8292 10.6162 25.5389 9.66088 26.3185C8.04978 27.633 6.19519 28.2728 4.11283 28.2874C3.64746 28.2903 3.18308 28.2874 2.71772 28.2874V28.2893H2.7187Z" fill="currentColor" />
			<path d="M18.1435 30.2182C16.6012 30.1959 15.047 29.9298 13.4987 29.6337C13.0932 29.5561 12.8802 29.2697 12.9509 28.9512C13.0275 28.6037 13.3308 28.4299 13.7442 28.5027C15.3082 28.7804 16.8692 29.0891 18.4705 29.0464C19.8567 29.0085 21.2136 28.7464 22.5733 28.5066C22.9749 28.4357 23.2694 28.5988 23.3499 28.927C23.4304 29.2541 23.2321 29.5551 22.8345 29.6318C21.298 29.9298 19.7566 30.1929 18.1435 30.2192V30.2182Z" fill="currentColor" />
		</svg>
	);
}
export default SvgNutrients;
