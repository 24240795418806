function SvgMetacaskIcon(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 112 112'
			className='MuiSvgIcon-root'
			{...props}
		>
			<g data-name='Group 2'>
				<g data-name='Group 1'>
					<g data-name='Layer 2'>
						<g data-name='Layer 1'>
							<path
								data-name='Path 1'
								d='M95.666 56.006v17.7a11.44 11.44 0 01-6.21 10.49 53.13 53.13 0 01-14.14 5.27 82.25 82.25 0 01-17.41 2.12 82 82 0 01-24.93-3.12 45.38 45.38 0 01-11-4.6 11 11 0 01-5.66-9.71v-35.75a11.44 11.44 0 014.76-9.64 23.55 23.55 0 014.08-2.28 62.41 62.41 0 0115.86-4.82 85.452 85.452 0 0113.36-1.26 80.17 80.17 0 0127.19 4 40 40 0 018.37 3.73 11.23 11.23 0 015.72 9.82c.07 5.99.01 12.02.01 18.05zm-54.93-2.88h.1c.06.14.13.28.19.43q5.29 12.67 10.59 25.35a.64.64 0 00.7.5h7.85a9.19 9.19 0 00-.18-.51l-19.06-45.86a.6.6 0 00-.61-.43h-7.29v46.8h7.76zm38.28 26.28v-46.74a1.81 1.81 0 00-.31 0h-7c-.33 0-.42.18-.52.42q-6.44 15.32-12.87 30.67a1.13 1.13 0 000 .79c1.24 3.07 2.51 6.13 3.77 9.19.06.15.13.29.24.54 3-7.14 5.94-14.18 8.88-21.22h.12v26.35z'
							/>
							<path
								data-name='Path 2'
								d='M40.736 53.126v26.28h-7.76v-46.76h7.29a.6.6 0 01.66.43l19.08 45.8c.06.15.11.3.18.51h-7.85a.64.64 0 01-.72-.49q-5.3-12.68-10.59-25.36c-.06-.15-.13-.29-.19-.43z'
								fill='#fff'
							/>
							<path
								data-name='Path 3'
								d='M79.016 79.406h-7.7v-26.32h-.12c-2.88 7.01-5.88 14.05-8.88 21.19-.11-.25-.18-.39-.24-.54-1.26-3.06-2.53-6.12-3.77-9.19a1.13 1.13 0 010-.79q6.43-15.35 12.83-30.73c.1-.24.19-.42.52-.42h7a1.81 1.81 0 01.31 0z'
								fill='#fff'
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
export default SvgMetacaskIcon;
