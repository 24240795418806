import * as yup from 'yup';

const periodSerialFormat = /^\d{4}-\d{2}$/; // YYYY-##
const prepaymentSerialFormat = /^P-\d{4}-\d{3}$/; // P-YYYY-###

export default function generalInformationFields(fields = {}) {
	const {
		ReturnType = '2',
		SERIAL_FINAL = false,
		PERMIT_NUMBER = '',
		sensEMPLOYER_ID = '',
		SERIAL_NUMBER = '',
		partyCompanyName = '',
		DBA = '',
		partyAddress1 = '',
		partyCity = '',
		partyState = '',
		partyZipcode = '',
		Pay = 'EFT',
		PaymentMethodMethodCode = '',
		RETURN_COVERS_TYPE = '1',
		PERIOD_YEAR = '',
		PeriodList = '',
	} = fields;

	return {
		ReturnType: [ReturnType, yup.number().required('Return type is required!')],
		SERIAL_FINAL: [SERIAL_FINAL, yup.boolean()],
		PERMIT_NUMBER: [
			PERMIT_NUMBER,
			yup.string().required('Plant, Registry, or Permit Number  is required!'),
		],
		sensEMPLOYER_ID: [
			sensEMPLOYER_ID,
			yup.string().required('Employer Identification Number is required!'),
		],
		SERIAL_NUMBER: [
			SERIAL_NUMBER,
			yup.string().required('Serial Number is required! YYYY-## or P-YYYY-###'),
		],

		partyCompanyName: [
			partyCompanyName,
			yup.string().required('Company Name is required!'),
		],
		DBA: [DBA, yup.string().required('Business name is required!')],
		partyAddress1: [
			partyAddress1,
			yup.string().required('Street address is required!'),
		],
		partyCity: [partyCity, yup.string().required('City is required!')],
		partyState: [partyState, yup.string().required('State is required!')],
		partyZipcode: [
			partyZipcode,
			yup.string().required('Zip code is required!'),
		],
		Pay: [Pay, yup.string().required('Form of Payment is required!')],
		PaymentMethodMethodCode: [
			PaymentMethodMethodCode,
			yup.string().required('Electronic Type is required!'),
		],
		RETURN_COVERS_TYPE: [
			RETURN_COVERS_TYPE,
			yup.number().required('Return Covers is required!'),
		],
		PERIOD_YEAR: [PERIOD_YEAR, yup.string().required('Year is required!')],
		PeriodList: [PeriodList, yup.string().required('Period is required!')],
	};
}
