import React from 'react';

function SvgPasskey() {
	return (
		<svg
			width="2em"
			height="2em"
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 288.6 260.37"
		>
			<ellipse className="cls-1" cx="113.97" cy="59.73" rx="56.04" ry="59.73" />
			<path
				className="cls-1"
				d="m200.85,175.49s-58.64-61.68-154.21-14.77C8.02,182.3,1.47,213.28,1.47,213.28c0,0-10.86,31.71,26.5,31.71h172.89v-69.5Z"
			/>
			<path
				className="cls-1"
				d="m252.16,167.41c20.91-5.09,36.44-23.94,36.44-46.42,0-26.39-21.39-47.78-47.78-47.78s-47.78,21.39-47.78,47.78c0,20.09,12.41,37.28,29.97,44.34v79.67l14.55,14.12s3.48,2.82,6.3,0l23.67-23.67s3.8-2.5,0-6.3c-3.8-3.8-14.77-14.77-14.77-14.77l20.37-20.37s3.45-1.81,0-5.26c-2.35-2.35-13.86-14.08-20.97-21.34Zm-11.34-74.82c7.63,0,13.82,6.19,13.82,13.82s-6.19,13.82-13.82,13.82-13.82-6.19-13.82-13.82,6.19-13.82,13.82-13.82Z"
			/>
		</svg>
	);
}

export default SvgPasskey;
