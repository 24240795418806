import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import NotificationWrapper from '@pw/components/NotificationWrapper';
import { FIREBASE_CONFIG } from '@pw/consts/firebase';
import AppRoutes from '@pw/pages/AppRoutes';
import ConfirmationProvider from '@pw/providers/ConfirmationProvider';
import LoadingProvider from '@pw/providers/LoadingProvider';
import NetworkStateProvider from '@pw/providers/NetworkStateProvider';
import { persistor, store } from '@pw/redux/store';
import theme from '@pw/styles/theme';
import createEmotionCache from '@pw/utilities/createEmotionCache';
import { initializeApp } from 'firebase/app';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const clientSideEmotionCache = createEmotionCache();

function App() {
	useEffect(() => {
		console.log('Initializing...');
		initializeApp(FIREBASE_CONFIG);
	}, []);

	return (
		<StoreProvider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<NetworkStateProvider>
					<CacheProvider value={clientSideEmotionCache}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<SnackbarProvider
								maxSnack={3}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							>
								<LoadingProvider>
									<NotificationWrapper>
										<ConfirmationProvider>
											<AppRoutes />
										</ConfirmationProvider>
									</NotificationWrapper>
								</LoadingProvider>
							</SnackbarProvider>
						</ThemeProvider>
					</CacheProvider>
				</NetworkStateProvider>
			</PersistGate>
		</StoreProvider>
	);
}

export default App;
