import { Box } from '@mui/material';
import InventorySelector from '@pw/components/InventorySelector';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import RevertMessage from '../Forms/RevertMessage';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';

function ParentAssetID({ hasParent, entity }) {
	const { enqueueSnackbar } = useSnackbar();

	const { values, setFieldValue } = useContext(FormikContext);
	const [historyExist, isSame, history] = useAssetRevertHook(
		entity,
		(e, e2) => e?.parent_asset_id === e2?.parent_asset_id,
	);

	const [parentAsset, init, addAsset, , removeAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
		values?.['parent_asset']?.['rw_asset_id'] ? [values['parent_asset']] : [],
		{
			onRemove: () => {
				debounce(() => {
					setFieldValue('parent_asset_id', undefined);
					setFieldValue('parent_asset', {});
				}, 50);
			},
			onAdd: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							asset_id,
							asset_status,
							rw_asset_id,
							asset_type,
							unique_location_id,
						} = toTaggedAsset(items[0]);

						if (asset_status === ASSET_STATUS.DEFECTED) {
							enqueueSnackbar(`Defective assets cannot be selected!`, {
								variant: 'error',
							});
							return;
						}

						setFieldValue('parent_asset_id', asset_id);
						setFieldValue('parent_asset', {
							rw_asset_id,
							asset_type,
							unique_location_id,
						});
					}, 50);
				}
			},
			onUpsert: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							asset_id,
							asset_status,
							rw_asset_id,
							asset_type,
							unique_location_id,
						} = items[0];

						if (asset_status === ASSET_STATUS.DEFECTED) {
							enqueueSnackbar(`Defective assets cannot be selected!`, {
								variant: 'error',
							});
							return;
						}

						setFieldValue('parent_asset_id', asset_id);
						setFieldValue('parent_asset', {
							asset_id,
							rw_asset_id,
							asset_type,
							unique_location_id,
						});
					}, 50);
				}
			},
		},
	);

	useEffect(() => {
		hasParent && hasParent(!!values['parent_asset_id']);
	}, [hasParent, values]);

	const onRevert = useCallback(() => {
		if (history.parent_asset_id) {
			init([history.parent_asset])
		} else {
			init([])
		}
		setFieldValue('parent_asset', history.parent_asset);
		setFieldValue('parent_asset_id', history.parent_asset_id);
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		if (entity.parent_asset_id) {
			init([entity.parent_asset])
		} else {
			init([])
		}
		setFieldValue('parent_asset', entity.parent_asset);
		setFieldValue('parent_asset_id', entity.parent_asset_id);
	}, [entity, setFieldValue]);

	const isReverted = useMemo(() => values['parent_asset_id'] === history?.parent_asset_id, [history, values])

	return (
		<Box sx={{ position: 'relative' }}>
			{historyExist && !isSame && (
				<RevertMessage
					title='Pallet'
					onRevert={onRevert}
					item={history?.parent_asset}
					onUndo={onUndo}
					isReverted={isReverted}
					type="asset"
				/>
			)}
			<InventorySelector
				sectionTitle='Pallet'
				list={parentAsset}
				types={{ asset_types: [ASSET_TYPES.pallet] }}
				single
				onAdd={(i) => addAsset(i, true)}
				onRemove={(i) => removeAsset(i, true)}
				summary={false}
			/>
		</Box>
	);
}

export default ParentAssetID;
