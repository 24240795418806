import { Box, Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { MenuItem } from '@pw/components/Layout/SideBar/Menu/MenuItem';
import { useMemo, useState } from 'react';
import { MenuHeader } from './MenuHeader';

function MenuGroup({ title, icon, show, options }) {
	const selected = useMemo(() => {
		return options.some((v) => location.pathname === v.tag || location.pathname?.startsWith(`${v.tag}/`));
	}, [location.pathname, options]);

	const [toggle, setToggle] = useState(selected);

	if (!show) return;

	return (
		<Stack className="menu-group" spacing={0.15}>
			{title && (
				<MenuHeader
					icon={icon}
					label={title}
					xs={true}
					toggle={toggle}
					setToggle={setToggle}
				/>
			)}
			<Collapse in={toggle} unmountOnExit>
				<Box className="menu-content">
					{options.map((v) => (
						<MenuItem key={v.tag} {...v} />
					))}
				</Box>
			</Collapse>
		</Stack>
	);
}

export default MenuGroup;
