const Start = (props) => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g >
      <path d="M17.0066 0H16.9965C12.4537 0.00301793 8.18622 1.76951 4.97928 4.97556C1.77234 8.18161 0.00403951 12.45 6.89778e-06 16.994C-0.00402572 21.5309 1.76024 25.7983 4.96617 29.0083C8.1721 32.2184 12.4406 33.992 16.9844 34H17.0166C21.5392 34 25.7946 32.2395 29.0016 29.0395C32.2166 25.8325 33.9909 21.5651 34 17.0231C34.0081 12.4711 32.2388 8.19167 29.0167 4.97254C25.8067 1.76549 21.5422 0 17.0066 0ZM27.7505 27.7328C24.8903 30.5948 21.0775 32.1772 17.0156 32.1872H16.9783C12.9427 32.1872 9.148 30.6189 6.28786 27.769C3.41967 24.911 1.82981 21.0983 1.81065 17.0342C1.7915 12.9872 3.35716 9.17451 6.2183 6.29842C9.08751 3.41529 12.9104 1.82182 16.9844 1.81176C16.9965 1.81176 17.0096 1.81176 17.0227 1.81176C21.0573 1.81176 24.853 3.38109 27.7172 6.23303C30.6035 9.10711 32.1914 12.9318 32.1894 17.004C32.1873 21.0611 30.6106 24.8718 27.7505 27.7338V27.7328Z" fill="currentColor" />
      <path d="M24.049 15.0996C21.5488 13.7113 18.9931 12.3231 16.5211 10.9811C15.5906 10.4761 14.6611 9.9711 13.7315 9.46509C13.3938 9.281 13.043 9.18945 12.6831 9.18945C12.4885 9.18945 12.2919 9.21661 12.0933 9.26993C11.072 9.54456 10.4601 10.3976 10.456 11.5505C10.452 12.817 10.453 14.1047 10.454 15.349C10.454 15.8933 10.454 16.4385 10.454 16.9828V22.4291V22.4875C10.4681 23.3657 10.8885 24.1322 11.5781 24.5366C12.2233 24.9149 12.9966 24.9169 13.7013 24.5417C17.0232 22.7701 20.5063 20.8709 24.054 18.8971C24.7476 18.5108 25.162 17.8016 25.162 16.9999C25.162 16.1971 24.7466 15.4869 24.051 15.1006L24.049 15.0996Z" fill="currentColor" />
    </g>

  </svg>
);

export default Start;
