import * as yup from 'yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';
import { LIQUID_UNIT_OPTIONS, TEMPERATURE_UNIT_OPTIONS, PRESSURE_UNIT_OPTIONS } from '@pw/consts/measures';
import { FormikRadioGroup } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';

const StillForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const {
    flow_rate_target = { value: '', unit: '' },
    flow_rate = { value: '', unit: '' },
    steam_pressure = { value: '', unit: '' },
  } = nodeData;
  const changeSet = {
    flow_rate_target: unitField(flow_rate_target),
    flow_rate: unitField(flow_rate),
    steam_pressure: unitField(steam_pressure),
  };

  return (
    <NodeModalForm changeSet={changeSet}>


      <Box className="section">
        <H5 className="section-header">Targets</H5>
        <UnitField
          label='Flow Rate Target (per Minute)'
          name='flow_rate_target'
          options={LIQUID_UNIT_OPTIONS}
        />
      </Box>
      <Stack className="section">
        <H5 className="section-header">Measured</H5>
        <UnitField
          label='Flow Rate (per Minute)'
          name='flow_rate'
          options={LIQUID_UNIT_OPTIONS}
        />
        <UnitField
          label='Steam Pressure'
          name='steam_pressure'
          options={PRESSURE_UNIT_OPTIONS}
        />
      </Stack>

    </NodeModalForm>
  )
}

export default StillForm;
