import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import templateFields from '@pw/components/TemplateHandler/templateFields';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useAccountPermissions } from '@pw/redux/containers/User/hooks';
import processLiquid from '@pw/utilities/adapters/processLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}, converter) {
	const { tags = [], imported = false, liquid = {} } = fields;

	return {
		tags: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		imported: [imported, yup.boolean()],
		liquid: liquidPropertyFields(liquid, converter, true),
	};
}

function Properties({ entity }) {
	const { editLiquid = false } = useAccountPermissions();

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Tanker Properties</H5>
				<TagSelect entity={entity} name='tags' type='tags' />
			</Stack>

			<LiquidProperties
				entity={entity}
				name='liquid'
				disabled={!editLiquid}
				useBulk={false}
			/>
		</FormikProvider>
	);
}

function TankerForm(props) {
	const converter = useConverter();
	const [entity, setEntity] = useState(null);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			properties: propertyFields(initialValues.properties, converter),
		}),
		[],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
		};
	};

	const handleInit = (entity) => {
		setEntity(entity);
	};

	return (
		<AssetForm
			assetLabel='Tanker'
			assetType={ASSET_TYPES.tanker}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			onInit={handleInit}
			{...props}
		>
			<Properties entity={entity} />
		</AssetForm>
	);
}

export default withAppLayout(TankerForm, { title: 'Tanker Management' });
