const camelToKebab = (string) => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

const convertStyleObjectToCSS = (styleObject, selector = '') => {
  let cssString = '';

  const processStyleObject = (obj, prefix = '') => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (key.startsWith('@media')) {
          cssString += `${key} {\n`;
          processStyleObject(obj[key], prefix);
          cssString += '}\n';
        } else if (key.startsWith('&')) {
          processStyleObject(obj[key], `${prefix}${key.slice(1)}`);
        } else {
          processStyleObject(obj[key], `${prefix} ${key}`);
        }
      } else {
        const property = camelToKebab(key);
        const value = obj[key];
        cssString += `${prefix}${selector} { ${property}: ${value}; }\n`;
      }
    }
  };

  processStyleObject(styleObject);
  return cssString;
};

export default convertStyleObjectToCSS;