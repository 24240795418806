import { useContext } from 'react';
import * as yup from 'yup';
import { useNodeDataContext } from '../NodeDataContext';
import DesignerContext from '@pw/context/DesignerContext';
import { FormikNumberField } from '@pw/components/Forms/FormikForm';
import NodeModalForm from './NodeModalForm';
// import ObservationConfigForm from './ObservationConfigForm';
import ObervationForm from '../ScheduledObservation';
import NodeConfigForm from './NodeConfigForm';

const PHForm = () => {
  const { mode, batch, selectedNode } = useContext(DesignerContext);
  const { nodeData = {} } = useNodeDataContext();
  const { ph_level } = nodeData;
  const changeSet = {
    ph_level: [ph_level || '', yup.string()],
    observation_name: ['PHForm', yup.string()],
  };
  const fieldNames = Object.keys(changeSet);

  return mode === 'design'
    ? <NodeConfigForm changeSet={changeSet} />
    : (
      <NodeModalForm changeSet={changeSet}>
        <ObervationForm
          batch={batch}
          nodeId={selectedNode.id}
          nodeData={nodeData}
          fieldNames={fieldNames}
        >
          <FormikNumberField
            label='PH Level'
            name='ph_level'
          />
        </ObervationForm>
      </NodeModalForm>
    )
}

export default PHForm;
