import withDialogCard from '@pw/components/Cards/DialogCard';
import Company from '@pw/components/Company';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyName } from '@pw/redux/containers/User/hooks';

function CompanyPage() {
	const company = useCompanyName();

	usePageTitleHook(`Organization ${company}`);

	return <Company />;
}

export default withAppLayout(withDialogCard(CompanyPage));
