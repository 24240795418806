import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import controlStates from '@pw/consts/controlStates.js';
import styles from '@pw/styles/modal.styles';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as yup from 'yup';

function CodeModal({ title, item, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const { state, code = '' } = item ?? {};

  const label = state ? `Update ${title}` : `New ${title}`;

  const changeSet = useMemo(
    () => ({
      state: [state, yup.string().required('State required!')],
      code: [
        code,
        yup.string().required('Code required!'),
      ],
    }),
    [state, code],
  );

  const handleSubmit = (code) => {
    try {
      console.log('Setting Code', code);
      onClose(code);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Stack sx={styles} className='root' spacing={2}>
        <FlexBox>
          <H5>{label}</H5>
          <IconButton
            onClick={() => onClose()}
            className='close-btn'
            aria-label='Close'
          >
            <CloseIcon />
          </IconButton>
        </FlexBox>

        <Box
          sx={{
            overflowY: 'auto',
            height: 'auto',
            maxHeight: 'calc(95vh - 9rem)',
          }}
        >

          <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <FlexBox>
                <FormikSelect
                  label="US State"
                  name="state"
                  options={controlStates}
                  fullWidth
                />
                <FormikTextField label='Control Code' name='code' fullWidth />
              </FlexBox>

              <AlertView />

              <Box className='action-buttons'>
                <TextButton
                  size='small'
                  handleClick={() => onClose()}
                  color='secondary'
                >
                  Cancel
                </TextButton>
                <FilledButton type='submit' size='small'>
                  Save
                </FilledButton>
              </Box>
            </Stack>
          </FormikForm>

        </Box>
      </Stack>
    </Modal>
  );
}

export default CodeModal;
