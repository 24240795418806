function SvgNo(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 22 22'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<g data-name='Group 413'>
				<path
					data-name='Path 9343'
					className='no_svg__cls-1'
					d='M19.82 10.79a.335.335 0 010-.44v.44z'
				/>
				<path
					data-name='Path 9344'
					className='no_svg__cls-1'
					d='M19.82 10.05c-.15-.17-.09-.39-.13-.59-.85-4.8-5.43-7.99-10.23-7.14-4.79.85-7.99 5.43-7.14 10.22.84 4.71 5.28 7.9 10.01 7.18a8.644 8.644 0 007.19-6.41c.13-.42.13-.86.3-1.27v-1.26a.56.56 0 010-.44v-.3zm-8.83 7.56c-3.64 0-6.59-2.97-6.58-6.61s2.97-6.59 6.61-6.58c3.64 0 6.59 2.97 6.58 6.61-.03 3.63-2.98 6.56-6.61 6.58z'
				/>
				<path
					data-name='Path 9345'
					className='no_svg__cls-1'
					d='M8.17 14.86c-.41-.02-.78-.28-.93-.67-.2-.42-.11-.91.23-1.23.58-.59 1.16-1.18 1.75-1.75.16-.16.17-.24 0-.4-.57-.55-1.12-1.11-1.68-1.67-.48-.4-.55-1.11-.15-1.58a1.117 1.117 0 011.73 0c.55.55 1.11 1.09 1.65 1.65.19.2.29.17.46-.01.54-.56 1.09-1.11 1.65-1.65.4-.48 1.11-.54 1.58-.14a1.116 1.116 0 01.01 1.71c-.56.57-1.13 1.14-1.7 1.7-.15.14-.16.23 0 .38.57.55 1.12 1.11 1.68 1.67.48.39.56 1.1.17 1.58a1.138 1.138 0 01-1.76 0c-.53-.53-1.08-1.05-1.59-1.6-.22-.24-.34-.2-.54.01-.53.56-1.08 1.09-1.62 1.63-.25.26-.59.39-.95.37z'
				/>
			</g>
		</svg>
	);
}
export default SvgNo;
