function SvgTimeline(props) {
	return (
		<svg
			id='Timeline_svg__Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			x={0}
			y={0}
			viewBox='0 0 316.1 1086.9'
			xmlSpace='preserve'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<style>
				{
					'.Timeline_svg__st0{font-family:&apos;Poppins&apos;}.Timeline_svg__st1{font-size:24px;font-weight:700}.Timeline_svg__st2{fill:none}.Timeline_svg__st3{font-family:&apos;Poppins&apos;}.Timeline_svg__st4{font-size:15px;font-weight:400}.Timeline_svg__st5{fill:none;stroke:#000;stroke-width:2}.Timeline_svg__st6{fill:#f0aa29}.Timeline_svg__st7{fill:none;stroke:#0a2b4c;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}'
				}
			</style>
			<text
				transform='translate(85.11 42.026)'
				className='Timeline_svg__st0 Timeline_svg__st1'
			>
				Grains
			</text>
			<path className='Timeline_svg__st2' d='M85.9 62h230.2v65H85.9z' />
			<text transform='translate(85.903 73.085)'>
				<tspan x={0} y={0} className='Timeline_svg__st3 Timeline_svg__st4'>
					Record raw ingredients and
				</tspan>
				<tspan x={0} y={24} className='Timeline_svg__st3 Timeline_svg__st4'>
					the source of the ingredients
				</tspan>
			</text>
			<text
				transform='translate(85.11 228.894)'
				className='Timeline_svg__st0 Timeline_svg__st1'
			>
				Mash
			</text>
			<path className='Timeline_svg__st2' d='M85.9 248.9h230.2v65H85.9z' />
			<text transform='translate(85.903 259.953)'>
				<tspan x={0} y={0} className='Timeline_svg__st3 Timeline_svg__st4'>
					Record which batches of
				</tspan>
				<tspan x={0} y={24} className='Timeline_svg__st3 Timeline_svg__st4'>
					ingredients are in each run
				</tspan>
			</text>
			<text
				transform='translate(85.11 412.914)'
				className='Timeline_svg__st0 Timeline_svg__st1'
			>
				Ferment
			</text>
			<path className='Timeline_svg__st2' d='M85.9 432.9h194.7v65H85.9z' />
			<text transform='translate(85.903 443.973)'>
				<tspan x={0} y={0} className='Timeline_svg__st3 Timeline_svg__st4'>
					Record which runs of the
				</tspan>
				<tspan x={0} y={24} className='Timeline_svg__st3 Timeline_svg__st4'>
					cook are fermented in
				</tspan>
				<tspan x={0} y={48} className='Timeline_svg__st3 Timeline_svg__st4'>
					this run
				</tspan>
			</text>
			<text
				transform='translate(85.11 601.047)'
				className='Timeline_svg__st0 Timeline_svg__st1'
			>
				Distil
			</text>
			<path className='Timeline_svg__st2' d='M85.9 621h213.9v65H85.9z' />
			<text transform='translate(85.903 632.105)'>
				<tspan x={0} y={0} className='Timeline_svg__st3 Timeline_svg__st4'>
					Record which runs of the
				</tspan>
				<tspan x={0} y={24} className='Timeline_svg__st3 Timeline_svg__st4'>
					fermentation are distilled in
				</tspan>
				<tspan x={0} y={48} className='Timeline_svg__st3 Timeline_svg__st4'>
					this run
				</tspan>
			</text>
			<text
				transform='translate(85.11 785.237)'
				className='Timeline_svg__st0 Timeline_svg__st1'
			>
				Mature
			</text>
			<path className='Timeline_svg__st2' d='M85.9 805.2h230.2v91.4H85.9z' />
			<text transform='translate(85.903 816.296)'>
				<tspan x={0} y={0} className='Timeline_svg__st3 Timeline_svg__st4'>
					Each cask is recorded from
				</tspan>
				<tspan x={0} y={24} className='Timeline_svg__st3 Timeline_svg__st4'>
					this filling, linked to the
				</tspan>
				<tspan x={0} y={48} className='Timeline_svg__st3 Timeline_svg__st4'>
					distillation run and tagged
				</tspan>
				<tspan x={0} y={72} className='Timeline_svg__st3 Timeline_svg__st4'>
					with a QR code
				</tspan>
			</text>
			<text
				transform='translate(85.11 975.309)'
				className='Timeline_svg__st0 Timeline_svg__st1'
			>
				Bottle
			</text>
			<path className='Timeline_svg__st2' d='M85.9 995.3h213.9v89.3H85.9z' />
			<text transform='translate(85.903 1006.367)'>
				<tspan x={0} y={0} className='Timeline_svg__st3 Timeline_svg__st4'>
					Bottling from cask or
				</tspan>
				<tspan x={0} y={24} className='Timeline_svg__st3 Timeline_svg__st4'>
					blended batches are
				</tspan>
				<tspan x={0} y={48} className='Timeline_svg__st3 Timeline_svg__st4'>
					recorded individually and
				</tspan>
				<tspan x={0} y={72} className='Timeline_svg__st3 Timeline_svg__st4'>
					connected by NFC tags or QR codes
				</tspan>
			</text>
			<path id='Timeline_svg__Line_299' className='Timeline_svg__st5' d='M35.9 76.9v95.7' />
			<path
				id='Timeline_svg__Line_299_00000016784166256194249620000013878868962917365135_'
				className='Timeline_svg__st5'
				d='M35.9 262.5v95.8'
			/>
			<path
				id='Timeline_svg__Line_299_00000167358979104034892420000009513802837621460362_'
				className='Timeline_svg__st5'
				d='M35.9 448.2v95.7'
			/>
			<path
				id='Timeline_svg__Line_299_00000103955893242407233450000004484623743203363748_'
				className='Timeline_svg__st5'
				d='M35.9 633.8v95.7'
			/>
			<path
				id='Timeline_svg__Line_299_00000023279308522811278870000010012471137679309246_'
				className='Timeline_svg__st5'
				d='M35.9 819.5v95.7'
			/>
			<g>
				<path
					className='Timeline_svg__st6'
					d='M33.7 63.3c17.5 0 31.7-14.2 31.7-31.7C65.4 14.2 51.2 0 33.7 0S2.1 14.2 2.1 31.7c0 17.4 14.1 31.6 31.6 31.6'
				/>
				<path
					className='Timeline_svg__st7'
					d='M16.6 48.6s-10.4-6.7-3.3-16.1c.1 0 11 6.3 3.3 16.1'
				/>
				<path
					className='Timeline_svg__st7'
					d='M23.8 41s-10.4-6.7-3.3-16.1c0 0 11 6.3 3.3 16.1'
				/>
				<path
					className='Timeline_svg__st7'
					d='M30.1 35.1s-8.7-7.5-1-15.2c0-.1 9.3 7.1 1 15.2'
				/>
				<path
					className='Timeline_svg__st7'
					d='M36.3 27.8s-7.4-8.2.8-14.7c0 .1 8.1 8-.8 14.7M16.9 48.9s7.9 9.5 16.4 1.4c0 0-7.5-10.2-16.4-1.4M35.9 61.3s11.7 1.3 13.1-10c0 0-11.8-2-13.1 10'
				/>
				<path
					className='Timeline_svg__st7'
					d='M44.9 60s10.4 5.5 15.8-4.4c0-.1-10.2-6.3-15.8 4.4'
				/>
				<path
					className='Timeline_svg__st7'
					d='M47 48.9s7.3 9.2 16.3 2.3c-.1 0-6.9-9.8-16.3-2.3M24.4 41.5s7.9 9.5 16.4 1.4c-.1 0-7.6-10.2-16.4-1.4'
				/>
				<path
					className='Timeline_svg__st7'
					d='M30.6 35.6s8.4 7.8 15.2-.7c0-.1-8.1-8.5-15.2.7'
				/>
				<path
					className='Timeline_svg__st7'
					d='M37 28.5S46 35 51.5 26c0 0-8.8-7.1-14.5 2.5'
				/>
				<path
					className='Timeline_svg__st7'
					d='M41 22.9s-.7-11 9.8-10.9c0-.1 1.4 11.1-9.8 10.9M14.7 47.6L4.5 59.1s-.8 1.7.3 2.6c1.2 1 2.5-.3 2.5-.3l10.1-11.1M54.1 9.7l5.3-5.1M41.5 11.4s.8-2.2 5-5.6c4.1-3.4 8.7-4.5 8.7-4.5M63.1 7.8s-.3 2.3-3.5 6.6-7.3 6.4-7.3 6.4'
				/>
			</g>
			<g>
				<path
					className='Timeline_svg__st6'
					d='M33.7 249.1c17.5 0 31.7-14.2 31.7-31.7s-14.2-31.7-31.7-31.7S2.1 200 2.1 217.5s14.1 31.6 31.6 31.6'
				/>
				<path
					className='Timeline_svg__st7'
					d='M57.4 198.6c-.7-3.3-2.5-5.3-4.9-5.3H16.1c-2.2 0-3.9 1.5-4.8 4.9'
				/>
				<path
					className='Timeline_svg__st7'
					d='M41.9 193.4c-.3-3.3-.9-5.3-1.7-5.3H27.3c-.8 0-1.4 1.5-1.7 4.9M13.4 231.3c3.6 2.4 8.7 5.3 13 5.4 5.2.1 10.5.1 15.7 0 3.6 0 7.1-1.1 10.3-2.8.7-.4 2.6-2.1 3.3-2.6M30.8 236.8v7c0 2.1 1.7 4.4 4.9 4.4h22.8c2.3 0 3.2-4.1-1-4.2-5.4-.3-12.5-.5-17.9-.7-.9 0-1.4-.3-1.4-1.4l-.1-5.1M62.2 201.4c0 1.1-.9 1.9-1.9 1.9H7.1c-1.1 0-1.9-.9-1.9-1.9v-.1c0-1.1.9-1.9 1.9-1.9h53.2c1.1 0 1.9.9 1.9 1.9v.1zM62.2 229.1c0 1.1-.9 1.9-1.9 1.9H7.1c-1.1 0-1.9-.9-1.9-1.9v-.1c0-1.1.9-1.9 1.9-1.9h53.2c1.1 0 1.9.9 1.9 1.9v.1zM7.9 204.2v21.6M12 204.2v21.6M18.2 204.2v21.6M26.8 204.2v21.6M59.4 204.2v21.6M55.2 204.2v21.6M49.1 204.2v21.6M40.4 204.2v21.6'
				/>
			</g>
			<g>
				<path
					className='Timeline_svg__st6'
					d='M34.8 435c17.5 0 31.7-14.2 31.7-31.7s-14.2-31.7-31.7-31.7-31.7 14.2-31.7 31.7S17.3 435 34.8 435'
				/>
				<path
					className='Timeline_svg__st7'
					d='M20.1 377.5c-.3-2.2-.9-4-1.7-4.1H5.5c-.8 0-1.4 1.5-1.7 3.8M7.2 409.3c-1.1 0-1.9-.9-1.9-1.9v-20.9c0-1.1.9-1.9 1.9-1.9h.1c1.1 0 1.9.9 1.9 1.9v20.9c0 1.1-.9 1.9-1.9 1.9h-.1z'
				/>
				<path
					className='Timeline_svg__st7'
					d='M1.2 378.3h21.1v37.8H1.2zM13.1 386.5H16M13.1 389.9H16M13.1 393.2H16M13.1 396.6H16M13.1 399.9H16M13.1 403.3H16M13.1 406.6H16M21.2 416.3l-9.6 5.5-9.1-5.5M43.5 379.6l9.6-7.8 9.2 7.8M3.5 416.7v16.8M1 433.5h5.1M20 429.5v4M17.4 433.5h5.1'
				/>
				<path className='Timeline_svg__st7' d='M14 421.4v3.2h14.7v-38.1h11.2' />
				<path
					className='Timeline_svg__st7'
					d='M9.3 421.4v8.1h24.3v-38.1h6.3M40.4 379.7h25.1v36.4H40.4zM54.3 386.5h2.9M54.3 389.9h2.9M54.3 393.2h2.9M54.3 396.6h2.9M54.3 399.9h2.9M54.3 403.3h2.9M54.3 406.6h2.9M62.5 416.3s-3.2 5.6-9.6 5.6c-6.4 0-9.2-5.6-9.2-5.6M44.9 416.7v16.8M42.3 433.5h5.1M61.3 416.7v16.8M58.8 433.5h5M20 416.7v7.9'
				/>
			</g>
			<g>
				<path
					className='Timeline_svg__st6'
					d='M33.7 620.8c17.5 0 31.7-14.2 31.7-31.7s-14.2-31.7-31.7-31.7-31.6 14.3-31.6 31.7c0 17.5 14.1 31.7 31.6 31.7'
				/>
				<path
					className='Timeline_svg__st7'
					d='M40.8 577.3L39.3 566c0-1 .5-1.3 1.4-1.4l18.4-.3.1-3.4-22.6-1.3c-3.2 0-4.9 4.5-4.9 4.5s-1.2 12.5-1.2 13.9M45.6 581.9c0 1.1-.9 1.9-1.9 1.9H27.4c-1.1 0-1.9-.9-1.9-1.9v-1.7c0-1.1.9-1.9 1.9-1.9h16.3c1.1 0 1.9.9 1.9 1.9v1.7zM27 609.6l-13.7 2.1c-1.1.2-2-.6-2.2-1.6v-.1c-.1-1.1.6-2.1 1.7-2.2l11.3-1.7M44.2 609.6l13.7 2.1c1.1.2 2-.6 2.2-1.6v-.1c.1-1.1-.6-2.1-1.7-2.2L47.1 606M14.2 607.4c-1.3-3.5-2.4-6.8-2.4-8.3 0-3.9 4.3-9.6 6.8-11.7 2.5-2.2 7.9-3.7 7.9-3.7M55.3 611.6c-1.8 4.4-3.5 8.3-3.5 8.3H19.3s-1.6-3.7-3.4-8.1M44.6 583.7s5.3 1.5 7.9 3.7c2.5 2.2 6.8 7.9 6.8 11.7 0 1.4-1.1 4.7-2.4 8.3'
				/>
				<circle
					transform='rotate(-80.781 35.563 603.103)'
					className='Timeline_svg__st7'
					cx={35.6}
					cy={603.1}
					r={10.7}
				/>
				<circle className='Timeline_svg__st7' cx={35.6} cy={603.1} r={6.5} />
				<path className='Timeline_svg__st7' d='M29.4 601h12.1' />
			</g>
			<g>
				<path
					className='Timeline_svg__st6'
					d='M32.9 806.6c17.5 0 31.7-14.2 31.7-31.7s-14.2-31.7-31.7-31.7S1.2 757.5 1.2 775s14.2 31.6 31.7 31.6'
				/>
				<path
					className='Timeline_svg__st7'
					d='M22.1 748.4c-1.8.1-3.6.8-5.2 2.1s-2.9 3.1-4.1 5.4c-1.1 2.2-2 4.9-2.6 7.8-.6 2.9-.8 6.1-.7 9.5.1 3.4.6 6.6 1.4 9.5.8 2.9 1.8 5.5 3.1 7.8'
				/>
				<path
					className='Timeline_svg__st7'
					d='M27.6 747.7c-1.8.1-3.6 1.3-5.1 2.6-1.6 1.3-3 3.1-4.1 5.4-1.1 2.3-2 4.9-2.6 7.9-.6 2.9-.9 6.2-.7 9.5.1 3.4.6 6.6 1.3 9.5.8 2.9 1.8 5.6 3 7.8M41 747.8c-1.8.1-3.6.8-5.2 2.1s-3 3.2-4.1 5.4c-1.2 2.3-2.1 5-2.7 7.9-.6 3-.9 6.2-.8 9.6s.5 6.7 1.3 9.6c.7 3 1.7 5.6 3 7.9'
				/>
				<path
					className='Timeline_svg__st7'
					d='M14.4 797.5c-5.9-2.6-10.6-12.3-11-24-.5-13.5 4-22.4 11.8-24.9 0 0 10.4-1.1 19.3-1.4 8.9-.3 18.3.2 18.3.2'
				/>
				<path
					className='Timeline_svg__st7'
					d='M46.5 747.6c-1.8.1-3.6.8-5.2 2.1s-3 3.2-4.2 5.5c-1.2 2.3-2.1 5-2.7 8-.6 3-.9 6.3-.9 9.7.1 3.4.5 6.7 1.2 9.7.7 3 1.7 5.7 2.9 7.9'
				/>
				<path
					className='Timeline_svg__st7'
					d='M66.2 772.8c0 14.3-5.8 25.8-13 25.7-7.2-.1-13.2-11.5-13.4-25.4-.2-13.9 5.6-25.4 13-25.7 7.4-.2 13.4 11.2 13.4 25.4z'
				/>
				<path
					className='Timeline_svg__st7'
					d='M62.5 772.9c0 11.3-3.9 20.5-8.7 20.5-4.8 0-8.9-9.1-9-20.3-.1-11.2 3.8-20.3 8.7-20.4 4.9-.2 9 8.8 9 20.2z'
				/>
				<path
					className='Timeline_svg__st7'
					d='M50.4 755.1c4.1 2.3 7.1 8.5 7.2 17.9 0 9.4-2.9 15.2-6.8 17.6M55 759.2l4.9-.1M57.5 770.9l4.9-.1M55.9 785.3h4.4M18.4 802.5l2-9.4-4.5-.1-1.8 9.3 11.1.2-.6-3s-2.4-.8-4.6-4.1M35.2 802.7l2.1-9.5-4.5-.1-1.9 9.5 11.1.2-.6-3s-2.3-.8-4.6-4.2M6.1 757.2l5.7-.1M37 756.5l5.8-.2M18 756.9l12.7-.3M3.5 768.4h5.7M34.2 768l5.7-.1M15.3 768.3l12.6-.2M4.7 782.5l5.7-.1M9.5 792.8h4.2M23.8 792.9h4.3M22.8 798.3l8.3.1M39.8 798.5l12.9.1M39.8 793h4.3M35.2 782.4h5.6M16.4 782.4h12.5'
				/>
			</g>
			<g>
				<path
					className='Timeline_svg__st6'
					d='M33.7 992.5c17.5 0 31.7-14.2 31.7-31.7s-14.2-31.7-31.7-31.7-31.6 14.3-31.6 31.8 14.1 31.6 31.6 31.6'
				/>
				<path
					className='Timeline_svg__st7'
					d='M35.7 989.5c.3 1-.4 1.9-1.4 1.9H5.8c-1.1 0-1.7-.8-1.4-1.9l.5-1.8c.3-1 1.4-1.9 2.4-1.9h25.4c1.1 0 2.2.8 2.4 1.9l.6 1.8zM40.5 968.9c0-1.1.8-1.9 1.8-1.9h20.5c1.1 0 1.9.9 1.8 1.9l-1 20.5c0 1.1-1 1.9-2 1.9H43.5c-1.1 0-2-.9-2-1.9l-1-20.5z'
				/>
				<path
					className='Timeline_svg__st7'
					d='M60.8 967l-.7 14.8c0 1.1-1 1.9-2 1.9H47c-1.1 0-2-.9-2-1.9l-.7-14.8M26.4 934.7c0 1.1-.8 1.9-1.9 1.9h-9c-1.1 0-1.9-.9-1.9-1.9l.1-3.6c0-1.1.9-1.9 2-1.9h8.6c1.1 0 2 .9 2 1.9l.1 3.6zM13.6 973.7h12.9M44.6 973.7H60M13.6 977.5h12.9M13.6 981.3h12.9M13.3 958.1H5.4c-1.1 0-1.9-.9-1.9-1.9 0 0 0-4.5 7-5.8l4.2-.8c.4.4.5 0 .2-1.1 0 0-.7-2.1-.7-5.7 0-3.5 1.2-5.9 1.2-5.9M26.8 958.1h7.9c1.1 0 1.9-.9 1.9-1.9 0 0 0-4.5-7-5.8l-4.2-.8c-.4.4-.5 0-.2-1.1 0 0 .7-2.1.7-5.7 0-3.5-1.2-5.9-1.2-5.9M6.3 959.3l1.3 25.2M32.5 984.5l1.3-25.2'
				/>
				<circle className='Timeline_svg__st7' cx={20} cy={961.7} r={7.6} />
			</g>
		</svg>
	);
}

export default SvgTimeline;
