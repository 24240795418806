import * as yup from 'yup';

export default function generalInformationFields(fields = {}) {
	const { Permit_Number = '' } = fields;

	return {
		Permit_Number: [
			Permit_Number,
			yup.string().required('Plant number is required!'),
		],
	};
}
