const generateIds = (prefix = '', pad = 0, start = 1, end = 2) => {
	if (start >= end) {
		return [];
	}

	const etl = end.toString().length;
	const mp = Math.max(pad, etl);

	const ids = [];
	for (let i = start; i < end; i++) {
		const zeroPaddedNumber = i.toString().padStart(mp, '0');
		ids.push(`${prefix}${zeroPaddedNumber}`);
	}

	return ids;
};

export default generateIds;
