import { useCallback, useEffect, useState } from "react";
import DesignerContext from "../context/DesignerContext";
import { getDesign, getDesignBatch, getDesignList, saveDesign, saveDesignBatch } from "../services/production.service";
import { useEdgesState, useNodesState } from "reactflow";
import { useNavigate } from 'react-router-dom';
import useProductionDesignerQuery from '@pw/utilities/hooks/logic/useNavigateProductionDesigner';

const SKIP_LOAD_LABELS = ['label', 'create', 'upload']

export function getNextName(names, prefix) {
  console.log('names', { names, prefix });
  // Filter names that start with the given prefix and match the pattern "prefix (number)"
  const suffixNumbers = names
    .filter(name => name.startsWith(prefix))
    .map(name => {
      const match = name.match(/\((\d+)\)$/); // Extract the suffix number, if it exists
      return match ? parseInt(match[1], 10) : 1;
    });

  // Determine the next available suffix
  let nextSuffix = suffixNumbers.length ? suffixNumbers.length + 1 : 2;

  // Return the new name with the appropriate suffix
  return `${prefix} (${nextSuffix})`;
}

function DesignerProvider({ children, mode, skuId, batchId }) {
  if (!mode) {
    throw new Error("DesignContext: 'mode' is not provided");
  }
  const navigate = useNavigate();
  const type = mode === 'design' ? 'recipe' : 'batch';

  const [isLoading, setIsLoading] = useState({});
  const [batches, setBatches] = useState([]);
  const [recipe, setRecipe] = useState({});
  const [batch, setBatch] = useState({});
  const [refreshOptions, setRefreshOptions] = useState(0);

  const [nodes, setNodes, _onNodesChange] = useNodesState([]);
  const [edges, setEdges, _onEdgesChange] = useEdgesState([]);
  const [nodesData, setNodesData] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [observations, setObservations] = useState({});

  const setRecipeCallback = (recipe) => {
    setRecipe(recipe);
    setNodes(recipe.nodes ?? []);
    setEdges(recipe.edges ?? []);
    setNodesData(recipe.nodes_data ?? {});
  }

  const setBatchCallback = (newBatch, recipe) => {
    setBatch(newBatch);
    setRecipeCallback(recipe);
  };

  useEffect(() => {
    console.log({ nodes, edges, nodesData });
  }, [nodes, edges, nodesData]);

  const loadRecipe = useCallback(async () => {
    console.log('skuId', skuId);
    if (!skuId || SKIP_LOAD_LABELS.indexOf(skuId) > -1) return;
    if (recipe?.sku_id === skuId) return;
    setIsLoading({ message: 'Loading recipe' });
    try {
      const result = await getDesign(skuId);
      if (result) {
        setRecipeCallback(result);
      }
    } catch (e) {
      console.log(`couldn't load design`, e);
      setRecipe({
        sku_id: skuId,
        nodes,
        edges,
      });
      setNodesData({});
    }
    setIsLoading(false);
  }, [skuId, recipe]);

  const createNewBatch = useCallback(async () => {
    const notSaved = batches.filter(({ id, path }) => !id && !path);

    if (notSaved.length > 0) {
      return;
    }

    setIsLoading({ messag: 'creating and saving the new batch' });
    const now = new Date();
    const ts = now.getTime();
    const isoDate = now.toISOString().split('T')[0];
    let _name = `Batch ${isoDate}`;

    const names = batches.map(({ name }) => name).filter(name => name === _name || name.startsWith(_name + ' ('));
    _name = getNextName(names, _name);

    const newBatch = {
      batch_id: btoa(ts),
      name: _name,
      recipe_id: recipe.sku_id,
    };
    // setBatch(newBatch);
    // setBatches((prev) => [...prev, newBatch]);
    await saveDesignBatch(newBatch);
    loadBatches();
    setIsLoading(false);
  }, [recipe, batches]);

  const loadBatch = useCallback(async () => {
    if (!batchId || SKIP_LOAD_LABELS.indexOf(batchId) > -1) {
      console.log('skipping load batch', { batchId, SKIP_LOAD_LABELS });
      return;
    }
    setIsLoading({ message: 'Loading specific batch' });
    try {
      setBatchCallback(await getDesignBatch(batchId), recipe);
    } catch (e) {
      console.log(`couldn't load batch '${batchId}'`, e);
    }
    setIsLoading(false);
  }, [batchId, recipe]);

  useEffect(() => {
    loadRecipe();
  }, [skuId]);

  const loadBatches = async () => {
    setIsLoading({ message: 'Loading batches' });

    try {
      const batchesResponse = await getDesignList({ type: 'batch', sku_id: skuId });
      const resOpts = batchesResponse.map(({ id, path, name, batch_id, recipe_id }) => ({ id, path, name, batch_id, recipe_id }));
      if (resOpts.length > 0 && batchId) {
        setBatches(resOpts);
        const batch_id = resOpts.find(
          (r) => r.batch_id === batchId
        ).path;

        const routeString = useProductionDesignerQuery(skuId, batch_id);
        navigate(routeString);
      }
    } catch (e) {
      console.log('Error loading batches', e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (mode === 'schedule') {
      loadBatches();
    }
    if (mode === 'design') {
      setBatches([]);
    }
  }, [mode]);

  useEffect(() => {
    if (type === 'recipe') {
      setRecipe((prev) => ({
        ...prev,
        nodes,
        edges,
        nodes_data: nodesData,
      }));
    }
  }, [edges, nodes, nodesData]);

  useEffect(() => {
    loadBatch();
  }, [batchId]);

  const saveDesignDocument = useCallback(async () => {
    setIsLoading(true);

    try {
      if (type === 'recipe') {
        await saveDesign(recipe);
      } else {
        await saveDesignBatch(batch);
      }
      setRefreshOptions((prev) => prev + 1);
    } catch (e) {
      console.log('error while saving the document', e);
    }

    setIsLoading(false);
  }, [type, recipe, batch]);

  useEffect(() => {
    console.log('selected Node', selectedNode);
  }, [selectedNode]);

  console.log('DPP', { recipe, skuId });

  return (
    <DesignerContext.Provider value={{
      isLoading, setIsLoading,
      batches, setBatches,
      batch, setBatch,
      recipe, setRecipe,
      skuId, //setSkuId,
      nodes, setNodes, _onNodesChange,
      edges, setEdges, _onEdgesChange,
      loadBatch, // setBatchId,
      batchId,
      refreshOptions, setRefreshOptions,
      mode, saveDesignDocument, createNewBatch,
      nodesData, setNodesData,
      selectedNode, setSelectedNode,
      observations, setObservations,
    }}>
      {children}
    </DesignerContext.Provider>
  );
}

export default DesignerProvider;
