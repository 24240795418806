import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import MaterialsUsedReport from './MaterialsUsedReport';

export default function MaterialsUsed({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<MaterialsUsedReport name='1_Corn' title='1.Corn' />
					<MaterialsUsedReport name='2_Rye' title='2.Rye' />
					<MaterialsUsedReport name='3_Malt' title='3.Malt' />
					<MaterialsUsedReport name='4_Wheat' title='4.Wheat' />
					<MaterialsUsedReport name='5_SorghumGrain' title='5.SorghumGrain' />
					<MaterialsUsedReport name='6_Barley' title='6.Barley' />
					<MaterialsUsedReport
						name='7_Specify'
						title='7.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='8_Specify'
						title='8.Specify'
						specify={true}
					/>
					<MaterialsUsedReport name='9_Grape' title='9.Grape' />
					<MaterialsUsedReport
						name='10_Specify'
						title='10.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='11_Specify'
						title='11.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='12_Specify'
						title='12.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='13_Specify'
						title='13.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='14_Specify'
						title='14.Specify'
						specify={true}
					/>
					<MaterialsUsedReport name='15_Maolasses' title='15.Maolasses' />
					<MaterialsUsedReport
						name='16_Specify'
						title='16.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='17_Specify'
						title='17.Specify'
						specify={true}
					/>
					<MaterialsUsedReport
						name='18_Specify'
						title='18.Specify'
						specify={true}
					/>
					<MaterialsUsedReport name='19_EthylSulfate' title='19.EthylSulfate' />
					<MaterialsUsedReport name='20_EthyleneGas' title='20.EthyleneGas' />
					<MaterialsUsedReport
						name='21_SulphiteLiquors'
						title='21.SulphiteLiquors'
					/>
					<MaterialsUsedReport name='22_ButaneGas' title='22.ButaneGas' />
					<MaterialsUsedReport
						name='23_Specify'
						title='23.Specify'
						specify={true}
					/>
				</>
			)}
		</FormikProvider>
	);
}
