import swapKeyValue from '@pw/utilities/swapKeyValue';

export const SKU_TYPES = {
	TRACKED: 't', // tracked inventory (each individual asset is tracked)
	FINISHED: 'f', // finished goods, not individually tracked
	CONSUMABLE: 'c', // general stock (dry goods etc.)
	RAW: 'r', // raw ingredients
	EXPIRING: 'e', // expiring ingredients
	SERVICE: 's', // standard quantised inventory
	WIP: 'i', // work-in-progress
	WASTE: 'w', // can be used for potentially tracking waste
	LIQUID: 'l', // can be used for tracking produced liquid type
	EXPENSES: 'x', // Allow tracking of incidental costs in the system
};

export const SKU_OPTIONS = [
	{ label: 'Tracked', value: 't' },
	{ label: 'Finished Goods', value: 'f' },
	{ label: 'Consumable', value: 'c' },
	{ label: 'Raw Material', value: 'r' },
	{ label: 'Expiring Material', value: 'e' },
	{ label: 'Services', value: 's' },
	{ label: 'Marketplace Products', value: 'i' },
	{ label: 'Waste Material', value: 'w' },
	{ label: 'Liquid', value: 'l' },
	{ label: 'Expenses', value: 'x' },
];

export const SKU_TYPE_REVERSE = {
	t: 'Tracked',
	f: 'Finished Goods',
	c: 'Consumable',
	r: 'Raw Material',
	e: 'Expiring Material',
	s: 'Services',
	i: 'Marketplace Products',
	w: 'Waste Material',
	l: 'Liquid',
	x: 'Expenses',
};

export const INVENTORY_STATUS = {
	PENDING: '0',
	CONFIRMED: '1',
	ERROR: '2',
};

export const FINISHED_GOODS_TYPES = {
	bottle: '0',
	pack: '1',
	container: '2',
};

export const FINISHED_GOODS_TYPE_OPTIONS = [
	{ label: 'Bottle', value: '0' },
	{ label: 'Pack', value: '1' },
	{ label: 'Container', value: '2' },
];

export const INVENTORY_STATUS_REVERSE = swapKeyValue(INVENTORY_STATUS);

export const METRIC_UNIT_CONVERSION = {
	g: {
		label: 'Grams',
		value: 'g',
		f: 1 / 28.35,
		i: 'oz',
	},
	kg: {
		label: 'Kilograms',
		value: 'kg',
		f: 1 / 0.454,
		i: 'lb',
	},
	t: {
		label: 'Tonnes',
		value: 't',
		f: 1 / 1.102311,
		i: 't',
	},
	ml: {
		label: 'Milliliters',
		value: 'ml',
		f: 1 / 29.57,
		i: 'fl_oz',
	},
	l: {
		label: 'Liters',
		value: 'l',
		f: 1 / 3.785,
		i: 'gal',
	},
	hl: {
		label: 'Hectoliters',
		value: 'hl',
		f: 378.5,
		i: 'gal',
	},
	'%': {
		label: '% ABV',
		value: '%',
		f: 2,
		i: 'proof',
	},
};

// NOTE US Only
export const IMPERIAL_UNIT_CONVERSION = {
	oz: {
		label: 'Ounces',
		value: 'oz',
		f: 28.35,
		m: 'g',
	},
	lb: {
		label: 'Pounds',
		value: 'lb',
		f: 0.454,
		m: 'kg',
	},
	tn: {
		label: 'Tons',
		value: 't',
		f: 1.102311,
		m: 't',
	},
	fl_oz: {
		label: 'Fluid Ounces',
		value: 'fl_oz',
		f: 29.57,
		m: 'ml',
	},
	gal: {
		label: 'Gallons',
		value: 'gal',
		f: 3.785,
		m: 'l',
	},
	proof: {
		label: 'Proof',
		value: 'proof',
		f: 1 / 2,
		m: '%',
	},
};

export const SKU_EDIT = '/admin/sku?id=';

export const SKU_EDIT_URL = {
	t: '/admin/skus/tracked',
	f: '/admin/skus/finished',
	c: '/admin/skus/cons',
	r: '/admin/skus/raw',
	e: '/admin/skus/exp',
	s: '/admin/skus/services',
	i: '/admin/skus/market',
	w: '/admin/skus/waste',
	l: '/admin/skus/liquid',
	x: '/admin/skus/expenses',
};

export const SKU_LIQUID_TYPES = [
	{ label: 'Beer', value: 'beer' },
	{ label: 'Wine', value: 'wine' },
	{ label: 'Bourbon', value: 'bourbon' },
	{ label: 'Single Malt', value: 'single_malt' },
	{ label: 'Grain Whiskey', value: 'grain_whiskey' },
	{ label: 'Rye Whiskey', value: 'rye_whiskey' },
	{ label: 'Whiskey', value: 'whiskey' },
	{ label: 'Whisky', value: 'whisky' },
	{ label: 'Vodka', value: 'vodka' },
	{ label: 'Rum', value: 'rum' },
	{ label: 'Gin', value: 'gin' },
	{ label: 'Tequila', value: 'tequila' },
	{ label: 'Brandy', value: 'brandy' },
	{ label: 'Liqueurs', value: 'liqueurs' },
	{ label: 'Cider', value: 'cider' },
	{ label: 'Mead', value: 'mead' },
	{ label: 'Sake', value: 'sake' },
	{ label: 'Pisco', value: 'pisco' },
	{ label: 'Soju', value: 'soju' },
	{ label: 'Absinthe', value: 'absinthe' },
	{ label: 'Schnapps', value: 'schnapps' },
	{ label: 'Mezcal', value: 'mezcal' },
	{ label: 'Scotch', value: 'scotch' },
	{ label: 'Sotol', value: 'sotol' },
	{ label: 'Cognac', value: 'cognac' },
	{ label: 'Grappa', value: 'grappa' },
];

export const WASTE_REASONS = {
	contaminated: '1',
	broken: '2',
	lost: '3',
	spilled: '4',
	leaked: '5',
};

export const WASTE_REASON_OPTIONS = [
	{ label: 'Contaminated', value: '1' },
	{ label: 'Broken', value: '2' },
	{ label: 'Lost', value: '3' },
	{ label: 'Spilled', value: '4' },
	{ label: 'leaked', value: '5' },
];