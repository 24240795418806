import Partners from '@pw/components/Company/admin/Partners';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function CustomerPage() {
	usePageTitleHook('Partners');
	return <Partners />;
}

export default withAppLayout(CustomerPage);
