import { useState } from 'react';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
/* eslint-disable react/prop-types */
export default function Part3({
	name,
	title = 'General Information',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<FormikTextField
						name='Puerto_Rican_Spirits'
						label='Puerto Rican Spirits'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='Virgin_Island_Spirits'
						label='Virgin Island Spirits'
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='Other'
						label='"Other" Imported Rum'
						fullWidth
						disabled={disabled}
					/>
				</>
			)}
		</FormikProvider>
	);
}
