import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useGetId = () => {
	let { platform, chain, address } = useParams();
	return useMemo(() => {
		if(!platform || platform === 'gen' || platform === 'new'){
			return {id: [], path: platform};
		}
		return {
			id: [platform, chain, address], path: `${platform}/${chain}/${address}`
		};
	},[platform, chain, address]);
};

export default useGetId;
