import FormikContext from '@pw/context/FormikContext';
import { useCallback, useContext, useMemo } from 'react';

export function RootFormikProvider({ formik, edit, readonly, history, children }) {
	const withErrors = useCallback(
		(field) => {
			const error = formik.errors[field];
			return {
				error: formik.touched[field] ? !!error : false,
				helperText:
					formik.touched[field] && !!error ? formik.errors[field] : null,
			};
		},
		[formik],
	);

	const handleChange = useCallback(
		(e) => {
			const name = e.target.name || e.target.id;
			// console.log('Root handle change', name, formik.values, e.target.value);
			if (name) {
				formik.setFieldValue(name, e.target.value);
			}
		},
		[formik],
	);

	const setFieldValue = useCallback(
		(field, value) => {
			// console.log('Root set field', field, value);
			formik.setFieldValue(field, value);
		},
		[formik],
	);

	const valuesMemo = useMemo(
		() => ({
			values: formik.values,
			errors: formik.errors,
			touched: formik.touched,
			withErrors,
			path: '',
			handleChange,
			setFieldValue,
			edit,
			readonly,
			valid: formik.isValid,
		}),
		[
			edit,
			formik.errors,
			formik.touched,
			formik.values,
			handleChange,
			readonly,
			setFieldValue,
			withErrors,
		],
	);

	return (
		<FormikContext.Provider value={valuesMemo}>
			{children}
		</FormikContext.Provider>
	);
}

export function FormikProvider({ path, history, children }) {
	const {
		values,
		errors,
		touched,
		setFieldValue: _setFieldValue,
		readonly,
	} = useContext(FormikContext);

	const pathValues = values?.[path];
	const pathErrors = errors ? errors[path] : undefined;
	const pathTouched = touched ? touched[path] : undefined;

	// console.log('Component provider', path, pathValues);

	// const currentPath = (rootPath !== "")? `${rootPath}.${path}` : path;

	const withErrors = useCallback(
		(field) => {
			if (pathErrors && pathTouched) {
				// if (Object.keys(pathErrors).length) console.log('Path', path, pathErrors);
				const error = !!pathErrors[field];
				return {
					error: pathTouched[field] ? error : false,
					helperText: pathTouched[field] && error ? pathErrors[field] : null,
				};
			}
			return {};
		},
		[pathErrors, pathTouched],
	);

	const handleChange = useCallback(
		(e) => {
			const name = e.target.name || e.target.id;
			// console.log('Path set field', path, name, e.target.value);
			if (name) {
				_setFieldValue(`${path}.${name}`, e.target.value);
			}
		},
		[path, _setFieldValue],
	);

	const setFieldValue = useCallback(
		(field, value) => {
			// console.log('Path set field', path, field, value);
			_setFieldValue(`${path}.${field}`, value);
		},
		[path, _setFieldValue],
	);

	const valuesMemo = useMemo(
		() => ({
			allValues: values,
			values: pathValues,
			errors: pathErrors,
			touched: pathTouched,
			withErrors,
			path,
			handleChange,
			setFieldValue,
			readonly,
		}),
		[
			values,
			pathValues,
			pathErrors,
			pathTouched,
			withErrors,
			path,
			handleChange,
			setFieldValue,
			readonly,
		],
	);

	return (
		<FormikContext.Provider value={valuesMemo}>
			{children}
		</FormikContext.Provider>
	);
}

export function ArrayFormikProvider({
	path,
	index,
	value = null,
	def = {},
	children,
}) {
	const {
		values,
		errors,
		touched,
		setFieldValue: _setFieldValue,
		readonly,
	} = useContext(FormikContext);

	// console.log('Array provider', path, index, values);
	const pathValues =
		value ?? (index < values[path]?.length ? values[path]?.[index] : def);
	const pathErrors = errors && path ? errors[path]?.[index] : undefined;
	const pathTouched = touched && path ? touched[path]?.[index] : undefined;

	// const currentPath = (rootPath !== "")? `${rootPath}.${path}` : path;
	// console.log('. -->', pathErrors, pathTouched, pathValues);

	const withErrors = useCallback(
		(field) => {
			if (pathErrors && pathTouched) {
				// if (Object.keys(pathErrors).length) console.log('Path', path, pathErrors);
				const error = !!pathErrors[field];
				return {
					error: error ?? false,
					helperText: error ? pathErrors[field] : null,
				};
			}
			return {};
		},
		[pathErrors, pathTouched],
	);

	const handleChange = useCallback(
		(e) => {
			const name = e.target.name || e.target.id;
			// console.log('Path set field', path, name, e.target.value);
			if (name) {
				_setFieldValue(`${path}[${index}].${name}`, e.target.value);
			}
		},
		[_setFieldValue, path, index],
	);

	const setFieldValue = useCallback(
		(field, v) => {
			// console.log('Path set field', path, field, value);
			_setFieldValue(`${path}[${index}].${field}`, v);
		},
		[_setFieldValue, path, index],
	);

	const valuesMemo = useMemo(
		() => ({
			values: pathValues,
			errors: pathErrors,
			touched: pathTouched,
			withErrors,
			path,
			handleChange,
			setFieldValue,
			readonly,
		}),
		[
			pathValues,
			pathErrors,
			pathTouched,
			withErrors,
			path,
			handleChange,
			setFieldValue,
			readonly,
		],
	);

	return (
		<FormikContext.Provider value={valuesMemo}>
			{children}
		</FormikContext.Provider>
	);
}
