import swapKeyValue from '@pw/utilities/swapKeyValue';

export const REQUEST_TYPES = {
	unknown: '0',
	sample: '1',
	regauge: '2',
	transfer: '3',
	pick: '4',
	ship: '5',
	production_run: '6',
	change_ownership: '8',
	restock: '9',
	inventory_audit: '10',
	delivery: '11',
	duty_transfer: '12',
	report_defect: '13',
	duty_submission: '14',
	invoice: '15',
	purchase_order: '16',
	expense_report: '17',
	pack: '18',
};

export const REQUEST_NAMES = {
	1: 'Sample',
	2: 'Reguage',
	3: 'Transfer',
	4: 'Pick',
	5: 'Ship',
	6: 'Production Run',
	8: 'Change Ownership',
	9: 'Restock',
	10: 'Inventory Audit',
	11: 'Delivery',
	12: 'Duty Transfer',
	13: 'Report Defect',
	14: 'Duty Submission',
	15: 'Invoice',
	16: 'Purchase Order',
	17: 'Expense Report',
	18: 'Pack',
};

export const REQUESTS_WITH_POLICIES = {
	1: 'Sample',
	2: 'Reguage',
	3: 'Transfer',
	5: 'Ship',
	9: 'Restock',
	11: 'Delivery',
	18: 'Pack',
};

export const REQUEST_EDIT_URL = {
	1: '/app/sample',
	2: '/app/regauge',
	3: '/app/transfer',
	4: '/app/pick',
	5: '/app/ship',
	6: '/app/production-run',
	8: '/app/change-ownership',
	9: '/app/restock',
	10: '/app/inventory-audit',
	11: '/app/delivery',
	12: '/app/duty-transfer',
	13: '/app/report-defect',
	14: '/app/duty-submission',
	15: '/app/invoice',
	16: '/app/purchase-order',
	17: '/app/expense-report',
	18: '/app/pack',
};

export const REQUEST_STATUS = {
	PENDING: '0', // TODO
	IN_PROGRESS: '1',
	AWAITING_APPROVAL: '2',
	DONE: '3',
	REJECTED: '4',
	CANCELLED: '5',
	ARCHIVED: '6',
};
export const REQUEST_STATUS_REVERSE = {
	0: 'Pending', // TODO
	1: 'In Progress',
	2: 'Awaiting Approval',
	3: 'Done',
	4: 'Rejected',
	5: 'Canceled',
	6: 'Archived',
};

export const ASSET_PROCESSED_STATUS = {
	PENDING: '0',
	CONFIRMED: '1',
	ERROR: '2',
};

export const LOSS_INDICATOR_ENUM = {
	EXPECTED: '0', // exp === bl
	LOSS: '1', // exp < bl
	GAIN: '2', // exp > bl
};

export const REQUEST_TYPES_REVERSE = swapKeyValue(REQUEST_TYPES);
