import { br3 } from '../styles/border';
import { pa2 } from '../styles/padding';

const cardStyles = {
	'.card': {
		...br3,
		// ...bg_surface_4,
		// borderTop: '1px solid rgba(120,120,120,0.5)',
		// borderBottom: '1px solid rgba(120,120,120,0.5)',
		// borderRight: '1px solid rgba(120,120,120,0.5)',

		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#fff',
		overflow: 'hidden',
		transition: 'all 0.2s',
		height: 'max-content',

		'--shadow-color': '0deg 0% 63%',
		boxShadow: `
      0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
      0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
      1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
	},
	'.card-tab': {
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#1f6018',
		color: '#fff',
		fill: '#fff',
		borderLeft: '0.2rem solid #1f6018',
		borderRight: '0.2rem solid #1f6018',
		'*': {
			color: 'inherit',
			fill: 'currentColor',
		},
	},
	'.card-tab-pending': {
		backgroundColor: '#D0B158',
		color: '#2c2929',
		fill: '#2c2929',
		borderLeft: '0.2rem solid #D0B158',
		borderRight: '0.2rem solid #D0B158',
	},
	'.card-tab-confirmed': {
		backgroundColor: '#2a58af',
		color: '#fff',
		fill: '#fff',
		borderLeft: '0.2rem solid #2a58af',
		borderRight: '0.2rem solid #2a58af',
	},
	'.card-content': {
		...pa2,
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	'.card-highlight': {
		backgroundColor: '#f6fdf6',
		color: '#18520e',
		textColor: '#18520e',
		fill: '#18520e',
	},
	'.asset-container': {
		padding: '0.5rem',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	'.card-tab-small': {
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'stretch',
		backgroundColor: '#1f6018',
		color: '#fff',
		fill: '#fff',
		borderLeft: '0.2rem solid #1f6018',
		borderRight: '0.2rem solid #1f6018',
		'*': {
			color: 'inherit',
			fill: 'currentColor',
		},
	},
};

export default cardStyles;
