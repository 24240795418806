function SvgTrakrLogoReverse(props) {
	return (
		<svg
			id='TrakrLogoReverse_svg__Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			x={0}
			y={0}
			viewBox='0 0 269.8 99.6'
			xmlSpace='preserve'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<style>
				{
					'.TrakrLogoReverse_svg__st0{fill:#efede9}.TrakrLogoReverse_svg__st1{fill:#fd3b02}.TrakrLogoReverse_svg__st2{fill:#f0aa29}'
				}
			</style>
			<path
				className='TrakrLogoReverse_svg__st0'
				d='M54.3 4.8v11.7c0 .6-.4 1-1 1H36.7c-.6 0-1 .4-1 1V73c0 .6-.4 1-1 1H19.6c-.6 0-1-.4-1-1V18.5c0-.6-.4-1-1-1H1c-.6 0-1-.4-1-1V4.8c0-.6.4-1 1-1h52.3c.5 0 1 .4 1 1zM87.4 20.2c2.7-1.6 5.7-2.5 9-2.6.6 0 1 .4 1 1v16.1c0 .6-.4 1-1 1h-3.7c-4.3 0-7.5.9-9.6 2.7s-3.2 5-3.2 9.6v24.9c0 .6-.4 1-1 1h-15c-.6 0-1-.4-1-1V19.2c0-.6.4-1 1-1H79c.6 0 1 .4 1 1v8.3c1.9-3.1 4.4-5.5 7.4-7.3zM105.5 30.9c2.2-4.3 5.1-7.7 8.8-10 3.7-2.3 7.9-3.5 12.5-3.5 3.9 0 7.4.8 10.3 2.4 3 1.6 5.2 3.7 6.8 6.3v-6.9c0-.6.4-1 1-1H160c.6 0 1 .4 1 1V73c0 .6-.4 1-1 1h-15c-.6 0-1-.4-1-1v-6.9c-1.7 2.6-4 4.7-6.9 6.3-3 1.6-6.4 2.4-10.3 2.4-4.5 0-8.7-1.2-12.4-3.5-3.7-2.4-6.7-5.7-8.8-10.1-2.2-4.4-3.2-9.4-3.2-15.1-.2-5.9.9-10.9 3.1-15.2zm34.9 5.1c-3.1-3.2-7.1-4.4-12-3.3-.1 0-.1 0-.2.1-5.7 2.2-8.6 6.6-8.6 13.3 0 4.3 1.2 7.6 3.5 10.1 2.4 2.5 5.2 3.7 8.6 3.7s6.3-1.2 8.6-3.7c2.4-2.5 3.5-5.8 3.5-10.1.2-4.3-1-7.7-3.4-10.1zM207.2 73.5l-16.7-23v22.4c0 .6-.4 1-1 1h-15.1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h15.1c.6 0 1 .4 1 1v39.9l16.6-22.3c.2-.3.5-.4.8-.4h18.5c.8 0 1.3 1 .8 1.6l-21.3 25.7c-.3.4-.3.9 0 1.3l21.5 25.5c.5.7.1 1.6-.8 1.6h-18.5c-.4 0-.7-.1-.9-.4zM259.8 20.2c2.7-1.6 5.7-2.5 9-2.6.6 0 1 .4 1 1v16.1c0 .6-.4 1-1 1h-3.7c-4.3 0-7.5.9-9.6 2.7s-3.2 5-3.2 9.6v24.9c0 .6-.4 1-1 1h-15.1c-.6 0-1-.4-1-1V19.2c0-.6.4-1 1-1h15.1c.6 0 1 .4 1 1v8.3c2-3.1 4.5-5.5 7.5-7.3zM26.4 90.1h214.7v2H26.4z'
			/>
			<circle className='TrakrLogoReverse_svg__st1' cx={182} cy={91.1} r={8.6} />
			<circle className='TrakrLogoReverse_svg__st2' cx={243.8} cy={91.1} r={8.6} />
			<circle className='TrakrLogoReverse_svg__st2' cx={152.5} cy={91.1} r={8.6} />
			<circle cx={71.4} cy={91.1} r={8.6} fill='#00a89d' />
			<circle className='TrakrLogoReverse_svg__st1' cx={27.1} cy={91.1} r={8.6} />
		</svg>
	);
}
export default SvgTrakrLogoReverse;
