const kinds = [
  { label: 'Grains', value: 'grains' },
  { label: 'Corn', value: 'corn' },
  { label: 'Rye', value: 'rye' },
  { label: 'Malt', value: 'malt' },
  { label: 'Wheat', value: 'wheat' },
  { label: 'Sorghum', value: 'sorghum' },
  { label: 'Barley', value: 'barley' },
  { label: 'Grapes', value: 'grapes' },
  { label: 'Apples', value: 'apples' },
  { label: 'Pears', value: 'pears' },
  { label: 'Molasses', value: 'molasses' },
  { label: 'Cane Sugar', value: 'cane_sugar' },
  { label: 'Agave', value: 'agave' },
]

export default kinds;
