import { getRequestsList } from '@pw/services/request.service';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';
import useLoadingHook from '@pw/utilities/hooks/useLoadingHook';

const serviceFunction = (params = {}) =>
	getRequestsList(params).then(({ items = [] }) => items);
export const [useRequestsListLazyQuery, _] = genServiceCallHooks(
	serviceFunction,
	{ useLoadingHook: useLoadingHook },
);
