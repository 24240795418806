import { IS_PROD } from '@pw/consts/config';
import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SLICE as appSlice } from '../containers/App';
import { SLICE as userSlice } from '../containers/User';

// import listenerMiddleware from './listenerMiddleware';
import rootReducer from './rootReducer';

//
// export const configureStoreWithPersist = () => {
//   const store = configureStore({
//     reducer: rootReducer,
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },}),
//     devTools: !IS_PROD,
// });
//   // enable hot reloading
//   // if (envSelect({default: true, production: false}) && module.hot) {
//   //   module.hot.accept(() => store.replaceReducer(combineReducers(reducers)));
//   // }
//   const persistor = persistStore(store);
//   return {
//     store,
//     persistor,
//   };
// };
//
// export const {persistor, store} = configureStoreWithPersist();

const persistConfig = {
  key: 'root',
  version: 1,
  // timeout: 4 * 3600,
  storage,
  blocklist: [appSlice],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: !IS_PROD,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
  }
);

export const persistor = persistStore(store);
