import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Collapse, Divider, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import withExtendedDialogCard from '@pw/components/Cards/ExtendedDialogCard';
import Confirm from '@pw/components/Confirm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SourceTypeIcon from '@pw/components/Liquid/SourceTypeIcon';
import SyncChanges from '@pw/components/SyncChanges';
import { Body1, H5 } from '@pw/components/Typography';
import { ASSET_TYPES, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { REQUEST_TYPES, REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import { useOnlineHook } from '@pw/redux/containers/App';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyId } from '@pw/redux/containers/User/hooks';
import { syncChangesThunk } from '@pw/redux/thunks/sync';
import { useToggleState } from '@pw/utilities/hooks/logic/useToggleState';
import { useAssetCheckConflictLazyQuery } from '@pw/utilities/hooks/service/useAssetCheckConflictQuery';
import useDeleteChangesetOfflineMutation from '@pw/utilities/hooks/service/useDeleteChangesetOfflineMutation';
import { useGetChangesetsOfflineLazyQuery } from '@pw/utilities/hooks/service/useGetChangesetsOfflineQuery';
import keyBy from 'lodash.keyby';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

/**
 * sort based on priority
 * - asset[pallet=>ibc=>cask]
 * - request[delivery=>.....(based on date)]
 * - asset by creation date
 * - request by due date
 * - first asset then request
 * @param {Array} data changeset data
 * @returns sorted data based on priority
 */
function priorityBasedChangeSort(data) {
	const assetPriority = {
		[ASSET_TYPES.pallet]: 1,
		[ASSET_TYPES.ibc]: 2,
		[ASSET_TYPES.cask]: 3,
		[ASSET_TYPES.filling_tank]: 4,
		[ASSET_TYPES.tanker]: 5,
	};

	const requestPriority = {
		[REQUEST_TYPES.pick]: 1,
		[REQUEST_TYPES.delivery]: 2,
		[REQUEST_TYPES.ship]: 3,
		[REQUEST_TYPES.sample]: 4,
		[REQUEST_TYPES.restock]: 5,
		[REQUEST_TYPES.transfer]: 6,
		[REQUEST_TYPES.regauge]: 7,
		[REQUEST_TYPES.production_run]: 8,
		[REQUEST_TYPES.change_ownership]: 9,
		[REQUEST_TYPES.inventory_audit]: 10,
	};
	return data.sort((a, b) => {
		// asset priority based on asset type
		if (
			b?.asset_type &&
			a?.asset_type &&
			assetPriority[a.asset_type] &&
			assetPriority[b.asset_type]
		) {
			return assetPriority[a.asset_type] - assetPriority[b.asset_type];
		}
		// request priority based on request type
		if (
			b?.request_type &&
			a?.request_type &&
			requestPriority[a.request_type] &&
			requestPriority[b.request_type]
		) {
			return requestPriority[a.request_type] - requestPriority[b.request_type];
		}
		// asset checkin date priority
		if (a?.asset_checkin_date && b?.asset_checkin_date) {
			return a.asset_checkin_date - b.asset_checkin_date;
		}
		// request due date priority
		if (a?.request_due && b?.request_due) {
			return a.request_due - b.request_due;
		}
		// assets first then request
		if (a?.asset_type && b?.request_type) return -1;
		if (a?.request_type && b?.asset_type) return 1;
		return 0;
	});
}

function Sync() {
	const dispatch = useDispatch();
	const companyId = useCompanyId();
	const { online } = useOnlineHook();
	const navigate = useNavigate();

	// for delete confirmation
	const [itemToDelete, setItemToDelete] = useState({});
	// get changesets query
	const [
		getOfflineChangesApi,
		{ data: changesUnsorted = [], isLoading: isLoadingChangesets = false },
	] = useGetChangesetsOfflineLazyQuery();
	const changes = useMemo(
		() => priorityBasedChangeSort(changesUnsorted),
		[changesUnsorted],
	);
	// only if parent exists inside changes
	const parentChanges = useMemo(() => {
		return changesUnsorted.filter(
			(change, _, arr) =>
				!(arr.findIndex((a) => a.path === change.parent_asset_id) >= 0) ||
				!change.parent_asset_id,
		);
	}, [changesUnsorted]);
	console.log('parent changes', parentChanges, changes);
	const [
		checkConflict,
		{ data: conflictsRes = [], isLoading: conflictCheckIsLoading = false },
	] = useAssetCheckConflictLazyQuery();
	const [deleteById, { isLoading: isLoadingDeleteChangeset = false }] =
		useDeleteChangesetOfflineMutation();
	const conflictsMap = useMemo(
		() => keyBy(conflictsRes, (a) => a.path),
		[conflictsRes],
	);
	console.log('conflictsMap', conflictsMap);

	// calculated loading state
	const isLoading = useMemo(
		() =>
			[
				isLoadingChangesets,
				isLoadingDeleteChangeset,
				conflictCheckIsLoading,
			].some(Boolean),
		[
			conflictCheckIsLoading,
			isLoadingChangesets,
			isLoadingDeleteChangeset,
		],
	);

	const hasChangeToCommit = useMemo(() => changes.length > 0, [changes]);

	const commitDisabled = useMemo(
		() => [!online, isLoading].some(Boolean),
		[online, isLoading],
	);

	// used to refetch changesets and check for conflicts
	const refetchChangesets = useCallback(async () => {
		const changesRes = await getOfflineChangesApi();
		if (changesRes.length && online) {
			await checkConflict(changesRes);
		}
	}, [checkConflict, getOfflineChangesApi, online]);

	// used to push changes to server
	const commitChanges = useCallback(async () => {
		if (conflictsRes.length) {
			enqueueSnackbar('Please resolve conflicts before committing', {
				variant: 'error',
			});
			return;
		}
		if (!online) {
			enqueueSnackbar(
				'No internet connection detected! You will not be able to sync data until you are online.',
				{ variant: 'error' },
			);
			return;
		}
		dispatch(syncChangesThunk(changes))
			.unwrap()
			.finally(() => refetchChangesets());
	}, [changes, conflictsRes, online, refetchChangesets]);

	// used to navigate to review page
	const onEditClick = useCallback(
		({ asset_type, request_type, path }) =>
			navigate(
				`/app/${REQUEST_TYPES_REVERSE?.[request_type] ?? ASSET_TYPES_REVERSE?.[asset_type]}/${path}`,
			),
		[navigate],
	);

	// delete confirmation function to remove item from local storage
	const handleDeleteConfirm = useCallback(
		() =>
			deleteById(itemToDelete?.id, itemToDelete?.path).finally(() => {
				setItemToDelete({});
				refetchChangesets();
			}),
		[deleteById, itemToDelete?.id, itemToDelete?.path, refetchChangesets],
	);

	usePageTitleHook('Synchronize');

	useEffect(() => {
		if (companyId) {
			refetchChangesets();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	return (
		<Stack spacing={2}>
			{!online && (
				<Alert severity='warning' color='warning'>
					No internet connection detected! You will not be able to sync data
					until you are online.
				</Alert>
			)}

			{hasChangeToCommit ? (
				<Grid container>
					<Grid item sx={12} sm={12} md={12} lg={12} xl={9} gap={2} spacing={1}>
						<List
							subheader={
								<Stack gap={1} pb={1}>
									<Body1>
										The following changes will be pushed to the server
									</Body1>
									<H5>
										{parentChanges.length ?? 0} sets of data to be uploaded
									</H5>
								</Stack>
							}
						>
							<Stack spacing={1} gap={1}>
								{parentChanges.map((item) => (
									<ChangeParentItem
										key={item.path}
										item={item}
										conflictsMap={conflictsMap}
										isOffline={!online}
										onEditClick={onEditClick}
										changes={changes}
										onDeleteClick={setItemToDelete}
										refetchChangesets={refetchChangesets}
									/>
								))}
								<FilledButton
									handleClick={commitChanges}
									disabled={commitDisabled}
									loading={isLoading}
								>
									Commit
								</FilledButton>
							</Stack>
						</List>
						<Confirm
							open={!!Object.keys(itemToDelete).length}
							onConfirm={handleDeleteConfirm}
							onCancel={() => setItemToDelete({})}
						>
							<H5>
								{`Delete ${(
									ASSET_TYPES_REVERSE?.[itemToDelete?.asset_type] ??
									itemToDelete?.id ??
									''
								).toUpperCase()} - ${itemToDelete?.path}`}
							</H5>
						</Confirm>
					</Grid>
				</Grid>
			) : (
				<Alert severity='success' color='success'>
					No changes to commit yet!
				</Alert>
			)}
		</Stack>
	);
}

export default withAppLayout(withExtendedDialogCard(Sync));

function ChangeParentItem({
	item,
	conflictsMap,
	onEditClick,
	onDeleteClick,
	changes,
	refetchChangesets,
}) {
	const subItems = useMemo(
		() => changes.filter((a) => a?.parent_asset_id === item.path),
		[changes, item.path],
	);
	const conflict = useMemo(
		() =>
			subItems.some(
				(a) => a?.parent_asset_id === item.path && conflictsMap?.[a.path],
			) && conflictsMap?.[item.path],
		[conflictsMap, item.path, subItems],
	);

	console.log('conflictsMap?.[item.path]', conflictsMap, item, changes);
	// if has conflict make it open
	const [open, toggle] = useToggleState(
		!!conflictsMap?.[item.path]?.errorPayload,
	);

	const getConflictData = useCallback(
		(item) => {
			return conflictsMap?.[item.path]?.errorPayload ?? item;
		},
		[conflictsMap],
	);

	return (
		<Card key={item.path} variant='outlined'>
			<Box sx={{ py: 0.5 }}>
				<Stepper
					orientation='vertical'
					sx={{
						'& .MuiStepContent-root': {
							marginLeft: '20px', // align conte
							paddingLeft: '0px', // align content start with tit
							paddingRight: '0px',
						},
						'& .MuiStepConnector-lineVertical': {
							marginLeft: '8px',
						},
						'& .MuiStepLabel-root': {
							// padding: '0px',
							margin: '-8px 0px',
						},
						padding: '0.5rem 0 0.5rem 0.5rem',
					}}
				>
					<Step active>
						<CustomStepContent
							item={item}
							showDivider={false}
							conflictsMap={conflictsMap}
							onEditClick={onEditClick}
							onDeleteClick={onDeleteClick}
							actions={
								conflictsMap?.[item.path]?.errorPayload ? (
									<IconButton size='small' onClick={() => toggle()}>
										{open ? <ExpandLess /> : <ExpandMore />}
									</IconButton>
								) : null
							}
						>
							<Collapse in={open} timeout='auto' unmountOnExit>
								<StepContent>
									<SyncChanges
										refetchChangesets={refetchChangesets}
										oldData={item}
										newData={getConflictData(item)}
									/>
								</StepContent>
							</Collapse>
						</CustomStepContent>
					</Step>
					<Collapse in={open} timeout='auto' unmountOnExit>
						{subItems.map((subItem) => (
							<Step active key={subItem.path}>
								<ChangeSubItem
									refetchChangesets={refetchChangesets}
									subItem={subItem}
									conflictsMap={conflictsMap}
									onEditClick={onEditClick}
									onDeleteClick={onDeleteClick}
								/>
							</Step>
						))}
					</Collapse>
				</Stepper>
			</Box>
		</Card>
	);
}

function ChangeSubItem({
	subItem,
	conflictsMap,
	onEditClick,
	onDeleteClick,
	refetchChangesets,
}) {
	// if error in sub item show it open
	const conflict = conflictsMap?.[subItem.path];
	console.log('subitem', conflict, conflictsMap, subItem);
	const [open, toggle] = useToggleState(!!conflict?.id);
	return (
		<CustomStepContent
			item={subItem}
			conflictsMap={conflictsMap}
			onEditClick={onEditClick}
			onDeleteClick={onDeleteClick}
			showDivider={false}
			actions={
				<IconButton size='small' onClick={() => toggle()}>
					{open ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			}
		>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<StepContent>
					<SyncChanges
						refetchChangesets={refetchChangesets}
						oldData={subItem}
						newData={conflict?.errorPayload ?? subItem}
					/>
				</StepContent>
			</Collapse>
		</CustomStepContent>
	);
}

function CustomStepContent({
	item,
	conflictsMap,
	onEditClick,
	onDeleteClick,
	actions,
	showDivider = true,
	children,
}) {
	const isParent = !item?.parent_asset_id;
	return (
		<>
			<StepLabel
				sx={{ padding: '0' }}
				StepIconComponent={() => (
					<Avatar
						sx={{
							bgcolor: isParent ? 'whitegray' : 'white',
							border: '1px solid',
							borderColor: !isParent ? 'gray' : 'white',
						}}
					>
						<SourceTypeIcon
							type={
								REQUEST_TYPES_REVERSE?.[item?.request_type] ??
								ASSET_TYPES_REVERSE?.[item?.asset_type]
							}
						/>
					</Avatar>
				)}
			>
				<FlexBox flexDirection='row' alignItems='center'>
					<Box>
						<Typography>{item?.rw_asset_id ?? item?.rw_request_id}</Typography>
						<Typography
							variant='body2'
							color={conflictsMap?.[item.path] ? 'error' : 'inherit'}
						>
							{conflictsMap?.[item.path]?.errorMessage ??
								new Date(
									item?.asset_checkin_date ?? item?.request_created,
								).toLocaleString()}
						</Typography>
					</Box>
					<Box>
						<IconButton size='small' onClick={() => onEditClick(item)}>
							<EditIcon />
						</IconButton>
						<IconButton size='small' onClick={() => onDeleteClick(item)}>
							<DeleteIcon />
						</IconButton>
						{actions}
					</Box>
				</FlexBox>
				<Box sx={{ height: 0, pt: 0.5 }}>
					<Divider hidden={!showDivider} />
				</Box>
			</StepLabel>
			{children}
		</>
	);
}
