import {
	ArrowCircleLeft,
	ArrowCircleRight,
	CheckBoxOutlineBlank,
	CheckBoxOutlined,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, H5, Overline } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import styles from '@pw/styles/modal.styles';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import chunk from 'lodash.chunk';
import compact from 'lodash.compact';
import { useCallback, useEffect, useState } from 'react';

function SelectAll({ selected, setSelected }) {
	return (
		<FlexBox justifyContent='end'>
			{selected && <CheckBoxOutlined onClick={() => setSelected(false)} />}
			{!selected && <CheckBoxOutlineBlank onClick={() => setSelected(true)} />}
			<Body1>Select All</Body1>
		</FlexBox>
	);
}

function RequestAssetSelectorModal({ assets, open, onClose }) {
	const [pages] = useState(chunk(assets, 5));
	const [page, setPage] = useState(0);

	const [selected, setSelected] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	const isSelected = useCallback(
		(x) => !!selected.find((i) => i.path === x.path),
		[selected],
	);

	const flipSelected = useCallback(
		(x) => {
			setSelected((v) => {
				const index = v.findIndex((i) => i.path === x.path);
				if (index >= 0) {
					delete v[index];
					return compact(v);
				}
				return [...v, x];
			});
		},
		[setSelected],
	);

	useEffect(() => {
		if (selectAll) {
			setSelected(assets);
		} else {
			setSelected([]);
		}
	}, [selectAll]);

	const handleSubmit = () => {
		// Map through each selected asset to structure it with possible child assets if applicable
		const structuredAssets = selected.map(asset => {
			// Check if the asset is of type pallet or container to include its child assets
			if ([ASSET_TYPES.pallet, ASSET_TYPES.container].includes(asset?.asset_type)) {
			  const childAssets = selected.filter(child => child?.parent_asset_id === asset?.path);
			  return toTaggedAsset(
				{ ...asset, children: childAssets },
				asset?.processed === ASSET_PROCESSED_STATUS.CONFIRMED
			  );
			}
			return toTaggedAsset(asset, asset?.processed === ASSET_PROCESSED_STATUS.CONFIRMED);
		});
		// Pass the structured assets to the onClose handler
		onClose(structuredAssets);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Select Assets</H5>
					<IconButton
						onClick={() => onClose(null)}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<FlexBox>
					<SelectAll setSelected={setSelectAll} selected={selectAll} />
				</FlexBox>
				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<Stack className='list'>
						{(pages[page] ?? []).map((i) => (
							<AssetListComponentV2
								key={i.path}
								item={i}
								selected={isSelected}
								buttonIcon={
									isSelected(i) ? (
										<CheckBoxOutlined />
									) : (
										<CheckBoxOutlineBlank />
									)
								}
								onClick={() => flipSelected(i)}
							/>
						))}
					</Stack>
				</Box>
				{pages.length > 1 && (
					<FlexBox>
						<IconCircleButton
							onClick={() => setPage((v) => v - 1)}
							disabled={page === 0}
						>
							<ArrowCircleLeft />
						</IconCircleButton>

						{assets.length > 1 && <Overline>{assets.length} Items</Overline>}

						<IconCircleButton
							onClick={() => setPage((v) => v + 1)}
							disabled={page + 1 === pages.length}
						>
							<ArrowCircleRight />
						</IconCircleButton>
					</FlexBox>
				)}

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton
						size='small'
						disabled={selected.length === 0}
						handleClick={handleSubmit}
					>
						OK
					</FilledButton>
				</Box>
			</Stack>
		</Modal>
	);
}

export default RequestAssetSelectorModal;
