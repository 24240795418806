function SvgLinks(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<g data-name='ic Links'>
				<path
					className='links_svg__cls-1'
					d='M49.34 57.46H3.01c-.85 0-1.53-.68-1.53-1.53V9.51c0-.85.68-1.53 1.53-1.53h26.53v3.06H4.55V54.4h43.26V27.14h3.06v28.79c0 .85-.68 1.53-1.53 1.53z'
					data-name='Path 9248'
				/>
				<g data-name='Group 381'>
					<path
						className='links_svg__cls-1'
						d='M26.88 33.8l-2.17-2.17L55.9.45c.44-.44 1.1-.57 1.67-.33.57.24.95.8.95 1.41v18.99h-3.06V5.23L26.89 33.8z'
						data-name='Path 9249'
					/>
					<path
						className='links_svg__cls-1'
						d='M37.66 0h19.33v3.06H37.66z'
						data-name='Path 9250'
					/>
				</g>
			</g>
		</svg>
	);
}
export default SvgLinks;
