import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const PUB_BASE = `${APP_API_GATEWAY}/accounts-pub`;
const PRIV_BASE = `${APP_API_GATEWAY}/accounts`;

// Passkey
export const registrationOptions = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/passkey${objectToQueryString(params)}`, headers);
export const registerCredentials = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/passkey`, headers, request);
export const authenticationOptions = async (params, headers = {}) =>
	fetchGet(`${PUB_BASE}/passkey${objectToQueryString(params)}`, headers);
export const authenticateCredentials = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/passkey`, headers, request);

// Registration
export const challengeIdent = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/register/challenge`, headers, request);
export const verifyIdent = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/register/verify`, headers, request);
export const verifySocials = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/register/social`, headers, request);
export const registerUser = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/register`, headers, request);
export const recover = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/recover`, headers, request);

// Sign in
export const signin = async (request, headers = {}) =>
	fetchPost(`${PUB_BASE}/signin`, headers, request);

// export const handleInvite = async (request, headers = {}) => fetchPost(`${PRIV_BASE}/user/setup`, headers, request);

export const refreshToken = async (request = {}, headers = {}) =>
	fetchPost(`${PRIV_BASE}/refresh?${new Date().getTime()}`, headers, request);

export const logout = async (headers = {}) => {
	return await fetchPost(`${PRIV_BASE}/logout?${new Date().getTime()}`, headers);
};
