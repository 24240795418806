import { Typography } from '@mui/material';
import { f6 } from '@pw/styles/fonts';
import splitToParagraphs from '@pw/utilities/splitToParagraphs';
import { forwardRef } from 'react';

// @ts-ignore
export const H1 = forwardRef(({ children, ...props }, ref) => <Typography ref={ref} variant='h1' {...props}>{children}</Typography>);
H1.displayName = 'H1';

// @ts-ignore
export const H2 = forwardRef(({ children, ...props }, ref) => <Typography ref={ref} variant='h2' {...props}>{children}</Typography>);
H2.displayName = 'H2';

// @ts-ignore
export const H3 = forwardRef(({ children, ...props }, ref) => <Typography ref={ref} variant='h3' {...props}>{children}</Typography>);
H3.displayName = 'H3';

// @ts-ignore
export const H4 = forwardRef(({ children, ...props }, ref) => <Typography ref={ref} variant='h4' {...props}>{children}</Typography>);
H4.displayName = 'H4';

// @ts-ignore
export const H5 = forwardRef(({ children, ...props }, ref) => <Typography ref={ref} variant='h5' {...props}>{children}</Typography>);
H5.displayName = 'H5';

// @ts-ignore
export const H6 = forwardRef(({ children, ...props }, ref) => <Typography ref={ref} variant='h6' {...props}>{children}</Typography>);
H6.displayName = 'H6';

// @ts-ignore
export const Button = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='button' {...props}>{children}</Typography>
));
Button.displayName = 'Button';

// @ts-ignore
export const Body1 = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='body1' {...props}>{children}</Typography>
));
Body1.displayName = 'Body1';

// @ts-ignore
export const Body2 = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='body2' {...props}>{children}</Typography>
));
Body2.displayName = 'Body2';

// @ts-ignore
export const Body3 = forwardRef(({ children, sx = {}, ...props }, ref) => (
	<Typography ref={ref} variant='body1' sx={{ ...f6, ...sx }} {...props}>{children}</Typography>
));
Body3.displayName = 'Body3';

// @ts-ignore
export const Caption = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='caption' {...props}>{children}</Typography>
));
Caption.displayName = 'Caption';

// @ts-ignore
export const Overline = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='overline' {...props}>{children}</Typography>
));
Overline.displayName = 'Overline';

// @ts-ignore
export const Subtitle1 = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='subtitle1' {...props}>{children}</Typography>
));
Subtitle1.displayName = 'Subtitle1';

// @ts-ignore
export const Subtitle2 = forwardRef(({ children, ...props }, ref) => (
	<Typography ref={ref} variant='subtitle2' {...props}>{children}</Typography>
));
Subtitle2.displayName = 'Subtitle2';

export const Paragraphs = forwardRef((props, ref) => {
	// @ts-ignore
	const { text, as = Body1, ...rest } = props;
	if (!text || text.length === 0) return null;
	const Component = as;
	// if array or string
	let paras = text;
	// if array of one, unwrap
	if (typeof text === 'object' && text.length === 1) {
		paras = text[0];
	}
	// if string, split. if already array, assumes no need for splitting
	if (typeof paras === 'string') {
		paras = splitToParagraphs(paras);
	}
	// eslint-disable-next-line react/no-array-index-key
	return paras.map((p, i) => (
		<Component key={i} ref={ref} {...rest}>
			{p}
		</Component>
	));
});
Paragraphs.displayName = 'Paragraphs';
