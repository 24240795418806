function SvgDownload(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 14 13.31'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M6.3 8.07v-.19V.82c0-.1 0-.21.03-.31.09-.34.41-.54.77-.5.33.04.58.32.6.66v7.44c.07-.06.11-.1.15-.14.71-.71 1.42-1.43 2.13-2.14.32-.32.77-.31 1.05 0 .24.27.24.66 0 .93-.09.1-.18.19-.28.28l-3.18 3.18c-.38.38-.76.38-1.14 0l-3.4-3.4c-.31-.32-.31-.77 0-1.05.29-.26.7-.24 1 .06l1.73 1.73.51.51c.01 0 .03-.01.04-.02zM7 13.31H.74c-.44 0-.75-.3-.74-.72 0-.33.24-.6.56-.66.09-.02.17-.02.26-.02h12.36c.12 0 .24 0 .36.04.32.1.5.41.46.76-.04.32-.32.57-.65.59H7z' />
		</svg>
	);
}
export default SvgDownload;
