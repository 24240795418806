import pako from 'pako';

const useEncoder = () => {
  const encode = (input) => {
    // Convert the input string to a Uint8Array
    const inputBytes = new TextEncoder().encode(input);

    // Compress the input using gzip
    const compressed = pako.gzip(inputBytes);

    // Convert the compressed data to a Base64 string
    let base64String = btoa(String.fromCharCode(...compressed));

    // Make it URL-safe
    base64String = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    return base64String;
  };

  const decode = (base64String) => {
    // Make it standard Base64
    base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');

    // Add padding if necessary
    const padding = 4 - (base64String.length % 4);
    if (padding < 4) {
      base64String += '='.repeat(padding);
    }

    // Decode the Base64 string to a binary string
    const binaryString = atob(base64String);

    // Convert the binary string to a Uint8Array
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Decompress the gzip data
    return pako.ungzip(bytes, { to: 'string' });
  };

  return { encode, decode };
}



export default useEncoder;
