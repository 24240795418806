import * as yup from 'yup';

export default function liquidWeightFields(level = {}) {
	const {
		enable = false,
		weightFactor = '',
	} = level;

	return {
		enable: [enable, yup.bool()],
		weightFactor: [
			weightFactor,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0, 'Invalid!').required('Weight Factor is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
