import { useContext } from 'react';
import { useNodeDataContext } from '../NodeDataContext';
import DesignerContext from '@pw/context/DesignerContext';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import ObservationForm from '../ScheduledObservation';
import { TEMPERATURE_UNIT_OPTIONS } from '@pw/consts/measures';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import NodeModalForm from './NodeModalForm';
import NodeConfigForm from './NodeConfigForm';
import * as yup from 'yup';

const TemperatureForm = () => {
  const { mode } = useContext(DesignerContext);
  const { nodeData = {}, batch, nodeId } = useNodeDataContext();

  const {
    temperature = { value: '', unit: '' },
  } = nodeData;
  const changeSet = {
    temperature: unitField(temperature),
    observation_name: ['TemperatureForm', yup.string()],
  };
  const fieldNames = Object.keys(changeSet);

  if (mode === 'design') {
    return <NodeConfigForm changeSet={changeSet} />;
  }

  return (
      <NodeModalForm changeSet={changeSet}>
        <ObservationForm
          nodeData={nodeData}
          fieldNames={fieldNames}
        >
          <FlexBox>
            <UnitField
              label='Temperature'
              name='temperature'
              options={TEMPERATURE_UNIT_OPTIONS}
            />
          </FlexBox>
        </ObservationForm>
      </NodeModalForm>
    )
}

export default TemperatureForm;
