import DDMenu from '@pw/components/Menu';

export function BaseMenu({ items, idSuffix, ...props }) {
	return (
		<DDMenu
			items={items}
			idSuffix={idSuffix}
			label='...'
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			{...props}
		/>
	);
}
