import { Divider, Stack } from '@mui/material';
import { useAccount } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect } from 'react';
import Security from './Security';
import Settings from './Settings';

function AccountSettings() {
  const [target, scroll] = useScrollTarget();

  const user = useAccount();

  useEffect(() => scroll(), [user]);

  return (
    <Stack sx={styles} className="root" spacing="2rem" ref={target}>
      <Settings />
      <Divider className="divider" />
      <Security />
    </Stack>
  );
}

export default AccountSettings;
