const styles = {
	'&.root': {
		width: '100%',
		color: 'white',
		fill: 'white',

		'.menu-group': {
			'.menu-group-header': {
				marginBottom: '0.15rem',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'top',
				cursor: 'pointer',

				'.icon': {
					color: '#fff',
					fill: '#fff',
					minWidth: '50px',
					display: 'flex',
					alignItems: 'center',
					paddingLeft: '8px',
					'*': {
						color: '#fff',
						fill: '#fff',
						width: '24px',
						height: '24px',
					},
				},
				'.label': {
					flexGrow: 1,
					paddingTop: '0.25rem',
					paddingBottom: '0.25rem',
					whiteSpace: 'nowrap',
					color: '#fff',
				},
				'.toggle': {
					float: 'right',
				},
			},
			'.menu-link': {
				color: '#fff',
				textDecoration: 'none',
			},
			'.menu-content': {
				display: 'flex',
				flexDirection: 'column',

				'&:before': {
					content: '" "',
					position: 'sticky',
					top: 0,
					left: 0,
					flexGrow: 1,
					minWidth: '100%',
					minHeight: '10px',
					zIndex: '100',
					'--shadow-color': '223deg 8% 11%',
					boxShadow: `
					inset 0px 0.5px 0.6px hsl(var(--shadow-color) / 0.23),
					inset 0px 1.3px 1.5px -0.8px hsl(var(--shadow-color) / 0.23),
					inset 0px 3.2px 3.6px -1.7px hsl(var(--shadow-color) / 0.23),
					inset 0px 4.7px 6.7px -2.5px hsl(var(--shadow-color) / 0.23)`,
				},

				'.menu-item': {
					height: 'min-content',
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
					borderRadius: '3px',
					paddingTop: '0.15rem',
					paddingBottom: '0.15rem',
					paddingLeft: '50px',
					gap: '0.5rem',
				},
			},

			'.menu-item:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.15)',
			},

			'.menu-item-selected': {
				fontWeight: '400',
				backgroundColor: 'rgba(255, 255, 255, 0.3)',
				border: '1px solid rgba(255, 255, 255, 0.4)',
			},

			'.menu-item-default': {
				border: '1px solid rgba(0, 0, 0, 0)',
			},

			'.menu-item-disabled': {
				fontWeight: '200',
				color: 'rgba(255, 255, 255, 0.45)',
				textColor: 'rgba(255, 255, 255, 0.45)',
			},
		},

		'.menu &gt; .menu-group': {
			borderTop: '1px solid rgba(255, 255, 255, 0.15)',
			paddingTop: '1rem',
			paddingBottom: '1rem',
		},
		'.menu &gt; .menu-group:first-of-type': {
			borderTop: 'none',
		},
		'.menu &gt; .menu-group:last-of-type': {
			borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
		},
	},
};

export default styles;
