import { CURRENCIES } from "@pw/consts/currencies";
import { UNITS } from "@pw/consts/units";
import useConverter from "@pw/utilities/hooks/logic/useConverter";
import { useCallback } from "react";

const useUKCompliance = () => {
  const converter = useConverter();
  const currency = CURRENCIES.GBP;
  const date = Date.parse('2024-09-01');

  const getTaxCode = useCallback((liquid, abv) => {
    const convertedAbv = converter.cx(abv.value, abv.unit, UNITS.abv);
    
    switch (liquid.category) {
      case 'rum':
      case 'gin':
      case 'vodka':
      case 'brandy':
      case 'whisky': {
        // Based on abv..
        // Spirits less than 3.5% ABV
        // Spirits less than 3.5 % ABV and eligible for reduced rates of duty for draught products	8.42
        if (convertedAbv < 3.5) {
          return {
            date,
            code: 'uk/spirits<3.5',
            rate: [{ rate: 9.27, tax_code: '315' }, { rate: 8.42, tax_code: '355' }],    // primary rate and reduced rate
            currency,
            name: 'Spirits less than 3.5 % ABV',
            unit: UNITS.liters,
          }
        }
        // Spirits at least 3.5 % ABV but less than 8.5 % ABV	24.77
        // Spirits at least 3.5 % ABV but less than 8.5 % ABV and eligible for reduced rates of duty for draught products	19.08
        if (convertedAbv >= 3.5 && convertedAbv < 8.5) {
          return {
            date,
            code: 'uk/spirits<8.5',
            rate: [{ rate: 24.77, tax_code: '325' }, { rate: 19.08, tax_code: '350' }],    // primary rate and reduced rate
            currency,
            name: 'Spirits at least 3.5 % ABV but less than 8.5 % ABV',
            unit: UNITS.liters,
          }
        } 
        // Spirits at least 8.5 % ABV but not exceeding 22 % ABV	28.5
        if (convertedAbv >= 8.5 && convertedAbv <= 22) {
          return {
            date,
            code: 'uk/spirits<=22',
            rate: [{ rate: 28.5, tax_code: '325' }],
            currency,
            name: 'Spirits at least 8.5 % ABV but not exceeding 22 % ABV',
            unit: UNITS.liters,
          }
        }
        // Spirits exceeding 22 % ABV	31.64
        if (convertedAbv > 22) {
          return {
            date,
            code: 'uk/spirits>22',
            rate: [{ rate: 31.64, tax_code: '345' }],    // primary rate
            currency,
            name: 'Spirits exceeding 22 % ABV',
            unit: UNITS.liters,
          }
        }
      }
      default:
        break;
    }
    return null;
  }, [converter]);  

  return { getTaxCode }
};

export default useUKCompliance;
