import {
	FormikNumberField,
	FormikSelect,
} from '@pw/components/Forms/FormikForm';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';
import PropTypes from 'prop-types';

export function unitField(params = {}) {
	const { value = '', unit = '' } = params;

	return {
		value: [value, yup.string()],
		unit: [unit, yup.string()],
	};
}

function UnitField({
	name = 'unit',
	label = 'Unit Field',
	options = [],
	disabled = false,
}) {
	return (
		<FormikProvider path={name}>
			<FormikNumberField
				label={label}
				name='value'
				fullWidth
				disabled={disabled}
				InputProps={{
					endAdornment: (
						<FormikSelect
							label='Unit'
							name='unit'
							options={options}
							disabled={disabled}
							sx={{
								minWidth: '2rem',
								// marginRight: '-0.4rem',
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
							}}
						/>
					),
				}}
			/>
		</FormikProvider>
	);
}
UnitField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array,
	disabled: PropTypes.bool,
};

export default UnitField;
