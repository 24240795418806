import Box from '@mui/material/Box';
import TimeLine from './Timeline';

const ProductionSchedule = () => (
  <Box>
    <TimeLine />
  </Box>
)

export default ProductionSchedule;
