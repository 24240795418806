import { useAccountPerms } from '@pw/redux/containers/User';
import { checkPermission } from '@pw/utilities/checkPermission';

export function checkPermissions(permissions = [], accountPerms) {
	return permissions.some((permission) =>
		checkPermission(accountPerms, permission),
	);
}

export function useAccountHasPermission(permissions = []) {
	const accountPerms = useAccountPerms();
	permissions = Array.isArray(permissions) ? permissions : [permissions];
	if (permissions.length === 0) {
		return true;
	}
	return checkPermissions(permissions, accountPerms);
}

export default useAccountHasPermission;
