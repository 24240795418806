const styles = {
	'.container': {
		backgroundColor: 'black',
		padding: '3px',

		'.label': {
			backgroundColor: 'black',
			display: 'grid',
			gridTemplateColumns: 'repeat(6, 1fr)',
			gridTemplateRows: 'repeat(15, 1.4rem)',
			width: '100%',
			pageBreakAfter: 'always',
			gap: '3px',

			'.upcBarcode': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'white',
				alignContent: 'center',
			},

			'.long': {
				backgroundColor: 'white',
				textAlign: 'start',
				color: 'black',
				padding: '2px',
				alignContent: 'center',
			},
		},
	},
};

export default styles;
