import { BATCH_TYPE } from '@pw/consts/batch';
import { TASK_TYPES } from '@pw/consts/tasks';
import { br3 } from '@pw/styles/border';
import { bg_surface_4 } from '@pw/styles/colors';
import { mb1, mt1 } from '@pw/styles/margins';
import { pa2 } from '@pw/styles/padding';

const styles = {
	[`&[action="${TASK_TYPES.cask}"]`]: { '--action-color': '#4C4' },
	[`&[action="${TASK_TYPES.ibc}"]`]: { '--action-color': '#2a58af' },
	[`&[action="${TASK_TYPES.pallet}"]`]: { '--action-color': '#5c1479' },
	[`&[action="${TASK_TYPES.container}"]`]: { '--action-color': '#44880a' },
	[`&[action="${TASK_TYPES.pick}"]`]: { '--action-color': '#00a89d' },
	[`&[action="${TASK_TYPES.regauge}"]`]: { '--action-color': '#1a3352' },
	[`&[action="${TASK_TYPES.sample}"]`]: { '--action-color': '#fd3b02' },
	[`&[action="${TASK_TYPES.ship}"]`]: { '--action-color': '#C9C9C2' },
	[`&[action="${TASK_TYPES.transfer}"]`]: { '--action-color': '#D0B158' },
	[`&[action="${TASK_TYPES.production_run}"]`]: { '--action-color': '#775b0d' },
	[`&[action="${BATCH_TYPE}"]`]: { '--action-color': '#616e79' },
	[`&[action="${TASK_TYPES.tanker}"]`]: { '--action-color': '#7a4507' },
	[`&[action="${TASK_TYPES.filling_tank}"]`]: { '--action-color': '#c24f1e' },
	[`&[action="${TASK_TYPES.restock}"]`]: { '--action-color': '#6e0606' },
	[`&[action="${TASK_TYPES.change_ownership}"]`]: {
		'--action-color': '#60b4f1',
	},
	[`&[action="${TASK_TYPES.inventory_audit}"]`]: {
		'--action-color': '#7a136d',
	},
	[`&[action="${TASK_TYPES.delivery}"]`]: { '--action-color': '#a99223' },
	[`&[action="${TASK_TYPES.duty_transfer}"]`]: { '--action-color': '#1a1a1a' },
	[`&[action="${TASK_TYPES.duty_submission}"]`]: {
		'--action-color': '#1a1a1a',
	},
	[`&[action="${TASK_TYPES.report_defect}"]`]: { '--action-color': '#a99223' },
	[`&[action="${TASK_TYPES.invoice}"]`]: { '--action-color': '#1a1a1a' },
	[`&[action="${TASK_TYPES.purchase_order}"]`]: { '--action-color': '#ba2b2b' },
	[`&[action="${TASK_TYPES.expense_report}"]`]: { '--action-color': '#d99223' },
	'&.root': {
		...br3,
		...bg_surface_4,
		boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.3),
    0px 1px 1px 0px rgba(0, 0, 0, 0.24),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12)`,

		display: 'flex',
		flexDirection: 'row',
		backgroundColor: '#fff',
		overflow: 'hidden',
		transition: 'all 0.2s',
		height: 'max-content',

		'&:hover': {
			boxShadow: `0px 2px 1px -1px rgba(0, 0, 0, 0.4),
      0px 1px 1px 0px rgba(0, 0, 0, 0.34),
      0px 1px 3px 0px rgba(0, 0, 0, 0.22)`,
			cursor: 'pointer',
		},
	},
	'.asset-id': {
		...mb1,
		display: 'flex',
		alignItems: 'center',
		svg: {
			verticalAlign: 'middle',
		},
	},
	'.tab': {
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'stretch',
		backgroundColor: 'var(--action-color)',
		color: '#fff',
		fill: '#fff',
		borderLeft: '0.2rem solid var(--action-color)',
		borderRight: '0.2rem solid var(--action-color)',
		'*': {
			color: 'inherit',
			fill: 'currentColor',
		},
	},
	'.task-card-content': {
		...pa2,
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	'.check': {
		width: '16px',
		height: 'auto',
	},
	'.header': {
		height: 'min-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: 0,
		paddingBottom: 0,
	},
	'.body': {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		gap: '0.25rem',
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: '0.5em',
		paddingBottom: 0,
	},
	'.footer': {
		height: 'min-content',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	'.action': {
		fontWeight: 700,
		position: 'relative',
		paddingRight: '0.25rem',
		overflowWrap: 'break-word',
		color: 'var(--action-color)',
		// ...pa0,
	},
	'.date': {
		fontWeight: 300,
		padding: 0,
	},
	'.row': {
		...mt1,
		wordWrap: 'break-word',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	'.bold': {
		fontWeight: '600',
		textTransform: 'capitalize',
	},
	'.assigneeList': {
		'.avatar': {
			height: 18,
			width: 18,
			marginLeft: 0,
			fontSize: 9,
			backgroundColor: '#e6e6e6',
			color: '#1a3352',
			textTransform: 'uppercase',
			lineHeight: '18px',
		},
	},
};

export default styles;
