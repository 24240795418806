import Box from '@mui/material/Box';
import styles from './styles';

export function DialogCard({ children }) {
	return (
		<Box sx={styles} className='dialog-card'>
			{children}
		</Box>
	);
}

const withDialogCard = (WrappedComponent) =>
	function WithDialogCard(props) {
		return (
			<DialogCard>
				<WrappedComponent {...props} />
			</DialogCard>
		);
	};

export default withDialogCard;
