import { ArrowForward, DownloadOutlined } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { ASSET_EDIT_URL, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { UNIT } from '@pw/consts/units';
import { getAsset } from '@pw/services/asset.service';
import downloadAssetCsv from '@pw/utilities/downloadAssetCsv';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import pathToParams from '@pw/utilities/pathToParams';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ReportAssetTable({ assetIds }) {
	const { t } = useTranslation();
	const converter = useConverter();
	const [items, setItems] = useState([]);

	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const loadAssets = useCallback(async () => {
		const arr = assetIds.map((id) => getAsset(pathToParams(id)));
		setLoading(true);
		const res = await Promise.all([...arr]);
		setLoading(false);

		setItems(
			res.map((i) => {
				const { path, rw_asset_id, properties = {}, unique_location_id, parent_asset } = i;
				const [
					type = '0',
					facility = '0',
					bay = '0',
					row = '0',
					locationLevel = '0',
				] = unique_location_id ? unique_location_id.split('/') : [];
				const assetType =
					ASSET_TYPES_REVERSE[i?.asset_type] ?? i?.asset_type ?? '';

				const { liquid = {}, sku: barrelSku, reference } = properties ?? {};

				const { level = {}, sku: liquidSku } = liquid;
				const { enable = false, bl, abv, la, expected_bl, tcf } = level;

				const bl_unit = converter.unit(UNIT.BL);
				const la_unit = converter.unit(UNIT.LA);
				const abv_unit = converter.unit(UNIT.ALC);

				const laVal = converter.to(la, UNIT.LA);
				const bulkVal = converter.to(bl, UNIT.BL);
				const abvVal = converter.to(abv, UNIT.ALC);

				const location =
					type !== '0'
						? `${type}/${facility}/${bay}/${row}/${locationLevel}`
						: 'No location';
				return {
					id: rw_asset_id,
					path,
					asset_type: i.asset_type,
					type: assetType,
					reference,
					bulk: enable && bulkVal ? `${bulkVal?.toFixed(3)}${bl_unit}` : '',
					la: enable && laVal ? `${laVal?.toFixed(3)}${la_unit}` : '',
					abv: enable && abvVal ? `${abvVal?.toFixed(2)}${abv_unit}` : '',
					tcf: enable && tcf ? Number(tcf)?.toFixed(3) : '1.000',
					barrelSku: barrelSku?.sku_name,
					liquidSku: liquidSku?.sku_name,
					parent: parent_asset?.rw_asset_id,
					location,
				};
			}),
		);
	}, [assetIds]);

	useEffect(() => {
		if (assetIds?.length) loadAssets();
		else setLoading(false);
	}, []);

	const goToItem = (params) => {
		const id = params?.path ?? params?.asset_id ?? 'new';
		const route = ASSET_EDIT_URL?.[params?.asset_type];
		navigate(`${route}/${id}`, { replace: true });
	};

	const onExport = useCallback(() => {
		downloadAssetCsv(items, t);
	}, [items]);

	return (
		<Stack sx={{ width: '100%' }} spacing={2}>
			<FlexBox sx={{ justifyContent: 'space-between' }}>
				<H5>Assets</H5>

				<TitledButton
					handleClick={onExport}
					label='CSV'
					disabled={loading}
				>
					<DownloadOutlined height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box sx={{ overflow: 'auto' }}>
				<Table sx={{ width: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Reference</TableCell>
							<TableCell align='right'>Type</TableCell>
							<TableCell align='right'>{t('bulk')}</TableCell>
							<TableCell align='right'>{t('alcohol')}</TableCell>
							<TableCell align='right'>{t('strength')}</TableCell>
							<TableCell align='right'>TCF</TableCell>
							<TableCell align='right'>Barrel</TableCell>
							<TableCell align='right'>Liquid</TableCell>
							<TableCell align='right'>Parent</TableCell>
							<TableCell align='right'>Location</TableCell>
							<TableCell align='right' />
						</TableRow>
					</TableHead>

					{loading && (
						<TableBody>
							<TableRow>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
								<TableCell>
									<Skeleton variant='rectangular' sx={{ my: 4, mx: 1 }} />
								</TableCell>
							</TableRow>
						</TableBody>
					)}

					{!loading && (
						<TableBody>
							{items?.map((row) => (
								<TableRow
									key={row.path}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component='th' scope='row'>
										{row.id}
									</TableCell>
									<TableCell>{row.reference}</TableCell>
									<TableCell align='right'>{row.type}</TableCell>
									<TableCell align='right'>{row.bulk}</TableCell>
									<TableCell align='right'>{row.la}</TableCell>
									<TableCell align='right'>{row.abv}</TableCell>
									<TableCell align='right'>{row.tcf}</TableCell>
									<TableCell align='right'>{row.barrelSku}</TableCell>
									<TableCell align='right'>{row.liquidSku}</TableCell>
									<TableCell align='right'>{row.parent}</TableCell>
									<TableCell align='right'>{row.location}</TableCell>
									<TableCell align='right'>
										<IconCircleButton onClick={() => goToItem(row)}>
											<ArrowForward />
										</IconCircleButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</Table>
			</Box>
		</Stack>
	);
}

export default ReportAssetTable;
