import part1ReportFields from './part1ReportFields';

export default function part1Fields(fields = {}) {
	const {
		On_Hand_First_of_Month = '',
		Received_Other_than_line_3 = '',
		Alcohol_for_Fuel_Use_Received_From_Customs_Custody = '',
		Alcohol_Flavor_Materials_Dumped = '',
		Wine_Mixed_with_Spirits = '',
		Dumped_for_Further_Processing = '',
		Gains = '',
		Bottled_or_Package = '',
		Wine_Mixed_With_Spirits = '',
		Used_for_Denaturation = '',
		Transferred_in_Bond = '',
		Withdrawn_Tax_Determined = '',
		Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use = '',
		Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine = '',
		For_Exportation_Transfer_to_CBW = '',
		Transferred_to_Production_Account_for_Redistillation = '',
		Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples = '',
		Destroyed = '',
		Used_for_Redistillation = '',
		Specify_21 = '',
		Specify_22 = '',
		Specify_23 = '',
		Losses = '',
		On_Hand_End_of_Month = '',
	} = fields;

	return {
		On_Hand_First_of_Month: part1ReportFields(On_Hand_First_of_Month),
		Received_Other_than_line_3: part1ReportFields(Received_Other_than_line_3),
		Alcohol_for_Fuel_Use_Received_From_Customs_Custody: part1ReportFields(
			Alcohol_for_Fuel_Use_Received_From_Customs_Custody,
		),
		Alcohol_Flavor_Materials_Dumped: part1ReportFields(
			Alcohol_Flavor_Materials_Dumped,
		),
		Wine_Mixed_with_Spirits: part1ReportFields(Wine_Mixed_with_Spirits),
		Dumped_for_Further_Processing: part1ReportFields(
			Dumped_for_Further_Processing,
		),
		Gains: part1ReportFields(Gains),
		Bottled_or_Package: part1ReportFields(Bottled_or_Package),
		Wine_Mixed_With_Spirits: part1ReportFields(Wine_Mixed_With_Spirits),
		Used_for_Denaturation: part1ReportFields(Used_for_Denaturation),
		Transferred_in_Bond: part1ReportFields(Transferred_in_Bond),
		Withdrawn_Tax_Determined: part1ReportFields(Withdrawn_Tax_Determined),
		Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use:
			part1ReportFields(
				Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use,
			),
		Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine: part1ReportFields(
			Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine,
		),
		For_Exportation_Transfer_to_CBW: part1ReportFields(
			For_Exportation_Transfer_to_CBW,
		),
		Transferred_to_Production_Account_for_Redistillation: part1ReportFields(
			Transferred_to_Production_Account_for_Redistillation,
		),
		Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples:
			part1ReportFields(
				Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples,
			),
		Destroyed: part1ReportFields(Destroyed),
		Used_for_Redistillation: part1ReportFields(Used_for_Redistillation),
		Specify_21: part1ReportFields(Specify_21),
		Specify_22: part1ReportFields(Specify_22),
		Specify_23: part1ReportFields(Specify_23),
		Losses: part1ReportFields(Losses),
		On_Hand_End_of_Month: part1ReportFields(On_Hand_End_of_Month),
	};
}
