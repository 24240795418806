import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts';
import { H5 } from '@pw/components/Typography';
import { useMemo } from 'react';

function AssetCountsWidget({ title, data }) {
	const { total_pallets = 0, total_ibcs = 0, total_casks = 0 } = data;

	const assets = useMemo(
		() => [
			{
				id: 0,
				label: 'Casks',
				value: Number(total_casks),
				color: '#41424c',
			},
			{
				id: 1,
				label: 'Pallets',
				value: Number(total_pallets),
				color: '#F0AA29',
			},
			{
				id: 2,
				label: 'IBC',
				value: Number(total_ibcs),
				color: '#fc7e49',
			},
		],
		[total_casks, total_ibcs, total_pallets],
	);

	return (
		<Stack className='content-section'>
			<H5 className='content-section-title'>{title}</H5>
			<Stack className='headline-widget-content' alignItems='center'>
				<PieChart
					series={[
						{
							data: assets,
							innerRadius: 55,
							outerRadius: 95,
							paddingAngle: 5,
							cornerRadius: 5,
							startAngle: -225,
							endAngle: 45,
							cx: 100,
							cy: 100,
							highlightScope: { faded: 'global', highlighted: 'item' },
							faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
						},
					]}
					width={250}
					height={220}
				/>
			</Stack>
		</Stack>
	);
}

export default AssetCountsWidget;
