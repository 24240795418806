import { createAsyncThunk } from '@reduxjs/toolkit';
import { setOnline } from '../containers/App';

export const initEventListenerThunk = createAsyncThunk(
  `init/initEventListenerThunk`,
  async (state, { fulfillWithValue, dispatch }) => {
		console.log('initEventListenerThunk', state);

		const toggleOnlineStatus = () => {
			console.log('toggleOnlineStatus', window.navigator.onLine);
			dispatch(setOnline(window.navigator.onLine));
		};

		window.addEventListener('online', toggleOnlineStatus);
		window.addEventListener('offline', toggleOnlineStatus);

    return fulfillWithValue(state);
  }
);
