import { Skeleton, Stack } from '@mui/material';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import useSKUStorageQuery from '@pw/utilities/hooks/service/useSKUStorageQuery';
import SKUConsumptionItemInfo from '../items/SKUConsumptionItemInfo';
import { SKU_TYPES } from '@pw/consts/sku';
import { useMemo } from 'react';
import { FlexBox } from '@pw/components/Layout/FlexBox';

function SKUConsumptionModal({ title, item, open, onClose, sku }) {
  const { sku_type, properties } = sku ?? {};
	const { unit } = properties ?? {};

	const uu = useMemo(
		() =>
			[
				SKU_TYPES.TRACKED,
				SKU_TYPES.FINISHED,
				SKU_TYPES.WIP,
				SKU_TYPES.SERVICE,
				SKU_TYPES.CONSUMABLE,
			].includes(sku_type)
				? undefined
				: unit,
		[sku_type],
	);

  const { data, isLoading } = useSKUStorageQuery({id: item?.id, path: item?.path});
  return (
    <ModalWithClose open={open} onClose={onClose} title={title}>
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-start' gap='2rem'>
						<Skeleton variant='rectangular' width={100} height={25} />
					</FlexBox>
          <Stack className='list'>
            <Skeleton variant='rectangular' width={150} height={80} 	sx={{ mb: 1 }} />
            <Skeleton variant='rectangular' width={150} height={80} />
          </Stack>
        </Stack>
      )}
      {!isLoading && (
        <SKUConsumptionItemInfo item={data} unit={uu} sku_type={sku_type} />
      )}
    </ModalWithClose>
  );
}
export default SKUConsumptionModal;
