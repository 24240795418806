import { CURRENCIES } from "@pw/consts/currencies";
import { UNITS } from "@pw/consts/units";
import useConverter from "@pw/utilities/hooks/logic/useConverter";
import { useCallback } from "react";

const useIRCompliance = () => {
  const converter = useConverter();
  const currency = CURRENCIES.EUR;
  const date = Date.parse('2024-01-01');

  const code = {
    ir_spirits: {
      date,
      code: 'ir/spirits',
      rate: [{ rate: 42.57 }],    // there is only one rate, no discounted rates
      currency,
      name: 'Spirits',
      unit: UNITS.liters,
    }
  };

  const getTaxCode = useCallback((liquid, _) => {
    // abv not required here
    // const convertedAbv = converter.cx(abv.value, abv.unit, UNITS.abv);

    // Based on category
    switch (liquid.category) {
      case 'rum':
      case 'gin':
      case 'vodka':
      case 'brandy':
      case 'whisky':
        return code.ir_spirits;
      default:
        break;
    }
    return null;
  }, [converter]);  

  return { getTaxCode }
};

export default useIRCompliance;
