import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withEmptyLayout } from '@pw/components/Layout/EmptyLayout';
import { Body2, H4 } from '@pw/components/Typography';

const styles = {
	'&.root': {
		padding: '2rem',
	},
};

function Custom426() {
	return (
		<Stack spacing={2} sx={styles} className='root'>
			<H4 color='error'>Function not Supported</H4>
			<Body2>Your current subscription does not include this function.</Body2>
			<Body2>
				Please contact
				{' '}
				<Link to='mailto:support@metacask.com'>support@metacask.com.</Link>
				{' '}
				to enable it for your subscription.
			</Body2>
		</Stack>
	);
}

export default withEmptyLayout(withDialogCard(Custom426), {
	title: 'Page not Found!',
});
