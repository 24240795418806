import Stack from '@mui/material/Stack';
import NABCALabelDisplay from '@pw/components/admin/SKUs/labels/NABCA/NABCALabelDisplay';
import StandardLabelDisplay from '@pw/components/admin/SKUs/labels/StandardLabel/StandardLabelDisplay';
import styles from '@pw/components/Auth/auth.styles';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withEmptyLayout } from '@pw/components/Layout/EmptyLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import useEncoder from '@pw/utilities/hooks/useEncoder';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function QRLocator() {
  const encoder = useEncoder();
  usePageTitleHook('QR');

  const [searchParams] = useSearchParams();
  const type = searchParams.get('t');
  const params = searchParams.get('p');

  console.log('Decoding', params);

  const qq = useMemo(() => JSON.parse(encoder.decode(params)), [params]);

  return (
    <Stack spacing={4} sx={styles} className='root'>
      {type === 'n' && (<NABCALabelDisplay params={qq} />)}
      {type === 's' && (<StandardLabelDisplay params={qq} />)}
    </Stack>
  );
}

export default withEmptyLayout(withDialogCard(QRLocator));
