import { Box, LinearProgress } from '@mui/material';
import { clamp } from '@mui/x-data-grid/internals';
import { Body3 } from '@pw/components/Typography';
import { useEffect, useState } from 'react';

const useProgress = ({ total }) => {
	const [totalValue, setTotalValue] = useState(parseInt(total, 10));
	const [progress, setProgress] = useState(0);
	const [completion, setCompletion] = useState(0);

	function ProgressBar(props) {

		useEffect(() => {
			let p = 0;
			if (totalValue !== 0) p = (progress / totalValue) * 100.0;
			setCompletion(clamp(p, 0, 100));
			if (totalValue <= 0) {
				return;
			}
			if (progress <= 0) {
				return;
			}
		}, [totalValue]);

		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Box sx={{ width: '100%', mr: 1, height: '8px' }}>
					<LinearProgress
						variant='determinate'
						color={completion < 100 ? 'primary' : 'success'}
						{...props}
						value={completion}
					/>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Body3 color='secondary'>{`${Math.round(completion)}%`}</Body3>
				</Box>
			</Box>
		);
	}

	return [ProgressBar, { completion, setProgress, setTotal: (v) => setTotalValue(parseInt(v, 10)) }];
};

export default useProgress;
