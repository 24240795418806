function ContractsIcon(props) {
	return (
		<svg
			{...props}
			className='MuiSvgIcon-root'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
		>
			<defs>
				<style>
					{`.cls-1 {
        stroke-width: 0px;
      }`}
				</style>
			</defs>
			<path
				className='cls-1'
				d='M122.92,122.13c0-34.04.01-68.07,0-102.11,0-9.39-7.34-16.75-16.71-16.76-9.74,0-19.48,0-29.22,0-8.28,0-16.56,0-24.84,0h-.02c-4.91,0-9.36,1.59-13.21,4.71-5.97,4.85-11.41,9.38-16.64,13.85-4.92,4.21-7.43,9.54-7.44,15.84-.06,25.32-.06,52.34,0,85.04,0,5.22,2.2,9.62,6.5,13.08,2.25,1.81,4.97,2.72,7.38,3.37h79.98c.18-.06.36-.11.54-.15,8.31-1.77,13.68-8.38,13.68-16.86ZM105.84,133.56h-6.48c-11.68,0-23.36,0-35.04,0-10.8,0-21.59,0-32.39,0-6.73,0-11.44-4.69-11.45-11.4,0-15.11,0-30.22,0-45.33,0-12.92,0-25.84,0-38.76,0-4.87,1.77-8.84,5.25-11.79,6.07-5.14,12.26-10.18,17.52-14.43,2.6-2.1,5.94-3.13,10-3.09,10.24.13,20.65.13,30.73.13,7.32,0,14.9,0,22.35.05,6.2.04,10.89,4.87,10.89,11.24.02,12.35.01,24.69.01,37.04v14.01s0,12.73,0,12.73c0,12.73,0,25.46,0,38.19,0,5.47-3.21,9.73-8.36,11.11-1.08.29-2.12.29-3.03.29Z'
			/>
			<path
				className='cls-1'
				d='M55.8,64.02h-12.16s-.54,0-.54,0c-.36,0-.71-.01-1.06.01-.93.08-1.75.54-2.24,1.27-.48.71-.6,1.55-.34,2.39.41,1.32,1.45,2,3.11,2,6.74,0,13.47,0,20.2,0h8.73s6.76,0,6.76,0c6.76,0,13.51,0,20.26,0,1.75,0,2.94-.85,3.17-2.27.15-.93-.04-1.73-.54-2.33-.59-.69-1.57-1.07-2.77-1.07-14.19,0-28.38,0-42.57,0Z'
			/>
			<path
				className='cls-1'
				d='M98.76,48.84h-36.43c-6.37,0-12.73,0-19.1,0-.94,0-1.76.32-2.3.89-.51.54-.75,1.25-.7,2.08.09,1.62,1.39,2.67,3.32,2.68,5.31.01,10.63.01,15.94,0h14.78c2.65,0,5.3,0,7.96,0,5.3,0,10.59,0,15.89,0h0c1.43,0,2.24-.27,2.82-.92.86-.99,1.04-1.97.54-3.08-.51-1.15-1.35-1.67-2.73-1.67Z'
			/>
			<path
				className='cls-1'
				d='M80.19,33.22h-24.87c-4.35,0-8.69,0-13.04,0-.64,0-1.2.32-1.57.89-.35.53-.51,1.25-.48,2.08.06,1.62.95,2.67,2.27,2.67,3.63.01,7.26.01,10.88,0h10.09c1.81,0,3.62,0,5.43,0,3.62,0,7.23,0,10.85,0h0c.97,0,1.53-.27,1.92-.92.59-.99.71-1.96.37-3.08-.35-1.15-.92-1.67-1.86-1.67Z'
			/>
			<path
				className='cls-1'
				d='M99.09,78.72h-24.87c-4.35,0-8.69,0-13.04,0-.64,0-1.2.32-1.57.89-.35.53-.51,1.25-.48,2.08.06,1.62.95,2.67,2.27,2.67,3.63.01,7.26.01,10.88,0h10.09c1.81,0,3.62,0,5.43,0,3.62,0,7.23,0,10.85,0h0c.97,0,1.53-.27,1.92-.92.59-.99.71-1.96.37-3.08-.35-1.15-.92-1.67-1.86-1.67Z'
			/>
			<path
				className='cls-1'
				d='M49.79,118.56c3.38-1.97,3.77-4.53,4.99-6.44,1.23-1.92,2.34-4.01,5.02-3.91,2.72.1,3.97,2.21,4.45,4.41,1.18,5.32,3.33,5.12,6.59,1.62,1.53-1.64,3.28-3.07,4.99-4.53,1.69-1.43,3.59-2.44,5.45-.38,1.68,1.87.56,3.56-.82,5.01-3.35,3.51-6.87,6.83-10.96,9.47-3.39,2.18-6.54,2.37-8.75-1.63-1.22-2.2-2.29-2.23-3.73-.25-2.65,3.65-6.03,4.36-9.98,2.24-1.8-.97-3.31-.16-4.88.61-1.56.76-3.06.64-4.18-.81-.96-1.25-.89-2.72.09-3.84,1.97-2.25,1.28-4.36.2-6.69-1.61-3.48-1.57-7.05.08-10.54,2.22-4.73,6.38-6.96,10.31-5.59,4.69,1.63,6.99,6.11,5.41,11.35-.91,3.03-2.5,5.85-4.28,9.9Z'
			/>
		</svg>
	);
}
export default ContractsIcon;
