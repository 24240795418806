import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Box from '@mui/material/Box';
import React, { useCallback } from 'react';
import { H5 } from '@pw/components/Typography';

function Toggle({ className, toggle, setToggle }) {
	return (
		<>
			{toggle && (
				<ExpandLess
					className={className}
					onClick={(e) => {
						setToggle(false);
						e.preventDefault();
					}}
				/>
			)}
			{!toggle && (
				<ExpandMore
					className={className}
					onClick={(e) => {
						setToggle(true);
						e.preventDefault();
					}}
				/>
			)}
		</>
	);
}

export function MenuHeader({ icon, label, xs = false, toggle = false, setToggle = null, onClick = null }) {
	const handleClick = useCallback(() => {
		if (!xs) return;

		if (setToggle) {
			setToggle(!toggle);
		}

		if (onClick) {
			onClick();
			return;
		}
	}, [toggle]);

	return (
		<Box className='menu-group-header' onClick={handleClick}>
			<Box className='icon'>{icon}</Box>
			<H5 className='label'>{label}</H5>
			{xs && <Toggle className='toggle' toggle={toggle} setToggle={setToggle} />}
		</Box>
	);
}
