import { black, white } from '@pw/styles/colors';

const styles = {
	'&.root': {
		backgroundColor: black,

		'.list': {
			marginTop: '2px',
			width: '100%',
		},
		'.divider': {
			backgroundColor: white,
			marginTop: '10px',
		},
		'.cursorPointer': {
			cursor: 'pointer',
		},
	},
};

export default styles;
