import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import AssetPreview from '@pw/components/BatchUpload/components/AssetPreview';
import DropZone from '@pw/components/BatchUpload/components/DropZone';
import FieldMapping from '@pw/components/BatchUpload/components/FieldMapping';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import { useState } from 'react';

export const Connector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderWidth: 0,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderWidth: 0,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderWidth: 0,
	},
}));

const steps = ['Upload File', 'Field Mapping', 'Asset Preview'];

function UploadWizard({ sku, assetName, model, type, onCancel, onConfirm }) {
	const [activeStep, setActiveStep] = useState(0);
	const [rawData, setRawData] = useState(null);
	const [mappings, setMappings] = useState(null);

	const isMobile = useIsMobile();

	const setData = (data) => {
		console.log('File data', data);
		setRawData(data);
		setActiveStep(1);
	};

	const handleMappings = (m) => {
		console.log('Mappings', m);
		setMappings(m);
		setActiveStep(2);
	};

	const handleBack = () => {
		setActiveStep((v) => v - 1);
	};

	return (
		<Stack spacing={2}>
			<Stepper
				alternativeLabel={isMobile}
				activeStep={activeStep}
				connector={<Connector />}
			>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel className='stepIcon'>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			{activeStep === 0 && <DropZone setData={setData} />}
			{activeStep === 1 && (
				<FieldMapping
					rawData={rawData}
					model={model}
					assetType={type}
					onBack={handleBack}
					onCancel={onCancel}
					onConfirm={handleMappings}
				/>
			)}
			{activeStep === 2 && (
				<AssetPreview
					sku={sku}
					rawData={rawData}
					mappings={mappings}
					model={model}
					assetName={assetName}
					assetType={type}
					onBack={handleBack}
					onCancel={onCancel}
					onConfirm={onConfirm}
				/>
			)}
		</Stack>
	);
}

export default UploadWizard;
