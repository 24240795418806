// Margins
export const ma0 = { margin: '0' };
export const ma1 = { margin: '.25rem' };
export const ma2 = { margin: '.5rem' };
export const ma3 = { margin: '1rem' };
export const ma4 = { margin: '2rem' };
export const ma5 = { margin: '4rem' };
export const ma6 = { margin: '8rem' };
export const ma7 = { margin: '16rem' };
export const ml0 = { marginLeft: '0' };
export const ml1 = { marginLeft: '.25rem' };
export const ml2 = { marginLeft: '.5rem' };
export const ml3 = { marginLeft: '1rem' };
export const ml4 = { marginLeft: '2rem' };
export const ml5 = { marginLeft: '4rem' };
export const ml6 = { marginLeft: '8rem' };
export const ml7 = { marginLeft: '16rem' };
export const mr0 = { marginRight: '0' };
export const mr1 = { marginRight: '.25rem' };
export const mr2 = { marginRight: '.5rem' };
export const mr3 = { marginRight: '1rem' };
export const mr4 = { marginRight: '2rem' };
export const mr5 = { marginRight: '4rem' };
export const mr6 = { marginRight: '8rem' };
export const mr7 = { marginRight: '16rem' };
export const mb0 = { marginBottom: '0' };
export const mb1 = { marginBottom: '.25rem' };
export const mb2 = { marginBottom: '.5rem' };
export const mb3 = { marginBottom: '1rem' };
export const mb4 = { marginBottom: '2rem' };
export const mb5 = { marginBottom: '4rem' };
export const mb6 = { marginBottom: '8rem' };
export const mb7 = { marginBottom: '16rem' };
export const mt0 = { marginTop: '0' };
export const mt1 = { marginTop: '.25rem' };
export const mt2 = { marginTop: '.5rem' };
export const mt3 = { marginTop: '1rem' };
export const mt4 = { marginTop: '2rem' };
export const mt5 = { marginTop: '4rem' };
export const mt6 = { marginTop: '8rem' };
export const mt7 = { marginTop: '16rem' };
export const mv0 = { marginTop: '0', marginBottom: '0' };
export const mv1 = { marginTop: '.25rem', marginBottom: '.25rem' };
export const mv2 = { marginTop: '.5rem', marginBottom: '.5rem' };
export const mv3 = { marginTop: '1rem', marginBottom: '1rem' };
export const mv4 = { marginTop: '2rem', marginBottom: '2rem' };
export const mv5 = { marginTop: '4rem', marginBottom: '4rem' };
export const mv6 = { marginTop: '8rem', marginBottom: '8rem' };
export const mv7 = { marginTop: '16rem', marginBottom: '16rem' };
export const mh0 = { marginLeft: '0', marginRight: '0' };
export const mh1 = { marginLeft: '.25rem', marginRight: '.25rem' };
export const mh2 = { marginLeft: '.5rem', marginRight: '.5rem' };
export const mh3 = { marginLeft: '1rem', marginRight: '1rem' };
export const mh4 = { marginLeft: '2rem', marginRight: '2rem' };
export const mh5 = { marginLeft: '4rem', marginRight: '4rem' };
export const mh6 = { marginLeft: '8rem', marginRight: '8rem' };
export const mh7 = { marginLeft: '16rem', marginRight: '16rem' };

// Negative margins
export const na1 = { margin: '-0.25rem' };
export const na2 = { margin: '-0.5rem' };
export const na3 = { margin: '-1rem' };
export const na4 = { margin: '-2rem' };
export const na5 = { margin: '-4rem' };
export const na6 = { margin: '-8rem' };
export const na7 = { margin: '-16rem' };
export const nl1 = { marginLeft: '-0.25rem' };
export const nl2 = { marginLeft: '-0.5rem' };
export const nl3 = { marginLeft: '-1rem' };
export const nl4 = { marginLeft: '-2rem' };
export const nl5 = { marginLeft: '-4rem' };
export const nl6 = { marginLeft: '-8rem' };
export const nl7 = { marginLeft: '-16rem' };
export const nr1 = { marginRight: '-0.25rem' };
export const nr2 = { marginRight: '-0.5rem' };
export const nr3 = { marginRight: '-1rem' };
export const nr4 = { marginRight: '-2rem' };
export const nr5 = { marginRight: '-4rem' };
export const nr6 = { marginRight: '-8rem' };
export const nr7 = { marginRight: '-16rem' };
export const nb1 = { marginBottom: '-0.25rem' };
export const nb2 = { marginBottom: '-0.5rem' };
export const nb3 = { marginBottom: '-1rem' };
export const nb4 = { marginBottom: '-2rem' };
export const nb5 = { marginBottom: '-4rem' };
export const nb6 = { marginBottom: '-8rem' };
export const nb7 = { marginBottom: '-16rem' };
export const nt1 = { marginTop: '-0.25rem' };
export const nt2 = { marginTop: '-0.5rem' };
export const nt3 = { marginTop: '-1rem' };
export const nt4 = { marginTop: '-2rem' };
export const nt5 = { marginTop: '-4rem' };
export const nt6 = { marginTop: '-8rem' };
export const nt7 = { marginTop: '-16rem' };

// negate margin horizontal
export const nh0 = { marginLeft: '0', marginRight: '0' };
export const nh1 = { marginLeft: '-.25rem', marginRight: '-.25rem' };
export const nh2 = { marginLeft: '-.5rem', marginRight: '-.5rem' };
export const nh3 = { marginLeft: '-1rem', marginRight: '-1rem' };
export const nh4 = { marginLeft: '-2rem', marginRight: '-2rem' };
export const nh5 = { marginLeft: '-4rem', marginRight: '-4rem' };
export const nh6 = { marginLeft: '-8rem', marginRight: '-8rem' };
export const nh7 = { marginLeft: '-16rem', marginRight: '16rem' };
// negate margin vertical
export const nv0 = { marginTop: '0', marginBottom: '0' };
export const nv1 = { marginTop: '-.25rem', marginBottom: '-.25rem' };
export const nv2 = { marginTop: '-.5rem', marginBottom: '-.5rem' };
export const nv3 = { marginTop: '-1rem', marginBottom: '-1rem' };
export const nv4 = { marginTop: '-2rem', marginBottom: '-2rem' };
export const nv5 = { marginTop: '-4rem', marginBottom: '-4rem' };
export const nv6 = { marginTop: '-8rem', marginBottom: '-8rem' };
export const nv7 = { marginTop: '-16rem', marginBottom: '16rem' };
