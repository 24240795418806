import * as yup from 'yup';

export function storageChargeCodeFields(charge_code = {}) {
	const {
		enable = false,
		code = '',
		start_date = Date.now(),
		end_date = Date.now(),
	} = charge_code;

	return {
		enable: [enable, yup.bool()],
		code: [
			code,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Code is required!'),
				otherwise: (schema) => schema,
			}),
		],
		start_date: [
			start_date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0).required('Start date is required!'),
				otherwise: (schema) => schema,
			}),
		],
		end_date: [
			end_date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0).required('End date is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
