import { Box } from '@mui/material';
import SKUSelector from '@pw/components/SKUSelector';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import RevertMessage from '../Forms/RevertMessage';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';
import isEqual from 'lodash.isequal';

function SKUField({ title, skuTypes, tags, entity, itemFilter = (x) => x, field = 'sku', idField = 'sku_id' }) {
	const { values, setFieldValue } = useContext(FormikContext);

	console.log('SKUField:', field, idField, title, entity);

	const [historyExist, isSame, history] = useAssetRevertHook(
		entity,
		(e, e2) => (e?.[idField] ?? '') === (e2?.[idField] ?? ''),
	);

	const isReverted = useMemo(() => values[idField] === history?.[idField], [history, values])

	const [sku, init, addSku, , removeSku] = useItemListManager(
		ID.sku,
		COMP.sku,
		values?.[idField] && values?.[field]?.['sku_name']
			? [{ ...values[field], [idField]: values[idField] }]
			: [],
		{
			onRemove: () => {
				debounce(() => {
					if (tags) {
						setFieldValue(tags, []);
					}
					setFieldValue(field, {});
					setFieldValue(idField, null);
				}, 50);
			},
			onAdd: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							sku_tags,
							sku_id,
							sku_name,
							sku_type,
							sku_description,
							tax_code,
						} = items[0];
						if (tags) {
							setFieldValue(tags, sku_tags);
						}
						setFieldValue(field, {
							sku_name,
							sku_type,
							sku_description,
							sku_id,
							tax_code,
						});
						setFieldValue(idField, sku_id);
					}, 50);
				}
			},
			onUpsert: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							sku_tags,
							sku_id,
							sku_name,
							sku_type,
							sku_description,
							tax_code,
						} = items[0];
						if (tags) {
							setFieldValue(tags, sku_tags);
						}
						setFieldValue(field, {
							sku_name,
							sku_type,
							sku_description,
							sku_id,
							tax_code,
						});
						setFieldValue(idField, sku_id);
					}, 50);
				}
			},
		},
	);

	useEffect(() => {
		const valueSKU = Object.keys(values[field] ?? {}).length
			? [{ ...values[field], [idField]: values[idField] }]
			: [];
		if (!isEqual(valueSKU, sku)) {
			init(valueSKU);
		}
	}, [values.sku]);

	const onRevert = useCallback(() => {
		setFieldValue(field, history[field]);
		setFieldValue(idField, history[idField]);
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		setFieldValue(field, entity[field]);
		setFieldValue(idField, entity[idField]);
	}, [entity, setFieldValue])

	return (
		<Box sx={{ position: 'relative' }}>
			{historyExist && !isSame && (
				<RevertMessage title={title} isReverted={isReverted} onRevert={onRevert} onUndo={onUndo} type="sku" item={history[field]} />
			)}
			<SKUSelector
				sectionTitle={title}
				list={sku}
				onAdd={(i) => addSku(i, true)}
				onRemove={(i) => removeSku(i, true)}
				filter={skuTypes}
				itemFilter={itemFilter}
				single
				summary={false}
			/>
		</Box>
	);
}

export default SKUField;
