import { FlexBox } from '@pw/components/Layout/FlexBox';
import LinkedAssetItem from '@pw/components/SKUSelector/items/LinkedAssetItem';


export function AssetLinks({ asset }) {
	const { parent_asset_id, parent_asset, children } = asset ?? {};

	if (!parent_asset_id && !parent_asset && !children) return;

	return (
		<FlexBox className='asset-container' justifyContent='start' gap='0.75rem'>
			{parent_asset_id && parent_asset && <LinkedAssetItem asset={parent_asset} />}
			{children &&
				children.map((_item) => <LinkedAssetItem key={_item.asset_id} asset={_item} />)}
		</FlexBox>
	);
}
