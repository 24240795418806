import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/policy`;

export const createPolicy = async (sku, headers = {}) =>
  fetchPost(BASE, headers, sku);

export const getPolicy = async (params, headers = {}) =>
  fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const getPolicyList = async (params = {}, headers = {}) =>
  fetchGet(`${BASE}/list${objectToQueryString(params)}`, headers);
