function SyncIcon(props) {
	return (
		<svg
			{...props}
			className='MuiSvgIcon-root'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
		>
			<defs>
				<style>{'.cls-1 {stroke-width: 0px;}'}</style>
			</defs>
			<path
				className='cls-1'
				d='M138.83,87.93c1.07-3.63,1.54-7.48,2-11.21l.09-.74v-11.04c-.1-.29-.13-.58-.16-.84-.01-.1-.03-.23-.05-.33-.05,0-.12,0-.22.02-2.02.32-4.02.56-5.94.71-.15.01-.25.03-.31.04,0,.06,0,.17,0,.32.44,5.08.28,10.1-.47,14.91-2.43,15.73-9.6,28.72-21.3,38.58-16.44,13.86-35.51,18.33-56.65,13.3-13.11-3.12-24.32-10.07-33.33-20.65-.03-.04-.08-.08-.12-.11-.73-.66-.9-1.34-.52-2.03.49-.87,1.42-.78,1.82-.74.07,0,.15.02.2.01h.12c1.55-.02,3.16-.04,4.74,0,.28,0,.41-.02.45-.04,0-.02.02-.13,0-.35-.07-2.08-.04-4.19,0-5.97,0-.14,0-.23-.02-.28-.05,0-.13-.01-.26-.02-6.61.02-13.23.02-19.84,0-.19,0-.28.02-.33.03,0,.03,0,.11,0,.25.01,6.98.01,13.96,0,20.94,0,.16.02.25.03.29.02,0,.1,0,.25,0,.01,0,.02,0,.04,0,1.12-.02,2.23-.01,3.35,0h1.63c.52,0,1.06,0,1.33-.03.04-.26.04-.77.04-1.28v-8.61l1.73,1.87c.5.54.96,1.05,1.42,1.54.94,1.03,1.83,2.01,2.74,2.92,1.21,1.2,2.65,2.6,4.17,3.86,17.61,14.52,37.21,19.8,58.27,15.7,28.21-5.5,46.77-22.66,55.14-51.02Z'
			/>
			<path
				className='cls-1'
				d='M132.61,17.46s-.04-.04-.31-.04c-.07,0-.16,0-.27.01-.48.05-.95.03-1.41.02-.36-.01-.73-.02-1.09,0-.55.04-1.11-.02-1.64-.08-.92-.1-1.54-.15-1.83.14-.27.28-.19,1.02-.11,1.74.06.53.12,1.09.07,1.64-.03.33-.02.66,0,1.01.02.81.05,1.73-.29,2.8l-.58,1.83-1.49-1.95c-.14-.18-.24-.33-.35-.45-9.73-10.75-21.79-17.94-35.82-21.37-3.07-.75-6.26-1.2-9.34-1.63l-1.05-.15h-11.43c-.71.11-1.42.2-2.14.3-1.54.21-3.13.42-4.66.73C37.1,6.43,20.66,18.43,9.99,37.69c-4.65,8.4-7.38,17.84-8.12,28.06-.31,4.28-.23,8.47.24,12.46.01.12.03.27.05.38,0,0,.02,0,.02,0,1.78-.25,3.68-.49,6.17-.76.24-.03.33-.07.35-.08,0,0,.03-.14-.01-.48-.69-5.58-.54-11.25.44-16.87,2.72-15.65,10.19-28.61,22.21-38.53,11.45-9.44,25-14.3,40.26-14.43,7.08-.05,14.26,1.23,21.37,3.85,9.76,3.59,18.33,9.39,25.46,17.23.03.04.07.07.1.1.23.22.86.8.6,1.64-.16.52-.6,1.12-1.77,1.01-.05,0-.09,0-.13,0-1.68.02-3.33.03-4.99,0-.13,0-.21,0-.26.01,0,.05-.02.14-.02.28.04,2.06.04,4.11,0,6.1,0,.12,0,.2.01.25.05.01.15.02.3.02,6.7-.02,13.41-.02,20.11,0,.16.01.24-.02.29-.03,0-.03,0-.12,0-.28-.02-.76-.01-1.52,0-2.28v-1.05s0-4.51,0-4.51c0-3.81-.01-7.74.03-11.61,0-.53-.08-.68-.09-.69Z'
			/>
			<path
				className='cls-1'
				d='M52.21,55.26c-.43,2.31-1.9,2.76-3.13,2.9-5.14.58-9.85,3.33-12.92,7.56-3.05,4.2-4.2,9.48-3.17,14.49,1.82,8.8,9.34,14.98,18.28,15.04,5.07.03,10.15.02,15.22.02,2.32,0,4.64,0,6.95,0h6.7c5.02,0,10.05,0,15.07,0,.94.03,1.87-.07,2.79-.21,5.68-.86,10.26-4.95,11.67-10.42,1.46-5.65-.62-11.58-5.18-14.74-2.48-1.72-3.28-3.66-3.72-6.03-1.5-8.18-8.98-14-17.45-13.54-1.8.09-3.29-.22-4.69-2.07-2.74-3.62-7.19-5.71-11.66-5.71-1.29,0-2.58.17-3.83.54-6.08,1.75-9.76,5.86-10.93,12.19ZM57.58,57.4c.08-4.53,3.46-8.47,7.87-9.17,4.42-.7,8.76,1.84,10.3,6.04.45,1.22.79,2.47,1.12,3.67,1.05-.47,2.1-.9,3.11-1.29,3.54-1.36,7.36-.98,10.48,1.05,3.08,2,4.95,5.29,5.13,9,.07,1.47-.23,2.88-.52,4.24-.06.29-.12.59-.18.88-.03.14-.04.24-.05.33.03,0,.06,0,.09,0,.73.05,1.4.21,2.05.37.2.05.4.1.6.14,4.58,1.01,7.36,4.81,6.9,9.46-.41,4.24-4.13,7.45-8.65,7.46-5.3.01-10.6.01-15.91,0h-6.55s-7.28,0-7.28,0c-1.9,0-3.8,0-5.7,0-3.11,0-6.23,0-9.34-.02-5.42-.03-10.15-3.38-12.06-8.55-1.81-4.91-.39-10.58,3.53-14.1,2.42-2.18,5.28-3.3,8.5-3.34h.32c2.03-.03,4.14-.06,6.22,0,0,0,.02,0,.03,0,0-.02,0-.04,0-.07-.07-2.07-.04-4.17,0-6.13Z'
			/>
		</svg>
	);
}
export default SyncIcon;
