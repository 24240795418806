import { AddCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useCallback, useContext, useEffect, useState } from 'react';
import ReleaseModal from '@pw/components/admin/Releases/ReleaseModal';
import ReleasesList from '@pw/components/admin/Releases/ReleasesList';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';

function ReleaseComponent({
	title,
	setSelectedRelease,
	releases,
	handleRemoveRelease,
	removeReleases,
}) {
	return (
		<Stack spacing={1} className='section'>
			{title && <H5 className='section-header'>{title}</H5>}

			<Stack className='inventory'>
				<Box className='inventory-header'>
					<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
					<Stack direction='row' spacing={1}>
						<TitledButton
							handleClick={() => setSelectedRelease({})}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>
					</Stack>
				</Box>
				{releases.length > 0 && (
					<ReleasesList
						items={releases}
						onEdit={(i) => setSelectedRelease(i)}
						onRemove={(i) => handleRemoveRelease(i)(removeReleases)}
					/>
				)}
				{releases.length === 0 && (
					<Box p={2} className='inventory-footer'>
						<Body3>None</Body3>
					</Box>
				)}
			</Stack>
		</Stack>
	);
}

function ModalComponents({
	upsertReleases,
	setSelectedRelease,
	selectedRelease,
}) {
	const handleReleaseUpdate = useCallback(
		(release) => {
			if (release) {
				upsertReleases(release);
			}
			setSelectedRelease(null);
		},
		[upsertReleases, setSelectedRelease],
	);

	return (
		<>
			{!!selectedRelease && (
				<ReleaseModal
					title='Release'
					open={!!selectedRelease}
					item={selectedRelease}
					onClose={handleReleaseUpdate}
				/>
			)}
		</>
	);
}

function Releases(props) {
	const { title = 'Releases', field = 'releases' } = props;
	const { setFieldValue, values } = useContext(FormikContext);

	const confirm = useConfirm();

	const [releases, , , upsertReleases, removeReleases] = useItemListManager(
		ID.id,
		COMP.date,
		values[field] ?? [],
	);

	// Currently selected code
	const [selectedRelease, setSelectedRelease] = useState(null);

	const handleRemoveRelease = (release) => (removeFunc) => {
		confirm({
			title: 'Remove State',
			content: <Body1>{`Remove ${release?.name}?`}</Body1>,
		})
			.then(() => removeFunc(release))
			.catch(() => { });
	};

	useEffect(() => {
		console.log('Updating releases', releases);
		debounce(() => setFieldValue(field, releases), 25);
	}, [releases]);

	return (
		<>
			<ReleaseComponent
				title={title}
				setSelectedRelease={setSelectedRelease}
				releases={releases}
				handleRemoveRelease={handleRemoveRelease}
				removeReleases={removeReleases}
			/>
			<ModalComponents
				upsertReleases={upsertReleases}
				setSelectedRelease={setSelectedRelease}
				selectedRelease={selectedRelease}
			/>
		</>
	);
}

export default Releases;
