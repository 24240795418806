import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationLiquidModal from '@pw/components/SKUSelector/modals/DestinationLiquidModal';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useMemo } from 'react';

function ProductionRunForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [[sourceSkus], , initSources, SourceInventory, SourceModals] =
		useInventorySelectorHook({
			title: 'Input',
			filter: {
				request_types: [REQUEST_TYPES.pick],
				sku_types: [
					SKU_TYPES.RAW,
					SKU_TYPES.EXPIRING,
					SKU_TYPES.CONSUMABLE,
					SKU_TYPES.SERVICE,
					SKU_TYPES.WIP,
				],
			},
			submitForm,
			SKUModal: SourceSKUModal,
		});

	const [
		[destinationSkus],
		[destinationAssets],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Output',
		filter: {
			asset_types: [ASSET_TYPES.filling_tank],
			sku_types: [SKU_TYPES.WIP, SKU_TYPES.WASTE, SKU_TYPES.SERVICE],
		},
		assetAdapter: toTaggedAsset,
		submitForm,
		SKUModal: DestinationSKUModal,
		AssetModal: DestinationLiquidModal,
	});
	//
	// const { data: tanks = [] } = useAssetByTypeQuery({
	// 	type: ASSET_TYPES.filling_tank,
	// });
	// console.log('Tanks', tanks);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: entity?.sku_destinations,
			});
		},
		[initSources, initDestinations],
	);

	// On the way out, add the sku list in
	const handleBeforeSave = useCallback(
		(entity) => {
			// const dest_tank = toTaggedAsset(
			// 	tanks.find((t) => t.path === entity.tank_id),
			// );
			// /** get confirmed tanker asset */
			// if (destinationAssets?.[0]?.processed === '1')
			// 	dest_tank.processed = destinationAssets?.[0].processed;
			return {
				...entity,
				// tank_id: null,
				sku_sources: sourceSkus,
				destinations: destinationAssets,
				sku_destinations: destinationSkus,
			};
		},
		[destinationAssets, sourceSkus, destinationSkus],
	);

	return (
		<>
			<RequestForm
				requestLabel='Production Run'
				requestType={REQUEST_TYPES.production_run}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />

				{/*<Stack spacing={2}>*/}
				{/*	<H4>Destination Tank</H4>*/}
				{/*	<FormikSelect*/}
				{/*		fullWidth*/}
				{/*		options={tanks.map((t) => ({*/}
				{/*			value: t.path,*/}
				{/*			label: t.rw_asset_id,*/}
				{/*		}))}*/}
				{/*		label='Production Tank'*/}
				{/*		name='tank_id'*/}
				{/*	/>*/}
				{/*</Stack>*/}

				<DestinationInventory />

				{/*<Properties />*/}
				<FormSubmitter />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
		</>
	);
}

export default withAppLayout(ProductionRunForm, { title: 'Liquid Production' });
