// import { itemWidth, itemHeight } from '@pw/pages/app/production-designer';

export const itemWidth = 190;
export const itemHeight = itemWidth;


const styles = {
  '@keyframes pulse': {
    '0%': { opacity: 0.8, },
    '50%': { opacity: 1, },
    '100%': { opacity: 0.8, },
  },
  '&.node-root': {
    position: 'relative',
    visibility: 'visible',
    overflow: 'visible',
    width: `${itemWidth}px`,
    height: `${itemHeight}px`,
    boxSizing: 'border-box',
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    transform: 'rotate(45deg)',
    transformOrigin: 'center center',

    '&:hover': {
      '.node-handle': { zIndex: 3, }
    },
    '&::after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      opacity: 0,
      left: '1rem',
      top: '1rem',
      right: '1rem',
      bottom: '1rem',
      padding: '1rem',
      boxSizing: 'content-box',
      border: '2px dashed #ccc',
      transition: 'all 0.4s',
      zIndex: 0,
    },

    '&:hover, &.expanded': {
      '.node-info': {
        maxWidth: 'unset',
        width: 'fit-content',
        flexGrow: 1,
        background: '#fff'
      },
      '.text': {
        opacity: 1,
        transform: 'translateX(0)',
        whiteSpace: 'nowrap'
      },
    }
  },
  '--handle-color': '#EBEAE9',
  // '.label': { color: '#00A89D', },
  '&.node-root:hover': {
    '--handle-color': '#F0AA29',
  },
  '.handle-connected': {
    '--handle-color': '#F0AA29',
    opacity: 1
  },
  '&.material-node': {
    '.label': { color: '#00A89D' }
  },
  '&.process-node': {
    '--handle-color': '#00A89D',
    '.handle-type-source': { opacity: 0, },
    '.handle-type-source.handle-connected': { opacity: 1, },
    '.handle-connected': { '--handle-color': '#00A89D', },
    '.handles-container .handle-connected': { '--handle-color': '#F0AA29', },
    '.handle-base': { opacity: 1, },

    '.node-content': {
      backgroundColor: '#00A89D',
      color: 'white'
    },
    '&:hover': {
      '.handle-type-source': { opacity: 1 },
      '.handle-type-target': { opacity: 1 },
      '.handle-connected': { opacity: 1 },
    },
  },

  '&.observation-node': {
    '.node-content': { backgroundColor: '#C753A8', },
    '.icon-label-group': { color: 'white', },
    '.node-handle': { color: '#B4509F' },
    '--handle-color': '#B4509F',
  },
  '&.operation-node': {
    '.node-content': { backgroundColor: '#C2D559', },
    '.icon-label-group': { color: '#707070', },
    // '.node-handle': { color: '#082345' },
    '--handle-color': '#C2D559',
  },
  '&.node-selected': {
    '&::after': {
      opacity: 1,
      zIndex: -1,
    },
  },

  '.node-controls': {
    position: 'absolute',
    zIndex: 999,
    width: '8rem',
    left: '2.5rem',
    top: '-2.5rem',
    transform: 'rotate(-45deg) translate(0, 0)',
    display: 'flex',
    alignItems: 'center',
    // border: '1px solid',

    '*': {
      transformOrigin: 'left center',
      color: '#00A89D'
    },

    'svg': {
      display: 'block',
      boxSizing: 'content-box',
      fontSize: '1.5rem',
      width: '1em',
      height: '1em',
    },


    '.button': {
      padding: '0.5rem',
      margin: '-0.25rem',
      transform: 'translateX(-0.75px)',
      width: '1rem',
      height: '1rem',
      lineHeight: 1,
      boxSizing: 'content-box',
      backgroundColor: '#fff',
      borderRadius: '100%',
      minWidth: 0,
      minHeight: 0,
      border: '1px solid #00A89D44',
      '&:hover': {
        backgroundColor: '#00A89D44',
      }
    },
    '.pulse': {
      animation: 'pulse 1.5s 0s forwards infinite',
    },

    '.node-info': {
      position: 'relative',
      maxWidth: '100%',
      width: 'fit-content',
      overflow: 'hidden',
      // maxWidth: '1.5rem',
      // flexGrow: 1,
      borderRadius: '99rem',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      padding: '0.25rem',
      border: '1px solid',
      whiteSpace: 'nowrap',
      height: '2rem',
      boxSizing: 'content-box',
      py: '0',
      transition: 'all 0.1s',

      '&.repeating': {
        color: '#C753A8',
      },

      '*': { color: 'inherit' },
      // backgroundColor: '#fff',

      '.repeat-icon': {
        position: 'relative',
        top: 0,
        // left: '1px',
        color: 'inherit',
        // fill: 'currentColor',
        transformOrigin: 'center center',
        transform: 'scaleY(-1) rotate(60deg)',
        fill: '#C753A8'
      },

      '.token': { paddingRight: '0.25rem', color: 'inherit' },
    },
    '.no-text .node-info, .no-text.node-info': {
      maxWidth: '1.5rem',
    },
    '&:hover': {
      '.node-info': { flexGrow: 1 },
    },
  },

  '.duration': {
    padding: '0.25rem',
    border: '1px solid #00A89D88',
    backgroundColor: '#fff',
    borderRadius: '99rem',
    // transform: 'rotate(45deg)',
    display: 'flex',

    svg: { transform: 'none' },
  },

  '.text': {
    display: 'inline-flex',
    paddingRight: '0.5rem',

    transition: 'all 0.3s',
    opacity: 0,
    transformOrigin: 'left center',
    transform: 'translateX(-100%)',
  },

  '.node-content': {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    position: 'absolute',
    boxShadow: '1px 1px 3px 0px #00000040',
    left: '2rem',
    right: '2rem',
    top: '2rem',
    bottom: '2rem',
    textAlign: 'center'
  },
  '.icon-label-group': {
    width: '100%',
    height: '100%',
    transform: 'rotate(-45deg) translateY(-0.5rem)',
    transformOrigin: 'center center'
  },
  '.icon-container': {
    display: 'block',
    position: 'relative',
    top: '1.5rem',
    fontSize: '3rem',
    height: '1em',
    width: 'auto',
    lineHeight: 1,
  },
  '.label': {
    position: 'relative',
    top: '1.75rem',
    fontSize: `${14 / 16}rem`,
    textTransform: 'capitalize',
  },
  '.more': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    // color: '#00A89D',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(135deg, transparent 0%,  transparent 77%,  #EBEAE9 77%)',
    color: '#aaa',
    '&.has-form svg': { color: '#00A89D', },

    svg: {
      borderRadius: '100%',
      border: '1px solid',
    },

    '.more-button': {
      position: 'absolute',
      right: '-1.1rem',
      bottom: '-.4rem',
      width: '3em',
      height: '2em',
      transform: 'rotate(-45deg)',
      fontSize: '1.5rem',
    },
  },

  '.node-handle': {
    width: '14px',
    height: '14px',
    margin: '-7px',
    transition: 'all 0.8s',
    backgroundImage: 'radial-gradient(var(--handle-color) 0%, var(--handle-color) 35%, white 35%, white 35%)',
    border: '0.125rem solid var(--handle-color)',
    transform: 'unset !important',
  },
  // offset by padding on root

  '.top': { top: '2rem', left: '2rem', },
  '.right': { right: '2rem', left: 'auto', top: '2rem', },
  '.bottom': { bottom: '2rem', top: 'auto', right: '2rem', left: 'auto' },
  '.left': { left: '2rem', bottom: '2rem', top: 'auto' },
  '.top-left': { top: '50%', left: '2rem' },
  '.top-right': { left: '50%', top: '2rem' },
  '.bottom-right': { bottom: '50%', right: '2rem', left: 'auto' },
  '.bottom-left': { left: '50%', bottom: '2rem' },

  // on process nodes, position 
  // '.target': {
  //   // opacity: 0,
  //   border: '1px solid red',
  //   '&.top': { left: '2rem' },
  //   '&.right': { top: '2rem' },
  //   '&.bottom': { left: 'auto', right: '1.25rem' },
  //   '&.left': { top: 'auto', bottom: '1.25rem' },
  // },
};

export default styles;
