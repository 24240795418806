import { AddCircle, FilterAltOffOutlined, FilterAltOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import PolicyList from '@pw/components/admin/SKUPolicies/PolicyList';
import PolicyModal from '@pw/components/admin/SKUPolicies/PolicyModal';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { getPolicyAttrs } from '@pw/utilities/adapters/getAttrs';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import { Filters, useFilteredHook } from '@pw/utilities/hooks/logic/useFilters';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import React, { useCallback, useContext, useEffect, useState } from 'react';

function SKUPolicy(props) {
	const { title = 'Policies', initialValues = [], field = 'policies' } = props;

	const confirm = useConfirm();

	const [policies, , , upsertPolicies, removePolicies] = useItemListManager(
		ID.policy,
		COMP.policy,
		initialValues ?? [],
	);

	const filterOptions = [
		{
			value: 'none',
			label: 'None',
		},
		{
			value: 'name',
			getter: (x) => x?.policy_name,
			label: 'Name',
		},
	];

	const [showFilter, setShowFilter] = useState(false);

	const [filteredPolicies, [setFilter, setSort, setDirection]] = useFilteredHook(
		showFilter,
		policies,
		filterOptions,
		(a) => a,
		getPolicyAttrs,
	);

	// Currently selected policy
	const [selectedPolicy, setSelectedPolicy] = useState(null);

	const handleRemovePolicy = (policy) => (removeFunc) => {
		confirm({
			title: 'Remove Policy',
			content: <Body1>{`Remove ${policy?.policy_name}?`}</Body1>,
		})
			.then(() => removeFunc(policy))
			.catch(() => {});
	};

	return (
		<>
			<InventoryComponent
				{...{
					policies,
					field,
					title,
					setSelectedPolicy,
					setShowFilter,
					showFilter,
					setSort,
					setDirection,
					filterOptions,
					filteredPolicies,
					setFilter,
					handleRemovePolicy,
					removePolicies,
				}}
			/>
			<ModalComponents
				{...{ upsertPolicies, setSelectedPolicy, selectedPolicy }}
			/>
		</>
	);
}

function InventoryComponent({
	policies,
	field,
	title,
	setSelectedPolicy,
	setShowFilter,
	showFilter,
	setSort,
	setDirection,
	filterOptions,
	filteredPolicies,
	setFilter,
	handleRemovePolicy,
	removePolicies,
}) {
	const { setFieldValue } = useContext(FormikContext);

	useEffect(() => {
		console.log('Updating policies', policies);
		debounce(() => setFieldValue(field, policies), 25);
	}, [policies]);

	return (
		<Stack spacing={1} className='section'>
			{title && <H5 className='section-header'>{title}</H5>}

			<Stack className='inventory'>
				<Box className='inventory-header'>
					<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
					<Stack direction='row' spacing={1}>
						<TitledButton
							handleClick={() => setSelectedPolicy({})}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>
						<TitledButton
							handleClick={() => setShowFilter((x) => !x)}
							label='Filter'
						>
							{showFilter && <FilterAltOffOutlined height={24} width={24} />}
							{!showFilter && <FilterAltOutlined height={24} width={24} />}
						</TitledButton>
					</Stack>
				</Box>
				<Filters
					showFilter={showFilter}
					setFilter={setFilter}
					setSort={setSort}
					setDirection={setDirection}
					options={filterOptions}
				/>
				{policies.length > 0 && (
					<PolicyList
						items={filteredPolicies}
						onEdit={(i) => setSelectedPolicy(i)}
						onRemove={(i) => handleRemovePolicy(i)(removePolicies)}
					/>
				)}
				{policies.length === 0 && (
					<Box p={2} className='inventory-footer'>
						<Body3>None</Body3>
					</Box>
				)}
			</Stack>
		</Stack>
	);
}

function ModalComponents({ upsertPolicies, setSelectedPolicy, selectedPolicy }) {
	const handlePolicyUpdate = useCallback(
		(policy) => {
			if (policy) {
				upsertPolicies(policy);
			}
			setSelectedPolicy(null);
		},
		[upsertPolicies, setSelectedPolicy],
	);

	return (
		<>
			{!!selectedPolicy && (
				<PolicyModal
					open={!!selectedPolicy}
					item={selectedPolicy}
					onClose={handlePolicyUpdate}
				/>
			)}
		</>
	);
}

export default SKUPolicy;
