import { IS_PROD } from '@pw/consts/config';

// const FIREBASE_CONFIG_DEV = {
// 	apiKey: 'AIzaSyDPgyAaWKwsmwfg9OjstFdbspgCBioJtdY',
// 	authDomain: 'trakr-dev-aaf6f.firebaseapp.com',
// 	projectId: 'trakr-dev-aaf6f',
// 	storageBucket: 'trakr-dev-aaf6f.appspot.com',
// 	messagingSenderId: '384622143266',
// 	appId: '1:384622143266:web:f4fb3d9db55141c892a77b',
// 	measurementId: 'G-LEB9T7L8H3',
// };

// const FIREBASE_CONFIG_PROD = {
// 	apiKey: 'AIzaSyD2bk169rEAei1-K_DkVWR_HmTXADCGEXE',
// 	authDomain: 'trakr-prod-c9f29.firebaseapp.com',
// 	projectId: 'trakr-prod-c9f29',
// 	storageBucket: 'trakr-prod-c9f29.appspot.com',
// 	messagingSenderId: '395509026413',
// 	appId: '1:395509026413:web:5bf391692d089972323964',
// 	measurementId: 'G-R932YZSTPN',
// };

// const FIREBASE_VAPID_KEY_DEV =
// 	'BDjo6wsMZb5diBgBFd68_2hSMoG1N2rekrak7BVnEiAM5dW9HzuAuyD3G-18puoFjgb9o18m6pTp2kEjsx1TxpQ';
// const FIREBASE_VAPID_KEY_PROD =
// 	'BMDBtVp5w5dvpqllnf_hQJjxn5x0Wbs29sZ2qh4gi-KwDX-M4wMRicPQdEx8ZlLbQBwYdpD3cuX3hNeifIOBf78';

const FIREBASE_CONFIG_DEV = {
	apiKey: 'AIzaSyAnVmxZDAM_J638hBtlaWY2NCVtsa5ifb4',
	authDomain: 'metacask-dev.firebaseapp.com',
	projectId: 'metacask-dev',
	storageBucket: 'metacask-dev.appspot.com',
	messagingSenderId: '768082414666',
	appId: '1:768082414666:web:4d68f330d74016d04b6ab3',
	measurementId: 'G-XFX74VNNMH'
  };
  
  const FIREBASE_CONFIG_PROD = {
	apiKey: 'AIzaSyAAO5HOiD1BKv7OZ4wLrxDcmpCGXwgaqfc',
	authDomain: 'metacask-prod.firebaseapp.com',
	projectId: 'metacask-prod',
	storageBucket: 'metacask-prod.appspot.com',
	messagingSenderId: '391403766335',
	appId: '1:391403766335:web:4e37b097b5a5cf6b452e8a',
	measurementId: 'G-22G0B0JBG8'
  };
  
  const FIREBASE_VAPID_KEY_DEV = 'BIjpQE7cLglJ0CCy0vAbjuijfnj1LUhmuaa_bmpsaspM3YF4uwijisaTXSPboLWEDUsdVtjlaQkXhrnk74ebV7o';
  const FIREBASE_VAPID_KEY_PROD = 'BFQyxmFPIPEtZ0T03A3r4leQSYIRgneATEZI5NUHqrlLPrVm_i0wM1pWwCqdWxmWoh3rjYUqSEYFRWfAu6IWOsM';

export const FIREBASE_CONFIG = IS_PROD ? FIREBASE_CONFIG_PROD : FIREBASE_CONFIG_DEV;
export const FIREBASE_VAPID_KEY = IS_PROD ? FIREBASE_VAPID_KEY_PROD : FIREBASE_VAPID_KEY_DEV;
