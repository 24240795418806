import Stack from '@mui/material/Stack';
import kinds from '@pw/consts/rawMaterials';
import { useMemo } from 'react';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5 } from '@pw/components/Typography';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { unit, kind } = fields;

	return {
		kind: [kind, yup.string()],
		unit: [unit, yup.string().required('Unit is required!')],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Properties</H5>
				<FormikSelect label="Kind" name="kind" options={kinds} />
				<FormikSelect label='Unit' name='unit' options={UNITS_OPTIONS} />
			</Stack>
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.RAW),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Raw Material'
			skuType={SKU_TYPES.RAW}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, { title: 'Raw Material SKUs' });
