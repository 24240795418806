import { useState, useEffect, useMemo, useCallback } from 'react';
import NodeModalForm from './NodeModalForm';

import * as yup from 'yup';
// import NodeConfigForm from './NodeConfigForm';
import { useNodeDataContext } from '../NodeDataContext';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import { FormikCheckBox, RenderIF } from '@pw/components/Forms/FormikForm';
import { TIME_UNIT_OPTIONS } from '@pw/consts/measures';
const NodeConfigForm = ({ changeSet = {}, children }) => {
  const { nodeData = {} } = useNodeDataContext();
  const {
    repeat = false,
    interval = { value: '', unit: '' }
  } = nodeData;

  const augmentedChangeSet = useMemo(
    () => ({
      repeat: [repeat, yup.boolean()],
      interval: unitField(interval),
      ...changeSet
    })
    , [repeat, interval, changeSet]);

  console.log({ augmentedChangeSet });

  const [cs, setCs] = useState(changeSet);

  const addNewField = useCallback(() => {
    const updated = {
      ...cs,

    }
  }, [cs]);

  return (
    <NodeModalForm changeSet={augmentedChangeSet}>
      <FormikCheckBox
        label='Repeating'
        name='repeat'
      />
      <RenderIF check={({ values }) => values?.repeat}>
        <UnitField
          label='Measurement interval'
          name='interval'
          options={TIME_UNIT_OPTIONS}
        />
      </RenderIF>
      {children}
    </NodeModalForm >
  );
}

export default NodeConfigForm;