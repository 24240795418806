import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import styles from '@pw/styles/content.styles';

function SummarySkeleton() {
  return (
    <Stack sx={styles} className='root' spacing={3}>
      <FlexBox>
        <Skeleton variant='text' width={100} />
      </FlexBox>
    </Stack>
  );
}

export default withSmallDialogCard(SummarySkeleton);
