import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS } from '@pw/consts/sku';
import debounce from '@pw/utilities/debounce';

const useSkuApproveHook = (skus, upsert) => {
  const { enqueueSnackbar } = useSnackbar();

  return useCallback(() => {
    const newSkus = skus.map((item) => ({
      ...item,
      entries: item.entries?.map((entry) => ({
        ...entry,
        storage: entry.storage?.map((storage) => ({
          ...storage,
          processed: ASSET_PROCESSED_STATUS.CONFIRMED,
        })),
      })),
      processed: INVENTORY_STATUS.CONFIRMED,
    }));
    console.log('SKUs confirmed', newSkus);
    debounce(() => upsert(newSkus), 50);
    enqueueSnackbar('All SKUs confirmed!', { variant: 'success' });
  }, [skus, upsert]);
};

export default useSkuApproveHook;
