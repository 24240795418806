import { Divider } from '@mui/material';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import { SKU_TYPES } from '@pw/consts/sku';
import SKUEntryItemInfo from './SKUEntryItemInfo';
import { useMemo } from 'react';

function SKUItemInfo({ sku, onSelectStorage }) {
	// console.log('sku', sku);
	const { sku_type, amount, waste_amount, picked, properties, entries = [] } = sku ?? {};
	const { unit } = properties ?? {};

	const uu = useMemo(
		() =>
			[
				SKU_TYPES.TRACKED,
				SKU_TYPES.FINISHED,
				SKU_TYPES.WIP,
				SKU_TYPES.SERVICE,
				SKU_TYPES.CONSUMABLE,
			].includes(sku_type)
				? undefined
				: unit,
		[sku_type],
	);

	return (
		<>
			<AmountDisplay label='Amount' amount={amount} unit={uu} />
			<AmountDisplay label='Picked' amount={picked} unit={uu} />
			{!!waste_amount &&(<AmountDisplay label='Waste Amount' amount={waste_amount} unit={uu} />)}
			{entries.map((e, index) => {
				return (
					<>
						<Divider
							variant='middle'
							sx={{ opacity: 0.6 }}
							key={`__div_${index}`}
						/>
						<SKUEntryItemInfo
							key={`entry_${index}`}
							item={e}
							unit={uu}
							sku_type={sku_type}
							onSelectStorage={onSelectStorage}
						/>
					</>
				);
			})}
		</>
	);
}

export default SKUItemInfo;
