import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import LiquidIcon from '@pw/components/icons/Liquid';
import { UNIT } from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';

function LiquidDisplay({ label = 'Liquid', empty = false, value = null, la = null, force = false }) {
	const converter = useConverter();

	const blVal = converter.from(value, UNIT.BL) ?? (force ? Number(0) : null);
	const laVal = converter.from(la, UNIT.LA) ?? (force ? Number(0) : null);

	if (empty) {
		return (
			<Box
				sx={{
					flexDirection: 'row',
					display: 'flex',
					gap: '0.25rem',
				}}
			>
				<LiquidIcon style={{ width: '12px', height: '14px' }} />
				<Body3>
					<Bolder>EMPTY</Bolder>
				</Body3>
			</Box>
		);
	}

	if (!force && !value && !la) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LiquidIcon style={{ width: '12px', height: '14px' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{(force || blVal) && (
					<>
						<Bolder>{blVal?.toFixed(3)}</Bolder>
						<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{converter.unit(UNIT.BL)}</Bolder>
					</>
				)}
				{(force || laVal) && (
					<>
						{(force || blVal) ? ' | ' : ''}
						<Bolder>{laVal?.toFixed(3)}</Bolder>
						<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{converter.unit(UNIT.LA)}</Bolder>
					</>
				)}
			</Body3>
		</Box>
	);
}

export default LiquidDisplay;
