function SvgId(props) {
	return (
		<svg
			id='id_svg__Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 34.96 34.96'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<clipPath id='id_svg__clippath'>
					<path fill='none' d='M0 0h34.96v34.96H0z' />
				</clipPath>
				<style>{'.id_svg__cls-3{fill:#5d5d5d}'}</style>
			</defs>
			<g
				clipPath='url(#id_svg__clippath)'
				fill='none'
				id='id_svg__Group_857'
				data-name='Group 857'
			>
				<g id='id_svg__Group_791' data-name='Group 791'>
					<path
						id='id_svg__Path_9809'
						data-name='Path 9809'
						className='id_svg__cls-3'
						d='M0 17.44c0-3.27.03-6.58 0-9.87A7.531 7.531 0 016.23.09C6.6.03 6.97 0 7.35 0h20.21c3.73.03 6.86 2.81 7.34 6.51.04.33.07.67.07 1.01v19.86a7.461 7.461 0 01-5.75 7.39c-.49.13-.99.2-1.5.2H7.27a7.482 7.482 0 01-6.88-5.12C.13 29.03 0 28.18 0 27.32v-9.87zm14.35.06v8.66c-.03 1.04.79 1.9 1.82 1.93H18.64c5.87.04 10.66-4.68 10.7-10.55 0-.51-.03-1.02-.1-1.53-.34-2.62-1.66-5.01-3.71-6.68a10.452 10.452 0 00-5.75-2.41c-1.13-.12-2.26-.05-3.39-.06-1.04-.09-1.94.68-2.03 1.72v8.91m-3.12 0v-8.7c.05-1.03-.76-1.91-1.79-1.95-1.03-.05-1.91.76-1.95 1.79V26.19c0 .38.1.75.31 1.07.45.7 1.31 1.02 2.11.77a1.89 1.89 0 001.32-1.82v-8.7'
					/>
					<path
						id='id_svg__Path_9810'
						data-name='Path 9810'
						className='id_svg__cls-3'
						d='M18.11 17.44v-6.48c0-.17-.07-.34.24-.36 3.79-.21 7.03 2.7 7.24 6.5.19 3.57-2.38 6.69-5.91 7.18-.36.05-.75.04-1.12.07s-.46-.06-.45-.44c.02-2.16 0-4.32 0-6.48h.01z'
					/>
				</g>
			</g>
		</svg>
	);
}
export default SvgId;
