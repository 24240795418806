const styles = {
	'&.root': {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		gap: '0',

		'.identity': {
			color: '#000',
			fill: '#000',
			// fontSize: '16px',
			// lineHeight: 2.19,
			// letterSpacing: 'normal',
			// textAlign: 'left',
			height: '56px',

			'& .MuiInputBase-root': {
				borderTopLeftRadius: 0,
				borderBottomLeftRadius: 0,
				borderLeft: 'none',
				borderColor: '#CEC9BF',

				'&.Mui-focused fieldset': {
					// borderColor: 'rgb(48, 111, 219)',
				},
			},
		},
	},
	'.passkey': {
		margin: '0 0.5rem',
		display: 'flex',
		flexDirection: 'row',
	},
};

export default styles;
