import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import ContainerListItem from '@pw/components/AssetListItem/ContainerListItem';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import SKUField from '@pw/components/SKUSelector/SKUField';
import skuFields from '@pw/components/SKUSelector/skuFields';
import templateFields from '@pw/components/TemplateHandler/templateFields';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { container: tags = [], sku_id = '', sku = {} } = fields;

	return {
		container: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		...skuFields(sku_id, sku),
	};
}

function Properties({ entity }) {
	const filter = (item) =>
		item?.properties?.asset_type === ASSET_TYPES.container;

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Container Properties</H5>
				<SKUField
					title='Type'
					skuTypes={[SKU_TYPES.TRACKED]}
					tags='container'
					itemFilter={filter}
					entity={entity}
				/>

				<TagSelect entity={entity} name='container' type='container' />
			</Stack>
		</FormikProvider>
	);
}

function ContainerForm(props) {
	const [skuStorage, initSkuStorage, , ,] = useItemListManager(
		ID.sku,
		COMP.sku,
	);
	const [entity, setEntity] = useState(null);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			location: locationFields(initialValues.location),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleInit = (entity) => {
		const { sku_storage = [] } = entity ?? {};
		setEntity(entity);
		sku_storage.sort(COMP.sku);
		debounce(() => initSkuStorage(sku_storage), 50);
	};

	const handleBeforeSave = (entity) => {
		return {
			...entity,
			unique_location_id: toUniqueLocation(entity.location),
		};
	};

	return (
		<AssetForm
			assetLabel='Container'
			assetType={ASSET_TYPES.container}
			changeSetGenerator={changeSetGenerator}
			onInit={handleInit}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Location name='location' entity={entity} />

			<Properties entity={entity} />

			<Stack spacing={2} className='section'>
				<H5 className='section-header'>SKUs</H5>
				<FlexBox gap={1} justifyContent='space-between'>
					<ContainerListItem skuStorage={skuStorage} />
				</FlexBox>
			</Stack>
		</AssetForm>
	);
}

export default withAppLayout(ContainerForm, { title: 'Pallet Management' });
