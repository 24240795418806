/**
 * Convert from SKU to SKUItem
 * @param sku
 */

const toSKUDependentItem = (sku) => {
	// Inbound SKU details
	const {
		id,
		path,
		sku_id,
		sku_name,
		sku_type,
		sku_description,
		amount,
		unit,
	} = sku ?? {};
	// Return the SKUItem
	return {
		id,
		path,
		sku_id,
		sku_name,
		sku_type,
		sku_description,
		amount,
		unit,
	};
};

export default toSKUDependentItem;
