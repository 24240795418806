function ShipmentsIcon(props) {
	return (
		<svg
			{...props}
			className='MuiSvgIcon-root'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
		>
			<defs>
				<style>{'.cls-1 {stroke-width: 0px;}'}</style>
			</defs>
			<path
				className='cls-1'
				d='M126.03,25.88c-20.53,0-41.06,0-61.58,0-15.97,0-31.93,0-47.9,0h0c-3.15,0-5.99.95-8.67,2.9-2.95,2.14-4.86,5.2-5.85,9.35v66.27h0c.07.26.14.51.2.76,1.89,7.96,9,11.58,14.83,11.58h.04c24.94-.07,50.29-.06,74.81-.05,11.28,0,22.56,0,33.84,0,8.31,0,14.83-6.53,14.83-14.87.01-20.45.02-40.9,0-61.35h0c0-8.04-6.54-14.58-14.56-14.58ZM135.68,100.74c0,6.91-4.1,11.03-10.96,11.03h-53.31s-53.54,0-53.54,0c-6.91,0-11.03-4.1-11.03-10.96,0-19.66,0-39.33,0-58.99,0-6.9,4.15-11.03,11.1-11.03,13.02,0,26.05,0,39.08,0h67.5c7.1,0,11.17,4.05,11.17,11.1,0,19.62,0,39.24,0,58.85Z'
			/>
			<path
				className='cls-1'
				d='M61.63,52.5c0-2.44-1.02-3.24-2.01-3.69-.46-.21-.94-.32-1.41-.32-.59,0-1.17.16-1.68.49-.98.63-1.56,1.71-1.56,2.97-.03,4.52-.03,9.12-.02,13.57,0,1.87,0,3.74,0,5.61,0,1.94,0,3.88,0,5.81,0,4.52-.01,9.19.02,13.78.01,1.68,1.22,3.11,2.76,3.25,2.03.19,3.4-.8,3.76-2.73.09-.48.13-1.04.13-1.77.01-5.15,0-10.31,0-15.46v-4.29s0-4.89,0-4.89c0-4.12,0-8.24,0-12.35Z'
			/>
			<path
				className='cls-1'
				d='M87.55,90.59c.03-4.55.03-9.19.02-13.67,0-1.88,0-3.76,0-5.64,0-1.93,0-3.87,0-5.8,0-4.48.01-9.11-.02-13.65-.01-1.68-1.22-3.11-2.75-3.25-.16-.02-.32-.02-.48-.02-1.77,0-2.95.98-3.29,2.75-.09.46-.13.99-.13,1.62-.01,4.56,0,9.13,0,13.69v3.69s0,5.41,0,5.41c0,4.78,0,9.56,0,14.33,0,2.49,1.05,3.28,2.08,3.72,1.03.44,2.14.36,3.04-.23.97-.63,1.53-1.71,1.53-2.96Z'
			/>
			<path
				className='cls-1'
				d='M35.55,52.11c0-1.14-.34-2.07-.97-2.7-.56-.56-1.36-.85-2.3-.85,0,0-.02,0-.02,0-1.97.01-3.19,1.36-3.2,3.53,0,12.79,0,25.58,0,38.37,0,2.18,1.22,3.54,3.18,3.55.97,0,1.77-.29,2.34-.86.63-.62.96-1.55.97-2.68,0-4.47,0-8.95,0-13.42v-5.62s0-5.74,0-5.74c0-4.53,0-9.06,0-13.58Z'
			/>
			<path
				className='cls-1'
				d='M106.97,90.44c0,2.18,1.22,3.54,3.18,3.55.95,0,1.77-.29,2.34-.86.63-.62.96-1.55.96-2.67,0-12.37,0-25.28,0-38.36,0-1.14-.33-2.07-.96-2.69-.56-.56-1.36-.85-2.3-.85,0,0-.02,0-.02,0-1.97.01-3.19,1.36-3.2,3.53,0,4.48,0,8.97,0,13.45v5.59s0,5.72,0,5.72c0,4.53,0,9.07,0,13.6Z'
			/>
		</svg>
	);
}
export default ShipmentsIcon;
