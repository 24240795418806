function SvgMenu(props) {
	return (
		<svg
			data-name='Group 1453'
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 19.422 19.422'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<clipPath id='Menu_svg__a'>
					<path data-name='Rectangle 2263' fill='#464d4d' d='M0 0h19.422v19.422H0z' />
				</clipPath>
			</defs>
			<g data-name='Group 1452' clipPath='url(#Menu_svg__a)' fill='#464d4d'>
				<path
					data-name='Path 10218'
					d='M15.137 19.418a.92.92 0 01-.587-.956c.018-1.015 0-2.03.006-3.044a.807.807 0 01.862-.862c1.015 0 2.03.012 3.044-.006a.919.919 0 01.956.587v4.149c0 .113-.023.136-.136.136h-4.149'
				/>
				<path
					data-name='Path 10219'
					d='M19.419 4.301a.921.921 0 01-.958.587c-1-.018-2.006 0-3.009-.006a.814.814 0 01-.9-.887v-3.1a.812.812 0 01.878-.886c1.009 0 2.019.013 3.028-.006a.919.919 0 01.957.587z'
				/>
				<path
					data-name='Path 10220'
					d='M19.419 11.576a.921.921 0 01-.958.587c-1-.018-2.006 0-3.009-.006a.814.814 0 01-.9-.887v-3.1a.812.812 0 01.878-.886c1.009 0 2.019.013 3.028-.006a.919.919 0 01.957.587z'
				/>
				<path
					data-name='Path 10221'
					d='M.588 19.419A.92.92 0 010 18.462c.018-1 .005-2.006.006-3.009a.814.814 0 01.886-.9H4a.811.811 0 01.886.878c0 1-.015 1.994.007 2.99a.94.94 0 01-.588.995z'
				/>
				<path
					data-name='Path 10222'
					d='M7.862 19.419a.92.92 0 01-.587-.958c.018-1 0-2.006.006-3.009a.814.814 0 01.886-.9h3.1a.811.811 0 01.886.878c0 1-.015 1.994.007 2.99a.941.941 0 01-.588.995z'
				/>
				<path
					data-name='Path 10223'
					d='M4.882 2.458v1.591a.8.8 0 01-.837.833h-3.2a.8.8 0 01-.837-.833V.829A.8.8 0 01.834.003h3.22a.8.8 0 01.827.822v1.629'
				/>
				<path
					data-name='Path 10224'
					d='M12.157 2.458v1.591a.8.8 0 01-.837.833h-3.2a.8.8 0 01-.837-.832V.83a.8.8 0 01.826-.827h3.22a.8.8 0 01.827.822v1.629'
				/>
				<path
					data-name='Path 10225'
					d='M2.458 7.281h1.591a.8.8 0 01.832.836v3.2a.8.8 0 01-.832.837H.829a.8.8 0 01-.823-.827v-3.22a.8.8 0 01.822-.827h1.629'
				/>
				<path
					data-name='Path 10226'
					d='M12.161 9.719v1.591a.8.8 0 01-.842.846h-3.2a.8.8 0 01-.832-.837v-3.2a.8.8 0 01.832-.836h3.22a.8.8 0 01.822.827v1.61'
				/>
			</g>
		</svg>
	);
}
export default SvgMenu;
