import { Timer } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function TimeDisplay({ label, time }) {
	if (!time) return;
	const { value, unit } = time;
	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<Timer style={{ width: '12px', height: 'auto' }} />
			<Body3>
				{label && <Bolder>{`${label}: `}</Bolder>}
				{value}
				{unit}
			</Body3>
		</Box>
	);
}

export default TimeDisplay;
