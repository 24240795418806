import { Link } from '@mui/material';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import { H2 } from '@pw/components/Typography';
import styles from '@pw/styles/content.styles';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ScanAnything = withDialogCard(() => {
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const handleOnRegisterClick = useCallback(() => {
		navigate(`/profile?path=${window?.location?.href}app/overview&section=organizations`);
	}, [navigate]);

	const handleOnQrRead = useCallback(
		({ text: qrString }) => {
			console.log('QR Code', qrString);
			// Check the qr code is valid
			const host = `${window.location.protocol}//${window.location.host}`;
			if (qrString.startsWith(host)) {
				navigate(qrString);
			} else {
				enqueueSnackbar('Invalid QR Code!', { variant: 'error' });
			}
		},
		[enqueueSnackbar, navigate],
	);

	return (
		<FlexBox sx={styles} className='root'>
			<ScanQR withLabel onSuccess={handleOnQrRead} />
			<H2 className='title'>
				Scan Anything or <Link onClick={handleOnRegisterClick}>Register Company</Link>
			</H2>
		</FlexBox>
	);
});

export default ScanAnything;
