const styles = {
	'&.root': {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minHeight: '100vh',
		overflowX: 'hidden',
		backgroundColor: 'rgb(55, 57, 62)',

		/* Application header Bar */
		'.app-header': {
			zIndex: 800,
			backgroundColor: 'white',
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: {
				xs: '1em',
				md: '1.25em',
			},
			paddingBottom: {
				xs: '0.9em',
				md: '1em',
			},
			'.menu-icon': {
				borderRadius: '5px',
				padding: '0px',
			},

			'.scan-icon': {
				borderRadius: '5px',
				padding: '0px',
			},

			'.app-header-logo': {
				border: '1px solid blue',
				width: {
					xs: '150px',
					md: '250px',
				},
				height: {
					xs: '32px',
					md: '42px',
				},
			},

			'.app-header-wrapper': {
				display: 'flex',
				flexDirection: 'row',
				width: {
					xs: '40vw',
					md: 'min-content',
				},
				gap: '1rem',
				alignItems: 'center',

				'.app-header-search': {
					minWidth: {
						xs: '100%',
						sm: '10rem',
					},
					fontWeight: '400',
					borderRadius: '5px',
					backgroundColor: 'rgba(255, 255, 255, 0.3)',
					border: '1px solid rgba(255, 255, 255, 0.4)',

					'*': {
						color: 'rgb(55, 57, 62)',
						fill: 'rgb(55, 57, 62)',
					},
				},

				'.app-header-icons': {
					display: {
						xs: 'none',
						sm: 'flex',
					},
					flexDirection: 'row',
					gap: '1rem',
					justifyContent: 'flex-end',
					alignItems: 'center',
					width: 'min-content',

					svg: {
						fill: 'rgb(55, 57, 62)',
					},
				},
			},
		},

		'.menu': {
			display: 'flex',
			backgroundColor: 'rgb(55, 57, 62)',
			flexDirection: 'column',
			justifyContent: 'space-between',

			'.menu-box': {
				flexGrow: 1,
				paddingLeft: '0.5rem',
				paddingRight: '0.5rem',

				'.account-header': {
					display: 'flex',
					whiteSpace: 'nowrap',
					color: '#000',
					border: '1px solid rgba(255, 255, 255, 0.05)',
					'--shadow-color': '223deg 8% 11%',
					boxShadow: `
					inset 0px 0.5px 0.6px hsl(var(--shadow-color) / 0.23),
					inset 0px 1.3px 1.5px -0.8px hsl(var(--shadow-color) / 0.23),
					inset 0px 3.2px 3.6px -1.7px hsl(var(--shadow-color) / 0.23),
					inset 0px 4.7px 6.7px -2.5px hsl(var(--shadow-color) / 0.23)`,
					backgroundColor: '#C6DB5C',
					borderRadius: {
						xs: '6px',
						md: '10px'
					},
					marginTop: '1rem',
					padding: {
						xs: '0.25rem',
						md: '0.5rem',
					},
					width: '100%',

					'.account-name': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: 0.2,
						borderRadius: '3px',
						gap: 0,
						width: '100% !important',
						cursor: 'pointer',
						'.avatar': {
							width: '50px !important',
						},
						'.label': {
							display: 'flex',
							flexDirection: 'row',
							flexGrow: 1,
							width: '75% !important',

							'.label-content': {
								width: '85% !important',
								'.text': {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},

								'.refresh-button': {
									textColor: 'black',
									color: 'black',
								},
								'.countdown': {
									display: 'inline-block',
								},

								'.timer': {
									maxWidth: '15ch',
									gap: '0.2rem',
									display: 'flex',
									flexDirection: 'row',
									fontWeight: 400,
									textTransform: 'uppercase',
								},
								'.segment': {
									display: 'flex',
									flexDirection: 'row',
									gap: '0.1rem',
								},
								'.number': {
									minWidth: '2ch',
									textAlign: 'right',
								},
								'.unit': {
									minWidth: '1.5ch',
								},


							},
							'.icon': {
								width: '15% !important',
							},
						},
					},
				},

				'.company-header': {
					display: 'flex',
					whiteSpace: 'nowrap',
					color: '#fff',
					borderBottom: {
						xs: 'none',
						md: '1px solid rgba(255, 255, 255, 0.15)',
					},
					padding: {
						xs: '0.25rem',
						md: '0.5rem',
					},
					width: '100%',

					'.company-name': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						padding: 0.2,
						borderRadius: '3px',
						gap: 0,
						width: '100% !important',

						'.avatar': {
							width: '50px !important',
						},

						'.label': {
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							flexGrow: 1,
							width: '75% !important',

							'.label-content': {
								width: '85% !important',
								'.text': {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								},
							},
							'.icon': {
								width: '15% !important',
							},
						},
						// '&:hover': {
						// 	backgroundColor: 'rgba(255, 255, 255, 0.15)',
						// },
						// '&.selected': {
						// 	fontWeight: '400',
						// 	backgroundColor: 'rgba(255, 255, 255, 0.3)',
						// 	border: '1px solid rgba(255, 255, 255, 0.4)',
						// },
						//
						// '&.disabled': {
						// 	fontWeight: '200',
						// 	color: 'rgba(255, 255, 255, 0.45)',
						// },
						//
						// '&.disabled:hover': {
						// 	backgroundColor: 'inherit',
						// },
						//
						// '&.selected:hover': {
						// 	backgroundColor: 'rgba(255, 255, 255, 0.3)',
						// }
					},
				},

				'.company-menu': {
					marginTop: '1rem',
					width: '100%',
					display: 'flex',

					'&:before': {
						content: '" "',
						position: 'sticky',
						top: 0,
						left: 0,
						flexGrow: 1,
						minWidth: '100%',
						minHeight: '10px',
						zIndex: '100',
						'--shadow-color': '223deg 8% 11%',
						boxShadow: `
					inset 0px 0.5px 0.6px hsl(var(--shadow-color) / 0.23),
					inset 0px 1.3px 1.5px -0.8px hsl(var(--shadow-color) / 0.23),
					inset 0px 3.2px 3.6px -1.7px hsl(var(--shadow-color) / 0.23),
					inset 0px 4.7px 6.7px -2.5px hsl(var(--shadow-color) / 0.23)`,
					},

					'.company-name': {
						width: '100% !important',
						height: 'min-content',
						display: 'flex',
						alignItems: 'center',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						cursor: 'pointer',
						borderRadius: '3px',
						paddingTop: '0.15rem',
						paddingBottom: '0.15rem',
						paddingLeft: '50px',
						gap: '0.5rem',
						fontWeight: 300,
						fontSize: '12pt',
					},

					'.company-header--btn': {
						display: 'flex',
						gap: '1rem',
						alignItems: 'center',
					},
				},
			},

			'.menu-footer': {
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
				color: 'rgba(255, 255, 255, 0.5)',
				minWidth: '250px',
				display: 'flex',
				flexDirection: 'column',
				maxHeight: 'min-content',

				'.copy-right': {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: '1rem',
					textAlign: 'center',
					fontSize: '7pt',
				},
			},
		},

		'@keyframes fade_in': {
			'0%': {
				opacity: 0,
				// transform: 'scaleX(0)',
			},
			'10%': {
				opacity: 0,
				// transform: 'scaleX(0)',
			},
			'90%': {
				opacity: 1,
				// transform: 'scaleX(1)',
			},
		},

		'.menu-show': {
			width: '250px',
			transition: '1s',
			animation: 'fade_in 1s',
		},

		'@keyframes fade_out': {
			'0%': {
				opacity: 1,
				// transform: 'scaleX(1)',
			},
			'70%': {
				opacity: 0,
				// transform: 'scaleX(0)',
			},
			'100%': {
				opacity: 0,
				// transform: 'scaleX(0)',
			},
		},

		'.menu-hide': {
			width: {
				xs: '0px',
				sm: '250px',
			},
			transition: '1s',
			animation: {
				xs: 'fade_out 1s',
				sm: 'fade_in 1s',
			},
		},

		'.content': {
			// border: '1px solid blue',
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'row',
			overflowX: 'auto',

			/* Application content */
			'.wrapper-content': {
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				position: 'relative',
				overflowX: 'scroll',
				webkitOverflowScrolling: 'touch',
				paddingLeft: {
					xs: '0',
					md: '2rem',
				},
				paddingRight: {
					xs: '0',
					md: '2rem',
				},
				paddingBottom: {
					xs: '0',
					md: '2rem',
				},
				paddingTop: {
					xs: '0',
					md: '1rem',
				},
				background: 'whitesmoke',
				minWidth: {
					xs: '100vw',
					sm: '0',
				},

				// background: 'w',
				// '--shadow-color': '180deg 4% 9%',
				// boxShadow: `
				//   0.3px 0.4px 0.5px hsl(var(--shadow-color) / 0.45),
				//   0.6px 0.7px 1px -1.3px hsl(var(--shadow-color) / 0.43),
				//   1.4px 1.9px 2.6px -2.7px hsl(var(--shadow-color) / 0.41)`,

				'.app-title-bar': {
					display: 'flex',
					width: '100%',
					whiteSpace: 'nowrap',
					justifyContent: 'center',
					alignItems: 'center',
					paddingTop: '1rem',
					paddingBottom: '1rem',
					marginBottom: {
						xs: 0,
						md: '1rem',
					},
				},

				'.wrapper-box': {
					width: '100%',
					// border: '1px solid white',
					display: 'flex',
					flexDirection: 'column',
					// flexGrow: 1,
					justifyItems: 'start',
					// padding: {
					//   xs: '0',
					//   md: '2rem',
					// },

					'.adaptive-content': {
						// border: '1px solid yellow',
						display: 'flex',
						flexDirection: {
							xs: 'column',
							md: 'row',
						},
						// margin: {
						//   xs: '0',
						//   md: '2rem',
						// },
						columnGap: '2rem',
						rowGap: '0.25rem',
					},
					'.adaptive-content > *': {
						width: {
							xs: '100%',
							md: 'max-content',
						},
						minWidth: {
							xs: '100%',
							md: '568px',
						},
						maxWidth: {
							xs: '100%',
							md: '568px',
						}
					},
				},
			},
		},

		'.wrapper': {
			display: 'flex',
			flexDirection: {
				xs: 'column',
				md: 'row',
			},
			flexGrow: 1,
			alignItems: {
				xs: 'flex-start',
				md: 'stretch',
			},
		},

		'.empty-wrapper': {
			paddingTop: {
				xs: 0,
				md: '1rem',
				lg: '2rem',
			},
		},
	},

	// '&.light-bg': {
	//   background: 'rgb(220, 220, 220)',
	// },
};

export default styles;
