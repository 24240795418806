import {
	AddCircle,
	FilterAltOffOutlined,
	FilterAltOutlined,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import VendorList from '@pw/components/admin/VendorProperties/VendorList';
import VendorModal from '@pw/components/admin/VendorProperties/VendorModal';
import FormikContext from '@pw/context/FormikContext';
import { getAccountAttrs } from '@pw/utilities/adapters/getAttrs';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import { Filters, useFilteredHook } from '@pw/utilities/hooks/logic/useFilters';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import React, { useCallback, useContext, useEffect, useState } from 'react';

function VendorProperties(props) {
	const { title = 'Vendors', initialValues = [], field = 'vendors' } = props;

	const confirm = useConfirm();

	const [vendors, , , upsertVendors, removeVendors] = useItemListManager(
		ID.name,
		COMP.sku,
		initialValues ?? [],
	);

	const filterOptions = [
		{
			value: 'none',
			label: 'None',
		},
		{
			value: 'name',
			getter: (x) => x?.vendor?.name ?? x?.vendor?.public_name,
			label: 'Name',
		},
	];

	const [showFilter, setShowFilter] = useState(false);

	const [filteredVendors, [setFilter, setSort, setDirection]] = useFilteredHook(
		showFilter,
		vendors,
		filterOptions,
		(a) => a?.vendor,
		getAccountAttrs,
	);

	// Currently selected vendor
	const [selectedVendor, setSelectedVendor] = useState(null);

	const handleRemoveVendor = (vendor) => (removeFunc) => {
		confirm({
			title: 'Remove Vendor',
			content: <Body1>{`Remove ${vendor?.vendor?.name}?`}</Body1>,
		})
			.then(() => removeFunc(vendor))
			.catch(() => {});
	};

	return (
		<>
			<InventoryComponent
				{...{
					vendors,
					field,
					title,
					setSelectedVendor,
					setShowFilter,
					showFilter,
					setSort,
					setDirection,
					filterOptions,
					filteredVendors,
					setFilter,
					handleRemoveVendor,
					removeVendors,
				}}
			/>
			<ModalComponents
				{...{ upsertVendors, setSelectedVendor, selectedVendor }}
			/>
		</>
	);
}

function InventoryComponent({
	vendors,
	field,
	title,
	setSelectedVendor,
	setShowFilter,
	showFilter,
	setSort,
	setDirection,
	filterOptions,
	filteredVendors,
	setFilter,
	handleRemoveVendor,
	removeVendors,
}) {
	const { setFieldValue } = useContext(FormikContext);

	useEffect(() => {
		console.log('Updating vendors', vendors);
		debounce(() => setFieldValue(field, vendors), 25);
	}, [vendors]);

	return (
		<Stack spacing={1} className='section'>
			{title && <H5 className='section-header'>{title}</H5>}

			<Stack className='inventory'>
				<Box className='inventory-header'>
					<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
					<Stack direction='row' spacing={1}>
						<TitledButton
							handleClick={() => setSelectedVendor({})}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>
						<TitledButton
							handleClick={() => setShowFilter((x) => !x)}
							label='Filter'
						>
							{showFilter && <FilterAltOffOutlined height={24} width={24} />}
							{!showFilter && <FilterAltOutlined height={24} width={24} />}
						</TitledButton>
					</Stack>
				</Box>
				<Filters
					showFilter={showFilter}
					setFilter={setFilter}
					setSort={setSort}
					setDirection={setDirection}
					options={filterOptions}
				/>
				{vendors.length > 0 && (
					<VendorList
						items={filteredVendors}
						onEdit={(i) => setSelectedVendor(i)}
						onRemove={(i) => handleRemoveVendor(i)(removeVendors)}
					/>
				)}
				{vendors.length === 0 && (
					<Box p={2} className='inventory-footer'>
						<Body3>None</Body3>
					</Box>
				)}
			</Stack>
		</Stack>
	);
}

function ModalComponents({ upsertVendors, setSelectedVendor, selectedVendor }) {
	const handleVendorUpdate = useCallback(
		(vendor) => {
			if (vendor) {
				upsertVendors(vendor);
			}
			setSelectedVendor(null);
		},
		[upsertVendors, setSelectedVendor],
	);

	return (
		<>
			{!!selectedVendor && (
				<VendorModal
					title='Partner'
					open={!!selectedVendor}
					item={selectedVendor}
					onClose={handleVendorUpdate}
				/>
			)}
		</>
	);
}

export default VendorProperties;
