import debounce from '@pw/utilities/debounce';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isOnline,
  selectActiveListItem,
  selectMessages,
  selectPageLoading,
  selectPageTitle,
} from './selectors';
import {
  clearPageLoading,
  popNotification,
  pushNotification,
  setActiveListItem,
  setOnline,
  setPageLoadingMessage,
  setPageTitle,
} from './slice';

export const useActiveItemHook = () => {
  const activeItem = useSelector(selectActiveListItem);
  const dispatch = useDispatch();
  const setActiveItem = useCallback((item) => dispatch(setActiveListItem(item)), [dispatch]);

  return { activeItem, setActiveItem };
};

export const usePageLoadingHook = () => {
  const loading = useSelector(selectPageLoading);
  const dispatch = useDispatch();
  const setLoading = useCallback((loading) => dispatch(setPageLoadingMessage(loading)), [dispatch]);
  const clearLoading = useCallback(() => dispatch(clearPageLoading()), [dispatch]);
  return { loading, setLoading, clearLoading };
};

export const useNotificationHook = () => {
  const messages = useSelector(selectMessages);
  const dispatch = useDispatch();
  const notify = useCallback((error) => dispatch(pushNotification(error)), [dispatch]);
  const clear = useCallback((ids) => dispatch(popNotification(ids)), [dispatch]);
  return { messages, notify, clear };
};

export const useIsOnlineHook = () => useSelector(isOnline);

export const useOnlineHook = () => {
  const online = useSelector(isOnline);
  const dispatch = useDispatch();
  const setOnlineState = useCallback((network) => dispatch(setOnline(network)), [dispatch]);
  return { online, setOnlineState };
};

export const usePageTitleHook = (pageTitle = undefined) => {
  const title = useSelector(selectPageTitle);
  const dispatch = useDispatch();
  const setTitle = useCallback((title) => dispatch(setPageTitle(title)), [dispatch]);

  useEffect(() => {
    if (pageTitle) {
      debounce(() => setTitle(pageTitle));
    }
  }, [pageTitle]);

  return { title, setPageTitle: setTitle };
};
