import transactionsRumReportFields from './transactionsRumReportFields';

export default function transactionsRumFields(fields = {}) {
	const {
		taxPaymentFields = {},
		useOfUSFields = {},
		hospital_Scientific_orEducationalUseFields = {},
		exportFields = {},
		transfertoForeignTradeZoneFields = {},
		transfertoCMBWFields = {},
		useAsSuppliesOnVesselsOrAircraftFields = {},
		useInWineProductionFields = {},
		enteredInProcessingAccountFields = {},
		enteredForTransferInBondFields = {},
		enteredInStorageAccountFields = {},
		withdrawnForResearchDevelopmentOrTestingFields = {},
		specifyFields = {},
		producedFields = {},
		receivedForRedistillationFields = {},
		specify2Fields = {},
		physicalInventoryEOQ_ReceivedForRedistillationFields = {},
		physicalInventoryEOQ_UnfinishedSpiritsFields = {},
	} = fields;
	return {
		'1_TaxPayment': transactionsRumReportFields(taxPaymentFields),
		'2_UseOfUS': transactionsRumReportFields(useOfUSFields),
		'3_HospitalScientificorEducationalUse': transactionsRumReportFields(
			hospital_Scientific_orEducationalUseFields,
		),
		'4_Export': transactionsRumReportFields(exportFields),
		'5_TransfertoForeignTradeZone': transactionsRumReportFields(
			transfertoForeignTradeZoneFields,
		),
		'6_TransfertoCMBW': transactionsRumReportFields(transfertoCMBWFields),
		'7_UseAsSuppliesOnVesselsOrAircraft': transactionsRumReportFields(
			useAsSuppliesOnVesselsOrAircraftFields,
		),
		'8_UseInWineProduction': transactionsRumReportFields(
			useInWineProductionFields,
		),
		'9_EnteredInProcessingAccount': transactionsRumReportFields(
			enteredInProcessingAccountFields,
		),
		'10_EnteredForTransferInBond': transactionsRumReportFields(
			enteredForTransferInBondFields,
		),
		'11_EnteredInStorageAccount': transactionsRumReportFields(
			enteredInStorageAccountFields,
		),
		'12_WithdrawnForResearchDevelopmentOrTesting': transactionsRumReportFields(
			withdrawnForResearchDevelopmentOrTestingFields,
		),
		'13_Specify': transactionsRumReportFields(specifyFields),
		'14_Produced': transactionsRumReportFields(producedFields),
		'15_ReceivedForRedistillation': transactionsRumReportFields(
			receivedForRedistillationFields,
		),
		'16_Specify': transactionsRumReportFields(specify2Fields),
		'17a_PhysicalInventoryEOQReceivedForRedistillation':
			transactionsRumReportFields(
				physicalInventoryEOQ_ReceivedForRedistillationFields,
			),
		'17b_PhysicalInventoryEOQUnfinishedSpirits': transactionsRumReportFields(
			physicalInventoryEOQ_UnfinishedSpiritsFields,
		),
	};
}
