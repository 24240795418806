import {
	APP_API_GATEWAY,
	CONTENT_API_GATEWAY,
	CONTENT_BUCKET,
} from '@pw/consts/config';
import fetchDelete from '@pw/utilities/fetchDelete';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPut from '@pw/utilities/fetchPut';
import objectToQueryString from '@pw/utilities/objectToQueryString';

export const getAvatar = async (id) => {
	const path =
		CONTENT_BUCKET && CONTENT_BUCKET !== ''
			? `${CONTENT_API_GATEWAY}/${CONTENT_BUCKET}/${id}`
			: `${CONTENT_API_GATEWAY}/${id}`;

	const response = await fetch(path);
	const text = await response.text();

	if (response.ok) {
		return response.text();
	}
	throw new Error(text);
};

export const getAvatarSmall = async (id) => {
	const path =
		CONTENT_BUCKET && CONTENT_BUCKET !== ''
			? `${CONTENT_API_GATEWAY}/${CONTENT_BUCKET}/${id}-small`
			: `${CONTENT_API_GATEWAY}/${id}-small`;

	const response = await fetch(path);
	const text = await response.text();

	if (response.ok) {
		return response.text();
	}
	throw new Error(text);
};

const PRIV_BASE = `${APP_API_GATEWAY}/accounts`;

export const updateProfile = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/profile`, headers, request);

export const updatePassword = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/profile/password`, headers, request);

export const listPasskeys = async (headers = {}) =>
	fetchGet(`${PRIV_BASE}/passkey/list`, headers);

export const removePasskey = async (params, headers = {}) =>
	fetchDelete(`${PRIV_BASE}/passkey${objectToQueryString(params)}`, headers);
