import { f5 } from '@pw/styles/fonts';
import { ph2, pv3 } from '@pw/styles/padding';

const styles = {
	'&.root': {
		// border: '1px solid red',
		width: '100%',

		// padding: {
		//   xs: '0.1rem',
		//   md: '0.25rem',
		// },
		// maxWidth: {
		//   xs: '95vw',
		//   lg: '100%',
		// },
	},
	'.tasks': {
		width: '100%',
		display: 'grid',
		// gridGap: '1rem',
		gridTemplateColumns: {
			xs: 'auto',
			md: '1fr 1fr 1fr 1fr',
		},
		// gridTemplateColumns: {
		//   xs: 'repeat(4, 90vw)',
		//   sm: 'repeat(4, 35vw)',
		//   md: 'repeat(4, 30%)',
		//   lg: 'repeat(4, 23.5%)',
		// },
		willChange: 'transform',
		alignContent: 'center',
		overflowX: 'auto',
		maxHeight: 'min-content',
		scrollSnapCoordinate: '0 0',
		scrollSnapPointsX: '1fr 1fr 1fr 1fr',
		// scrollSnapPointsX: {
		//   xs: 'repeat(4, 100vw)',
		//   sm: 'repeat(4, 35vw)',
		//   md: 'repeat(4, 30%)',
		//   lg: 'repeat(4, 23.5%)',
		// },
		scrollSnapType: 'x mandatory',
		webkitOverflowScrolling: 'touch',
		alignItems: 'start',
		padding: {
			xs: '0.5rem',
			lg: '0.75rem',
		},
	},
	'.column': {
		// flex: '0 0 25rem',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#eeeeee',
		// max-height: calc(100vh - 11.8rem);
		borderRadius: '10px',
		marginRight: '1rem',
		marginBottom: {
			xs: '1rem',
			md: '0',
		},
		padding: '0.75rem',
		minWidth: {
			xs: '100%',
			md: '25rem',
		},

		'--shadow-color': '0deg 0% 54%',
		boxShadow: {
			xs: 'none',
			md: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
		},
		// ...ph2,
		// ...pb2,
		// ...br3,
		// '--shadow-color': '41deg 6% 69%',
		// boxShadow: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.24),
		// 0.7px 1.4px 1.8px -0.8px hsl(var(--shadow-color) / 0.24),
		// 1.7px 3.5px 4.4px -1.7px hsl(var(--shadow-color) / 0.24),
		// 4.2px 8.4px 10.6px -2.5px hsl(var(--shadow-color) / 0.24)`,
		scrollSnapAlign: 'start',
		// maxHeight: '90vh',
		// background: `
		//     linear-gradient(225deg, rgba(25,23,23,0.5), rgba(43,47,47,0.4), rgba(43,47,47,0.3), rgba(32,32,32,0.2))
		//  `,
		alignContent: 'start',
		// display: 'flex',
		// flexDirection: 'column',
		// // minWidth: 'max-content',

		'.column-header': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			// color: 'white',
			// fill: 'white',

			'.title': {
				...f5,
				...ph2,
				...pv3,
				fontWeight: 'bold',
				// width: 'max-content',
			},
		},
		'.column-content': {
			padding: {
				xs: '0.1rem',
				md: '0.2rem',
			},
			flexGrow: 1,
			overflowY: {
				xs: 'none',
				md: 'auto',
			},
			display: 'grid',
			gridAutoRows: 'min-content',
			gridGap: '0.5rem',
		},
	},
	'.column:last-of-type': {
		marginRight: 0,
		marginBottom: 0,
	},
	'.check': {
		width: '14px',
		height: 'auto',
	},

	// dialog: {
	//   '.MuiDialog-paper': {
	//     ...br3,
	//     width: '100vw',
	//     maxWidth: '900px',
	//     minHeight: '400px',
	//     background: '#E3E3E2',
	//     margin: {
	//       xs: '1rem',
	//       md: 'auto',
	//     },
	//     padding: {
	//       xs: '2rem',
	//       // md: '4rem',
	//     },
	//   },
	//   '.content': {
	//     position: 'relative',
	//     display: 'flex',
	//     flexDirection: 'row',
	//     flexWrap: 'wrap',
	//     alignItems: 'stretch',
	//     width: '100%',
	//     maxWidth: '1200px',
	//   },
	//   '.closeButton': {
	//     position: 'absolute',
	//     ...br_pill,
	//
	//     top: '-1rem',
	//     right: '-1rem',
	//
	//     '.icon': {
	//       width: '1em',
	//       height: '1em',
	//     },
	//   },
	// },
};

export default styles;
