
import { useMemo, useContext, useCallback } from 'react';

import PlayCircleFilled from '@mui/icons-material/PlayCircleFilledOutlined';
import PauseCircleFilled from '@mui/icons-material/PauseCircleFilled';
import AccessTimeFilled from '@mui/icons-material/AccessTimeFilled';

import { Box, Button } from '@mui/material';
import DesignerContext from '@pw/context/DesignerContext';
// import CountdownTimer from '../Timer/Countdown';
import Timer from '../Timer';

// const timeWithUnits = (timeStr, show = ['h', 'm'], hideEmpty = true) => {
//   if (!timeStr) return '';
//   const [h, m, s] = timeStr.split(':');
//   const parts = [
//     (show.includes('h') && h !== '00') && `${h}h`,
//     (show.includes('m') && m !== '00') && `${m}m`,
//     (show.includes('s')) && `${s}s`,
//   ];
//   return parts.filter((p) => Boolean(p)).join('')
// }

const styles = {
  '&.node-info': {
    display: 'flex',
    flexFlow: 'row nowrap',
    overflow: 'hidden',
  },
  '.child-content': {
    display: 'inline-flex',
    position: 'relative',
    zIndex: 8,
  },
  '&.running .child-content': {
    // position: 'static',
  },
  '.progress.MuiCircularProgress-indeterminate': {
    opacity: 0
  },
  '.progress-total': {
    marginLeft: '-0.5px',
  },
  '.MuiCircularProgress-root.MuiCircularProgress-root': {
    zIndex: 9,
    marginLeft: '-0.5px',
    marginTop: '0.5px',
    pointerEvents: 'none'
  },
};

const TimerBadge = ({ id }) => {

  const { nodesData, setNodesData, mode } = useContext(DesignerContext);
  const nodeData = nodesData[id] || {};

  const updateNodeData = useCallback((payload) => {
    setNodesData((prev) => {
      const update = {
        ...prev,
        [id]: {
          ...prev?.[id],
          ...payload,
        }
      };
      return update;
    });
  }, [nodesData, setNodesData]);

  const {
    duration = null, started = null, ended = null
  } = nodeData;

  // const elapsed = useMemo(() => {
  //   if (!(started && ended)) return null;
  //   const seconds = (new Date(ended).getTime() - new Date(started).getTime()) / 1000;
  //   const d = new Date(null);
  //   d.setSeconds(seconds)
  //   const _elapsed = d.toISOString().substring(11, 19);
  //   // const ellapsed = new Date(seconds * 1000).toISOString().substr(11, 8);
  //   console.log({ id, _elapsed });
  //   return _elapsed;
  // }, [started, ended]);

  return (
    <Box
      className={`node-info timing ${!started ? 'no-text' : ''} ${started && !ended && "running"} ${ended ? 'expanded' : ''}`}
      sx={styles}
    >
      {(mode === 'design')
        ? (
          <Box className="token">{duration?.value}{duration?.unit}</Box>
        )
        // : (started)
        //   ? (
        //     <Box className="" sx={{ whiteSpace: 'nowrap' }}>
        //       {ended
        //         ? <AccessTimeFilled />
        //         : (
        //           <Button className="button running pulse" onClick={() => {
        //             updateNodeData({ ended: new Date().toUTCString() })
        //           }}>
        //             <PauseCircleFilled />
        //           </Button>)
        //       }
        //     </Box>
        //   )
        //   : (
        //     <Button className="button" onClick={() => {
        //       updateNodeData({ started: new Date().toUTCString() })
        //     }}>
        //       <PlayCircleFilled />
        //     </Button>
        //   )} &nbsp;<Box className="text">
        // {timeWithUnits(elapsed)} / {duration?.value}{duration?.unit}
        : (
          <Timer id={started ? id : null} offset={duration} isStopped={ended}
            onComplete={(...params) => alert(`timer finished! ${JSON.stringify(params, null, 2)}`)}
          >
            {/* <Box className="token">{interval?.value}{interval?.unit}</Box> */}
            {(started && ended)
              ? (<AccessTimeFilled />)
              : started
                ? (
                  <Button className="button running pulse" onClick={() => {
                    updateNodeData({ ended: new Date().toUTCString() })
                  }}>
                    <PauseCircleFilled />
                  </Button>
                )
                : (
                  <Button className="button" onClick={
                    () => {
                      updateNodeData({ started: new Date().toUTCString() })
                    }}>
                    <PlayCircleFilled />
                  </Button>
                )}
          </Timer>
        )}&nbsp;<Box className="text">/{`${duration.value}${duration.unit}`}</Box>
    </Box >
  );
}

export default TimerBadge;