import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';
import HeadlineWidgetChart from '@pw/components/Dashboard/components/HeadlineWidgetChart';
import ScanAnything from '@pw/components/Dashboard/components/ScanAnything';
import SensorPanel from '@pw/components/Dashboard/components/SensorPanel';
import { processAssetList, processRequestList } from '@pw/components/Tasks';
import { H5 } from '@pw/components/Typography';
import Liquid from '@pw/components/icons/Liquid';
import Monitoring from '@pw/components/icons/Monitoring';
import TasksIcon from '@pw/components/icons/Tasks';
import { UNIT } from '@pw/consts/units';
import { useCompanyLiquidTypes } from '@pw/redux/containers/User/hooks';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useLiquidOverviewLazyQuery } from '@pw/utilities/hooks/service/useLiquidOverviewQuery';
import { useSensorDataLazyQuery } from '@pw/utilities/hooks/service/useSensorDataQuery';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssetsSection from './components/AssetsSection';
import DashboardFilter from './components/DashboardFilter';
import LiquidTransfersWidget from './components/LiquidTransfersWidget';
import RecentRequestsWidget from './components/RecentRequestsWidget';
import ReportWidget from './components/ReportWidget';
import TasksWidget from './components/TasksWidget';
import styles from './dashboard.styles';

export default function Dashboard({ data, refetch, facilities = [] }) {
	const { t } = useTranslation();
	const converter = useConverter();
	const bl_unit = converter.unit(UNIT.BL);
	const la_unit = converter.unit(UNIT.LA);
	const [unique_location_id, setUniqueLocationId] = useState();
	const [fetchSensorData, { data: sensorData = {}, isLoading }] =
		useSensorDataLazyQuery({});
	const [
		fetchLiquidData,
		{ data: liquidData, isLoading: isLiquidDataLoading },
	] = useLiquidOverviewLazyQuery(null);

	const liquidTypes = useCompanyLiquidTypes();
	// start loading with first facility
	useEffect(() => {
		facilities?.[0]?.id && setUniqueLocationId(facilities?.[0]?.id);
	}, [facilities]);
	// listen for changes in unique_location_id
	useEffect(() => {
		unique_location_id && fetchSensorData({ unique_location_id });
	}, [unique_location_id, fetchSensorData]);

	const [skuId, setSkuId] = useState();

	useEffect(() => {
		fetchLiquidData({ sku_id: skuId }).finally(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skuId]);

	const isCompanyActive = true; // TODO: useSelector(selectIsCompanyActive);
	const {
		awaitingRequests = [],
		pendingAssets = [],
		casks = [],
		skus = [],
	} = data ?? {};

	const parsed = (liquidData ?? []).reduce(
		(acc, item) => {
			acc.total_bl += converter.from(item?.total_bl ?? 0, UNIT.BL);
			acc.total_la += converter.from(item?.total_la ?? 0, UNIT.LA);

			acc.total_bl_produced += converter.from(
				item?.total_bl_produced ?? 0,
				UNIT.BL,
			);
			acc.total_la_produced += converter.from(
				item?.total_la_produced ?? 0,
				UNIT.LA,
			);

			acc.bl.push(converter.from(item?.total_bl ?? 0, UNIT.BL));
			acc.la.push(converter.from(item?.total_la ?? 0, UNIT.LA));

			acc.produced_bl.push(
				converter.from(item.total_bl_produced ?? 0, UNIT.BL),
			);
			acc.produced_la.push(
				converter.from(item.total_la_produced ?? 0, UNIT.LA),
			);

			acc.total_bl_in += Math.abs(
				converter.from(item?.total_bl_in ?? 0, UNIT.BL),
			);
			acc.total_la_in += Math.abs(
				converter.from(item?.total_la_in ?? 0, UNIT.LA),
			);

			acc.total_bl_out += Math.abs(
				converter.from(item?.total_bl_out ?? 0, UNIT.BL),
			);
			acc.total_la_out += Math.abs(
				converter.from(item?.total_la_out ?? 0, UNIT.LA),
			);

			acc.dates.push(new Date(item.binned_timestamp));
			return acc;
		},
		{
			bl: [],
			la: [],
			produced_bl: [],
			produced_la: [],
			dates: [],
			total_bl: 0,
			total_la: 0,
			total_bl_produced: 0,
			total_la_produced: 0,
			total_bl_in: 0,
			total_la_in: 0,
			total_bl_out: 0,
			total_la_out: 0,
		},
	);

	const tasks = useMemo(
		() => [
			...processAssetList({ items: pendingAssets }),
			...processRequestList(awaitingRequests),
		],
		[awaitingRequests, pendingAssets],
	);

	return (
		<Stack sx={styles} className='root'>
			{!isCompanyActive && <ScanAnything />}
			{isCompanyActive && (
				<>
					<Box className='filter'>
						<DashboardFilter />
					</Box>
					{!!facilities?.length && (
						<Box className='content-row content-light'>
							<Box className='content-header'>
								<Box className='title-box'>
									<Monitoring height={24} width={24} />
									<H5>Monitoring</H5>
								</Box>
								<BasicMenu
									menuOptions={facilities.map(({ id, title }) => ({
										title,
										onClick: () => setUniqueLocationId(id),
									}))}
									isLoading={isLoading}
								/>
							</Box>
							<Box className='content-body content-3col'>
								<SensorPanel data={sensorData} />
							</Box>
						</Box>
					)}

					<AssetsSection skus={skus} casks={casks} />

					<Box className='content-row'>
						<Box className='content-header'>
							<Box className='title-box'>
								<Liquid height={24} width={24} />
								<H5>Liquid</H5>
							</Box>
							{!!liquidTypes.length && (
								<BasicMenu
									menuOptions={liquidTypes.map(({ sku_name, sku_id }) => ({
										title: sku_name,
										onClick: () => setSkuId(sku_id),
									}))}
									isLoading={isLiquidDataLoading}
								/>
							)}
						</Box>
						<Box className='content-body content-3col'>
							<HeadlineWidgetChart
								title='Total Liquid'
								data={[
									{
										id: 0,
										label: `${t('bulk')}(${bl_unit})`,
										value: parsed.total_bl ?? 0,
										color: '#41424c',
									},
									{
										id: 1,
										label: `${t('alcohol')}(${la_unit})`,
										value: parsed.total_la ?? 0,
										color: '#F0AA29',
									},
								]}
								lineChartData={{
									bl: parsed.bl,
									la: parsed.la,
									dates: parsed.dates,
								}}
							/>
							<HeadlineWidgetChart
								title='Total Production'
								data={[
									{
										id: 0,
										label: `${t('bulk')}(${bl_unit})`,
										value: parsed.total_bl_produced ?? 0,
										color: '#41424c',
									},
									{
										id: 1,
										label: `${t('alcohol')}(${la_unit})`,
										value: parsed.total_la_produced ?? 0,
										color: '#F0AA29',
									},
								]}
								lineChartData={{
									bl: parsed.produced_bl,
									la: parsed.produced_la,
									dates: parsed.dates,
								}}
							/>
							<LiquidTransfersWidget
								title='Liquid Movement'
								bl_in={parsed.total_bl_in}
								la_in={parsed.total_la_in}
								bl_out={parsed.total_bl_out}
								la_out={parsed.total_la_out}
							/>
						</Box>
					</Box>

					<Box className='content-row'>
						<Box className='content-header'>
							<Box className='title-box'>
								<TasksIcon height={24} width={24} />
								<H5>Activity</H5>
							</Box>
						</Box>

						<Box className='content-body content-3col'>
							<TasksWidget refetch={refetch} data={tasks ?? []} />
							<RecentRequestsWidget
								data={data?.requests ?? []}
								title='Deliveries/Shipments'
							/>
							<ReportWidget title='Report' />
						</Box>
					</Box>
				</>
			)}
		</Stack>
	);
}
