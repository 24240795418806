import { cloneSKU, createSKU, getSKUList } from '@pw/services/sku.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const cloneSKUThunk = createAsyncThunk(
	'sku/clone',
	async (sku, { rejectWithValue }) => {
		try {
			const response = await cloneSKU(sku);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const upsertSKUThunk = createAsyncThunk(
	'sku/upsert',
	async (sku, { rejectWithValue }) => {
		try {
			const response = await createSKU(sku);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const refetchSKUsThunk = createAsyncThunk(
	'sku/refetch',
	async (params, { rejectWithValue }) => {
		try {
			console.log(`refetchSKUsThunk called `, params);

			// @ts-ignore
			return await getSKUList(params);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const updateSKUsThunk = createAsyncThunk(
	'sku/update',
	async (skus) => skus,
);
