function SvgCaskIcon(props) {
	return (
		<svg
			id='CaskIcon_svg__Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 52.3 52.3'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<style>
					{
						'.CaskIcon_svg__cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}'
					}
				</style>
			</defs>
			<circle
				className='CaskIcon_svg__cls-1'
				cx={26.2}
				cy={26.2}
				r={25.2}
				transform='rotate(-76.7 26.168 26.15)'
			/>
			<circle className='CaskIcon_svg__cls-1' cx={26.2} cy={26.2} r={20.6} />
			<path
				className='CaskIcon_svg__cls-1'
				d='M12.2 11v30.3M19.2 6.8v38.8M26.2 5.6v26.6M40.1 11v30.3M33.1 6.8v38.8'
			/>
			<circle className='CaskIcon_svg__cls-1' cx={26.2} cy={39.8} r={2.6} />
		</svg>
	);
}
export default SvgCaskIcon;
