import FormikContext from '@pw/context/FormikContext';
import { getTemplates } from '@pw/services/template.service';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

const useFormTemplateHandlerSearch = (type) => {
	const { values, setValues, setFieldValue } = useContext(FormikContext);
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);

	const templateType = useMemo(() => `form_${type}`, [type]);
	const templateName = useMemo(() => values?.templateName ?? '', [values?.templateName]);

	const handleOnSearch = useCallback(async () => {
		if (templateName) {
			console.log('Searching for...', templateName, templateType);
			setIsLoading(true);
			await getTemplates({
				template_name: templateName,
				asset_type: templateType,
			})
				.then(({ items = [] }) => {
					console.log('Search result', items);
					if (items.length > 0) {
						const { properties } = items?.[0] ?? {};
						const mergeValue = JSON.parse(properties);
						setValues({
							...values,
							...mergeValue,
							saveTemplate: false, // lets also reset the save template checkbox
						});
						enqueueSnackbar('Template loaded', { variant: 'success' });
					} else {
						enqueueSnackbar('Template not found', { variant: 'warning' });
					}
					return items;
				})
				.catch((error) => {
					console.log('Error searching', error);
					enqueueSnackbar(error?.message ?? 'Error loading template', {
						variant: 'error',
					});
					return error;
				})
				.finally(() => {
					setIsLoading(!true);
				});
		}
	}, [enqueueSnackbar, setValues, templateName, templateType, values]);

	// set templateType to form_{type} on mount
	useEffect(() => {
		if (!values) return;
		setFieldValue('templateType', templateType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFieldValue, templateType]);

	return [handleOnSearch, { isLoading }];
};

export default useFormTemplateHandlerSearch;
