import {
  WEIGHT_UNIT_OPTIONS,
} from '@pw/consts/measures';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';

const PeatForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const { quantity = { value: '', unit: '' } } = nodeData;
  const changeSet = {
    quantity: unitField(quantity),
  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <UnitField
        label='Quantity'
        name='quantity'
        options={WEIGHT_UNIT_OPTIONS}
      />

    </NodeModalForm>
  )
}

export default PeatForm;
