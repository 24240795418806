import RequestQRCode from '@pw/components/QRImage/RequestQRCode';
import Bolder from '@pw/components/Typography/Bolder';
import { ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { SKU_TYPE_REVERSE } from '@pw/consts/sku';
import { UNIT } from '@pw/consts/units';
import useLiquidSummaryCalculator from '@pw/utilities/hooks/logic/useLiquidSummaryCalculator';

const styles = {
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		marginTop: '2rem',
		marginBottom: '1rem',
		fontSize: '1rem',
		fontWeight: '600',
		textAlign: 'left',
	},
	card: {
		padding: '0.75rem',
		gap: '.75rem',
	},
};

function AssetRow({ asset, converter }) {
	const { properties = {} } = asset;
	const assetType = ASSET_TYPES_REVERSE[asset?.asset_type] ?? asset?.asset_type ?? '';
	const { level = {} } = properties?.liquid ?? {};
	const { enable = false, la, abv, filled_date, action_date } = level;

	const liq = converter.unit(UNIT.LA);
	const alc = converter.unit(UNIT.ALC);

	const liqVal = converter.to(la ?? 0, UNIT.LA);
	const abvVal = converter.to(abv ?? 0, UNIT.ALC);

	return (
		<tr>
			<td>{asset?.rw_asset_id}</td>
			<td>{assetType?.toUpperCase()}</td>
			<td>
				{!enable || !la
					? 'EMPTY'
					: ` ${liqVal?.toFixed(3)}${liq} ${
						abv ? `@ ${abvVal?.toFixed(2)}${alc}` : ''
					}`}
			</td>
			<td>{(filled_date ?? action_date) && new Date(filled_date ?? action_date).toLocaleDateString()}</td>
		</tr>
	);
}

function SKURow({ sku }) {
	return (
		<tr>
			<td>{sku?.sku_name}</td>
			<td>{SKU_TYPE_REVERSE[sku?.sku_type]}</td>
			<td>{sku?.sku_description}</td>
			<td></td>
		</tr>
	);
}
function RequestSummaryHTML({ entity, converter, skus }) {
	const bl_unit = converter.unit(UNIT.BL);
	const la_unit = converter.unit(UNIT.LA);
	const abv_unit = converter.unit(UNIT.ALC);

	const {
		asset_count,
		original_bulk,
		original_la,
		sku_count,
		sku_bulk,
		sku_la,
		sku_groups,
		net_weight,
		gross_weight,
		tcf_bulk,
		weighted_abv,
		weighted_tcf,
		duty_paid_asset_bulk,
		duty_paid_asset_la,
		duty_paid_sku_bulk,
		duty_paid_sku_la,
	} = useLiquidSummaryCalculator(converter, skus, entity?.sources, entity?.sku_sources);

	const disp_bulk = converter.to(original_bulk ?? 0, UNIT.BL);
	const disp_la = converter.to(original_la ?? 0, UNIT.LA);

	const disp_sku_bulk = converter.to(sku_bulk ?? 0, UNIT.BL);
	const disp_sku_la = converter.to(sku_la ?? 0, UNIT.LA);

	const disp_tcf_bulk = converter.to(Number(tcf_bulk ?? 0) + Number(sku_bulk ?? 0), UNIT.BL);
	const disp_tcf_la = converter.to(Number(original_la ?? 0) + Number(sku_la ?? 0), UNIT.LA);

	const disp_duty_paid_asset_bulk = converter.to(duty_paid_asset_bulk ?? 0, UNIT.BL);
	const disp_duty_paid_asset_la = converter.to(duty_paid_asset_la ?? 0, UNIT.LA);

	const disp_duty_paid_sku_bulk = converter.to(duty_paid_sku_bulk ?? 0, UNIT.BL);
	const disp_duty_paid_sku_la = converter.to(duty_paid_sku_la ?? 0, UNIT.LA);

	const disp_weighted_abv = converter.to(weighted_abv ?? 0, UNIT.ALC);

	// TODO: need to fix the weights below to use a company default settings for external reporting
	return (
		<div style={styles.container}>
			<RequestQRCode request={entity} fullScreen={false} />
			<p style={styles.title}>Assets</p>
			<table>
				<tr>
					<th>ID</th>
					<th>Type</th>
					<th>Liquid</th>
					<th>Date</th>
				</tr>
				{entity?.sources?.map((asset) => (
					<AssetRow asset={asset} key={asset?.path} converter={converter} />
				))}
			</table>

			<p style={styles.title}>SKUs</p>
			<table>
				<tr>
					<th>ID</th>
					<th>Type</th>
					<th>Description</th>
				</tr>
				{entity?.sku_sources?.map((sku) => (
					<SKURow sku={sku} key={sku?.path} />
				))}
			</table>

			<p style={styles.title}>Summary</p>
			<table>
				<tbody>
				{asset_count > 0 && (
					<>
						<tr>
							<th>Assets</th>
							<td>{asset_count}</td>
						</tr>
						<tr>
							<th>Content</th>
							<td>
								{disp_bulk?.toFixed(3)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{bl_unit}</Bolder>
								{disp_la && (
									<>
										{' | '}
										<Bolder>{disp_la?.toFixed(3)}</Bolder>
										<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{la_unit}</Bolder>
									</>
								)}
							</td>
						</tr>
						<tr>
							<th>Tax On</th>
							<td>
								{disp_duty_paid_asset_bulk?.toFixed(3)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{bl_unit}</Bolder>
								{disp_duty_paid_asset_la && (
									<>
										{' | '}
										<Bolder>{disp_duty_paid_asset_la?.toFixed(3)}</Bolder>
										<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{la_unit}</Bolder>
									</>
								)}
							</td>
						</tr>
					</>
				)}
				{sku_count > 0 && (
					<>
						<tr>
							<th>SKUs</th>
							<td>{entity?.sku_sources?.length}</td>
						</tr>
						<tr>
							<th>Items</th>
							<td>{sku_count}</td>
						</tr>
						<tr>
							<th>Groups</th>
							<td>{sku_groups}</td>
						</tr>
						<tr>
							<th>Content</th>
							<td>
								{disp_sku_bulk?.toFixed(3)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{bl_unit}</Bolder>
								{disp_sku_la && (
									<>
										{' | '}
										<Bolder>{disp_sku_la?.toFixed(3)}</Bolder>
										<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{la_unit}</Bolder>
									</>
								)}
							</td>
						</tr>
						<tr>
							<th>Tax On</th>
							<td>
								{disp_duty_paid_sku_bulk?.toFixed(3)}
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{bl_unit}</Bolder>
								{disp_duty_paid_sku_la && (
									<>
										{' | '}
										<Bolder>{disp_duty_paid_sku_la?.toFixed(3)}</Bolder>
										<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{la_unit}</Bolder>
									</>
								)}
							</td>
						</tr>
					</>
				)}
				<tr>
					<th>Net Weight</th>
					<td>
						{converter.to(net_weight, UNIT.MAS)?.toFixed(3)}
						{converter.unit(UNIT.MAS)}
					</td>
				</tr>
				<tr>
					<th>Gross Weight</th>
					<td>
						{converter.to(gross_weight, UNIT.MAS)?.toFixed(3)}
						{converter.unit(UNIT.MAS)}
					</td>
				</tr>
				<tr>
					<th>Adjusted Content</th>
					<td>
						{disp_tcf_bulk?.toFixed(3)}
						<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{bl_unit}</Bolder>
						{disp_tcf_la && (
							<>
								{' | '}
								<Bolder>{disp_tcf_la?.toFixed(3)}</Bolder>
								<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{la_unit}</Bolder>
							</>
						)}
					</td>
				</tr>
				<tr>
					<th>Strength</th>
					<td>
						{disp_weighted_abv?.toFixed(2)}
						<Bolder style={{ paddingLeft: '0.25ch', opacity: '0.5' }}>{abv_unit}</Bolder>
						{weighted_tcf && (
							<>
								{' | '}
								<Bolder>TCF:</Bolder>
								&nbsp;
								{Number(weighted_tcf).toFixed(3)}
							</>
						)}
					</td>
				</tr>

				</tbody>
			</table>


		</div>
	);
}

export default RequestSummaryHTML;
