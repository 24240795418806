import * as yup from 'yup';

export default function materialsUsedReportFields(fields = {}) {
	const { a, b, c, d, e } = fields;

	return {
		a: [a, yup.number().min(0, 'Kind value cannot be less than 0')],
		b: [
			b,
			yup
				.number()
				.min(0, 'Used in Distilled Spirits Pounds value cannot be less than 0'),
		],
		c: [
			c,
			yup
				.number()
				.min(
					0,
					'Used in Distilled Spirits Gallons value cannot be less than 0',
				),
		],
		d: [
			d,
			yup
				.number()
				.min(
					0,
					'Other Than Distilled Spirits Pounds value cannot be less than 0',
				),
		],
		e: [
			e,
			yup
				.number()
				.min(
					0,
					'Other Than Distilled Spirits Gallons value cannot be less than 0',
				),
		],
	};
}
