import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { useCallback, useMemo } from 'react';
// import { withAppLayout } from '@pw/components/Layout/AppLayout';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationAssetModal from '@pw/components/SKUSelector/modals/DestinationAssetModal';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceAssetModal from '@pw/components/SKUSelector/modals/SourceAssetModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS, REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import fround from '@pw/utilities/fround';
import uniq from 'lodash.uniq';

export function convertSourceAsset(item) {
	const { properties = {} } = item ?? {};
	const { liquid = {} } = properties;
	const { level = {} } = liquid;
	const { bl = 0, enable: le = false } = level;

	// Only add non-empty assets
	if (bl && le) {
		return toTaggedAsset({
			...item,
			processed: ASSET_PROCESSED_STATUS.PENDING,
			properties: {
				...properties,
				liquid: {
					...liquid,
					level: {
						...level,
						expected_bl: bl,
					},
				},
			},
		});
	}
	return null;
}

function LookupSKU({ type, onBeforeSave }) {
	const skuList = useCompanySKUs();

	// const [FormSubmitter] = useFormSubmissionHook();

	const sourceProps = useMemo(
		() => ({
			title: 'Sources',
			filter: {
				asset_types: [
					ASSET_TYPES.pallet,
					ASSET_TYPES.cask,
					ASSET_TYPES.ibc,
					ASSET_TYPES.tanker,
					ASSET_TYPES.filling_tank,
				],
				request_types: [REQUEST_TYPES.pick],
				sku_types: [
					SKU_TYPES.TRACKED,
					SKU_TYPES.RAW,
					SKU_TYPES.EXPIRING,
					SKU_TYPES.CONSUMABLE,
				],
			},
			assetFilter: (a) =>
				[
					ASSET_TYPES.ibc,
					ASSET_TYPES.cask,
					ASSET_TYPES.tanker,
					ASSET_TYPES.filling_tank,
				].includes(a.asset_type),
			// submitForm,
			assetAdapter: convertSourceAsset,
			SKUModal: SourceSKUModal,
			AssetModal: SourceAssetModal,
		}),
		[],
	);

	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useInventorySelectorHook(sourceProps);

	const sourceWeightProperties = useMemo(() => {
		// Find the first asset with weight properties enabled and level properties disabled
		const assetWithWeight = sourceAssets?.find(
			(item) => item?.properties?.liquid?.weight?.enable,
		);
		console.log(
			'Weight properties',
			assetWithWeight?.properties?.liquid?.weight,
		);

		// Return weight properties if found, otherwise return null
		return assetWithWeight
			? { weightFactor: assetWithWeight?.properties?.liquid?.weight }
			: null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceAssets, sourceAssets.length]);

	const sourcesAbv = useMemo(() => {
		// If weight properties exist or there are no source assets, return 0
		if (sourceAssets.length === 0) return [0, 1];

		let totalBulk = 0;
		let totalAbv = 0; // Total baseline
		let totalTcf = 0; // TCF
		// Do a weighted average of the ABV and TCF
		sourceAssets
			.filter((asset) => asset?.properties?.liquid?.level?.bl)
			.forEach((asset) => {
				const bl = Number(asset?.properties?.liquid?.level?.bl);
				totalBulk += bl;
				totalAbv += Number(asset?.properties?.liquid?.level?.abv ?? 0) * bl;
				totalTcf += Number(asset?.properties?.liquid?.level?.tcf || 1.0) * bl;
			});

		console.log('ABV', totalAbv, totalTcf, totalBulk);

		if (totalBulk > 0) {
			return [fround(totalAbv / totalBulk, 2), fround(totalTcf / totalBulk, 3)];
		}
		return [0, 1];
	}, [sourceAssets]);

	const sourcesLiquidSku = useMemo(() => {
		// If there are no source assets, return an empty string
		if (sourceAssets.length === 0) return {};
		// Check if the liquid SKU is the same for all source assets
		const liquidSkus = sourceAssets
			.map((s) => s.properties?.liquid?.sku_id ?? '')
			.filter((y) => y !== '')
			.sort()
			.filter((x, i, a) => !i || x !== a[i - 1]);

		console.log('Liquid SKUs', liquidSkus);

		// Grab the batch, the date and the liquid from the propertiess..
		const props = sourceAssets.map((s) => [
			s.properties?.liquid?.batch,
			s.properties?.liquid?.date,
			s.properties?.liquid?.liquid,
		]);
		const uniqueProps = uniq(props.sort());
		console.log('Unique properties', uniqueProps);
		let [batch, date, liquid] = uniqueProps[0] ?? [];

		// If same, then use that..
		if (liquidSkus.length === 1) {
			const skuId = liquidSkus[0];
			// Find this sku
			const fs = skuList.find((s) => s.sku_id === skuId);
			if (fs) {
				return {
					batch,
					liquid,
					date,
					sku_id: skuId,
					sku: {
						sku_name: fs.sku_name,
						sku_description: fs.sku_description,
						sku_type: fs.sku_type,
					},
				};
			}
		}
		return {};
	}, [sourceAssets, skuList]);

	const convertDestinationAsset = useCallback(
		(item) => {
			// Extract the max capacity and set that as the expected..
			const { properties = {}, sku } = item ?? {};

			// Default capacity in liters
			let capacity = '200';
			if (sku) {
				const { capacity: skc = '0' } = sku ?? {};
				capacity = skc;
			}

			// Update the properties
			const { liquid = {} } = properties;
			const { level = {} } = liquid;
			const { bl = '0' } = level;

			console.log(
				'Adding dest',
				item.rw_asset_id,
				sourceWeightProperties,
				sourcesAbv,
				sourcesLiquidSku,
			);

			// Factor in existing bulk and capacity to compute what we can fill in this container
			return toTaggedAsset({
				...item,
				properties: {
					...properties,
					liquid: {
						...liquid,
						...sourcesLiquidSku,
						level: {
							...level,
							enableWeight: !!sourceWeightProperties,
							...(sourceWeightProperties ?? {}),
							expected_bl:
								Number(capacity) > 0
									? Math.min(Number(capacity) - Number(bl), Number(capacity))
									: null,
							updated_abv: sourcesAbv[0],
							updated_tcf: sourcesAbv[1],
						},
					},
				},
			});
		},
		[sourceWeightProperties, sourcesAbv, sourcesLiquidSku],
	);

	const destProps = useMemo(
		() => ({
			title: 'Destinations',
			filter: {
				asset_types: [
					ASSET_TYPES.pallet,
					ASSET_TYPES.cask,
					ASSET_TYPES.ibc,
					ASSET_TYPES.tanker,
					ASSET_TYPES.filling_tank,
				],
				request_types: [REQUEST_TYPES.pick, REQUEST_TYPES.change_ownership],
				sku_types: [
					SKU_TYPES.TRACKED,
					SKU_TYPES.FINISHED,
					SKU_TYPES.WASTE,
					SKU_TYPES.SERVICE,
				],
			},
			assetFilter: (a) =>
				[
					ASSET_TYPES.ibc,
					ASSET_TYPES.cask,
					ASSET_TYPES.tanker,
					ASSET_TYPES.filling_tank,
				].includes(a.asset_type),
			// submitForm,
			assetAdapter: convertDestinationAsset,
			SKUModal: DestinationSKUModal,
			AssetModal: DestinationAssetModal,
			parentPalletSupport: true,
		}),
		[convertDestinationAsset],
	);

	const [
		[destinationSkus],
		[destinationAssets],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook(destProps);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({
				...entity,
				assets: entity?.destinations,
				skus: entity?.sku_destinations,
			});
		},
		[initSources, initDestinations],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				sku_sources: sourceSkus,
				sources: sourceAssets,
				destinations: destinationAssets,
				sku_destinations: destinationSkus,
			};
		},
		[sourceSkus, sourceAssets, destinationSkus, destinationAssets],
	);

	return (
		<>
			{/* <RequestForm
				requestLabel={`Choose ${type}`}
				requestType={REQUEST_TYPES.transfer}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={onBeforeSave || handleBeforeSave}
			> */}
			<SourceInventory />

			{/* <FormSubmitter /> */}
			{/* </RequestForm> */}
		</>
	);
}

export default LookupSKU;
