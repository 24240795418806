const styles = {
	'.container': {
		backgroundColor: 'black',
		padding: '3px',

		'.label': {
			backgroundColor: 'black',
			display: 'grid',
			gridTemplateColumns: 'repeat(6, 1fr)',
			gridTemplateRows: 'repeat(30, 1.4rem)',
			width: '100%',
			pageBreakAfter: 'always',
			gap: '3px',

			'.nabcaCode': {
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: 'white',
				textAlign: 'center',
				alignItems: 'baseline',
				flexGrow: 1,

				'.prefix': {
					fontSize: '24px',
					fontWeight: '500',
					width: '30%',
					bottom: '0px',
				},

				'.code': {
					fontSize: '44px',
					fontWeight: '500',
					bottom: '0px',
				},

				'.suffix': {
					fontSize: '24px',
					fontWeight: '500',
					width: '30%',
					bottom: '0px',
				},
			},

			'.stateCode_b': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'black',
				// border: '1px solid yellow',
				padding: '3px',
				span: {
					color: 'white',
					fontSize: '22px',
					fontWeight: '600',
					lineHeight: '1',
					transform: 'scale(1.1, 2.5)',
				}
			},

			'.stateCode_w': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'white',
				// border: '1px solid yellow',
				padding: '3px',
				span: {
					color: 'black',
					fontSize: '22px',
					fontWeight: '600',
					lineHeight: '1',
					transform: 'scale(1.1, 2.5)',

					span: {
						fontSize: '11px',
					},
				}
			},

			'.upcBarcode': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'white',
				alignContent: 'center',
			},

			'.long': {
				backgroundColor: 'white',
				textAlign: 'start',
				color: 'black',
				padding: '2px',
				alignContent: 'center',
			},
		},
	},
};

export default styles;
