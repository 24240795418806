import part2ReportFields from './part2ReportFields';

export default function part2Fields(fields = {}) {
	const {
		On_Hand_First_of_Month = '',
		Bottled_or_Packaged = '',
		Received = '',
		Inventory_Overages = '',
		Transferred_in_Bond = '',
		Withdrawn_Tax_Determined = '',
		Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use = '',
		Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine = '',
		For_Exportation_Vessels_and_Aircraft_and_Transfer_to_CBW = '',
		Transferred_to_Production_Account_for_Redistillation = '',
		Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples = '',
		Destroyed = '',
		Dumped_for_Further_Processing = '',
		Specify_41 = '',
		Specify_42 = '',
		Specify_43 = '',
		Recorded_Losses = '',
		Inventory_Shortages = '',
		On_Hand_End_of_Month = '',
	} = fields;

	return {
		On_Hand_First_of_Month: part2ReportFields(On_Hand_First_of_Month),
		Bottled_or_Packaged: part2ReportFields(Bottled_or_Packaged),
		Received: part2ReportFields(Received),
		Inventory_Overages: part2ReportFields(Inventory_Overages),
		Transferred_in_Bond: part2ReportFields(Transferred_in_Bond),
		Withdrawn_Tax_Determined: part2ReportFields(Withdrawn_Tax_Determined),
		Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use:
			part2ReportFields(
				Withdrawn_Free_of_Tax_For_US_Hospital_Scientific_or_Educational_Use,
			),
		Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine: part2ReportFields(
			Withdrawn_Without_Payment_of_Tax_For_Addition_to_Wine,
		),
		For_Exportation_Vessels_and_Aircraft_and_Transfer_to_CBW: part2ReportFields(
			For_Exportation_Vessels_and_Aircraft_and_Transfer_to_CBW,
		),
		Transferred_to_Production_Account_for_Redistillation: part2ReportFields(
			Transferred_to_Production_Account_for_Redistillation,
		),
		Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples:
			part2ReportFields(
				Withdrawn_for_Research_Development_or_Testing_Including_Gov_samples,
			),
		Destroyed: part2ReportFields(Destroyed),
		Dumped_for_Further_Processing: part2ReportFields(
			Dumped_for_Further_Processing,
		),
		Specify_41: part2ReportFields(Specify_41),
		Specify_42: part2ReportFields(Specify_42),
		Specify_43: part2ReportFields(Specify_43),
		Recorded_Losses: part2ReportFields(Recorded_Losses),
		Inventory_Shortages: part2ReportFields(Inventory_Shortages),
		On_Hand_End_of_Month: part2ReportFields(On_Hand_End_of_Month),
	};
}
