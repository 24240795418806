import { useMemo } from 'react';
import { PERMISSIONS } from '@pw/consts/permissions';
import { REQUEST_STATUS } from '@pw/consts/requests';
import { useCompanyId, useIsAccountActive } from '@pw/redux/containers/User/hooks';
import { LOCAL_STATUS } from '@pw/worker/constants';
import useAccountHasPermission from '@pw/utilities/hooks/logic/useAccountHasPermission';

const useReadOnly = (entity) => {
	const userIsActive = useIsAccountActive();
	// const companyIsActive = useSelector(selectIsCompanyActive);
	const companyId = useCompanyId();
	const hasPermission = useAccountHasPermission([
		PERMISSIONS.MODIFY_REQUEST,
		PERMISSIONS.MODIFY_ASSET,
	]);

	const readonly = useMemo(() => {
		// Default is not readonly or asset was created offline it is editable
		if (
			!entity ||
			[LOCAL_STATUS.CREATED, LOCAL_STATUS.UPDATED].includes(entity.local_status)
		) {
			console.log('1 > Has permissions', userIsActive, hasPermission, companyId, entity?.company_id);
			return false;
		}
		// update is removed for cancelled and rejected requests
		if (
			entity?.request_status &&
			[
				REQUEST_STATUS.CANCELLED,
				REQUEST_STATUS.REJECTED,
				REQUEST_STATUS.DONE,
				REQUEST_STATUS.ARCHIVED,
			].includes(entity.request_status)
		) {
			console.log('2 > Has permissions', userIsActive, hasPermission, companyId, entity?.company_id);

			return true;
		}
		// Inactive users are not allowed to edit
		console.log('3 > Has permissions', userIsActive, hasPermission, companyId, entity?.company_id);

		if (!userIsActive) {
			return true;
		}
		// Company id must match for it to be editable
		return (
			!entity.company_id || companyId !== entity.company_id || !hasPermission
		);
	}, [companyId, entity, hasPermission, userIsActive]);

	return { readonly };
};

export default useReadOnly;
