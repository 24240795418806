import { createPolicy, getPolicyList } from '@pw/services/policy.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertPolicyThunk = createAsyncThunk(
  'policy/upsert',
  async (policy, { rejectWithValue }) => {
    try {
      const response = await createPolicy(policy);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const refetchPoliciesThunk = createAsyncThunk(
  'policy/refetch',
  async (params, { rejectWithValue }) => {
    try {
      console.log(`refetchPoliciesThunk called `, params);

      // @ts-ignore
      return await getPolicyList(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
