import { useEffect, useMemo, useState } from 'react';

function useAssetRevertHook(entity, filter) {
	const [historyExist, setHistoryExist] = useState(false);

	useEffect(() => {
		setHistoryExist(!!entity?.history);
	}, [entity]);

	const isSame = useMemo(
		() => filter(entity, entity?.history),
		[entity, filter],
	);

	return [historyExist, isSame, entity?.history];
}

export default useAssetRevertHook;
