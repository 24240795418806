import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';

function ConfirmationDialog({ open, options, onCancel, onConfirm, onClose }) {
	const {
		title,
		content,
		confirmationText = 'Confirm',
		cancellationText = 'Cancel',
		dialogProps = {},
		confirmationButtonProps = {},
		cancellationButtonProps = {},
		titleProps = {},
		contentProps = {},
		hideCancelButton = false,
		buttonOrder = ['cancel', 'confirm'],
	} = options;

	const dialogActions = buttonOrder.map((buttonType) => {
		if (buttonType === 'cancel') {
			return (
				!hideCancelButton && (
					<TextButton
						size='small'
						key='cancel'
						{...cancellationButtonProps}
						handleClick={onCancel}
						color='secondary'
					>
						{cancellationText}
					</TextButton>
				)
			);
		}

		if (buttonType === 'confirm') {
			return (
				<FilledButton
					key='confirm'
					size='small'
					{...confirmationButtonProps}
					handleClick={onConfirm}
					autoFocus
				>
					{confirmationText}
				</FilledButton>
			);
		}

		throw new Error(
			`Supported button types are only "confirm" and "cancel", got: ${buttonType}`,
		);
	});

	return (
		<Modal {...dialogProps} open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				{title && <H5 {...titleProps}>{title}</H5>}
				{content && <DialogContent {...contentProps}>{content}</DialogContent>}
				<Box className='action-buttons'>{dialogActions}</Box>
			</Stack>
		</Modal>
	);
}

export default ConfirmationDialog;
