import Address from '@pw/components/Address';
import { addressFields } from '@pw/components/Address/addressFields';
import { clientFields } from '@pw/components/Client/clientFields';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import PolicyEditor from '@pw/components/PolicyEditor';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import Transport from '@pw/components/Transport';
import { transportFields } from '@pw/components/Transport/transportFields';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import debounce from '@pw/utilities/debounce';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { destination = {}, transport = {}, duty_paid = false } = fields;

	return {
		destination: addressFields(destination),
		transport: transportFields(transport),
		duty_paid: [duty_paid, yup.boolean()],
	};
}

function Properties() {
	const { t } = useTranslation();

	return (
		<FormikProvider path='properties'>
			<Address name='destination' label='Destination' />
			<Transport name='transport' label='Transport' />
			<FormikCheckBox name='duty_paid' label={t('dutyPaid')} />
		</FormikProvider>
	);
}

function ShipForm(props) {

	const [
		[policies, initializePolicies],
		PolicyComponent,
		PolicyModals,
		loadPolicies
	] = usePolicySelectorHook({
		requestType: REQUEST_TYPES.ship,
		title: 'Policies',
		PolicyModal: PolicyEditor,
	});

	const onSKUAdded = useCallback((sku) => loadPolicies(sku?.policies), [loadPolicies]);

	const [
		[sourceSkus],
		[sourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Order',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.tanker,
				ASSET_TYPES.container,
			],
			request_types: [REQUEST_TYPES.pick],
			sku_types: [
				SKU_TYPES.WASTE,
				SKU_TYPES.FINISHED,
				SKU_TYPES.TRACKED,
			],
		},
		SKUModal: SourceSKUModal,
		extendedSummary: true,
		onSKUAdded,
	});

	const [
		[destinationSkus],
		[],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Services',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
		},
		skuModalProps: { calculateSkuLiquid: true },
		SKUModal: DestinationSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: propertyFields(initialValues?.properties),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
				skus: entity?.sku_sources,
			});
			initDestinations({ ...entity, skus: entity?.sku_destinations });
			initializePolicies(entity?.policies);
		},
		[initSources, initDestinations, initializePolicies],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sku_sources: sourceSkus,
			sources: sourceAssets,
			sku_destinations: destinationSkus,
			policies,
		}),
		[sourceSkus, sourceAssets, destinationSkus, policies],
	);

	return (
		<>
			<RequestForm
				requestLabel='Shipment'
				requestType={REQUEST_TYPES.ship}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
				{...props}
			>
				<SourceInventory />
				<DestinationInventory />

				<Properties />

				<PolicyComponent />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
			<PolicyModals />
		</>
	);
}

export default withAppLayout(ShipForm, { title: 'Shipments' });
