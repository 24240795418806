import * as yup from 'yup';

export function assetAccountsFields(fields = {}) {
	const { enable = false, bo = {}, mo = {}, ro = {} } = fields;

	return {
		enable: [enable, yup.bool()],
		bo: [
			bo,
			yup.object().shape({
				name: yup.string(),
				public_name: yup.string(),
				id: yup.string(),
				hash: yup.string(),
				type: yup.string(),
			}),
		],
		mo: [
			mo,
			yup.object().shape({
				name: yup.string(),
				public_name: yup.string(),
				id: yup.string(),
				hash: yup.string(),
				type: yup.string(),
			}),
		],
		ro: [
			ro,
			yup.object().shape({
				name: yup.string(),
				public_name: yup.string(),
				id: yup.string(),
				hash: yup.string(),
				type: yup.string(),
			}),
		],
	};
}
