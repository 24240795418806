export const PRODUCTION = 'production';

export const APP_API_GATEWAY = process.env.REACT_APP_APP_GATEWAY;
export const CONTENT_API_GATEWAY = process.env.REACT_APP_APP_CONTENT;
export const CONTENT_BUCKET = process.env.REACT_APP_APP_CONTENT_BUCKET;
export const MODE = process.env.REACT_APP_APP_MODE;
export const GA_ID = process.env.REACT_APP_GA_ID;
export const IS_PROD = MODE === PRODUCTION;
export const JWT_SECRET = process.env.REACT_APP_JWT_SECRET;

export const PUBLIC_APP_BASE_URL = IS_PROD
	? 'https://app.proofworks.com'
	: 'https://dev-app.proofworks.com';

// IPFS
export const IPFS_GATEWAY = process.env.REACT_APP_APP_PINATA_GATEWAY;
export const GROQ_API_KEY = process.env.REACT_APP_GROQ_API_KEY;
export const DEFAULT_CURRENCY = process.env.REACT_APP_DEFAULT_CURRENCY || 'GBP';
