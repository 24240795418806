import TimerContext from '@pw/context/TimerContext';
import { useEffect, useState } from 'react';

function TimerProvider({ interval = 1000, children }) {
  const [nowTime, setNowTime] = useState(new Date().getTime());

  useEffect(() => {
    const int = setInterval(() => setNowTime(new Date().getTime()), interval);
    return () => {
      clearInterval(int);
    };
  }, [interval]);

  return (
    <TimerContext.Provider value={nowTime}>
      {children}
    </TimerContext.Provider>
  );
}

export default TimerProvider;
