export class UnauthorizedError extends Error {
	constructor(message) {
		super(message);
		this.name = 'UnauthorizedError';
	}
}	

export function checkResponseStatus(status) {
	// console.log('Checking response status', status);
	if (status === 401) {
		// clean state
		throw new UnauthorizedError('Unauthorized');
	}
}
