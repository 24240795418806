import { useNotificationHook } from '@pw/redux/containers/App';
import debounce from '@pw/utilities/debounce';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

function NotificationWrapper({ children }) {
  const { enqueueSnackbar } = useSnackbar();

  const { messages, clear } = useNotificationHook();

  useEffect(() => {
    if (messages && messages.length > 0) {
      console.log('messages', messages);
      messages.forEach((message) => {
        enqueueSnackbar(message.content, { variant: message.severity ?? 'error' });
      });
      // Remove these notifications..
      debounce(() => clear(messages.map((m) => m.id)), 25);
    }
  }, [messages]);

  return (
    <>
      {children}
    </>
  );
}

export default NotificationWrapper;
