import { createAsyncThunk } from "@reduxjs/toolkit";

export const setLanguageThunk = createAsyncThunk(
  `language/setLanguageThunk`,
  async (params, { fulfillWithValue }) => {
    console.log('Setting language', params.language);
    params.i18n.changeLanguage(params.language);
    window.changeLanguage = params.i18n.changeLanguage;
    return fulfillWithValue(params.language);
  }
);