export const styles = {
	wrapper: {
		maxWidth: 'min-content',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
	},
	logo: {
		position: 'absolute',
		padding: '5px',
		// backgroundColor: '#fff',
	},
};
