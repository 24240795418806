import { useCompanyId } from '@pw/redux/containers/User/hooks';
import { useMemo } from 'react';

const useCheckCompany = (entity) => {
	const companyId = useCompanyId();

	return useMemo(() => !entity || (entity.company_id && companyId === entity.company_id), [companyId, entity]);
};

export const useIsSameCompany = () => {
	const companyId = useCompanyId();

	return (company_id) => !company_id || companyId === company_id;
};

export default useCheckCompany;
