import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikCheckBox,
	FormikForm,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidProperties, {
	liquidPropertyFields,
} from '@pw/components/Liquid/LiquidProperties';
import Switch from '@pw/components/SwitchComponent';
import { Body2, H5, H6 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import styles from '@pw/styles/modal.styles';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import match from '@pw/utilities/match';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import useAlertView from '@pw/utilities/hooks/components/useAlertView';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import toLocation from '@pw/utilities/adapters/toLocation';
import convertProperties from '@pw/utilities/convertProperties';

function DeliveryDestinationAssetModal({ item, items, open, onClose }) {
	const converter = useConverter();
	const [, { setAlert }] = useAlertView();

	const {
		properties = {},
		asset_type,
		unique_location_id,
		accounts = { enable: false },
	} = item ?? {};
	const {
		liquid = {},
		reference = '',
		imported = false,
		duty_paid = false,
	} = properties;

	const changeSet = useMemo(
		() => ({
			...match(asset_type, {
				[ASSET_TYPES.cask]: {
					cask: [properties?.cask, yup.array().of(yup.string())],
					imported: [imported, yup.boolean()],
					duty_paid: [duty_paid, yup.boolean()],
					reference: [reference, yup.string()],
				},
				[ASSET_TYPES.ibc]: {
					ibc: [properties?.ibc, yup.array().of(yup.string())],
					imported: [imported, yup.boolean()],
					duty_paid: [duty_paid, yup.boolean()],
					reference: [reference, yup.string()],
				},
				[ASSET_TYPES.pallet]: {
					pallet: [properties?.pallet, yup.array().of(yup.string())],
				},
				[ASSET_TYPES.tanker]: {
					tags: [properties?.tags, yup.array().of(yup.string())],
				},
				[ASSET_TYPES.container]: {
					tags: [properties?.tags, yup.array().of(yup.string())],
				},
				_: {},
			}),
			liquid: liquidPropertyFields(liquid, converter, true),
			location: locationFields(toLocation(unique_location_id)),
			accounts: assetAccountsFields(accounts),
		}),
		[
			asset_type,
			properties?.cask,
			properties?.ibc,
			properties?.pallet,
			properties?.tags,
			imported,
			duty_paid,
			reference,
			liquid,
			converter,
			unique_location_id,
			accounts,
		],
	);

	const handleSubmit = useCallback(
		(values) => {
			try {
				const { location } = values;
				const unique_location_id = toUniqueLocation(location || {});
				const empty_location = toUniqueLocation({});
				console.log('Location', unique_location_id, empty_location);
				if (unique_location_id !== empty_location) {
					// See if there is another asset with the same location id
					const existing = items?.find(
						(i) =>
							i.unique_location_id !== empty_location &&
							i.unique_location_id === unique_location_id &&
							i.asset_id !== item?.asset_id,
					);
					if (existing) {
						setAlert({
							severity: 'warning',
							title: 'Asset at location',
							content: `Asset ${existing.rw_asset_id} is already at this location!`,
						});
						return;
					}
				}

				const convertedProperties = convertProperties(
					{
						...properties,
						...match(asset_type, {
							[ASSET_TYPES.cask]: {
								cask: values?.cask,
								reference: values?.reference,
								imported: values?.imported,
								duty_paid: values?.duty_paid,
							},
							[ASSET_TYPES.ibc]: {
								ibc: values?.ibc,
								reference: values?.reference,
								imported: values?.imported,
								duty_paid: values?.duty_paid,
							},
							[ASSET_TYPES.pallet]: {
								pallet: values?.pallet,
								reference: values?.reference,
								imported: values?.imported,
								duty_paid: values?.duty_paid,
							},
							[ASSET_TYPES.tanker]: {
								tags: values?.tags,
							},
							[ASSET_TYPES.container]: {
								tags: values?.tags,
							},
							_: {},
						}),
						liquid: {
							...liquid,
							...values.liquid,
						},
					},
					converter,
				);

				const newAsset = {
					...item,
					properties: convertedProperties,
					location: values?.location,
					accounts: values?.accounts,
					unique_location_id: toUniqueLocation(values?.location),
					processed: ASSET_PROCESSED_STATUS.CONFIRMED,
				};
				console.log('Upserting asset', newAsset);
				onClose(newAsset);
			} catch (e) {
				console.log('Failed to update', e);
			}
		},
		[item, properties, asset_type, liquid, onClose, items, setAlert],
	);

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={'Properties'}>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={2} className='main-content'>
					<Location name='location' />
					<Switch value={asset_type}>
						<Switch.Case condition={ASSET_TYPES.cask}>
							<H5>Cask</H5>
							<FormikTextField name='reference' label='Reference' fullWidth />
							<TagSelect name='cask' type='cask' />
							<Stack className='section'>
								<H6 className='section-title'>Liquid Status</H6>
								<FlexBox justifyContent='space-between'>
									<FormikCheckBox
										name='imported'
										label={<Body2>Imported</Body2>}
									/>
									<FormikCheckBox
										name='duty_paid'
										label={<Body2>Duty Paid</Body2>}
									/>
								</FlexBox>
							</Stack>
							<LiquidProperties name='liquid' />
						</Switch.Case>
						<Switch.Case condition={ASSET_TYPES.ibc}>
							<H5>IBC</H5>
							<FormikTextField name='reference' label='Reference' fullWidth />
							<TagSelect name='ibc' type='ibc' />
							<Stack className='section'>
								<H6 className='section-title'>Liquid Status</H6>
								<FlexBox justifyContent='space-between'>
									<FormikCheckBox
										name='imported'
										label={<Body2>Imported</Body2>}
									/>
									<FormikCheckBox
										name='duty_paid'
										label={<Body2>Duty Paid</Body2>}
									/>
								</FlexBox>
							</Stack>
							<LiquidProperties name='liquid' />
						</Switch.Case>
						<Switch.Case condition={ASSET_TYPES.pallet}>
							<H5>Pallet</H5>
							<TagSelect name='pallet' type='pallet' />
						</Switch.Case>
						<Switch.Case condition={ASSET_TYPES.tanker}>
							<H5>Tanker</H5>
							<TagSelect name='tags' type='tags' />
							<LiquidProperties name='liquid' useBulk={false} />
						</Switch.Case>
						<Switch.Case condition={ASSET_TYPES.container}>
							<H5>Container</H5>
							<TagSelect name='tags' type='tags' />
						</Switch.Case>
					</Switch>
					<AssetAccounts />
					<Errors />
				</Stack>
				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton size='small' type='submit'>
						Save
					</FilledButton>
				</Box>
			</FormikForm>
		</ModalWithClose>
	);
}

export default DeliveryDestinationAssetModal;
