import { AddCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import CodeList from '@pw/components/admin/SCCodes/CodeList';
import CodeModal from '@pw/components/admin/SCCodes/CodeModal';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';

function SCCodes(props) {
	const { title = 'SC Codes', field = 'sc_codes' } = props;
	const { setFieldValue, values } = useContext(FormikContext);

	const confirm = useConfirm();

	const [codes, , , upsertCodes, removeCodes] = useItemListManager(
		ID.state,
		COMP.state,
		values[field] ?? [],
	);

	// Currently selected code
	const [selectedCode, setSelectedCode] = useState(null);

	const handleRemoveCode = (code) => (removeFunc) => {
		confirm({
			title: 'Remove State',
			content: <Body1>{`Remove ${code?.state}?`}</Body1>,
		})
			.then(() => removeFunc(code))
			.catch(() => {});
	};

	useEffect(() => {
		console.log('Updating codes', codes);
		debounce(() => setFieldValue(field, codes), 25);
	}, [codes]);

	return (
		<>
			<CodeComponent
				title={title}
				setSelectedCode={setSelectedCode}
				handleRemoveCode={handleRemoveCode}
				removeCodes={removeCodes}
				codes={codes}
			/>
			<ModalComponents
				upsertCodes={upsertCodes}
				setSelectedCode={setSelectedCode}
				selectedCode={selectedCode}
				title={title}
			/>
		</>
	);
}

function CodeComponent({
	title,
	setSelectedCode,
	handleRemoveCode,
	removeCodes,
	codes,
}) {
	return (
		<Stack spacing={1} className='section'>
			{title && <H5 className='section-header'>{title}</H5>}

			<Stack className='inventory'>
				<Box className='inventory-header'>
					<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
					<Stack direction='row' spacing={1}>
						<TitledButton
							handleClick={() => setSelectedCode({})}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>
					</Stack>
				</Box>
				{codes.length > 0 && (
					<CodeList
						items={codes}
						onEdit={(i) => setSelectedCode(i)}
						onRemove={(i) => handleRemoveCode(i)(removeCodes)}
					/>
				)}
				{codes.length === 0 && (
					<Box p={2} className='inventory-footer'>
						<Body3>None</Body3>
					</Box>
				)}
			</Stack>
		</Stack>
	);
}

function ModalComponents({
	upsertCodes,
	setSelectedCode,
	selectedCode,
	title,
}) {
	const handleCodeUpdate = useCallback(
		(code) => {
			if (code) {
				upsertCodes(code);
			}
			setSelectedCode(null);
		},
		[upsertCodes, setSelectedCode],
	);

	return (
		<>
			{!!selectedCode && (
				<CodeModal
					title={title}
					open={!!selectedCode}
					item={selectedCode}
					onClose={handleCodeUpdate}
				/>
			)}
		</>
	);
}

export default SCCodes;
