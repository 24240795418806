import { useCallback } from 'react';

import { useAccount } from '@pw/redux/containers/User/hooks';

import { Box, Button, Select, MenuItem } from "@mui/material";
import { East, West } from "@mui/icons-material";

import { getNextName } from '@pw/providers/DesignerProvider';
import { saveDesignBatch } from "@pw/services/production.service";


import { viewModes } from './consts';
import FilledButton from "@pw/components/Buttons/FilledButton";
import { parseRecipe } from './functions';

const PrevNextButtons = ({ updatePanOffset, viewing }) => (
  <Box className="flex paging-buttons">
    <Button className="pan prev" onClick={() => { updatePanOffset(false) }}>
      <West />
    </Button>
    <Box className="viewing">{viewing}</Box>
    <Button className="pan next" onClick={() => { updatePanOffset(true) }}>
      <East />
    </Button>
  </Box>
);

const TimeframeButtons = ({ viewMode, setViewMode }) => {
  return (
    <Box className="flex timeframe-buttons">
      {viewModes.map(({ label, key }) => (
        <Button
          key={key}
          className={`viewmode ${viewMode === key ? 'active' : ''} `}
          disabled={key !== 'day'}
          onClick={() => setViewMode(key)}>{label}</Button >
      ))}
    </Box>
  );
}

const CreateBatch = ({ designs, design, setDesign, batches, setBatches, }) => {

  const user = useAccount();


  const createNewBatch = useCallback(async () => {


    // setIsLoading({ messag: 'creating and saving the new batch' });
    const now = new Date();
    const ts = now.getTime();
    const isoDate = now.toISOString().split('T')[0];
    let _name = `Batch ${isoDate}`;

    const names = batches.map(({ recipe_name }) => recipe_name).filter((name) =>
      name === _name
      || name?.startsWith(_name + ' (')
    );
    _name = getNextName(names, _name);

    const newBatch = {
      batch_id: btoa(`${ts}`),
      name: _name,
      recipe_id: design.sku_id,
      created_at: ts,
      updated_at: ts,
      created_by: user.account_name,
      updated_by: user.account_name,
    };

    // console.log({ batches, newBatch });

    await saveDesignBatch(newBatch);
    setBatches([...batches, { ...newBatch, ...parseRecipe(design) }]);
  }, [design, batches, setBatches, user?.account_name]);

  return (
    <Box className="create-batch-root flex">
      <Select
        className="create-batch-select"
        value={design?.sku_id}
        onChange={({ target: { value: v } }) => {
          const newDesign = designs.find(({ sku_id }) => sku_id === v);
          setDesign(newDesign);
        }}>
        {designs.map(({ recipe_name }) => (
          <MenuItem key={recipe_name} value={recipe_name}>{recipe_name}
          </MenuItem>
        ))}
      </Select>
      <FilledButton
        handleClick={() => createNewBatch()}
        disabled={!design?.sku_id}
      >
        + Schedule new Batch
      </FilledButton>
    </Box>
  )
}

const TopBar = ({
  viewMode, setViewMode, updatePanOffset, viewing, designs, design, setDesign, batches, setBatches,
}) => (
  <Box className={`top-bar-root viewmode-${viewMode}`}>
    <PrevNextButtons updatePanOffset={updatePanOffset} viewing={viewing} />
    <TimeframeButtons viewMode={viewMode} setViewMode={setViewMode} />
    <CreateBatch
      designs={designs}
      design={design}
      setDesign={setDesign}
      batches={batches}
      setBatches={setBatches}
    />
  </Box>
);

export default TopBar;
