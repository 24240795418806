import { checkResponseStatus } from './checkResponseStatus';
import requestHeaders from './requestHeaders';

const fetchGet = async (url, headers = {}) => {
	// console.log('Getting url', url, h);
	const response = await fetch(url, {
		method: 'GET',
		headers: requestHeaders(headers),
	});
	checkResponseStatus(response.status);
	const jsonResponse = await response.json();
	if (!response.ok) {
		throw jsonResponse;
	}
	return jsonResponse;
};

export default fetchGet;
