import withDialogCard from '@pw/components/Cards/DialogCard';
import Users from '@pw/components/Company/admin/Users';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function UsersPage() {
	usePageTitleHook('Manage Users');
	return <Users />;
}

export default withAppLayout(withDialogCard(UsersPage));
