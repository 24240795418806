function SvgArt(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 293.55 334.73'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M293.5 167.42c0-25.44-.06-50.88.05-76.32.03-5.71-2.21-9.73-7.11-12.56-44.07-25.41-88.14-50.83-132.16-76.32-5.17-2.99-9.91-2.94-15.09.07C95.39 27.68 51.55 53.01 7.66 78.24 2.33 81.31-.01 85.43 0 91.59c.11 50.5.1 101.01.01 151.51-.01 6.27 2.45 10.5 7.91 13.64 43.68 25.1 87.32 50.27 130.89 75.57 5.61 3.26 10.49 3.18 16.05-.05 43.57-25.29 87.21-50.47 130.88-75.57 5.46-3.14 7.85-7.46 7.81-13.71-.12-25.19-.05-50.38-.05-75.57zm-80.14 103.37c-21.22 12.25-42.45 24.49-63.65 36.76-1.66.96-2.97 1.71-5.05.5-37.65-21.84-75.36-43.58-113.02-65.31-.05-1.12.8-1.58 1.37-2.17 17.91-18.76 35.87-37.46 53.7-56.3 2.02-2.13 2.9-1.96 4.81.06 18.88 20.07 37.87 40.03 56.81 60.05 2.1 2.22 4.34 4.09 7.46 4.64 5.32.94 10.21-1.26 12.74-5.74 2.69-4.76 2.05-10.17-1.96-14.45-9.56-10.18-19.14-20.34-28.86-30.37-2.08-2.14-2.52-3.31-.35-5.91 14.3-17.12 28.36-34.44 42.84-52.1 27.79 33.89 55.42 67.59 83.19 101.46-16.84 9.73-33.42 19.31-50.01 28.88zm56.19-63.43v4.25c-4.75-5.76-8.9-10.77-13.02-15.8-21.89-26.69-43.77-53.38-65.65-80.08-6.49-7.92-14.87-7.95-21.36-.03-16.36 19.95-32.74 39.87-49.02 59.88-1.69 2.08-2.52 2.17-4.35.15-5.6-6.19-11.41-12.21-17.2-18.22-6.08-6.31-13.59-6.34-19.63-.04-17.43 18.18-34.81 36.4-52.21 54.61-.83.86-1.68 1.7-3.1 3.14v-3.92c0-37.15.02-74.31-.06-111.46 0-2.4.63-3.75 2.76-4.98 39.03-22.43 78.02-44.93 116.98-67.49 2.15-1.25 3.71-1.48 6.04-.13 38.82 22.54 77.71 44.99 116.63 67.36 2.32 1.33 3.26 2.67 3.25 5.44-.11 35.78-.07 71.56-.07 107.34z' />
			<path d='M103.69 73.59c-19.84.09-35.84 16.19-35.75 35.98.09 19.82 16.28 35.93 35.97 35.8 19.8-.13 35.91-16.34 35.8-36.02-.12-19.82-16.26-35.85-36.02-35.76zm-.11 47.83c-6.54-.14-11.76-5.54-11.69-12.09.06-6.57 5.38-11.82 11.95-11.8 6.53.02 11.9 5.35 11.93 11.87.04 6.62-5.58 12.16-12.19 12.02z' />
		</svg>
	);
}
export default SvgArt;
