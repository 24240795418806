import { ABV_UNITS, LIQUID_UNITS, MASS_UNITS, TEMPERATURE_UNITS, TIME_UNITS, UNITS, UNIT_MAP, UNIT_RANGE, UNITS_REVERSE } from "@pw/consts/units";
import { usePreferredUnits } from "@pw/redux/containers/User/hooks";
import convert from "convert";

const useConverter = () => {
  const preferredUnits = usePreferredUnits();
  
  // This is how the value is stored in the database, so for display we must convert from this unit to the linked unit
  const baseUnit = (unit) => {
    // Check if the unit is a liquid measure
    if (Object.values(LIQUID_UNITS).includes(unit)) {
      return UNITS.liters; // Base unit for liquids
    }

    // Check if the unit is a mass measure
    if (Object.values(MASS_UNITS).includes(unit)) {
      return UNITS.kilograms; // Base unit for mass
    }

    // Check if the unit is a abv measure
    if (Object.values(ABV_UNITS).includes(unit)) {
      return UNITS.abv; // Base unit for abv
    }

    if (Object.values(TIME_UNITS).includes(unit)) {
      return UNITS.days; // Base unit for time
    }

    if (Object.values(TEMPERATURE_UNITS).includes(unit)) {
      return UNITS.celsius; // Base unit for temperature
    }

    return unit;
  };

  const unit = (item) => {
    if (item in preferredUnits) {
      return preferredUnits[item];
    }
    if (item in UNITS) {
      return item;
    }
    if (item in UNITS_REVERSE) {
      return UNITS_REVERSE[item];
    }
    throw new Error(`Unknown unit: ${item}`);
  };

  const mapped = (unit) => {
    return UNIT_MAP[unit] || unit;
  };

  const range = (category) => {
    return UNIT_RANGE[preferredUnits[category]] || [];
  };

  // Convert to the base unit for storage in the database
  const to_base = (value, from_category) => {
    if (!value || Number.isNaN(value)) return null;

    const from_unit = preferredUnits[from_category];
    const to_unit = baseUnit(from_unit);

    if (from_unit === to_unit) {
      return Number(value);
    }

    // Convert doesn't support proof, so we need to convert to abv manually
    if (from_unit === UNITS.proof) {
      return Number(Number(value) / 2);
    }
    // Also support conversion from proof gallon
    if (from_unit === UNITS.proof_gallons) {
      const pl = Number(convert(Number(value), mapped(UNITS.gallons)).to(mapped(to_unit)));
      return pl / 2.0;
    }

    return Number(convert(value, mapped(from_unit)).to(mapped(to_unit)));
  };

  // Convert from the base unit to the preferred unit for display
  const from_base = (value, to_category) => {
    if (!value || Number.isNaN(value)) return null;

    const to_unit = preferredUnits[to_category];
    const from_unit = baseUnit(to_unit);

    if (to_unit === from_unit) {
      return Number(value);
    }

    // Convert doesn't support proof, so we need to convert from abv to proof manually
    if (to_unit === UNITS.proof) {
      return Number(Number(value) * 2);
    }
    // Also support conversion from proof gallon
    if (to_unit === UNITS.proof_gallons) {
      const gallons = Number(convert(Number(value), mapped(from_unit)).to(mapped(UNITS.gallons)));
      return gallons * 2.0;
    }

    return Number(convert(value, mapped(from_unit)).to(mapped(to_unit)));
  };

  const cx = (value, from_unit = null, to_unit = null) => {
    if (!value || Number.isNaN(value)) return null;

    if (from_unit === to_unit) {
      return Number(value);
    }

    if (from_unit === null || !from_unit) {
      from_unit = baseUnit(to_unit);
    }
    if (to_unit === null || !to_unit) {
      to_unit = baseUnit(from_unit);
    }

    if (from_unit === UNITS.proof) {
      if (to_unit === UNITS.abv) {
        return Number(value) / 2;
      }
      throw new Error('Cannot convert proof to non-abv units!');
    }
    if (from_unit === UNITS.abv) {
      if (to_unit === UNITS.proof) {
        return Number(value) * 2;
      }
      throw new Error('Cannot convert abv to non-proof units!');
    }

    if (from_unit === UNITS.proof_gallons) {
      const pl = Number(convert(Number(value), mapped(UNITS.gallons)).to(mapped(to_unit)));
      return pl / 2.0;
    }
    if (to_unit === UNITS.proof_gallons) {
      const gallons = Number(convert(Number(value), mapped(from_unit)).to(mapped(UNITS.gallons)));
      return gallons * 2.0;
    }

    return Number(convert(value, mapped(from_unit)).to(mapped(to_unit)));
  };

  return { to: to_base, from: from_base, range, unit, cx  };
};

export default useConverter;
