import { Divider } from '@mui/material';
import GeneralInformation from './GeneralInformation';
import Part1 from './Part1';
import Part2 from './Part2';
import Part3 from './Part3';
import { useContext } from 'react';
import FormikContext from '@pw/context/FormikContext';

export default function TTBReport_75() {
	const { values } = useContext(FormikContext);
	const typeOfPlant = values?.Part1?.typeOfPlant;
	return (
		<>
			<Divider />
			<GeneralInformation
				name='general_information'
				title='General Information'
			/>
			<Divider />
			<Part1 name='Part1' title='Part I' />
			{typeOfPlant && typeOfPlant != 3 && (
				<>
					<Divider />
					<Part2
						name='Part2'
						title='Part II - For Small and Medium Plants Only'
					/>
				</>
			)}
			{typeOfPlant && typeOfPlant == 3 && (
				<>
					<Divider />
					<Part3 name='Part3' title='Part III - For Large Plants Only' />
				</>
			)}
			<Divider />
		</>
	);
}
