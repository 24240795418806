import { useMemo, memo } from 'react';
import { useTimeLineContext } from "./TimeLineContext";
import { Box } from '@mui/material';

import {
  getShortDayNameFromIndex, filledArray, getDaysByMonth, getStartOfDayTime, getStartOfDayEpoch, getSecondsInTimeframe, tf,
} from './functions';
import { monthNames } from './consts';

import DividerGroup from './DividerGroup';
import Divider from './Divider';
import { H4 } from '@pw/components/Typography';

const numHrsInDay = tf.day[0];
const numDaysInWeek = tf.week[0];

const Month = memo(({ index = 0, offset = 0 }) => {
  const { viewMode: rootViewMode, date, timeframes } = useTimeLineContext();

  const viewMode = rootViewMode;
  const d = useMemo(() => new Date(`${date.getFullYear()}`)
    , []); // don't mutate the root month!!

  const startOfYearTime = useMemo(() => d.getTime(), [d]);

  const dateOffsetMonth = useMemo(() => new Date(
    d.setMonth(date.getMonth() + (index - 6))
    // d.setMonth(index)
  ), []);

  const monthNum = dateOffsetMonth.getMonth();
  const monthName = monthNames[monthNum];
  const daysByMonth = getDaysByMonth(dateOffsetMonth.getFullYear());
  const numDaysInMonth = daysByMonth[monthNum];
  const daysInMonth = filledArray(numDaysInMonth);
  const dateStartOfYear = new Date(`${dateOffsetMonth.getFullYear()}`);
  const startOfMonth = new Date(dateStartOfYear.setMonth(monthNum));
  const dayNameOffset = startOfMonth.getDay();

  // calculate offset so we are aligned to the start of the current viewMode
  // (ie start of month, week, day)
  const offsetInSeconds = useMemo(() => {
    const startOfMonthTime = dateOffsetMonth.getTime();
    const nowTime = date.getTime();
    const startOfDay = getStartOfDayTime(date) / 1000;
    const secsInDay = getSecondsInTimeframe(tf.day);
    let diff = (startOfYearTime - startOfMonthTime) / (secsInDay * 1000)

    // if week, align to  date of prev monday
    if (viewMode === 'week') {
      const nowDate = date.getDate();
      // monday is day 1
      if (nowDate - (dayNameOffset - 1) >= 0) {

        const prevMonday = new Date(startOfMonth);
        prevMonday.setDate(startOfMonth.getDate() + (dayNameOffset - 1));
        // );
        diff = (startOfMonthTime - (nowTime + ((dayNameOffset - 1) * secsInDay * 1000))) / 1_000;
        // console.log({ monthName, nowDate, dateOffsetMonth, dayNameOffset, prevMonday });
      }
      // if day align to start of day
    } else if (viewMode === 'day') {
      // diff = (startOfMonthTime - new Date(nowTime).setTime(
      //   Math.round(nowTime / (secsInDay * 1000))
      //   * secsInDay * 1000
      // )) / 1_000;
      // // }
      // return - 1 * (nowTime - startOfMonthTime) / 1_000
      // diff = startOfMonthTime / 1000;

      // const startOfDay = Math.floor(nowTime / (secsInDay * 1000))
      // diff = (startOfMonthTime - (startOfDay * secsInDay * 1000)) / 1_000; // ms
      diff = (startOfMonthTime - (startOfDay * secsInDay * 1000)) / 1_000; // ms 
    }

    // console.log(`offsetInSeconds ${monthName} diff`, diff);
    return diff;
  }, [startOfYearTime, dateOffsetMonth, dayNameOffset, monthName, viewMode, date]);

  const [_count, seconds] = timeframes[viewMode];
  const count = useMemo(() => () => {
    switch (viewMode) {
      case 'month':
        return numDaysInMonth;
      case 'week':
        return numDaysInWeek;
      case 'day':
        return numHrsInDay;
    }
  }, [viewMode, numDaysInMonth, numDaysInWeek, numHrsInDay]);

  const unitCount = count();

  const parentWidth = unitCount * seconds;

  const MonthViewContent = useMemo(() => viewMode === 'month' && daysInMonth.map((_, i) => {
    const dayName = getShortDayNameFromIndex(i, dayNameOffset);
    return (
      <Divider index={i} key={i} width={seconds} offset={i * seconds}
        className={`divider--view-month divider--day day-num--${i} day-name--${dayName}`}>
        <Box className="day-letter">{dayName[0]}</Box>
        <Box className="day-name">{dayName}</Box>
        <Box>{i < 9 ? '0' : ''}{i + 1}</Box>
      </Divider>)
  }
  ), [viewMode, daysInMonth]);


  const WeekViewContent = useMemo(() =>
    viewMode === 'week' && daysInMonth.map((_, i) => (
      <Divider index={i} key={i} width={seconds} offset={i * seconds}
        className="divider--view-week"
      >
        <H4>{getShortDayNameFromIndex(i, dayNameOffset)}</H4>
        <Box>{i < 9 ? '0' : ''}{i + 1}</Box>
      </Divider >)
    ), [viewMode, daysInMonth, dayNameOffset]);

  const DayViewContent = useMemo(() => viewMode === 'day' && daysInMonth.map((_, i) => (
    <Divider index={i} key={i} width={parentWidth} offset={i * parentWidth}
      className="divider--view-day"
    >
      <H4 >{getShortDayNameFromIndex(i, dayNameOffset)}</H4>
      <Box>{i < 9 ? '0' : ''}{i + 1}</Box>
    </Divider>
  )
  ), [viewMode, daysInMonth, dayNameOffset]);

  const sx = useMemo(() => ({
    '--width': parentWidth,
    '--offset': offsetInSeconds,
  })
    , [parentWidth, offsetInSeconds]);

  // console.log('Month render',
  //   { index, monthNum, monthName, startOfMonth, dayNameOffset, offsetInSeconds, daysInMonth: daysInMonth.length, seconds, parentWidth });

  return (
    <DividerGroup
      rootViewMode={rootViewMode}
      viewMode={viewMode}
      index={index}
      childrenCount={daysInMonth}
      offset={offsetInSeconds}
      width={parentWidth}
    >

      <Box as="h2" className="title">{monthName}</Box>
      <Box className="divider-items-container"
        sx={sx}
      >
        {viewMode === 'month' && MonthViewContent}
        {viewMode === 'week' && WeekViewContent}
        {viewMode === 'day' && DayViewContent}

        {/* {viewMode === 'day' && (
          <Box className="now"
            sx={{ '--offset': positionOffset }}>
            x
          </Box>
        )} */}

      </Box>
    </DividerGroup>
  )
});

export default Month;
