const requestHeaders = (headers = {}) => {
    const auth = window.localStorage.getItem('auth-token');
    const companyId = window.localStorage.getItem('company-id');
    const accountId = window.localStorage.getItem('account-id');
    return {
        'Content-Type': 'application/json',
        'Authorization': auth,
        'SW-Scope': JSON.stringify({
            'Company': companyId,
            'AccountId': accountId, 
        }),
        ...headers,
    };
}; 

export default requestHeaders;
