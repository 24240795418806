import { AddBox } from '@mui/icons-material';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import { Body1, Body3, H5, H6 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { setActiveListItem } from '@pw/redux/containers/App';
import { useCompanyPolicies } from '@pw/redux/containers/User';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PolicyListComponent from '../admin/Policy/PolicyListItem';

function PolicySelector({
  title,
  sectionTitle,
  list,
  onAdd,
  onEdit,
  onRemove,
  single = false,
}) {
  const { readonly } = useContext(FormikContext);

  return (
    <Stack className="section">
      {title && <H5 className="section-header">{title}</H5>}
      {sectionTitle && <H6 className="section-title">{sectionTitle}</H6>}

      <Collapse in={!(single && list.length === 1)} unmountOnExit>
        <GeneralSearch
          label="Name"
          disabled={readonly}
          types={{ policies: true }}
          onItemClick={onAdd}
          multiple={!single}
          count={list.length}
          buttonIcon={<AddBox />}
          scan={false}
        />
      </Collapse>
      {list?.length > 0 && (
        <Box className="inventory">
          <Stack spacing={0} className="list">
            {list.map((policy) => (
              <PolicyListComponent
                item={policy}
                onEdit={onEdit}
                onRemove={onRemove}
                key={policy.policy_id}
              />
            ))}
          </Stack>
        </Box>
      )}
      {list?.length === 0 && <Body3>None</Body3>}
    </Stack>
  );
}

// export default PolicySelector;

function usePolicySelectorHook(props) {
  const {
    requestType,
    title,
    initialPolicies = [],
    PolicyModal,
    policyFilter = (x) => x,
  } = props;

  const companyPolicies = useCompanyPolicies();
  const defaultPolicies = useMemo(() => {
    if (requestType) {
      return companyPolicies.filter((policy) => policy.requests?.includes(requestType));
    }
    return [];
  }, [requestType, companyPolicies]);

  console.log('Policy Selector', props, defaultPolicies);

  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [currentPolicy, setCurrentPolicy] = useState(null);

  const [policies, initPolicies, , upsertPolicies, removePolicies] = useItemListManager(
    ID.policy,
    COMP.policy,
    initialPolicies ?? [],
  );

  // TODO: This is used to confirm all policy entries of all policies
  const setSelectedPolicy = useCallback(
    (item) => {
      setCurrentPolicy(item);
      if (item) {
        dispatch(setActiveListItem(item));
      }
    },
    [dispatch, setCurrentPolicy],
  );

  /**
   * Initialize the policy set - including the defaults
   * @type {(function([]=): void)|*}
   */
  const initializePolicies = useCallback((policySet = []) => {
    debounce(() => initPolicies([...defaultPolicies, ...policySet]), 25);
  }, [initPolicies, defaultPolicies]);

  /**
   * Load policies based on passed in configuration
   * @type {(function([]=): void)|*}
   */
  const loadPolicies = useCallback((policies = []) => {
    if (policies.length > 0) {
      console.log('Policies', policies);
      const mappedPolicies = policies
      .map((p) => {
        return companyPolicies.find((k) => k.policy_id === p.policy_id);
      })
      .filter((p) => p);

      console.log(' -->', mappedPolicies);
      debounce(() => {
        upsertPolicies(mappedPolicies);
      }, 25);
    }
  }, [upsertPolicies, companyPolicies]);

  function PolicyComponent() {
    const upsertPolicy = useCallback(
      (policy) => {
        if (policyFilter && policyFilter(policy)) {
          setSelectedPolicy(policy);
        }
      },
      [policyFilter, setSelectedPolicy],
    );

    const removePolicy = useCallback(
      (policy) => {
        if (policy) {
          confirm({
            title: 'Remove Policy',
            content: <Body1>{`Remove ${policy?.policy_name}?`}</Body1>,
          })
          .then(() => removePolicies(policy))
          .catch(() => {});

        }
      },
      [confirm, removePolicies],
    );

    return (
      <>
        <PolicySelector
          title={title}
          list={policies}
          onEdit={upsertPolicy}
          onAdd={upsertPolicy}
          onRemove={removePolicy}
        />
      </>
    );
  }

  function ModalComponents() {
    const handlePolicyUpdate = useCallback(
      (policy) => {
        console.log(
          '>>handlePolicyUpdate',
          policy?.policy_id,
          policy?.policy_name,
          policy,
        );

        if (policy) {
          upsertPolicies(policy);
        }
        setCurrentPolicy(null);
      },
      [upsertPolicies, setCurrentPolicy],
    );

    return (
      <>
        {PolicyModal && !!currentPolicy && (
          <PolicyModal
            item={currentPolicy}
            open={!!currentPolicy}
            onClose={handlePolicyUpdate}
            upsertPolicies={upsertPolicies}
          />
        )}
      </>
    );
  }

  return [
    [policies, initializePolicies, upsertPolicies, removePolicies],
    PolicyComponent,
    ModalComponents,
    loadPolicies
  ];
}

export default usePolicySelectorHook;

