import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { MappingInput } from '@pw/components/BatchUpload/components/MappingInput';
import PaginatedDraggableList from '@pw/components/BatchUpload/components/PaginatedDraggableList';
import PaginatedDroppableList from '@pw/components/BatchUpload/components/PaginatedDroppableList';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function FieldMapping({ rawData, model, assetType, onBack, onCancel, onConfirm }) {
	const [mappings, setMappings] = useState(
		Object.entries(model).reduce((acc, [key]) => ({ ...acc, [key]: [] }), {}),
	);

	const updateMappingField = useCallback(
		({ key, value }) => {
			// find and delete old mapped relation
			const oldKey = Object.keys(mappings).find((k) => mappings[k].includes(value));

			const copy = { ...mappings };
			if (oldKey) {
				copy[oldKey] = mappings[oldKey].filter((oldItem) => oldItem !== value);
			}

			if (Object.keys(mappings).includes(key)) {
				copy[key].push(value);
			}
			setMappings(copy);
		},
		[mappings],
	);

	const removeMappingByValue = useCallback(
		(value) => {
			const key = Object.keys(mappings).find((k) => mappings[k].includes(value));
			if (Object.keys(mappings).includes(key)) {
				const copy = { ...mappings };
				copy[key] = mappings[key].filter((oldItem) => oldItem !== value);
				setMappings(copy);
			}
		},
		[mappings],
	);

	const removeMappingByKey = useCallback(
		(key) => {
			if (Object.keys(mappings).includes(key)) {
				const copy = { ...mappings, [key]: [] };
				setMappings(copy);
			}
		},
		[mappings],
	);

	const handleConfirm = useCallback(() => {
		onConfirm(mappings);
	}, [mappings]);

	return (
		<DndProvider backend={HTML5Backend}>
			<Stack spacing={2}>
				<MappingInput mappings={mappings} loadMapping={setMappings} assetType={assetType} />
				<FlexBox>
					<Stack spacing={1.5} sx={{ width: '50%' }}>
						<H5>Columns</H5>
						<PaginatedDraggableList
							rawData={rawData}
							mappings={mappings}
							removeMapping={removeMappingByValue}
							upsertMapping={updateMappingField}
						/>
					</Stack>
					<Stack spacing={1.5} sx={{ width: '50%' }}>
						<H5>Mappings</H5>
						<PaginatedDroppableList
							rawData={rawData}
							mappings={mappings}
							model={model}
							removeMapping={removeMappingByKey}
						/>
					</Stack>
				</FlexBox>
				<FlexBox alignItems='baseline'>
					<TextButton size='small' handleClick={onBack} color='secondary'>
						Back
					</TextButton>

					<Box className='action-buttons'>
						<TextButton size='small' handleClick={onCancel} color='secondary'>
							Cancel
						</TextButton>
						<FilledButton size='small' handleClick={handleConfirm}>
							Confirm
						</FilledButton>
					</Box>
				</FlexBox>
			</Stack>
		</DndProvider>
	);
}

export default FieldMapping;
