function SvgAdmin(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 26.3 26.3'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M22.33 16.18c.22-.23.48-.51.75-.78.41-.41.98-.43 1.35-.06.37.37.35.94-.06 1.35-.27.27-.55.53-.78.75.19.5.35.95.53 1.38.03.06.14.12.21.12.32.01.65 0 .97 0 .59 0 .99.39.99.92 0 .54-.4.91-.99.91h-.97c-.07 0-.19.06-.21.13-.18.44-.34.88-.53 1.39l.76.73c.43.43.46.99.09 1.37-.38.38-.94.36-1.37-.07-.26-.26-.52-.53-.74-.76-.5.19-.95.35-1.4.54-.06.02-.11.14-.11.22-.01.33 0 .67 0 1 0 .55-.38.94-.9.95-.53 0-.92-.39-.93-.95v-1c0-.08-.09-.2-.16-.24-.21-.1-.43-.15-.64-.24-.21-.09-.41-.2-.66-.33-.25.26-.51.54-.78.81-.43.42-1 .45-1.37.06-.37-.38-.34-.94.08-1.35.26-.26.54-.52.79-.76-.22-.52-.42-1-.63-1.51h-1.14c-.56 0-.97-.4-.97-.92 0-.51.4-.9.95-.91.4-.01.8 0 1.15 0 .21-.48.41-.91.6-1.35.02-.06-.02-.18-.07-.23-.21-.23-.44-.45-.66-.67-.43-.43-.45-.99-.07-1.37.38-.38.94-.34 1.37.09.21.21.43.41.63.63.11.12.2.15.36.06.34-.17.69-.31 1.05-.44.16-.06.21-.13.2-.28-.01-.31 0-.61 0-.92 0-.6.4-1.03.93-1.02.53 0 .9.42.91 1.01v.97c0 .07.07.18.13.21.44.18.88.34 1.39.54zm.07 3.71c0-1.38-1.1-2.48-2.43-2.53-1.41-.05-2.64 1.17-2.6 2.58.04 1.34 1.14 2.45 2.51 2.45 1.42 0 2.51-1.09 2.51-2.5zM12.54 13.45c-3.72 0-6.73-3.02-6.72-6.73C5.82 3 8.83 0 12.55 0c3.72 0 6.73 3.02 6.73 6.73 0 3.72-3.03 6.73-6.74 6.72zm.01-1.84c2.7 0 4.89-2.18 4.89-4.88 0-2.7-2.19-4.9-4.9-4.9-2.71 0-4.9 2.19-4.89 4.91 0 2.69 2.2 4.88 4.9 4.87zM7.22 25.09H2.23c-1.36 0-2.22-.86-2.22-2.22 0-.74-.04-1.48.05-2.2.16-1.28.91-2.25 1.86-3.06 1.31-1.11 2.85-1.77 4.47-2.25 1.98-.58 4.01-.84 6.08-.84.59 0 .99.38.98.92 0 .52-.4.88-.97.92-1.07.07-2.14.1-3.2.25-1.95.27-3.82.82-5.5 1.9-.55.35-1.05.77-1.44 1.3-.33.45-.52.95-.51 1.52v1.52c0 .37.04.41.41.41H12.54c.54.03.92.43.91.95-.01.5-.4.88-.93.88h-5.3z' />
		</svg>
	);
}
export default SvgAdmin;
