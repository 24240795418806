import { Print } from '@mui/icons-material';
import { Box } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKULocatorQRCode from '@pw/components/QRImage/SKULocatorQRCode';
import convertStyleObjectToCSS from '@pw/utilities/convertStyleObjectToCSS';
import printElement from '@pw/utilities/printElement';
import { useRef } from 'react';
import Barcode from 'react-barcode';
import styles from './index.style';

const NABCALabel = ({ params }) => {
  const printRef = useRef();

  const {
    p: {
      n, // : [prefix, nabca, suffix],
      u, //: upc_code,
      s, //: scc_code,
      c, //: sc_codes,
    },
    s: sku_id, //: sku_id,
    n: name, //: release?.name,
    r, //: release_id,
    c: company_id, //: company?.company_id,
    y, //: company?.company_name,
    d, //: company?.company_compliance?.excise_id,
    f, //: filledDate,
    m, //: sizeML,
    a, //: proof,
    b, //: count,
    l, //: lcs,
    g, //: pgcs,
    k, //: taxCode?.name,
    i, //: index/serial
  } = params;

  let stateNC = null;
  let statePA = null;
  let stateOthers = [];

  c.length > 0 &&
  c.forEach((item) => {
    if (item.state === 'NC') {
      stateNC = item;
    } else if (item.state === 'PA') {
      statePA = item;
    } else {
      stateOthers.push(item);
    }
  });

  const handlePrint = (ref) => {
    const element = ref?.current?.innerHTML;
    printElement({ element, styles: convertStyleObjectToCSS(styles) });
  };

  return (
    <Box sx={styles} className="root">
      <FlexBox justifyContent="flex-end" sx={{ marginBottom: 2 }}>
        <TitledButton
          handleClick={() => handlePrint(printRef)}
          label="Print"
        >
          <Print height={24} width={24} />
        </TitledButton>
      </FlexBox>
      <Box ref={printRef} sx={{ display: 'grid', gap: '10px' }}>
          <div className="container">
            <div className="label">
              {/* First row */}
              <div className="long" style={{ gridColumn: '1 / span 4', gridRow: '1 / span 2' }}>{name}</div>
              <div className="nabcaCode" style={{ gridColumn: '1 / span 4', gridRow: '3 / span 4' }}>
                <div className="prefix">{n?.[0]}</div>
                <div className="code">{n?.[1]}</div>
                <div className="suffix"> {n?.[2]}</div>
              </div>
              <div className="stateCode_b" style={{ gridColumn: '5 / span 2', gridRow: '1 / span 3' }}>
                {stateOthers[0] && (
                  <span>{stateOthers[0]?.state}-{stateOthers[0]?.code}</span>
                )}
              </div>
              <div className="stateCode_w" style={{ gridColumn: '5 / span 2', gridRow: '4 / span 3' }}>
                {stateOthers[1] && (
                  <span>{stateOthers[1]?.state}-{stateOthers[1]?.code}</span>
                )}
              </div>
              {/* Second row */}
              <div className="stateCode_b" style={{ gridColumn: '1 / span 2', gridRow: '7 / span 3' }}>
                {stateOthers[2] && (
                  <span>{stateOthers[2]?.state}-{stateOthers[2]?.code}</span>
                )}
              </div>
              <div className="stateCode_w" style={{ gridColumn: '3 / span 2', gridRow: '7 / span 3' }}>
                {stateOthers[3] && (
                  <span>{stateOthers[3]?.state}-{stateOthers[3]?.code}</span>
                )}
              </div>
              <div className="stateCode_b" style={{ gridColumn: '5 / span 2', gridRow: '7 / span 3' }}>
                {stateOthers[4] && (
                  <span>{stateOthers[4]?.state}-{stateOthers[4]?.code}</span>
                )}
              </div>
              {/* Third row */}
              <div className="upcBarcode" style={{ gridColumn: '1 / span 2', gridRow: '10 / span 6' }}>
                <Barcode
                  width={1}
                  height={80}
                  fontSize={10}
                  value={u}
                  format="UPC"
                />
              </div>

              <div className="stateCode_b" style={{ gridColumn: '3 / span 2', gridRow: '10 / span 3' }}>
                {stateOthers[5] && (
                  <span>{stateOthers[5]?.state}-{stateOthers[5]?.code}</span>
                )}
              </div>
              <div className="stateCode_w" style={{ gridColumn: '3 / span 2', gridRow: '13 / span 3' }}>
                {statePA && (
                  <span>
                    <span>
                      {statePA?.state}-
                    </span>
                    {statePA?.code}
                  </span>
                )}
              </div>

              <div className="stateCode_w" style={{ gridColumn: '5 / span 2', gridRow: '10 / span 3' }}>
                {stateOthers[6] && (
                  <span>{stateOthers[6]?.state}-{stateOthers[6]?.code}</span>
                )}
              </div>
              <div className="stateCode_b" style={{ gridColumn: '5 / span 2', gridRow: '13 / span 3' }}>
                {stateNC && (
                  <span>{stateNC?.state}-{stateNC?.code}</span>
                )}
              </div>
              {/* Fourth row */}
              <div className="upcBarcode" style={{ gridColumn: '1 / span 6', gridRow: '16 / span 6' }}>
                <Barcode value={s} format="ITF" />
              </div>
              {/* Fifth row */}
              <div className="upcBarcode" style={{ gridColumn: '1 / span 3', gridRow: '22 / span 7' }}>
                <SKULocatorQRCode params={params} type='n' />
              </div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '22' }}>{`Serial Number: `}<strong>{i}</strong>
              </div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '23' }}>{`Date Filled: `}<strong>{f}</strong>
              </div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '24' }}>{'Btl Size: '}<strong>{`${m} mL`}</strong>
              </div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '25' }}>{`Btls/CS: `}<strong>{b}</strong></div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '26' }}>{'L/CS: '}<strong>{`${l} L`}</strong></div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '27' }}>{`Proof: `}<strong>{a}</strong></div>
              <div className="long"
                   style={{ gridColumn: '4 / span 3', gridRow: '28' }}>{`PG/CS: `}<strong>{g}</strong></div>
              {/* Sixth row */}
              <div className="long" style={{ gridColumn: '1 / span 6', gridRow: '29' }}>
                {`Kind of Spirits: ${k}`}
              </div>
              <div className="long" style={{ gridColumn: '1 / span 6', gridRow: '30' }}>
                {`${d}, ${y}`}
              </div>
            </div>
          </div>
      </Box>
    </Box>
  );
};

export default NABCALabel;
