import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import StrengthDisplay from '@pw/components/properties/StrengthDisplay';
import { UNITS } from '@pw/consts/units';
import { useCompanySKUs } from '@pw/redux/containers/User/hooks';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useLiquidSummaryCalculator from '@pw/utilities/hooks/logic/useLiquidSummaryCalculator';

function LiquidSummary({
	skus = [],
	assets = [],
	force = false,
	extendedSummary = false,
	dutyPaidSummary = false,
}) {
	const converter = useConverter();
	const skuList = useCompanySKUs();

	const {
		asset_count,
		original_bulk,
		original_la,
		updated_bulk,
		updated_la,
		sku_count,
		sku_bulk,
		sku_la,
		sku_groups,
		net_weight,
		gross_weight,
		tcf_bulk,
		weighted_abv,
		weighted_tcf,
		duty_paid_asset_bulk,
		duty_paid_asset_la,
		duty_paid_sku_bulk,
		duty_paid_sku_la,
	} = useLiquidSummaryCalculator(converter, skuList, assets, skus);

	return (
		<Stack spacing={0.5}>
			{asset_count > 0 && !dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='Assets' amount={asset_count} />
					<LiquidDisplay label='Original' value={original_bulk} la={original_la} force={force} />
					<LiquidDisplay label='Final' value={updated_bulk} la={updated_la} force={force} />
					<LiquidDisplay
						label='Amount'
						value={Math.abs(updated_bulk - original_bulk)}
						la={Math.abs(updated_la - original_la)}
						force={force}
					/>
				</FlexBox>
			)}
			{sku_count > 0 && !dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='SKUs' amount={skus.length} />
					<AmountDisplay label='Items' amount={sku_count} />
					<AmountDisplay label='Groups' amount={sku_groups} />
					<LiquidDisplay label='Liquid' value={sku_bulk} la={sku_la} force={force} />
				</FlexBox>
			)}
			{extendedSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='Net Weight' amount={net_weight} unit={UNITS.kilograms} />
					<AmountDisplay label='Gross Weight' amount={gross_weight} unit={UNITS.kilograms} />
					<LiquidDisplay
						label='TCF Adjusted'
						value={Number(tcf_bulk) + Number(sku_bulk)}
						la={Number(updated_la) + Number(sku_la)}
						force={force}
					/>
					<StrengthDisplay label='ABV' value={weighted_abv} tcf={weighted_tcf} />
				</FlexBox>
			)}
			{asset_count > 0 && dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='Assets' amount={asset_count} />
					<LiquidDisplay
						label='Tax On'
						value={duty_paid_asset_bulk}
						la={duty_paid_asset_la}
						force={force}
					/>
				</FlexBox>
			)}
			{sku_count > 0 && dutyPaidSummary && (
				<FlexBox className='inventory-footer'>
					<AmountDisplay label='SKUs' amount={skus.length} />
					<AmountDisplay label='Items' amount={sku_count} />
					<AmountDisplay label='Groups' amount={sku_groups} />
					<LiquidDisplay label='Tax?' value={duty_paid_sku_bulk} la={duty_paid_sku_la} force={force} />
				</FlexBox>
			)}
		</Stack>
	);
}

export default LiquidSummary;
