function SvgEnzyme(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 33 34'
			width='0.9705882353em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d="M20.8028 34.0001C19.9812 33.7811 19.2746 33.3883 18.7582 32.6871C17.9525 31.594 17.8968 30.0984 18.6317 28.9485C19.3825 27.7721 20.7709 27.1967 22.1293 27.4981C23.4558 27.7925 24.4754 28.8935 24.6673 30.2384C24.9079 31.9264 23.89 33.4797 22.2478 33.9291C22.1629 33.9522 22.0789 33.977 21.994 34.001H20.8028V34.0001ZM21.4015 28.8324C20.3597 28.8359 19.5028 29.687 19.501 30.719C19.4993 31.7651 20.3889 32.6587 21.4165 32.6428C22.4742 32.6268 23.3108 31.7669 23.3037 30.703C23.2966 29.6471 22.4644 28.8288 21.4015 28.8324Z" fill="currentColor" />
			<path d="M0.726562 23.1208C0.795541 21.6093 1.63213 20.1952 3.32564 19.4133C5.03153 18.6252 6.67463 18.8752 8.11079 20.0986C8.79881 20.6846 9.5275 20.9204 10.3995 20.7015C10.7497 20.6137 11.0371 20.4178 11.3077 20.1793C12.6386 19.0081 14.1676 18.6633 15.8328 19.2599C17.4706 19.8468 18.4567 21.0605 18.7476 22.7813C19.1686 25.2672 17.3247 27.6538 14.8282 27.9206C13.4858 28.0642 12.3264 27.6617 11.3209 26.7823C10.3508 25.9339 9.1552 25.9525 8.17712 26.7974C6.7498 28.0297 5.13588 28.2992 3.39904 27.5651C1.76831 26.8763 0.727447 25.2423 0.726562 23.1208ZM2.14062 23.0818C2.14062 24.2503 2.39266 24.9418 2.87197 25.4773C4.07467 26.8204 5.95124 26.9224 7.30251 25.7113C7.98876 25.0952 8.78555 24.7716 9.70614 24.7556C10.7426 24.7379 11.5898 25.1555 12.3707 25.8213C13.3408 26.6484 14.4648 26.7929 15.6188 26.2566C16.7862 25.7131 17.3981 24.7414 17.3946 23.456C17.391 22.182 16.7729 21.2165 15.6206 20.6882C14.4188 20.1376 13.2408 20.3007 12.2681 21.2077C10.8788 22.5047 8.6414 22.5056 7.22115 21.1811C6.26695 20.2919 5.10581 20.1491 3.9261 20.6819C2.75347 21.2112 2.17069 22.1767 2.14062 23.0809V23.0818Z" fill="currentColor" />
			<path d="M22.6898 22.2821C22.6872 21.9497 22.7199 21.6208 22.7871 21.2945C23.0347 20.0888 22.4192 19.0143 21.2678 18.6295C19.5566 18.0577 18.4936 16.8768 18.1806 15.1073C17.6827 12.2898 19.8706 9.96442 22.4024 9.87754C24.4744 9.80661 26.649 11.226 27.0143 13.7713C27.0832 14.2544 27.0585 14.7411 26.9665 15.2234C26.6985 16.6233 27.2477 17.5816 28.5751 18.0409C30.5375 18.7191 31.7384 20.5409 31.6057 22.6385C31.4828 24.5871 29.9839 26.2804 28.0224 26.6865C25.2792 27.2548 22.6987 25.1253 22.6889 22.2821H22.6898ZM25.6382 14.3005C25.6285 14.1675 25.6329 13.9654 25.5949 13.7721C25.2641 12.1205 23.8324 11.1648 22.3953 11.2783C20.6762 11.4139 19.4514 12.7819 19.5044 14.4362C19.5442 15.672 20.3366 16.8653 21.7807 17.3396C23.5529 17.9221 24.539 19.7085 24.1702 21.5427C24.0915 21.9328 24.0544 22.3202 24.1127 22.7245C24.3612 24.432 25.9981 25.6421 27.6563 25.323C29.4338 24.9808 30.5578 23.2919 30.1324 21.5764C29.8521 20.447 29.1393 19.7129 28.0392 19.337C26.1812 18.7031 25.1916 16.9832 25.5684 15.0559C25.6135 14.8254 25.6241 14.5957 25.6373 14.2988L25.6382 14.3005Z" fill="currentColor" />
			<path d="M5.24146 12.1397C5.22024 9.69194 7.03579 7.81335 9.565 7.64668C10.735 7.56955 11.5946 6.69896 11.6573 5.52605C11.7776 3.27777 13.3128 1.57382 15.5361 1.22364C15.6661 1.20325 15.8005 1.19527 15.9314 1.20325C16.2692 1.22364 16.5318 1.47542 16.5778 1.80787C16.622 2.13323 16.4266 2.45062 16.0958 2.54725C15.9066 2.60222 15.705 2.61108 15.5104 2.65098C14.1273 2.9329 13.1333 4.11201 13.0741 5.53669C12.9936 7.46582 11.4584 8.99777 9.53758 9.06604C7.8989 9.12455 6.56886 10.5315 6.5945 12.1805C6.62015 13.8436 7.97053 15.1947 9.61894 15.208C11.2965 15.2205 12.6142 14.0378 12.7141 12.3525C12.8424 10.1973 14.3714 9.04388 15.6546 8.80628C15.9137 8.75841 16.1781 8.73536 16.4416 8.71142C17.9733 8.57135 19.1486 7.32663 19.1963 5.77872C19.2069 5.44626 19.2892 5.16434 19.5978 4.99856C20.0833 4.73791 20.6228 5.11115 20.6104 5.72553C20.5901 6.77697 20.2522 7.72469 19.5695 8.5279C18.7099 9.53857 17.6169 10.0865 16.2824 10.1326C15.1319 10.1725 14.1768 11.1379 14.1379 12.294C14.0601 14.6158 12.1897 16.5255 9.89574 16.6256C7.56993 16.7276 5.54744 15.0387 5.24677 12.7363C5.22112 12.5413 5.24323 12.3392 5.24323 12.1406L5.24146 12.1397Z" fill="currentColor" />
			<path d="M3.31451 1.61215e-06C5.15039 0.00177471 6.59717 1.46724 6.59275 3.31835C6.58833 5.13045 5.10971 6.6101 3.30478 6.60833C1.48923 6.60744 -0.00176711 5.10918 1.57194e-06 3.29087C0.00265459 1.45926 1.4742 -0.00177148 3.31451 1.61215e-06ZM3.29859 5.18985C4.35714 5.18985 5.19549 4.36004 5.19903 3.30594C5.20345 2.25627 4.35537 1.38656 3.3207 1.37947C2.27187 1.37238 1.41318 2.23854 1.41406 3.30151C1.41494 4.37423 2.22854 5.18897 3.29859 5.18985Z" fill="currentColor" />
			<path d="M29.7121 3.58301C31.5436 3.58478 33.0072 5.06089 33.001 6.8987C32.9948 8.7108 31.4896 10.1993 29.6714 10.1913C27.8559 10.1825 26.402 8.69041 26.4082 6.84285C26.4144 5.0272 27.8806 3.58035 29.7112 3.58301H29.7121ZM31.5869 6.88806C31.5887 5.82332 30.7344 4.95893 29.6829 4.96248C28.65 4.96603 27.8002 5.83573 27.8019 6.88629C27.8037 7.93951 28.6421 8.77286 29.6997 8.77286C30.7707 8.77286 31.5851 7.9599 31.5869 6.88806Z" fill="currentColor" />
			<path d="M18.7145 3.56308C18.3147 3.5684 18.0123 3.28027 18.0079 2.8893C18.0026 2.50099 18.3068 2.19336 18.695 2.19336C19.0841 2.19336 19.3866 2.49833 19.3839 2.88841C19.3813 3.26874 19.0947 3.55776 18.7145 3.56219V3.56308Z" fill="currentColor" />
		</svg>
	);
}
export default SvgEnzyme;
