import Close from '@mui/icons-material/Close';
import FlipCameraIos from '@mui/icons-material/FlipCameraIos';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';
import QrScan3 from '@pw/components/icons/QrScan3';
import { clamp } from 'lodash';
import { useCallback, useId, useRef, useState } from 'react';
import QRCodeScanner from './QRCodeScanner';

const styles = {
  '&.root': {
    position: 'relative',
    borderRadius: '5px',
  },
  '.scanIcon': {
    fontSize: '1rem',
    width: '1.75em',
    height: '1.75em',
    padding: '0.125em',
  },
  '.MuiDialog-paper': {
    borderRadius: '2rem',
  },
  '.scanner': {
    position: 'relative',
    margin: 0,
    width: '100%',
    maxWidth: '600px',
    background: '#fff',
  },
  '.modalButton': {
    position: 'absolute',
    zIndex: 1,
    top: '1rem',
    padding: '1rem',
    borderRadius: '9999px',
    background: 'rgba(255,255,255, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  '.dialogClose': {
    left: '1rem',
  },
  '.zoomContainer': {
    right: '1rem',
    top: '40%',
    position: 'absolute',
    zIndex: 1,
    // background: 'rgba(255,255,255, 0.2)',
    display: 'flex',
    flexDirection: 'column',
  },
  '.zoomIn': {
    position: 'relative',
  },
  '.zoomOut': {
    position: 'relative',
  },
  '.toggleFacingMode': {
    right: '1rem',
  },
  '.qrData': {
    position: 'absolute',
    top: '100%',
    right: 0,
    whiteSpace: 'nowrap',
  },
  containerStyle: {},
  videoContainerStyle: {},
  videoStyle: {
    objectFit: 'cover',
  },
};

const facingModeMap = ['environment', 'user'];

function QRCode(props) {
  const {
    onSuccess,
    withLabel,
    Component = Button,
    closeOnSuccess = true,
    disabled = false,
    height = 42,
    width = 42,
    sx = { width: '78px', height: '55px' },
    ...rest
  } = props;

  const [showQRScanner, setShowQRScanner] = useState(false);

  const closeDialog = async () => {
    setShowQRScanner(false);
  };

  return (
    <>
      <Component
        onClick={() => !disabled && setShowQRScanner(true)}
        size='medium'
        margin='dense'
        variant='outlined'
        color = 'secondary'
        sx={sx}
        label={withLabel && 'Scan'}
        {...rest}
      >
        <QrScan3 height={height} width={width} />
      </Component>
      {showQRScanner && (
        <QrScannerDialog
          closeDialog={closeDialog}
          closeOnSuccess={closeOnSuccess}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}

export default QRCode;
export function QrScannerDialog({ closeDialog, closeOnSuccess, onSuccess }) {
  const controlsRef = useRef({});
  const [facingMode, setFacingMode] = useState(0);
  const id = useId();
  const [zoomNativeSupport, setZoomNativeSupport] = useState(false);
  const zoomIn = useCallback(async () => {
    const capabilities =
      controlsRef?.current?.streamVideoCapabilitiesGet?.(() => true) ?? {};
    if (
      'zoom' in capabilities &&
      controlsRef?.current?.streamVideoConstraintsGet &&
      controlsRef?.current?.streamVideoSettingsGet
    ) {
      const { zoom } =
        controlsRef?.current?.streamVideoSettingsGet?.(() => true) ?? {};
      const step = (capabilities.zoom.max - capabilities.zoom.min) / 10;
      const value = zoom + step;
      await controlsRef?.current?.streamVideoConstraintsApply(
        {
          advanced: [
            {
              zoom: clamp(
                value,
                capabilities.zoom.min,
                capabilities.zoom.max,
              ).toFixed(1),
            },
          ],
        },
        () => true,
      );
    }
  }, []);
  const zoomOut = useCallback(async () => {
    const capabilities =
      controlsRef?.current?.streamVideoCapabilitiesGet?.(() => true) ?? {};
    if (
      'zoom' in capabilities &&
      controlsRef?.current?.streamVideoConstraintsGet &&
      controlsRef?.current?.streamVideoSettingsGet
    ) {
      const { zoom } =
        controlsRef?.current?.streamVideoSettingsGet?.(() => true) ?? {};
      const step = (capabilities.zoom.max - capabilities.zoom.min) / 10;
      const value = zoom - step;
      await controlsRef?.current?.streamVideoConstraintsApply(
        {
          advanced: [
            {
              zoom: clamp(
                value,
                capabilities.zoom.min,
                capabilities.zoom.max,
              ).toFixed(1),
            },
          ],
        },
        () => true,
      );
    }
  }, []);
  const toggleFacingMode = () => {
    setFacingMode(facingMode === 0 ? 1 : 0);
  };
  const handleOnControls = useCallback((ctrl) => {
    controlsRef.current = ctrl;
    const capabilities =
      ctrl?.streamVideoCapabilitiesGet?.(() => true) ?? false;
    setZoomNativeSupport('zoom' in capabilities);
  }, []);

  return (
    <Dialog
      sx={styles}
      className='dialog'
      open={true}
      onClose={closeDialog}
      fullWidth
    >
      <Box className='scanner'>
        <ButtonBase onClick={closeDialog} className='modalButton dialogClose'>
          <Close />
        </ButtonBase>
        <ButtonBase
          onClick={toggleFacingMode}
          className='modalButton toggleFacingMode'
        >
          <FlipCameraIos />
        </ButtonBase>

        <QRCodeScanner
          onSuccess={(data) => {
            if (closeOnSuccess) closeDialog();
            if (typeof onSuccess === 'function') onSuccess(data);
          }}
          facingMode={facingModeMap[facingMode]}
          containerStyle={styles.containerStyle}
          videoContainerStyle={styles.videoContainerStyle}
          videoStyle={styles.videoStyle}
          videoId={id}
          onControls={handleOnControls}
        />
      </Box>
    </Dialog>
  );
}
