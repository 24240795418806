function SvgLiquid(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 20.304 29.827'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<clipPath id='Liquid_svg__a'>
					<path data-name='Rectangle 2139' fill='#393c3c' d='M0 0h20.304v29.827H0z' />
				</clipPath>
			</defs>
			<g data-name='Group 1496'>
				<g data-name='Group 1301' clipPath='url(#Liquid_svg__a)' fill='#393c3c'>
					<path
						data-name='Path 10116'
						d='M10.441 0a1.294 1.294 0 01.512.459A107.156 107.156 0 0118.1 12.745a28.131 28.131 0 011.93 5.012 8.952 8.952 0 01-.582 5.985 10.144 10.144 0 01-8.873 6.076 10.168 10.168 0 01-9.916-6.56 9.684 9.684 0 01-.646-3.93 8.453 8.453 0 01.387-2.02 37.466 37.466 0 012.929-6.8C5.137 7.077 7.162 3.771 9.3.532A1.381 1.381 0 019.859 0zm-.23 28.154c.432-.056.937-.051 1.434-.148a8.264 8.264 0 005.792-4.056 7.549 7.549 0 00.819-6.285 30.047 30.047 0 00-1.742-4.309 105.23 105.23 0 00-6.152-10.714c-.212-.329-.211-.329-.433.014a120.929 120.929 0 00-4.155 6.9 51.015 51.015 0 00-3.442 7.21 8.129 8.129 0 00-.473 4.64 8.537 8.537 0 008.351 6.745'
					/>
					<path
						data-name='Path 10117'
						d='M10.983 25.759a.861.861 0 01-.945.847 6.963 6.963 0 01-6.468-4.791 7.383 7.383 0 01-.341-2.086.847.847 0 111.692-.03 5.522 5.522 0 00.391 1.954 5.214 5.214 0 004.674 3.243c.617.014 1 .343.994.862'
					/>
				</g>
			</g>
		</svg>
	);
}
export default SvgLiquid;
