/* eslint-disable react/prop-types */
import {
	AccountCircleRounded,
	AddCircle,
	PendingActions,
} from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import {
	DataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	useGridRootProps,
} from '@mui/x-data-grid';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';
import { countriesOptions } from '@pw/components/Forms/FormikForm';
import { UK_COUNTRY_CODES } from '@pw/consts/admin';
import { CLIENT_TYPE } from '@pw/consts/contacts';
import {
	useCompanyCountry,
	useCompanyOwners,
} from '@pw/redux/containers/User/hooks';
import {
	inviteOwnerThunk,
	refetchOwnersThunk,
	uploadOwnersThunk,
} from '@pw/redux/thunks/owners';
import generateUsername from '@pw/utilities/generateUsername';
import useModalState from '@pw/utilities/hooks/components/useModalState';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import { forwardRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { GridToolbarButton } from './components/GridToolbarButton';
import { ModalWithClose } from '../../../Dialogs/ModalWithClose';
import OwnerAssets from './components/OwnerAssets';
import OwnerForm from './components/OwnerForm';
import { ResetPasswordDialog } from './components/ResetPasswordDialog';

const MODALS = {
	EDIT_OWNER: 'edit_owner',
	VIEW_ASSETS: 'view_assets',
	RESET_PASSWORD: 'reset_password',
	UPLOAD_OWNERS: 'upload_owners',
};

const CustomToolbar = forwardRef(function CustomToolbar(
	{
		showQuickFilter = false,
		quickFilterProps = {},
		onClickUpload,
		onClickAdd,
		onClickRefetch,
		...other
	},
	ref,
) {
	const rootProps = useGridRootProps();
	return (
		<GridToolbarContainer ref={ref} {...other}>
			<GridToolbarColumnsButton />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />
			{!rootProps?.disableUploadButton && (
				<GridToolbarButton
					button={{
						children: 'Upload',
						onClick: onClickUpload,
						startIcon: <UploadFileIcon />,
					}}
					tooltip={{ title: 'Upload' }}
				/>
			)}
			<GridToolbarButton
				button={{
					children: 'refetch',
					onClick: onClickRefetch,
					startIcon: <CachedIcon />,
				}}
				tooltip={{ title: 'refetch' }}
			/>
			<GridToolbarButton
				button={{
					children: 'add',
					onClick: onClickAdd,
					startIcon: <AddCircle />,
				}}
				tooltip={{ title: 'add' }}
			/>
			<Box sx={{ flex: 1 }} />
			{showQuickFilter && <GridToolbarQuickFilter {...quickFilterProps} />}
		</GridToolbarContainer>
	);
});

function Owners() {
	const dispatch = useDispatch();

	// selectors
	const isMobile = useIsMobile();
	const company_cc = useCompanyCountry();

	// state
	const { modal, openModal, closeModal } = useModalState();

	// const { data, isLoading, refetch } = useOwnersQuery();
	const owners = useCompanyOwners();

	const handleForceRefetch = () =>
		dispatch(refetchOwnersThunk({ force: true }));

	const onSubmitUpload = (data) =>
		dispatch(uploadOwnersThunk(data.validData))
			.unwrap()
			.then(handleForceRefetch);

	const handleInviteCustomer = (a) => dispatch(inviteOwnerThunk(a));

	const handleResetCustomerPassword = (a) => dispatch(resetCustomerPassword(a));

	const handleEditCustomer = useCallback(
		({
			path,
			account_name,
			account_email,
			account_username,
			country,
			wowgr,
			duty_req,
			...rest
		}) =>
			openModal(MODALS.EDIT_OWNER, {
				path,
				name: account_name,
				email: account_email,
				username: account_username,
				country,
				wowgr,
				duty_req,
				...rest,
			}),
		[openModal],
	);
	// calculated
	const columns = useMemo(
		() => [
			{
				field: 'status',
				headerName: 'Status',
				width: 70,
				renderCell: (params) => {
					return params.row.joined ? (
						<AccountCircleRounded />
					) : (
						<PendingActions />
					);
				},
			},
			{
				field: 'account_name',
				headerName: 'Name',
				width: 200,
				filterable: true,
			},
			{
				field: 'account_username',
				headerName: 'Username',
				width: 150,
				filterable: true,
			},
			{
				field: 'account_email',
				headerName: 'Email',
				width: 250,
				filterable: true,
			},
			{
				field: 'invited',
				headerName: 'Invited',
				width: 250,
				renderCell: (params) => {
					return params.row.invited
						? new Date(params.row.invited).toLocaleString()
						: null;
				},
			},
			{
				field: 'actions',
				headerName: 'Actions',
				width: 200,
				renderCell: (params) => (
					<BasicMenu
						menuOptions={[
							{
								title: 'Edit',
								onClick: () => handleEditCustomer(params.row),
								disabled: false,
							},
							{
								title: 'Invite',
								onClick: () => handleInviteCustomer(params.row),
								disabled: params.row.joined,
							},
							{
								title: 'Reset Password',
								onClick: () => openModal(MODALS.RESET_PASSWORD, params.row),
								disabled: !params.row.joined,
							},
							{
								title: 'View owned assets',
								onClick: () => openModal(MODALS.VIEW_ASSETS, params.row),
								disabled: !params?.row?.account_id,
							},
						]}
					/>
				),
			},
		],
		[handleEditCustomer, handleInviteCustomer, openModal],
	);

	const uploadColumns = useMemo(
		() => [
			{
				// Visible in table header and when matching columns.
				label: 'Full name',
				// This is the key used for this field when we call onSubmit.
				key: 'name',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['first name', 'first'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'John Doe',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					{
						// Can be "required" / "unique" / "regex"
						rule: 'required',
						errorMessage: 'Full name is required',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
					},
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'User name',
				// This is the key used for this field when we call onSubmit.
				key: 'username',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['account', 'accountId', 'generated username'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'john.doe',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'User name is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Contact email',
				// This is the key used for this field when we call onSubmit.
				key: 'email',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['mail', 'e-mail'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'john.doe@example.com',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					{
						// Can be "required" / "unique" / "regex"
						rule: 'required',
						errorMessage: 'Contact email is required',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
					},
					{
						// Can be "required" / "unique" / "regex"
						rule: 'unique',
						errorMessage: 'Contact email should be unique',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
					},
					{
						// Can be "required" / "unique" / "regex"
						rule: 'regex',
						errorMessage: 'Invalid contact email',
						// There can be "info" / "warning" / "error" levels. Optional. Default "error".
						level: 'error',
						value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
					},
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Street/Number',
				// This is the key used for this field when we call onSubmit.
				key: 'line1',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'line1'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'Street/Number is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Building',
				// This is the key used for this field when we call onSubmit.
				key: 'line2',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'line2', 'building'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Town',
				// This is the key used for this field when we call onSubmit.
				key: 'line3',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'line3'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [],
			},
			{
				// Visible in table header and when matching columns.
				label: 'City',
				// This is the key used for this field when we call onSubmit.
				key: 'city',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'city'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'City is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'State',
				// This is the key used for this field when we call onSubmit.
				key: 'state',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['address', 'state'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'City is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Postal Code',
				// This is the key used for this field when we call onSubmit.
				key: 'postCode',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['code', 'post', 'postCode', 'post code'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'Postal Code is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Country',
				// This is the key used for this field when we call onSubmit.
				key: 'country',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['country code'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "input" / "checkbox" / "select".
					type: 'select',
					options: countriesOptions,
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: 'GB',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [
					// {
					// 	// Can be "required" / "unique" / "regex"
					// 	rule: 'required',
					// 	errorMessage: 'Country is required',
					// 	// There can be "info" / "warning" / "error" levels. Optional. Default "error".
					// 	level: 'error',
					// },
				],
			},
			{
				// Visible in table header and when matching columns.
				label: 'Phone',
				// This is the key used for this field when we call onSubmit.
				key: 'telephone',
				// Allows for better automatic column matching. Optional.
				alternateMatches: ['telephone', 'phone', 'tel'],
				// Used when editing and validating information.
				fieldType: {
					// There are 3 types - "" / "checkbox" / "select".
					type: 'input',
				},
				// Used in the first step to provide an example of what data is expected in this field. Optional.
				example: '+9 999 9999 999...',
				// Can have multiple validations that are visible in Validation Step table.
				validations: [],
			},
			...(UK_COUNTRY_CODES.includes(company_cc)
				? [
						{
							// Visible in table header and when matching columns.
							label: 'Require Duty Representation',
							// This is the key used for this field when we call onSubmit.
							key: 'duty_req',
							// Allows for better automatic column matching. Optional.
							alternateMatches: ['duty'],
							// Used when editing and validating information.
							fieldType: {
								// There are 3 types - "input" / "checkbox" / "select".
								type: 'checkbox',
							},
							// Used in the first step to provide an example of what data is expected in this field. Optional.
							example: 'TRUE',
							// Can have multiple validations that are visible in Validation Step table.
							validations: [],
						},
						{
							// Visible in table header and when matching columns.
							label: 'Is registered for WOWGR',
							// This is the key used for this field when we call onSubmit.
							key: 'wowgr',
							// Allows for better automatic column matching. Optional.
							alternateMatches: ['is registered'],
							// Used when editing and validating information.
							fieldType: {
								// There are 3 types - "input" / "checkbox" / "select".
								type: 'checkbox',
								disabled: true,
							},
							// Used in the first step to provide an example of what data is expected in this field. Optional.
							example: 'FALSE',
							// Can have multiple validations that are visible in Validation Step table.
							validations: [],
						},
					]
				: []),
		],
		[company_cc],
	);

	return (
		<Card>
			<CardHeader
				title='Manage Owners'
				subheader='Manage owners who have own assets managed by your organization.'
			/>
			<CardContent>
				<Box style={{ height: '69vh', width: '100%' }}>
					<DataGrid
						autoPageSize
						// initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
						// pageSizeOptions={[10, 25, 50, 100]}
						rows={owners ?? []}
						columns={columns}
						getRowId={(row) => row.path}
						disableSelectionOnClick
						disableColumnSelector={isMobile}
						disableUploadButton={isMobile}
						slots={{ toolbar: CustomToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
								onClickUpload: () => openModal(MODALS.UPLOAD_OWNERS, true),
								onClickAdd: () => openModal(MODALS.EDIT_OWNER, {}),
								onClickRefetch: handleForceRefetch,
							},
						}}
						sx={{
							'& .MuiDataGrid-columnHeaders': {
								position: 'sticky',
								top: 0,
								backgroundColor: '#f4f4f4',
								zIndex: 1,
							},
						}}
					/>
				</Box>

				{modal.fold({
					[MODALS.EDIT_OWNER]: (state) => (
						<ModalWithClose
							title='Edit Owner'
							open={!!state.data}
							onClose={() => closeModal()}
						>
							<OwnerForm
								user={state.data}
								onCancel={() => closeModal()}
								onSubmit={() => closeModal()}
							/>
						</ModalWithClose>
					),
					[MODALS.VIEW_ASSETS]: (state) => (
						<ModalWithClose
							title='Assets'
							open={!!state.data}
							onClose={() => closeModal()}
						>
							<OwnerAssets
								type={CLIENT_TYPE.ACCOUNT}
								id={state.data?.account_id}
							/>
						</ModalWithClose>
					),
					[MODALS.RESET_PASSWORD]: (state) => (
						<ResetPasswordDialog
							item={state.data}
							handleClose={() => closeModal()}
							handleResetPassword={handleResetCustomerPassword}
						/>
					),
					[MODALS.UPLOAD_OWNERS]: () => (
						<ReactSpreadsheetImport
							isOpen
							onClose={closeModal}
							onSubmit={onSubmitUpload}
							fields={uploadColumns}
							isNavigationEnabled
							rowHook={(row) =>
								!row.username ? { ...row, username: generateUsername() } : row
							}
							translations={{
								uploadStep: {
									title: 'Upload Customers',
								},
							}}
							customTheme={{
								colors: {
									subtitleColor: '#718096',
									inactiveColor: '#A0AEC0',
									border: '#E2E8F0',
									background: 'white',
									backgroundAlpha: 'rgba(255,255,255,0)',
									secondaryBackground: '#f5f5f5',
									highlight: '#E2E8F0',
									rsi: {
										50: '#E6E6FF',
										100: '#C4C6FF',
										200: '#A2A5FC',
										300: '#8888FC',
										400: '#7069FA',
										500: '#F0AA29',
										600: '#a8761c',
										700: '#a8761c',
										800: '#1D0EBE',
										900: '#0C008C',
									},
									red: {
										600: '#fd3b02',
										500: '#d32f2f',
									},
								},
							}}
						/>
					),
				})}
			</CardContent>
			<CardActions />
		</Card>
	);
}

export default Owners;
