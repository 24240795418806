import Stack from '@mui/material/Stack';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { H5 } from '@pw/components/Typography';
import { useFormikContext } from 'formik';

function CompanySettings() {
  const { values } = useFormikContext();

  return (
    <Stack spacing='1.5rem'>
      {['US'].includes(values.country) && (
        <>
          <H5>Display Settings</H5>
          <Instructions>
            The platform uses Metric units by default, check this to enable
            Imperial Units (US) for display.
          </Instructions>
          <FormikCheckBox name='imperial_units' label='Imperial Units' />
        </>
      )}

    </Stack>
  );
}

export default CompanySettings;
