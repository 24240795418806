import { useCompanySKUs } from '@pw/redux/containers/User';
import { useCallback } from 'react';

const useSKUSearch = () => {
	const skus = useCompanySKUs();

	const search = useCallback(
		(filter = [], param = '', itemFilter = (x) => !!x) => {
			// Find all skus matching this
			return skus
				.filter((sku) => {
					// Sku type must be in the filter
					if (filter.length !== 0 && !filter.includes(sku.sku_type)) {
						// console.log('Sku type not in filter', sku.sku_type);
						return false;
					}
					// Search term must be in the sku properties..
					const search_key =
						`${sku.sku_id} ${sku.sku_name} ${sku.sku_description}`.toLowerCase();
					// console.log('Search key', search_key, param);
					return search_key.includes(param.toLowerCase());
				})
				.filter(itemFilter);
		},
		[skus],
	);

	return [search];
};

export default useSKUSearch;
