import { Box, Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import Client from '@pw/components/Client';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import { ASSET_ACCOUNT_TYPE_TO_LABEL } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsOnlineHook } from '@pw/redux/containers/App';
import { useAccountPermissions } from '@pw/redux/containers/User/hooks';
import { mt2 } from '@pw/styles/margins';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';
import { useCallback, useContext, useMemo } from 'react';
import RevertMessage from '../Forms/RevertMessage';
import isEqual from 'lodash.isequal';

export function AssetAccountsImp({
	label = 'Asset Accounts',
	disabled = false,
}) {
	const { values } = useContext(FormikContext);
	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				label={<H5>{label}</H5>}
				disabled={disabled}
			/>
			<Collapse in={!!values?.enable} unmountOnExit>
				<Stack spacing='1.5rem' sx={{ ...mt2 }}>
					<Client
						name='bo'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['0']}`}
						disabled={disabled}
						withEnable={false}
					/>
					<Client
						name='mo'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['1']}`}
						disabled={disabled}
						withEnable={false}
						noCustomers={true}
					/>
					<Client
						name='ro'
						title={`${ASSET_ACCOUNT_TYPE_TO_LABEL['2']}`}
						disabled={disabled}
						withEnable={false}
						noCustomers={true}
					/>
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function AssetAccounts({ name = 'accounts', entity, ...rest }) {
	const { editOwners } = useAccountPermissions();
	const online = useIsOnlineHook();
	const { readonly } = useContext(FormikContext);
	const { values, setFieldValue } = useContext(FormikContext);
	const [historyExist, isSame, history] = useAssetRevertHook(entity, (e, e2) =>
		isEqual(e?.accounts, e2?.accounts),
	);
	const onRevert = useCallback(() => {
		setFieldValue('accounts', history.accounts);
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		setFieldValue('accounts', entity.accounts);
	}, [entity, setFieldValue]);

	const isReverted = useMemo(() => isEqual(values['accounts'], history?.accounts), [history, values])
	return (
		!readonly && (
			<FormikProvider path={name}>
				<Box sx={{ position: 'relative' }}>
					{historyExist && !isSame && (
						<RevertMessage
							title='Asset accounts'
							onRevert={onRevert}
							isReverted={isReverted}
							onUndo={onUndo}
							item={history?.accounts}
							type="accounts"
						/>
					)}
					<AssetAccountsImp {...rest} disabled={!editOwners || !online} />
				</Box>
			</FormikProvider>
		)
	);
}
