import { Children, isValidElement } from 'react';

function isFunction(input) {
	return typeof input === 'function';
}

/**
 * A React component that implements a switch/case-like control flow.
 *
 * The `Switch` component renders the first `Case` component whose `condition` prop
 * evaluates to true, or the `Default` component if no `Case` conditions match.
 *
 * The `value` prop is passed to the `condition` prop of each `Case` component.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components, which should include one or more `Case` and/or `Default` components.
 * @param {string|number|boolean} props.value - The value to match against the `Case` component conditions.
 * @returns {React.ReactNode} - The first matching `Case` component, or the `Default` component if no `Case` conditions match.
 */
const Switch = ({ children, value }) => {
	// -- Inspired by react-router --
	// We use React.Children.forEach instead of React.Children.toArray().find()
	// here because toArray adds keys to all child elements and we do not want
	// to trigger an unmount/remount for two children <Case>s or <Default>s
	let matchingCase = undefined;
	let defaultCase = undefined;

	// If the children are a function then resolve it first
	if (isFunction(children)) {
		children = children();
	}

	Children.forEach(children, (child) => {
		// not a valid react child, don't add it
		/* istanbul ignore next - This is only a safe fail for people writing bad code */
		if (!isValidElement(child)) {
			return;
		}
		if (!matchingCase && child.type === Case) {
			const { condition } = child.props;
			const conditionResult = Boolean(
				isFunction(condition) ? condition(value) : condition === value,
			);
			if (conditionResult) {
				matchingCase = child;
			} // else not matching condition, don't add it
		} else if (!defaultCase && child.type === Default) {
			defaultCase = child;
		} // else unknown type, don't add it
	});
	return matchingCase ?? defaultCase ?? null;
};

/**
 * A functional component that renders its child components if the condition is truthy.
 * This component is typically used within the `Switch` component to define a case condition.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render if the condition is truthy.
 * @param {function|string|number|boolean} props.condition - The condition to evaluate. Can be a function that returns a boolean, or a value to match against.
 */
const Case = ({ children }) => (
	<>{isFunction(children) ? children() : children}</>
);

/**
 * A functional component that renders its child components unconditionally.
 * This component is typically used within the `Switch` component to define a default case.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render.
 */
const Default = ({ children }) => (
	<>{isFunction(children) ? children() : children}</>
);

Switch.Case = Case;
Switch.Default = Default;

export default Switch;
