import { useMemo, useRef, useCallback } from 'react';
import { Box } from "@mui/material";

import { useTimeLineContext } from './TimeLineContext';
import Draggable from './Draggable';
import Step from './Step';

import { getSecondsInTimeframe, getStartOfDayEpoch, timeframes } from './functions';

import _styles from './Batch.styles';


const tf = timeframes();
const secondsInDay = getSecondsInTimeframe(tf.day);
const now = new Date();
const startOfDay = getStartOfDayEpoch(now);
const startOfDayTime = Math.floor(startOfDay / secondsInDay) * secondsInDay;

const Batch = ({ viewMode, date, batch, rescheduleBatch, index, ...rest }) => {
  const rootRef = useRef();
  const { window } = useTimeLineContext();
  const { batch_started, steps, batch_id } = batch;
  const batchStartTime = batch_started ? batch_started.getTime() : now.getTime()
  console.log(`using batch_started ${batch_started}`, batch_started ? 'yes' : 'no')
  const batchOffset = (batchStartTime - startOfDayTime) / 1_000; // in seconds

  const styles = useMemo(() => ({
    ..._styles,
    '--offset': batchOffset,
    '--index': index,
  }), [_styles, index, batchOffset]);

  const onDrop = useCallback(({ dropResult }) => {
    const { offsetDelta, offsetParent } = dropResult;
    // relative amount of window moved by, * num seconds it represents
    const xDelta = (offsetDelta.x / offsetParent.width) * window;
    rescheduleBatch({ batch_id, timeChange: xDelta * 1_000 });
  }, [window, batchStartTime]);

  return (

    <Box
      className={`batch-root view-${viewMode}`}
      sx={styles}
      {...rest}
      ref={rootRef}
    >
      <Draggable
        onDrop={onDrop}
        data={batch}
        type="batch"
      >
        <Box className="steps-container">
          {steps.map((s, i) => (
            <Step key={s.label} step={s} batch={batch} index={i}  {...rest} />
          ))}
        </Box>
      </Draggable >

    </Box >
  );
}

export default Batch;
