import { withAppLayout } from '@pw/components/Layout/AppLayout';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { SKU_TYPES } from '@pw/consts/sku';
import { useMemo } from 'react';

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.CONSUMABLE),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Consumable'
			skuType={SKU_TYPES.CONSUMABLE}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		/>
	);
}

export default withAppLayout(SKUPage, { title: 'Consumable SKUs' });
