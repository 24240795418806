export const POLICY_EDIT = '/admin/policies';


export const POLICY_STATUS = {
  ACTIVE: '0',
  DISABLED: '1',
};
export const POLICY_STATUSES = [
  { label: 'Active', value: '0' },
  { label: 'Disabled', value: '1' },
];

export const POLICY_STAGES = [
  { label: 'Pre', value: '0' },
  { label: 'During', value: '1' },
  { label: 'Post', value: '2' },
];

export const POLICY_ENTRY_TYPES = {
  BOOLEAN: 0,
  STRING: 1,
  NUMBER: 2,
};

export const POLICY_ENTRY_TYPE_OPTIONS = [
  {
    label: 'Confirmation',
    value: 0,
  },
  {
    label: 'Text',
    value: 1,
  },
  {
    label: 'Number',
    value: 2,
  },
  {
    label: 'Selection',
    value: 3,
  },
];


export const POLICY_CONDITION_OPTIONS = [
  {
    label: 'Not Required',
    value: 0,
  },
  {
    label: 'Approval Required',
    value: 1,
  },
  {
    label: 'Block',
    value: 2,
  },
];
