import { UNITS } from "@pw/consts/measures";

import {
  viewModes,
  dayNames,
  daysInWeek,
  monthNames,
  dayNamesShort,
} from './consts';

export const sum = (acc, curr) => acc + curr;
export const sumReducer = (array) => array.reduce(sum, 0);


export const d = new Date();
let startOfYear = new Date(`${d.getFullYear()}`);

export const getTimeframeFromStart = (viewMode, offset /* in number of viewMode units */) => {
  const d = new Date();
  switch (viewMode) {
    case 'year':
      return new Date(d.setFullYear(d.getFullYear()));
    case 'month':
      return new Date(d.setDate(1));
    case 'week':
      return new Date(d.setDate(d.getDate() - d.getDay() + 1));
    case 'day':
      return new Date(d.setHours(0, 0, 0, 0));
  }
}


export const getdaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
export const getDaysByMonth = (year = startOfYear.getFullYear()) => [
  31,
  year % 4 === 0 ? 29 : 28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31,
];

// whether we show them or noth, using 5-day weeks for calcs is gonna create havoc!
// const daysInWeek = (showWeekends = true) => showWeekends ? 7 : 5;

export const daysInMonthsReducer = (acc, curr) => acc + curr;
export const getDayOfYear = (date) => {
  const months = getDaysByMonth(date.getFullYear());
  const m = date.getMonth();
  // because this will return 1 forst first of month
  // this fn will return a 1-based dayNumber
  const countThisMonth = date.getDate();
  const countPrevMonths = months.slice(0, m).reduce(daysInMonthsReducer, 0);
  const total = countPrevMonths + countThisMonth;
  console.log({ date, m, countThisMonth, countPrevMonths, total });
  return total;
}

export const getMonthIdxFromDayNum = (year, dayNum) => {
  const months = getDaysByMonth(year);
  let count = 0;
  for (let i = 0; i < months.length - 1; i++) {
    if (count + months[i] > dayNum) return i;
    count += months[i];
  }
}

// this sums the number of days in each month prior to the current month.
// we can subtract this from raw dayNumber to get the date of the month
export const getDayNumOffset = (date, dayNum) => {
  // console.log('getDayNumOffset', { date, dayNum })
  const year = date.getFullYear();
  const months = getDaysByMonth(year);
  const monthNum = getMonthIdxFromDayNum(year, dayNum);
  const monthsBefore = months.slice(0, monthNum);
  const daysBefore = sumReducer(monthsBefore);
  console.log({ date, dayNum, months, monthNum, monthsBefore, daysBefore });
  return daysBefore;
}

export const getDayNameOffset = (year, month) => {
  const d = new Date(`${year}`);
  const thisMonth = d.setMonth(month);
  return new Date(thisMonth).getDay();
}

export const filledArray = (length) => Object.keys([...Array(length)]);

export const getDayNameFromIndex = (index, offset) => dayNames[(index + offset) % 7];
export const getShortDayNameFromIndex = (index, offset) => dayNamesShort[(index + offset) % 7];

export const getStartOfDayTime = (date = new Date()) => {
  const secInDay = getSecondsInTimeframe(tf.day);
  return Math.floor(
    date.getTime() / (secInDay * 1000)
  ) * 1000;
}

// get the epoch time at the start of current day
export const getStartOfDayEpoch = (date = new Date()) => {
  const secInDay = getSecondsInTimeframe(tf.day);
  return Math.floor(
    date.getTime() / (secInDay * 1000) // getTime => epoch in ms
  ) * (secInDay * 1000);
}

export const suffixDate = (date) => {
  const ds = `${date}`; // date num as string
  const last = ds[ds.length - 1];
  const secondLast = ds[ds.length - 2] || null;

  if (!secondLast || secondLast !== '1') {
    switch (last) {
      case '1':
        return [ds, 'st'];
      case '2':
        return [ds, 'nd'];
      case '3':
        return [ds, 'rd'];
      default:
        return [ds, 'th'];
    }
  } else {
    return [ds, 'th'];
  }
}

// // count of sub units, and number of seconds in each sub unit
export const timeframes = (
  date = new Date(),
) => {

  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonths = getdaysInMonth(year, month);

  // pairs of the number of sub-units in each unit, and the length of each sub, in seconds
  // hrs in day, days in week, there are prefixed variations when multiple are common
  // -- month_week to use weeks as sub-unit of month, year_week to split year into weeks
  return {
    minutes: [60, 1],
    hours: [60, 60],
    day: [24, 60 * 60],
    week: [7, 24 * 60 * 60],
    month: [daysInMonths, 24 * 60 * 60],
    month_week: [Math.ceil(daysInMonths / 7), 24 * 60 * 60],
    // year: [daysInMonths, 24 * 60 * 60],
    // years: [12, daysPerMonth(year)[month] * 24 * 60 * 60],
    year_day: [year % 4 === 0 ? 365 : 364, 24 * 60 * 60],
    year_week: [52, 7 * 24 * 60 * 60],
    year: [12, daysInMonths * 24 * 60 * 60],

  }
};

// don't use this for month-related as they vary on non/leap-year
export const tf = timeframes();

// const calcWindowOffset = (date, viewMode, offset) => {
//   console.log({ viewMode });
//   const addFn = () => {
//     switch (viewMode) {
//       case 'month':
//         return ''
//     }
//   }
// }

export const getSecondsInTimeframe = (timeframe, date) => {
  // const timeframe = timeframes(date)[timeframe];
  return timeframe[0] * timeframe[1]
};


// // buttons to chose mode

// // maps button options to timeframes
// const views = ({ date }) => viewModes.map(({ label, key }) => {
//   const timeframe = timeframes({ date })[key];
//   const [count, seconds] = timeframe;
//   return { key, count, seconds };
// });

// console.log({ views: views() });

// convert hr => hour, etc
export const unitToTimeframeKey = (unit) => {
  const measure = Object.entries(UNITS).find(([k, v]) => v === unit);
  // console.log({ measure });
  return measure[0];
}

// get the number of seconds in, for ex "hr"
export const getTimeFrameFromUnit = (unit) => timeframes()[unitToTimeframeKey(unit)];

const resolveTimeField = (f = {}) => {
  const { unit, value } = f;
  if (!(unit && value)) return NaN;

  const [count, seconds] = getTimeFrameFromUnit(unit);
  const secsInUnit = count * seconds;
  return +value * secsInUnit;
}

export const secondsToTimeString = (seconds) => {
  const [count, secs] = getTimeFrameFromUnit('hr');
  const secsInHr = count * secs;
  const hrs = Math.floor(seconds / secsInHr);
  const mins = Math.round((seconds - (hrs * secsInHr)) / 60);

  const h = (hrs !== 0) ? `${mins === 60 ? hrs + 1 : hrs}h` : '';
  const m = mins && (mins !== 60 && mins !== 0) ? `${mins}m` : '';

  // console.log({ h, m });
  return `${h}${m}`;
}

// // const divisionsForTimeframe = (timeframe) => {
// //   const idx = Object.entries(timeframes).findIndex(([k, v]) => k === timeframe);
// //   const divisions = Object.entries(timeframes)[idx - 1];
// //   console.log({ idx, divisions });
// //   return divisions;
// // }

const createDateOffset = (viewMode, idx) => {
  // console.log('createDateOffset', { viewMode, idx });
  // const d = startOfYear
  const offsetStart = new Date(startOfYear);
  // new Date(d.setDate(0));

  // const [count, seconds] = timeframes(now)[viewMode];
  const thisTf = tf[viewMode];
  console.log({ thisTf })
  const [count, seconds] = thisTf;
  const timeOffset = count * seconds;
  // console.log('createDateOffset', { viewMode, count, seconds });
  offsetStart.setSeconds(offsetStart.getSeconds() + (idx * timeOffset))
  return offsetStart;
}



// const labelFormatter = (viewMode, idx) => {
//   const withOffset = createDateOffset(viewMode, idx);

//   switch (viewMode) {
//     case 'hour':
//       return (
//         <Box className={`divider-header ${viewMode}`}>
//           <Box className="sub-title">{withOffset.getHours()}</Box>
//           {/* <Box>{suffixDate('' + withOffset.getDate())}</Box> */}
//         </Box>
//       );
//     case 'day':
//       return (
//         <Box className={`divider-header ${viewMode}`}>
//           <Box className="sub-title">{dayNames[withOffset.getDay()]}</Box>
//           <Box>{suffixDate('' + withOffset.getDate())}</Box>
//         </Box>
//       );
//     case 'week':
//       return (
//         <Box className={`divider-header ${viewMode}`}>
//           <Box className="sub-title">Week {idx}</Box>
//           {/* <Box>{suffixDate('' + withOffset.getDate())}</Box> */}
//         </Box>
//       );
//     case 'month':
//       return (
//         <Box className={`divider-header ${viewMode}`}>
//           <Box className="sub-title">{monthNames[withOffset.getMonth()]}</Box>
//         </Box>
//       );
//     case 'year':
//       return (
//         <Box className={`divider-header ${viewMode}`}>
//           <Box className="sub-title">{withOffset.getFullYear()}</Box>
//           {/* <Box>{suffixDate('' + withOffset.getDate())}</Box> */}
//         </Box>
//       );

//     default:
//       return (
//         <Box className="divider-header unknown">
//           <Box className="sub-title">UNKNOWN viewMode passed to labelFormatter</Box>
//           {/* <Box>{suffixDate('' + withOffset.getDate())}</Box> */}
//         </Box>
//       )
//   }
// }



/* source: water-1729771290144    - find node with this id,
                                    and entry in nodes_data under this key.
id: reactflow__edge-water-1729771290144-handle-bottom-right-source-washback-1729771285846-handle-top-left-source    - find entry in nodes_data under this key.
*/
const resolveUpstream = ({ upstream, nodes_data, nodes }) => upstream.map(
  ({ source, id: edgeId }) => {
    const upstreamNode = nodes.find(({ id: nId }) => nId === source);
    const nodeFormData = nodes_data[upstreamNode.id];
    const edgeFormData = nodes_data[edgeId];

    // return just these keys (same as root node, don't need rest)
    const resolvedInput = {
      id: upstreamNode.id,
      ...upstreamNode.data, // spread category, icon, label, ...
      data: {
        // the actual form data from the node
        ...nodeFormData,
        // the form data from the edge (only offset & quantity atm). 
        // nested because nodes_data may have quantity
        edgeConfig: edgeFormData,
      },
    };

    console.log(`resolveUpstream:${source}`, { resolvedInput });
    return resolvedInput;
  }
);

// for each process node, lookup its upstream inputs and 
// upstream connected edges as they can both have relevant data.
// ie from design:
// the circular steps in expanded view,
// - 'water' added, 'yeast' added, come from the upstream nodes .data.label
// - '60 degrees', '2 kg', come from its 
const resolveNode = ({ node, nodes, edges, nodes_data }) => {
  const { id, data } = node;

  if (data.category !== 'process') return null;

  // all connected edges. we need upstream to resolve our inputs & edge config
  // and downstream to find the next process in the design.
  const nodeEdges = edges.filter(({ target, source }) =>
    target === id || source === id
  );
  const upstream = nodeEdges.filter(({ target }) => target === id);
  const downstream = nodeEdges.filter(({ source }) => source === id);
  // get a list of ids of downstream process nodes 
  // (likely to only be one, but maybe more?)
  const nextSteps = downstream
    .map(({ target }) => {
      const targetNode = nodes.find(({ id: nId }) => nId === target);
      // again, only show process nodes on schedule
      return targetNode.data.category === 'process' ? targetNode.id : null;
    })
    .filter((nId) => Boolean(nId));

  const resolvedUpstream = resolveUpstream({ upstream, nodes_data, nodes });
  console.log(`resolveNode:${id}`, { data, resolvedUpstream, nextSteps });

  const resolved = {
    id,
    ...data,
    data: nodes_data?.[id] || {},
    inputs: resolvedUpstream,
    next: nextSteps,
  };

  return resolved;
}


const orderSteps = (steps) => {
  const last = steps.find((s) => s.next.length === 0);
  let rest = steps.filter((s) => s.id !== last.id);
  let ordered = [last];

  while (rest.length > 0) {
    const prevStep = rest.find((r) => r.next.includes(ordered[0]?.id));
    ordered = [prevStep, ...ordered];
    rest.pop();

    // console.log({ i: rest.length, search: ordered[0].id, found: prevStep.next });
  }

  // console.log({ steps, ordered });
  return ordered;
}

const appendOffsetToSteps = (steps) => {
  let offset = 0;

  return steps.filter((s) => s).map((s) => {
    s.offset = offset;
    const additional = resolveTimeField(s.data?.duration);
    offset = isNaN(additional) ? offset : offset + additional;
    return s;
  });
}


export const parseRecipe = (recipe) => {
  const { sku_id, recipe_name, nodes, edges, nodes_data } = recipe;

  console.log(`${sku_id}:${nodes.length} nodes, ${edges.length} edges`);

  const steps = nodes
    .map((node) => resolveNode({ node, nodes, edges, nodes_data }))
    .filter((n) => Boolean(n));

  console.log({ steps })

  // chain together based on .next
  const ordered = orderSteps(steps);

  console.log({ ordered });
  // append .offset key with total seconds since start of recipe 
  // based of sum of previous steps .duration
  const orderedWithOffset = appendOffsetToSteps(ordered);

  console.log({ orderedWithOffset });

  const ret = {
    sku_id,
    recipe_name,
    steps: orderedWithOffset,
  };

  // console.log({ ret });
  return ret;
}

