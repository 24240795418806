import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Instructions from '@pw/components/Instructions';
import DependentSKU from '@pw/components/SKUSelector/modals/forms/DependentSKU';

function DependentSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_description } = item ?? {};

	return (
		<ModalWithClose title={sku_name} open={open} onClose={() => onClose()}>
			<Instructions>{sku_description}</Instructions>
			<DependentSKU item={item ?? {}} onClose={onClose} />
		</ModalWithClose>
	);
}

export default DependentSKUModal;
