import * as yup from 'yup';

import Box from '@mui/material/Box';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import LookupSKU from '../LookupSKU';
import {
  WEIGHT_UNIT_OPTIONS,
} from '@pw/consts/measures';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';

const HammerMillForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const { quantity = { value: '', unit: '' }, grain_size, sku } = nodeData;
  const changeSet = {
    quantity: unitField(quantity),
    grain_size: [grain_size || '', yup.string()],
    sku: [sku || '', yup.string()]
  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <UnitField
        label='Quantity'
        name='quantity'
        options={WEIGHT_UNIT_OPTIONS}
      />
      <Box className='input-root'>
        <FormikSelect
          name='grain_size'
          label='Grain Size'
          options={[
            { label: 'Finest', value: 'finest' },
            { label: 'Fine', value: 'fine' },
            { label: 'Medium', value: 'medium' },
            { label: 'Coarse', value: 'coarse' },
          ]}
        />
      </Box>

      {/* TODO: GAZ how to search within expiring items?? */}
      <LookupSKU type='grain' />

      {/* start & end times to be recorded with operation nodes [start, stop] */}
    </NodeModalForm>
  )
}

export default HammerMillForm;
