import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function Confirm({
	children,
	onConfirm,
	onCancel,
	confirmText = 'Yes',
	cancelText = 'No',
	title = 'Are you sure?',
	open = false,
}) {
	return (
		<Dialog
			sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
			maxWidth='xs'
			open={open}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent dividers>{children}</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={onCancel} variant='outlined'>
					{cancelText}
				</Button>
				<Button variant='contained' onClick={onConfirm}>
					{confirmText}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
