import Groq from 'groq-sdk';
import { useCallback } from 'react';
import { GROQ_API_KEY } from '@pw/consts/config';

const useLilly = () => {
	const groq = new Groq({
		apiKey: GROQ_API_KEY,
		dangerouslyAllowBrowser: true,
	});


	const chat = useCallback(
		async (spec, request) => {
			console.log('Initializing with', spec, request);
			try {
				const response = await groq.chat.completions.create({
					//
					// Required parameters
					//
					messages: [
						// Set an optional system message. This sets the behavior of the
						// assistant and can be used to provide specific instructions for
						// how it should behave throughout the conversation.
						{
							role: 'system',
							content: `Output raw json using the specification: ${JSON.stringify(spec)} with no context help`,
						},
						// Set a user message for the assistant to respond to.
						{
							role: 'user',
							content: request,
						},
					],
					// The language model which will generate the completion.
					model: 'llama-3.1-70b-versatile',
					// //
					// // Optional parameters
					// //
					// // Controls randomness: lowering results in less random completions.
					// // As the temperature approaches zero, the model will become deterministic
					// // and repetitive.
					// temperature: 0.5,
					// // The maximum number of tokens to generate. Requests can use up to
					// // 2048 tokens shared between prompt and completion.
					// max_tokens: 1024,
					// // Controls diversity via nucleus sampling: 0.5 means half of all
					// // likelihood-weighted options are considered.
					// top_p: 1,
					// // A stop sequence is a predefined or user-specified text string that
					// // signals an AI to stop generating content, ensuring its responses
					// // remain focused and concise. Examples include punctuation marks and
					// // markers like "[end]".
					// stop: null,
					// // If set, partial message deltas will be sent.
					// stream: false
					response_format: { "type": "json_object" },
				});
				let content = response.choices[0]?.message?.content || '{}';
				content = content.replaceAll('`', '');
				if (content.startsWith('json')) {
					content = content.substring(content.indexOf('{'));
					// Also remove any trailing text
					content = content.substring(0, content.lastIndexOf('}') + 1);
				}
				console.log(`Parsing [${content}]`);
				return JSON.parse(content);
			} catch (e) {
				console.log('Failed to convert!', e);
				return {};
			}
		},
		[groq],
	);

	return { chat };
};

export default useLilly;
