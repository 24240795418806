import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import BaseAvatar from '@pw/components/Avatar/BaseAvatar';
import { Caption } from '@pw/components/Typography';
import { txt_color_1 } from '@pw/styles/colors';
import { f6 } from '@pw/styles/fonts';
import { ml2, mr2 } from '@pw/styles/margins';

const styles = {
	root: {
		...f6,
		display: 'flex',
		alignItems: 'center',
	},
	link: {
		...txt_color_1,
	},
	avatar: {
		width: 24,
		height: 24,
		fontSize: 14,
	},
};

function SmallAvatar({ sx = {}, src = '', name, leading = false, linked = true, withName = true }) {
	const textSx = {
		...styles.link,
		...(leading ? mr2 : ml2),
		...{ order: leading ? -1 : 1 },
	};
	return (
		<Box
			sx={{ ...styles.root, ...sx }}
			onClick={(e) => (linked ? e.stopPropagation() : null)}
			component={linked ? Link : 'div'}
			href={linked ? `/u/${name}` : null}
		>
			<BaseAvatar sx={styles.avatar} src={src} alt={name} />
			{withName && <Caption sx={textSx}>{name}</Caption>}
		</Box>
	);
}

SmallAvatar.defaultProps = {
	sx: undefined,
	src: undefined,
	name: undefined,
	leading: false,
};

export default SmallAvatar;
