/* eslint-disable no-restricted-syntax */
import { useFormik } from 'formik';
import * as yup from 'yup';

export function processChangeSet(fields = {}) {
	const initialValues = {};
	const validationSchema = {};

	try {
		for (const [field, values] of Object.entries(fields)) {
			if (values instanceof Array) {
				const [value, validation] = values;
				// console.log('Processing', field, value, validation);

				if (validation) {
					initialValues[field] = value;
					validationSchema[field] = validation;
					// console.log('Field', field, value);
				} else {
					const { validationSchema: vs } = processChangeSet(value);
					initialValues[field] = [];
					validationSchema[field] = yup.array().of(vs);
					// console.log('Nested', field, value, iv, vs);
				}
			} else {
				const { initialValues: value, validationSchema: validation } =
					processChangeSet(values);
				initialValues[field] = value;
				validationSchema[field] = validation;
			}
		}
	} catch (e) {
		console.error('Error processing', e);
	}
	return {
		initialValues,
		validationSchema: yup.object().shape(validationSchema),
	};
}

export function useFormikUtils(formikOptions = {}) {
	const { initialValues, validationSchema } = processChangeSet(formikOptions.changeSet);
	const formik = useFormik({
		...formikOptions,
		initialValues,
		validationSchema,
	});

	const withErrors = (field) => {
		if (Object.keys(formik.errors).length) console.log(formik.errors);
		return {
			error: formik.touched[field] && Boolean(formik.errors[field]),
			helperText: formik.touched[field] && formik.errors[field],
		};
	};

	return [formik, withErrors];
}
