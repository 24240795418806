import materialsUsedReportFields from './materialsUsedReportFields';

export default function materialsUsedFields(fields = {}) {
	const {
		cornFields = {},
		ryeFields = {},
		maltFields = {},
		wheatFields = {},
		sorghumGrainFields = {},
		barleyFields = {},
		specifyFields1 = {},
		specifyFields2 = {},
		specifyFields3 = {},
		specifyFields4 = {},
		specifyFields5 = {},
		specifyFields6 = {},
		specifyFields7 = {},
		specifyFields8 = {},
		maolassesFields = {},
		ethylSulfateFields = {},
		ethyleneGasFields = {},
		sulphiteLiquorsFields = {},
		butaneGasFields = {},
	} = fields;

	return {
		'1_Corn': materialsUsedReportFields(cornFields),
		'2_Rye': materialsUsedReportFields(ryeFields),
		'3_Malt': materialsUsedReportFields(maltFields),
		'4_Wheat': materialsUsedReportFields(wheatFields),
		'5_SorghumGrain': materialsUsedReportFields(sorghumGrainFields),
		'6_Barley': materialsUsedReportFields(barleyFields),
		'7_Specify': materialsUsedReportFields(specifyFields1),
		'8_Specify': materialsUsedReportFields(specifyFields2),
		'9_Grape': materialsUsedReportFields(specifyFields3),
		'10_Specify': materialsUsedReportFields(specifyFields4),
		'11_Specify': materialsUsedReportFields(specifyFields5),
		'12_Specify': materialsUsedReportFields(specifyFields6),
		'13_Specify': materialsUsedReportFields(specifyFields7),
		'14_Specify': materialsUsedReportFields(specifyFields8),
		'15_Maolasses': materialsUsedReportFields(maolassesFields),
		'16_Specify': materialsUsedReportFields(specifyFields8),
		'17_Specify': materialsUsedReportFields(specifyFields8),
		'18_Specify': materialsUsedReportFields(specifyFields8),
		'19_EthylSulfate': materialsUsedReportFields(ethylSulfateFields),
		'20_EthyleneGas': materialsUsedReportFields(ethyleneGasFields),
		'21_SulphiteLiquors': materialsUsedReportFields(sulphiteLiquorsFields),
		'22_ButaneGas': materialsUsedReportFields(butaneGasFields),
		'23_Specify': materialsUsedReportFields(specifyFields8),
	};
}
