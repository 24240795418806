import { clientFields } from '@pw/components/Client/clientFields';
import * as yup from 'yup';

function policyFields(params = {}) {
  return {
    policy_name: [
      params?.policy_name ?? '',
      yup.string().required('Name is required!'),
    ],
    policy_status: [
      params?.policy_status ?? '0',
      yup.string().required('Status is required!'),
    ],
    policy_stage: [
      params?.policy_stage ?? '',
      yup.string().required('Stage is required!'),
    ],
    entries: [params?.entries ?? [], yup.array().of(yup.object()).min(1, 'Entry is required')],
    client: clientFields(params?.client),
    requests: [params?.requests ?? [], yup.array()],
  };
}

export default policyFields;
