import { Box } from "@mui/material";

const Divider = ({ width, offset = 0, index, children, className = '', sx, ...rest }) => {
  return (
    <Box
      className={`divider-item ${className}`}
      sx={{ '--width': width, '--offset': offset, ...sx }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default Divider;