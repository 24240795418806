import Stack from '@mui/material/Stack';
import AirQualityWidget from '@pw/components/Dashboard/components/AirQualityWidget';
import HumidityWidget from '@pw/components/Dashboard/components/HumidityWidget';
import TemperatureWidget from '@pw/components/Dashboard/components/TemperatureWidget';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';

function SensorWidget({ title = 'Monitoring', icon = null, data = {} }) {
	const { temperature = 0, humidity = 0, iaq = 0 } = data?.latest ?? {};

	const parsed = (data?.graph ?? []).reduce(
		(acc, item) => {
			acc.temperature.push(parseFloat(item.temperature));
			acc.humidity.push(parseFloat(item.humidity));
			acc.iaq.push(parseFloat(item.iaq));
			// acc.battery_level.push(parseFloat(item.battery_level));
			// acc.pressure.push(parseFloat(item.pressure));
			// acc.binned_timestamp.push(new Date(item.binned_timestamp));
			// acc.gas_resistance.push(parseFloat(item.gas_resistance));
			return acc;
		},
		{
			temperature: [],
			humidity: [],
			iaq: [],
			// battery_level: [],
			// pressure: [],
			// binned_timestamp: [],
			// gas_resistance: [],
		},
	);
	return (
		<Stack spacing={1}>
			<FlexBox>
				<H5>{title}</H5>
				{icon}
			</FlexBox>
			<Stack spacing={2}>
				<TemperatureWidget data={parsed.temperature} last={temperature} />
				<HumidityWidget data={parsed.humidity} last={humidity} />
				<AirQualityWidget data={parsed.iaq} last={iaq} />
			</Stack>
		</Stack>
	);
}

export default SensorWidget;
