import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import SearchButton from '@pw/components/Search/SearchButton';
import { Body2 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

function InventorySearch({
	name,
	loading = false,
	className = 'field',
	unit = null,
	handler,
	searching,
	InputLabelProps = {},
	...props
}) {
	const { values, withErrors, handleChange, readonly } = useContext(FormikContext);

	return (
		<TextField
			variant='filled'
			type='number'
			InputLabelProps={{ ...InputLabelProps, shrink: true }}
			InputProps={{
				disableUnderline: true,
				endAdornment: (
					<InputAdornment position='end'>
						<FlexBox>
							{searching && <LoadingSmall message='' />}
							{!searching && (
								<>
									{unit && (
										<Tooltip title={unit}>
											<Body2
												sx={{ marginLeft: '0.75rem', fontWeight: '600' }}
											>
												{unit}
											</Body2>
										</Tooltip>
									)}
									<SearchButton
										onClick={() => handler(values)}
										color='secondary'
									/>
								</>
							)}
						</FlexBox>
					</InputAdornment>
				),
				readOnly: readonly,
			}}
			className={className}
			name={name}
			value={values?.[name] ?? ''}
			onChange={handleChange}
			loading={loading || 'false'}
			{...withErrors(name)}
			{...props}
		/>
	);
}

export default InventorySearch;
