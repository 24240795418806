import generalInformationFields from './generalInformationFields';
import part1Fields from './part1Fields';
import part2Fields from './part2Fields';
import part3Fields from './part3Fields';
import * as yup from 'yup';

export default function TTBReport_75Fields(fields = {}, company, companyname) {
	const {
		general_information,
		part2fields,
		part3fields,
		formUrlId = '27309361',
	} = fields;
	const defaultValues = {
		Proprietor_Name: companyname ?? '',
		address1: company?.company_address.address_1 ?? '',
		city: company?.company_address.city ?? '',
		state: company?.company_address.state ?? '',
		zipCode: company?.company_address.post_code ?? '',
	};
	return {
		formUrlId: [formUrlId, yup.string()],
		general_information: generalInformationFields(general_information),
		Part1: part1Fields(defaultValues),
		Part2: part2Fields(part2fields),
		Part3: part3Fields(part3fields),
	};
}
