import * as yup from 'yup';

export default function productionOfWhiskeyReportFields(fields = {}) {
	const { a, b, c, d } = fields;

	return {
		a: [a, yup.number().min(0, 'Kind value cannot be less than 0')],
		b: [
			b,
			yup
				.number()
				.min(0, 'Proof Gallons New Cooperage value cannot be less than 0'),
		],
		c: [
			c,
			yup
				.number()
				.min(0, 'Proof Gallons Used Cooperage value cannot be less than 0'),
		],
		d: [
			d,
			yup
				.number()
				.min(0, 'Proof Gallons Deposited in Tanks value cannot be less than 0'),
		],
	};
}
