function SvgCircleLogo(props) {
	return (
		<svg
			id='CircleLogo_svg__Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 322.8 322.8'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<style>{'.CircleLogo_svg__cls-2{stroke-width:0;fill:#fff}'}</style>
			</defs>
			<circle
				className='CircleLogo_svg__cls-2'
				cx={161.4}
				cy={161.4}
				r={161.4}
			/>
			<circle cx={161.4} cy={161.4} r={113.4} strokeWidth={0} fill='#000' />
			<path
				className='CircleLogo_svg__cls-2'
				d='M200 97H97v103h51.5v-51.5H200V97z'
			/>
			<path
				className='CircleLogo_svg__cls-2'
				d='M174.2 174.2h51.5v51.5h-51.5z'
			/>
		</svg>
	);
}
export default SvgCircleLogo;
