import { useMemo, useCallback, useContext, useState } from 'react';
import { Box, Modal } from '@mui/material';
import { iconFromName, formLookup } from '@pw/components/ProductionDesigner/nodes';

import { H4 } from '@pw/components/Typography';
import DesignerContext from "@pw/context/DesignerContext";
import NodeDataContextProvider from './NodeDataContext';

import styles from './NodeModal.styles';
import { saveDesign, saveDesignBatch, saveDesignObservations } from '@pw/services/production.service';
const skipFields = ['date', 'label'];

const NodeModal = () => {
  const {
    nodesData,
    setNodesData,
    batch,
    mode,
    recipe,
    selectedNode: node,
    setSelectedNode,
    selectedNode,
    setIsLoading,
  } = useContext(DesignerContext);

  const [selfCausedUpdate, setSelfCausedUpdate] = useState(false);

  const nodeData = useMemo(
    () => nodesData[node?.id] || {},
    [nodesData, node?.id]
  );

  const Form = useCallback(
    () => formLookup(node?.data?.form || null),
    [node?.data?.form]
  );

  const appendTimestampedFields = (nodeData, fields, date, label) => {
    const appendedFields = {};
    if (!(fields.length || !date)) return appendedFields;

    fields.forEach(([k, v]) => {
      appendedFields[k] = [
        //  previous entries
        ...(nodeData?.[k]?.length ? nodeData[k] : []),
        // add new one, add nested fields
        {
          date,
          ...(label && { label }),
          value: v,
        }
      ];
    });
    return appendedFields;
  }

  const saveHandlerBatch = (formData, batch, mode, nodeData) => {
    if (!batch.id && batch.path) {
      saveDesignBatch(batch);
    }
    const { date = null, label = null, observation_name, ...values} = formData;
    let fields = [];

    if (mode === 'schedule' && nodeData?.repeat) {
      fields = Object.entries(formData)
        .filter(([k]) => !skipFields.includes(k));
    }
    const datedFields = appendTimestampedFields(formData, fields, date, label);
    const rootFields = Object.entries(formData)
      .filter(([k]) => !skipFields.includes(k) && !datedFields[k])
      .reduce((acc, [k, v]) => ({...acc, [k]: v}), ({}));

    const id = btoa(`${new Date(date).getTime()}-${node.id}`);

    const observationData = {
      id,
      observation_name,
      value: JSON.stringify(values),
      time: new Date(date).getTime(),
      node_id: node.id,
    };
    const requestData = {
      recipe_id: batch.recipe_id,
      batch_id: batch.batch_id,
      observations: [observationData],
    }

    console.log('saveHanlderBatch', {formData, datedFields, rootFields, requestData});
    /*
    observations = [
    {
      value: JSON.stringify(values),
      time: date,
      observation_name: '',
      node_id: node.id,
    }
    ];

    const request = {
      recipe_id: batch.recipe_id,
      batch_id: batch.batch_id,
      observations: [newData],
    }
    */

    saveDesignObservations(requestData);
  };

  const saveHandlerRecipe = (formData, recipe, nodesData, nodeId) => {
    const nodes_data = {
      ...nodesData,
      [nodeId]: {
        ...nodesData?.[nodeId],
        ...formData,
      }
    }
    setNodesData(nodes_data);
    saveDesign({
      ...recipe,
      nodes_data,
    });
  }

  const saveHandler = useCallback((formData) => {
    if (selfCausedUpdate) return;
    setIsLoading(true);

    setSelfCausedUpdate(true);

    if (mode === 'schedule') {
      saveHandlerBatch(formData, batch, mode, nodeData);
    } else {
      saveHandlerRecipe(formData, recipe, nodesData, node?.id);
    }

    setSelectedNode(null);
    setSelfCausedUpdate(false);
    setIsLoading(false);
  }, [selfCausedUpdate, node]);

  if (!node) return null;
  console.log('nodeData', nodeData);

  return (
    <Modal
      disableAutoFocus={true}
      disableRestoreFocus={true}
      open={Boolean(node)}
      sx={styles}
      className='modal-root'
      onClose={() => setSelectedNode(null)}
      slotProps={{
        backdrop: {},
      }}
    >
      <Box className='modal-content'>
        <Box className='header'>
          <Box
            className={`icon-container
          icon-${node.data.category}`}
          >
            {iconFromName(node.data.icon)}
          </Box>
          <Box className=''>
            <H4 className='title'>
              {node.data.label}
              {node.data.category === 'process' && ' Settings'}
            </H4>
          </Box>
        </Box>
        <Box className='form-root'>

          <NodeDataContextProvider
            batch={batch}
            nodeId={selectedNode?.id}
            nodeData={nodeData}
            saveHandler={saveHandler}
          >
            <Form />
          </NodeDataContextProvider>

        </Box>
      </Box >
    </Modal >
  );
};

export default NodeModal;
