import { Sparklines, SparklinesCurve, SparklinesReferenceLine } from 'react-sparklines';

function SparkLinesImpl({ data }) {
	return (
		<Sparklines data={data} margin={10}>
			<SparklinesCurve style={{ fill: 'none' }} color='#41424c' />
			<SparklinesReferenceLine
				type='max'
				style={{
					strokeWidth: 0.5,
					strokeDasharray: '5,5',
					stroke: '#6c6261',
					fill: 'none',
				}}
			/>
			<SparklinesReferenceLine
				type='median'
				style={{
					strokeWidth: 1,
					strokeDasharray: '5,5',
					stroke: '#F0AA29',
					fill: 'none',
				}}
			/>
			<SparklinesReferenceLine
				type='min'
				style={{
					strokeWidth: 0.5,
					strokeDasharray: '5,5',
					stroke: '#61626c',
					fill: 'none',
				}}
			/>
		</Sparklines>
	);
}

export default SparkLinesImpl;
