import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikCheckBox, FormikForm } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetQRCode from '@pw/components/QRImage/AssetQRCode';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { useCallback, useState } from 'react';
import { renderToString } from 'react-dom/server';

import styles from '@pw/styles/modal.styles';
import printElement from '@pw/utilities/printElement';
import * as yup from 'yup';

export function QrCodes(list = []) {
	console.log('list ', list);
	return (
		<Box>
			{list.map((asset) => (
				<AssetQRCode
					asset={{ ...asset, path: asset?.asset_id }}
					key={asset?.asset_id}
				/>
			))}
		</Box>
	);
}

function PrintModal({ open, assets, onClose }) {
	const [printing, setPrinting] = useState(false);

	const changeSet = {
		casks: [true, yup.boolean()],
		ibcs: [true, yup.boolean()],
		pallets: [true, yup.boolean()],
		containers: [true, yup.boolean()],
		tankers: [true, yup.boolean()],
	};

	const handleSubmit = useCallback(
		(values) => {
			const filteredAssets = assets.filter((a) => {
				const asset_type = a?.asset_type?.toString();
				if (asset_type === ASSET_TYPES.cask && values.casks) return true;
				if (asset_type === ASSET_TYPES.ibc && values.ibcs) return true;
				if (asset_type === ASSET_TYPES.pallet && values.pallets) return true;
				if (asset_type === ASSET_TYPES.container && values.containers) return true;
				return !!(asset_type === ASSET_TYPES.tanker && values.tankers);
			});

			console.log('Filtered assets', filteredAssets);

			setPrinting(true);
			const element = renderToString(QrCodes(filteredAssets));
			printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
			setPrinting(false);

			onClose();
		},
		[assets],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Print Asset Tags</H5>
					<IconButton
						onClick={() => onClose()}
						disabled={printing}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<Instructions>Select which asset types to print</Instructions>

					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<FlexBox gap={2} display='flex-wrap'>
							<FormikCheckBox name='casks' label='Casks' />
							<FormikCheckBox name='containers' label='Containers' />
							<FormikCheckBox name='ibcs' label='IBCs' />
							<FormikCheckBox name='pallets' label='Pallets' />
							<FormikCheckBox name='tankers' label='Tankers' />
						</FlexBox>
						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								Print
							</FilledButton>
						</Box>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default PrintModal;
