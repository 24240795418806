import { Divider } from '@mui/material';
import GeneralInformation from './GeneralInformation';
import Signature from './Signature';
import Part1 from './Part1';
import Part2 from './Part2';

export default function TTBReport_11() {
	return (
		<>
			<Divider />
			<GeneralInformation
				name='general_information'
				title='General Information'
			/>
			<Divider />
			<Part1 name='part1' title='Whisky, Brandy, Rum, and Gin (Gallons)' />
			<Divider />
			<Part2
				name='part2'
				title='Vodka, Alcohol, Spirits, Other and Wine (Gallons)'
			/>
			<Divider />
			<Signature name='signature' title='Signature' />
			<Divider />
		</>
	);
}
