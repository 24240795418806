import { clientFields } from '@pw/components/Client/clientFields';
import { SKU_OPTIONS, SKU_TYPES } from '@pw/consts/sku';
import * as yup from 'yup';

function skuSettingsFields(params = {}, skuType = SKU_TYPES.CONSUMABLE) {
	return {
		sku_name: [
			params?.sku_name ?? '',
			yup.string().required('SKU name is required!'),
		],
		sku_type: [
			params?.sku_type ?? SKU_OPTIONS[skuType]?.value,
			yup.string().required('SKU type is required!'),
		],
		sku_description: [
			params?.sku_description ?? '',
			yup.string().required('SKU description is required!'),
		],
		sku_tags: [params?.sku_tags ?? [], yup.array().of(yup.string())],

		// Client who may own this SKU
		client: clientFields(params?.client),

		vendors: [
			params?.vendors ?? [],
			yup.array().of(
				yup.object().shape({
					vendor: yup.object().shape({
						name: yup.string(),
						public_name: yup.string(),
						id: yup.string(),
						hash: yup.string(),
						type: yup.string(),
					}),
					product: yup.string(),
					unit_price: yup.string(),
					lead_time: yup.object().shape({
						value: yup.number(),
						unit: yup.string(),
					}),
				}),
			),
		],
		
		policies: [
			params?.policies ?? [],
			yup.array().of(
				yup.object().shape({
					policy_id: yup.string(),
					policy_name: yup.string(),
				}),
			),
		]
	};
}

export default skuSettingsFields;
