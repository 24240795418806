import ForgotPassword from '@pw/components/Auth/ForgotPassword';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withEmptyLayout } from '@pw/components/Layout/EmptyLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function RecoveryPage() {
	usePageTitleHook('Account Recovery');
	return (
		<ForgotPassword />
	);
}

export default withEmptyLayout(withDialogCard(RecoveryPage));
