import { Google } from '@mui/icons-material';
import { Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { signinWithGoogleThunk } from '@pw/redux/thunks/signin';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function SocialSignin({ account, invite, redirect }) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

	const { ident } = account ?? {};

	const login = async (type) => {
		dispatch(signinWithGoogleThunk({ type, ident, invite, i18n }))
			.unwrap()
			.then(redirect);
	};

	return (
		<Stack spacing={2}>
			<FilledButton icon={<Google />} iconPosition="start" label="Google" handleClick={() => login('google')} />
		</Stack>
	);
}

export default SocialSignin;
