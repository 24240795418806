import objectToQueryString from '@pw/utilities/objectToQueryString';

const useProductionDesignerQuery = (skuId, batchId) => {
  const query = batchId 
    ? objectToQueryString({ skuId, batchId }) 
    : objectToQueryString({ skuId });

  return `/app/production-designer${query}`;
};

export default useProductionDesignerQuery;