// Generated at 10/24/2024, 10:37:29 PM

export const unitsObject = {
  'radian': [0, 1],
  'radians': [0, 1],
  'rad': [0, 1],
  'rads': [0, 1],
  'r': [0, 1],
  'turn': [0, 2 * Math.PI],
  'turns': [0, 2 * Math.PI],
  'degree': [0, Math.PI / 180],
  'degrees': [0, Math.PI / 180],
  'deg': [0, Math.PI / 180],
  'degs': [0, Math.PI / 180],
  '°': [0, Math.PI / 180],
  'gradian': [0, Math.PI / 200],
  'gradians': [0, Math.PI / 200],
  'gon': [0, Math.PI / 200],
  'gons': [0, Math.PI / 200],
  'grad': [0, Math.PI / 200],
  'grads': [0, Math.PI / 200],
  'grade': [0, Math.PI / 200],
  'grades': [0, Math.PI / 200],
  'square meter': [1, 1],
  'square meters': [1, 1],
  'square metre': [1, 1],
  'square metres': [1, 1],
  'm²': [1, 1],
  'm2': [1, 1],
  'square petameter': [1, 1e+30],
  'square petametre': [1, 1e+30],
  'square petameters': [1, 1e+30],
  'square petametres': [1, 1e+30],
  'Pm²': [1, 1e+30],
  'Pm2': [1, 1e+30],
  'square terameter': [1, 1e+24],
  'square terametre': [1, 1e+24],
  'square terameters': [1, 1e+24],
  'square terametres': [1, 1e+24],
  'Tm²': [1, 1e+24],
  'Tm2': [1, 1e+24],
  'square gigameter': [1, 1000000000000000000],
  'square gigametre': [1, 1000000000000000000],
  'square gigameters': [1, 1000000000000000000],
  'square gigametres': [1, 1000000000000000000],
  'Gm²': [1, 1000000000000000000],
  'Gm2': [1, 1000000000000000000],
  'square megameter': [1, 1000000000000],
  'square megametre': [1, 1000000000000],
  'square megameters': [1, 1000000000000],
  'square megametres': [1, 1000000000000],
  'Mm²': [1, 1000000000000],
  'Mm2': [1, 1000000000000],
  'square kilometer': [1, 1000000],
  'square kilometre': [1, 1000000],
  'square kilometers': [1, 1000000],
  'square kilometres': [1, 1000000],
  'km²': [1, 1000000],
  'km2': [1, 1000000],
  'square hectometer': [1, 10000],
  'square hectometre': [1, 10000],
  'square hectometers': [1, 10000],
  'square hectometres': [1, 10000],
  'hm²': [1, 10000],
  'hm2': [1, 10000],
  'square decameter': [1, 100],
  'square decametre': [1, 100],
  'square decameters': [1, 100],
  'square decametres': [1, 100],
  'dam²': [1, 100],
  'dam2': [1, 100],
  'square decimeter': [1, 0.1],
  'square decimetre': [1, 0.1],
  'square decimeters': [1, 0.1],
  'square decimetres': [1, 0.1],
  'dm²': [1, 0.1],
  'dm2': [1, 0.1],
  'square centimeter': [1, 0.0001],
  'square centimetre': [1, 0.0001],
  'square centimeters': [1, 0.0001],
  'square centimetres': [1, 0.0001],
  'cm²': [1, 0.0001],
  'cm2': [1, 0.0001],
  'square millimeter': [1, 0.000001],
  'square millimetre': [1, 0.000001],
  'square millimeters': [1, 0.000001],
  'square millimetres': [1, 0.000001],
  'mm²': [1, 0.000001],
  'mm2': [1, 0.000001],
  'square micrometer': [1, 1e-12],
  'square micrometre': [1, 1e-12],
  'square micrometers': [1, 1e-12],
  'square micrometres': [1, 1e-12],
  'μm²': [1, 1e-12],
  'µm²': [1, 1e-12],
  'μm2': [1, 1e-12],
  'µm2': [1, 1e-12],
  'square nanometer': [1, 1e-18],
  'square nanometre': [1, 1e-18],
  'square nanometers': [1, 1e-18],
  'square nanometres': [1, 1e-18],
  'nm²': [1, 1e-18],
  'nm2': [1, 1e-18],
  'square picometer': [1, 1e-24],
  'square picometre': [1, 1e-24],
  'square picometers': [1, 1e-24],
  'square picometres': [1, 1e-24],
  'pm²': [1, 1e-24],
  'pm2': [1, 1e-24],
  'square femtometer': [1, 1e-30],
  'square femtometre': [1, 1e-30],
  'square femtometers': [1, 1e-30],
  'square femtometres': [1, 1e-30],
  'fm²': [1, 1e-30],
  'fm2': [1, 1e-30],
  'acre': [1, 4046.8564224],
  'acres': [1, 4046.8564224],
  'ac': [1, 4046.8564224],
  'centiare': [1, 1],
  'centiares': [1, 1],
  'ca': [1, 1],
  'deciare': [1, 10],
  'deciares': [1, 10],
  'da': [1, 10],
  'are': [1, 100],
  'ares': [1, 100],
  'decare': [1, 1000],
  'decares': [1, 1000],
  'daa': [1, 1000],
  'hectare': [1, 10000],
  'hectares': [1, 10000],
  'ha': [1, 10000],
  'square foot': [1, 0.09290304],
  'square feet': [1, 0.09290304],
  'sq ft': [1, 0.09290304],
  'ft²': [1, 0.09290304],
  'ft2': [1, 0.09290304],
  'square inch': [1, 0.00064516],
  'square inches': [1, 0.00064516],
  'sq in': [1, 0.00064516],
  'in²': [1, 0.00064516],
  'in2': [1, 0.00064516],
  'square yard': [1, 0.83612736],
  'square yards': [1, 0.83612736],
  'sq yd': [1, 0.83612736],
  'yd²': [1, 0.83612736],
  'yd2': [1, 0.83612736],
  'square mile': [1, 2589988.110336],
  'square miles': [1, 2589988.110336],
  'sq mi': [1, 2589988.110336],
  'mi²': [1, 2589988.110336],
  'mi2': [1, 2589988.110336],
  'bit': [2, 1],
  'bits': [2, 1],
  'b': [2, 1],
  'pebibit': [2, 1125899906842624],
  'pebibits': [2, 1125899906842624],
  'Pib': [2, 1125899906842624],
  'tebibit': [2, 1099511627776],
  'tebibits': [2, 1099511627776],
  'Tib': [2, 1099511627776],
  'gibibit': [2, 1073741824],
  'gibibits': [2, 1073741824],
  'Gib': [2, 1073741824],
  'mebibit': [2, 1048576],
  'mebibits': [2, 1048576],
  'Mib': [2, 1048576],
  'kibibit': [2, 1024],
  'kibibits': [2, 1024],
  'Kib': [2, 1024],
  'Kb': [2, 1000],
  'KB': [2, 8000],
  'petabit': [2, 1000000000000000],
  'petabits': [2, 1000000000000000],
  'Pb': [2, 1000000000000000],
  'terabit': [2, 1000000000000],
  'terabits': [2, 1000000000000],
  'Tb': [2, 1000000000000],
  'gigabit': [2, 1000000000],
  'gigabits': [2, 1000000000],
  'Gb': [2, 1000000000],
  'megabit': [2, 1000000],
  'megabits': [2, 1000000],
  'Mb': [2, 1000000],
  'kilobit': [2, 1000],
  'kilobits': [2, 1000],
  'kb': [2, 1000],
  'hectobit': [2, 100],
  'hectobits': [2, 100],
  'hb': [2, 100],
  'decabit': [2, 10],
  'decabits': [2, 10],
  'dab': [2, 10],
  'decibit': [2, 0.1],
  'decibits': [2, 0.1],
  'db': [2, 0.1],
  'centibit': [2, 0.01],
  'centibits': [2, 0.01],
  'cb': [2, 0.01],
  'millibit': [2, 0.001],
  'millibits': [2, 0.001],
  'mb': [2, 0.001],
  'microbit': [2, 0.000001],
  'microbits': [2, 0.000001],
  'μb': [2, 0.000001],
  'µb': [2, 0.000001],
  'nanobit': [2, 1e-9],
  'nanobits': [2, 1e-9],
  'nb': [2, 1e-9],
  'picobit': [2, 1e-12],
  'picobits': [2, 1e-12],
  'pb': [2, 1e-12],
  'femtobit': [2, 1e-15],
  'femtobits': [2, 1e-15],
  'fb': [2, 1e-15],
  'nibble': [2, 4],
  'nibbles': [2, 4],
  'semioctet': [2, 4],
  'semioctets': [2, 4],
  'halfbyte': [2, 4],
  'halfbytes': [2, 4],
  'byte': [2, 8],
  'bytes': [2, 8],
  'octect': [2, 8],
  'octects': [2, 8],
  'B': [2, 8],
  'pebibyte': [2, 9007199254740992],
  'pebibytes': [2, 9007199254740992],
  'PiB': [2, 9007199254740992],
  'tebibyte': [2, 8796093022208],
  'tebibytes': [2, 8796093022208],
  'TiB': [2, 8796093022208],
  'gibibyte': [2, 8589934592],
  'gibibytes': [2, 8589934592],
  'GiB': [2, 8589934592],
  'mebibyte': [2, 8388608],
  'mebibytes': [2, 8388608],
  'MiB': [2, 8388608],
  'kibibyte': [2, 8192],
  'kibibytes': [2, 8192],
  'KiB': [2, 8192],
  'petabyte': [2, 8000000000000000],
  'petabytes': [2, 8000000000000000],
  'PB': [2, 8000000000000000],
  'terabyte': [2, 8000000000000],
  'terabytes': [2, 8000000000000],
  'TB': [2, 8000000000000],
  'gigabyte': [2, 8000000000],
  'gigabytes': [2, 8000000000],
  'GB': [2, 8000000000],
  'megabyte': [2, 8000000],
  'megabytes': [2, 8000000],
  'MB': [2, 8000000],
  'kilobyte': [2, 8000],
  'kilobytes': [2, 8000],
  'kB': [2, 8000],
  'hectobyte': [2, 800],
  'hectobytes': [2, 800],
  'hB': [2, 800],
  'decabyte': [2, 80],
  'decabytes': [2, 80],
  'daB': [2, 80],
  'decibyte': [2, 0.8],
  'decibytes': [2, 0.8],
  'dB': [2, 0.8],
  'centibyte': [2, 0.08],
  'centibytes': [2, 0.08],
  'cB': [2, 0.08],
  'millibyte': [2, 0.008],
  'millibytes': [2, 0.008],
  'mB': [2, 0.008],
  'microbyte': [2, 0.000008],
  'microbytes': [2, 0.000008],
  'μB': [2, 0.000008],
  'µB': [2, 0.000008],
  'nanobyte': [2, 8e-9],
  'nanobytes': [2, 8e-9],
  'nB': [2, 8e-9],
  'picobyte': [2, 8e-12],
  'picobytes': [2, 8e-12],
  'pB': [2, 8e-12],
  'femtobyte': [2, 8e-15],
  'femtobytes': [2, 8e-15],
  'fB': [2, 8e-15],
  'hextet': [2, 16],
  'hextets': [2, 16],
  'joule': [3, 1],
  'joules': [3, 1],
  'J': [3, 1],
  'petajoule': [3, 1000000000000000],
  'petajoules': [3, 1000000000000000],
  'PJ': [3, 1000000000000000],
  'terajoule': [3, 1000000000000],
  'terajoules': [3, 1000000000000],
  'TJ': [3, 1000000000000],
  'gigajoule': [3, 1000000000],
  'gigajoules': [3, 1000000000],
  'GJ': [3, 1000000000],
  'megajoule': [3, 1000000],
  'megajoules': [3, 1000000],
  'MJ': [3, 1000000],
  'kilojoule': [3, 1000],
  'kilojoules': [3, 1000],
  'kJ': [3, 1000],
  'hectojoule': [3, 100],
  'hectojoules': [3, 100],
  'hJ': [3, 100],
  'decajoule': [3, 10],
  'decajoules': [3, 10],
  'daJ': [3, 10],
  'decijoule': [3, 0.1],
  'decijoules': [3, 0.1],
  'dJ': [3, 0.1],
  'centijoule': [3, 0.01],
  'centijoules': [3, 0.01],
  'cJ': [3, 0.01],
  'millijoule': [3, 0.001],
  'millijoules': [3, 0.001],
  'mJ': [3, 0.001],
  'microjoule': [3, 0.000001],
  'microjoules': [3, 0.000001],
  'μJ': [3, 0.000001],
  'µJ': [3, 0.000001],
  'nanojoule': [3, 1e-9],
  'nanojoules': [3, 1e-9],
  'nJ': [3, 1e-9],
  'picojoule': [3, 1e-12],
  'picojoules': [3, 1e-12],
  'pJ': [3, 1e-12],
  'femtojoule': [3, 1e-15],
  'femtojoules': [3, 1e-15],
  'fJ': [3, 1e-15],
  'watt-hour': [3, 3600],
  'W⋅h': [3, 3600],
  'W h': [3, 3600],
  'Wh': [3, 3600],
  'petawatt-hour': [3, 3600000000000000000],
  'petawatt-hours': [3, 3600000000000000000],
  'PW⋅h': [3, 3600000000000000000],
  'PW h': [3, 3600000000000000000],
  'PWh': [3, 3600000000000000000],
  'terawatt-hour': [3, 3600000000000000],
  'terawatt-hours': [3, 3600000000000000],
  'TW⋅h': [3, 3600000000000000],
  'TW h': [3, 3600000000000000],
  'TWh': [3, 3600000000000000],
  'gigawatt-hour': [3, 3600000000000],
  'gigawatt-hours': [3, 3600000000000],
  'GW⋅h': [3, 3600000000000],
  'GW h': [3, 3600000000000],
  'GWh': [3, 3600000000000],
  'megawatt-hour': [3, 3600000000],
  'megawatt-hours': [3, 3600000000],
  'MW⋅h': [3, 3600000000],
  'MW h': [3, 3600000000],
  'MWh': [3, 3600000000],
  'kilowatt-hour': [3, 3600000],
  'kilowatt-hours': [3, 3600000],
  'kW⋅h': [3, 3600000],
  'kW h': [3, 3600000],
  'kWh': [3, 3600000],
  'hectowatt-hour': [3, 360000],
  'hectowatt-hours': [3, 360000],
  'hW⋅h': [3, 360000],
  'hW h': [3, 360000],
  'hWh': [3, 360000],
  'decawatt-hour': [3, 36000],
  'decawatt-hours': [3, 36000],
  'daW⋅h': [3, 36000],
  'daW h': [3, 36000],
  'daWh': [3, 36000],
  'deciwatt-hour': [3, 360],
  'deciwatt-hours': [3, 360],
  'dW⋅h': [3, 360],
  'dW h': [3, 360],
  'dWh': [3, 360],
  'centiwatt-hour': [3, 36],
  'centiwatt-hours': [3, 36],
  'cW⋅h': [3, 36],
  'cW h': [3, 36],
  'cWh': [3, 36],
  'milliwatt-hour': [3, 3.6],
  'milliwatt-hours': [3, 3.6],
  'mW⋅h': [3, 3.6],
  'mW h': [3, 3.6],
  'mWh': [3, 3.6],
  'microwatt-hour': [3, 0.0036],
  'microwatt-hours': [3, 0.0036],
  'μW⋅h': [3, 0.0036],
  'µW⋅h': [3, 0.0036],
  'μW h': [3, 0.0036],
  'µW h': [3, 0.0036],
  'μWh': [3, 0.0036],
  'µWh': [3, 0.0036],
  'nanowatt-hour': [3, 0.0000036],
  'nanowatt-hours': [3, 0.0000036],
  'nW⋅h': [3, 0.0000036],
  'nW h': [3, 0.0000036],
  'nWh': [3, 0.0000036],
  'picowatt-hour': [3, 3.6e-9],
  'picowatt-hours': [3, 3.6e-9],
  'pW⋅h': [3, 3.6e-9],
  'pW h': [3, 3.6e-9],
  'pWh': [3, 3.6e-9],
  'femtowatt-hour': [3, 3.6e-12],
  'femtowatt-hours': [3, 3.6e-12],
  'fW⋅h': [3, 3.6e-12],
  'fW h': [3, 3.6e-12],
  'fWh': [3, 3.6e-12],
  'newton': [4, 1],
  'newtons': [4, 1],
  'N': [4, 1],
  'petanewton': [4, 1000000000000000],
  'petanewtons': [4, 1000000000000000],
  'PN': [4, 1000000000000000],
  'teranewton': [4, 1000000000000],
  'teranewtons': [4, 1000000000000],
  'TN': [4, 1000000000000],
  'giganewton': [4, 1000000000],
  'giganewtons': [4, 1000000000],
  'GN': [4, 1000000000],
  'meganewton': [4, 1000000],
  'meganewtons': [4, 1000000],
  'MN': [4, 1000000],
  'kilonewton': [4, 1000],
  'kilonewtons': [4, 1000],
  'kN': [4, 1000],
  'hectonewton': [4, 100],
  'hectonewtons': [4, 100],
  'hN': [4, 100],
  'decanewton': [4, 10],
  'decanewtons': [4, 10],
  'daN': [4, 10],
  'decinewton': [4, 0.1],
  'decinewtons': [4, 0.1],
  'dN': [4, 0.1],
  'centinewton': [4, 0.01],
  'centinewtons': [4, 0.01],
  'cN': [4, 0.01],
  'millinewton': [4, 0.001],
  'millinewtons': [4, 0.001],
  'mN': [4, 0.001],
  'micronewton': [4, 0.000001],
  'micronewtons': [4, 0.000001],
  'μN': [4, 0.000001],
  'µN': [4, 0.000001],
  'nanonewton': [4, 1e-9],
  'nanonewtons': [4, 1e-9],
  'nN': [4, 1e-9],
  'piconewton': [4, 1e-12],
  'piconewtons': [4, 1e-12],
  'pN': [4, 1e-12],
  'femtonewton': [4, 1e-15],
  'femtonewtons': [4, 1e-15],
  'fN': [4, 1e-15],
  'dyne': [4, 0.00001],
  'dynes': [4, 0.00001],
  'dyn': [4, 0.00001],
  'pound of force': [4, 4.448222],
  'pound-force': [4, 4.448222],
  'lbf': [4, 4.448222],
  'kip': [4, 4448.2216],
  'klb': [4, 4448.2216],
  'kipf': [4, 4448.2216],
  'klbf': [4, 4448.2216],
  'poundal': [4, 0.138255],
  'poundals': [4, 0.138255],
  'pdl': [4, 0.138255],
  'kilogram-force': [4, 9.80665],
  'kilopond': [4, 9.80665],
  'kiloponds': [4, 9.80665],
  'kgf': [4, 9.80665],
  'kp': [4, 9.80665],
  'tonne-force': [4, 9806.65],
  'metric ton-force': [4, 9806.65],
  'megagram-force': [4, 9806.65],
  'megapond': [4, 9806.65],
  'tf': [4, 9806.65],
  'Mp': [4, 9806.65],
  'meter': [5, 1],
  'meters': [5, 1],
  'metre': [5, 1],
  'metres': [5, 1],
  'm': [5, 1],
  'petameter': [5, 1000000000000000],
  'petametre': [5, 1000000000000000],
  'petameters': [5, 1000000000000000],
  'petametres': [5, 1000000000000000],
  'Pm': [5, 1000000000000000],
  'terameter': [5, 1000000000000],
  'terametre': [5, 1000000000000],
  'terameters': [5, 1000000000000],
  'terametres': [5, 1000000000000],
  'Tm': [5, 1000000000000],
  'gigameter': [5, 1000000000],
  'gigametre': [5, 1000000000],
  'gigameters': [5, 1000000000],
  'gigametres': [5, 1000000000],
  'Gm': [5, 1000000000],
  'megameter': [5, 1000000],
  'megametre': [5, 1000000],
  'megameters': [5, 1000000],
  'megametres': [5, 1000000],
  'Mm': [5, 1000000],
  'kilometer': [5, 1000],
  'kilometre': [5, 1000],
  'kilometers': [5, 1000],
  'kilometres': [5, 1000],
  'km': [5, 1000],
  'hectometer': [5, 100],
  'hectometre': [5, 100],
  'hectometers': [5, 100],
  'hectometres': [5, 100],
  'hm': [5, 100],
  'decameter': [5, 10],
  'decametre': [5, 10],
  'decameters': [5, 10],
  'decametres': [5, 10],
  'dam': [5, 10],
  'decimeter': [5, 0.1],
  'decimetre': [5, 0.1],
  'decimeters': [5, 0.1],
  'decimetres': [5, 0.1],
  'dm': [5, 0.1],
  'centimeter': [5, 0.01],
  'centimetre': [5, 0.01],
  'centimeters': [5, 0.01],
  'centimetres': [5, 0.01],
  'cm': [5, 0.01],
  'millimeter': [5, 0.001],
  'millimetre': [5, 0.001],
  'millimeters': [5, 0.001],
  'millimetres': [5, 0.001],
  'mm': [5, 0.001],
  'micrometer': [5, 0.000001],
  'micrometre': [5, 0.000001],
  'micrometers': [5, 0.000001],
  'micrometres': [5, 0.000001],
  'μm': [5, 0.000001],
  'µm': [5, 0.000001],
  'nanometer': [5, 1e-9],
  'nanometre': [5, 1e-9],
  'nanometers': [5, 1e-9],
  'nanometres': [5, 1e-9],
  'nm': [5, 1e-9],
  'picometer': [5, 1e-12],
  'picometre': [5, 1e-12],
  'picometers': [5, 1e-12],
  'picometres': [5, 1e-12],
  'pm': [5, 1e-12],
  'femtometer': [5, 1e-15],
  'femtometre': [5, 1e-15],
  'femtometers': [5, 1e-15],
  'femtometres': [5, 1e-15],
  'fm': [5, 1e-15],
  'foot': [5, 0.3048],
  'feet': [5, 0.3048],
  'ft': [5, 0.3048],
  '\'': [5, 0.3048],
  'US survey foot': [5, 0.30480060960121920244],
  'US survey feet': [5, 0.30480060960121920244],
  'U.S. survey foot': [5, 0.30480060960121920244],
  'U.S. survey feet': [5, 0.30480060960121920244],
  'inch': [5, 0.0254],
  'inches': [5, 0.0254],
  'in': [5, 0.0254],
  '\"': [5, 0.0254],
  'yard': [5, 0.9144],
  'yards': [5, 0.9144],
  'yd': [5, 0.9144],
  'mile': [5, 1609.344],
  'miles': [5, 1609.344],
  'mi': [5, 1609.344],
  'nautical mile': [5, 1852],
  'nautical miles': [5, 1852],
  'M': [5, 1852],
  'NM': [5, 1852],
  'nmi': [5, 1852],
  'light-year': [5, 9460730472580800],
  'light-years': [5, 9460730472580800],
  'ly': [5, 9460730472580800],
  'pica': [5, 0.0042333],
  'picas': [5, 0.0042333],
  'pc': [5, 0.0042333],
  'point': [5, 0.0003528],
  'points': [5, 0.0003528],
  'gram': [6, 1],
  'grams': [6, 1],
  'g': [6, 1],
  'petagram': [6, 1000000000000000],
  'petagrams': [6, 1000000000000000],
  'Pg': [6, 1000000000000000],
  'teragram': [6, 1000000000000],
  'teragrams': [6, 1000000000000],
  'Tg': [6, 1000000000000],
  'gigagram': [6, 1000000000],
  'gigagrams': [6, 1000000000],
  'Gg': [6, 1000000000],
  'megagram': [6, 1000000],
  'megagrams': [6, 1000000],
  'Mg': [6, 1000000],
  'kilogram': [6, 1000],
  'kilograms': [6, 1000],
  'kg': [6, 1000],
  'hectogram': [6, 100],
  'hectograms': [6, 100],
  'hg': [6, 100],
  'decagram': [6, 10],
  'decagrams': [6, 10],
  'dag': [6, 10],
  'decigram': [6, 0.1],
  'decigrams': [6, 0.1],
  'dg': [6, 0.1],
  'centigram': [6, 0.01],
  'centigrams': [6, 0.01],
  'cg': [6, 0.01],
  'milligram': [6, 0.001],
  'milligrams': [6, 0.001],
  'mg': [6, 0.001],
  'microgram': [6, 0.000001],
  'micrograms': [6, 0.000001],
  'μg': [6, 0.000001],
  'µg': [6, 0.000001],
  'nanogram': [6, 1e-9],
  'nanograms': [6, 1e-9],
  'ng': [6, 1e-9],
  'picogram': [6, 1e-12],
  'picograms': [6, 1e-12],
  'pg': [6, 1e-12],
  'femtogram': [6, 1e-15],
  'femtograms': [6, 1e-15],
  'fg': [6, 1e-15],
  'tonne': [6, 1000000],
  'tonnes': [6, 1000000],
  'metric ton': [6, 1000000],
  'metric tons': [6, 1000000],
  't': [6, 1000000],
  'kilotonne': [6, 1000000000],
  'kilotonnes': [6, 1000000000],
  'kt': [6, 1000000000],
  'megatonne': [6, 1000000000000],
  'megatonnes': [6, 1000000000000],
  'Mt': [6, 1000000000000],
  'gigatonne': [6, 1000000000000000],
  'gigatonnes': [6, 1000000000000000],
  'Gt': [6, 1000000000000000],
  'pound': [6, 453.59237],
  'pounds': [6, 453.59237],
  'lb': [6, 453.59237],
  'lbs': [6, 453.59237],
  'stone': [6, 6350.29318],
  'stones': [6, 6350.29318],
  'st': [6, 6350.29318],
  'ounce': [6, 28.349523125],
  'ounces': [6, 28.349523125],
  'oz': [6, 28.349523125],
  'short ton': [6, 907184.74],
  'short tons': [6, 907184.74],
  'US ton': [6, 907184.74],
  'US tons': [6, 907184.74],
  'long ton': [6, 1016046.9088],
  'long tons': [6, 1016046.9088],
  'imperial ton': [6, 1016046.9088],
  'imperial tons': [6, 1016046.9088],
  'displacement ton': [6, 1016046.9088],
  'displacement tons': [6, 1016046.9088],
  'watt': [7, 1],
  'watts': [7, 1],
  'W': [7, 1],
  'petawatt': [7, 1000000000000000],
  'petawatts': [7, 1000000000000000],
  'PW': [7, 1000000000000000],
  'terawatt': [7, 1000000000000],
  'terawatts': [7, 1000000000000],
  'TW': [7, 1000000000000],
  'gigawatt': [7, 1000000000],
  'gigawatts': [7, 1000000000],
  'GW': [7, 1000000000],
  'megawatt': [7, 1000000],
  'megawatts': [7, 1000000],
  'MW': [7, 1000000],
  'kilowatt': [7, 1000],
  'kilowatts': [7, 1000],
  'kW': [7, 1000],
  'hectowatt': [7, 100],
  'hectowatts': [7, 100],
  'hW': [7, 100],
  'decawatt': [7, 10],
  'decawatts': [7, 10],
  'daW': [7, 10],
  'deciwatt': [7, 0.1],
  'deciwatts': [7, 0.1],
  'dW': [7, 0.1],
  'centiwatt': [7, 0.01],
  'centiwatts': [7, 0.01],
  'cW': [7, 0.01],
  'milliwatt': [7, 0.001],
  'milliwatts': [7, 0.001],
  'mW': [7, 0.001],
  'microwatt': [7, 0.000001],
  'microwatts': [7, 0.000001],
  'μW': [7, 0.000001],
  'µW': [7, 0.000001],
  'nanowatt': [7, 1e-9],
  'nanowatts': [7, 1e-9],
  'nW': [7, 1e-9],
  'picowatt': [7, 1e-12],
  'picowatts': [7, 1e-12],
  'pW': [7, 1e-12],
  'femtowatt': [7, 1e-15],
  'femtowatts': [7, 1e-15],
  'fW': [7, 1e-15],
  'horsepower': [7, 745.699872],
  'mechanical horsepower': [7, 745.699872],
  'hp': [7, 745.699872],
  'pascal': [8, 1],
  'pascals': [8, 1],
  'Pa': [8, 1],
  'petapascal': [8, 1000000000000000],
  'petapascals': [8, 1000000000000000],
  'PPa': [8, 1000000000000000],
  'terapascal': [8, 1000000000000],
  'terapascals': [8, 1000000000000],
  'TPa': [8, 1000000000000],
  'gigapascal': [8, 1000000000],
  'gigapascals': [8, 1000000000],
  'GPa': [8, 1000000000],
  'megapascal': [8, 1000000],
  'megapascals': [8, 1000000],
  'MPa': [8, 1000000],
  'kilopascal': [8, 1000],
  'kilopascals': [8, 1000],
  'kPa': [8, 1000],
  'hectopascal': [8, 100],
  'hectopascals': [8, 100],
  'hPa': [8, 100],
  'decapascal': [8, 10],
  'decapascals': [8, 10],
  'daPa': [8, 10],
  'decipascal': [8, 0.1],
  'decipascals': [8, 0.1],
  'dPa': [8, 0.1],
  'centipascal': [8, 0.01],
  'centipascals': [8, 0.01],
  'cPa': [8, 0.01],
  'millipascal': [8, 0.001],
  'millipascals': [8, 0.001],
  'mPa': [8, 0.001],
  'micropascal': [8, 0.000001],
  'micropascals': [8, 0.000001],
  'μPa': [8, 0.000001],
  'µPa': [8, 0.000001],
  'nanopascal': [8, 1e-9],
  'nanopascals': [8, 1e-9],
  'nPa': [8, 1e-9],
  'picopascal': [8, 1e-12],
  'picopascals': [8, 1e-12],
  'pPa': [8, 1e-12],
  'femtopascal': [8, 1e-15],
  'femtopascals': [8, 1e-15],
  'fPa': [8, 1e-15],
  'bar': [8, 100000],
  'bars': [8, 100000],
  'petabar': [8, 100000000000000000000],
  'petabars': [8, 100000000000000000000],
  'Pbar': [8, 100000000000000000000],
  'terabar': [8, 100000000000000000],
  'terabars': [8, 100000000000000000],
  'Tbar': [8, 100000000000000000],
  'gigabar': [8, 100000000000000],
  'gigabars': [8, 100000000000000],
  'Gbar': [8, 100000000000000],
  'megabar': [8, 100000000000],
  'megabars': [8, 100000000000],
  'Mbar': [8, 100000000000],
  'kilobar': [8, 100000000],
  'kilobars': [8, 100000000],
  'kbar': [8, 100000000],
  'hectobar': [8, 10000000],
  'hectobars': [8, 10000000],
  'hbar': [8, 10000000],
  'decabar': [8, 1000000],
  'decabars': [8, 1000000],
  'dabar': [8, 1000000],
  'decibar': [8, 10000],
  'decibars': [8, 10000],
  'dbar': [8, 10000],
  'centibar': [8, 1000],
  'centibars': [8, 1000],
  'cbar': [8, 1000],
  'millibar': [8, 100],
  'millibars': [8, 100],
  'mbar': [8, 100],
  'microbar': [8, 0.1],
  'microbars': [8, 0.1],
  'μbar': [8, 0.1],
  'µbar': [8, 0.1],
  'nanobar': [8, 0.0001],
  'nanobars': [8, 0.0001],
  'nbar': [8, 0.0001],
  'picobar': [8, 1e-7],
  'picobars': [8, 1e-7],
  'pbar': [8, 1e-7],
  'femtobar': [8, 1e-10],
  'femtobars': [8, 1e-10],
  'fbar': [8, 1e-10],
  'torr': [8, 133.32236842105263157895],
  'torrs': [8, 133.32236842105263157895],
  'Torr': [8, 133.32236842105263157895],
  'millitorr': [8, 0.13332236842105263158],
  'mTorr': [8, 0.13332236842105263158],
  'atmosphere': [8, 101325],
  'atmospheres': [8, 101325],
  'atm': [8, 101325],
  'pound per square inch': [8, 6894.757],
  'pounds per square inch': [8, 6894.757],
  'psi': [8, 6894.757],
  'lbf/in2': [8, 6894.757],
  'lbf/in²': [8, 6894.757],
  'kelvin': [9, 1],
  'kelvins': [9, 1],
  'K': [9, 1],
  'petakelvin': [9, 1000000000000000],
  'petakelvins': [9, 1000000000000000],
  'PK': [9, 1000000000000000],
  'terakelvin': [9, 1000000000000],
  'terakelvins': [9, 1000000000000],
  'TK': [9, 1000000000000],
  'gigakelvin': [9, 1000000000],
  'gigakelvins': [9, 1000000000],
  'GK': [9, 1000000000],
  'megakelvin': [9, 1000000],
  'megakelvins': [9, 1000000],
  'MK': [9, 1000000],
  'kilokelvin': [9, 1000],
  'kilokelvins': [9, 1000],
  'kK': [9, 1000],
  'hectokelvin': [9, 100],
  'hectokelvins': [9, 100],
  'hK': [9, 100],
  'decakelvin': [9, 10],
  'decakelvins': [9, 10],
  'daK': [9, 10],
  'decikelvin': [9, 0.1],
  'decikelvins': [9, 0.1],
  'dK': [9, 0.1],
  'centikelvin': [9, 0.01],
  'centikelvins': [9, 0.01],
  'cK': [9, 0.01],
  'millikelvin': [9, 0.001],
  'millikelvins': [9, 0.001],
  'mK': [9, 0.001],
  'microkelvin': [9, 0.000001],
  'microkelvins': [9, 0.000001],
  'μK': [9, 0.000001],
  'µK': [9, 0.000001],
  'nanokelvin': [9, 1e-9],
  'nanokelvins': [9, 1e-9],
  'nK': [9, 1e-9],
  'picokelvin': [9, 1e-12],
  'picokelvins': [9, 1e-12],
  'pK': [9, 1e-12],
  'femtokelvin': [9, 1e-15],
  'femtokelvins': [9, 1e-15],
  'fK': [9, 1e-15],
  'fahrenheit': [9, 0.5555555555555556],
  'F': [9, 0.5555555555555556],
  'celsius': [9, 1],
  'C': [9, 1],
  'rankine': [9, 0.5555555555555556],
  'R': [9, 0.5555555555555556],
  'second': [10, 1],
  'seconds': [10, 1],
  's': [10, 1],
  'petasecond': [10, 1000000000000000],
  'petaseconds': [10, 1000000000000000],
  'Ps': [10, 1000000000000000],
  'terasecond': [10, 1000000000000],
  'teraseconds': [10, 1000000000000],
  'Ts': [10, 1000000000000],
  'gigasecond': [10, 1000000000],
  'gigaseconds': [10, 1000000000],
  'Gs': [10, 1000000000],
  'megasecond': [10, 1000000],
  'megaseconds': [10, 1000000],
  'Ms': [10, 1000000],
  'kilosecond': [10, 1000],
  'kiloseconds': [10, 1000],
  'ks': [10, 1000],
  'hectosecond': [10, 100],
  'hectoseconds': [10, 100],
  'hs': [10, 100],
  'decasecond': [10, 10],
  'decaseconds': [10, 10],
  'das': [10, 10],
  'decisecond': [10, 0.1],
  'deciseconds': [10, 0.1],
  'ds': [10, 0.1],
  'centisecond': [10, 0.01],
  'centiseconds': [10, 0.01],
  'cs': [10, 0.01],
  'millisecond': [10, 0.001],
  'milliseconds': [10, 0.001],
  'ms': [10, 0.001],
  'microsecond': [10, 0.000001],
  'microseconds': [10, 0.000001],
  'μs': [10, 0.000001],
  'µs': [10, 0.000001],
  'nanosecond': [10, 1e-9],
  'nanoseconds': [10, 1e-9],
  'ns': [10, 1e-9],
  'picosecond': [10, 1e-12],
  'picoseconds': [10, 1e-12],
  'ps': [10, 1e-12],
  'femtosecond': [10, 1e-15],
  'femtoseconds': [10, 1e-15],
  'fs': [10, 1e-15],
  'minute': [10, 60],
  'minutes': [10, 60],
  'min': [10, 60],
  'hour': [10, 3600],
  'hours': [10, 3600],
  'h': [10, 3600],
  'milliday': [10, 86.4],
  'millidays': [10, 86.4],
  'md': [10, 86.4],
  'day': [10, 86400],
  'days': [10, 86400],
  'd': [10, 86400],
  'week': [10, 604800],
  'weeks': [10, 604800],
  'wk': [10, 604800],
  'fortnight': [10, 1209600],
  'fortnights': [10, 1209600],
  'fn': [10, 1209600],
  'month': [10, 2592000],
  'months': [10, 2592000],
  'mo': [10, 2592000],
  'year': [10, 31536000],
  'years': [10, 31536000],
  'a': [10, 31536000],
  'y': [10, 31536000],
  'yr': [10, 31536000],
  'decade': [10, 315569520],
  'decades': [10, 315569520],
  'dec': [10, 315569520],
  'century': [10, 3155695200],
  'centuries': [10, 3155695200],
  'millennium': [10, 31556952000],
  'millennia': [10, 31556952000],
  'moment': [10, 90],
  'moments': [10, 90],
  'shake': [10, 1e-8],
  'shakes': [10, 1e-8],
  'time unit': [10, 0.001024],
  'TU': [10, 0.001024],
  'svedberg': [10, 1e-13],
  'svedbergs': [10, 1e-13],
  'S': [10, 1e-13],
  'cubic meter': [11, 1],
  'cubic meters': [11, 1],
  'cubic metre': [11, 1],
  'cubic metres': [11, 1],
  'stere': [11, 1],
  'steres': [11, 1],
  'm³': [11, 1],
  'm3': [11, 1],
  'cubic petameter': [11, 1e+45],
  'cubic petameters': [11, 1e+45],
  'Pm3': [11, 1e+45],
  'Pm³': [11, 1e+45],
  'cubic terameter': [11, 1e+36],
  'cubic terameters': [11, 1e+36],
  'Tm3': [11, 1e+36],
  'Tm³': [11, 1e+36],
  'cubic gigameter': [11, 1e+27],
  'cubic gigameters': [11, 1e+27],
  'Gm3': [11, 1e+27],
  'Gm³': [11, 1e+27],
  'cubic megameter': [11, 1000000000000000000],
  'cubic megameters': [11, 1000000000000000000],
  'Mm3': [11, 1000000000000000000],
  'Mm³': [11, 1000000000000000000],
  'cubic kilometer': [11, 1000000000],
  'cubic kilometers': [11, 1000000000],
  'km3': [11, 1000000000],
  'km³': [11, 1000000000],
  'cubic hectometer': [11, 1000000],
  'cubic hectometers': [11, 1000000],
  'hm3': [11, 1000000],
  'hm³': [11, 1000000],
  'cubic decameter': [11, 1000],
  'cubic decameters': [11, 1000],
  'dam3': [11, 1000],
  'dam³': [11, 1000],
  'cubic decimeter': [11, 0.001],
  'cubic decimeters': [11, 0.001],
  'dm3': [11, 0.001],
  'dm³': [11, 0.001],
  'cubic centimeter': [11, 0.000001],
  'cubic centimeters': [11, 0.000001],
  'cm3': [11, 0.000001],
  'cm³': [11, 0.000001],
  'cubic millimeter': [11, 1e-9],
  'cubic millimeters': [11, 1e-9],
  'mm3': [11, 1e-9],
  'mm³': [11, 1e-9],
  'cubic micrometer': [11, 1e-18],
  'cubic micrometers': [11, 1e-18],
  'μm3': [11, 1e-18],
  'µm3': [11, 1e-18],
  'μm³': [11, 1e-18],
  'µm³': [11, 1e-18],
  'cubic nanometer': [11, 1e-27],
  'cubic nanometers': [11, 1e-27],
  'nm3': [11, 1e-27],
  'nm³': [11, 1e-27],
  'cubic picometer': [11, 1e-36],
  'cubic picometers': [11, 1e-36],
  'pm3': [11, 1e-36],
  'pm³': [11, 1e-36],
  'cubic femtometer': [11, 1e-45],
  'cubic femtometers': [11, 1e-45],
  'fm3': [11, 1e-45],
  'fm³': [11, 1e-45],
  'liter': [11, 0.001],
  'liters': [11, 0.001],
  'litre': [11, 0.001],
  'litres': [11, 0.001],
  'l': [11, 0.001],
  'L': [11, 0.001],
  'petaliter': [11, 1000000000000],
  'petaliters': [11, 1000000000000],
  'petalitre': [11, 1000000000000],
  'petalitres': [11, 1000000000000],
  'Pl': [11, 1000000000000],
  'PL': [11, 1000000000000],
  'teraliter': [11, 1000000000],
  'teraliters': [11, 1000000000],
  'teralitre': [11, 1000000000],
  'teralitres': [11, 1000000000],
  'Tl': [11, 1000000000],
  'TL': [11, 1000000000],
  'gigaliter': [11, 1000000],
  'gigaliters': [11, 1000000],
  'gigalitre': [11, 1000000],
  'gigalitres': [11, 1000000],
  'Gl': [11, 1000000],
  'GL': [11, 1000000],
  'megaliter': [11, 1000],
  'megaliters': [11, 1000],
  'megalitre': [11, 1000],
  'megalitres': [11, 1000],
  'Ml': [11, 1000],
  'ML': [11, 1000],
  'kiloliter': [11, 1],
  'kiloliters': [11, 1],
  'kilolitre': [11, 1],
  'kilolitres': [11, 1],
  'kl': [11, 1],
  'kL': [11, 1],
  'hectoliter': [11, 0.1],
  'hectoliters': [11, 0.1],
  'hectolitre': [11, 0.1],
  'hectolitres': [11, 0.1],
  'hl': [11, 0.1],
  'hL': [11, 0.1],
  'decaliter': [11, 0.01],
  'decaliters': [11, 0.01],
  'decalitre': [11, 0.01],
  'decalitres': [11, 0.01],
  'dal': [11, 0.01],
  'daL': [11, 0.01],
  'deciliter': [11, 0.0001],
  'deciliters': [11, 0.0001],
  'decilitre': [11, 0.0001],
  'decilitres': [11, 0.0001],
  'dl': [11, 0.0001],
  'dL': [11, 0.0001],
  'centiliter': [11, 0.00001],
  'centiliters': [11, 0.00001],
  'centilitre': [11, 0.00001],
  'centilitres': [11, 0.00001],
  'cl': [11, 0.00001],
  'cL': [11, 0.00001],
  'milliliter': [11, 0.000001],
  'milliliters': [11, 0.000001],
  'millilitre': [11, 0.000001],
  'millilitres': [11, 0.000001],
  'ml': [11, 0.000001],
  'mL': [11, 0.000001],
  'microliter': [11, 1e-9],
  'microliters': [11, 1e-9],
  'microlitre': [11, 1e-9],
  'microlitres': [11, 1e-9],
  'μl': [11, 1e-9],
  'µl': [11, 1e-9],
  'μL': [11, 1e-9],
  'µL': [11, 1e-9],
  'nanoliter': [11, 1e-12],
  'nanoliters': [11, 1e-12],
  'nanolitre': [11, 1e-12],
  'nanolitres': [11, 1e-12],
  'nl': [11, 1e-12],
  'nL': [11, 1e-12],
  'picoliter': [11, 1e-15],
  'picoliters': [11, 1e-15],
  'picolitre': [11, 1e-15],
  'picolitres': [11, 1e-15],
  'pl': [11, 1e-15],
  'pL': [11, 1e-15],
  'femtoliter': [11, 1e-18],
  'femtoliters': [11, 1e-18],
  'femtolitre': [11, 1e-18],
  'femtolitres': [11, 1e-18],
  'fl': [11, 1e-18],
  'fL': [11, 1e-18],
  'cubic mile': [11, 4200],
  'cubic miles': [11, 4200],
  'cu mi': [11, 4200],
  'mi3': [11, 4200],
  'mi³': [11, 4200],
  'acre-foot': [11, 1233.48183754752],
  'acre-feet': [11, 1233.48183754752],
  'ac⋅ft': [11, 1233.48183754752],
  'ac ft': [11, 1233.48183754752],
  'cubic yard': [11, 0.764554857984],
  'cubic yards': [11, 0.764554857984],
  'cu yd': [11, 0.764554857984],
  'yd3': [11, 0.764554857984],
  'yd³': [11, 0.764554857984],
  'cubic foot': [11, 0.028316846592],
  'cubic feet': [11, 0.028316846592],
  'cu ft': [11, 0.028316846592],
  'ft3': [11, 0.028316846592],
  'ft³': [11, 0.028316846592],
  'board foot': [11, 0.002359737],
  'board feet': [11, 0.002359737],
  'cubic inch': [11, 0.000016387064],
  'cubic inches': [11, 0.000016387064],
  'cu in': [11, 0.000016387064],
  'in3': [11, 0.000016387064],
  'in³': [11, 0.000016387064],
  'measurement ton': [11, 1.133],
  'measurement tons': [11, 1.133],
  'MTON': [11, 1.133],
  'imperial barrel': [11, 0.16],
  'imperial barrels': [11, 0.16],
  'imp bbl': [11, 0.16],
  'imperial bushel': [11, 0.03636872],
  'imperial bushels': [11, 0.03636872],
  'imp bsh': [11, 0.03636872],
  'imp bu': [11, 0.03636872],
  'imperial peck': [11, 0.00909218],
  'imperial pecks': [11, 0.00909218],
  'pk': [11, 0.00909218],
  'imp pk': [11, 0.00909218],
  'imperial gallon': [11, 0.00454609],
  'imperial gallons': [11, 0.00454609],
  'imp gal': [11, 0.00454609],
  'imperial quart': [11, 0.0011365225],
  'imperial quarts': [11, 0.0011365225],
  'imp qt': [11, 0.0011365225],
  'imperial pint': [11, 0.00056826125],
  'imperial pints': [11, 0.00056826125],
  'imp pt': [11, 0.00056826125],
  'imperial fluid ounce': [11, 0.0000284130625],
  'imperial fluid ounces': [11, 0.0000284130625],
  'imp fl oz': [11, 0.0000284130625],
  'teaspoon': [11, 0.00000492892159375],
  'teaspoons': [11, 0.00000492892159375],
  'US teaspoon': [11, 0.00000492892159375],
  'US teaspoons': [11, 0.00000492892159375],
  'tsp': [11, 0.00000492892159375],
  'tablespoon': [11, 0.00001478676478125],
  'tablespoons': [11, 0.00001478676478125],
  'US tablespoon': [11, 0.00001478676478125],
  'US tablespoons': [11, 0.00001478676478125],
  'tbsp': [11, 0.00001478676478125],
  'US fluid ounce': [11, 0.0000295735295625],
  'US fluid ounces': [11, 0.0000295735295625],
  'fl oz': [11, 0.0000295735295625],
  'fl. oz.': [11, 0.0000295735295625],
  'oz. fl.': [11, 0.0000295735295625],
  'cup': [11, 0.0002365882365],
  'cups': [11, 0.0002365882365],
  'c': [11, 0.0002365882365],
  'US legal cup': [11, 0.00024],
  'US legal cups': [11, 0.00024],
  'US lc': [11, 0.00024],
  'pint': [11, 0.000473176473],
  'pints': [11, 0.000473176473],
  'US liquid pint': [11, 0.000473176473],
  'US liquid pints': [11, 0.000473176473],
  'pt': [11, 0.000473176473],
  'p': [11, 0.000473176473],
  'quart': [11, 0.000946352946],
  'quarts': [11, 0.000946352946],
  'US liquid quart': [11, 0.000946352946],
  'US liquid quarts': [11, 0.000946352946],
  'qt': [11, 0.000946352946],
  'gallon': [11, 0.003785411784],
  'gallons': [11, 0.003785411784],
  'US liquid gallon': [11, 0.003785411784],
  'US liquid gallons': [11, 0.003785411784],
  'gal': [11, 0.003785411784],
  'US bushel': [11, 0.03523907016688],
  'US bushels': [11, 0.03523907016688],
  'US bsh': [11, 0.03523907016688],
  'US bu': [11, 0.03523907016688],
  'US peck': [11, 0.00880976754172],
  'US pk': [11, 0.00880976754172],
  'US dry gallon': [11, 0.00440488377086],
  'US dry gal': [11, 0.00440488377086],
  'US dry barrel': [11, 0.1156],
  'US dry barrels': [11, 0.1156],
  'US dry bbl': [11, 0.1156],
  'US dry quart': [11, 0.001101220942715],
  'US dry qt': [11, 0.001101220942715],
  'US dry pint': [11, 0.0005506104713575],
  'US dry pt': [11, 0.0005506104713575],
} as const;

export const differences = {
  'fahrenheit': 459.67,
  'F': 459.67,
  'celsius': 273.15,
  'C': 273.15,
} as const;