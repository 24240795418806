import { Stack } from '@mui/material';
import styles from './menu.styles';

function Menu({ children }) {
	return (
		<Stack sx={styles} className='root' spacing='0.25rem'>
			{children}
		</Stack>
	);
}

export default Menu;
