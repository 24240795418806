const toLocation = (location) => {
	const [facility_type = '0', facility_name = '0', bay = '0', row = '0', level = '0'] =
		location?.split('/') ?? [];
	const facility_id = [facility_type, facility_name].join('/');
	return {
		facility_id,
		bay,
		row,
		level,
	};
};

export default toLocation;
