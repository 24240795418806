function SvgLocation(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 29.69 29.69'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				className='location_svg__cls-2'
				fill='none'
				d='M15.74 0h13.71c.2 0 .24.04.24.24v29.21c0 .2-.04.24-.24.24H.01C0 19.88 0 10.07 0 .27 0 .05.05 0 .27 0h13.56c0 .15-.13.12-.21.13-1.14.15-2.23.45-3.26.95-.75.36-1.45.81-2.09 1.34-1.05.86-1.9 1.88-2.55 3.07-.65 1.19-1.04 2.46-1.19 3.81-.25 2.34.33 4.53 1.23 6.66 1.08 2.56 2.58 4.89 4.14 7.17 1.31 1.92 2.7 3.78 4 5.7.08.11.15.22.23.33.4.51.97.51 1.36 0 .39-.52.74-1.08 1.12-1.61 1.38-1.96 2.8-3.9 4.12-5.9.94-1.44 1.84-2.92 2.59-4.47.77-1.58 1.39-3.22 1.68-4.97.34-2.09.11-4.11-.77-6.04-1.02-2.24-2.65-3.91-4.83-5.02C18.34.61 17.2.29 16.01.14c-.1-.01-.24.04-.27-.13z'
			/>
			<path
				className='location_svg__cls-3'
				d='M14.58 0a.33.33 0 01-.4 0h.4zM15.45 0a.33.33 0 01-.4 0h.4z'
			/>
			<path
				className='location_svg__cls-1'
				d='M14.82 5.21c-2.88 0-5.21 2.32-5.21 5.2a5.2 5.2 0 005.21 5.21 5.2 5.2 0 005.21-5.21c0-2.88-2.32-5.21-5.2-5.21zm-.01 8.68c-1.9 0-3.47-1.59-3.46-3.49 0-1.89 1.6-3.47 3.5-3.45 1.89.02 3.45 1.59 3.44 3.47 0 1.9-1.59 3.47-3.48 3.47z'
			/>
			<path
				className='location_svg__cls-1'
				d='M24.93 7.98c-1.11-4.12-3.77-6.7-7.91-7.74-.42-.11-.85-.16-1.28-.24h-.29c-.13.05-.27.05-.4 0h-.46c-.13.05-.27.05-.4 0h-.35c-.15.12-.34.08-.52.1-3.26.52-5.76 2.19-7.45 5.01-1.6 2.65-1.82 5.5-1.02 8.45.63 2.31 1.71 4.41 2.94 6.45 1.93 3.2 4.22 6.15 6.32 9.24.36.53 1.08.52 1.44-.02 1.72-2.53 3.57-4.97 5.24-7.53 1.39-2.13 2.67-4.31 3.55-6.71.84-2.28 1.24-4.62.6-7.01zm-2.09 6c-.78 2.31-1.99 4.4-3.3 6.44-1.45 2.26-3.06 4.41-4.59 6.61-.07.1-.12.2-.24.03-2.15-3.1-4.45-6.09-6.27-9.41-.88-1.61-1.62-3.28-2.04-5.08C5.27 7.83 8.06 3.19 12.75 2c.34-.09.7-.15 1.05-.19.34-.04.69-.05.9-.07 4.23.01 7.81 2.94 8.62 6.93.37 1.82.11 3.58-.47 5.31z'
			/>
		</svg>
	);
}
export default SvgLocation;
