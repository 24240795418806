import { authenticateCredentials, authenticationOptions, signin } from '@pw/services/auth.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { startAuthentication } from '@simplewebauthn/browser';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { handleSignin } from './utils/handleSignin';

export const signinUserThunk = createAsyncThunk(
  `signin/signinUserThunk`,
  async ({ i18n, ...credentials }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      // This has to be loaded dynamically to avoid circular dependency
      const response = await signin(credentials);
      const { verified = false } = response ?? {};
      if (!verified) {
        return rejectWithValue('Failed to sign in!');
      }

      return handleSignin(i18n, response, fulfillWithValue, rejectWithValue, dispatch);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const signinWithPasskeyThunk = createAsyncThunk(
  `signin/signinWithPasskeyThunk`,
  async ({ i18n, type, ident, invite }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const id = {
        type,
        ident,
      };


      console.log('identity', id);
      const opt = await authenticationOptions(id);
      console.debug('Options', opt);
      const reg = await startAuthentication(opt);
      console.debug('Authentication', reg);

      const response = await authenticateCredentials({
        ...id,
        token: reg,
        invite,
      });
      const { verified = false } = response ?? {};
      if (!verified) {
        return rejectWithValue('Passkey not recognized. Please first sign in using another method and then create a passkey for your account!');
      }

      return handleSignin(i18n, response, fulfillWithValue, rejectWithValue, dispatch);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const signinWithGoogleThunk = createAsyncThunk(
  `signin/signinWithGoogleThunk`,
  async ({ i18n, type, ident, invite }, { fulfillWithValue, rejectWithValue, dispatch }) => {
    const auth = getAuth();
    auth.useDeviceLanguage();

    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    // const defaultEmail = window?.localStorage?.getItem('social-user');
    // provider.setCustomParameters({
    //   'login_hint': defaultEmail
    // });

    try {
      // Google account select..
      const result = await signInWithPopup(auth, provider);
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      const { user } = result;
      const { email, stsTokenManager } = user;

      if (ident && email !== ident) {
        return rejectWithValue('Your selected Google account did not match the invite!');
      }

      // const firebaseNotificationToken = await getFcmToken();
      // console.debug(`FCM TOKEN: '${firebaseNotificationToken}'`);

      // Hit the back-end to authenticate this user
      const response = await signin({
        type,
        ident: email,
        token: stsTokenManager.accessToken,
        invite,
      });

      window?.localStorage?.setItem('social-user', email);

      const { verified = false } = response ?? {};
      if (!verified) {
        return rejectWithValue('Failed to sign in!');
      }

      return handleSignin(i18n, response, fulfillWithValue, rejectWithValue, dispatch);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      console.log('Error', error, credential, errorCode, errorMessage);

      if (errorCode) {
        switch (errorCode) {
          case 'auth/popup-closed-by-user': {
            return rejectWithValue('You did not select an account!');
          }
          default: {
            return rejectWithValue(`There was an error signing in, see more details here [${errorCode}, ${errorMessage}]!`);
          }
        }
      } else {
        return rejectWithValue(error.message ?? 'There was a problem signing in, please try again!');
      }
    }
  }
);
