import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikNumberField, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body2, Overline } from '@pw/components/Typography';
import { SKU_TYPES } from '@pw/consts/sku';
import { ArrayFormikProvider } from '@pw/providers/FormikProvider';
import { useCompanySKUs } from '@pw/redux/containers/User';
import calculateSkuStorageLiquid from '@pw/utilities/calculateSkuStorageLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function AuditItem({ sku, index }) {
	console.log('sku', sku.sku_id, sku.sku_name, index, sku.audit_reason, sku.audit_quantity, sku.available_quantity);
	return (
		<ArrayFormikProvider path="audit" index={index}>
			<Stack spacing="0.5rem">
				<Body2>{sku?.sku_name}</Body2>
				<FlexBox>
					<FormikNumberField name="quantity" label="Audit Quantity" placeholder={sku.available_quantity ?? '0'} fullWidth />
					<FormikTextField name="reason" label="Explanation" fullWidth />
				</FlexBox>
			</Stack>
		</ArrayFormikProvider>
	);
}

function SKUAuditConfirmModal({ item, open, onClose }) {
	const companySkus = useCompanySKUs();
	const converter = useConverter();

	const { sku_storage } = item ?? {};

	const changeSet = useMemo(
		() => ({
			audit: [
				sku_storage.map((s) => ({ id: s.sku_id, quantity: s.audit_quantity ?? '', reason: s.audit_reason ?? '' })) ?? [],
				yup.array().of(
					yup.object().shape({
						id: yup.string(),
						quantity: yup.number().required('New quantity is required!'),
						reason: yup.string().required('Explanation is required!'),
					})
				)
			],
		}),
		[sku_storage],
	);

	const handleSubmit = useCallback((values) => {
		console.log('+>>handleSubmit', values);

		// Go through the quantities and add an audit_quantity to the Storage item to be handled
		// by the back-end..
		const updatedStorage = [];
		for (const a of values.audit) {
			const { id, quantity, reason } = a;
			const storage = sku_storage.find((s) => s.sku_id === id);

			const sku = companySkus.find((s) => s.sku_id === id);

			const amount = storage.available_quantity ?? storage.amount;
			const liquid_amount = calculateSkuStorageLiquid(converter, amount, sku, companySkus);

			// Find the SKU
			const { sku_type, properties } = sku;
			const { unit } = properties ?? {};

			// Re-calculate the liquid if this is a finished goods item.
			if (sku_type === SKU_TYPES.FINISHED) {
				updatedStorage.push({
					...storage,
					amount: unit ? converter.cx(quantity, unit) : quantity,
					liquid_amount,
					audit_quantity: unit ? converter.cx(quantity, unit) : quantity,
					audit_liquid_quantity: calculateSkuStorageLiquid(converter, quantity, sku, companySkus),
					audit_reason: reason,
				});
			} else {
				updatedStorage.push({
					...storage,
					amount: unit ? converter.cx(quantity, unit) : quantity,
					liquid_amount,
					audit_quantity: unit ? converter.cx(quantity, unit) : quantity,
					audit_reason: reason
				});
			}
		}

		console.log(' -->', updatedStorage);

		onClose({ ...item, sku_storage: updatedStorage });
	}, [item, onClose, sku_storage]);

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={`Inventory of ${item?.rw_asset_id}`}>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing="2rem">
					<Stack className="inventory">
						<Box className="inventory-header">
							<Overline>Inventory</Overline>
						</Box>
						<Stack spacing="2rem" className="inventory-contents">
						{sku_storage.map((i, index) => (
							<AuditItem sku={i} key={i.sku_id} index={index} />
						))}
						</Stack>
					</Stack>

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default SKUAuditConfirmModal;
