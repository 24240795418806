import { H2 } from '@pw/components/Typography';
import { usePageTitleHook } from '@pw/redux/containers/App';

function TitleBar({ title = '...' }) {
	const { title: pageTitle } = usePageTitleHook(title);

	const displayTitle = pageTitle ?? title;

	if (!displayTitle) {
		return null;
	}

	return <H2 className='app-title-bar'>{displayTitle}</H2>;
}

export default TitleBar;
