function SvgCryptoCom(props) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			width='1em'
			height='1em'
			viewBox='0 0 190.765 36.248'
			id='crypto-com_svg__svg8'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs id='crypto-com_svg__defs2'>
				<path
					d='M70.1 264.3v24.9h19.6l4.7 20H70.1v3h12.8l-4.2 11.2 1.2 12.5h-9.8v43.6l49.9-28.8v-57.6zM83 323.5l11.5-7.5 13.1 9.9L89.8 357h-7.1l-8.5-7.8v-4l8.8-8.4z'
					id='crypto-com_svg__SVGID_1_'
				/>
				<path
					d='M70.1 264.3l-49.9 28.8v57.6l49.9 28.8V336h-9.7l1.3-12.5-4.3-11.2h12.7v-3H45.9l4.6-20h19.7v-25zm-37.5 61.6l13.2-9.8 11.6 7.4v13.3l8.8 8.4v4l-8.5 7.9h-7.2z'
					id='crypto-com_svg__SVGID_3_'
				/>
			</defs>
			<g id='crypto-com_svg__layer1' transform='translate(-5.375 -113.61)'>
				<g id='crypto-com_svg__g903' transform='matrix(.31794 0 0 .31465 -1.047 30.447)'>
					<path
						id='crypto-com_svg__path817'
						d='M70.1 264.3l-49.9 28.8v57.6l49.9 28.8 49.9-28.8v-57.6zm0 0l-49.9 28.8v57.6l49.9 28.8 49.9-28.8v-57.6z'
						fill='#fff'
					/>
					<path
						id='crypto-com_svg__path819'
						d='M89.8 357h-7.1l-8.5-7.8v-4l8.8-8.4v-13.3l11.5-7.5 13.1 9.9zm-29.4-21l1.3-12.5-4.3-11.2h25.4l-4.2 11.2 1.2 12.5H70zm5.8 13.2l-8.5 7.9h-7.2l-17.9-31.2 13.2-9.8 11.6 7.4v13.3l8.8 8.4zm-15.8-60h39.3l4.7 20H45.8zm19.7-24.9l-49.9 28.8v57.6l49.9 28.8 49.9-28.8v-57.6z'
						fill='#03316c'
					/>
					<g id='crypto-com_svg__g831'>
						<g id='crypto-com_svg__g829'>
							<g id='crypto-com_svg__g827'>
								<defs id='crypto-com_svg__defs822'>
									<path
										d='M70.1 264.3v24.9h19.6l4.7 20H70.1v3h12.8l-4.2 11.2 1.2 12.5h-9.8v43.6l49.9-28.8v-57.6zM83 323.5l11.5-7.5 13.1 9.9L89.8 357h-7.1l-8.5-7.8v-4l8.8-8.4z'
										id='crypto-com_svg__path877'
									/>
								</defs>
								<clipPath id='crypto-com_svg__SVGID_2_'>
									<use
										id='crypto-com_svg__use824'
										xlinkHref='#crypto-com_svg__SVGID_1_'
										x={0}
										y={0}
										width='100%'
										height='100%'
										overflow='visible'
									/>
								</clipPath>
							</g>
						</g>
					</g>
					<g id='crypto-com_svg__g843'>
						<g id='crypto-com_svg__g841'>
							<g id='crypto-com_svg__g839'>
								<defs id='crypto-com_svg__defs834'>
									<path
										d='M70.1 264.3l-49.9 28.8v57.6l49.9 28.8V336h-9.7l1.3-12.5-4.3-11.2h12.7v-3H45.9l4.6-20h19.7v-25zm-37.5 61.6l13.2-9.8 11.6 7.4v13.3l8.8 8.4v4l-8.5 7.9h-7.2z'
										id='crypto-com_svg__path885'
									/>
								</defs>
								<clipPath id='crypto-com_svg__SVGID_4_'>
									<use
										id='crypto-com_svg__use836'
										xlinkHref='#crypto-com_svg__SVGID_3_'
										x={0}
										y={0}
										width='100%'
										height='100%'
										overflow='visible'
									/>
								</clipPath>
							</g>
						</g>
					</g>
					<path
						id='crypto-com_svg__path845'
						d='M176 348.6c-13.2 0-22.9-10.3-22.9-23s9.8-23.3 23-23.3c8.4 0 13.6 3.1 17.7 7.6l-6.3 6.8c-3.1-3.3-6.4-5.5-11.5-5.5-7.4 0-12.8 6.4-12.8 14.2 0 8 5.5 14.4 13.3 14.4 4.8 0 8.3-2.3 11.5-5.5l6.1 6.1c-4.4 4.7-9.5 8.2-18.1 8.2'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__path847'
						d='M213.4 348.6h-10.2v-46.2h10.2v10.8c2.8-6.6 7.9-11.2 15.7-10.8v10.8h-.6c-8.9 0-15.1 5.8-15.1 17.6z'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__polygon849'
						className='crypto-com_svg__st2'
						fill='#002e73'
						d='M282.4 302.2L259.9 361h-10.3l4.9-12.4-19.3-46.4h10.9l13.1 34.3 12.6-34.3z'
					/>
					<path
						id='crypto-com_svg__path851'
						d='M312.4 311.1c-6.9 0-12.9 5.6-12.9 14.3 0 8.7 6.1 14.3 12.9 14.3 7 0 12.7-5.5 12.7-14.3s-5.8-14.3-12.7-14.3m2.3 37.4c-7.3 0-11.9-3.7-15-7.8V361h-10.2v-58.8h10.2v8.3c3.3-4.6 7.9-8.3 15-8.3 10.5 0 20.7 8.3 20.7 23.1s-10.1 23.2-20.7 23.2'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__path853'
						d='M357.7 311.9v21.8c0 3.9 2 5.5 5.5 5.5h4v9.1h-7c-7.4 0-12.7-3.3-12.7-13v-23.4h-5.6v-8.7h5.6V291h10.2v12.2h9.5v8.7z'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__path855'
						d='M399.3 311.1c-8.2 0-13.4 6.4-13.4 14.2 0 7.9 5.6 14.4 13.5 14.4 8.2 0 13.4-6.4 13.4-14.2.2-7.9-5.5-14.4-13.5-14.4m0 37.5c-13.5 0-23.5-10.3-23.5-23 0-12.8 10.1-23.3 23.7-23.3s23.6 10.3 23.6 23.1c0 12.6-10 23.2-23.8 23.2'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__path857'
						d='M520.4 311.1c-8.2 0-13.4 6.4-13.4 14.2 0 7.9 5.6 14.4 13.5 14.4 8.2 0 13.4-6.4 13.4-14.2.1-7.9-5.5-14.4-13.5-14.4m0 37.5c-13.5 0-23.5-10.3-23.5-23 0-12.8 10.1-23.3 23.7-23.3s23.6 10.3 23.6 23.1c0 12.6-10.1 23.2-23.8 23.2'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__path859'
						d='M604.6 302.2c9.7 0 15.6 6.1 15.6 17v29.3H610v-26.2c0-7.1-3.3-10.8-9-10.8-5.5 0-9.5 3.9-9.5 11v26.1h-10.2v-26.3c0-6.9-3.4-10.8-9-10.8s-9.5 4.2-9.5 11.1v26h-10.2v-46.3h10.2v7.6c2.9-3.9 6.7-7.6 13.5-7.6 6.4 0 10.8 3.1 13.2 7.8 3.7-4.8 8.4-7.9 15.1-7.9'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
					<path
						id='crypto-com_svg__polygon861'
						className='crypto-com_svg__st2'
						fill='#002e73'
						d='M432 338.7l4.4-2.5h.6l4.5 2.5.3.5v5.2l-.3.5-4.5 2.5h-.6l-4.4-2.5-.3-.5v-5.2z'
					/>
					<path
						id='crypto-com_svg__path863'
						d='M472.7 348.5c-13.2-.3-22.7-10.8-22.5-23.5.2-12.7 10.2-23.1 23.5-22.8 8.4.2 13.6 3.4 17.6 8l-6.4 6.7c-3-3.3-6.3-5.7-11.4-5.8-7.4-.1-12.9 6.1-13 14-.2 8 5.2 14.5 13 14.6 4.8.1 8.4-2.1 11.6-5.3l6 6.2c-4.5 4.7-9.7 8.1-18.4 7.9'
						className='crypto-com_svg__st2'
						fill='#002e73'
					/>
				</g>
			</g>
			<style id='style815' type='text/css'>
				{'.crypto-com_svg__st2{fill:#002e73}'}
			</style>
		</svg>
	);
}
export default SvgCryptoCom;
