import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikNumberField } from '@pw/components/Forms/FormikForm';
import { useMemo } from 'react';
import * as yup from 'yup';

function TrackedSKU({ item, onClose }) {
	const { amount } = item ?? {};

	const changeSet = useMemo(
		() => ({
			amount: [
				amount ?? '',
				yup.number().positive('Must be positive!').required('Amount required!'),
			],
		}),
		[amount],
	);

const handleSubmit = (values) => {
		const sku = {
			...item,
			amount: values.amount, // convert the amount back
		};
		console.log('Setting SKU', values, sku);
		onClose(sku);
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={1}>
				<FormikNumberField label='Items' name='amount' fullWidth />

				<Errors />

				<Box className='action-buttons'>
					<TextButton size='small' handleClick={() => onClose()} color='secondary' label='Cancel' />
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default TrackedSKU;
