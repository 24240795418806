import { useCallback, useContext, useState, useMemo } from 'react';

import Box from '@mui/material/Box';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';

import { TIME_UNIT_OPTIONS, WEIGHT_UNIT_OPTIONS, LIQUID_UNIT_OPTIONS } from '@pw/consts/measures';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { H4 } from '@pw/components/Typography';

// import StandardEdge from './StandardEdge';
import DesignerContext from '@pw/context/DesignerContext';
import { Button, Modal, Stack } from '@mui/material';
import { HourglassTop, PieChart, Settings } from '@mui/icons-material';
import styles from './EdgeForm.styles';
import OutlinedButton from '@pw/components/Buttons/OutlinedButton';

const PERCENT_OPTION = [{ label: 'Percent', value: '%' }];


const verticalHandles = ['Top', 'Bottom'];
const horizontalHandles = ['Left', 'Right'];
const EdgeForm = ({ id, labelX, labelY, sourcePosition, targetPosition }) => {
  const { nodesData, setNodesData, mode } = useContext(DesignerContext);
  const [open, setOpen] = useState(false);
  const nodeData = nodesData[id] || {};
  const {
    offset = { value: '', unit: '' },
    quantity = { value: '', unit: '' },
  } = nodeData;

  const changeSet = {
    offset: unitField(offset),
    quantity: unitField(quantity),
  }

  const fixOffsets = useMemo(() => {
    let offsetX = 0;
    let offsetY = 0;

    // appears too low (under line) -> move up
    // if (sourcePosition === 'Top' || targetPosition === 'Top) {
    // }

    // appears too low (under line) -> move up
    // if (sourcePosition === 'Bottom' || targetPosition === 'Bottom) {
    // }


    return [offsetX, offsetY];

  }, [sourcePosition, targetPosition]);

  // const edges = useEdges();
  // const connectedEdges = getConnectedEdges([{ data, id }], edges);

  const updateNodeData = useCallback((payload) => {
    console.log('updateNodeData', { payload });
    setNodesData((prev) => {
      const update = {
        ...prev,
        [id]: {
          ...prev?.[id],
          ...payload,
        }
      };
      return update;
    });
    setOpen(false);
  }, [nodesData, setNodesData]);

  const delay = (offset.value && offset.unit)
    ? <Box className="token"><HourglassTop />{offset.value}{offset.unit}</Box>
    : null;

  const quant = (quantity.value && quantity.unit)
    ? <Box className="token"><PieChart />{quantity.value}{quantity.unit}</Box>
    : null;

  const label = (delay || quant) ? <>{delay}{quant}</> : null;

  return (
    <Box
      className="edge-form-root"
      sx={{
        ...styles,
        left: `${labelX - fixOffsets[0]}px`,
        top: `${labelY - fixOffsets[1]}px`,
        // transform: `translateY(${-1 * (offsetY / labelY || 1)}px) translate(-50%, -50%) !important`
      }}>

      <Button
        className={`edge-button ${label ? 'with-label' : ''} ${mode === 'design' ? 'design-mode' : 'run-mode'}`}
        onClick={() => { setOpen(true) }}>
        {label || <Settings />}
      </Button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        disableAutoFocus={true}
        disableRestoreFocus={true}
        sx={styles}
        slotProps={{
          backdrop: {},
        }}
      >
        <Box className='modal-content'>
          <Box className='header'>
            <Box
              className={`icon-container
           `}
            >
              <Settings />
            </Box>
            <Box className=''>
              <H4 className='title'>
                Configure Connection
              </H4>
            </Box>
          </Box>
          <FormikForm
            changeSet={changeSet}
            onSubmit={updateNodeData}
          >
            <Stack gap="1.5rem">
              <UnitField
                label='Offset'
                name='offset'
                options={TIME_UNIT_OPTIONS}
                disabled={mode !== 'design'}
              />
              <UnitField
                label='Quantity'
                name='quantity'
                options={[...WEIGHT_UNIT_OPTIONS, ...LIQUID_UNIT_OPTIONS, ...PERCENT_OPTION]}
                disabled={mode !== 'design'}

              />
              <Box className='action-buttons'>
                <OutlinedButton type='reset' size='small' color="error"
                  disabled={mode !== 'design'}
                >
                  Reset
                </OutlinedButton>
                <FilledButton type='submit' size='small'
                  disabled={mode !== 'design'}
                >
                  Save
                </FilledButton>
              </Box>
            </Stack>
          </FormikForm>
        </Box>
      </Modal>
    </Box>
  );
}
export default EdgeForm;
