function ReportsIcon(props) {
	return (
		<svg
			{...props}
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
		>
			<defs>
				<style>{'.cls-1 {stroke-width: 0px;}'}</style>
			</defs>
			<path
				className='cls-1'
				d='M123,122.13c0-34.04.01-68.07,0-102.11,0-9.39-7.34-16.75-16.71-16.76-9.74,0-19.48,0-29.22,0-8.28,0-16.56,0-24.84,0h-.02c-4.91,0-9.36,1.59-13.21,4.71-5.97,4.85-11.41,9.38-16.64,13.85-4.92,4.21-7.43,9.54-7.44,15.84-.06,25.32-.06,52.34,0,85.04,0,5.22,2.2,9.62,6.5,13.08,2.25,1.81,4.97,2.72,7.38,3.37h79.98c.18-.06.36-.11.54-.15,8.31-1.77,13.68-8.38,13.68-16.86ZM105.93,133.56h-6.48c-11.68,0-23.36,0-35.04,0-10.8,0-21.59,0-32.39,0-6.73,0-11.44-4.69-11.45-11.4,0-15.11,0-30.22,0-45.33,0-12.92,0-25.84,0-38.76,0-4.87,1.77-8.84,5.25-11.79,6.07-5.14,12.26-10.18,17.52-14.43,2.6-2.1,5.94-3.13,10-3.09,10.24.13,20.65.13,30.73.13,7.32,0,14.9,0,22.35.05,6.2.04,10.89,4.87,10.89,11.24.02,12.35.01,24.69.01,37.04v14.01s0,12.73,0,12.73c0,12.73,0,25.46,0,38.19,0,5.47-3.21,9.73-8.36,11.11-1.08.29-2.12.29-3.03.29Z'
			/>
			<path
				className='cls-1'
				d='M54.23,105.69h-12.16s-.54,0-.54,0c-.36,0-.71-.01-1.06.01-.93.08-1.75.54-2.24,1.27-.48.71-.6,1.55-.34,2.39.41,1.32,1.45,2,3.11,2,6.74,0,13.47,0,20.2,0h8.73s6.76,0,6.76,0c6.76,0,13.51,0,20.26,0,1.75,0,2.94-.85,3.17-2.27.15-.93-.04-1.73-.54-2.33-.59-.69-1.57-1.07-2.77-1.07-14.19,0-28.38,0-42.57,0Z'
			/>
			<path
				className='cls-1'
				d='M97.18,82.45h-36.43c-6.37,0-12.73,0-19.1,0-.94,0-1.76.32-2.3.89-.51.54-.75,1.25-.7,2.08.09,1.62,1.39,2.67,3.32,2.68,5.31.01,10.63.01,15.94,0h14.78c2.65,0,5.3,0,7.96,0,5.3,0,10.59,0,15.89,0h0c1.43,0,2.24-.27,2.82-.92.86-.99,1.04-1.97.54-3.08-.51-1.15-1.35-1.67-2.73-1.67Z'
			/>
			<path
				className='cls-1'
				d='M85,62.95c.55,1.26,1.84,1.96,3.11,1.71,1.44-.28,2.3-1.45,2.31-3.11.05-9.82.06-18.92,0-27.81,0-.91-.33-1.73-.92-2.28-.51-.48-1.17-.74-1.89-.74-.04,0-.08,0-.12,0-1.64.07-2.7,1.33-2.71,3.22,0,3.41,0,6.82,0,10.23v6.19c0,3.71,0,7.42,0,11.13,0,.7.06,1.12.21,1.46Z'
			/>
			<path
				className='cls-1'
				d='M71.68,43.76c-.01-1.54-1.03-2.69-2.47-2.81-.09,0-.18-.01-.27-.01-1.42,0-2.52.93-2.78,2.39l-.04.21c-.01.06-.02.11-.03.17,0,1.66-.02,3.36-.04,5.07-.05,4.38-.1,8.91.15,13.32.09,1.65,1.23,2.67,2.92,2.59,1.52-.07,2.54-1.25,2.56-2.94.02-2.08.01-4.17,0-6.25v-2.75s0-2.74,0-2.74c0-2.09,0-4.17,0-6.26Z'
			/>
			<path
				className='cls-1'
				d='M53.09,47.56c-.02-1.63-1.2-2.89-2.73-2.92-.02,0-.04,0-.06,0-.75,0-1.46.28-1.99.8-.54.52-.85,1.24-.86,2.02-.07,4.6-.07,9.39,0,14.25.03,1.77,1.18,3.01,2.89,2.98,1.57-.01,2.73-1.33,2.75-3.14.02-1.57.01-3.15,0-4.72v-2.21s0-2.14,0-2.14c0-1.64.01-3.28-.01-4.92Z'
			/>
		</svg>
	);
}
export default ReportsIcon;
