import { getRequest } from '@pw/services/request.service';
import useLoading from '@pw/utilities/hooks/useLoadingHook';
import pathToParams from '@pw/utilities/pathToParams';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';

const serviceFunction = (id) => getRequest(pathToParams(id));
export const [useRequestLazyQuery, useRequestQuery] = genServiceCallHooks(
	serviceFunction
);

export default useRequestQuery;
