import AirQualityWidget from '@pw/components/Dashboard/components/AirQualityWidget';
// import BatteryWidget from '@pw/components/Dashboard/components/BatteryWidget';
import HumidityWidget from '@pw/components/Dashboard/components/HumidityWidget';
import TemperatureWidget from '@pw/components/Dashboard/components/TemperatureWidget';

function SensorPanel({ data = {} }) {
	const {
		temperature = 0,
		humidity = 0,
		iaq = 0,
		// battery_level = 0,
	} = data?.latest ?? {};

	const parsed = (data?.graph ?? []).reduce(
		(acc, item) => {
			acc.temperature.push(parseFloat(item.temperature));
			acc.humidity.push(parseFloat(item.humidity));
			acc.iaq.push(parseFloat(item.iaq));
			// acc.battery_level.push(parseFloat(item.battery_level));
			// acc.pressure.push(parseFloat(item.pressure));
			// acc.binned_timestamp.push(new Date(item.binned_timestamp));
			// acc.gas_resistance.push(parseFloat(item.gas_resistance));
			return acc;
		},
		{
			temperature: [],
			humidity: [],
			iaq: [],
			// battery_level: [],
			// pressure: [],
			// binned_timestamp: [],
			// gas_resistance: [],
		},
	);

	return (
		<>
			<TemperatureWidget data={parsed.temperature} last={temperature} />
			<HumidityWidget data={parsed.humidity} last={humidity} />
			<AirQualityWidget data={parsed.iaq} last={iaq} />
		</>
	);
}

export default SensorPanel;
