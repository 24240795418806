function SvgCalendar(props) {
	return (
		<svg
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 161 167.14'
			{...props}
		>
			<path
				className='cls-1'
				d='m141.55,167.14H19.44c-.14-.08-.26-.21-.41-.24C6.86,164.42,0,156.07,0,143.68,0,107.72,0,71.76.02,35.8c0-1.68.04-3.39.34-5.04C2.41,19.8,11.33,12.43,22.47,12.39c2.76,0,5.52,0,8.46,0,0-2-.02-3.83,0-5.66C30.98,2.78,33.59-.01,37.18,0c3.61.01,6.12,2.78,6.14,6.78.01,1.83,0,3.66,0,5.49h74.35c0-.66,0-1.19,0-1.73,0-1.41-.02-2.83,0-4.24.07-3.53,2.6-6.2,5.97-6.29,3.42-.1,6.2,2.49,6.38,6.07.1,2.05.02,4.1.02,6.32,3.17,0,6.15-.02,9.13,0,3.72.02,7.23.92,10.43,2.8,7.64,4.5,11.39,11.26,11.39,20.1,0,22.36,0,44.72,0,67.08,0,13.98.03,27.96,0,41.95-.03,10.66-6.45,19.28-16.31,22.01-1.03.29-2.08.53-3.12.79Zm7.07-102.03H12.46c-.03.49-.08.86-.08,1.23,0,26.05-.02,52.1.01,78.16,0,6.2,4.19,10.25,10.43,10.25,38.46,0,76.92,0,115.38,0,.81,0,1.64-.01,2.43-.17,5.02-1.01,8-4.94,8-10.57,0-25.67,0-51.34,0-77.01,0-.59,0-1.18,0-1.87ZM43.31,24.85c0,2.26.05,4.32-.01,6.38-.08,2.66-1.89,4.92-4.41,5.64-4.02,1.15-7.79-1.7-7.93-6.05-.07-1.98-.01-3.97-.01-6.05-2.86,0-5.47,0-8.07,0-6.41.01-10.46,4.06-10.49,10.51-.02,5.22,0,10.44,0,15.67,0,.52.05,1.03.08,1.51h136.03c0-6.49.22-12.85-.08-19.19-.19-3.99-2.68-7.05-6.52-7.88-3.79-.82-7.81-.59-11.83-.82,0,2.32,0,4.16,0,6.01,0,1.94-.6,3.66-2.09,4.95-1.94,1.66-4.15,2.13-6.53,1.08-2.34-1.03-3.66-2.87-3.74-5.45-.06-2.1-.01-4.2-.01-6.3H43.31Z'
			/>
		</svg>
	);
}
export default SvgCalendar;
