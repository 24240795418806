import Stack from '@mui/material/Stack';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { type } = fields;
	return {
		type: [type, yup.string().required('Type is required!')],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<H5>Properties</H5>
				<FlexBox>
					<FormikTextField label='Type' name='type' fullWidth />
				</FlexBox>
			</Stack>
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.EXPENSES),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Expenses'
			skuType={SKU_TYPES.EXPENSES}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, { title: 'Expenses SKUs' });
