import { getAsset } from '@pw/services/asset.service';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';
import pathToParams from '@pw/utilities/pathToParams';

const serviceFunction = (params) => getAsset(pathToParams(params));

export const [useAssetLazyQuery, useAssetQuery] =
	genServiceCallHooks(serviceFunction);

export default useAssetQuery;
