import { useState, useContext, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import DesignerContext from '@pw/context/DesignerContext';
import { useNodeDataContext } from '../NodeDataContext';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { timestampField } from '../ScheduledObservation';


const NodeModalForm = ({ changeSet = {}, children }) => {
  const { mode } = useContext(DesignerContext);
  const { saveHandler, nodeData = {} } = useNodeDataContext();
  const [isLoading, setIsLoading] = useState(false);

  const augmentedChangeSet = useMemo(
    () => (/* mode !== 'design' && */ nodeData.repeat)
      ? ({ ...changeSet, ...timestampField() })
      : changeSet
    , [changeSet, mode, nodeData?.repeat,]);

  const onSubmit = (formData) => {
    setIsLoading(true);
    saveHandler(formData);

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  return (<>
    <FormikForm
      changeSet={augmentedChangeSet}
      onSubmit={onSubmit}
    >
      <Stack gap="1.5rem">
        {children}
        <Box className='action-buttons'>
          <FilledButton type='submit' size='small' disabled={isLoading}>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  </>)
}

export default NodeModalForm;
