import Owners from '@pw/components/Company/admin/Owners';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function OwnerPage() {
	usePageTitleHook('Owners');
	return <Owners />;
}

export default withAppLayout(OwnerPage);
