import { useCallback, useEffect, useState } from 'react';

const usePaginatedSqlLite = ([lazyFetch, { isLoading }], params) => {
	// const [fetchCustomers,{isLoading}] = useCustomersLazyQuery();
	const [pages, setPages] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [hasNextPage, setHasNextPage] = useState(false);

	const load = useCallback(
		async (p) => {
			try {
				const res = await lazyFetch(p);
				setPages((v) => [...v, res]);
				const { limit = 5 } = p;
				setHasNextPage(res?.items?.length >= limit);
			} catch (e) {
				setPages([]);
				setCurrentPage(0);
			}
		},
		[lazyFetch],
	);

	useEffect(() => {
		setPages([]);
		setCurrentPage(0);
		load({ ...params, index: 0 }).finally();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const prev = useCallback(() => {
		if (currentPage) {
			setCurrentPage(currentPage - 1);
		}
	}, [currentPage]);

	const next = useCallback(async () => {
		if (currentPage + 1 < pages.length) {
			setCurrentPage(currentPage + 1);
		} else {
			await load({ ...params, index: currentPage + 1 }).finally();
			setCurrentPage(currentPage + 1);
		}
	}, [currentPage, pages, load, params]);

	const refetchCurrentPage = useCallback(async () => {
		if (pages.length > 0) {
			await load({ ...params, index: currentPage }).finally();
		}
	}, [pages.length, load, params, currentPage]);

	return [
		isLoading,
		pages[currentPage] && pages[currentPage].items
			? pages[currentPage].items
			: [],
		currentPage > 0,
		prev,
		currentPage + 1 < pages.length || hasNextPage,
		next,
		refetchCurrentPage,
	];
};

export default usePaginatedSqlLite;
