import policyFields from '@pw/components/admin/Policy/policyFields';
import PolicyForm from '@pw/components/admin/Policy';
import { useMemo } from 'react';
import { withAppLayout } from '@pw/components/Layout/AppLayout';


  function PolicyPage(props) {
    const changeSetGenerator = useMemo(
      () => (initialValues) => ({
        ...policyFields(initialValues),
      }),
      [],
    );

  
    return (
      <PolicyForm
        policyLabel="Policy"
        changeSetGenerator={changeSetGenerator}
        {...props}
      >
      </PolicyForm>
    );
  }
  
  export default withAppLayout(PolicyPage, { title: 'Policies' });