import { ApprovalRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function DutyPaidDisplay({ label = 'Duty', value }) {
	if (value === undefined) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<ApprovalRounded style={{ width: '12px', height: 'auto' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{value ? 'Paid' : 'Suspended'}
			</Body3>
		</Box>
	);
}

export default DutyPaidDisplay;
