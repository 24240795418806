import withExtendedDialogCard from '@pw/components/Cards/ExtendedDialogCard';
import ReportAssetTable from '@pw/components/InventorySelector/ReportAssetTable';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useSearchParams } from 'react-router-dom';

function AssetsTable() {
	usePageTitleHook('Asset List');
	let [searchParams] = useSearchParams();

	const sources = searchParams.get('sources');
	const cv = Buffer.from(`${sources}`, 'base64').toString('utf-8');
	const assetIds = JSON.parse(cv ?? []);
	// console.log('asset-table', cv, assetIds);

	return <ReportAssetTable assetIds={assetIds} />;
}

export default withAppLayout(withExtendedDialogCard(AssetsTable));
