import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import ProductionOfBrandyReport from './ProductionOfBrandyReport';

export default function ProductionOfBrandy({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<ProductionOfBrandyReport
						name='1_GrapeBrandy'
						title='1.GrapeBrandy'
					/>
					<ProductionOfBrandyReport
						name='2_AllOtherBrandy'
						title='2.AllOtherBrandy'
					/>
					<ProductionOfBrandyReport
						name='3_NaturalGrapeBrandy'
						title='3.NaturalGrapeBrandy'
					/>
					<ProductionOfBrandyReport
						name='4_AllOtherNaturalBrandy'
						title='4.AllOtherNaturalBrandy'
					/>
					<ProductionOfBrandyReport
						name='5_Specify'
						title='5.Specify'
						specify={true}
					/>
					<ProductionOfBrandyReport
						name='6_Specify'
						title='6.Specify'
						specify={true}
					/>
					<ProductionOfBrandyReport
						name='7_Specify'
						title='7.Specify'
						specify={true}
					/>
					<ProductionOfBrandyReport
						name='8_Specify'
						title='8.Specify'
						specify={true}
					/>
				</>
			)}
		</FormikProvider>
	);
}
