/* eslint-disable no-bitwise */
import * as Yup from 'yup';

/**
 * a function to parse data from csv file
 * @param {File} file
 * @returns {Promise<Papa.ParseResult>}
 */
export function parseDataFromCsvFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onerror = reject;
		reader.onload = (e) => {
			async function read() {
				const Papa = await import('papaparse');
				const csv = e.target.result;
				const result = Papa.parse(csv, { header: true, skipEmptyLines: true });
				resolve(result);
			}
			read().finally();
		};
	});
}

/**
 * a function to check if file is excel file
 * @param {File} file uploaded file
 * @returns {boolean} true if file is excel file
 */
export function isExcelFile(file) {
	const fileExtension = file.name.split('.').pop();
	return fileExtension === 'xlsx' || fileExtension === 'xls';
}

/**
 * a function to parse data from excel file
 * @param {File} file
 * @returns {Promise<Papa.ParseResult>}
 */
export function parseDataFromExcelFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onerror = reject;
		reader.onload = (e) => {
			async function read() {
				const Papa = await import('papaparse');
				const XLSX = await import('xlsx');

				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: 'array' });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];

				console.log('worksheet', worksheet);
				const csv = XLSX.utils.sheet_to_csv(worksheet);
				const result = Papa.parse(csv, { header: true, skipEmptyLines: true });

				console.log('result', result);
				resolve(result);
			}
			read().finally();
		};
	});
}

/**
 * a function to get first n data for field preview
 * @param {String} fieldName name of field to get data from
 * @param {Number} count number of data to get
 * @param {Object[]} data array of data
 * @returns String[] array of data for field
 */
export function getFirstNDataForFieldPreview(fieldNames, count = 5, data = []) {
	return data
		.slice(0, count)
		.map((item) => fieldNames.map((fieldName) => item?.[fieldName] ?? '').join(', '));
}

/**
 * a function to validate single row of data
 * @param {Object} model mapping of yup validation
 * @param {Object} row single item of data to validate
 * @returns error object or valid data
 */
export function validateRow(model, row) {
	try {
		const validation = Object.entries(model).reduce((acc, [key, value]) => {
			if (value.validation) {
				acc[key] = value.validation;
			}
			return acc;
		}, {});
		const schema = Yup.object().shape(validation);
		return schema.validateSync(row);
	} catch (e) {
		console.log('Failed to validate', row, e);
		return e;
	}
}
/**
 * a function to validate list of data
 * @param {Object} model mapping of yup validation
 * @param {Object[]} data list of data to validate
 * @returns  error object or valid data
 */
export function validateData(model, data = []) {
	try {
		const validation = Object.entries(model).reduce((acc, [key, value]) => {
			if (value.validation) {
				acc[key] = value.validation;
			}
			return acc;
		}, {});
		const schema = Yup.array().of(Yup.object().shape(validation));
		return schema.validateSync(data);
	} catch (e) {
		return e;
	}
}
/**
 * a function to check if single row of data is valid
 * @param {Object} model mapping of yup validation
 * @param {Object} row single item of data to validate
 * @returns boolean true if row is valid
 */
export function isRowValid(model, row) {
	const a = validateRow(model, row);
	if (a instanceof Error) {
		return false;
	}
	return true;
}
/**
 * a function to check if list of data is valid
 * @param {Object} model mapping of yup validation
 * @param {Object[]} data list of data to validate
 * @returns boolean true if data is valid
 */
export function isDataValid(model, data) {
	const a = validateData(model, data);
	if (a instanceof Error) {
		return false;
	}
	return true;
}
