import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import { ArrowComponent } from '@pw/components/Pagination';
import { Body3, H5 } from '@pw/components/Typography';
import {
	ASSET_NAMES,
	ASSET_TYPES,
	ASSET_TYPES_REVERSE,
} from '@pw/consts/asset';
import toPairs from 'lodash.topairs';
import { useMemo } from 'react';
import { SearchAssetListItemComponent } from '../AssetListItem/SearchAssetListItemComponent';
import { FlexBox } from '../Layout/FlexBox';
import ListItemSkeleton from '../sekeletons/ListItemSkeleton';
import {
	useSkuAssetsQuery,
	useSkuAssetsQueryPaginated,
} from '@pw/utilities/hooks/service/useSkuAssetsQuery';

function AssetEntry({
	assetType,
	sku_id,
	sku_type,
	items,
	buttonIcon,
	onItemClick,
}) {
	const reqParams = useMemo(() => {
		return {
			sku_id,
			sku_type,
			assetType,
			limit: 5,
		};
	}, [assetType, sku_id, sku_type]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useSkuAssetsQueryPaginated(reqParams, items);

	return (
		<Stack>
			<Stack
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<H5>{ASSET_NAMES[assetType]} List</H5>
				<ArrowComponent
					hasNextPage={hasNext}
					hasPrevPage={hasPrev}
					onPressNextPage={loadNext}
					onPressPrevPage={loadPrev}
					size={'small'}
				/>
			</Stack>

			{!isLoading && page && page.length > 0 && (
				<Stack className='list'>
					{page.map((a) => (
						<AssetListComponentV2
							item={a}
							key={a.path}
							buttonIcon={buttonIcon}
							onClick={onItemClick}
						>
							<SearchAssetListItemComponent item={a} />
						</AssetListComponentV2>
					))}
				</Stack>
			)}
			{!isLoading && (!page || page.length === 0) && (
				<Body3>Nothing yet.</Body3>
			)}
			{isLoading && (
				<Stack spacing={0.5}>
					<Stack className='list'>
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}

function SKUAssets({ sku_id, sku_type, ...rest }) {
	const { data = {}, isLoading } = useSkuAssetsQuery({
		sku_id,
		sku_type,
	});
	const { assets } = data;
	const assetCount = useMemo(
		() =>
			toPairs(assets)
				.map(([, l]) => l.length)
				.reduce((c, v) => c + v, 0),
		[assets],
	);
	if (!isLoading && assetCount === 0) {
		return <Body3>Nothing matches</Body3>;
	}
	return (
		<Box className='search-result-contents'>
			{isLoading && (
				<Stack spacing={0.5}>
					<FlexBox justifyContent='flex-end' gap='2rem'>
						<Skeleton variant='rectangular' width={45} height={45} />
						<Skeleton variant='rectangular' width={60} height={35} />
					</FlexBox>
					<Stack className='list'>
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[ASSET_TYPES.cask]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[ASSET_TYPES.cask]} />
					</Stack>
				</Stack>
			)}
			{assetCount > 0 && !isLoading && (
				<Stack className='search-section' spacing={0.75}>
					{toPairs(assets).map(([t, l]) => (
						<AssetEntry
							key={t}
							assetType={t}
							items={l}
							sku_id={sku_id}
							sku_type={sku_type}
							{...rest}
						/>
					))}
				</Stack>
			)}
		</Box>
	);
}

export default SKUAssets;
