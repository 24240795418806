import Box from '@mui/material/Box';
import { useState, useEffect, useCallback } from 'react';

import debounce from '@pw/utilities/debounce';



const ViewportObserver = ({ viewport, setViewport }) => {
  const [vp, setVp] = useState({ width: window.innerWidth, height: window.outerHeight });

  const onResize = useCallback(() => {
    debounce(() => {
      setVp({ width: window.innerWidth, height: window.outerHeight });
    }, 200);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, []);

  return (
    <Box sx={{ display: 'none' }}>
      {JSON.stringify(viewport, null, 2)}
    </Box>
  );
}

export default ViewportObserver;
