const pathToParams = (path) => {
	let bits = [null, null, null];
	if (typeof path === 'string') {
		bits = path.split('/');
		if (bits.length !== 3) {
			throw new Error('Invalid path!');
		}
	} else {
		bits = path;
	}
	return {
		platform: bits?.[0],
		chain: bits?.[1],
		address: bits?.[2],
	};
};

export default pathToParams;
