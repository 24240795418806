import { Box } from '@mui/material';
import SvgCalendar from '@pw/components/icons/Calendar';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function DateDisplay({ label = undefined, date }) {
	if (!date) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<SvgCalendar style={{ width: '12px', height: 'auto' }} />
			<Body3>
				{label && <Bolder>{`${label}: `}</Bolder>}
				{new Date(date).toLocaleDateString()}
			</Body3>
		</Box>
	);
}

export default DateDisplay;
