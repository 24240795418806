import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Address from '@pw/components/Company/Address';
import {
	FormikCheckBox,
	FormikForm,
	FormikPhoneField,
	FormikTextField,
	RenderIF,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { H5 } from '@pw/components/Typography';
import { UK_COUNTRY_CODES } from '@pw/consts/admin';
import generateUsername from '@pw/utilities/generateUsername';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';
// import "yup-phone";
import {
	useCompanyCountry,
	useCompanyId,
} from '@pw/redux/containers/User/hooks';
import { upsertOwnerThunk } from '@pw/redux/thunks/owners';
import gPhoneNumber from 'google-libphonenumber';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

var b = gPhoneNumber.PhoneNumberUtil.getInstance();
Yup.addMethod(Yup.string, 'phone', function (a, d, c) {
	void 0 === d && (d = !1);
	void 0 === c && (c = '');
	return this.test(
		'phone',
		'string' === typeof c && c
			? c
			: 'string' === typeof a && 2 === a.length
				? '${path} must be a valid phone number for region ' + a
				: '${path} must be a valid phone number.',
		function (f) {
			if ('string' !== typeof a || 2 !== a.length) (a = 'IN'), (d = !1);
			try {
				var e = b.parseAndKeepRawInput(f, a);
				if (!b.isPossibleNumber(e)) return !1;
				var g = b.getRegionCodeForNumber(e);
				return d
					? b.isValidNumberForRegion(e, a)
					: b.isValidNumberForRegion(e, g);
			} catch (h) {
				return !1;
			}
		},
	);
});

function OwnerForm({ user, onCancel, onSubmit }) {
	const dispatch = useDispatch();
	const [target, scroll] = useScrollTarget();

	const company_id = useCompanyId();
	const company_cc = useCompanyCountry();

	const {
		path,
		name = '',
		email = '',
		username = '',
		country,
		wowgr = false,
		duty_req = false,
	} = user ?? {};

	const changeSet = useMemo(
		() => ({
			name: [name, yup.string()],
			username: [
				username || generateUsername(),
				yup.string().required('Username is required!'),
			],
			email: [
				email,
				yup.string().email('Invalid email!').required('Email required!'),
			],
			wowgr: [wowgr, yup.boolean()],
			duty_req: [duty_req, yup.boolean()],
			//  address
			line1: [user?.address?.address_1 ?? '', yup.string()],
			line2: [user?.address?.address_2 ?? '', yup.string()],
			line3: [user?.address?.address_3 ?? '', yup.string()],
			city: [user?.address?.city ?? '', yup.string()],
			state: [user?.address?.state ?? '', yup.string()],
			country: [country ?? company_cc ?? '', yup.string()],
			postCode: [user?.address?.post_code ?? '', yup.string()],
			/** phone  */
			telephone: [
				user?.telephone ?? '',
				yup.string().when({
					is: (exists) => {
						return !!exists;
					},
					then: () => yup.string().phone(),
					otherwise: () => yup.string().nullable(),
				}),
			],
		}),
		[
			name,
			username,
			email,
			wowgr,
			duty_req,
			user?.address?.address_1,
			user?.address?.address_2,
			user?.address?.address_3,
			user?.address?.city,
			user?.address?.state,
			user?.address?.post_code,
			user?.telephone,
			country,
			company_cc,
		],
	);

	const handleSubmit = useCallback(
		async (values) => {
			dispatch(
				upsertOwnerThunk({
					...values,
					company_id,
					path,
				}),
			)
				.unwrap()
				.then(onSubmit);
		},
		[dispatch, company_id, path, onSubmit],
	);

	useEffect(() => {
		scroll();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={(values) => handleSubmit(values)}
			enableReinitialize
		>
			<Stack className='form' spacing='1.2rem' ref={target}>
				<H5>{path ? 'Update Owner' : 'Create Owner'}</H5>

				<Instructions>Allow your owners to access their assets.</Instructions>

				<FormikTextField
					name='name'
					label='Full name'
					autoComplete='name'
					required
				/>

				<FormikTextField name='username' label='User name' required />

				<FormikTextField
					name='email'
					label='Contact email'
					autoComplete='email'
					required
					disabled={Boolean(path)}
				/>
				<Address required={false} />
				<FormikPhoneField name='telephone' label='Phone' fullWidth />
				<RenderIF check={() => UK_COUNTRY_CODES.includes(company_cc)}>
					<Instructions>
						Current regulations may require the customer to be registered
						under&nbsp; WOWGR (if they are UK based) or alternatively have Duty
						Representation.
						<br />
						Please indicate if they have or require this.
					</Instructions>
					<RenderIF
						check={({ values }) =>
							values.country && UK_COUNTRY_CODES.includes(values.country)
						}
					>
						<FormikCheckBox
							name='wowgr'
							label='Customer is registered for WOWGR'
						/>
					</RenderIF>
					<FormikCheckBox name='duty_req' label='Require Duty Representation' />
				</RenderIF>

				<Box
					className='action-buttons'
					display={'flex'}
					justifyContent={'flex-end'}
				>
					<TextButton
						size='small'
						handleClick={onCancel}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton type='submit' size='small'>
						{path ? 'Update' : 'Create'}
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default OwnerForm;
