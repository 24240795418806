import { useCallback, useState } from 'react';

function useSelections(initialSelection = [], compare) {
	const [selection, setSelection] = useState(initialSelection);

	const isSelected = useCallback(
		(item) => selection.some((_item) => compare(item, _item)),
		[compare, selection],
	);

	const select = useCallback((item) => {
		setSelection((prev) => [...prev, item]);
	}, []);

	const unselect = useCallback(
		(item) => {
			setSelection((prev) => prev.filter((_item) => !compare(item, _item)));
		},
		[compare],
	);

	const toggle = useCallback(
		(item) => (isSelected(item) ? unselect(item) : select(item)),
		[isSelected, select, unselect],
	);

	return [selection, setSelection, toggle, isSelected];
}

export default useSelections;
