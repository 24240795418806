import { createAsyncThunk } from '@reduxjs/toolkit';

export const setOnlineThunk = createAsyncThunk(
  'connection/setOnlineThunk',
  async (state, { rejectWithValue, fulfillWithValue }) => {
		// Dispatch a message to the service worker
		try {
			await setConnected(state);
		  if (navigator?.serviceWorker) {
			  await navigator.serviceWorker.ready;
        if (navigator?.serviceWorker?.controller) {
          // service worker will update listeners
          navigator.serviceWorker.ready.then((registration) => {
            registration.active.postMessage({ type: `APP/setOnline`, payload: state });
          });
        }

			  return fulfillWithValue(state);
			}
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);
