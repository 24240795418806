function TasksIcon(props) {
	return (
		<svg
			{...props}
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
		>
			<defs>
				<style>{'.cls-1 {stroke-width: 0px;}'}</style>
			</defs>
			<path d='M29.74,4.72c-12.49,3.58-20.49,11.16-24.45,23.17-2.11,6.4-3.13,13.37-3.21,21.96-.14,15.52-.08,30.81-.01,42.4.04,6.72.7,12.7,2.03,18.27,3.19,13.38,10.55,22,22.5,26.34,6.52,2.36,13.68,3.5,23.24,3.68,4.95.09,10,.07,14.89.05,2.2,0,4.4-.02,6.6-.02l2,.02c1.33,0,2.67,0,4,0,4.43,0,9,.02,13.5-.02,9.04-.08,15.81-.9,21.98-2.65,12.51-3.56,20.52-11.12,24.49-23.12,2.1-6.35,3.16-13.53,3.23-21.95.11-13.18.11-27.25,0-43.02-.04-6.42-.71-12.24-2.04-17.8-3.4-14.24-11.6-23.12-25.08-27.14-6.11-1.82-12.87-2.73-20.69-2.79-7.13-.05-14.29-.08-21.43-.08s-14.34.03-21.43.08c-7.54.06-14.11.92-20.11,2.64ZM94.83,8.42c5.45.04,10.81.75,15.95,2.13,11.05,2.97,18.19,10.07,21.22,21.1,1.37,4.99,2.08,10.25,2.18,16.09.09,5.52.07,11.13.05,16.56,0,2.34-.02,4.67-.02,7.01,0,2.3,0,4.59.02,6.89.02,5.41.04,11.01-.05,16.52-.08,5.14-.71,10.19-1.86,14.99-2.99,12.45-10.99,20.15-23.78,22.89-4.96,1.06-10.21,1.61-15.62,1.61h-7.39c-5.33.02-10.75.03-16.18.03-7.11,0-14.26-.02-21.35-.07-6.1-.04-11.89-.86-17.19-2.44-10.44-3.09-17.24-10.07-20.19-20.72-1.42-5.12-2.16-10.55-2.2-16.11-.11-15.72-.11-31.62,0-47.25.04-5.46.77-10.82,2.16-15.95,2.99-10.97,10.07-18.07,21.03-21.1,5.12-1.41,10.54-2.15,16.11-2.19,15.49-.11,31.34-.11,47.09,0Z' />
			<path d='M94.98,93.68h-5.06s-4.56,0-4.56,0c-3.69,0-7.37-.01-11.06.02-1.19.01-2.15.36-2.78,1.01-.55.56-.83,1.34-.81,2.26.03,1.81,1.4,3.03,3.4,3.04,4.03.02,8.06.02,12.09.01h4.92s5.03,0,5.03,0c3.99,0,7.99.01,11.98-.02,1.06,0,1.99-.37,2.59-1.02.57-.61.84-1.42.78-2.35-.11-1.77-1.54-2.93-3.65-2.94-4.3-.03-8.59-.02-12.89-.02Z' />
			<path d='M70.72,50.19c-.16,1.9.81,3.17,2.67,3.49.52.09,1.11.13,1.82.13,10.63,0,21.26.01,31.89,0,.85,0,1.56-.07,2.17-.21,1.26-.28,2.21-1.47,2.26-2.82.03-.9-.25-1.69-.81-2.27-.61-.64-1.52-.99-2.56-.99-4.06-.02-8.13-.02-12.19-.01h-4.83s-5.24,0-5.24,0c-1.6,0-3.19,0-4.79,0-2.43,0-4.86,0-7.3.02-1.6.01-2.96,1.18-3.08,2.67Z' />
			<path d='M51.6,84.95c-3.12,3.07-6.43,6.34-9.59,9.61-.49.51-1.52,1.57-2.98,1.57h-.01c-1.47,0-2.5-1.09-2.84-1.45-.24-.25-.82-.86-1.89-.86-.44,0-.96.1-1.57.37-.86.38-1.66,1.02-1.66,3.08v.08c-.02.38.13.99.6,1.48l.79.83c1.47,1.54,2.98,3.13,4.56,4.53,1.35,1.2,2.94,1.14,4.25-.15,4.06-4,8.16-8.12,12.12-12.1l2.76-2.77c.51-.51.79-1.09.85-1.78.12-1.38-.51-2.56-1.68-3.14-1.17-.58-2.7-.3-3.71.7Z' />
			<path d='M56.15,38.61c-1.99-2-4.05-.38-4.85.4l-2.06,2.04c-2.38,2.35-4.84,4.79-7.17,7.24-.76.8-1.71,1.61-3.01,1.61h0c-1.33,0-2.3-.86-2.91-1.5-.9-.95-2-1.13-3.28-.55-.86.39-1.79,1.02-1.8,3.47v.07c0,.2.26.89,1,1.64l1.89,1.89c.83.83,1.65,1.66,2.48,2.48,1.88,1.86,3.41,1.85,5.3-.03,4.68-4.65,9.35-9.32,14-14,1.52-1.53,1.69-3.49.41-4.77Z' />
		</svg>
	);
}
export default TasksIcon;
