import React, { useCallback } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';

export function BasicMenu({ menuOptions = [], isLoading = false, className = '' }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = useCallback(
		(event) => {
			if (isLoading) return;
			setAnchorEl(event.currentTarget);
		},
		[isLoading],
	);

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton
				aria-label='settings'
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				className={className}
			>
				{isLoading ? <CircularProgress size={24} /> : <MoreVertIcon />}
			</IconButton>
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{menuOptions.map((option) => (
					<MenuItem
						disabled={option?.disabled || isLoading}
						onClick={() => {
							handleClose();
							option?.onClick?.();
						}}
						key={option.title}
					>
						{option.title}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}
