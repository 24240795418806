const liquids = [
  {
    "label": "Beer",
    "value": "beer"
  },
  {
    "label": "Wine",
    "value": "wine"
  },
  {
    "label": "Baiju",
    "value": "baiju"
  },
  {
    "label": "Bourbon",
    "value": "bourbon",
    "category": "whisky",
    "nabca_prefix": "150",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Bonded Bourbon",
    "value": "bonded_bourbon",
    "nabca_prefix": "150",
    "category": "whisky",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Straight Bourbon",
    "value": "straight_bourbon",
    "category": "whisky",
    "nabca_prefix": "151",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Blended Bourbon",
    "value": "blended_bourbon",
    "nabca_prefix": "152",
    "category": "whisky",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Blended",
    "value": "blended",
    "nabca_prefix": "160",
    "category": "whisky",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Corn Whiskey",
    "value": "corn_whiskey",
    "category": "whisky",
    "nabca_prefix": "190",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Rye Whiskey",
    "value": "rye_whiskey",
    "category": "whisky",
    "nabca_prefix": "180",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Bonded Rye Whiskey",
    "value": "bonded_rye_whiskey",
    "category": "whisky",
    "nabca_prefix": "180",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Straight Rye Whiskey",
    "value": "straight_rye_whiskey",
    "category": "whisky",
    "nabca_prefix": "181",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Light Whiskey",
    "value": "light_whiskey",
    "category": "whisky",
    "nabca_prefix": "191",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Blended Light Whiskey",
    "value": "blended_light_whiskey",
    "category": "whisky",
    "nabca_prefix": "192",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Kentucky Whiskey",
    "value": "kentucky_whiskey",
    "category": "whisky",
    "nabca_prefix": "165",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Tennessee Whiskey",
    "value": "tennessee_whiskey",
    "category": "whisky",
    "nabca_prefix": "170",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Wheat Whiskey",
    "value": "wheat_whiskey",
    "category": "whisky",
    "nabca_prefix": "199",
    "countries": [
      "US"
    ]
  },
  {
    "label": "Whiskey",
    "value": "whiskey",
    "category": "whisky",
    "nabca_prefix": "150",
    "countries": [
      "US",
      "IE"
    ]
  },
  {
    "label": "Grain Whiskey",
    "value": "grain_whiskey",
    "category": "whisky",
    "nabca_prefix": "191",
    "countries": [
      "US",
      "IE"
    ]
  },
  {
    "label": "Grain Whisky",
    "value": "grain_whisky",
    "category": "whisky",
    "countries": [
      "GB-ENG",
      "GB-NIR",
      "GB-SCT",
      "GB-WLS",
      "GB"
    ]
  },
  {
    "label": "Pot Still",
    "value": "pot_still",
    "category": "whisky",
    "countries": [
      "IE"
    ]
  },
  {
    "label": "Whisky",
    "value": "whisky",
    "category": "whisky",
    "countries": [
      "GB-ENG",
      "GB-NIR",
      "GB-SCT",
      "GB-WLS",
      "GB"
    ]
  },
  {
    "label": "Single Malt",
    "value": "single_malt",
    "category": "whisky"
  },
  {
    "label": "Vodka",
    "value": "vodka"
  },
  {
    "label": "Rum",
    "value": "rum"
  },
  {
    "label": "Gin",
    "value": "gin"
  },
  {
    "label": "Tequila",
    "value": "tequila"
  },
  {
    "label": "Brandy",
    "value": "brandy"
  },
  {
    "label": "Liqueurs",
    "value": "liqueurs"
  },
  {
    "label": "Cider",
    "value": "cider"
  },
  {
    "label": "Mead",
    "value": "mead"
  },
  {
    "label": "Sake",
    "value": "sake"
  },
  {
    "label": "Pisco",
    "value": "pisco"
  },
  {
    "label": "Soju",
    "value": "soju"
  },
  {
    "label": "Absinthe",
    "value": "absinthe"
  },
  {
    "label": "Schnapps",
    "value": "schnapps"
  },
  {
    "label": "Mezcal",
    "value": "mezcal"
  },
  {
    "label": "Sotol",
    "value": "sotol"
  },
  {
    "label": "Cognac",
    "value": "cognac"
  },
  {
    "label": "Grappa",
    "value": "grappa"
  }
];

export default liquids;
