import { Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FormikCheckBox, FormikDatePicker, FormikMeasuresField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { LIQUID_UNIT_OPTIONS, UNIT } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';
import { useContext } from 'react';
import FormikUnitField from '../Forms/FormikUnitField';

function SamplePropertiesImpl() {
	const { values } = useContext(FormikContext);

	return (
		<>
			<FormikUnitField
				label='Sample Size'
				name='sample'
				options={LIQUID_UNIT_OPTIONS}
			/>

			<Divider />

			<Stack>
				<FormikCheckBox name='enable' label={<H5>Sample Liquid</H5>} />
				<Collapse in={Boolean(values?.enable)}>
					<Stack spacing={2} sx={{ ...mt2 }}>
						<FlexBox alignItems='top'>
							<FormikDatePicker
								name='action_date'
								label='Action Date'
								fullWidth
							/>
						</FlexBox>
						<FormikCheckBox name='duty_paid' label='Duty Due' />
					</Stack>
				</Collapse>
			</Stack>
		</>
	);
}

export default function SampleProperties({ name = 'properties' }) {
	return (
		<FormikProvider path={name}>
			<SamplePropertiesImpl />
		</FormikProvider>
	);
}
