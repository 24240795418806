import { AccountCircle, VerifiedOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import {
	FormikForm,
	FormikPasswordField,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import Identity from '@pw/components/Identity';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { CountdownTimer } from '@pw/components/Timer/Countdown';
import { Body2, Body3, H5 } from '@pw/components/Typography';
import useInviteHook from '@pw/utilities/hooks/service/useInviteHook';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IDENTITY } from '@pw/consts/account';
import CountdownContext from '@pw/context/CountdownContext';
import CountdownProvider from '@pw/providers/CountdownProvider';
import TimerProvider from '@pw/providers/TimerProvider';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import styles from './auth.styles';
import {
	challengeIdentThunk,
	recoverThunk,
	verifyIdentThunk,
} from '@pw/redux/thunks/register';

YupPassword(yup); // extend yup

function ForgotPasswordForm({
	account,
	invite,
	showVerification,
	showPassword,
}) {
	const dispatch = useDispatch();
	const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};
	const changeSet = {
		type: [type, yup.string().required('Identity type is required!')],
		ident: [
			ident,
			yup.string().test({
				name: 'valid_identity',
				test: (value, ctx) => {
					switch (ctx.parent.type) {
						case IDENTITY.EMAIL: {
							if (!value) {
								return ctx.createError({
									path: 'identity',
									message: 'Email is required!',
								});
							}
							const valid = yup.string().email().isValidSync(value);
							if (!valid) {
								return ctx.createError({
									path: 'identity',
									message: 'Invalid email!',
								});
							}
							break;
						}
						case IDENTITY.PHONE: {
							if (!value) {
								return ctx.createError({
									path: 'identity',
									message: 'Phone number is required!',
								});
							}
							const valid = yup.string().phone().isValidSync(value);
							if (!valid) {
								return ctx.createError({
									path: 'identity',
									message: 'Invalid phone number!',
								});
							}
							break;
						}
						default: {
							break;
						}
					}
					return true;
				},
			}),
		],
	};

	const handleSubmit = async (values) => {
		dispatch(challengeIdentThunk({ ...values, invite }))
			.unwrap()
			.then((response) => {
				const { otp, identity } = response;
				if (otp) {
					// Move to the verification step
					showVerification(values);
				} else {
					// Move to the password reset step
					showPassword({ ...values, identity });
				}
			});
	};

	return (
		<FormikForm readonly changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={4}>
				<Body3>
					Please enter the identity you used to create the account on the
					platform. You will need to verify the identity before setting a new
					password.
					<br />
					<br />
					<strong>NOTE:</strong>
					&nbsp; If you had any Passkeys configured, these will be reset.
				</Body3>

				<Identity type='type' name='ident' nopasskey disabled={!!invite} />
				<FilledButton
					icon={<AccountCircle />}
					iconPosition='start'
					label='Continue'
					type='submit'
				/>
			</Stack>
		</FormikForm>
	);
}

function ResendCode({ resendCode }) {
	const { done } = useContext(CountdownContext);

	return (
		<Box>
			{!done && (
				<Body2>
					Please wait&nbsp;
					<CountdownTimer />, before re-requesting the code.
				</Body2>
			)}
			{done && (
				<Body2>
					Click&nbsp;
					<span className='resend' onClick={resendCode}>
						here
					</span>
					&nbsp;to resend the code.
				</Body2>
			)}
		</Box>
	);
}

function VerificationForm({ account, changeIdentity, showPassword }) {
	const dispatch = useDispatch();
	const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};
	const [endTime, setEndTime] = useState(
		new Date(new Date().getTime() + 5 * 60 * 1000),
	);

	const changeSet = {
		otp: ['', yup.string().required('Verification code is required!')],
	};

	const handleSubmit = async (values) => {
		dispatch(
			verifyIdentThunk({
				...account,
				otp: values.otp,
			}),
		)
			.unwrap()
			.then((response) => {
				showPassword({ ...account, ...response });
			});
	};

	const resendCode = async () => {
		dispatch(challengeIdentThunk(account))
			.unwrap()
			.then(() => {
				setEndTime(new Date(new Date().getTime() + 5 * 60 * 1000));
			});
	};

	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={(values) => handleSubmit(values)}
		>
			<Stack spacing={4}>
				<FlexBox className='identityDisplay' justifyContent='space-between'>
					<Body2>{ident}</Body2>
					<H5 onClick={changeIdentity} className='changeButton'>
						Change
					</H5>
				</FlexBox>

				{type === IDENTITY.EMAIL && (
					<Instructions instruction='Check email'>
						Please enter the verification code sent to your email address.
					</Instructions>
				)}
				{type === IDENTITY.PHONE && (
					<Instructions instruction='Check SMS'>
						Please enter the verification code sent to your phone.
					</Instructions>
				)}

				<FormikTextField
					name='otp'
					label='Verification Code'
					fullWidth
					required
				/>

				<FilledButton
					icon={<VerifiedOutlined />}
					iconPosition='start'
					label='Submit'
					type='submit'
				/>

				<TimerProvider>
					<CountdownProvider end={endTime}>
						<ResendCode resendCode={resendCode} />
					</CountdownProvider>
				</TimerProvider>
			</Stack>
		</FormikForm>
	);
}

function NewPasswordForm({ account, signed, invite, changeIdentity }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { ident } = account ?? {};
	const { identity } = signed ?? {};
	const changeSet = {
		password: [
			'',
			yup.string().password('Password').required('Password is required!'),
		],
		confirmPassword: [
			'',
			yup
				.string()
				.min(8, 'Must be at least 8 characters')
				.minUppercase(1, 'Must contain at least 1 uppercase character')
				.minNumbers(1, 'Must contain at least 1 number')
				.minSymbols(1, 'Must contain at least 1 symbol (excluding space)')
				.oneOf([yup.ref('password'), null], ' Passwords must match'),
		],
	};

	const handleSubmit = async (values) => {
		dispatch(
			recoverThunk({
				...values,
				token: identity,
				invite,
			}),
		)
			.unwrap()
			.then(() => {
				navigate('/account/settings');
			});
	};

	return (
		<FormikForm
			readonly
			changeSet={changeSet}
			onSubmit={(values) => handleSubmit(values)}
		>
			<Stack spacing={4}>
				<FlexBox className='identityDisplay' justifyContent='space-between'>
					<Body2>{ident}</Body2>
					<H5 onClick={changeIdentity} className='changeButton'>
						Change
					</H5>
				</FlexBox>

				<H5>Enter new password</H5>

				<Stack spacing={1.5}>
					<FormikPasswordField
						name='password'
						label='Password'
						fullWidth
						required
						autoComplete='password'
					/>
					<Body3 sx={{ color: 'grey', paddingLeft: '0.5rem' }}>
						Password must contain at least one upper case letter, one number and
						one symbol
					</Body3>
				</Stack>
				<FormikPasswordField
					name='confirmPassword'
					label='Confirm Password'
					fullWidth
					required
				/>
				<FilledButton
					icon={<AccountCircle />}
					iconPosition='start'
					label='Update Password'
					type='submit'
				/>
			</Stack>
		</FormikForm>
	);
}

function ForgotPassword() {
	const { account, setAccount, invite, signed, setSigned } = useInviteHook();

	const [page, setPage] = useState(0);

	const changeIdentity = () => setPage(0);

	const showVerification = (account) => {
		setAccount(account);
		setSigned(undefined);
		setPage(1);
	};

	const showPassword = (reg) => {
		const { type, ident, name, identity } = reg;
		setAccount({ type, ident });
		setSigned({ name, identity });
		setPage(2);
	};

	return (
		<Stack spacing={4} sx={styles} className='root'>
			{page === 0 && (
				<ForgotPasswordForm
					account={account}
					invite={invite}
					showVerification={showVerification}
					showPassword={showPassword}
				/>
			)}
			{page === 1 && (
				<VerificationForm
					account={account}
					changeIdentity={changeIdentity}
					showPassword={showPassword}
				/>
			)}
			{page === 2 && (
				<NewPasswordForm
					account={account}
					signed={signed}
					invite={invite}
					changeIdentity={changeIdentity}
				/>
			)}
		</Stack>
	);
}

export default ForgotPassword;
