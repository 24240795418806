import { Edit, TaskOutlined } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import CostDisplay from '@pw/components/properties/CostDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import ProductCodeDisplay from '@pw/components/properties/ProductCodeDisplay';
import TimeDisplay from '@pw/components/properties/TimeDisplay';

function Vendor({ item, onRemove, onEdit, selected }) {
	const { vendor, product, unit_price, lead_time } = item ?? {};
	//
	// const openSku = useCallback(
	//   (e) => {
	//     e.preventDefault();
	//     e.stopPropagation();
	//
	//     const path = item?.sku_id;
	//
	//     const route = `/admin/sku?id=${path}`;
	//     console.log('Routing to', route);
	//     window.open(route, '_blank').focus();
	//   },
	//   [item],
	// );

	return (
		<Box className='listItem'>
			{selected && selected(item) && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack spacing={0.5}>
					<IDDisplay value={vendor?.id} />
					<NameDisplay name={vendor?.name ?? vendor?.public_name} />
					<ProductCodeDisplay value={product} />
					<Grid container spacing={0.5}>
						<Grid item xs={6}>
							<CostDisplay label="Unit Cost" value={unit_price} />
						</Grid>
						<Grid item xs={6}>
							<TimeDisplay value={lead_time} />
						</Grid>
					</Grid>
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
				{onRemove && (
					<IconCircleButton onClick={() => onRemove(item)}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

function VendorList({ items, onEdit, onRemove }) {
	return (
		<Box className='inventory-contents'>
			<Stack className='list'>
				{items.map((vendor) => (
					<Vendor
						item={vendor}
						onEdit={onEdit}
						onRemove={onRemove}
						key={vendor?.vendor?.id}
					/>
				))}
			</Stack>
		</Box>
	);
}

export default VendorList;
