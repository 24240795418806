import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IDDisplaySkeleton } from '@pw/components/properties/IDDisplay';
import { SourceDisplaySkeleton } from '@pw/components/properties/SourceDisplay';

function ListItemSkeleton({ type }) {
  return (
    <Box className='listItem'>
      <Box className='listContent'>
        <Stack spacing={0.5} sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}>
          <IDDisplaySkeleton />
          <SourceDisplaySkeleton type={type} />
          <Skeleton variant={'text'} width={120} />
        </Stack>
      </Box>

      <Stack className='listButtons'>
        <Skeleton variant='circular' width={24} height={24} />
      </Stack>
    </Box>
  );
}

export default ListItemSkeleton;
