import { Box } from '@mui/material'
import { useDrag } from 'react-dnd'

function Draggable({ children, data, type = 'dnd-item', onDrop, ...rest }) {

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type,
    data,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      offset: monitor.getClientOffset()
    }),
    end: (_, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) return;

      const dropResult = monitor.getDropResult();
      if (typeof onDrop === 'function') onDrop({ ...data, dropResult });
    },
  }))

  return (
    <Box
      // ref={dragPreview}
      sx={{ opacity: isDragging ? 0.5 : 1 }}
      {...rest}
    >
      <Box
        role="Handle"
        ref={drag}
      >
        {children}
      </Box>
    </Box>
  );
}
export default Draggable;
