function safeParse(val) {
	if (!val) {
		return {};
	}
	if (typeof val === 'object') {
		return val;
	}
	return JSON.parse(val);
}

export default safeParse;
