import * as yup from 'yup';

export default function part2Fields(fields = {}) {
	const {
		P2_Spirits_Produced = '',
		P2_Spirits_Received = '',
		P2_Spirits_Received_Customs = '',
		P2_Spirits_Used_On_Fuel = '',
		P2_Spirits_Making_Fuel = '',
		P2_Spirits_Destroyed = '',
		P2_Spirits_Transferred = '',
		P2_Fuel_Alcohol_Produced = '',
		P2_Fuel_Alcohol_Used_For_Fuel = '',
		P2_Fuel_Alcohol_Transferred = '',
		perjury1 = true,
	} = fields;

	return {
		P2_Spirits_Produced: [
			P2_Spirits_Produced,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Spirits_Received: [
			P2_Spirits_Received,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Spirits_Received_Customs: [
			P2_Spirits_Received_Customs,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Spirits_Used_On_Fuel: [
			P2_Spirits_Used_On_Fuel,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Spirits_Making_Fuel: [
			P2_Spirits_Making_Fuel,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Spirits_Destroyed: [
			P2_Spirits_Destroyed,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Spirits_Transferred: [
			P2_Spirits_Transferred,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Fuel_Alcohol_Produced: [
			P2_Fuel_Alcohol_Produced,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Fuel_Alcohol_Used_For_Fuel: [
			P2_Fuel_Alcohol_Used_For_Fuel,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		P2_Fuel_Alcohol_Transferred: [
			P2_Fuel_Alcohol_Transferred,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
		perjury1: [perjury1, yup.boolean()],
	};
}
