import { CheckCircle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetTag from '@pw/components/Tasks/AssetTag';
import { H5 } from '@pw/components/Typography';
import { REQUEST_STATUS } from '@pw/consts/requests';
import { requestArchiveThunk } from '@pw/redux/thunks/request';
import styles from '@pw/styles/modal.styles';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

function ArchiveModal({ tasks, onClose }) {
	const dispatch = useDispatch();

	const archiveTasks = useCallback(() => {
		const requests = tasks.map((t) => ({ path: t.path }));

		dispatch(requestArchiveThunk({ requests, request_status: REQUEST_STATUS.ARCHIVED }))
			.unwrap()
			.then(() => onClose(true, true));
	}, [tasks]);

	return (
		<Modal open onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Archive Tasks</H5>
					<IconButton
						onClick={() => onClose(false, false)}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<FlexBox className='asset-container' justifyContent='start' spacing={1}>
					{tasks.map((task) => (
						<AssetTag
							key={task.id}
							id={task.id}
							action={task.action}
							type={task.type}
						/>
					))}
				</FlexBox>
				<FlexBox justifyContent='end'>
					<TextButton
						size='small'
						handleClick={() => onClose(false, true)}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton
						size='small'
						icon={<CheckCircle />}
						iconPosition='start'
						handleClick={archiveTasks}
					>
						Confirm
					</FilledButton>
				</FlexBox>
			</Stack>
		</Modal>
	);
}

export default ArchiveModal;
