import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Instructions from '@pw/components/Instructions';
import AmountSKU from '@pw/components/SKUSelector/modals/forms/AmountSKU';
import ServiceSKU from '@pw/components/SKUSelector/modals/forms/ServiceSKU';
import { SKU_TYPES } from '@pw/consts/sku';

function PurchaseSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_type, sku_description } = item ?? {};

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={sku_name}>
			<Instructions>{sku_description}</Instructions>

			{[SKU_TYPES.SERVICE, SKU_TYPES.CONSUMABLE].includes(sku_type) && (
				<ServiceSKU item={item} onClose={onClose} {...rest} />
			)}

			{![SKU_TYPES.CONSUMABLE, SKU_TYPES.SERVICE].includes(sku_type) && (
				<AmountSKU item={item} onClose={onClose} {...rest} />
			)}
		</ModalWithClose>
	);
}

export default PurchaseSKUModal;
