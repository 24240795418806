import { PointOfSale } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { CURRENCY_SYMBOLS } from '@pw/consts/currencies';
import { useCompanyCountry } from '@pw/redux/containers/User';
import { useMemo } from 'react';

function CostDisplay({ label = null, value, code = undefined }) {
	const cc = useCompanyCountry();

	const defaultSymbol = useMemo(() => {
		switch (cc?.toLowerCase()) {
			case 'gb':
			case 'uk':
			case 'gb-sct':
			case 'gb-eng':
			case 'gb-nir': {
				return <Bolder>{CURRENCY_SYMBOLS.GBP}</Bolder>;
			}
			case 'ie': {
				return <Bolder>{CURRENCY_SYMBOLS.EUR}</Bolder>;
			}
			case 'us': {
				return <Bolder>{CURRENCY_SYMBOLS.USD}</Bolder>;
			}
			case 'ca': {
				return <Bolder>{CURRENCY_SYMBOLS.CAD}</Bolder>;
			}
			case 'au': {
				return <Bolder>{CURRENCY_SYMBOLS.AUD}</Bolder>;
			}
			case 'nz': {
				return <Bolder>{CURRENCY_SYMBOLS.NZD}</Bolder>;
			}
			case 'jp': {
				return <Bolder>{CURRENCY_SYMBOLS.JPY}</Bolder>;
			}
			default: {
				return <Bolder>{CURRENCY_SYMBOLS.USD}</Bolder>;
			}
		}
	}, [cc]);

	const codeSymbol = useMemo(() => CURRENCY_SYMBOLS[code], [code]);

	if (!value) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<PointOfSale style={{ width: '12px', height: 'auto' }} />
			{label && <Body3>{label}:</Body3>}
			<Stack direction="row" spacing="0.15rem">
				<Body3><strong>{codeSymbol ?? defaultSymbol}</strong></Body3>
				<Body3>{Number(value)?.toFixed(2)}</Body3>
			</Stack>
		</Box>
	);
}

export default CostDisplay;
