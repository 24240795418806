import { ArrowForward, Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LiquidContent from '@pw/components/AssetListItem/LiquidContent';
import LocationContent from '@pw/components/AssetListItem/LocationContent';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import Switch from '@pw/components/SwitchComponent';
import { ASSET_EDIT_URL, ASSET_TYPES, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';

function CaskContent({ item }) {
	const { unique_location_id, properties = {} } = item ?? {};

	return (
		<>
			<LocationContent location={unique_location_id} />
			<LiquidContent {...properties} />
		</>
	);
}

function IBCContent({ item }) {
	const { unique_location_id, properties = {} } = item ?? {};

	return (
		<>
			<LocationContent location={unique_location_id} />
			<LiquidContent {...properties} />
		</>
	);
}

function TankerContent({ item }) {
	const { properties = {} } = item ?? {};

	return <LiquidContent {...properties} />;
}

function FillingTankContent({ item }) {
	const { properties = {} } = item ?? {};

	return <LiquidContent {...properties} />;
}

function PalletContent({ item }) {
	const { unique_location_id } = item ?? {};

	return <LocationContent location={unique_location_id} />;
}

function ContainerContent({ item }) {
	const { unique_location_id } = item ?? {};

	return <LocationContent location={unique_location_id} />;
}

export function AssetListItemComponent({ item, display }) {
	const assetType =
		ASSET_TYPES_REVERSE[item?.asset_type] ?? item?.asset_type ?? '';

	const openAsset = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			const path = item?.asset_id ?? item?.path;

			const route = ASSET_EDIT_URL?.[item?.asset_type];
			console.log('Routing to', route, path);
			window.open(`${route}/${path}`, '_blank').focus();
		},
		[item],
	);

	return (
		<Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: 'left' }}>
			<Grid container>
				<Grid item xs={6}>
					<IDDisplay value={item?.rw_asset_id} onClick={openAsset} />
				</Grid>
				<Grid item xs={6}>
					<IDDisplay value={item?.properties?.reference}/>
				</Grid>
			</Grid>
			<SourceDisplay type={assetType} name={assetType?.toUpperCase()} />
			<Switch value={item?.asset_type}>
				<Switch.Case condition={ASSET_TYPES.cask}>
					<CaskContent item={item} />
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.ibc}>
					<IBCContent item={item} />
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.tanker}>
					<TankerContent item={item} />
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.filling_tank}>
					<FillingTankContent item={item} />
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.pallet}>
					<PalletContent item={item} />
				</Switch.Case>
				<Switch.Case condition={ASSET_TYPES.container}>
					<ContainerContent item={item} />
				</Switch.Case>
				<Switch.Default></Switch.Default>
			</Switch>
			{display && display(item)}
		</Stack>
	);
}

/**
 * Unified Asset component
 * @param item
 * @param selected function to check if the item should be displayed "selected"
 * @param display function to call to display additional state
 * @param buttonIcon
 * @param onClick
 * @param onEdit
 * @param onRemove
 * @returns {JSX.Element}
 * @constructor
 */
export const AssetListComponentV2 = forwardRef(
	(
		{
			item,
			selected = undefined,
			display = undefined,
			buttonIcon = <ArrowForward />,
			onClick = undefined,
			onEdit = undefined,
			onRemove = undefined,
			children,
		},
		ref,
	) => {
		const { readonly } = useContext(FormikContext);

		return (
			<Box className='listItem' ref={ref}>
				{selected && selected(item) && (
					<Box className='listSelected'>
						<TaskOutlined className='check' />
					</Box>
				)}

				<Box className='listContent'>
					<Stack spacing={0.75}>
						{children ? (
							children
						) : (
							<AssetListItemComponent item={item} display={display} />
						)}
					</Stack>
				</Box>

				<Stack className='listButtons'>
					{!readonly && onClick && (
						<IconCircleButton onClick={() => onClick(item)}>
							{buttonIcon}
						</IconCircleButton>
					)}
					{!readonly && onEdit && (
						<IconCircleButton onClick={() => onEdit(item)}>
							<Edit />
						</IconCircleButton>
					)}
					{!readonly && onRemove && (
						<IconCircleButton onClick={() => onRemove(item)}>
							<Delete />
						</IconCircleButton>
					)}
				</Stack>
			</Box>
		);
	},
);

AssetListComponentV2.displayName = 'AssetListComponentV2';
