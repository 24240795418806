import Print from '@mui/icons-material/Print';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withDialogCard from '@pw/components/Cards/DialogCard';
import ExplorerLocation from '@pw/components/ExplorerLocation';
import LocationQRCodes from '@pw/components/ExplorerLocation/LocationQRCodes';
import { explorerLocationFields } from '@pw/components/ExplorerLocation/explorerLocationFields';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { LOCATION_PRINT_MAP, LOCATION_PRINT_OPTIONS } from '@pw/consts/facility';
import FormikContext from '@pw/context/FormikContext';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import { bn, br3 } from '@pw/styles/border';
import { bg_light_gray } from '@pw/styles/colors';
import { min_vh_75 } from '@pw/styles/heights';
import { pa0, pl2 } from '@pw/styles/padding';
import printElement from '@pw/utilities/printElement';
import { useContext, useMemo, useRef, useState } from 'react';

const styles = {
	root: {
		'&.grid-container': {
			...br3,
			width: '100%',
			backgroundColor: '#fff',
			...pa0,
			...br3,
			...min_vh_75,
			justifyContent: 'center',
			'.assets-explorer-data-grid': {
				...bn,
				'.MuiDataGrid-virtualScrollerContent.MuiDataGrid-virtualScrollerContent--overflowed, .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor':
					{
						...pl2,
					},
				'.MuiDataGrid-row': {
					'&:nth-of-type(odd)': {
						...bg_light_gray,
					},
				},
				'.mui-style-tlelie-MuiListItemText-root': {
					marginTop: 0,
					marginBottom: 0,
				},
			},
		},
	},
};

function makeNumbers(number) {
	return Array(+number)
		.fill(0)
		.map((_, i) => i + 1);
}

function Content({ facilities = {} }) {
	const [filter, setFilter] = useState(LOCATION_PRINT_MAP.all);
	const printRef = useRef();
	const { values } = useContext(FormikContext);

	const handlePrint = () => {
		const element = printRef?.current?.innerHTML;
		printElement({
			element,
			styles:
				'p{ margin-top: 0; margin-bottom: 10px;   text-overflow: ellipsis };',
		});
	};

	const currentWarehouse = useMemo(
		() => facilities?.[values?.location?.facility_id] ?? {},
		[values?.location?.facility_id, facilities],
	);

	const rows = useMemo(() => {
		if (values?.location?.row) return [values?.location?.row];
		const bayRef = currentWarehouse?.bays?.find(
			(b) => b?.[0] === values?.location?.bay,
		);
		return makeNumbers(bayRef?.[1] ?? 0);
	}, [values?.location?.row, values?.location?.bay, currentWarehouse?.bays]);

	const levels = useMemo(() => {
		if (values?.location?.level) return [values?.location?.level];
		const bayRef = currentWarehouse?.bays?.find(
			(b) => b?.[0] === values?.location?.bay,
		);
		return makeNumbers(bayRef?.[2] ?? 0);
	}, [values?.location?.level, values?.location?.bay, currentWarehouse?.bays]);

	const filterOptions = useMemo(() => {
		if (!values?.location?.facility_id) {
			return LOCATION_PRINT_OPTIONS.filter(
				(o) =>
					o.value !== LOCATION_PRINT_MAP.rows &&
					o.value !== LOCATION_PRINT_MAP.bays,
			);
		}
		if (!values?.location?.bay) {
			return LOCATION_PRINT_OPTIONS.filter(
				(o) => o.value !== LOCATION_PRINT_MAP.rows,
			);
		}
		return LOCATION_PRINT_OPTIONS;
	}, [values]);

	return (
		<Stack spacing={3}>
			<FlexBox gap={2}>
				<FormikSelect
					fullWidth
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					options={filterOptions}
					label='Print'
					withNone={false}
				/>
				<TitledButton
					onClick={handlePrint}
					label='Print'
				>
					<Print height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box ref={printRef}>
				<LocationQRCodes
					filter={filter}
					origin={origin}
					facilities={facilities}
					facility_id={values?.location?.facility_id}
					bay={values?.location?.bay}
					bays={values?.location?.bay}
					rows={rows}
					levels={levels}
				/>
			</Box>
		</Stack>
	);
}

function Locations() {
	const changeSet = { location: explorerLocationFields({}) };

	const facilities = useCompanyFacilities();

	return (
		<FormikForm changeSet={changeSet}>
			<Stack spacing={2}>
				<ExplorerLocation
					name='location'
					label='Storage Location'
					facilities={facilities}
					withPrintAllButton={false}
				/>
				<Divider />
				<Content facilities={facilities} />
			</Stack>
		</FormikForm>
	);
}

export default withAppLayout(withDialogCard(Locations));
