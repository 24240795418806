import useLilly from "./useLilly";

const useHeaderMapper = (spec) => {
  const lilly = useLilly();

  const mapHeaders = async (data) => {
    if (data.length === 0) return [];

    const headers = Object.keys(data[0]);

    const mappedHeaders = await lilly.chat(
      spec,
      `Map the following fields to the given specification, use the most relevant field exactly as provided with no modifications and only the fields requested in the specification, if there are multiple matches and output as a JSON object with no context help or exaplanations: ${JSON.stringify(headers)}`
    );
    console.log('mappedHeaders', mappedHeaders);
    
    const mapper = (row, field) => {
      const mappedField = mappedHeaders[field];
      // console.log(' --> row', mappedField, row, row[mappedField]);
      if (mappedField) {
        for (const key in row) {
          if (key.trim().toLowerCase() === mappedField.trim().toLowerCase()) {
            return row[key];
          }
        }
      }
      return null;
    };
    return [mapper, mappedHeaders];
  }

  return { mapHeaders };
};

export default useHeaderMapper;