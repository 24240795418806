import { useCompanyPolicies } from '@pw/redux/containers/User';
import { useCallback } from 'react';

const usePolicySearch = () => {
  const policies = useCompanyPolicies();

  const search = useCallback(
    (param = '', itemFilter = (x) => !!x) => {
      // Find all policies matching this
      return policies
      .filter((policy) => {
        // Policy type must be in the filter
        console.log('policies', policies, policy);
        // Search term must be in the policy properties..
        const search_key =
          `${policy.policy_id} ${policy.policy_name}`.toLowerCase();
        // console.log('Search key', search_key, param);
        return search_key.includes(param.toLowerCase());
      })
      .filter(itemFilter);
    },
    [policies],
  );

  return [search];
};

export default usePolicySearch;
