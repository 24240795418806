import { createAsset, deleteAsset, updateAsset, updateAssetsAdmin } from '@pw/services/asset.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertAssetThunk = createAsyncThunk('asset/upsert', async (asset, { rejectWithValue }) => {
  try {
    return (asset?.path) ? await updateAsset(asset) : await createAsset(asset);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const deleteAssetThunk = createAsyncThunk('asset/delete', async (path, { rejectWithValue }) => {
  try {
    return await deleteAsset({path});
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const assetApproveRejectThunk = createAsyncThunk('asset/admin', async (params, { rejectWithValue }) => {
  try {
    return await updateAssetsAdmin(params);
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
