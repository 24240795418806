function SvgLongLogo(props) {
	return (
		<svg
			id='LongLogo_svg__Layer_1'
			xmlns='http://www.w3.org/2000/svg'
			x={0}
			y={0}
			viewBox='0 0 816.8 130.5'
			xmlSpace='preserve'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<style>{'.LongLogo_svg__st0{fill:#000}'}</style>
			<path
				className='LongLogo_svg__st0'
				d='M102.9 0H0v102.9h51.5V51.5h51.4z'
			/>
			<path
				className='LongLogo_svg__st0'
				d='M77.2 77.2h51.5v51.5H77.2zM177.8 128.7v-2.1l4.6-1.2c4.9-1.4 5.8-2.2 5.8-12.6V61.3c0-10.4-.9-11.4-5.8-12.6l-4.6-1.1v-2.1c8.9-.4 25.5-.9 37.4-.9 14.1 0 32.7 3.2 32.7 23.9 0 20.9-21.9 27.6-39 27.6l-.1-4.7c10.9-.1 21.5-6.3 21.5-21.2 0-13.9-8-20.4-19.8-20.4-1.9 0-4.1.1-5.6.4v62.6c0 10.4 1.4 11.6 6.4 12.6l7 1.2v2.1h-40.5zM255.9 128.7V127l3.8-1.1c4.3-1.1 5.1-1.9 5.1-10.8V83.5c0-8.9-1.2-9.8-5.7-10.6l-4.3-1V70l22.8-3 .7.6V78h.1c6.3-7.8 11-11.5 16.8-11.5 5.1 0 8.8 3.7 8.8 8.7 0 4.9-4.3 8-7.9 7.9l-.7-.4c-1.1-2.8-4.2-5.2-7.8-5.2-5.7 0-8.4 4.2-8.4 7.2v30.4c0 8.9 1.1 9.6 5.4 10.5l6.3 1.4v1.7h-35zM337.8 130.5c-19 0-30.4-12.1-30.4-30.8 0-18.1 11.6-33.6 32.3-33.6 19.8 0 30.7 13 30.7 30.9 0 19.1-13 33.5-32.6 33.5zm-1.1-59.7c-10.4 0-13.7 10.6-13.7 22 0 10.5 4.1 33.1 17.3 33.1 10.9 0 14.3-10.8 14.3-22 .1-10.4-4.3-33.1-17.9-33.1zM407.7 130.5c-19 0-30.4-12.1-30.4-30.8 0-18.1 11.6-33.6 32.3-33.6 19.8 0 30.7 13 30.7 30.9 0 19.1-13 33.5-32.6 33.5zm-1.1-59.7c-10.4 0-13.7 10.6-13.7 22 0 10.5 4.1 33.1 17.3 33.1 10.9 0 14.3-10.8 14.3-22 0-10.4-4.3-33.1-17.9-33.1zM444 128.7V127l3.8-1.1c4.3-1.1 5.1-1.9 5.1-10.8V76.2h-8.5l-.5-.5 1.2-4.5 7.7-.4.1-5.4c.4-15.8 16.6-29.3 31.4-29.3 7 0 13.7 4 13.7 9.9 0 4.8-4.1 7.8-8.3 7.8l-.7-.4c-1.6-5.1-5.8-10.1-11.4-10.1-9.5 0-10.8 11.1-10.8 18.3v9l16.6-.6v5.7l-.5.5h-15.7V115c0 8.9 1 9.6 5.3 10.5l6.3 1.4v1.7H444z'
			/>
			<path
				className='LongLogo_svg__st0'
				d='M551.5 129.4l-13.7-35-13.2 35h-9.2l-18.4-47c-3.2-8.2-3.8-9.9-6.3-10.8l-2.5-1v-1.9h29.9v1.9l-3.8 1c-3.7 1.1-4.9 2.8-1.7 12.1l8.7 24.1c1.4 4 2.1 6.4 2.3 8.7h.2c.4-2 1-4.1 2.6-8.9l7.9-22-1.2-3.2c-3-8.2-3.8-9.9-6.3-10.8l-2.5-1v-1.9h29.9v1.9l-3.8 1c-3.7 1.1-4.9 2.8-1.7 12.1l8.7 24.1c1.4 4 2.1 6.4 2.3 8.7h.2c.4-2 1-4.1 2.6-8.9l8.3-23.1c3.5-9.5 1.7-11.9-2.1-12.9l-4-1v-1.9H587v1.9l-2.2 1c-2.3 1-3.2 2.6-6.3 10.8l-17.8 47h-9.2zM618.4 130.5c-19 0-30.4-12.1-30.4-30.8 0-18.1 11.6-33.6 32.3-33.6 19.8 0 30.7 13 30.7 30.9-.1 19.1-13.1 33.5-32.6 33.5zm-1.1-59.7c-10.4 0-13.7 10.6-13.7 22 0 10.5 4.1 33.1 17.3 33.1 10.9 0 14.3-10.8 14.3-22 0-10.4-4.3-33.1-17.9-33.1zM655.9 128.7V127l3.8-1.1c4.3-1.1 5.1-1.9 5.1-10.8V83.5c0-8.9-1.2-9.8-5.7-10.6l-4.3-1V70l22.8-3 .7.6V78h.1c6.3-7.8 11-11.5 16.8-11.5 5.1 0 8.8 3.7 8.8 8.7 0 4.9-4.3 8-7.9 7.9l-.7-.4c-1.1-2.8-4.2-5.2-7.8-5.2-5.7 0-8.4 4.2-8.4 7.2v30.4c0 8.9 1.1 9.6 5.4 10.5l6.3 1.4v1.7h-35zM707.6 128.7V127l3.8-1.1c4.3-1.1 5.1-1.9 5.1-10.8V52.9c0-8.9-.7-9.8-5.2-10.6l-4.3-1v-1.9l23.1-3 .7.6v78c0 8.9.7 9.6 5.1 10.8l3.8 1.1v1.7h-32.1zm44.6 0L732 95.3l13.6-12.9c7.8-7.3 5.4-9.6 1.6-10.8l-4-1v-1.9H772v1.9l-4.1 1c-4.5 1.2-7.1 4.1-14.3 10.8l-9 8.4 16 24.2c4.9 7.5 6.6 9.8 10.4 10.9l3.1 1v1.7h-21.9zM794.3 129.9c-4.9 0-11-1.1-14.8-3.1l-1.2-15.7 2.6-.4 1.6 5.1c1.6 4.8 5.4 8.9 12.5 8.9 5.4 0 11.1-3.2 11.1-9.2 0-6.7-5.9-9.4-14-13.5-7.3-3.7-13.4-8.3-13.4-17.3 0-12.1 10.9-17.9 21.4-17.9 3.6 0 8.9.6 13.4 2.6V84H811l-1.1-4.7c-1.1-4.2-4.6-7.5-11.3-7.5-5.6 0-9.4 3.3-9.4 7.7 0 5.6 6.2 8 13.2 11.6 8.4 4.2 14.3 8.8 14.3 18.8.1 11.7-9.7 20-22.4 20z'
			/>
		</svg>
	);
}
export default SvgLongLogo;
