const toUniqueLocation = ({ facility_id = '', bay = '', row = '', level = '' }) => {
	if (facility_id && bay && row && level) {
		return `${facility_id}/${bay}/${row}/${level}`;
	}
	if (facility_id && bay && row) {
		return `${facility_id}/${bay}/${row}/0`;
	}
	if (facility_id && bay) {
		return `${facility_id}/${bay}/0/0`;
	}
	if (facility_id) {
		return `${facility_id}/0/0/0`;
	}
	return '0/0/0/0/0';
};

export default toUniqueLocation;
