import * as yup from 'yup';

export default function reportQuantityFields(fields = {}) {
	const { cases = 0, casks = 0, litres = 0 } = fields;

	return {
		cases: [cases, yup.number().required('Cases count is required!')],
		casks: [casks, yup.number().required('Casks count is required!')],
		litres: [litres, yup.number().required('Litres is required!')],
	};
}
