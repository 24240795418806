function printElement({
	element,
	title = '',
	width = 768,
	height = 768,
	styles = '',
	pageTitle = 'PROOFWORKS',
}) {
	if (!element) {
		console.error('printElement called without element');
		return;
	}
	const printPopup = window.open(
		'',
		title,
		`left=0,top=0,width=${width},height=${height},toolbar=0,scrollbars=0,status=0`,
	);

	const cssStyles = `
      @font-face {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 200;
				font-display: swap;
				src: url('../fonts/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2') format('woff2');
				unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
			}

			/* latin */
			@font-face {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 400;
				font-display: swap;
				src: url('../fonts/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2') format('woff2');
				unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
			}

			/* latin */
			@font-face {
				font-family: 'Poppins';
				font-style: normal;
				font-weight: 600;
				font-display: swap;
				src: url('../fonts/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2') format('woff2');
				unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
			}

      body {
        padding: 0;
        margin: 0;
        font-family: 'Poppins', Arial, sans-serif;
      }

      ${styles}

      @media print {
        body {
          font-family: 'Poppins', Arial, sans-serif;
        }
			 .MuiGrid-root {
				 display: block; /* or flex, depending on your layout */
				 visibility: visible;
			 }
      }
    `;

	printPopup.document.write(element);
	printPopup.document.title = pageTitle;
	const style = printPopup.document.createElement('style');
	style.textContent = cssStyles;
	printPopup.document.head.appendChild(style);
	printPopup.document.close();
	printPopup.focus();
	printPopup.print();
	// printPopup.close();
}

export default printElement;
