import Box from '@mui/material/Box';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useRef } from 'react';

const QRCodeScanner = ({
	className,
	onSuccess,
	facingMode = 'environment',
	onError,
	...rest
}) => {
	const cacheList = useRef([]);

	const onResult = (data, error) => {
		console.log('Scan', data, error);
		if (data && data.length > 0 && data[0].rawValue && !cacheList.current.includes(data[0].rawValue)) {
			cacheList.current.push(data[0].rawValue);
			if (typeof onSuccess === 'function') onSuccess({ text: data[0].rawValue });
		}
	};

	return (
		<Box className={className}>
			{/*<QrReader*/}
			{/*	key={facingMode}*/}
			{/*	constraints={{*/}
			{/*		facingMode,*/}
			{/*		video: {*/}
			{/*			pan: true,*/}
			{/*			tilt: true,*/}
			{/*			zoom: true,*/}
			{/*		},*/}
			{/*	}}*/}
			{/*	delay={300}*/}
			{/*	onResult={onResult}*/}
			{/*	style={{ position: 'absolute', height: '100%', width: '100%' }}*/}
			{/*	{...rest}*/}
			{/*/>*/}

			<Scanner
				onScan={onResult}
				components={{ audio: false, torch: true, zoom: true, finder: true }}
				scanDelay={300}
				barCodes={['qr_code', 'rm_qr_code', 'upc_a', 'upc_c']}
				styles={{
					container: { width: '100%', height: '100%' },
					video: { width: '100%', height: '100%' },
					finderBorder: 0,
				}}
			/>
		</Box>
	);
};

export default QRCodeScanner;
