import { enqueueSnackbar } from 'notistack';
import ReactDOM from 'react-dom/client';
import App from './pages';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
	onSuccess: () => {
		// enqueueSnackbar('Offline support is enabled!', {
		// 	variant: 'info',
		// });
		console.log('Offline support enabled.');
	},
	onUpdate: () => {
		console.log('New content is available; please refresh.');
	},
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
