import MuiAvatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import { getAvatarSmall } from '@pw/services/profile.service';
import getInitialsFromString from '@pw/utilities/getInitialsFromString';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function BaseAvatar({ src, alt, sx }) {
	const [avatar, setAvatar] = useState(undefined);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (src) {
			setLoading(true);
			getAvatarSmall(src)
				.then((a) => {
					setAvatar(a);
					setLoading(false);
				})
				.catch(() => {
					setAvatar(undefined);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [src]);

	return (
		<>
			{loading && (
				<MuiAvatar sx={sx}>
					<CircularProgress
						size='30%'
						thickness={3.5}
						sx={{ opacity: '.65' }}
					/>
				</MuiAvatar>
			)}
			{!loading && avatar && (
				<MuiAvatar sx={{ ...sx, position: 'relative' }} src={avatar} />
			)}
			{!loading && !avatar && (
				<MuiAvatar sx={{ ...sx, position: 'relative' }}>
					{alt ? getInitialsFromString(alt) : null}
				</MuiAvatar>
			)}
		</>
	);
}

BaseAvatar.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	sx: PropTypes.object,
};

export default BaseAvatar;
