import { CLIENT_TYPE } from '@pw/consts/contacts';
import { useCompanyOwners, useCompanyPartners } from '@pw/redux/containers/User/hooks';
import { useCallback, useMemo, useState } from 'react';

function useNetworkSearch() {
	const allOwners = useCompanyOwners();
	const allPartners = useCompanyPartners();

	const [owners, setOwners] = useState([]);
	const [partners, setPartners] = useState([]);

	const ownerOptions = useMemo(
		() =>
			(owners ?? []).map((customer) => ({
				id: customer?.account_id,
				hash: customer?.account_hash,
				name: customer?.account_name ?? customer?.account_username,
				public_name: customer?.account_username,
				type: CLIENT_TYPE.ACCOUNT,
			})),
		[owners],
	);
	const partnerOptions = useMemo(
		() =>
			(partners ?? []).map((partner) => ({
				id: partner?.partner_id,
				hash: partner?.company_key_hash,
				name: partner?.company_name,
				public_name: partner?.company_public_name ?? partner?.company_name,
				type: CLIENT_TYPE.COMPANY,
			})),
		[partners],
	);

	const handleOnSearchDebounced = useCallback(
		(search = '') => {
			const owners = allOwners.filter((owner) => {
				const search_key = `${owner.account_name} ${owner.account_username} ${owner.account_id}`.toLowerCase();
				return search_key.includes(search.toLowerCase());
			});
			const partners = allPartners.filter((partner) => {
				const search_key = `${partner.company_name} ${partner.excise_id} ${partner.partner_id} ${partner.company_id}`.toLowerCase();
				return search_key.includes(search.toLowerCase());
			});
			setOwners(owners);
			setPartners(partners);
		},
		[allOwners, allPartners],
	);

	return [
		{ ownerOptions, partnerOptions },
		handleOnSearchDebounced,
	];
}

export default useNetworkSearch;