import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Body3 } from '@pw/components/Typography';
import formatNumber from '@pw/utilities/formatNumber';

function StatsWidget({ primary, secondary, label }) {
	return (
		<Stack spacing={1} className='widget stats'>
			<Box className='widget-content stats-content'>
				<Body1>{label}</Body1>
				<FlexBox style={{ width: 'auto' }}>
					<Box className='widget-text'>
						<Body3 className='widget-text-secondary'>{`${formatNumber(secondary.value)} ${secondary.unit}`}</Body3>/
						<Body1 className='widget-text-primary'>{`${formatNumber(primary.value)} ${primary.unit}`}</Body1>
					</Box>
				</FlexBox>
			</Box>
		</Stack>
	);
}

export default StatsWidget;
