function SvgFerment(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 150 150'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M20.53 36.1h-.23a5.51 5.51 0 00-5.5 5.5v43.1c0 3.03 2.47 5.5 5.5 5.5h.23c3.03 0 5.5-2.47 5.5-5.5V41.6c0-3.03-2.47-5.5-5.5-5.5zm2.5 48.6a2.5 2.5 0 01-2.5 2.5h-.23a2.5 2.5 0 01-2.5-2.5V41.6a2.5 2.5 0 012.5-2.5h.23a2.5 2.5 0 012.5 2.5v43.1zM38.43 40.1h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5zM38.43 53.98h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5zM38.43 67.87h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5zM38.43 81.75h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z' />
			<path d='M140.72 26.08h-5.58l-18.95-16.01c-.55-.46-1.35-.47-1.91-.02L94.45 26.07h-5.58c-.83 0-1.5.67-1.5 1.5v12.46H64.8c-.83 0-1.5.67-1.5 1.5v77.24H48.23V105.5l1.84-1.07s.05-.03.07-.05h1.44c.83 0 1.5-.67 1.5-1.5V24.7c0-.83-.67-1.5-1.5-1.5h-3.2c.02-.13.03-.26.01-.4-.42-3.61-1.63-9.67-5.07-9.71-2.53-.03-5.07-.02-7.6-.02h-8.44c-3.6.01-7.21.02-10.81 0-2.47 0-4.11 2.99-5.01 9.15-.05.35.03.68.19.96H7.93c-.83 0-1.5.67-1.5 1.5v78.2c0 .83.67 1.5 1.5 1.5h1.78s.03.02.05.04l1.52.93v31.94H7.53c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h10.49c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-3.75v-30.12l9.16 5.57c-.2.25-.32.57-.32.92v16.81c0 .83.67 1.5 1.5 1.5h20.62v5.32h-3.75c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h10.49c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-3.75v-5.32h26.67c.83 0 1.5-.67 1.5-1.5V53.23h10.96v49.65c0 .83.67 1.5 1.5 1.5h5.85c.35.6.99 1.62 1.94 2.82v30.08h-3.75c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h10.49c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-3.75v-26.9c3.3 2.95 8.24 5.78 15.05 5.78s12.41-3.1 15.92-6.18v27.3h-3.75c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h10.49c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-3.75v-30.36c.93-1.1 1.56-2.01 1.91-2.55h5.2c.83 0 1.5-.67 1.5-1.5V27.58c0-.83-.67-1.5-1.5-1.5zM115.2 13.16l15.29 12.92H99.21l15.99-12.92zM45.21 118.77h-9.39v-5.11c0-.3-.09-.58-.24-.81l9.63-5.6v11.52zm-29.77-14.39h28.73l-14.69 8.54-14.04-8.54zM14.4 22.67c.84-5.75 2.09-6.58 2.04-6.59 3.6.01 7.21 0 10.82 0h8.45c2.52-.02 5.04-.02 7.48 0 .46.21 1.65 2.27 2.2 7.06 0 .02.01.03.02.05H14.22c.09-.16.16-.33.18-.53zm-5 3.53h40.66v75.18H9.4V26.2zm65.49 24.03c-.83 0-1.5.67-1.5 1.5v77.23h-47.3v-14.6l2.6 1.58a1.503 1.503 0 001.53.02l2.6-1.51v5.82c0 .83.67 1.5 1.5 1.5h30.46c.83 0 1.5-.67 1.5-1.5V43.03h21.07v7.2H74.89zm39.8 62.93c-7.46 0-12.35-3.94-15.05-7.04v-1.75h30.97v1.37c-2.93 3.16-8.28 7.41-15.92 7.41zm24.53-11.78H90.35v-72.3h48.87v72.3z' />
			<path d='M117.68 43.1h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5zM117.68 56.98h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5zM117.68 70.87h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5zM117.68 84.75h5.97c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-5.97c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5z' />
		</svg>
	);
}
export default SvgFerment;
