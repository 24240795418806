import * as yup from 'yup';

export function transportFields(params = {}) {
	const { enable = true, transportType = '', registration = '', sealId = '' } = params;

	return {
		enable: [enable, yup.bool()],
		transportType: [
			transportType,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Transport type is required!'),
				otherwise: (schema) => schema,
			}),
		],
		registration: [
			registration,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Registration is required!'),
				otherwise: (schema) => schema,
			}),
		],
		sealId: [
			sealId,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Seal ID is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
