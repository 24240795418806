import { useState } from 'react';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import {
	KeyboardArrowRightOutlined,
	ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';

export default function GeneralInformation({
	name,
	title = 'General Information',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(true);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<FormikTextField
						name='Permit_Number'
						label='Permit Number'
						required
						fullWidth
						disabled={disabled}
					/>
				</>
			)}
		</FormikProvider>
	);
}
