import * as yup from 'yup';
import reportQuantityFields from './reportQuantityFields';
import reportAdjustmentFields from './reportAdjustmentFields';

export default function monthlyReportFields(fields = {}) {
	const {
		start_date,
		end_date,
		opening_stock,
		closing_stock,
		adjustment,
		receipts_from_importation,
		receipts_from_uk_and_other_warehouses,
		gains_in_storage,
		increases_from_operations,
		removals_to_home_use,
		removals_to_exportation,
		removals_to_other_uk_warehouses,
		removals_to_other_duty_free_uses,
		losses_in_storage,
		losses_from_operations,
	} = fields;

	return {
		start_date: [
			start_date,
			yup.number().min(0, 'Invalid date!').required('Date is required!'),
		],
		end_date: [
			end_date,
			yup.number().min(0, 'Invalid date!').required('Date is required!'),
		],
		opening_stock: reportQuantityFields(opening_stock),
		closing_stock: reportQuantityFields(closing_stock),
		adjustment: reportAdjustmentFields(adjustment),
		// receipts
		receipts_from_importation: reportQuantityFields(receipts_from_importation),
		receipts_from_uk_and_other_warehouses: reportQuantityFields(
			receipts_from_uk_and_other_warehouses,
		),
		gains_in_storage: reportQuantityFields(gains_in_storage),
		increases_from_operations: reportQuantityFields(increases_from_operations),
		// removals
		removals_to_home_use: reportQuantityFields(removals_to_home_use),
		removals_to_exportation: reportQuantityFields(removals_to_exportation),
		removals_to_other_uk_warehouses: reportQuantityFields(
			removals_to_other_uk_warehouses,
		),
		removals_to_other_duty_free_uses: reportQuantityFields(
			removals_to_other_duty_free_uses,
		),
		losses_in_storage: reportQuantityFields(losses_in_storage),
		losses_from_operations: reportQuantityFields(losses_from_operations),
	};
}
