import withDialogCard from '@pw/components/Cards/DialogCard';
import Teams from '@pw/components/Company/admin/Teams';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function TeamsPage() {
	usePageTitleHook('Manage Teams');

	return <Teams />;
}

export default withAppLayout(withDialogCard(TeamsPage));
