const slugify = (text) =>
	text
		.toLowerCase()
		.trim()
		.replace(/\s/g, '_')
		.replace(/\//g, '_')
		.replace(/([^a-z0-9_])/g, '')
		.replace(/(_{2,})/g, '_');

export default slugify;
