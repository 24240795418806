function SvgCask(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				className='Cask_svg__cls-2'
				d='M23.61 45.51c-.28 0-.56-.08-.8-.23L11 37.77c-.43-.28-.69-.75-.69-1.26V23.38c0-.54.29-1.04.76-1.3l12.58-7.15c.47-.27 1.04-.26 1.51.01l12.01 7.14c.46.27.73.76.73 1.29v13.68c0 .55-.3 1.05-.78 1.32l-12.79 6.97c-.22.12-.47.18-.72.18zM13.3 35.68l10.37 6.59 11.23-6.12V24.22l-10.53-6.26-11.06 6.29v11.43z'
				data-name='Path 9246'
			/>
			<path
				data-name='Rectangle 232'
				className='Cask_svg__cls-1'
				d='M14.41 49.94h19.38c1.16 0 2.11.88 2.11 1.97 0 1.09-.94 1.97-2.11 1.97H14.41c-1.16 0-2.11-.88-2.11-1.97 0-1.09.94-1.97 2.11-1.97z'
			/>
			<path
				className='Cask_svg__cls-2'
				d='M23.81 38.25h-.05c-.51-.02-.98-.3-1.24-.74l-3.94-6.69c-.31-.53-.27-1.19.1-1.67l4.41-5.74c.29-.38.76-.57 1.22-.59.48.01.92.25 1.2.63l4.05 5.74c.36.51.36 1.19.01 1.7l-4.52 6.69a1.5 1.5 0 01-1.24.66zm-2.14-8.08l2.22 3.77 2.61-3.87-2.28-3.23-2.55 3.33z'
				data-name='Path 9247'
			/>
			<path
				className='Cask_svg__cls-2'
				d='M53.87 0H17.92a3 3 0 00-2.99 3v5.95h-8.8c-1.65 0-3 1.34-3 3v44.93c0 1.65 1.34 3 3 3h35.95c1.65 0 3-1.34 3-3v-2.96h8.8c1.65 0 3-1.34 3-3V3c-.01-1.65-1.35-3-3.01-3zm-11.8 56.88H6.13V10.45v1.5h35.95v44.93zm11.8-5.95h-8.8V11.95c0-1.65-1.34-3-3-3H17.93V3h35.95v47.93z'
			/>
		</svg>
	);
}
export default SvgCask;
