import Stack from '@mui/material/Stack';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Instructions from '@pw/components/Instructions';
import GeneralSourceSKU from '@pw/components/SKUSelector/modals/forms/GeneralSourceSKU';
import TrackedSKU from '@pw/components/SKUSelector/modals/source/TrackedSKU';
import { SKU_TYPES } from '@pw/consts/sku';

function SourceSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_type, sku_description } = item ?? {};

	return (
		<ModalWithClose onClose={() => onClose()} open={open} title={sku_name}>
			<Instructions>{sku_description}</Instructions>

			{sku_type === SKU_TYPES.TRACKED && (
				<TrackedSKU item={item} onClose={onClose} {...rest} />
			)}

			{![SKU_TYPES.TRACKED].includes(
				sku_type,
			) && <GeneralSourceSKU item={item} onClose={onClose} {...rest} />}
		</ModalWithClose>
	);
}

export default SourceSKUModal;
