export const shadow_1 = { boxShadow: '2px 2px 6px 2px rgba( 0, 0, 0, .05 )' };
export const shadow_2 = { boxShadow: '0 0 8px 2px rgba( 0, 0, 0, .2 )' };
export const shadow_3 = { boxShadow: '2px 2px 6px 1px rgba( 0, 0, 0, .1 )' };
export const shadow_4 = { boxShadow: '2px 2px 8px 0 rgba( 0, 0, 0, .2 )' };
export const shadow_5 = { boxShadow: '4px 4px 8px 0 rgba( 0, 0, 0, .2 )' };
export const shadow_inset_1 = {
	boxShadow: 'inset 2px 2px 6px 2px rgba( 0, 0, 0, .05 )',
};
export const shadow_inset_2 = {
	boxShadow: 'inset 0 0 8px 2px rgba( 0, 0, 0, .2 )',
};
export const shadow_inset_3 = {
	boxShadow: 'inset 2px 2px 6px 1px rgba( 0, 0, 0, .1 )',
};
export const shadow_inset_4 = {
	boxShadow: 'inset 2px 2px 8px 0 rgba( 0, 0, 0, .2 )',
};
export const shadow_inset_5 = {
	boxShadow: 'inset 4px 4px 8px 0 rgba( 0, 0, 0, .2 )',
};
