import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';

export default function ProductionOfAlcohol({
	name,
	title = 'Production of Alcohol and Spirits of 190° or more',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<FormikTextField
						name='Grain'
						label='1. Grain'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='Fruit'
						label='2. Fruit'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='Molasses'
						label='3. Molasses'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='EthylSulfate'
						label='4. EthylSulfate'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='EthyleneGas'
						label='5. EthyleneGas'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='SulphiteLiquors'
						label='6. SulphiteLiquors'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='FromRedistillation'
						label='7. FromRedistillation'
						fullWidth
						disabled={disabled}
					/>

					<FormikTextField
						name='Other_Specify'
						label='8. Other-Specify'
						fullWidth
						disabled={disabled}
					/>
				</>
			)}
		</FormikProvider>
	);
}
