function SvgBottle(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 150 150'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M131.54 93.67c-.28-.3-.67-.46-1.08-.46H94.29c-.41 0-.8.17-1.08.46-.28.3-.43.7-.41 1.11l1.71 36.18c.04.8.7 1.43 1.5 1.43h32.76c.8 0 1.46-.63 1.5-1.43l1.71-36.18c.02-.41-.13-.81-.41-1.11zm-10.4 9.45h-17.67l-.28-6.91h18.23l-.28 6.91zm-.12 3l-.48 11.91h-16.46l-.48-11.91h17.43zm6.3 23.26h-29.9L95.86 96.2h4.33l.95 23.39c.03.8.69 1.44 1.5 1.44h19.34c.8 0 1.47-.63 1.5-1.44l.95-23.39h4.46l-1.56 33.18zM61.66 99.62H38.55c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h23.11c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5zM61.66 107.3H38.55c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h23.11c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z' />
			<path d='M67.5 55.6c-3.24-.61-5.47-.73-7.02-.61a56.1 56.1 0 001.58-11.41c.05-3.26-.53-6.54-1.68-9.6h1.28a1.49 1.49 0 001.49-1.54l-.35-13.38c-.02-.81-.69-1.46-1.5-1.46H38.89c-.81 0-1.48.65-1.5 1.46l-.35 13.38c-.01.4.14.8.42 1.09.28.29.67.45 1.07.45h1.28a26.286 26.286 0 00-1.68 9.62c.13 3.84.66 7.65 1.58 11.38-1.54-.12-3.79 0-7.02.61-11.49 2.18-15.15 11.65-14.6 14.88.25 1.47 1.26 2.09 2.19 2.09h3.77c-.12.23-.19.49-.18.77l2.33 45.41c.01.22.08.43.17.62h-2.6a1.5 1.5 0 00-1.45 1.1l-2.74 10.03c-.12.45-.03.93.25 1.31.28.37.72.59 1.19.59h58.11c.47 0 .91-.22 1.19-.59s.38-.85.25-1.31l-2.74-10.03a1.5 1.5 0 00-1.45-1.1h-2.6c.1-.19.16-.39.17-.62l2.33-45.41c.01-.28-.05-.54-.18-.77h3.77c.94 0 1.94-.62 2.19-2.09.55-3.23-3.11-12.7-14.6-14.88zM40.35 20.62h19.49L60.11 31H40.08l.27-10.38zm36.84 108.77H23.01l1.92-7.03h50.34l1.92 7.03zm-3.87-56.2l-2.33 45.41c-.01.28.05.54.18.77H29.04c.12-.23.19-.49.18-.77l-2.33-45.41c-.01-.22-.07-.43-.17-.62h9.04c-.57 1.6-.9 3.32-.9 5.11 0 8.41 6.84 15.25 15.25 15.25s15.25-6.84 15.25-15.25c0-1.8-.33-3.51-.9-5.11h9.04c-.09.19-.16.39-.17.62zm-10.97 4.5c0 6.75-5.49 12.25-12.25 12.25s-12.25-5.49-12.25-12.25 5.49-12.25 12.25-12.25 12.25 5.49 12.25 12.25zm.64-8.11c-2.7-4.28-7.46-7.13-12.89-7.13s-10.18 2.86-12.89 7.13H21.04c.04-.71.34-2.07 1.32-3.74 1.27-2.19 4.38-6.04 10.9-7.27 5.7-1.08 7.44-.4 7.65-.3.48.38 1.15.44 1.69.12.6-.35.88-1.06.68-1.72a52.727 52.727 0 01-2.13-13.11c-.05-3.26.61-6.55 1.91-9.55h14.09c1.3 3 1.96 6.29 1.91 9.52-.15 4.45-.87 8.87-2.13 13.14-.2.66.09 1.37.68 1.72.54.31 1.21.26 1.69-.12.21-.1 1.94-.78 7.64.3 6.52 1.24 9.63 5.09 10.9 7.27.98 1.67 1.28 3.03 1.32 3.74H62.99z' />
		</svg>
	);
}
export default SvgBottle;
