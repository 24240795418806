import { Divider } from '@mui/material';
import GeneralInformation from './GeneralInformation';

export default function TTBReport_24() {
	return (
		<>
			<Divider />
			<GeneralInformation
				name='general_information'
				title='General Information'
			/>
			<Divider />
		</>
	);
}
