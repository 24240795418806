export const ba = { borderStyle: 'solid', borderWidth: '1px' };
export const bt = { borderTopStyle: 'solid', borderTopWidth: '1px' };
export const br = { borderRightStyle: 'solid', borderRightWidth: '1px' };
export const bb = { borderBottomStyle: 'solid', borderBottomWidth: '1px' };
export const bl = { borderLeftStyle: 'solid', borderLeftWidth: '1px' };
export const bn = { borderStyle: 'none', borderWidth: '0' };

export const b__black = { borderColor: '#000' };
export const b__white = { borderColor: '#fff' };
export const b__white_90 = { borderColor: 'rgba( 255, 255, 255, .9 )' };
export const b__white_60 = { borderColor: 'rgba( 255, 255, 255, .6 )' };
export const b__white_50 = { borderColor: 'rgba( 255, 255, 255, .5 )' };
export const b__white_10 = { borderColor: 'rgba( 255, 255, 255, .1 )' };
export const b__black_90 = { borderColor: 'rgba( 0, 0, 0, .9 )' };
export const b__black_50 = { borderColor: 'rgba( 0, 0, 0, .5 )' };
export const b__black_40 = { borderColor: 'rgba( 0, 0, 0, .4 )' };
export const b__black_20 = { borderColor: 'rgba( 0, 0, 0, .2 )' };
export const b__black_10 = { borderColor: 'rgba( 0, 0, 0, .1 )' };
export const b__dark_red = { borderColor: '#e7040f' };
export const b__transparent = { borderColor: 'transparent' };
export const b__inherit = { borderColor: 'inherit' };
export const b__initial = { borderColor: 'initial' };
export const b__unset = { borderColor: 'unset' };

export const b__border_1 = { borderColor: 'primary.border_1' };
export const b__border_2 = { borderColor: 'primary.border_2' };
export const b__border_3 = { borderColor: 'primary.border_3' };

export const bw0 = { borderWidth: '0' };
export const bw1 = { borderWidth: '1px' };
export const bw2 = { borderWidth: '2px' };
export const bw3 = { borderWidth: '3px' };
export const bw4 = { borderWidth: '5px' };
export const bw5 = { borderWidth: '9px' };
export const bw6 = { borderWidth: '12px' };
/* Resets */
export const bt_0 = { borderTopWidth: '0' };
export const br_0 = { borderRightWidth: '0' };
export const bb_0 = { borderBottomWidth: '0' };
export const bl_0 = { borderLeftWidth: '0' };

export const br0 = { borderRadius: '0' };
export const br1 = { borderRadius: '.125rem' };
export const br2 = { borderRadius: '.25rem' };
export const br3 = { borderRadius: '.5rem' };
export const br4 = { borderRadius: '1rem' };
export const br5 = { borderRadius: '1.5rem' };
export const br6 = { borderRadius: '2rem' };
export const br_100 = { borderRadius: '100%' };
export const br_pill = { borderRadius: '9999px' };

export const br__bottom = {
	borderTopLeftRadius: '0',
	borderTopRightRadius: '0',
};
export const br__top = {
	borderBottomLeftRadius: '0',
	borderBottomRightRadius: '0',
};
export const br__right = {
	borderTopLeftRadius: '0',
	borderBottomLeftRadius: '0',
};
export const br__left = {
	borderTopRightRadius: '0',
	borderBottomRightRadius: '0',
};
