const adjectives = [
  'delicious',
  'savory',
  'spicy',
  'sweet',
  'tangy',
  'zesty',
  'rich',
  'creamy',
  'flaky',
  'juicy',
  'tender',
  'crispy',
  'crunchy',
  'velvety',
  'aromatic',
  'fragrant',
  'flavorful',
  'mouthwatering',
  'tasty',
  'delectable',
  'luscious',
  'scrumptious',
  'succulent',
  'smoky',
  'buttery',
  'silky',
  'sizzling',
  'cheesy',
  'chewy',
  'robust',
  'satisfying',
  'fresh',
  'crisp',
  'tart',
  'exquisite',
  'hearty',
  'nutty',
  'spongy',
  'wholesome',
  'aged',
  'sparkling',
  'smooth',
  'fizzy',
  'effervescent',
  'bubbly',
  'refreshing',
  'lively',
  'zingy',
  'fluffy',
  'homestyle',
  'tantalizing',
  'palatable',
  'mellow',
  'decadent',
  'gourmet',
  'exotic',
  'rustic',
  'whipped',
  'delightful',
  'irresistible',
  'sumptuous',
  'opulent',
  'divine',
  'lush',
  'voluptuous',
  'incredible',
  'splendid',
  'luxurious',
  'pleasurable',
  'heavenly',
  'sensational',
  'captivating',
  'scintillating',
  'enchanting',
  'appetizing',
  'ambrosial',
  'tantalizing',
  'alluring',
  'bewitching',
  'charming',
  'magical',
  'majestic',
  'radiant',
  'bountiful',
  'breathtaking',
  'celestial',
  'enjoyable',
  'enticing',
  'fascinating',
  'glorious',
  'harmonious',
  'joyful',
  'nourishing',
  'paradisiacal',
  'ravishing',
  'serene',
  'tasteful',
  'unforgettable',
  'uplifting',
  'wholesome',
  'wondrous',
  'yummy',
  'zealous',
];

const verbs = [
  'savor',
  'enjoy',
  'relish',
  'indulge',
  'devour',
  'delight',
  'taste',
  'appreciate',
  'nourish',
  'cherish',
  'revel',
  'celebrate',
  'devote',
  'feast',
  'explore',
  'experience',
  'tantalize',
  'sip',
  'guzzle',
  'munch',
  'bite',
  'nibble',
  'imbibe',
  'quench',
  'satisfy',
  'treat',
  'sample',
  'spice',
  'season',
  'dine',
  'clink',
  'toast',
  'swirl',
  'blend',
  'create',
  'cook',
  'bake',
  'grill',
  'roast',
  'simmer',
  'saute',
  'fry',
  'boil',
  'steam',
  'garnish',
  'plate',
  'present',
  'share',
  'collaborate',
  'mix',
  'stir',
  'pair',
  'discover',
  'invent',
  'infuse',
  'craft',
  'tingle',
  'perk',
  'excite',
  'enchant',
  'wow',
  'impress',
  'experiment',
  'enrich',
  'heighten',
  'elevate',
  'rejoice',
  'mingle',
  'radiate',
  'stimulate',
  'pique',
  'entice',
  'inspire',
  'engage',
];

const words = [
  'apple',
  'banana',
  'orange',
  'grape',
  'strawberry',
  'blueberry',
  'raspberry',
  'watermelon',
  'melon',
  'peach',
  'plum',
  'pear',
  'kiwi',
  'mango',
  'pineapple',
  'avocado',
  'lemon',
  'lime',
  'grapefruit',
  'cherry',
  'apricot',
  'blackberry',
  'cranberry',
  'pomegranate',
  'fig',
  'date',
  'coconut',
  'papaya',
  'nectarine',
  'carrot',
  'broccoli',
  'spinach',
  'kale',
  'lettuce',
  'cucumber',
  'tomato',
  'bellpepper',
  'zucchini',
  'eggplant',
  'potato',
  'sweetpotato',
  'onion',
  'garlic',
  'ginger',
  'basil',
  'parsley',
  'cilantro',
  'thyme',
  'rosemary',
  'oregano',
  'mint',
  'vanilla',
  'chocolate',
  'coffee',
  'tea',
  'milk',
  'yogurt',
  'cheese',
  'butter',
  'bread',
  'rice',
  'pasta',
  'pizza',
  'hamburger',
  'sandwich',
  'soup',
  'salad',
  'cake',
  'cookie',
  'cupcake',
  'pie',
  'icecream',
  'smoothie',
  'juice',
  'wine',
  'beer',
  'cocktail',
  'whiskey',
  'vodka',
  'rum',
  'gin',
  'tequila',
  'soda',
  'water',
  'plate',
  'fork',
  'knife',
  'spoon',
  'napkin',
  'plate',
  'cup',
  'dish',
  'bowl',
  'saucer',
  'platter',
  'tray',
  'glass',
  'mug',
  'pitcher',
  'bottle',
  'can',
  'jar',
  'box',
  'baguette',
  'croissant',
  'bagel',
  'donut',
  'muffin',
  'pancake',
  'waffle',
  'crepe',
  'toast',
  'biscuit',
  'sausage',
  'bacon',
  'egg',
  'panini',
  'taco',
  'burrito',
  'sushi',
  'noodle',
  'ricecake',
  'quiche',
  'scone',
  'caviar',
  'lobster',
  'shrimp',
  'scallop',
  'crab',
  'clam',
  'oyster',
  'squid',
  'mussel',
  'octopus',
  'anchovy',
  'truffle',
  'caviar',
  'pickle',
  'salsa',
  'guacamole',
  'hummus',
  'tapenade',
  'pesto',
  'syrup',
  'honey',
  'mustard',
  'ketchup',
  'mayonnaise',
  'sauce',
  'dressing',
  'gravy',
  'relish',
  'jam',
  'jelly',
  'preserves',
  'chutney',
  'vinaigrette',
  'sugar',
  'salt',
  'pepper',
  'cinnamon',
  'nutmeg',
  'paprika',
  'cumin',
  'coriander',
  'cardamom',
  'cloves',
  'vanilla',
  'lemonade',
  'smoothie',
  'mocktail',
  'latte',
  'espresso',
  'cappuccino',
  'macchiato',
  'mocha',
  'frappe',
  'americano',
  'cider',
  'liqueur',
  'mocktail',
  'brandy',
  'eggnog',
  'punch',
  'eggs',
  'bacon',
  'sausage',
  'toast',
  'jam',
  'muffin',
  'yogurt',
  'granola',
  'porridge',
  'cereal',
  'pancake',
  'waffle',
  'syrup',
  'fruit',
  'milkshake',
  'smoothie',
  'juice',
  'coffee',
  'tea',
  'water',
  'lemonade',
  'soda',
  'popcorn',
  'pretzel',
  'nutella',
  'oatmeal',
  'baguette',
  'quinoa',
  'wasabi',
  'bokchoy',
  'paella',
  'chai',
  'bisque',
  'sorbet',
  'tempeh',
  'sprout',
  'truffle',
  'jicama',
  'cider',
  'sundae',
  'tiramisu',
  'goulash',
  'gazpacho',
  'pilaf',
  'sorbet',
  'risotto',
  'gratin',
  'pita',
  'kebab',
  'marzipan',
  'gyro',
  'curry',
  'baba',
  'tofu',
  'fig',
  'guava',
  'tangerine',
  'seltzer',
];

const getRandomValue = (min, max) => {
  const arr = new Uint32Array(1);
  const crypto = window?.crypto || window?.msCrypto;

  let randomNumber;
  if (crypto) {
    crypto.getRandomValues(arr);
    // This jazz is necessary to translate from a random integer to a floating point from 0 to 1
    randomNumber = arr[0] / (0xffffffff + 1);
  } else {
    randomNumber = Math.random();
  }
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(randomNumber * (max - min + 1)) + min;
};

const generateUsername = () => {
  const ai = getRandomValue(0, adjectives.length - 1);
  const vi = getRandomValue(0, verbs.length - 1);
  const wi = getRandomValue(0, words.length - 1);

  return `${adjectives[ai]}.${verbs[vi]}.${words[wi]}`;
};

export default generateUsername;
