import withDialogCard from '@pw/components/Cards/DialogCard';
import Roles from '@pw/components/Company/admin/Roles';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function RolesPage() {
	usePageTitleHook('Manage Roles');

	return <Roles />;
}

export default withAppLayout(withDialogCard(RolesPage));
