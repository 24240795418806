import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { useEffect, useState } from 'react';

const PAGE_SIZES = ['5', '12', '25', '50', '100'];

function Pagination({
	hasNextPage,
	hasPrevPage,
	onPressNextPage,
	onPressPrevPage,
	onChangePageSize,
	pageSize: size,
	defaultPageSize = PAGE_SIZES[0],
}) {
	return (
		<FlexBox gap={3} justifyContent='flex-end' width='100%'>
			<ItemPerPage
				size={size}
				defaultPageSize={defaultPageSize}
				onChangePageSize={onChangePageSize}
			/>
			<ArrowComponent
				onPressPrevPage={onPressPrevPage}
				hasPrevPage={hasPrevPage}
				onPressNextPage={onPressNextPage}
				hasNextPage={hasNextPage}
			/>
		</FlexBox>
	);
}

export function ItemPerPage({ size, defaultPageSize, onChangePageSize }) {
	const [pageSize, setPageSize] = useState(size ?? defaultPageSize);

	useEffect(() => {
		onChangePageSize(pageSize);
	}, [pageSize]);
	return (
		<FormControl variant='filled'>
			<InputLabel id='page_size' shrink>
				Items
			</InputLabel>
			<Select
				labelId='page_size'
				native
				variant='filled'
				options={PAGE_SIZES}
				value={pageSize}
				onChange={(e) => setPageSize(e.target.value)}
			>
				{PAGE_SIZES.map((size) => (
					<option key={size} value={size}>
						{size}
					</option>
				))}
			</Select>
		</FormControl>
	);
}

export function ArrowComponent({
	onPressPrevPage,
	hasPrevPage,
	onPressNextPage,
	hasNextPage,
	size = 'medium',
	...rest
}) {
	return (
		<Box display={'flex'} flexDirection={'row'} {...rest}>
			<IconButton
				edge='end'
				onClick={onPressPrevPage}
				disabled={!hasPrevPage}
				size={size}
			>
				<ChevronLeftIcon />
			</IconButton>
			<IconButton
				edge='end'
				onClick={onPressNextPage}
				disabled={!hasNextPage}
				size={size}
			>
				<ChevronRightIcon />
			</IconButton>
		</Box>
	);
}

export default Pagination;
