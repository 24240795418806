import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Pagination from '@pw/components/Pagination';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import { H5 } from '@pw/components/Typography';
import { useCompanyId, useCompanyPolicies } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import usePaginatedList from '@pw/utilities/hooks/logic/usePaginatedList';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import { useDispatch } from 'react-redux';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { POLICY_EDIT } from '@pw/consts/policy';
import { refetchPoliciesThunk } from '@pw/redux/thunks/policy';
import PolicyListComponent from '../admin/Policy/PolicyListItem';
import { REQUEST_NAMES } from '@pw/consts/requests';

function PolicyList() {
	const dispatch = useDispatch();
	const company_id = useCompanyId();
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(5);

	const policyList = useCompanyPolicies();

	const [page, hasPrev, hasNext, loadPrev, loadNext] = usePaginatedList({
		list: policyList,
		pageSize,
	});

	const goToItem = (params) => {
		const id = params?.policy_id ?? 'new';
		navigate(`${POLICY_EDIT}/${id}`, { replace: true });
	};

	const onClickRefetch = () => {
		dispatch(refetchPoliciesThunk());
	};

	return (
		<Stack spacing={1}>
			<Stack
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<H5>Policy List</H5>
				<TitledButton
					handleClick={onClickRefetch}
					label='Refetch'
				>
					<CachedIcon height={24} width={24} />
				</TitledButton>
			</Stack>
			{page && page.length > 0 && (
				<Stack spacing={0.5}>
					<Pagination
						hasNextPage={hasNext}
						hasPrevPage={hasPrev}
						onPressNextPage={loadNext}
						onPressPrevPage={loadPrev}
						onChangePageSize={setPageSize}
						defaultPageSize={pageSize}
					/>
					<Stack className='list'>
						{page.map((i) => (
							<PolicyListComponent
								key={i.policy_id}
								item={i}
								buttonIcon={<ArrowForward />}
								onClick={() => goToItem(i)}
							/>
						))}
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}

function PolicyNavigation() {
	const navigate = useNavigate();

	const goToItem = (params) => {
		const id = params?.policy_id ?? 'new';
		console.log('Navigating to', params, POLICY_EDIT, id);
		navigate(`${POLICY_EDIT}/${id}`);
	};

    const requestTypes = Object.entries((REQUEST_NAMES)).slice(1).map(([ key, val ]) => key);

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<Box className='section'>
				<GeneralSearch
					title='Search'
					types={{ policy_request_types: requestTypes }}
					label="Policy"
					buttonIcon={<ArrowForward />}
					onItemClick={goToItem}
				/>
			</Box>

			<PolicyList />
		</Stack>
	);
}

export default withSmallDialogCard(PolicyNavigation);
