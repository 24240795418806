const Stop = (props) => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 34 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g >
      <path d="M17.0066 0H16.9965C12.4537 0.00301793 8.18622 1.76951 4.97928 4.97556C1.77234 8.18161 0.00403951 12.45 6.89778e-06 16.994C-0.00402572 21.5309 1.76024 25.7983 4.96617 29.0083C8.1721 32.2184 12.4406 33.992 16.9844 34H17.0166C21.5392 34 25.7946 32.2395 29.0016 29.0395C32.2166 25.8325 33.9909 21.5651 34 17.0231C34.0081 12.4711 32.2388 8.19167 29.0167 4.97254C25.8067 1.76549 21.5422 0 17.0066 0ZM27.7505 27.7328C24.8903 30.5948 21.0775 32.1772 17.0156 32.1872H16.9783C12.9427 32.1872 9.148 30.6189 6.28786 27.769C3.41967 24.911 1.82981 21.0983 1.81065 17.0342C1.7915 12.9872 3.35716 9.17451 6.2183 6.29842C9.08751 3.41529 12.9104 1.82182 16.9844 1.81176C16.9965 1.81176 17.0096 1.81176 17.0227 1.81176C21.0573 1.81176 24.853 3.38109 27.7172 6.23303C30.6035 9.10711 32.1914 12.9318 32.1894 17.004C32.1873 21.0611 30.6106 24.8718 27.7505 27.7338V27.7328Z" fill="currentColor" />
      <path d="M22.0134 10.2969H11.9843C10.7805 10.2969 9.80469 11.2706 9.80469 12.4718V22.4793C9.80469 23.6804 10.7805 24.6542 11.9843 24.6542H22.0134C23.2172 24.6542 24.1931 23.6804 24.1931 22.4793V12.4718C24.1931 11.2706 23.2172 10.2969 22.0134 10.2969Z" fill="currentColor" />
    </g>
  </svg>
);

export default Stop;
