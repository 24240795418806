import NetworkStateContext from '@pw/context/NetworkStateContext';
import { useIsUsUser } from '@pw/redux/containers/User/hooks';
import { initEventListenerThunk } from '@pw/redux/thunks/init';
import { setLanguageThunk } from '@pw/redux/thunks/language';
import { useRehydrated } from '@pw/utilities/hooks/logic/useRehydrated';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function NetworkStateProvider({ children }) {
  const dispatch = useDispatch();
  const isRehydrated = useRehydrated();
  const isUsUser = useIsUsUser();
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(initEventListenerThunk());

    if (isRehydrated) {
      // Perform actions after rehydration
      console.log('Redux store has been rehydrated');
      // You can dispatch actions or perform other operations here
      if (isUsUser) {
        dispatch(setLanguageThunk({ i18n, language: 'en-US' }));
      }
    }
  }, [isRehydrated, isUsUser]);

 return (
  <NetworkStateContext.Provider value={{}}>
    {children}
  </NetworkStateContext.Provider>
 )
  }

export default NetworkStateProvider;
