import { useCompanyCountry } from '@pw/redux/containers/User';
import liquids from '@pw/consts/liquids.js';
import { useMemo } from 'react';

const useLiquidTypes = () => {
  const countryCode = useCompanyCountry();

  return useMemo(() => {
    return liquids.filter(liquid => {
      if (liquid.countries && liquid.countries.length > 0) {
        return liquid.countries.includes(countryCode);
      }
      return true;
    });
  }, [countryCode]);
}

export default useLiquidTypes;
