export const CURRENCIES = {
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
  CAD: 'CAD',
  AUD: 'AUD',
  NZD: 'NZD',
  JPY: 'JPY',
  CNY: 'CNY',
  INR: 'INR',
  ZAR: 'ZAR',
  BRL: 'BRL',
  MXN: 'MXN',
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  CAD: '$',
  AUD: '$',
  NZD: '$',
  JPY: '¥',
  CNY: '¥',
  INR: '₹',
  ZAR: 'R',
  BRL: 'R$',
  MXN: '$',
};