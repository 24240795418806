import Box from '@mui/material/Box';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import IDDisplay from '@pw/components/properties/IDDisplay';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import StrengthDisplay from '@pw/components/properties/StrengthDisplay';
import TaxCodeDisplay from '@pw/components/properties/TaxCodeDisplay';
import { AssetLinks } from './AssetLinks';

const styles = {
	marginTop: '1rem',
	border: '1px solid rgba(0,0,0,0.15)',
	borderRadius: '5px',
	padding: '0.5rem',
};

function AssetInfo({ item }) {
	const { properties = {} } = item ?? {};
	const { liquid = {}, reference } = properties;
	const { level = {} } = liquid;

	const {
		updated_bl,
		updated_la,
		updated_abv,
		updated_tcf,
		duty_paid_bl,
		duty_paid_la,
		tax_code,
	} = level;

	// console.log('AssetInfo', item);

	return (
		<>
			{!!(updated_bl && updated_la && updated_abv && updated_tcf) && (
				<Box style={styles}>
					<IDDisplay value={reference} />
					<FlexBox>
						<StrengthDisplay value={updated_abv} tcf={updated_tcf} />
						<LiquidDisplay label='Liquid' value={updated_bl} la={updated_la} />
					</FlexBox>
				</Box>
			)}
			{!!(duty_paid_bl && duty_paid_la) && (
				<Box style={styles}>
					<FlexBox>
						<LiquidDisplay
							label='Duty Paid'
							value={duty_paid_bl}
							la={duty_paid_la}
						/>
						<TaxCodeDisplay id={tax_code?.split('/').reverse()[0]} />
					</FlexBox>
				</Box>
			)}
			<AssetLinks asset={item} />
		</>
	);
}

export default AssetInfo;
