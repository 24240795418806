import { useEffect } from 'react';
import { INVENTORY_STATUS, SKU_TYPES } from '@pw/consts/sku';
import debounce from '@pw/utilities/debounce';

const useSkuAssetPick = (assets, skus, upsert) => {
	useEffect(() => {
		// console.log('Updating SKU Stats', assets.length, skus);
		skus
			.filter((s) => s.sku_type === SKU_TYPES.TRACKED)
			.forEach((s) => {
				const picked = assets.filter(
					(a) => a.properties?.sku_id === s.sku_id || a.sku_id === s.sku_id,
				);

				// Get the amount from the entries
				let amount = 0;
				(s.entries ?? []).forEach((e) => {
					amount += Number(e.amount ?? '0');
				});

				const count = picked.length;
				// console.log('Amount', amount, count);
				const processed =
					count === amount && amount > 0
						? INVENTORY_STATUS.CONFIRMED
						: s.processed || INVENTORY_STATUS.PENDING;

				debounce(() => upsert({ ...s, picked: count, processed }, false), 50);
			});
	}, [assets]);
};

export default useSkuAssetPick;
