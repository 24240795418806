import StandardLabel from '@pw/components/admin/SKUs/labels/StandardLabel';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function StandardLabelPage() {
	usePageTitleHook(`QR Labels`);

	return <StandardLabel />;
}

export default withAppLayout(withDialogCard(StandardLabelPage));
