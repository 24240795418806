import { Box } from '@mui/material';
import PolicySelector from '@pw/components/PolicySelector';
import { POLICY_STATUS } from '@pw/consts/policy';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';

function PolicyID() {
	const { enqueueSnackbar } = useSnackbar();

	const { values, setFieldValue } = useContext(FormikContext);

	const [policy, , addPolicy, , removePolicy] = useItemListManager(
		ID.policy,
		COMP.policy,
		values?.['policy_id'] ? [{ policy_id: values['policy_id'], policy_name: values['policy_name'] }] : [],
		{
			onRemove: () => {
				debounce(() => {
					setFieldValue('policy_id', undefined);
					setFieldValue('policy_name', undefined);
				}, 50);
			},
			onAdd: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							policy_id,
							policy_name,
							policy_status,
						} = items[0];

						if (policy_status === POLICY_STATUS.DISABLED) {
							enqueueSnackbar(`Disabled policies cannot be selected!`, {
								variant: 'error',
							});
							return;
						}

						setFieldValue('policy_id', policy_id);
						setFieldValue('policy_name', policy_name);
					}, 50);
				}
			},
			onUpsert: (items) => {
				if (items.length === 1) {
					debounce(() => {
						const {
							policy_id,
							policy_name,
							policy_status,
						} = items[0];

						if (policy_status === POLICY_STATUS.DISABLED) {
							enqueueSnackbar(`Disabled policies cannot be selected!`, {
								variant: 'error',
							});
							return;
						}

						setFieldValue('policy_id', policy_id);
						setFieldValue('policy_name', policy_name);
					}, 50);
				}
			},
		},
	);

	return (
		<Box sx={{ position: 'relative' }}>
			<PolicySelector
				sectionTitle='Policy'
				list={policy}
				single
				onAdd={(i) => addPolicy(i, true)}
				onRemove={(i) => removePolicy(i, true)}
				summary={false}
			/>
		</Box>
	);
}

export default PolicyID;
