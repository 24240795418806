import * as yup from 'yup';

export function explorerLocationFields(fields = {}) {
	const {
		enable = true,
		facility_id = '',
		bay = '',
		row = '',
		level = '',
	} = fields;

	return {
		enable: [enable, yup.boolean()],
		facility_id: [facility_id, yup.string().required('Facility is required!')],
		bay: [bay, yup.string().required('Bay is required!')],
		row: [row, yup.string()],
		level: [level, yup.string()],
	};
}
