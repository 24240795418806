import {
  useCallback, useEffect, useRef, useState, memo
} from 'react';

import { Box, CircularProgress } from '@mui/material';
// import { getLsItem, removeLsItem, saveToLS } from '../../functions/localStorage';

import ManagedTimer from './ManagedTimer';

import styles from './Timer.styles';
import createTimeOffset from './createTimeOffset';

const lsKey = 'PW_designer_timers';

function Timer({ id, offset, repeat, allowOvercount = false, onComplete, isStopped = false, children }) {
  // const _end = useMemo(() => createTimeOffset(offset)
  // , [offset, repeat]);
  const [running, setRunning] = useState(false);
  // const [end, setEnd] = useState(/* _end */);
  const [started, setStarted] = useState(/* new Date().getTime() */);
  const [expires, setExpires] = useState(/* end.getTime() */);
  const [remaining, setRemaining] = useState();
  const [counted, setCounted] = useState();
  const intervalId = useRef();
  const [iteration, setIteration] = useState(0);

  // work to do each tick
  const updateRemaining = useCallback(() => {
    if (isStopped) return;
    const now = Date.now();
    // const rem = Math.floor((expires - now) / 1000);
    const _counted = now - started;
    const _rem = expires - now;
    console.log({ now, _counted })
    setCounted(_counted);
    setRemaining(_rem);

  }, [started, expires, setCounted, isStopped]);

  // handler once time has completed
  const handleTimeout = useCallback(() => {
    console.log('handleTimeout. repeat:', repeat);
    console.log('clearing interval');
    clearInterval(intervalId.current);
    setRunning(false);
    // removeLsItem(lsKey, id);
    if (typeof onComplete === 'function') {
      onComplete({ id, started, offset, expires, counted, repeat });
    }
    if (repeat) setNewTimer();
  }, [intervalId]);

  const setNewTimer = useCallback(() => {
    console.log('setNewTimer');
    setRunning(true);
    const now = Date.now();
    const _end = createTimeOffset(offset);
    // console.log({ _end })
    const ts = _end.getTime();
    setIteration((prev) => prev + 1)
    // setEnd(_end);
    const lsItem = { id, iteration, expires: ts, started: now };
    // saveToLS(lsKey, lsItem);
    setStarted(now);
    setExpires(ts);
    intervalId.current = null;
    console.log('setNewTimer', { lsItem })
  }, [id, iteration])

  useEffect(() => {
    if (!id) return;
    console.log('[id]useEffect');
    // if valid timer for this product, use it
    // const _timer = getLsItem(lsKey, id);
    // const now = Date.now();
    // if (_timer && _timer.id === id) {
    //   if (_timer.started < now && _timer.expires > now) {
    //     setStarted(_timer.started)
    //     setCounted(now - _timer.started);
    //     return;
    //   }
    // }
    // else set a new timer
    setNewTimer();
  }, [id]);

  // start our tick function
  useEffect(() => {
    // console.log({ expires, intervalId: intervalId.current });
    if (!expires || intervalId.current) return;
    intervalId.current = setInterval(updateRemaining, 1000);

    return () => clearInterval(intervalId.current);
  }, [expires, updateRemaining, intervalId]);

  useEffect(() => {
    // console.log({ counted })
    if (!remaining || remaining > 0 || allowOvercount) return;
    handleTimeout();
  }, [allowOvercount, remaining, handleTimeout]);

  // const now = Date.now();
  const progress = 100 * (counted / (expires - started));
  //  counts up to 100%
  // const percent = 100 * (counted / duration);
  // counts down from 100%
  // const percent = 100 * (remaining / (expires - started));



  return (
    <Box
      sx={styles}
      className={`timer-root ${(!remaining && 'loading')} ${running ? 'running' : ''}`}
    >
      {children && <Box className="child-content">{children}</Box>}
      <Box className="content">
        <Box className="progress-container">
          <CircularProgress
            variant={remaining ? 'determinate' : 'indeterminate'}
            value={progress}
            className={`progress`}
          />
          <Box className="progress-total" />
        </Box>
        <ManagedTimer
          className="timer"
          time={counted}
        />
      </Box>
    </Box>
  );
}
export default memo(Timer);
