function SkuIcon(props) {
	return (
		<svg
			className='MuiSvgIcon-root'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
			{...props}
		>
			<defs>
				<style>{'.cls-1 {stroke-width: 0px;}'}</style>
			</defs>
			<path
				className='cls-1'
				d='M54.7,75.14c0,8.46.01,16.92,0,25.38,0,3.82-1.08,4.87-4.89,4.91-1.49.01-2.99-.02-4.48,0-2.38.02-3.47-1.27-3.62-3.51-.04-.58-.03-1.16-.03-1.74,0-16.75,0-33.51,0-50.26,0-4.57.76-5.29,5.4-5.31,7.54-.03,7.62.06,7.62,7.65,0,7.63,0,15.26,0,22.89Z'
			/>
			<path
				className='cls-1'
				d='M84.62,75.14c0,8.46.01,16.92,0,25.38,0,3.82-1.08,4.86-4.89,4.9-1.49.01-2.99-.02-4.48,0-2.38.02-3.47-1.27-3.62-3.52-.04-.66-.03-1.33-.03-1.99,0-16.59,0-33.18,0-49.77,0-4.84.69-5.53,5.41-5.55,7.53-.03,7.62.06,7.62,7.66,0,7.63,0,15.26,0,22.89Z'
			/>
			<path
				className='cls-1'
				d='M101.5,75.02c0-8.46,0-16.92,0-25.38,0-4.15.86-5.01,4.91-5.05q8.1-.08,8.12,8.16c0,15.76,0,31.52,0,47.28,0,4.49-.91,5.38-5.4,5.4-.75,0-1.49,0-2.24,0-4.75,0-5.39-.65-5.39-5.52,0-8.29,0-16.59,0-24.88Z'
			/>
			<path
				className='cls-1'
				d='M36.79,75c0,8.29,0,16.58,0,24.87,0,.91.02,1.84-.16,2.72-.36,1.76-1.5,2.72-3.32,2.77-1.85.05-2.98-.96-3.45-2.64-.24-.86-.18-1.81-.18-2.72,0-16.67,0-33.33,0-50,0-.91-.06-1.86.18-2.72.46-1.68,1.59-2.7,3.44-2.65,1.82.04,2.97.99,3.33,2.76.18.88.16,1.81.16,2.72.01,8.29,0,16.58,0,24.87Z'
			/>
			<path
				className='cls-1'
				d='M66.71,75.11c0,8.29,0,16.58,0,24.87,0,.91.01,1.84-.18,2.72-.36,1.64-1.45,2.57-3.16,2.66-1.84.11-3.02-.83-3.55-2.5-.24-.77-.22-1.64-.22-2.46-.01-16.91-.01-33.83,0-50.74,0-.74-.04-1.52.14-2.23.47-1.81,1.62-2.87,3.58-2.78,1.83.08,2.93,1.09,3.25,2.85.16.89.14,1.82.14,2.73,0,8.29,0,16.58,0,24.87Z'
			/>
			<path
				className='cls-1'
				d='M96.62,75.1c0,8.29,0,16.58,0,24.87,0,.91.01,1.84-.18,2.72-.36,1.64-1.44,2.57-3.15,2.67-1.84.11-3.02-.82-3.56-2.49-.25-.77-.22-1.64-.22-2.46-.01-16.91-.01-33.83,0-50.74,0-.74-.04-1.52.14-2.23.46-1.81,1.62-2.87,3.58-2.79,1.83.07,2.93,1.08,3.26,2.84.16.89.14,1.82.14,2.73,0,8.29,0,16.58,0,24.87Z'
			/>
			<path
				className='cls-1'
				d='M12.12,110.87c0-3.07.02-6.14,0-9.21-.01-1.65.71-2.71,2.33-3.04,1.61-.33,2.95.09,3.83,1.58.69,1.17.97,2.44.95,3.81-.04,2.99.1,5.98-.04,8.96-.11,2.32.86,3.09,3.08,2.99,2.82-.13,5.64-.06,8.46-.02,3.65.05,5.98,1.6,5.98,3.93,0,2.33-2.32,3.9-5.97,3.94-4.4.04-8.8.06-13.19,0-4.35-.07-5.44-1.21-5.45-5.46,0-2.49,0-4.98,0-7.47,0,0,.01,0,.02,0Z'
			/>
			<path
				className='cls-1'
				d='M12.1,39.03c0-2.57,0-5.14,0-7.71.01-3.65.77-4.81,4.32-4.98,5.21-.25,10.44-.1,15.66-.03.87.01,1.75.48,2.59.82,1.38.56,2.06,1.67,2.02,3.12-.04,1.35-.72,2.35-1.98,2.91-1.46.65-2.96.99-4.58.95-2.73-.07-5.48.06-8.21-.04-2.08-.08-2.79.81-2.73,2.81.09,3.06,0,6.13.03,9.2.02,1.37-.29,2.63-.99,3.79-.83,1.39-2.13,1.82-3.62,1.55-1.58-.29-2.47-1.29-2.48-2.95-.01-3.15,0-6.3,0-9.45,0,0-.02,0-.03,0Z'
			/>
			<path
				className='cls-1'
				d='M132.09,39.03c0,2.98-.02,5.97,0,8.95.02,1.81-.65,3.12-2.52,3.44-1.85.32-3.18-.43-3.98-2.21-.46-1.02-.62-2.05-.62-3.13,0-3.07-.07-6.14.02-9.2.06-2-.67-2.88-2.74-2.81-2.98.1-5.97.06-8.96,0-3.49-.06-5.75-1.59-5.82-3.83-.07-2.35,2.24-3.99,5.87-4.03,4.39-.05,8.79-.06,13.19,0,4.47.06,5.55,1.2,5.56,5.6,0,2.4,0,4.81,0,7.21,0,0,0,0-.01,0Z'
			/>
			<path
				className='cls-1'
				d='M132.1,110.92c0,2.65.01,5.3,0,7.95-.02,3.36-.91,4.65-4.3,4.8-5.21.24-10.44.11-15.66.02-.95-.02-1.92-.5-2.81-.91-1.24-.57-1.86-1.65-1.82-3,.03-1.35.68-2.38,1.95-2.92,1.3-.55,2.61-.99,4.07-.96,2.98.06,5.97-.04,8.95.03,1.8.04,2.55-.7,2.52-2.51-.06-3.15,0-6.3-.02-9.45,0-1.27.24-2.44.83-3.58.81-1.55,2.13-2.07,3.72-1.82,1.7.27,2.56,1.39,2.55,3.15,0,3.07,0,6.13,0,9.2,0,0,.01,0,.02,0Z'
			/>
		</svg>
	);
}
export default SkuIcon;
