import { Print } from '@mui/icons-material';
import { Box } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SKULocatorQRCode from '@pw/components/QRImage/SKULocatorQRCode';
import convertStyleObjectToCSS from '@pw/utilities/convertStyleObjectToCSS';
import printElement from '@pw/utilities/printElement';
import { useRef } from 'react';
import Barcode from 'react-barcode';
import styles from './index.style';

function StandardLabel({ params }) {
	const printRef = useRef();

	const {
		p: {
			l: lc, //: lot_code,
		},
		s: sku_id, //: sku_id,
		n: name, //: release?.name,
		r, //: release_id,
		c: company_id, //: company?.company_id,
		y, //: company?.company_name,
		d, //: company?.company_compliance?.excise_id,
		f, //: filledDate,
		m, //: sizeML,
		a, // : abvV,
		b, //: count,
		l, //: lcs,
		k, //: taxCode?.name,
		i, //: index/serial
	} = params;

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: convertStyleObjectToCSS(styles) });
	};

	return (
		<Box sx={styles} className="root">
			<FlexBox justifyContent="flex-end" sx={{ marginBottom: 2 }}>
				<TitledButton
					handleClick={() => handlePrint(printRef)}
					label="Print"
				>
					<Print height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box ref={printRef} sx={{ display: 'grid', gap: '10px' }}>
					<div className="container">
						<div className="label">
							<div className="long" style={{ gridColumn: '1 / span 6', gridRow: '1' }}>
								{y}
							</div>
							<div className="long" style={{ gridColumn: '1 / span 6', gridRow: '2' }}>
								{`Liquid: ${k}`}
							</div>

							{/* First row */}
							<div className="upcBarcode" style={{ gridColumn: '1 / span 6', gridRow: '3 / span 6' }}>
								<Barcode value={lc} format="ITF" />
							</div>
							{/* Fifth row */}
							<div className="upcBarcode" style={{ gridColumn: '1 / span 3', gridRow: '9 / span 7' }}>
								<SKULocatorQRCode params={params} type='s' />
							</div>
							<div className="long"
									 style={{ gridColumn: '4 / span 3', gridRow: '9' }}>{`Serial Number: `}<strong>{i}</strong>
							</div>
							<div className="long"
									 style={{ gridColumn: '4 / span 3', gridRow: '10' }}>{`Date Filled: `}<strong>{f}</strong>
							</div>
							<div className="long"
									 style={{ gridColumn: '4 / span 3', gridRow: '11' }}>{'Btl Size: '}<strong>{`${m} mL`}</strong>
							</div>
							<div className="long"
									 style={{ gridColumn: '4 / span 3', gridRow: '12' }}>{`Btls/CS: `}<strong>{b}</strong></div>
							<div className="long"
									 style={{ gridColumn: '4 / span 3', gridRow: '13' }}>{'L/CS: '}<strong>{`${l} L`}</strong></div>
							<div className="long"
									 style={{ gridColumn: '4 / span 3', gridRow: '14 / span 2' }}>{' '}</div>
						</div>
					</div>
			</Box>
		</Box>
	);
}

export default StandardLabel;
