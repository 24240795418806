/**
 * We will use normalised units in the system
 * l_s, small liquid unit
 * l_m, medium liquid unit
 * w_s, small weight unit
 * w_m, medium weight unit
 * w_l, large weight unit
 * p_m, pressure
 * d_s, small distance unit
 * d_m, medium distance unit
 * d_l, large distance unit
 * alc alcohol unit
 *
 * t: conversion factor from metric -> imperial (simply multiply)
 * f: conversion factor from imperial -> metric (simply multiply)
 * @type {{l_s: {il: string, t: number, f: number, i: string, m: string, ml: string}, l_m: {il: string, t: number, f: number, i: string, m: string, ml: string}}}
 */

export const MEASURE = {
	l_s: 'l_s',
	l_m: 'l_m',
	w_s: 'w_s',
	w_m: 'w_m',
	w_l: 'w_l',
	p_m: 'p_m',
	alc: 'alc',
	d_s: 'd_s',
	d_m: 'd_m',
	d_l: 'd_l',
	count: 'count',
	perc: 'perc',
};

export const MEASURES = {
	l_s: {
		m: 'mL',
		i: 'fl oz',
		ml: 'Milliliters',
		il: 'Fluid Ounces',
		t: 1 / 29.57,
		f: 29.57,
	},
	l_m: {
		m: 'L',
		i: 'gal',
		ml: 'Liters',
		il: 'Gallons',
		t: 1 / 3.78544,
		f: 3.78544,
	},
	w_s: {
		m: 'g',
		i: 'oz',
		ml: 'Grams',
		il: 'Ounces',
		t: 1 / 28.35,
		f: 28.35,
	},
	w_m: {
		m: 'kg',
		i: 'lb',
		ml: 'Kilograms',
		il: 'Pounds',
		t: 2.205,
		f: 1 / 2.205,
	},
	w_l: {
		m: 't',
		i: 't',
		ml: 'Tonnes',
		il: 'Tons [US]',
		t: 1.102,
		f: 1 / 1.102,
	},
	p_m: {
		m: 'Pa',
		i: 'psi',
		ml: 'Pascal',
		il: 'Pounds/Square Inch',
		t: 1 / 6894.75729,
		f: 6894.75729,
	},
	d_s: {
		m: 'mm',
		i: 'in',
		ml: 'Millimeter',
		il: 'Inch',
		t: 25.4,
		f: 1 / 25.4,
	},
	d_m: {
		m: 'm',
		i: 'yd',
		ml: 'Meter',
		il: 'Yard',
		t: 1 / 1.093613,
		f: 1.093613
	},
	d_l: {
		m: 'km',
		i: 'mi',
		ml: 'Kilometer',
		il: 'Mile',
		t: 1 / 0.6213712,
		f: 0.6213712
	},
	alc: {
		m: '%',
		i: '°',
		ml: '% ABV',
		il: 'Proof',
		t: 2,
		f: 1 / 2,
	},
	count: {
		m: '',
		i: '',
		ml: 'Count',
		il: 'Count',
		t: 1,
		f: 1,
	},
	perc: {
		m: '%',
		i: '%',
		ml: 'Percentage',
		il: 'Percentage',
		t: 1,
		f: 1,
	},
};

export const IMPERIAL_MEASURES = {
	l_s: {
		value: 'fl oz',
		label: 'Fluid Ounces',
		scale: {
			l_s: (v) => v,
			l_m: (v) => v / 128.0,
		},
	},
	l_m: {
		value: 'gal',
		label: 'Gallons',
		scale: {
			l_m: (v) => v,
			l_s: (v) => v * 128.0,
		},
	},
	w_s: {
		value: 'oz',
		label: 'Ounces',
	},
	w_m: {
		value: 'lb',
		label: 'Pounds',
	},
	w_l: {
		value: 't',
		label: 'Tons [US]',
	},
	p_m: {
		value: 'psi',
		label: 'Pounds/Square Inch',
	},
	d_s: {
		value: 'in',
		label: 'Inches',
	},
	d_m: {
		value: 'yd',
		label: 'Yards',
	},
	d_l: {
		value: 'mi',
		label: 'Miles',
	},
	alc: {
		value: '°',
		label: 'Proof',
	},
	count: {
		value: '',
		label: 'Count',
	},
	perc: {
		value: '%',
		label: 'Percentage',
	},
};

export const METRIC_MEASURES = {
	l_s: {
		value: 'ml',
		label: 'Milliliters',
		scale: {
			l_s: (v) => v,
			l_m: (v) => v / 1000.0,
		},
	},
	l_m: {
		value: 'l',
		label: 'Liters',
		scale: {
			l_m: (v) => v,
			l_s: (v) => v * 1000.0,
		},
	},
	w_s: {
		value: 'g',
		label: 'Grams',
	},
	w_m: {
		value: 'kg',
		label: 'Kilograms',
	},
	w_l: {
		value: 't',
		label: 'Tonnes',
	},
	p_m: {
		value: 'Pa',
		label: 'Pascals',
	},
	d_s: {
		value: 'mm',
		label: 'Millimeters',
	},
	d_m: {
		value: 'm',
		label: 'Meters',
	},
	d_l: {
		value: 'km',
		label: 'Kilometers',
	},
	alc: {
		value: '%',
		label: '% ABV',
	},
	count: {
		value: '',
		label: 'Count',
	},
	perc: {
		value: '',
		label: 'Percentage',
	},
};

export const ITEM_OPTIONS = [
	{
		value: 'count',
		label: 'Items',
	},
];

export const DISTRIBUTION_OPTIONS = [
	{
		value: 'perc',
		label: 'Percentage',
	},
];

export const IMPERIAL_LIQUID_OPTIONS = [
	{
		value: 'l_s',
		label: 'Fluid Ounces',
	},
	{
		value: 'l_m',
		label: 'Gallons',
	},
];

export const IMPERIAL_OPTIONS = [
	...IMPERIAL_LIQUID_OPTIONS,
	{
		value: 'w_s',
		label: 'Ounces',
	},
	{
		value: 'w_m',
		label: 'Pounds',
	},
	{
		value: 'w_l',
		label: 'Tons [US]',
	},
	{
		value: 'alc',
		label: 'Proof',
	},
	...ITEM_OPTIONS,
	...DISTRIBUTION_OPTIONS,
];

export const LIQUID_OPTIONS = [
	{
		value: 'l_s',
		label: 'Milliliters',
	},
	{
		value: 'l_m',
		label: 'Liters',
	},
];

export const METRIC_OPTIONS = [
	...LIQUID_OPTIONS,
	{
		value: 'w_s',
		label: 'Grams',
	},
	{
		value: 'w_m',
		label: 'Kilograms',
	},
	{
		value: 'w_l',
		label: 'Tonnes',
	},
	{
		value: 'alc',
		label: '% ABV',
	},
	...ITEM_OPTIONS,
	...DISTRIBUTION_OPTIONS,
];

export const MEASURES_LIST = Object.keys(MEASURES);

export const validateMeasure = (unit) => {
	if (!MEASURES_LIST.includes(unit)) {
		throw new Error('Invalid measure: ', unit);
	}
};

export const UNIT_TO_MEASURE = {
	g: 'w_s',
	kg: 'w_m',
	t: 'w_l',
	mm: 'd_s',
	in: 'd_s',
	m: 'd_m',
	yd: 'd_m',
	km: 'd_l',
	mi: 'd_l',
	ml: 'l_s',
	l: 'l_m',
	psi: 'p_m',
	pa: 'p_m',
	'%': 'alc',
	oz: 'w_s',
	lb: 'w_m',
	tn: 'w_l',
	'fl oz': 'l_s',
	gal: 'l_m',
	proof: 'alc',
	count: 'count',
};

export const toMeasure = (unit) => {
	if (MEASURES_LIST.includes(unit)) {
		return unit;
	}
	// eslint-disable-next-line no-prototype-builtins
	if (UNIT_TO_MEASURE.hasOwnProperty(unit)) {
		return UNIT_TO_MEASURE[unit];
	}
	return '';
};

export const UNITS = {
	// liquid
	milliliters: 'ml',
	fluidOunces: 'fl oz',
	liters: 'l',
	gallons: 'gal',
	// weight
	grams: 'g',
	ounces: 'oz',
	kilograms: 'kg',
	pounds: 'lb',
	tonnes: 't',
	tons: 't[us]',
	// pressure
	pascals: 'Pa',
	'pounds/square inch': 'psi',
	// distance
	millimeters: 'mm',
	centimeteers: 'cm',
	meters: 'm',
	kilometers: 'km',
	inches: 'in',
	feet: 'ft',
	yards: 'yd',
	miles: 'mi',
	// alcohol
	percentage: '%',
	proof: '°',
	// count
	count: 'count',
	// Time
	minutes: 'min',
	hours: 'hr',
	days: 'd',
	weeks: 'w',
	months: 'm',
	// temperature
	celsius: '°C',
	fahrenheit: '°F',
	kelvin: 'K',
};

// Conversion factors
export const UNITS_CONVERSIONS = {
	// Liquid conversions
	'ml to l': 1 / 1000,
	'fl oz to l': 1 / 33.814,
	'gal to l': 3.78541,
	'l to ml': 1000,
	'l to fl oz': 33.814,
	'l to gal': 1 / 3.78541,

	// Weight conversions
	'g to kg': 1 / 1000,
	'oz to kg': 1 / 35.274,
	'lb to kg': 1 / 2.20462,
	't to kg': 1000,
	't[us] to kg': 907.185,
	'kg to g': 1000,
	'kg to oz': 35.274,
	'kg to lb': 2.20462,
	'kg to t': 1 / 1000,
	'kg to t[us]': 1 / 907.185,

	// distance conversions
	'mm to m': 1 / 1000,
	'cm to m': 1 / 100,
	'km to m': 1000,
	'in to m': 0.0254,
	'ft to m': 0.3048,
	'yd to m': 0.9144,
	'mi to m': 1_609.344,
	'm to mm': 1000,
	'm to cm': 100,
	'm to km': 1 / 1000,
	'm to in': 1 / 0.0254,
	'm to ft': 1 / 0.3048,
	'm to yd': 1 / 0.9144,
	'm to mi': 1 / 1_609.344,

	// Pressure converaions
	'psi to pa': 6894.75729,
	'pa to psi': 1 / 6894.75729,

	// Alcohol conversions
	'% to °': 2,
	'° to %': 1 / 2,

	// Conversions for time
	'd to min': 24 * 60,
	'd to hr': 24,
	'd to w': 1 / 7,
	'd to m': 1 / 30,
	'w to d': 7,
	'w to m': 1 / 4,
	'm to d': 30,

	// Temperature conversions
	'°C to °F': (c) => (c * 9) / 5 + 32,
	'°F to °C': (f) => ((f - 32) * 5) / 9,
	'°C to K': (c) => c + 273.15,
	'K to °C': (k) => k - 273.15,
	'°F to K': (f) => ((f - 32) * 5) / 9 + 273.15,
	'K to °F': (k) => ((k - 273.15) * 9) / 5 + 32,
};

export const ABV_UNITS = {
	percentage: UNITS.percentage,
	proof: UNITS.proof,
};

export const ABV_UNIT_OPTIONS = [
	{ label: 'Percentage', value: UNITS.percentage },
	{ label: 'Proof', value: UNITS.proof },
];

export const LIQUID_UNITS = {
	milliliters: UNITS.milliliters,
	fluidOunces: UNITS.fluidOunces,
	liters: UNITS.liters,
	gallons: UNITS.gallons,
};

export const LIQUID_UNIT_OPTIONS = [
	{ label: 'Milliliters', value: UNITS.milliliters },
	{ label: 'Fluid Ounces', value: UNITS.fluidOunces },
	{ label: 'Liters', value: UNITS.liters },
	{ label: 'Gallons', value: UNITS.gallons },
];

export const WEIGHT_UNITS = {
	grams: UNITS.grams,
	ounces: UNITS.ounces,
	kilograms: UNITS.kilograms,
	pounds: UNITS.pounds,
	tonnes: UNITS.tonnes,
	tons: UNITS.tons,
};

export const WEIGHT_UNIT_OPTIONS = [
	{ label: 'Grams', value: UNITS.grams },
	{ label: 'Ounces', value: UNITS.ounces },
	{ label: 'Kilograms', value: UNITS.kilograms },
	{ label: 'Pounds', value: UNITS.pounds },
	{ label: 'Tonnes', value: UNITS.tonnes },
	{ label: 'Tons [US]', value: UNITS.tons },
];

export const DISTANCE_UNITS = {
	millimeters: UNITS.millimeters,
	inches: UNITS.inches,
	meters: UNITS.meters,
	feet: UNITS.feet,
	yards: UNITS.yards,
	kilometers: UNITS.kilometers,
	miles: UNITS.miles,
};

export const DISTANCE_UNIT_OPTIONS = [
	{ label: 'Millimeters', value: UNITS.millimeters },
	{ label: 'Inches', value: UNITS.inches },
	{ label: 'Meters', value: UNITS.meters },
	{ label: 'Feet', value: UNITS.feet },
	{ label: 'Yards', value: UNITS.yards },
	{ label: 'Kilometers', value: UNITS.kilometers },
	{ label: 'Miles', value: UNITS.miles },
];

export const PRESSURE_UNITS = {
	pascals: UNITS.pascals,
	'pounds/square inch': UNITS['pounds/square inch'],
};

export const PRESSURE_UNIT_OPTIONS = [
	{ label: 'Pascals', value: UNITS.pascals },
	{ label: 'Pounds/Square Inch', value: UNITS['pounds/square inch'] },
];

export const TIME_UNITS = {
	minutes: UNITS.minutes,
	hours: UNITS.hours,
	days: UNITS.days,
	weeks: UNITS.weeks,
	months: UNITS.months,
};


export const TIME_UNIT_OPTIONS = [
	{ label: 'Minutes', value: UNITS.minutes },
	{ label: 'Hours', value: UNITS.hours },
	{ label: 'Days', value: UNITS.days },
	{ label: 'Weeks', value: UNITS.weeks },
	{ label: 'Months', value: UNITS.months },
];

export const TEMPERATURE_UNIT_OPTIONS = [
	{ label: 'Celsius', value: UNITS.celsius },
	{ label: 'Fahrenheit', value: UNITS.fahrenheit },
	{ label: 'Kelvin', value: UNITS.kelvin },
];

export const UNITS_OPTIONS = [
	...ABV_UNIT_OPTIONS,
	...LIQUID_UNIT_OPTIONS,
	...WEIGHT_UNIT_OPTIONS,
	...DISTANCE_UNIT_OPTIONS,
	...PRESSURE_UNIT_OPTIONS,
	...TIME_UNIT_OPTIONS,
	{ label: 'Count', value: UNITS.count },
];

export function getBaseUnit(unit) {
	// Check if the unit is a liquid measure
	if (Object.values(LIQUID_UNITS).includes(unit)) {
		return LIQUID_UNITS.liters; // Base unit for liquids
	}

	// Check if the unit is a weight measure
	if (Object.values(WEIGHT_UNITS).includes(unit)) {
		return WEIGHT_UNITS.kilograms; // Base unit for weights
	}

	// Check if the unit is a distance measure
	if (Object.values(DISTANCE_UNITS).includes(unit)) {
		return DISTANCE_UNITS.meters; // Base unit for distances
	}

	// Check if the unit is a pressure measure
	if (Object.values(PRESSURE_UNITS).includes(unit)) {
		return PRESSURE_UNITS.psi; // Base unit for distances
	}
	// Check if the unit is a abv measure
	if (Object.values(ABV_UNITS).includes(unit)) {
		return ABV_UNITS.percentage; // Base unit for abv
	}

	return unit;
}
