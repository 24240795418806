import Stack from '@mui/material/Stack';
import {
  FormikCheckBox,
  FormikSelect
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

function CompanyType() {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.company_type === '1') {
      setFieldValue('production', true);
      setFieldValue('warehousing', true);
      setFieldValue('bottling', true);
    }
    if (values.company_type === '2') {
      setFieldValue('production', false);
      setFieldValue('warehousing', true);
      setFieldValue('bottling', false);
    }
    if (values.company_type === '3') {
      setFieldValue('production', false);
      setFieldValue('warehousing', false);
      setFieldValue('bottling', false);
    }
    if (values.company_type === '4') {
      setFieldValue('production', false);
      setFieldValue('warehousing', false);
      setFieldValue('bottling', false);
    }
    if (values.company_type === '5') {
      setFieldValue('production', false);
      setFieldValue('warehousing', true);
      setFieldValue('bottling', true);
    }
  }, [values.company_type]);

  const companyOptions = [
    { value: '1', label: 'Distillery' },
    { value: '2', label: 'Warehouse' },
    { value: '3', label: 'Brand' },
    { value: '4', label: 'Broker' },
    { value: '5', label: 'Co-Packer' },
  ];

  return (
    <Stack spacing="1.5rem">
      <H5>Profile</H5>
      <FormikSelect
        name='company_type'
        label='Company Type'
        options={companyOptions}
        required
      />

      <FlexBox>
        <FormikCheckBox
          name='production'
          label='Production'
          disabled
        />
        <FormikCheckBox
          name='warehousing'
          label='Warehousing'
          disabled
        />
        <FormikCheckBox
          name='bottling'
          label='Bottling'
          disabled
        />
      </FlexBox>
    </Stack>
  );
}

export default CompanyType;
