import {
	FormikNumberField,
	FormikSelect,
} from '@pw/components/Forms/FormikForm';
import { FormikProvider } from '@pw/providers/FormikProvider';

function FormikUnitField({
	name = 'unit',
	label = '',
	options = [],
	disabled = false,
	fullWidth = false,
}) {
	return (
		<FormikProvider path={name}>
			<FormikNumberField
				label={label}
				name='value'
				fullWidth={fullWidth}
				disabled={disabled}
				InputProps={{
					endAdornment: (
						<FormikSelect
							label='Unit'
							name='unit'
							options={options}
							disabled={disabled}
							sx={{
								marginRight: '-0.75rem',
								borderTopLeftRadius: 0,
								borderBottomLeftRadius: 0,
								minWidth: '5rem',
								maxWidth: '5rem'
							}}
						/>
					),
				}}
			/>
		</FormikProvider>
	);
}

export default FormikUnitField;
