import { enqueueSnackbar } from 'notistack';
import { getCommits } from '@pw/services/sync.service';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';

const serviceFunction = (params)=>getCommits(params).then(({items = []})=>items).catch(()=>{
    enqueueSnackbar('Error on getting local data', { variant: 'error' });
    return [];
});
export const [useGetChangesetsOfflineLazyQuery, useGetChangesetsOfflineQuery] = genServiceCallHooks(serviceFunction);

export default useGetChangesetsOfflineQuery;
