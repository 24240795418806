function SvgNft(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 358.79 384'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M184.76 0c3.55.61 7.25.76 10.6 1.92 8.09 2.81 16.5 5.22 23.93 9.32 33.1 18.27 66 37 98.79 55.73 26.94 15.38 40.49 38.81 40.62 69.79.15 36.6.08 73.2 0 109.81q-.08 47.4-41.35 70.89-48.69 27.6-97.4 55.15c-9.88 5.62-20.26 9.63-31.63 10.82a5.44 5.44 0 00-1.32.57h-15c-6.26-1.55-12.89-2.27-18.69-4.85-10.23-4.54-20.08-10-29.85-15.47-28.84-16.19-57.86-32.08-86.24-49C13.4 300.4.92 278.4.47 250.69q-.93-58.63 0-117.3c.47-28.33 13.44-50.51 37.94-64.82C72.78 48.5 107.61 29.22 142.3 9.7A72.62 72.62 0 01172.14.61a11.61 11.61 0 002.12-.61zM13.07 192c0 18.5-.09 37 0 55.49.17 25.95 11.5 45.52 34.1 58.36q49.34 28 98.77 55.85c22.16 12.51 44.52 12.69 66.7.29q50-28 99.72-56.46c22.31-12.75 33.43-32.31 33.54-57.92q.22-55.68 0-111.35c-.12-25.45-11.14-45-33.33-57.65q-50.2-28.74-100.7-57c-21.71-12.16-43.64-11.89-65.29.29q-49.77 28-99.41 56.24C24.57 91 13.26 110.56 13.09 136.52 13 155 13.07 173.51 13.07 192z' />
			<path d='M326.65 192c0 18.49-.06 37 0 55.48.07 18.71-8.07 32.54-24.31 41.71l-99.08 56.06c-15.92 9-31.77 8.94-47.69-.11Q106.09 317 56.51 289.07c-16-9-24.13-22.62-24.13-41V136c0-18.41 8.16-32 24.13-41q49.72-28.11 99.41-56.25c15.53-8.8 31.16-9 46.7-.28q50.58 28.45 101 57.2c15.11 8.63 22.85 22 23 39.41.14 18.92.03 37.92.03 56.92zm-12.79.24c0-18.62-.1-37.24 0-55.85.1-13.66-5.76-23.68-17.6-30.36Q246.73 78 197.23 50c-11.92-6.78-23.54-6.75-35.49.06Q112.46 78.11 63 105.88c-12 6.75-17.91 16.75-17.87 30.58q.18 55.47 0 111c-.05 14 6 24 18.09 30.83q49 27.51 97.74 55.34c12.44 7.13 24.4 7.28 36.85.17q49.09-28 98.39-55.72c11.83-6.68 17.77-16.64 17.69-30.31-.12-18.55-.03-37.04-.03-55.53z' />
			<path d='M121.86 214.27v-4-67.11a14.11 14.11 0 01.64-4.77 5.73 5.73 0 016.5-3.89 5.91 5.91 0 015.49 5.29 24.66 24.66 0 01.13 3.75v97.1c0 1.13.36 2.53-.16 3.32-1.29 1.94-2.73 4.58-4.62 5.09s-4.55-.91-6.53-2.08c-1.11-.66-1.55-2.49-2.22-3.83Q103.53 208 86 172.83a9.21 9.21 0 00-2.44-3.32v71.23a21 21 0 01-.11 3.37 6.34 6.34 0 01-12.6-.22 18.73 18.73 0 01-.06-2.25q0-49.68.07-99.35a9.94 9.94 0 011.79-5.84c3-3.73 8.18-2.6 10.62 2.21 5.43 10.68 10.72 21.42 16.08 32.14q10.86 21.76 21.74 43.53zM166.91 147.14v38.44h15.39c2.88 0 5.75-.05 8.63 0 4.82.1 7.67 2.45 7.74 6.3s-2.87 6.47-7.91 6.52c-6.5.06-13 0-19.49 0h-4.53v43.69c0 5.59-4.21 8.85-8.74 6.95a6.16 6.16 0 01-4-6.17v-13.5-85.86c0-7.28 1.86-9.18 9-9.18h48.37c3.93.32 6.48 2.83 6.49 6.38 0 3.79-2.54 6.33-6.79 6.35-13.25.08-26.49 0-39.74 0zM250 147.14h-17.62c-5.24 0-7.93-2.21-7.92-6.35s2.82-6.42 8-6.43h48a10.3 10.3 0 014.35.8 5.8 5.8 0 013.41 6.81 5.87 5.87 0 01-6.07 5.09c-6.23.08-12.47 0-19.32 0v92.66c0 1.12.05 2.25 0 3.37-.28 3.79-3 6.52-6.46 6.48s-6.08-2.77-6.27-6.61c-.06-1.13 0-2.25 0-3.38v-92.44z' />
		</svg>
	);
}
export default SvgNft;
