import { enqueueSnackbar } from 'notistack';
import { removeCommit } from '@pw/services/sync.service';
import { useServiceCallHook } from '@pw/utilities/hooks/service/genHooks';

const serviceFunction = (id, path) => removeCommit({ id, path })
    .then((res) => {
        enqueueSnackbar('Successfully deleted', { variant: 'success' });
        return res;
    }).catch((error) => {
        enqueueSnackbar(error?.message ?? 'Error on deleting', { variant: 'error' });
        throw error;
    });
const useDeleteChangesetOfflineMutation = useServiceCallHook(serviceFunction);

export default useDeleteChangesetOfflineMutation;
