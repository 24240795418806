function SvgTransfer(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 188.93 229.45'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M63.12 229.45c-9.85-7.55-19.15-15.77-28.72-23.65-10.45-8.61-20.81-17.32-31.21-25.98-4.39-3.66-4.37-6.4.08-10.11C22.3 153.86 41.33 138 60.35 122.14c1.58-1.32 3.2-2.45 5.43-1.81 2.37.68 3.67 2.38 3.7 5.17.05 5.73.09 11.46-.02 17.18-.04 1.85.49 2.37 2.35 2.36 20.61-.07 41.21 0 61.82-.06 18.9-.06 33.91-10.25 41.14-27.78 1.67-4.05 3.18-8.15 4.11-12.46.67-3.12 2.74-4.65 5.52-4.37 2.59.26 4.37 2.2 4.36 5.29-.04 16.22.62 32.45-.3 48.64-.61 10.76-5.55 19.98-13 27.7-11.56 11.97-25.82 17.62-42.41 17.61-20.31-.02-40.62.03-60.93-.06-2.21 0-2.71.62-2.67 2.73.14 6.92-.04 13.84.09 20.75.06 3.03-.93 5.18-3.73 6.42h-2.68zm115.5-98.43c-2.44 3.12-4.81 6.17-7.54 8.91-10.38 10.45-23.07 15.02-37.65 14.98-22.39-.06-44.78-.01-67.17-.02-4.98 0-6.69-1.71-6.69-6.7v-12.48c-15.87 13.22-31.31 26.09-46.89 39.06 15.61 13 31.06 25.87 46.89 39.06v-16.94c0-5.68 1.5-7.2 7.09-7.2 22.09 0 44.18-.02 66.27.01 7.47.01 14.66-1.29 21.41-4.52 11.62-5.57 20.05-14.11 23.37-26.82 2.34-8.96.64-18.18.91-27.35z' />
			<path d='M86.4 30.7c11.91 0 23.81-.03 35.72.04 1.64 0 2.17-.4 2.14-2.1-.09-7.14 0-14.29-.06-21.43-.02-2.56.45-4.77 3.04-5.9 2.51-1.09 4.4.05 6.22 1.7 17.44 15.88 34.91 31.73 52.36 47.6 3.98 3.62 3.97 6.21-.01 9.83-17.46 15.86-34.92 31.72-52.36 47.59-1.82 1.66-3.74 2.79-6.22 1.69-2.46-1.09-3.05-3.19-3.02-5.69.07-5.58-.03-11.16.05-16.74.02-1.54-.39-2.03-1.98-2.03-22.62.05-45.24-.15-67.86.1-19.55.21-32.58 10.38-40.43 27.81-1.8 4-3.19 8.15-4.12 12.46-.66 3.08-2.83 4.64-5.57 4.31-2.6-.32-4.31-2.24-4.3-5.36.05-16.15-.6-32.31.29-48.43.6-10.86 5.58-20.13 13.08-27.9 11.44-11.85 25.58-17.49 42-17.54 10.34-.03 20.69 0 31.03 0zm47.71 63.05c.54-.35.68-.41.79-.51 13.49-12.25 26.97-24.52 40.5-36.73 1.38-1.25.29-1.68-.42-2.33-12.88-11.71-25.76-23.42-38.65-35.11-.62-.56-1.15-1.29-2.21-1.59v16.23c0 5.31-1.59 6.89-6.93 6.89H55.55c-10.4 0-20.02 2.66-28.49 8.79-10.44 7.56-16.8 17.53-17.1 30.74-.14 6.02-.04 12.05-.02 18.08 0 .38-.23.92.47 1.08.26-.34.55-.68.8-1.04 10.76-15.47 25.59-22.93 44.4-22.86 23.95.09 47.91.02 71.86.02 4.89 0 6.64 1.71 6.65 6.5v11.85z' />
		</svg>
	);
}
export default SvgTransfer;
