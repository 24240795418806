import { Box, Stack } from '@mui/material';
import { Body3, Overline } from '@pw/components/Typography';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { Delete, Edit } from '@mui/icons-material';

function DefectItem({ item, remove, edit }) {
	// eslint-disable-next-line react/prop-types
	const { defect_type, description, images = [] } = item;

	return (
		<Stack className='listItem'>
			<Box className='listContent'>
				<Stack className='listForm'>
					<Overline>Type</Overline>
					<Body3>{defect_type}</Body3>
				</Stack>
				<Stack className='listForm' mt={0.5}>
					<Overline>Description</Overline>
					<Body3>{description}</Body3>
				</Stack>
				<Box
					className={`photos-container ${images.length > 0 ? 'has-photo' : null}`}
					mt={1.5}
				>
					<Box className='photos'>
						{images.map((image, index) => (
							<Box className='photo-item' key={index}>
								<img
									key={index}
									src={image.dataUrl}
									alt={`Photo ${index + 1}`}
									className='photo-style'
								/>
							</Box>
						))}
					</Box>
				</Box>
			</Box>
			<Stack className='listButtons'>
				{!!remove && (
					<IconCircleButton onClick={edit}>
						<Edit />
					</IconCircleButton>
				)}
				{!!edit && (
					<IconCircleButton onClick={remove}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Stack>
	);
}

export default DefectItem;
