import * as yup from 'yup';

export default function transactionsSpiritsReportFields(fields = {}) {
	const { a, b, c, d } = fields;

	return {
		a: [a, yup.number().min(0, 'Transaction value cannot be less than 0')],
		b: [
			b,
			yup
				.number()
				.min(
					0,
					'Alcohol and Spirits 190° and Over value cannot be less than 0',
				),
		],
		c: [
			c,
			yup
				.number()
				.min(0, 'Alcohol and Spirits Under 190° value cannot be less than 0'),
		],
		d: [d, yup.number().min(0, 'Other value cannot be less than 0')],
	};
}
