import Box from '@mui/material/Box';
import PWIcon from '@pw/components/icons/ProofworksIcon';
import { Link } from 'react-router-dom';

const styles = {
	'&.logo': {
		color: '#000',
		transition: 'color 0.2s',
		cursor: 'pointer',
		fontSize: '1.75rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: 'auto',

		'&:hover': {
			color: '#000',
		},

		'*': {
			color: 'inherit',
			fill: 'currentColor',
		},

		a: {
			lineHeight: 1,
		},

	},
	'.logo-svg': {
		fontSize: '1em',
		display: {
			xs: 'none',
			sm: 'initial'
		},
		height: '1em',
		width: 'auto'
	},
	'.icon-svg': {
		fontSize: '1em',
		width: '1em',
		height: '1em',
		// display: {
		// 	xs: 'initial',
		// 	sm: 'none'
		// },
	},
};

function Logo() {
	return (
		<Box sx={styles} className="logo">
			<Link to='/'>
				{/*<PWLogo className="logo-svg" />*/}
				<PWIcon className="icon-svg" />
			</Link>
		</Box>
	);
}

export default Logo;
