const objectToQueryString = (obj = {}) => {
	const isDef = (checkDefined) =>
		checkDefined && typeof checkDefined !== 'undefined' && checkDefined !== '';
	const params = Object.entries(obj)
		.map(([k, v]) =>
			isDef(k) && isDef(v)
				? typeof v === 'object'
					? `${encodeURIComponent(k)}=${encodeURIComponent(JSON.stringify(v))}`
					: `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
				: null,
		)
		.filter((kvPair) => !!kvPair)
		.join('&');
	return params ? `?${params}` : '';
};
export default objectToQueryString;
