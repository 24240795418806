import i18n from '@pw/consts/i18n';
import { UNIT } from '@pw/consts/units';
import * as yup from 'yup';

export default function liquidLevelFields(level = {}, converter) {
	const {
		enable = false,
		bl,
		la,
		abv,
		tcf = '1.000',
		action_date,
		filled_date = Date.now(),
	} = level;

	// console.log('liquidLevelFields', level);

	// The to function here will convert to imperial based on the passed in measure (l_m - medium liquid measure)
	// const { to: cto, imperial } = measures;
	const [abvMin, abvMax] = converter.range(UNIT.ALC);

	return {
		enable: [enable, yup.bool()],
		bl: [
			converter.from(bl, UNIT.BL)?.toFixed(3) ??'',
			yup.number().when('enable', {
				is: true,
				then: (schema) =>
					schema.min(0, 'Invalid!').required(`${i18n.t('bulk')} is required!`),
				otherwise: (schema) => schema,
			}),
		],
		la: [
			converter.from(la, UNIT.LA)?.toFixed(3) ?? '',
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0, 'Invalid!'),
				otherwise: (schema) => schema,
			}),
		],
		abv: [
			converter.from(abv, UNIT.ALC)?.toFixed(2) ?? '',
			yup.number().when('enable', {
				is: true,
				then: (schema) =>
					schema
						.min(abvMin, 'Invalid!')
						.max(abvMax, 'Invalid!')
						.required(`${i18n.t('strength')} is required!`),
				otherwise: (schema) => schema,
			}),
		],
		tcf: [
			tcf ?? '1.000',
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.min(0.5, 'Invalid!').max(1.5, 'Invalid!'),
				otherwise: (schema) => schema,
			}),
		],
		action_date: [
			action_date ?? filled_date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.required('Date is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
