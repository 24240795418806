import { LabelOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function NameDisplay({ label = 'Name', name }) {
	if (!name) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<LabelOutlined style={{ width: '12px', height: 'auto' }} />
			<Body3>
				<Bolder>{`${label}:`}</Bolder>
				&nbsp;
				{name}
			</Body3>
		</Box>
	);
}

export default NameDisplay;
