import { ASSET_ID, REQUEST_ID } from '@pw/consts/pk';
import { diff, flattenChangeset } from 'json-diff-ts';

export default function checkDifference(oldData, newData) {
	let options = { treatTypeChangeAsReplace: false };
	const isRequest = oldData.id === REQUEST_ID;
	const isAsset = oldData.id === ASSET_ID;
	if (isRequest && oldData?.sources) {
		// add embeddedObjKeys key
		options.sources = 'path';
	}
	if (isRequest && oldData?.destinations) {
		// add embeddedObjKeys key
		options.destinations = 'path';
	}
	if (isRequest && oldData?.sku_sources) {
		// add embeddedObjKeys key
		options.sku_sources = 'path';
	}
	if (isRequest && oldData?.sku_destinations) {
		// add embeddedObjKeys key
		options.sku_destinations = 'id';
	}
	if (isAsset && oldData?.child_assets) {
		// add embeddedObjKeys key
		options.child_assets = 'path';
	}
	if (isAsset && oldData?.sku_storage) {
		// add embeddedObjKeys key
		options.sku_storage = 'sku_name';
	}
	console.log('options', options, oldData);
	const diffs = diff(oldData, newData, options);
	console.log('diffs', diffs, flattenChangeset(diffs));
	return flattenChangeset(diffs);
}
