function SvgLogin(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				className='login_svg__cls-1'
				d='M30 1.53C14.13 1.53 1.26 14.4 1.26 30.27S14.13 59.01 30 59.01s28.74-12.87 28.74-28.74S45.87 1.53 30 1.53zm16 47.45c-9.18 7.99-22.85 7.98-32.01-.03-2.68-2.41-3.19-4.45-.11-6.7 2.37-1.73 4.81-3.37 7.45-5.22-5.03-7.79-4.91-14.35.16-18.92 4.73-4.21 11.84-4.32 16.7-.27 5.34 4.49 5.57 11.18.52 19.2 2.36 1.63 4.76 3.23 7.1 4.93 3.09 2.23 3.23 4.32.17 7.02z'
			/>
		</svg>
	);
}
export default SvgLogin;
