import Draggable from '@pw/components/ProductionDesigner/Draggable';
import tileToNode from '@pw/components/ProductionDesigner/functions/tileToNode';
import Tile from './Tile';

const DraggableTile = ({ node, setNodes, className, }) => {
  // const categoryClass = node.category === 'process'
  //   ? 'process-tile'
  //   : node.category == 'observation'
  //     ? 'observation-tile'
  //     : node.category === 'operation'
  //       ? 'operation-tile'
  //       : '';
  return (
    <Draggable
      data={node}
      onDrop={(item) => {
        setNodes((curr) => [...curr, tileToNode(item)]);
      }}
    >
      <Tile {...node} className={`${node.category}-tile ${className || ''}`} />
    </Draggable>
  )
}
export default DraggableTile;
