import Stack from '@mui/material/Stack';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5 } from '@pw/components/Typography';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { unit_price = '0' } = fields;
	return {
		unit_price: [unit_price, yup.string().required('Unit Price is required!')],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<H5>Properties</H5>
				<FormikTextField label='Unit Price' name='unit_price' fullWidth />
			</Stack>
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.SERVICE),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Service'
			skuType={SKU_TYPES.SERVICE}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, { title: 'Service SKUs' });
