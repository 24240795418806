import { useCompanyCountry } from "@pw/redux/containers/User";
import useIRCompliance from "./cc/useIRCompliance";
import useUKCompliance from "./cc/useUKCompliance";
import useUSCompliance from "./cc/useUSCompliance";
import { useMemo } from "react";

const useCompliance = () => {
  const countryCode = useCompanyCountry();
  const us = useUSCompliance();
  const uk = useUKCompliance();
  const ir = useIRCompliance();

  return useMemo(() => {
    switch (countryCode) {
      case 'US':
        return us;
      case 'GB-SCT':
      case 'GB-ENG':
      case 'GB-WLS':
      case 'GB-NIR':
      case 'GB':
        return uk;
      case 'IE':
        return ir;
      default:
        return null;
    }
  }, [countryCode]);
};

export default useCompliance; 