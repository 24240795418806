import * as yup from 'yup';

export default function spiritReportFields(fields = {}) {
	const { proofGallons, label } = fields;

	return {
		label: [label, yup.string()],
		proofGallons: [
			proofGallons,
			yup.number().min(0, 'Proof Gallons value cannot be less than 0'),
		],
	};
}
