function SvgCsv(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60.15 60.15'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M14.33 60.3c-.32-.18-.65-.32-.95-.53-.73-.52-1.11-1.25-1.15-2.14-.02-.51.31-.9.76-.96.51-.07.87.21.99.76.19.89.45 1.11 1.35 1.11h38.82c.99 0 1.34-.35 1.34-1.35 0-14.11 0-28.22.01-42.33 0-.54-.17-.64-.67-.64-2.83.02-5.67.01-8.5 0-1.99 0-3.06-1.08-3.07-3.09V2.75 2h-27.8C14.3 2 14 2.3 14 3.45v27.14c0 .21 0 .43-.01.64-.05.51-.41.85-.87.85-.45 0-.83-.36-.89-.85-.02-.15 0-.31 0-.47 0-9.15.02-18.29-.02-27.44C12.21 1.48 13.55.2 15.3.2c9.13.05 18.25.03 27.38.01 1.25 0 2.26.41 3.14 1.29 3.36 3.38 6.73 6.76 10.12 10.11.9.89 1.33 1.91 1.32 3.19-.02 13.97-.03 27.94.01 41.92 0 1.7-.51 2.91-2.13 3.54H14.33zM45.07 3.5c0 .11-.02.28-.02.45v7.26c0 .93.33 1.26 1.27 1.26h7.26c.16 0 .33-.02.44-.02-2.99-2.98-5.98-5.96-8.95-8.94z' />
			<path d='M40.45 30.28H29.12c-.13.72.21 1.81-.96 1.8-1.12-.01-.82-1.06-.92-1.8H20.6c0 .3.02.59 0 .88-.04.58-.41.97-.9.96-.49-.01-.87-.41-.88-.99-.01-1.52 0-3.05 0-4.57v-6.33c0-1.91 1.15-3.06 3.07-3.07h25.74c1.95 0 3.08 1.14 3.08 3.1v23.8c0 1.96-1.12 3.09-3.08 3.1h-5.86c-1.02 0-1.29-.26-1.29-1.27V31v-.72zm0-7.51c0-1.11-.02-2.16.01-3.21.02-.48-.13-.63-.62-.63-3.38.02-6.75.01-10.13.01h-.6v3.82h11.34zm-.03 1.87h-11.3v3.76h11.3v-3.76zm1.84 15.05h6.62v-3.76h-6.62v3.76zm0 5.68c1.93 0 3.8.01 5.66 0 .49 0 .91-.29.93-.8.05-1 .02-2.01.02-3.01h-6.61v3.82zm6.65-22.6v-2.58c0-.88-.36-1.25-1.25-1.26-1.33-.01-2.66 0-3.99 0h-1.4v3.84h6.63zm-28.29-.03h6.58v-3.79c-1.92 0-3.79-.01-5.66 0-.5 0-.89.29-.91.81-.04.99-.01 1.98-.01 2.97zm6.6 1.9h-6.6v3.74h6.6v-3.74zm21.65 0h-6.6v3.74h6.6v-3.74zm-6.6 5.63v3.77h6.59v-3.77h-6.59z' />
			<path d='M19.67 54.67H4.02c-1.94 0-3.05-1.11-3.05-3.05V37.09c0-1.85 1.13-3 2.99-3H35.5c1.84 0 2.98 1.18 2.98 3.02v14.53c0 1.88-1.16 3.02-3.04 3.02H19.67zm.06-18.78H4.08c-1.04 0-1.35.31-1.35 1.36v14.24c0 1.07.33 1.41 1.39 1.41h31.19c1.04 0 1.37-.33 1.37-1.36v-14.3c0-1.08-.28-1.35-1.35-1.35h-15.6z' />
			<path d='M20.53 49.47c-1.36-.05-2.52-.44-3.45-1.38-.46-.46-.51-.94-.14-1.34.36-.39.87-.39 1.34.04.68.64 1.48.96 2.4.83.36-.05.75-.24 1.03-.48.45-.38.41-.94-.04-1.31-.23-.19-.52-.34-.81-.46-.68-.29-1.37-.56-2.06-.83-.98-.39-1.56-1.11-1.63-2.16-.07-1.09.42-1.94 1.37-2.47 1.58-.89 3.45-.39 4.69.63.38.31.39.75.12 1.16-.26.4-.75.5-1.19.22-.65-.43-1.33-.76-2.13-.64-.28.04-.58.14-.79.31-.49.39-.4 1 .18 1.26.75.33 1.52.61 2.27.92.93.4 1.69.98 2 2 .47 1.58-.7 3.27-2.48 3.59-.25.05-.5.07-.68.09zM6.82 44.33c.09-1.82.93-3.32 2.63-4.24 1.65-.9 3.33-.84 4.96.11.65.38.8.99.35 1.45-.42.43-.85.31-1.33.04-1.48-.83-3.26-.44-4.23.9a3.165 3.165 0 00-.1 3.58c.7 1.11 2.02 1.73 3.28 1.43.47-.11.9-.43 1.29-.74.5-.39.98-.46 1.36-.08.36.36.33.9-.09 1.35-1.57 1.71-4.46 1.77-6.37.11-1.15-1.01-1.72-2.29-1.75-3.92zM29.19 46.19c.64-1.91 1.22-3.67 1.8-5.42.09-.26.15-.53.27-.77.21-.44.71-.63 1.14-.43.43.21.66.57.51 1.05a1094 1094 0 01-2.68 8.09c-.16.47-.52.74-1.02.74-.49 0-.86-.26-1.02-.73-.93-2.66-1.86-5.33-2.78-8-.19-.56.04-1.02.53-1.19.51-.18.96.06 1.17.66.68 1.94 1.36 3.89 2.08 5.99z' />
		</svg>
	);
}
export default SvgCsv;
