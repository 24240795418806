const { useIsUsUser } = require("@pw/redux/containers/User");
const dayjs = require("dayjs");
const { useMemo } = require("react");

const useDateParser = () => {
  const isUS = useIsUsUser();
  
  const ACCEPTED_DATE_FORMATS = useMemo(() => {
    const ACCEPTED_US_DATE_FORMATS = [
      'MM/DD/YY',
      'M/D/YY',
      'MM-DD-YY',
      'M-D-YY',
      'MM.DD.YY',
      'M.D.YY',
      'MM-DD-YYYY',
      'MM.DD.YYYY',
      'MM/DD/YYYY',
    ];
    const ACCEPTED_INT_DATE_FORMATS = [
      'DD-MM-YY',
      'D-M-YY',
      'DD.MM.YY',
      'D.M.YY',
      'DD/MM/YY',
      'D/M/YY',
      'DD-MM-YYYY',
      'DD.MM.YYYY',
      'DD/MM/YYYY',
    ];
    return isUS ? ACCEPTED_US_DATE_FORMATS : ACCEPTED_INT_DATE_FORMATS;
  }, [isUS]);

  const dateParse = (date) => dayjs(date, ACCEPTED_DATE_FORMATS);

  return { dateParse };
}

export default useDateParser;