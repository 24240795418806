import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllFacilities, upsertFacility } from '@pw/services/facility.service';

export const refetchFacilitiesThunk = createAsyncThunk(
  'facilities/refetch',
  async (_, { rejectWithValue }) => {
    try {
      return await getAllFacilities();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const upsertFacilityThunk = createAsyncThunk(
  'facilities/upsertFacility',
  async (facility, { rejectWithValue }) => {
	try {
		return await upsertFacility(facility);
	} catch (error) {
		return rejectWithValue(error.message);
	}
}); 