const Temperature = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.3168 12.6052C15.3168 15.1741 15.3168 17.7429 15.3168 20.3118C15.3168 20.6052 15.3883 20.7829 15.6564 20.9429C19.356 23.1741 21.2415 27.4229 20.4283 31.6985C19.642 35.8407 16.2551 39.1296 12.0105 39.8496C6.07685 40.8629 0.438122 36.5252 0.0270584 30.5607C-0.258899 26.3829 1.43897 23.1563 4.98663 20.9074C5.21897 20.7563 5.28153 20.5963 5.28153 20.3385C5.28153 15.2452 5.27259 10.1429 5.28153 5.04961C5.28153 2.72072 6.79174 0.747386 8.97217 0.178497C12.2428 -0.674836 15.2811 1.6985 15.3079 5.14739C15.3258 7.63627 15.3079 10.1163 15.3079 12.6052H15.3168ZM13.9764 12.9785C13.9764 10.3563 13.9943 7.72516 13.9764 5.10294C13.9585 2.46294 11.5279 0.694053 9.10621 1.52961C7.56025 2.06294 6.63089 3.40516 6.63089 5.15628C6.63089 10.2941 6.61302 15.4318 6.63982 20.5696C6.63982 21.2629 6.42536 21.6452 5.80876 21.9741C2.46663 23.8141 0.679399 27.8141 1.50153 31.5207C2.52025 36.1341 6.6577 39.0941 11.3849 38.5874C15.6117 38.1341 19.1058 34.4629 19.2577 30.2318C19.3828 26.4985 17.7922 23.6718 14.5215 21.8229C14.1373 21.6007 13.9764 21.3341 13.9764 20.8896C13.9943 18.2496 13.9764 15.6096 13.9764 12.9785Z" fill="currentColor" />
    <path d="M18.7836 19.1729C18.3099 19.1729 17.8363 19.1729 17.3627 19.1729C16.9248 19.1729 16.6568 18.9151 16.6389 18.5329C16.6299 18.1329 16.907 17.8485 17.3538 17.8485C18.3099 17.8396 19.2751 17.8396 20.2312 17.8485C20.678 17.8485 20.9819 18.1329 20.9819 18.5151C20.9819 18.8974 20.687 19.164 20.2312 19.1729C19.7487 19.1729 19.2572 19.1729 18.7746 19.1729H18.7836Z" fill="currentColor" />
    <path d="M18.7923 7.28016C18.3187 7.28016 17.8451 7.28016 17.3714 7.28016C16.9068 7.27127 16.6387 7.01349 16.6387 6.61349C16.6387 6.23127 16.9068 5.96461 17.3446 5.95572C18.3187 5.93794 19.2927 5.93794 20.2578 5.95572C20.6868 5.95572 20.9727 6.24016 20.9816 6.61349C20.9816 6.99572 20.6957 7.27127 20.2399 7.28016C19.7574 7.28905 19.2659 7.28016 18.7834 7.28016H18.7923Z" fill="currentColor" />
    <path d="M18.8455 11.9024C19.3191 11.9024 19.7927 11.9024 20.2663 11.9024C20.7042 11.9024 20.9812 12.1602 20.9991 12.5336C21.017 12.9158 20.7132 13.218 20.2753 13.2269C19.3012 13.2358 18.3272 13.2447 17.3532 13.2269C16.8974 13.2269 16.6293 12.9247 16.6561 12.5158C16.674 12.1336 16.9421 11.9024 17.3889 11.8936C17.8715 11.8936 18.3629 11.8936 18.8455 11.8936V11.9024Z" fill="currentColor" />
    <path d="M18.0683 4.30242C17.8002 4.30242 17.541 4.3202 17.2729 4.30242C16.8708 4.26687 16.6295 3.98242 16.6474 3.60909C16.6653 3.25353 16.9066 3.00464 17.2998 2.99576C17.8002 2.97798 18.3006 2.97798 18.801 2.99576C19.2032 3.00464 19.4712 3.2802 19.4712 3.64464C19.4712 4.0002 19.2032 4.28464 18.81 4.31131C18.5598 4.32909 18.3095 4.31131 18.0593 4.31131L18.0683 4.30242Z" fill="currentColor" />
    <path d="M18.0604 16.1865C17.7834 16.1865 17.4974 16.2043 17.2293 16.1865C16.8808 16.151 16.6753 15.9288 16.6574 15.5821C16.6395 15.2088 16.8272 14.9332 17.1936 14.9065C17.7744 14.871 18.3642 14.871 18.9451 14.9065C19.3025 14.9243 19.4812 15.2088 19.4812 15.5465C19.4812 15.9021 19.2757 16.151 18.9004 16.1865C18.6234 16.2132 18.3464 16.1865 18.0693 16.1865H18.0604Z" fill="currentColor" />
    <path d="M18.0784 8.92489C18.3197 8.92489 18.5521 8.916 18.7933 8.92489C19.2133 8.95156 19.4993 9.236 19.4814 9.60934C19.4725 9.97378 19.1955 10.2404 18.7933 10.2493C18.3019 10.2582 17.8193 10.2582 17.3278 10.2493C16.9167 10.2404 16.6576 9.98267 16.6487 9.60934C16.6397 9.21823 16.8989 8.94267 17.3278 8.92489C17.578 8.90712 17.8282 8.92489 18.0784 8.92489Z" fill="currentColor" />
    <path d="M9.63236 23.7157V23.1913C9.63236 17.8846 9.63236 12.5868 9.63236 7.28018C9.63236 7.1824 9.63236 7.08462 9.63236 6.98685C9.63236 6.31129 9.85577 5.99129 10.3115 6.00018C10.7583 6.00907 10.9728 6.32907 10.9728 7.01351C10.9728 8.37351 10.9728 9.73351 10.9728 11.0935V23.698C14.869 24.2757 16.7366 27.778 16.2451 30.6757C15.7447 33.6002 13.1532 35.778 10.2132 35.7068C7.18385 35.6357 4.67279 33.3957 4.31534 30.4357C3.93109 27.1824 6.22768 24.1602 9.63236 23.7157ZM10.2936 34.3735C12.8583 34.3735 14.9762 32.2757 14.9851 29.7157C14.9851 27.1468 12.8851 25.058 10.3026 25.058C7.72002 25.058 5.62896 27.1291 5.62896 29.7157C5.62896 32.2935 7.72002 34.3824 10.3026 34.3824L10.2936 34.3735Z" fill="currentColor" />
    <path d="M10.1599 33.147C8.3816 33.1026 6.88032 31.5915 6.85352 29.8226C6.85352 29.3781 7.06798 29.0937 7.4433 29.0581C7.85437 29.0226 8.11352 29.2626 8.17607 29.7248C8.36373 31.0404 8.9982 31.6804 10.2493 31.8137C10.705 31.8581 10.982 32.1337 10.9552 32.5248C10.9284 32.907 10.6067 33.1559 10.1599 33.147Z" fill="currentColor" />
  </svg>
);

export default Temperature;
