function AuditIcon(props) {
	return (
		<svg
			{...props}
			className='MuiSvgIcon-root'
			id='Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 142.6 142.59'
		>
			<defs>
				<style>{'.cls-1 {stroke-width: 0px;}'}</style>
			</defs>
			<path
				className='cls-1'
				d='M98.84,105.98c.5.75,1.12,1.47,1.77,2.23.32.37.64.74.95,1.12l6.96,8.51c5.42,6.64,10.85,13.27,16.28,19.9,1.25,1.53,2.79,2.41,4.44,2.55,1.64.14,3.29-.46,4.77-1.75,1.41-1.22,2.75-2.62,3.94-3.87,1.61-1.69,2.41-3.49,2.3-5.21-.11-1.73-1.12-3.42-2.94-4.91l-7.84-6.42c-7.7-6.31-15.67-12.83-23.53-19.2-2.01-1.62-2.23-3.36-.7-5.46,9.67-13.31,13.18-28.22,10.43-44.32-2.59-15.13-11.17-28.32-24.18-37.15C78.6,3.25,62.63.05,47.68,3.22,20.8,8.91,2.02,32.11,2.01,59.63v.04c-.07,2.96.25,6.32.98,10.28,4.88,26.51,27.25,45.7,54.39,46.68,13.16.46,25.33-3.36,36.13-11.39.46-.34,1.36-1.01,2.5-1.01.16,0,.32.01.49.04,1.35.23,2.1,1.34,2.34,1.7ZM94,94.09c-9.26,9.24-21.61,14.33-34.77,14.33h-.07c-26.97-.04-48.89-22.07-48.88-49.1-.15-12.92,4.9-25.18,14.23-34.54,9.4-9.44,21.77-14.55,34.94-14.47,13.15.11,25.45,5.23,34.64,14.44,9.21,9.22,14.28,21.53,14.27,34.66,0,13.13-5.11,25.45-14.36,34.68Z'
			/>
			<path
				className='cls-1'
				d='M55.1,87.2c1.31-.03,2.75-.06,4.25-.06,1.25,0,2.55.02,3.85.07.07,0,.1-.01.18,0,0-.07,0-.16,0-.27-.06-6.77-.05-13.65-.04-20.3v-7.08s0-7.12,0-7.12c0-6.81-.01-13.85.04-20.78,0-.06,0-.12,0-.16-.03,0-.07,0-.1,0-2.57.1-5.29.1-8.11,0-.03,0-.05,0-.07,0,0,.03,0,.06,0,.1.03,18.54.03,37.24,0,55.58,0,0,.01,0,.02,0Z'
			/>
			<path
				className='cls-1'
				d='M84.12,43.33c-2.63.09-5.24.07-8.31.02.03,16.29.03,30.63,0,43.85h0s0,.01,0,.01c1.7-.04,3.39-.03,5.09-.02.97,0,1.95,0,2.91,0,.08,0,.18,0,.29,0,0-.14,0-.27,0-.38,0-5.21,0-10.41,0-15.62v-11.36c-.01-5.35-.02-10.89.02-16.33,0-.06,0-.12,0-.17Z'
			/>
			<path
				className='cls-1'
				d='M38.61,87.17c1.39,0,2.79,0,4.19.02-.02-10.68-.02-21.35,0-32.03-3.05.03-5.65.04-8.27-.01.04,3.9.04,7.85.03,11.67v4.35s0,4.18,0,4.18c0,3.94.01,7.89-.02,11.83,1.34-.02,2.7-.02,4.07-.02Z'
			/>
		</svg>
	);
}
export default AuditIcon;
