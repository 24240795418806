import { Download, Print } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import QRImage from '@pw/components/QRImage';
import { H6 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { toUniqueLocationId } from '@pw/utilities/format';
import useEncodeLocationQrCode from '@pw/utilities/hooks/logic/useEncodeLocationQrCode';
import useDownloadFacilityAssetsLazyQuery from '@pw/utilities/hooks/service/useDownloadFacilityAssetsQuery';
import useDownloadLazyQuery from '@pw/utilities/hooks/service/useDownloadQuery';
import printElement from '@pw/utilities/printElement';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { QrCodes } from '../InventorySelector/PrintModal';

function Toolbar() {
	const { enqueueSnackbar } = useSnackbar();
	const { values } = useContext(FormikContext);
	const [download, { isLoading: isDownloading }] = useDownloadLazyQuery();
	const [fetchAssets] = useDownloadFacilityAssetsLazyQuery();
	const [printing, setPrinting] = useState(false);

	const facilityId = useMemo(() => {
		if (values.location?.bay) {
			return `${values.location?.facility_id}/${values.location?.bay}`;
		}
		return values.location?.facility_id;
	}, [values.location?.facility_id, values.location?.bay]);

	const currentAddress = useMemo(
		() => {
			console.log('Location', values?.location);
			return toUniqueLocationId({
				facility_id: facilityId,
				row: values.location?.row,
				level: values.location?.level,
			})
		},
		[facilityId, values.location?.row, values.location?.level],
	);

	console.log('Current address', currentAddress);

	const encodedQrData = useEncodeLocationQrCode(
		'dt',
		facilityId,
		values.location?.row,
		values.location?.level);

	const handleDownload = useCallback(async () => {
		try {
			await download({ facility_id: facilityId });
			enqueueSnackbar(
				'Downloading data has been completed. You are ready to go!',
				{ variant: 'success' },
			);
		} catch (error) {
			enqueueSnackbar('Failed to download data!', { variant: 'error' });
		}
	}, [download, enqueueSnackbar, facilityId]);

	const handlePrint = useCallback(async () => {
		const request = {
			facility_id: facilityId,
			row: values.location?.row,
			level: values.location?.level
		};
		console.log('Fetching assets', request);

		const assets = await fetchAssets(request);

		if (assets?.length) {
			setPrinting(true);
			const element = renderToString(QrCodes(assets));
			printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
			setPrinting(false);
		}
	}, [
		fetchAssets,
		facilityId,
		values.location?.level,
		values.location?.row,
	]);

	return (
		<Stack spacing={0.25}>
			<FlexBox>
				{currentAddress && (
					<Stack direction='row' spacing={1} alignItems='center'>
						<QRImage boxSize={60} isLogoVisible value={encodedQrData} />
						<H6 sx={{ whiteSpace: 'nowrap' }}>{currentAddress}</H6>
					</Stack>
				)}
				<FlexBox justifyContent='flex-end'>
					<TitledButton
						handleClick={handlePrint}
						disabled={printing}
						label='Print'
					>
						<Print height={24} width={24} />
					</TitledButton>
					<TitledButton
						handleClick={handleDownload}
						label='Offline'
					>
						<Download height={24} width={24} />
					</TitledButton>
				</FlexBox>
			</FlexBox>
			{isDownloading && <LinearProgress color='primary' />}
		</Stack>
	);
}

export default Toolbar;
