import { CheckBoxRounded, TaskOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { ASSET_NAMES, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { useCallback, useMemo } from 'react';

function LinkedAssetItem({ asset, selected = false, onSelect }) {
	const { asset_id, rw_asset_id, asset_type, processed } = asset;

	const onClick = useCallback(() => {
		if (onSelect) {
			onSelect(!selected);
		}
	}, [selected, onSelect]);

	const cardStyle = useMemo(() => {
		if (onSelect) {
			return '';
		}
		switch (processed) {
		case ASSET_PROCESSED_STATUS.PENDING:
			return 'card-tab-pending';
		case ASSET_PROCESSED_STATUS.CONFIRMED:
			return 'card-tab-confirmed';
		default:
			return '';
		}
	}, [processed, onSelect]);

	return (
		<Box className='card' action={ASSET_NAMES[asset_type]} onClick={onClick}>
			<Box className={`card-tab ${cardStyle}`}>
				{selected && <CheckBoxRounded className='check' />}
				{processed && processed === ASSET_PROCESSED_STATUS.CONFIRMED && (
					<TaskOutlined className='check' />
				)}
			</Box>
			<Box className='card-content'>
				<IDDisplay value={rw_asset_id ?? asset_id} />
				<SourceDisplay type={ASSET_TYPES_REVERSE[asset_type]} name={ASSET_NAMES[asset_type]} />
			</Box>
		</Box>
	);
}

export default LinkedAssetItem;
