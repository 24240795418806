import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import BulkLiquidLevel from '@pw/components/Liquid/BulkLiquidLevel';
import LOALiquidLevel from '@pw/components/Liquid/LOALiquidLevel';
import liquidLevelFields from '@pw/components/Liquid/liquidLevelFields';
import LiquidSource from '@pw/components/LiquidSource';
import skuFields from '@pw/components/SKUSelector/skuFields';
import { H5 } from '@pw/components/Typography';
import { LIQUID_ACCOUNT_TYPE } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import LiquidWeight from './LiquidWeight/LiquidWeight';
import liquidWeightFields from './LiquidWeight/liquidWeightFields';
import { useIsUsUser } from '@pw/redux/containers/User';
import RevertMessage from '../Forms/RevertMessage';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';
import isEqual from 'lodash.isequal';

export function liquidPropertyFields(
	params = {},
	converter,
	enableWeights = false,
	useLiquidAccount = false,
) {
	const {
		enable = true,
		liquid: tags = [],
		date = Date.now(),
		batch = '',
		level = {},
		weight = {},
		sku_id = '',
		sku = {},
		liquid_account = LIQUID_ACCOUNT_TYPE.storage,
	} = params;

	return {
		enable: [enable, yup.bool()],
		date: [
			date,
			yup.number().when('enable', {
				is: true,
				then: (schema) => schema.required('Production date is required!'),
				otherwise: (schema) => schema,
			}),
		],
		batch: [batch, yup.string()],
		liquid: [tags, yup.array().of(yup.string())],
		...(useLiquidAccount
			? { liquid_account: [liquid_account, yup.string()] }
			: {}),
		...skuFields(sku_id, sku),
		level: liquidLevelFields(level, converter),
		...(enableWeights ? { weight: liquidWeightFields(weight) } : {}),
	};
}

function LiquidPropertiesImpl({
	disabled = false,
	useWeight = false,
	useLiquidSelector = true,
	useBulk = true,
	useLiquidAccount = false,
}) {
	const isUsUser = useIsUsUser();
	const { values, setFieldValue } = useContext(FormikContext);

	useEffect(() => {
		if (!values?.enable) {
			process.nextTick(() => {
				setFieldValue('level.enable', false);
				if (!isUsUser && useWeight) setFieldValue('weight.enable', false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.weight?.enable, values?.enable, useWeight]);

	return (
		<Stack>
			<FormikCheckBox
				name='enable'
				label={<H5>Liquid Properties</H5>}
				disabled={disabled}
			/>

			<Collapse
				in={!!values?.enable}
				sx={{ paddingTop: '0.5rem' }}
				unmountOnExit
			>
				<Stack spacing={2}>
					<LiquidSource
						disabled={disabled}
						useLiquidAccount={useLiquidAccount}
						useLiquidSelector={useLiquidSelector}
					/>
					{useBulk && <BulkLiquidLevel name='level' disabled={disabled} />}
					{!useBulk && <LOALiquidLevel name='level' disabled={disabled} />}
					{!isUsUser && useWeight && (
						<LiquidWeight name='weight' disabled={disabled} />
					)}
				</Stack>
			</Collapse>
		</Stack>
	);
}

export default function LiquidProperties({
	name,
	disabled = false,
	useWeight = false,
	useBulk = true,
	useLiquidAccount = false,
	entity,
}) {
	const { values, setFieldValue } = useContext(FormikContext);
	const [historyExist, isSame, history] = useAssetRevertHook(entity, (e, e2) =>
		isEqual(e?.properties.liquid, e2?.properties.liquid),
	);
	const onRevert = useCallback(() => {
		const liq = history?.properties.liquid;
		setFieldValue('liquid', {
			...liq,
			level: {
				...liq.level,
				abv: liq.level.abv ?? '',
				bl: liq.level.bl ?? '',
				la: liq.level.la ?? '',
			},
		});
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		const liq = entity?.properties.liquid;
		setFieldValue('liquid', {
			...liq,
			level: {
				...liq.level,
				abv: liq.level.abv ?? '',
				bl: liq.level.bl ?? '',
				la: liq.level.la ?? '',
			},
		});
	}, [entity, setFieldValue]);

	const isReverted = useMemo(() => {
		return isEqual(values['liquid'], history?.properties?.liquid)
	}, [history, values])

	return (
		<FormikProvider path={name}>
			<Box sx={{ position: 'relative' }}>
				{historyExist && !isSame && (
					<RevertMessage
						title='Liquid properties'
						onRevert={onRevert}
						isReverted={isReverted}
						onUndo={onUndo}
						item={history?.properties?.liquid}
						type="liquid"
					/>
				)}
				<LiquidPropertiesImpl
					disabled={disabled}
					useWeight={useWeight}
					useBulk={useBulk}
					useLiquidAccount={useLiquidAccount}
				/>
			</Box>
		</FormikProvider>
	);
}
