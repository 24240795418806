import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import {
	FormikDatePicker,
	FormikNumberField,
	FormikSelect,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const {
		collection_id = '',
		sales_status = 'active',
		listing_date = Date.now(),
		sale_price,
		storage_price,
	} = fields;

	return {
		collection_id: [
			collection_id,
			yup.string().required('Collection Id is required!'),
		],
		sales_status: [sales_status, yup.string()],
		listing_date: [listing_date, yup.number()],
		sale_price: [sale_price, yup.number().required('Sale price is required!')],
		storage_price: [storage_price, yup.number()],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<H5>Marketplace Link</H5>
				<FormikTextField
					label='Marketplace Collection'
					name='collection_id'
					fullWidth
				/>
				<FlexBox>
					<FormikSelect
						label='Status'
						name='sales_status'
						options={[
							{ label: 'Active', value: 'active' },
							{ label: 'Paused', value: 'paused' },
							{ label: 'Completed', value: 'completed' },
						]}
						fullWidth
					/>
					<FormikDatePicker
						sx={{ ...mt2 }}
						name='listing_date'
						label='Listing Date'
						fullWidth
						required
					/>
				</FlexBox>
				<FlexBox>
					<FormikNumberField label='List Price' name='sale_price' fullWidth />
					<FormikNumberField
						label='Storage Price'
						name='storage_price'
						fullWidth
					/>
				</FlexBox>
			</Stack>
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.WIP),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Sales Link'
			skuType={SKU_TYPES.WIP}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, { title: 'Sales Link SKUs' });
