import { createKey } from "../nodes";

const nodeTypeLookup = (type) => {
  switch (type) {
    case 'process':
      return 'processNode';
    case 'material':
      return 'materialNode';
    case 'observation':
      return 'observationNode';
    case 'operation':
      return 'operationNode';
    // just for debugging
    default:
      return 'unknownNode';
  }
}

const tileToNode = (item) => {
  if (!item) return null;
  const { position } = item;
  return {
    id: createKey(item),
    data: item,
    position,
    // dragHandle: '.icon-label-group',
    // position: { x: (itemWidth + itemGap) * i - (i > 0 ? itemGap : 0), y: 0 },
    type: nodeTypeLookup(item.category),
  }
}

export default tileToNode;
