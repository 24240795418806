import { AddCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { RequestTypeSelector } from '@pw/components/admin/SKUPolicies/PolicyModal';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Client from '@pw/components/Client';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import { Body3, H5 } from '@pw/components/Typography';
import { POLICY_STAGES, POLICY_STATUSES } from '@pw/consts/policy';
import FormikContext from '@pw/context/FormikContext';
import { upsertPolicyThunk } from '@pw/redux/thunks/policy';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PolicyEntryList from './PolicyEntryList';
import PolicyEntryModal from './PolicyEntryModal';

function PolicyEntrySection({ initialValues }) {
  const [selected, setSelected] = useState(null);
  const { values, setFieldValue } = useContext(FormikContext);
  const [entries, , , upsert, remove] = useItemListManager(
    ID.name,
    COMP.name,
    initialValues ?? [],
  );
  const handleClose = useCallback((entry) => {
    if (entry) {
      upsert(entry);
    }
    setSelected(null);
  }, [upsert, setSelected]);

  useEffect(() => {
    console.log('Updating entries', entries);
    debounce(() => setFieldValue('entries', entries), 25);
  }, [entries]);

  return (
    <>
      <Stack spacing={1} className="section">
        <H5 className="section-header">Entries</H5>

        <Stack className="inventory">
          <Box className="inventory-header">
            <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
            <Stack direction="row" spacing={1}>
              <TitledButton
                handleClick={() => setSelected({})}
                label="New"
              >
                <AddCircle height={24} width={24} />
              </TitledButton>
            </Stack>
          </Box>
          {entries.length > 0 && (
            <PolicyEntryList
              items={entries}
              onEdit={(i) => setSelected(i)}
              onRemove={remove}
            />
          )}
          {entries.length === 0 && (
            <Box p={2} className="inventory-footer">
              <Body3>None</Body3>
            </Box>
          )}
        </Stack>
      </Stack>
      {!!selected &&
        <PolicyEntryModal item={selected} open={!!selected} onClose={handleClose} stage={values?.policy_stage} />}
    </>
  );
}

function PolicyForm({
  id,
  entity,
  changeSet,
  readonly = false,
  edit = false,
  onCancel,
  onBeforeSave,
  onSave,
}) {
  const dispatch = useDispatch();
  const { entries = [] } = entity ?? {};

  const handleSubmit = async (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;

    const request = {
      ...(entity ?? {}),
      ...updated,
    };

    console.log('Submitting', request);

    dispatch(upsertPolicyThunk(request))
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <>
      <FormikForm
        changeSet={changeSet}
        onSubmit={handleSubmit}
        edit={edit}
        readonly={readonly}
      >
        <FormWrapper>
          <Stack spacing="1.5rem">
            <FlexBox alignItems="top">
              <FormikTextField label="Name" name="policy_name" fullWidth />
            </FlexBox>

            <FlexBox alignItems="top">
              <FormikSelect
                label="Stage"
                name="policy_stage"
                options={POLICY_STAGES}
                fullWidth
              />
              <FormikSelect
                label="Status"
                name="policy_status"
                options={POLICY_STATUSES}
                fullWidth
              />
            </FlexBox>
          </Stack>

          <PolicyEntrySection initialValues={entries} />

          <Client name="client" noCustomers={true} />

          <Stack spacing="1.5rem">
            <H5>Default Policy</H5>
            <RequestTypeSelector />
          </Stack>

          {!readonly && (
            <>
              <Errors />
              <Box className="action-buttons">
                {onCancel && (
                  <TextButton
                    size="small"
                    handleClick={onCancel}
                    color="secondary"
                    label="Cancel"
                  />
                )}
                <FilledButton type="submit" size="small">
                  {id?.length ? 'Update' : 'Add'}
                </FilledButton>
              </Box>
            </>
          )}
        </FormWrapper>
      </FormikForm>
    </>
  );
}

export default PolicyForm;
