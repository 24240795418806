import { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { createSearchParams, useNavigate } from 'react-router-dom';

function SearchInput({ Icon = Search }) {
	const [value, setValue] = useState('');
	const navigate = useNavigate();

	const handleChange = useCallback((event) => {
		setValue(event.target.value);
	}, []);

	const handleKeyUp = useCallback(
		(event) => {
			if (event.key === 'Enter') {
				navigate({
					pathname: '/app/search',
					search: createSearchParams({ searchValue: value }).toString(),
				});
			}
			if (event.key === 'Escape') {
				setValue('');
			}
		},
		[navigate, value],
	);

	return (
		<TextField
			value={value}
			onChange={handleChange}
			onKeyUp={handleKeyUp}
			className='app-header-search'
			placeholder='Search'
			variant='filled'
			size='small'
			margin='none'
			fullWidth
			InputProps={{
				disableUnderline: true,
				hiddenLabel: true,
				sx: { padding: 0, margin: 0 },
				startAdornment: (
					<InputAdornment
						position='end'
						sx={{ marginRight: '0.35rem', fill: '' }}
					>
						<Icon height={24} width={24} />
					</InputAdornment>
				),
			}}
		/>
	);
}

export default SearchInput;
