import ArrowForward from '@mui/icons-material/ArrowForward';
import { Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetSKUStorageItem from '@pw/components/SKUSelector/items/AssetSKUStorageItem';
import { Body3 } from '@pw/components/Typography';

export default function ContainerListItem({
	buttonIcon = <ArrowForward />,
	onClick = undefined,
	skuStorage = [],
}) {
	return (
		<Stack spacing={1.25} className='listItem listCol'>
			<Stack spacing={1.5}>
				{skuStorage.length === 0 && skuStorage.length === 0 && (
					<Stack className='listContent'>
						<Body3>Empty</Body3>
					</Stack>
				)}
				{skuStorage.length > 0 && (
					<FlexBox
						className='asset-container'
						justifyContent='start'
						gap='0.75rem'
					>
						{skuStorage.map((result) => (
							<AssetSKUStorageItem
								key={result.sku_entry_id}
								item={result}
								buttonIcon={buttonIcon}
								onClick={onClick}
							/>
						))}
					</FlexBox>
				)}
			</Stack>
		</Stack>
	);
}
