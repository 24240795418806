import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import ContainerListItem from '@pw/components/AssetListItem/ContainerListItem';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import InventorySelector from '@pw/components/InventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import SKUField from '@pw/components/SKUSelector/SKUField';
import skuFields from '@pw/components/SKUSelector/skuFields';
import templateFields from '@pw/components/TemplateHandler/templateFields';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { SKU_TYPES } from '@pw/consts/sku';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

function ChildAssets({ childAssets, addAsset, removeAsset }) {
	return (
		<>
			<InventorySelector
				title='Assets'
				list={childAssets}
				types={{ asset_types: [ASSET_TYPES.cask, ASSET_TYPES.ibc] }}
				onAdd={addAsset}
				onRemove={removeAsset}
			/>
		</>
	);
}

function propertyFields(fields = {}) {
	const { pallet: tags = [], sku_id = '', sku = {} } = fields;

	return {
		pallet: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
		...skuFields(sku_id, sku),
	};
}

function Properties({ entity }) {
	const filter = (item) => item?.properties?.asset_type === ASSET_TYPES.pallet;

	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<H5>Pallet Properties</H5>
				<SKUField
					entity={entity}
					title='Type'
					skuTypes={[SKU_TYPES.TRACKED]}
					tags='pallet'
					itemFilter={filter}
				/>
				<TagSelect entity={entity} name='pallet' type='pallet' />
			</Stack>
		</FormikProvider>
	);
}

function PalletForm(props) {
	const [skuStorage, initSkuStorage, , ,] = useItemListManager(
		ID.sku,
		COMP.sku,
	);
	const [childAssets, init, addAsset, , removeAsset] = useItemListManager(
		ID.asset,
		COMP.asset,
	);
	const [entity, setEntity] = useState(null);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...templateFields(),
			...assetIdFields(initialValues),
			location: locationFields(initialValues.location),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleInit = (entity) => {
		const { child_assets = [], sku_storage = [] } = entity ?? {};
		setEntity(entity);
		sku_storage.sort(COMP.sku);
		debounce(() => initSkuStorage(sku_storage), 50);
		child_assets.sort(COMP.asset);
		debounce(() => init(child_assets), 50);
	};

	// On the way out, add the children and sku
	const handleBeforeSave = useCallback(
		(entity) => {
			return {
				...entity,
				child_assets: childAssets,
				unique_location_id: toUniqueLocation(entity.location),
			};
		},
		[childAssets],
	);

	return (
		<AssetForm
			assetLabel='Pallet'
			assetType={ASSET_TYPES.pallet}
			changeSetGenerator={changeSetGenerator}
			onInit={handleInit}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Location entity={entity} name='location' />

			<Properties entity={entity} />

			<ChildAssets
				childAssets={childAssets}
				addAsset={addAsset}
				removeAsset={removeAsset}
			/>

			<Stack spacing={2} className='section'>
				<H5 className='section-header'>SKUs</H5>
				<FlexBox gap={1} justifyContent='space-between'>
					<ContainerListItem skuStorage={skuStorage} />
				</FlexBox>
			</Stack>
		</AssetForm>
	);
}

export default withAppLayout(PalletForm, { title: 'Pallet Management' });
