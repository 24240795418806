import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import React, { useCallback, useMemo } from 'react';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import styles from '@pw/styles/modal.styles';
import toLocation from '@pw/utilities/adapters/toLocation';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import useAlertView from '@pw/utilities/hooks/components/useAlertView';
import { Pallet } from './DestinationAssetModal';

function AssetLocation({ item, onClose, items }) {
	const { unique_location_id } = item ?? {};

	const [AlertView, { setAlert }] = useAlertView();

	const changeSet = useMemo(
		() => ({
			location: locationFields(toLocation(unique_location_id)),
		}),
		[unique_location_id],
	);
	const handleSubmit = useCallback(
		(values) => {
			const { location } = values;
			const unique_location_id = toUniqueLocation(location || {});
			const empty_location = toUniqueLocation({});
			console.log('Location', unique_location_id, empty_location);
			if (unique_location_id !== empty_location) {
				// See if there is another asset with the same location id
				const existing = items?.find(
					(i) =>
						i.unique_location_id !== empty_location &&
						i.unique_location_id === unique_location_id &&
						i.asset_id !== item.asset_id,
				);
				if (existing) {
					setAlert({
						severity: 'warning',
						title: 'Asset at location',
						content: `Asset ${existing.rw_asset_id} is already at this location!`,
					});
					return;
				}
			}

			const asset = { ...item, unique_location_id };
			onClose(asset);
		},
		[item, onClose],
	);
	return (
		<FormikForm
			changeSet={changeSet}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			<Stack spacing={2}>
				<Instructions>Select the location</Instructions>
				<Location name='location' showCheckBox={false} />

				<AlertView />

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
					>
						Cancel
					</TextButton>
					<FilledButton type='submit' size='small' disabled={!location}>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

function RestockAssetModal({ item, items, open, onClose }) {
	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={item.rw_asset_id}>
			{[ASSET_TYPES.pallet].includes(item.asset_type) && (
				<Pallet
					item={item}
					items={items
						?.filter(
							(i) =>
								!i.parent_asset_id &&
								![ASSET_TYPES.pallet, ASSET_TYPES.container].includes(
									i.asset_type,
								),
						)
						.filter(
							(i) => !item?.children?.find((s) => s.asset_id === i.asset_id),
						)}
					onClose={onClose}
				/>
			)}
			{item.asset_type !== ASSET_TYPES.pallet && (
				<AssetLocation item={item} items={items} onClose={onClose} />
			)}
		</ModalWithClose>
	);
}

export default RestockAssetModal;
