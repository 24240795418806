import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PolicyID from '@pw/components/admin/SKUPolicies/PolicyID';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import { REQUESTS_WITH_POLICIES } from '@pw/consts/requests';
import FormikContext from '@pw/context/FormikContext';
import { FieldArray } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';
import * as yup from 'yup';

export function RequestTypeSelector() {
	const { values } = useContext(FormikContext);

	const requestTypes = Object.entries(REQUESTS_WITH_POLICIES).map(([key, value]) => ({ value: key, label: value }));

	return (
		<Stack spacing="0.33rem">
			<Box className="fixedList">
				<FieldArray
					name="requests"
					render={(arrayHelpers) => (
						<Stack spacing="0.75rem">
							{requestTypes.map((req) => (
								<Stack direction="row" spacing="0.5rem" key={req.value}>
									<input
										name="requests"
										type="checkbox"
										value={req.value}
										checked={values?.requests?.includes(req.value)}
										onChange={(e) => {
											if (e.target.checked) {
												arrayHelpers.push(req.value);
											} else {
												const idx = values?.requests?.indexOf(req.value);
												arrayHelpers.remove(idx);
											}
										}}
									/>
									<span>{req.label}</span>
								</Stack>
							))}
						</Stack>
					)}
				/>
			</Box>
			<Errors />
		</Stack>
	);
}

function PolicyModal({ item, open, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

	// Properties from the vendor config..
	const {
		policy_id,
		policy_name,
		requests
	} = item ?? {};

	const label = policy_id ? `Update Policy ${policy_name}` : 'New Policy';

	const changeSet = useMemo(
		() => ({
			policy_id: [policy_id ?? '', yup.string()],
			policy_name: [policy_name ?? '', yup.string()],
			requests: [requests ?? [], yup.array().required('Requests are required!').min(1, 'Select at least one request type for the policy!')],
		}),
		[policy_id, policy_name, requests],
	);

	const handleSubmit = (policy) => {
		try {
			console.log('Setting Policy', policy);
			onClose(policy);
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={label}>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing="1.5rem" sx={{ paddingTop: '1rem' }}>

					<PolicyID />

					<Stack spacing="1.5rem">
						<H5>Request Types</H5>
						<RequestTypeSelector />
					</Stack>

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default PolicyModal;
