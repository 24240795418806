const styles = {
  '.custom-edge': {
    outlineOffset: '0.25rem',
    outlineStyle: 'dashed',
  },
  '&.process-edge path': {
    stroke: '#00A89D',
    strokeWidth: '3px',
    transform: 'translate(3px, 3px)',
    borderRadius: 0,
  },
  '&.default-edge path': {
    stroke: '#E3A428',
    strokeWidth: '2px',
    transform: 'translate(2px, 2px)',
    borderRadius: 0,
  },
  '&.selected': {
    outline: '2px dashed #ccc',
    strokeDashOffset: 0,
  }
}

export default styles;