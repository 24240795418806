function SvgHeartOn(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<g data-name='Group 148'>
				<path
					data-name='Path 2773'
					className='heart_on_svg__cls-1'
					d='M30 57.81c.99-.44 1.9-1.01 2.72-1.72 3.26-2.6 6.42-5.32 9.43-8.21 4.07-3.83 7.78-8.03 11.08-12.54 2.37-3.17 4.26-6.68 5.61-10.4 1.11-2.99 1.43-6.21.92-9.36-1.47-8.25-8.98-14.03-17.33-13.33-4.96.34-9.47 2.97-12.2 7.12-.08.11-.13.22-.23.36-.1-.14-.16-.25-.23-.36a15.894 15.894 0 00-12.2-7.12C9.22 1.55 1.7 7.33.23 15.58c-.5 3.15-.18 6.37.94 9.36 1.35 3.72 3.24 7.23 5.61 10.4 3.29 4.52 7 8.71 11.08 12.54 3.01 2.89 6.16 5.6 9.43 8.21.82.7 1.73 1.28 2.72 1.72z'
				/>
			</g>
		</svg>
	);
}
export default SvgHeartOn;
