import { useCallback, useState } from 'react';

export function useToggleState(initialState = false) {
	const [state, setState] = useState(initialState);
	const toggle = useCallback(
		(forcedState) =>
			setState((prev) => {
				return forcedState ? !!forcedState : !prev;
			}),
		[],
	);
	return [state, toggle];
}
