import Timeline from '@mui/lab/Timeline';
import SingleTimeline from './SingleTimeline';

export default function BasicTimeline({ items }) {
	return (
		<Timeline>
			{items.map((item, i) => (
				<SingleTimeline item={item} key={i} />
			))}
		</Timeline>
	);
}
