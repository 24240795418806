import { Delete, Edit, TaskOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';

function SCCode ({
  item,
  onRemove,
  onEdit,
  selected,
}) {
  const { state, code } = item ?? {};

  return (
    <Box className='listItem'>
      {selected && selected(item) && (
        <Box className='listSelected'>
          <TaskOutlined className='check' />
        </Box>
      )}

      <Box className='listContent'>
        <Stack spacing={0.5}>
          <IDDisplay value={state} />
          <NameDisplay label="Code" name={code} />
        </Stack>
      </Box>

      <Stack className='listButtons'>
        {onEdit && (
          <IconCircleButton onClick={() => onEdit(item)}>
            <Edit />
          </IconCircleButton>
        )}
        {onRemove && (
          <IconCircleButton onClick={() => onRemove(item)}>
            <Delete />
          </IconCircleButton>
        )}
      </Stack>
    </Box>
  );
}

function SCCodeList({ items, onEdit, onRemove }) {
  return (
    <Box className='inventory-contents'>
      <Stack className='list'>
        {items.map((code) => (
          <SCCode
            item={code}
            onEdit={onEdit}
            onRemove={onRemove}
            key={code?.state}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default SCCodeList;
