
const styles = {
  '&.timer-root': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    paddingRight: '0.5rem',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    flexGrow: 1,

    '*': { whiteSpace: 'nowrap' },
    // border: '1px solid',

    '&:hover': {
      // '.timer': {
      //   opacity: 1,
      //   transform: 'translateX(0)'
      // },
    },
  },
  '.content': {
    overflow: 'visible',
    width: '100%',
    flexGrow: 1,
  },

  '.child-content + .content': {
    '.progress-container': {
      left: '0.75rem',
      marginLeft: '0.5px',
      marginTop: '0.5px',
    },
  },

  '.progress-container, .progress-total': {
    position: 'relative',
    fontSize: '2rem',
    width: '1em !important',
    height: '1em !important',
    margin: 0,
    textAlign: 'center',
    lineHeight: 1,
    position: 'absolute',
    left: '-1rem',
    marginLeft: '4px',
    marginTop: '1px',
    top: '0',
    transform: 'unset !important',

    '.MuiCircularProgress-root': {
      width: '1em !important',
      height: '1em !important',
    },

    '.progress, .progress-total': {
      position: 'absolute',
      zIndex: 3,
      left: '-1rem',
      top: '-4.5px',
      transform: 'unset !important'
    },

    '.progress-total': {
      zIndex: 2,
      borderRadius: '99rem',
      marginLeft: 0,
      // boxShadow: '-inset 0 0 3px black'
      border: '3px solid',
      opacity: 0.3
    },

    svg: {
      // transformOrigin: 'center center',
      transform: 'unset !important',
      fontSize: '2rem',
      width: '1em',
      height: '1em'
    },
  },

  // '.progress': { width: '100%' },

  '.timer': {
    whiteSpace: 'nowrap',
    width: 'auto',
    px: '0.25rem',
    // minWidth: '6ch',

    // transition: 'all 0.3s',
    // opacity: 0,
    // transformOrigin: 'left center',
    // transform: 'translateX(-100%)',

    // '&:hover': {
    //   opacity: 1,
    //   transform: 'translateX(0)'
    // },
    // width: 'fit-content',
    flexShrink: 0,
    fontSize: '1.1em',

    '*': {
      fontSize: '0.95em',
      lineHeight: '1.5',
      fontWeight: '700',
    },
    '.segment': {
      marginLeft: '0.1em',
    },
  },

  // '.progress': {

  //   width: '1em',
  //   height: '1em',
  // },
};

export default styles;
