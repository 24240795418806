import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { usePageLoadingHook } from '@pw/redux/containers/App/hooks';

function FilledButton({
	label = '',
	icon = null,
	iconPosition = 'end',
	size = 'medium',
	handleClick = () => {},
	loading = false,
	disabled = false,
	type='button',
	sx = {},
	className = '',
	color = 'primary',
	children = null,
}) {
	const { loading: isLoading } = usePageLoadingHook();

	return (
		<Button
			variant='contained'
			sx={sx}
			disabled={loading || disabled || !!isLoading}
			onClick={handleClick}
			className={className}
			size={size}
			{...(iconPosition === 'start' && { startIcon: icon })}
			{...(iconPosition === 'end' && { endIcon: icon })}
			type={type}
			color={color}
		>
			{label}
			{children}
		</Button>
	);
}

export default FilledButton;
