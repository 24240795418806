const Brix = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 37"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.4391 36.9998C11.8222 36.9034 11.1996 36.8369 10.5895 36.7068C6.92325 35.9264 4.04496 33.9877 1.99815 30.8408C1.93337 30.7415 1.8773 30.6375 1.81445 30.5315C1.86376 30.493 1.89954 30.4602 1.94014 30.4351C2.18765 30.2868 2.43613 30.1393 2.71845 29.9717C4.74205 33.1909 7.63001 35.1575 11.3852 35.7443C15.1047 36.3253 18.4886 35.4658 21.4887 33.0955C21.714 33.3653 21.9345 33.6302 22.1713 33.9145C21.2238 34.7123 20.2019 35.3367 19.0977 35.8271C17.7103 36.4438 16.2572 36.8119 14.7431 36.9381C14.6493 36.9458 14.5584 36.9786 14.4656 36.9998H12.4391Z" fill="currentColor" />
    <path d="M30.1701 13.97C29.9738 14.2649 29.7882 14.5433 29.588 14.843C27.4987 13.5557 25.2982 13.2531 22.9932 14.0529C21.2268 14.6666 19.8867 15.8268 18.976 17.458C17.1651 20.7003 18.0739 24.7983 21.0808 27.0135C24.0915 29.2315 28.2635 28.8509 30.8672 26.1472C33.4835 23.431 33.8344 18.5719 30.4089 15.4471C30.6293 15.1966 30.8498 14.9451 31.0702 14.6946C33.6024 16.6467 35.2692 21.3141 32.7525 25.4593C30.2484 29.5823 24.8051 30.7896 20.7791 28.1254C16.7387 25.4516 15.7322 19.9777 18.5931 16.0503C21.5449 11.9986 26.8509 11.6594 30.1701 13.97Z" fill="currentColor" />
    <path d="M30.0159 21.0643C30.0159 21.8467 30.0266 22.6291 30.0063 23.4105C30.0024 23.5435 29.8999 23.7295 29.7868 23.7959C28.4284 24.5957 27.0622 25.3819 25.6893 26.1557C25.582 26.2164 25.3819 26.2144 25.2736 26.1537C23.9006 25.379 22.5345 24.5928 21.1751 23.795C21.0678 23.7324 20.9634 23.5599 20.9624 23.4385C20.9479 21.8621 20.9469 20.2858 20.9624 18.7104C20.9634 18.5803 21.0804 18.3992 21.1964 18.3307C22.5335 17.5435 23.8832 16.7765 25.2233 15.9932C25.4167 15.8805 25.5598 15.8901 25.7483 16.0009C27.0787 16.7765 28.4139 17.5435 29.753 18.3047C29.9415 18.4117 30.0237 18.5302 30.0188 18.7557C30.0034 19.5255 30.013 20.2954 30.013 21.0653L30.0159 21.0643ZM22.5867 18.772C23.5536 19.3299 24.4556 19.857 25.3674 20.3667C25.4428 20.4091 25.5965 20.3667 25.6883 20.3166C26.2568 20.0025 26.8186 19.6749 27.3813 19.3502C27.7003 19.1661 28.0184 18.9792 28.3752 18.7701C27.4267 18.2247 26.5324 17.7054 25.6313 17.1986C25.5559 17.1562 25.4167 17.1543 25.3422 17.1957C24.4401 17.7025 23.5458 18.2209 22.5877 18.772H22.5867ZM22.0433 19.7153C22.0433 20.8321 22.0375 21.8746 22.054 22.9162C22.0559 23.0097 22.1893 23.1292 22.2909 23.1889C23.0769 23.6524 23.8697 24.1052 24.6625 24.5591C24.7399 24.6034 24.8279 24.6284 24.9419 24.6757C24.9419 23.6109 24.9458 22.5906 24.9361 21.5702C24.9361 21.4815 24.8723 21.3543 24.7988 21.3119C23.9113 20.7878 23.015 20.2761 22.0433 19.7153ZM26.0306 24.7152C27.0042 24.1553 27.9101 23.637 28.8112 23.1109C28.8693 23.0771 28.9234 22.9789 28.9234 22.9095C28.9302 21.8689 28.9282 20.8273 28.9282 19.7838C28.8693 19.7838 28.8422 19.7751 28.8248 19.7847C27.9372 20.2906 27.0487 20.7945 26.166 21.309C26.0993 21.3476 26.0374 21.4574 26.0364 21.5355C26.0277 22.5684 26.0306 23.6013 26.0306 24.7142V24.7152Z" fill="currentColor" />
    <path d="M13.4478 0C16.1569 4.11143 18.8254 8.16023 21.5113 12.237C21.2058 12.4393 20.9205 12.6282 20.6053 12.8363C18.2182 9.21435 15.8494 5.62034 13.4478 1.97622C12.0053 4.16346 10.5918 6.30734 9.16179 8.47628C8.8553 8.27393 8.56912 8.08604 8.25586 7.87888C9.98554 5.25227 11.6988 2.65359 13.4478 0Z" fill="currentColor" />
    <path d="M2.23474 29.0515C1.89731 29.2134 1.59566 29.357 1.26887 29.5131C0.806714 28.5967 0.490557 27.6563 0.284619 26.6793C-0.440513 23.2375 0.233376 20.0646 2.15256 17.1402C3.91511 14.4539 5.68637 11.7723 7.45473 9.08983C7.5137 9.00022 7.57848 8.91543 7.66067 8.7998C7.95749 8.99348 8.24367 9.1804 8.55113 9.38082C8.47571 9.50319 8.4177 9.60533 8.35389 9.70264C6.60584 12.3562 4.86262 15.0118 3.10683 17.6605C1.73778 19.7254 1.01169 21.9782 1.09387 24.4651C1.14221 25.9104 1.46997 27.2969 2.04524 28.6247C2.10228 28.7557 2.16029 28.8858 2.23377 29.0515H2.23474Z" fill="currentColor" />
    <path d="M11.2149 25.4664C10.8668 25.3026 10.5661 25.16 10.2722 25.0212C10.6928 23.1221 9.72693 21.5679 7.77004 20.9561C7.05457 20.7325 6.33041 20.6824 5.59754 20.8819C3.97034 21.3261 3.30129 22.8841 4.08926 24.3766C5.03677 26.1717 7.87155 27.1844 9.72596 25.7208C9.95607 25.9694 10.19 26.2218 10.4424 26.4945C9.89129 27.0264 9.2319 27.2933 8.51644 27.4368C6.46577 27.8473 4.10377 26.7219 3.12822 24.8757C1.95157 22.649 3.11759 20.271 5.64008 19.7536C7.88993 19.293 10.4347 20.6371 11.2594 22.7338C11.6123 23.6309 11.6277 24.5308 11.2168 25.4645L11.2149 25.4664Z" fill="currentColor" />
    <path d="M18.3289 29.189C18.2284 30.5062 17.8107 31.6595 16.8806 32.5922C15.1045 34.3738 12.4892 33.8159 11.5881 31.4716C10.9239 29.7431 11.4344 27.5818 12.8034 26.3273C14.6182 24.6642 17.1629 25.2838 17.9876 27.6117C18.1655 28.1147 18.2187 28.661 18.3299 29.1881L18.3289 29.189ZM17.2432 29.1929C17.1291 28.7294 17.074 28.2419 16.8912 27.8083C16.2744 26.3446 14.7062 26.0122 13.5585 27.1126C12.4641 28.1628 12.1431 29.4636 12.5569 30.9176C12.7831 31.7125 13.2665 32.3282 14.1212 32.5103C14.9885 32.6953 15.6778 32.3147 16.2405 31.6904C16.8709 30.9899 17.1513 30.1458 17.2441 29.1938L17.2432 29.1929Z" fill="currentColor" />
    <path d="M16.3512 17.7568C16.3251 18.6779 15.9828 19.7051 15.0189 20.4258C14.1245 21.0945 13.1297 21.1542 12.1048 20.7688C9.88977 19.9363 8.62998 17.1237 9.4721 14.9153C10.2601 12.8485 12.6018 12.2656 14.4523 13.6762C15.6309 14.5742 16.3512 16.032 16.3512 17.7568ZM15.2809 17.434C15.1745 16.0725 14.606 14.8787 13.2379 14.209C12.0265 13.6155 10.7967 14.1984 10.4167 15.4896C10.0164 16.852 10.6381 18.5642 11.8418 19.4141C13.3327 20.4672 14.9454 19.7985 15.2393 18.0035C15.2645 17.8502 15.2635 17.6922 15.2809 17.434Z" fill="currentColor" />
    <path d="M9.92563 30.315C9.86858 31.2082 9.35519 31.85 8.50727 32.2171C7.64581 32.5909 6.79982 32.5447 6.08243 31.8817C5.55453 31.3932 5.44528 30.766 5.66668 30.0896C6.08146 28.8225 7.68352 28.1259 8.89304 28.6857C9.55533 28.9921 9.90146 29.5172 9.92466 30.3141L9.92563 30.315ZM7.47468 31.3537C7.99968 31.346 8.5034 31.0531 8.72287 30.6581C8.98006 30.1936 8.79925 29.7514 8.28489 29.6213C7.74056 29.4835 7.28325 29.6695 6.91778 30.0664C6.70024 30.3025 6.55618 30.5906 6.71087 30.924C6.8704 31.267 7.19043 31.3296 7.47468 31.3537Z" fill="currentColor" />
    <path d="M13.8241 11.5821C13.1753 11.5638 12.5188 11.2757 12.164 10.5107C11.8517 9.83811 12.0354 9.01814 12.6271 8.42363C13.2981 7.75012 14.1103 7.48996 15.0404 7.73277C16.1957 8.0334 16.7043 9.20892 16.1638 10.2736C15.7655 11.057 14.8953 11.5783 13.8241 11.5821ZM13.971 10.4972C14.4622 10.4846 14.8808 10.2833 15.1554 9.83811C15.4619 9.33996 15.2125 8.8235 14.6304 8.74835C14.1296 8.68379 13.7003 8.84374 13.3687 9.22241C13.156 9.46618 13.011 9.7485 13.1647 10.08C13.3165 10.4076 13.6172 10.4837 13.971 10.4972Z" fill="currentColor" />
    <path d="M8.3806 24.2154C8.45795 24.5709 8.52852 24.8966 8.59814 25.2194C7.34608 25.7098 5.57289 24.9708 4.85742 23.6565C5.14747 23.4754 5.43849 23.2923 5.74788 23.0986C6.37923 24.0275 7.23102 24.4235 8.37963 24.2154H8.3806Z" fill="currentColor" />
    <path d="M23.9473 30.3965C24.2286 30.5786 24.5081 30.7607 24.8571 30.9871C24.2548 31.7252 23.6688 32.443 23.0646 33.184C22.7726 32.8872 22.5318 32.6425 22.2988 32.4055C22.8422 31.7435 23.3798 31.0883 23.9473 30.3965Z" fill="currentColor" />
    <path d="M17.9563 12.7744V13.8131H16.9121V12.7744H17.9563Z" fill="currentColor" />
    <path d="M10.1701 10.7432V11.778H9.12109V10.7432H10.1701Z" fill="currentColor" />
    <path d="M13.4648 23.7369V22.6953H14.5071V23.7369H13.4648Z" fill="currentColor" />
    <path d="M3.67773 29.2274V28.2051H4.72869V29.2274H3.67773Z" fill="currentColor" />
    <path d="M19.7031 30.0732H20.7454V31.1119H19.7031V30.0732Z" fill="currentColor" />
    <path d="M7.33532 17.5093H6.30273V16.4648H7.33532V17.5093Z" fill="currentColor" />
    <path d="M14.9181 31.4368C14.7025 31.1679 14.4869 30.8981 14.2539 30.6072C15.0071 29.9857 15.1792 29.2245 14.7828 28.3082C15.1038 28.1472 15.4102 27.994 15.7158 27.8418C16.437 28.9788 16.087 30.5744 14.9181 31.4368Z" fill="currentColor" />
    <path d="M13.0262 18.8686C11.6871 18.1671 11.0635 16.7517 11.5353 15.501C11.8515 15.58 12.1715 15.659 12.4422 15.7264C12.5051 16.188 12.48 16.6428 12.6433 17.0147C12.8009 17.376 13.1529 17.6516 13.4642 18.0177C13.3501 18.2394 13.1935 18.5429 13.0262 18.8686Z" fill="currentColor" />
  </svg>
);

export default Brix;
