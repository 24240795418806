import Print from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import QRImage from '@pw/components/QRImage';
import ScanQR from '@pw/components/ScanQR';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mq } from '@pw/styles/media';
import debounce from '@pw/utilities/debounce';
import useEncodeLocationQrCode from '@pw/utilities/hooks/logic/useEncodeLocationQrCode';
import makeNumberOptions from '@pw/utilities/makeNumberOptions';
import safeParse from '@pw/utilities/safeParse';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const styles = {
	qrCode: {
		[mq('sm', 'down')]: {
			position: 'absolute',
			top: 160,
		},
	},
	scanQrCard: {
		'.scan-card': {
			width: 'min-content',
		},
		'.scan-button': {
			width: 'min-content',
		},
		'qr-print-all-button': {
			width: 'min-content',
		},
	},
};

function LocationImpl({
	label = '',
	withPrintAllButton = true,
	previewQr = false,
	disabled = false,
	facilities = {},
}) {
	let [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { values = {}, setFieldValue } = useContext(FormikContext);

	const setLocationValues = useCallback(
		(facility_id, bay, row, level) => {
			const found = Object.values(facilities).find(
				(w) => {
					return w?.path === facility_id.toLowerCase()
				},
			);
			if (found) {
				debounce(() => {
					setFieldValue('facility_id', facility_id);
					setFieldValue('bay', bay);
					setFieldValue('row', row);
					setFieldValue('level', level);
				}, 25);
			}
		},
		[setFieldValue, facilities],
	);

	const handleOnQrSuccess = useCallback(
		(data) => {
			const { text } = data; // 'https://test.metacask.com:4430/explorer?dt=1,2,3,4';
			const [facility_id, bay, row, level] = decodeURIComponent(
				text.split('?dt=')[1],
			).split(',');
			setLocationValues(facility_id, bay, row, level);
		},
		[setLocationValues],
	);

	const handlePrintAll = useCallback(() => {
		const route = `/app/locations?dt=${encodeURIComponent(
			[values.facility_id, values.bay, values.row, values.level].join(','),
		)}`;
		navigate(route);
	}, [values?.facility_id, values?.bay, values?.row, values?.level, navigate]);

	const encodedQrData = useEncodeLocationQrCode(
		'dt',
		values.facility_id,
		values.bay,
		values.row,
		values.level,
	);

	useEffect(() => {
		const dt = searchParams.get('dt');

		if (dt) {
			const [warehousePath, bay, row, level] =
				decodeURIComponent(dt).split(',');
			console.log('Location', warehousePath, bay, row, level, dt);
			setLocationValues(warehousePath, bay, row, level);
		}
	}, [searchParams, setLocationValues]);

	return (
		<Stack spacing={2}>
			<Box>
				<H5 sx={{ mb: 2 }}>{label}</H5>
			</Box>

			<FlexBox sx={styles.scanQrCard} alignItems='flex-start' gap={1}>
				<Stack spacing={1}>
					<ScanQR
						onSuccess={handleOnQrSuccess}
						variant='outlined'
						color='secondary'
						width={24}
						height={24}
						withLabel
						Component={TitledButton}
					/>
					{withPrintAllButton && (
						<TitledButton
							handleClick={handlePrintAll}
							label='Print'
						>
							<Print height={24} width={24} />
						</TitledButton>
					)}
				</Stack>

				<FlexBox spacing={1}>
					<LocationSelect facilities={facilities} disabled={disabled} />
					{previewQr && (
						<Box sx={styles.qrCode}>
							<QRImage boxSize={72} isLogoVisible value={encodedQrData} />
						</Box>
					)}
				</FlexBox>
			</FlexBox>
		</Stack>
	);
}

export function LocationSelect({ setLocation, disabled = false, facilities = {} }) {
	const { values, setFieldValue } = useContext(FormikContext);

	// Assumes map here
	const facilityOptions = Object.entries(facilities).map(([k, v]) => ({
		label: v.facility_name,
		value: k,
	}));

	// console.log(facilityOptions);

	const currentFacility = useMemo(
		() => facilities[values?.facility_id],
		[facilities, values?.facility_id],
	);

	// console.log(currentFacility);

	const bayOptions = useMemo(
		() =>
			(currentFacility?.bays ?? []).map(([b]) => ({
				label: b,
				value: b.toString().toLowerCase(),
			})),
		[currentFacility],
	);

	// console.log(bayOptions);

	const currentBay = useMemo(() => {
		return (currentFacility?.bays ?? []).find(
			([b]) => b.toString().toLowerCase() === values?.bay,
		);
	}, [currentFacility?.bays, values?.bay]);

	// console.log(currentBay);

	const rowOptions = useMemo(
		() => {
			const rows = makeNumberOptions((currentBay ?? ['', 0, 0])[1]);
			const exists = rows.find((r) => r.value === values?.row);
			// console.log(' --> row', rows, exists, values?.row);
			if (!exists) {
				debounce(() => {
					setFieldValue('row', '');
					setFieldValue('level', '');
				}, 25);
			}
			return rows;
		},
				[currentBay, values?.row]
	);

	// console.log('Row options', values?.row, rowOptions);

	const levelOptions = useMemo(() => {
		// console.log('>>level', currentBay, values?.row);
		if (currentBay && values?.row) {
			const levels = makeNumberOptions(currentBay[2]);
			const exists = levels.find((l) => l.value === values?.level);
			// console.log(' --> level', levels, exists, values?.level);
			if (!exists) {
				debounce(() => {
					setFieldValue('level', '');
				}, 25);
			}
			// console.log(' -->', levels);
			return levels;
		}
		return [];
	}, [currentBay, values?.row, values?.level]);

	// console.log('Level options', values?.level, levelOptions);

	useEffect(() => {
		if (setLocation) {
			setLocation([
				values?.facility_id,
				values?.bay,
				values?.row,
				values?.level,
			]);
		}
	}, [values, setLocation]);

	return (
		<Stack spacing={2} sx={{ flexGrow: '1' }}>
			<FormikSelect
				fullWidth
				options={facilityOptions}
				label='Facility'
				name='facility_id'
				disabled={disabled}
			/>

			<FormikSelect
				fullWidth
				value={values?.bay}
				options={bayOptions}
				label='Bay'
				name='bay'
				disabled={disabled}
			/>

			<FormikSelect
				fullWidth
				value={values?.row}
				options={rowOptions}
				label='Row'
				name='row'
				disabled={disabled}
			/>

			<FormikSelect
				fullWidth
				value={values?.level}
				options={levelOptions}
				label='Level'
				name='level'
				disabled={disabled}
			/>
		</Stack>
	);
}

export default function ExplorerLocation({ name, ...rest }) {
	return (
		<FormikProvider path={name}>
			<LocationImpl {...rest} />
		</FormikProvider>
	);
}
