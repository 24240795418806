function SvgQrScan3(props) {
	return (
		<svg
			id='QRScan3_svg__Layer_1'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 19 19'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<style>{'.QRScan3_svg__cls-1{stroke-width:0}'}</style>
			</defs>
			<path
				className='QRScan3_svg__cls-1'
				d='M4.87 0s.05.03.07.05c.25.13.38.38.32.63-.06.27-.28.44-.57.44H2.07a.93.93 0 00-.94.78c-.02.08-.01.17-.01.26v2.52c0 .43-.38.7-.75.55-.22-.09-.35-.26-.35-.5 0-.94-.02-1.88 0-2.82C.04 1 .76.21 1.67.04c.04-.01.08-.03.12-.04h3.08zM19 4.86s-.03.04-.04.06c-.13.27-.38.4-.63.34-.27-.06-.44-.28-.44-.58v-2.6a.94.94 0 00-.78-.95c-.08-.02-.17-.01-.26-.02h-2.48c-.33 0-.56-.16-.62-.44-.06-.26.07-.49.37-.64.01-.01.01-.03.02-.03h3.08c.03.01.07.03.1.04.6.12 1.07.43 1.38.96.14.24.2.52.3.78v3.08zM19 17.21c-.04.15-.08.3-.13.44-.29.79-1.03 1.33-1.87 1.34-.9.01-1.79 0-2.69 0-.33 0-.58-.24-.58-.55 0-.32.24-.56.58-.56h2.54c.65 0 1.03-.39 1.03-1.04v-2.46c0-.35.15-.57.44-.64.26-.06.49.07.64.37 0 0 .02.01.03.02v3.08zM9.5 10.05H.51a.571.571 0 01-.47-.58c.02-.28.24-.5.53-.52h17.69c.1 0 .2 0 .29.03.27.07.43.33.4.59-.03.27-.25.47-.52.49H9.49zM0 15.67v-1.34c0-.36.23-.61.56-.61.32 0 .55.25.55.61v2.52c0 .63.39 1.02 1.02 1.02h2.49c.4 0 .65.21.65.55 0 .34-.25.56-.64.56h-2.5c-1.23 0-2.12-.89-2.12-2.12v-1.21zM3.04 5.36c0-.38-.04-.76 0-1.13.1-.72.74-1.24 1.46-1.26.6-.01 1.2 0 1.8 0 .33 0 .57.24.56.56 0 .31-.24.55-.58.55H4.63c-.37 0-.53.16-.53.53V6.2c0 .38-.22.63-.56.63-.33 0-.55-.25-.56-.63v-.85h.03zM5.4 15.92c-.38 0-.76.04-1.13 0-.72-.1-1.24-.74-1.26-1.46-.01-.6 0-1.2 0-1.8 0-.33.25-.57.56-.56.31 0 .54.24.55.56v1.65c0 .39.16.55.55.55h1.58c.38 0 .63.22.62.56 0 .33-.25.55-.63.55h-.85v-.03zM13.61 3c.38 0 .76-.04 1.13 0 .71.1 1.24.73 1.25 1.45.01.61 0 1.21 0 1.82 0 .33-.25.57-.56.56-.31 0-.54-.24-.55-.56V4.6c0-.36-.16-.52-.52-.52h-1.61c-.38 0-.62-.22-.62-.56 0-.33.25-.55.63-.55h.85V3zM15.96 13.56c0 .38.04.76 0 1.13-.1.72-.74 1.24-1.46 1.26-.6.01-1.2 0-1.8 0a.54.54 0 01-.56-.56c0-.31.24-.55.58-.55h1.65c.37 0 .53-.16.53-.53V12.7c0-.36.23-.61.56-.61.33 0 .55.24.56.61v.87h-.03z'
			/>
		</svg>
	);
}
export default SvgQrScan3;
