import { useGridRootProps } from '@mui/x-data-grid';
import { forwardRef } from 'react';

export const GridToolbarButton = forwardRef(
	function GridToolbarColumnsButton(props, ref) {
		const buttonProps = props.button || {};
		const tooltipProps = props.tooltip || {};
		const rootProps = useGridRootProps();
		return (
			<>
				<rootProps.slots.baseTooltip
					enterDelay={1000}
					{...tooltipProps}
					{...rootProps.slotProps?.baseTooltip}
				>
					<rootProps.slots.baseButton
						ref={ref}
						size='small'
						{...buttonProps}
						{...rootProps.slotProps?.baseButton}
					></rootProps.slots.baseButton>
				</rootProps.slots.baseTooltip>
			</>
		);
	},
);
