import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';
import { DISTANCE_UNIT_OPTIONS, TIME_UNIT_OPTIONS, } from '@pw/consts/measures';

const WashbackForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const {
    headspace = { value: '', unit: '' },
    duration = { value: '', unit: '' },
  } = nodeData;
  const changeSet = {
    headspace: unitField(headspace),
    duration: unitField(duration),

  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <UnitField
        label='Headspace'
        name='headspace'
        options={DISTANCE_UNIT_OPTIONS}
      />
      <UnitField
        label='[FOR TESTING TIMER] Duration'
        name='duration'
        options={TIME_UNIT_OPTIONS}
      />
    </NodeModalForm>
  )
}

export default WashbackForm;
