import transactionsSpiritsReportFields from './transactionsSpiritsReportFields';

export default function transactionsSpiritsFields(fields = {}) {
	const {
		taxPaymentFields = {},
		useOfUSFields = {},
		hospital_Scientific_orEducationalUseFields = {},
		exportFields = {},
		transfertoForeignTradeZoneFields = {},
		transfertoCMBWFields = {},
		useAsSuppliesOnVesselsOrAircraftFields = {},
		useInWineProductionFields = {},
		enteredInProcessingAccountFields = {},
		enteredForTransferInBondFields = {},
		enteredInStorageAccountFields = {},
		withdrawnForResearchDevelopmentOrTestingFields = {},
		specifyFields = {},
		producedFields = {},
		receivedForRedistillationFields = {},
		specify2Fields = {},
		physicalInventoryEOQ_ReceivedForRedistillationFields = {},
		physicalInventoryEOQ_UnfinishedSpiritsFields = {},
	} = fields;
	return {
		'1_TaxPayment': transactionsSpiritsReportFields(taxPaymentFields),
		'2_UseOfUS': transactionsSpiritsReportFields(useOfUSFields),
		'3_HospitalScientificorEducationalUse': transactionsSpiritsReportFields(
			hospital_Scientific_orEducationalUseFields,
		),
		'4_Export': transactionsSpiritsReportFields(exportFields),
		'5_TransfertoForeignTradeZone': transactionsSpiritsReportFields(
			transfertoForeignTradeZoneFields,
		),
		'6_TransfertoCMBW': transactionsSpiritsReportFields(transfertoCMBWFields),
		'7_UseAsSuppliesOnVesselsOrAircraft': transactionsSpiritsReportFields(
			useAsSuppliesOnVesselsOrAircraftFields,
		),
		'8_UseInWineProduction': transactionsSpiritsReportFields(
			useInWineProductionFields,
		),
		'9_EnteredInProcessingAccount': transactionsSpiritsReportFields(
			enteredInProcessingAccountFields,
		),
		'10_EnteredForTransferInBond': transactionsSpiritsReportFields(
			enteredForTransferInBondFields,
		),
		'11_EnteredInStorageAccount': transactionsSpiritsReportFields(
			enteredInStorageAccountFields,
		),
		'12_WithdrawnForResearchDevelopmentOrTesting':
			transactionsSpiritsReportFields(
				withdrawnForResearchDevelopmentOrTestingFields,
			),
		'13_Specify': transactionsSpiritsReportFields(specifyFields),
		'14_Produced': transactionsSpiritsReportFields(producedFields),
		'15_ReceivedForRedistillation': transactionsSpiritsReportFields(
			receivedForRedistillationFields,
		),
		'16_Specify': transactionsSpiritsReportFields(specify2Fields),
		'17a_PhysicalInventoryEOQReceivedForRedistillation':
			transactionsSpiritsReportFields(
				physicalInventoryEOQ_ReceivedForRedistillationFields,
			),
		'17b_PhysicalInventoryEOQUnfinishedSpirits':
			transactionsSpiritsReportFields(
				physicalInventoryEOQ_UnfinishedSpiritsFields,
			),
	};
}
