import * as yup from 'yup';

export function addressFields(params = {}) {
	const {
		enable = true,
		name = '',
		address_1 = '',
		address_2 = '',
		address_3 = '',
		city = '',
		state = '',
		country = '',
		post_code = '',
		exciseId = '',
		warehouseId = '',
	} = params;

	return {
		enable: [enable, yup.bool()],
		name: [name, yup.string()],
		address_1: [
			address_1,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Address line 1 is required!'),
				otherwise: (schema) => schema,
			}),
		],
		address_2: [address_2, yup.string()],
		address_3: [address_3, yup.string()],
		city: [
			city,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('City is required!'),
				otherwise: (schema) => schema,
			}),
		],
		state: [state, yup.string()],
		country: [
			country,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Country is required!'),
				otherwise: (schema) => schema,
			}),
		],
		post_code: [
			post_code,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Post code is required!'),
				otherwise: (schema) => schema,
			}),
		],
		exciseId: [exciseId, yup.string()],
		warehouseId: [warehouseId, yup.string()],
	};
}
