function SvgBottle2(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 18.78 29.84'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<g data-name='Group 410'>
				<path
					data-name='Path 9333'
					className='bottle2_svg__cls-1'
					d='M12.77 21.32H6a.394.394 0 00-.1.78H12.77a.394.394 0 00.1-.78h-.1z'
				/>
				<path
					data-name='Path 9334'
					className='bottle2_svg__cls-1'
					d='M12.77 23.32H6a.394.394 0 00-.1.78H12.77a.394.394 0 00.1-.78h-.1z'
				/>
				<path
					data-name='Path 9335'
					className='bottle2_svg__cls-1'
					d='M14.48 9.88c-.68-.13-1.37-.18-2.06-.16.27-.97.42-1.96.46-2.97.01-.86-.16-1.71-.49-2.49h.37c.12 0 .23-.04.31-.12.08-.07.13-.17.12-.28l-.1-3.48a.42.42 0 00-.44-.38H6.1a.42.42 0 00-.44.38l-.1 3.48c0 .11.04.21.12.28.09.08.2.12.31.12h.37c-.34.79-.5 1.64-.49 2.5.04 1 .19 1.99.46 2.96-.69-.03-1.38.03-2.06.16-3.34.57-4.41 3.03-4.25 3.87.05.32.32.55.64.54h1.1c-.04.06-.05.13-.05.2L2.4 26.3c0 .06.02.11.05.16h-.76c-.19 0-.36.11-.42.29l-.8 2.61c-.04.12 0 .25.08.34.09.1.22.15.35.15h16.99c.13 0 .26-.05.35-.15.08-.09.11-.22.08-.34l-.8-2.61a.433.433 0 00-.42-.29h-.76s.05-.1.05-.16l.69-11.81c0-.07-.01-.14-.05-.2h1.1c.32 0 .6-.23.64-.54.16-.84-.91-3.3-4.28-3.87zM6.53.78h5.71l.08 2.7H6.46l.08-2.7zm10.79 28.28H1.46l.56-1.83h14.74l.56 1.83zm-1.13-14.61l-.69 11.81c0 .07.01.14.05.2H3.22c.04-.06.06-.13.05-.2l-.69-11.81c0-.06-.02-.11-.05-.16h2.65c-.17.42-.26.87-.26 1.33 0 2.19 2 3.96 4.46 3.96s4.47-1.77 4.47-3.96c0-.46-.09-.91-.26-1.33h2.65s-.05.1-.05.16zm-3.21 1.17a3.616 3.616 0 01-4.01 3.16c-1.65-.2-2.96-1.5-3.16-3.16a3.616 3.616 0 014.01-3.16c1.65.2 2.96 1.5 3.16 3.16zm.19-2.11a4.762 4.762 0 00-7.54 0H.88c.05-.35.18-.68.39-.97a4.584 4.584 0 013.19-1.89c.73-.2 1.5-.23 2.24-.08.15.1.34.11.49.03a.37.37 0 00.2-.45c-.37-1.1-.58-2.25-.62-3.41-.01-.86.18-1.71.56-2.48h4.13c.38.77.57 1.62.56 2.48-.05 1.16-.26 2.31-.62 3.42-.05.18.03.37.2.45.16.08.35.07.49-.03.74-.15 1.51-.13 2.24.08 1.28.15 2.44.84 3.19 1.89.21.29.34.62.39.97h-4.73z'
				/>
			</g>
		</svg>
	);
}
export default SvgBottle2;
