/**
 * Convert from SKU to SKUItem
 * @param sku
 */
import { INVENTORY_STATUS, SKU_TYPES } from '@pw/consts/sku';

const toSKUItem = (sku) => {
	// Inbound SKU details
	const {
		id,
		path,
		sku_id,
		sku_name,
		sku_type,
		sku_description,
		entries = [],
		properties = {},
		vendors = [],
		policies = [],
	} = sku ?? {};
	// Return the SKUItem
	return {
		id,
		path,
		sku_id,
		sku_name,
		sku_type,
		sku_description,
		entries,
		properties,
		vendors,
		policies,
		// // TODO: this is hack for now for tracked items, eventually, once we generate the ids, then we should mark as done
		processed: [SKU_TYPES.TRACKED, SKU_TYPES.SERVICE].includes(sku_type)
			? INVENTORY_STATUS.CONFIRMED
			: INVENTORY_STATUS.PENDING,
	};
};

export default toSKUItem;
