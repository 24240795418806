import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function TransactionsRumReport({
	name,
	title = 'Tax Payment',
	specify = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField
					name='a'
					label='Transaction'
					fullWidth
					sx={{ ...(!specify && styles.transaction) }}
				/>
				<FormikTextField name='b' label='Rum' fullWidth />
			</FlexBox>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField name='c' label='Gin' fullWidth />
				<FormikTextField name='d' label='Vodka' fullWidth />
			</FlexBox>
		</FormikProvider>
	);
}
