import { Stack } from '@mui/material';
import { Body3 } from '@pw/components/Typography';

function WithHelp({ instruction, children }) {
	return (
		<Stack spacing={0.5}>
			{children}
			<Body3 style={{ color: '#7d7d7d', paddingLeft: '0.5rem' }}>{instruction}</Body3>
		</Stack>
	);
}

export default WithHelp;
