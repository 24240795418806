import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import Section2Report from './Section2Report';

export default function Section2({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Section2Report
						name='On_hand_first_month'
						title='On hand first month'
					/>
					<Section2Report name='Received' title='Received' />
					<Section2Report
						name='Increase_due_to_redenaturation'
						title='Increase due to redenaturation'
					/>
					<Section2Report name='Specify_18' title='Specify 18' specify={true} />
					<Section2Report name='Shipped' title='Shipped' />
					<Section2Report name='Specify_21' title='Specify 21' specify={true} />
					<Section2Report name='Losses' title='Losses' />
					<Section2Report
						name='On_hand_end_of_month'
						title='On hand end of month'
					/>
				</>
			)}
		</FormikProvider>
	);
}
