import Stack from '@mui/material/Stack';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import useTaxCalculator from '@pw/compliance/useTaxCalculator';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidPriceCurve from '@pw/components/Liquid/LiquidPriceCurve';
import CostDisplay from '@pw/components/properties/CostDisplay';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import { Body1, H5, Overline } from '@pw/components/Typography';
import { SKU_TYPES } from '@pw/consts/sku';
import { ABV_UNIT_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsUsUser } from '@pw/redux/containers/User';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const {
		abv = {},
		liquid_type = '',
		price_curve = [],
		growth_factor = '',
	} = fields;

	return {
		abv: unitField(abv),
		liquid_type: [
			liquid_type,
			yup.string().required('Liquid type is required!'),
		],
		price_curve: [
			price_curve,
			yup.array().of(
				yup.object().shape({
					id: yup.string(),
					date: yup.number(),
					price: yup.number(),
					unit: yup.string(),
				}),
			),
		],
		growth_factor: [growth_factor, yup.number()],
	};
}

function LiquidTaxSettings() {
	const { values } = useContext(FormikContext)
	const isUsUser = useIsUsUser();

	const { getTaxCode } = useTaxCalculator();

	const liquidType = values?.['liquid_type'];
	const abv = values?.['abv'];

	const taxCode = useMemo(() => getTaxCode(liquidType, abv), [values]);

	if (!liquidType || !abv || !abv.value || !abv.unit) {
		return;
	}

	if (!taxCode) {
		return (
			<FlexBox className='creator-view'>
				<Stack spacing={0.5} className='column'>
					<Overline>TAX CODE REQUIRED</Overline>
					<Body1>Please contact support@proofworks.com to enable this</Body1>
				</Stack>
			</FlexBox>
		);
	}

	return (
		<FlexBox className='creator-view'>
			<Stack spacing={0.5} className='column'>
				<Overline>TAX CODE</Overline>
				<IDDisplay value={taxCode?.code} />
				<DescriptionDisplay value={taxCode?.name} />

				{isUsUser && (
					<CostDisplay label={`Price per ${taxCode?.unit?.toLocaleUpperCase()} (<100K)`} value={taxCode?.rate[0].rate} code={taxCode?.currency} />
				)}
				{!isUsUser && (
					<CostDisplay label={`Standard per ${taxCode?.unit?.toLocaleUpperCase()}`} value={taxCode?.rate[0].rate} code={taxCode?.currency} />
				)}

				{taxCode?.rate.length === 2 && (
					<>
						{isUsUser && (
							<CostDisplay label={`Price per ${taxCode?.unit?.toLocaleUpperCase()} (>100K)`} value={taxCode?.rate[1].rate} code={taxCode?.currency} />
						)}
						{!isUsUser && (
							<CostDisplay label={`Reduced per ${taxCode?.unit?.toLocaleUpperCase()}`} value={taxCode?.rate[1].rate} code={taxCode?.currency} />
						)}
					</>
				)}
			</Stack>
		</FlexBox>
	);
}

function Properties() {
	const { t } = useTranslation();

	const liquidTypes = useLiquidTypes();

	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<H5>Liquid Properties</H5>
				<FlexBox>
					<FormikSelect
						label='Liquid type'
						name='liquid_type'
						options={liquidTypes}
						fullWidth
					/>

					<FormikUnitField
						label={`${t('abvNew')}`}
						name='abv'
						options={ABV_UNIT_OPTIONS}
						fullWidth
					/>
				</FlexBox>

				<LiquidTaxSettings />

				<LiquidPriceCurve />
				<FormikTextField label='Growth Factor' name='growth_factor' fullWidth />
			</Stack>
		</FormikProvider>
	);
}

function SKUPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...skuSettingsFields(initialValues, SKU_TYPES.LIQUID),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<SKUForm
			skuLabel='Liquid'
			skuType={SKU_TYPES.LIQUID}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</SKUForm>
	);
}

export default withAppLayout(SKUPage, { title: 'Liquid SKUs' });
