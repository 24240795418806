import { useState, useMemo } from 'react';

class ModalStateMonad {
	constructor(state, data) {
		this.state = state;
		this.data = data;
	}

	static open(name, data) {
		return new ModalStateMonad(name, data);
	}

	static close() {
		return new ModalStateMonad('', null);
	}

	fold(obj = {}) {
		return obj?.[this?.state] ? obj[this.state](this) : null;
	}
}

function useModalState(func = ModalStateMonad.close) {
	const [modal, setModal] = useState(func);
	return useMemo(
		() => ({
			modal,
			openModal: (name, payload) =>
				setModal(ModalStateMonad.open(name, payload)),
			closeModal: () => setModal(ModalStateMonad.close),
		}),
		[modal],
	);
}

export default useModalState;
