import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestID from '@pw/components/RequestID';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import { REQUEST_EDIT_URL, REQUEST_TYPES } from '@pw/consts/requests';

import { upsertRequestThunk } from '@pw/redux/thunks/request';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function DeliveryModal({ skus, entity, open, onClose }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const changeSet = requestIdFields({});

	const handleSubmit = useCallback(
		async (values) => {
			dispatch(upsertRequestThunk({
				...values,
				request_type: REQUEST_TYPES.delivery,
				entity,
				sku_destinations: skus.map((s) => ({
					...s,
					entries: s.entries?.length ? s.entries : [{ amount: 0 }],
				})),
			}))
				.unrwap()
				.then((response) => {
					const route = REQUEST_EDIT_URL?.[REQUEST_TYPES.delivery];
					navigate(`${route}/${response?.path}`, { replace: true });
				});
		},
		[entity, skus],
	);

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title="Create Delivery">
			<FormikForm
				changeSet={changeSet}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				<FormWrapper>
					<RequestID
						label='ID.'
					/>

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
							label='Cancel'
						/>
						<FilledButton
							type='submit'
							size='small'
						>
							Create
						</FilledButton>
					</Box>
				</FormWrapper>
			</FormikForm>
		</ModalWithClose>
	);
}

export default DeliveryModal;
