import { AddCircle, Edit } from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import { useCompanyTeams } from '@pw/redux/containers/User/hooks';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useState } from 'react';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import { Body3, H5 } from '@pw/components/Typography';
import styles from '@pw/styles/content.styles';
import Team from './TeamForm';
import CachedIcon from '@mui/icons-material/Cached';
import { refetchTeamsThunk } from '@pw/redux/thunks/teams';
import { useDispatch } from 'react-redux';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';

function TeamItem({ team, editTeam }) {
	const teamName = team.path.replace('/t/', '');

	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<H5>{teamName}</H5>
				<Body3>{team.description}</Body3>
			</Box>
			<Stack className='listButtons'>
				<IconCircleButton onClick={() => editTeam({...team, name: teamName})}>
					<Edit />
				</IconCircleButton>
			</Stack>
		</Box>
	);
}

function Teams() {
	const dispatch = useDispatch();
	const [target, scroll] = useScrollTarget();

	const teams = useCompanyTeams();
	const [team, setTeam] = useState(null);

	useEffect(() => scroll(), [scroll]);

	return (
		<Stack sx={styles} className='root' spacing='1.25rem' ref={target}>
			<Box className='header'>
				<H5>Manage Teams</H5>
				<Stack
					gap={1}
					flexDirection={'row'}
					alignItems={'center'}
					justifyContent={'space-between'}
				>
					<TitledButton
						handleClick={() => {
							setTeam({});
							scroll();
						}}
						label='New'
					>
						<AddCircle height={24} width={24} />
					</TitledButton>
					<TitledButton
						handleClick={() => {
							dispatch(refetchTeamsThunk());
						}}
						label='Refetch'
					>
						<CachedIcon height={24} width={24} />
					</TitledButton>
				</Stack>
			</Box>

			<Instructions>
				Configures the teams that your users can be in.
			</Instructions>

			<Collapse in={!!team}>
				<Team
					team={team}
					onClose={() => {
						setTeam(null);
						scroll();
					}}
				/>
			</Collapse>

			<Stack spacing={0} className='list'>
				{teams.map((p) => (
					<TeamItem key={p.path} team={p} editTeam={setTeam} />
				))}
			</Stack>
		</Stack>
	);
}

export default Teams;
