import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useViewport } from 'reactflow';

const ViewportObserver = ({ viewport, setViewport }) => {
  const vp = useViewport();
  useEffect(() => {
    if (!vp) return;
    setViewport(vp);
  }, [vp]);

  return (
    <Box sx={{ display: 'none' }}>
      {JSON.stringify(viewport, null, 2)}
    </Box>
  );
}

export default ViewportObserver;
