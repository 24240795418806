import * as yup from 'yup';

export default function signatureFields(fields = {}) {
	const { Proprietor = '', Title = '', Perjury = true } = fields;

	return {
		Perjury: [Perjury, yup.boolean()],
		Proprietor: [Proprietor, yup.string().required('Proprietor is required!')],
		Title: [Title, yup.string().required('Title is required!')],
	};
}
