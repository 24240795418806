import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function BasicDatePicker(props) {
	const { sx, ...rest } = props;
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				renderInput={(params) => <TextField {...params} sx={sx} />}
				{...rest}
			/>
		</LocalizationProvider>
	);
}
