import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import {
	FormikCheckBox,
	FormikTextAreaField,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { H4, H5 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';
import SpiritReport from './SpiritReport';

const styles = {
	formikTextField: {
		marginTop: '10px !important',
	},
	checkboxLabel: {
		maxWidth: '500px',
		whiteSpace: 'normal',
	},
};

export default function Part3({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	const [expandedSpirit, setExpandedSpirit] = useState(false);
	const [expandedAlcohol, setExpandedAlcohol] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Stack direction='row' alignItems='center'>
						<H4>Spirit Transactions</H4>
						<IconCircleButton
							onClick={() => setExpandedSpirit((prev) => !prev)}
						>
							{!expandedSpirit ? (
								<KeyboardArrowRightOutlined />
							) : (
								<ExpandMoreIcon />
							)}
						</IconCircleButton>
					</Stack>
					{expandedSpirit && (
						<>
							<H4>On Hand Beginning of Reporting Period</H4>
							<FormikTextField
								name='P3_Spirits_Beginning'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Produced by Distilling</H4>
							<FormikTextField
								name='P3_Spirits_Distilling'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Received from Other Plants</H4>
							<FormikTextField
								name='P3_Spirits_Received'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Inventory Gain</H4>
							<FormikTextField
								name='P3_Spirits_Inventory'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Imported Spirits Received from Customs Custody</H4>
							<FormikTextField
								name='P3_Spirits_Imported'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<SpiritReport name='part3_6' title='Other Spirits (6)' />

							<H4>Total (lines 1 through 6)</H4>
							<FormikTextField
								name='P3_Spirits_Total'
								label='Proof Gallons'
								fullWidth
								disabled={true}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Used in Making Fuel Alcohol</H4>
							<FormikTextField
								name='P3_Spirits_Making_Fuel'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>
								Used on Plant Premises for Fuel (Do Not Include Fuel Alcohol)
							</H4>
							<FormikTextField
								name='P3_Spirits_Used_Fuel'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Used as Distilling Material or for Redistilling</H4>
							<FormikTextField
								name='P3_Spirits_Used_Distilling'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Lost by Theft</H4>
							<FormikTextField
								name='P3_Spirits_Lost'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Inventory Loss</H4>
							<FormikTextField
								name='P3_Spirits_InventoryLoss'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Transferred to Other Plants</H4>
							<FormikTextField
								name='P3_Spirits_Transferred'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Destroyed</H4>
							<FormikTextField
								name='P3_Spirits_Destroyed'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>
							<H4>Total (lines 8 through 15)</H4>
							<FormikTextField
								name='P3_Spirits_Total_16'
								label='Proof Gallons'
								fullWidth
								disabled={true}
								sx={{ ...styles.formikTextField }}
							/>
							<H4>ON Hand End Of Period (Subtract line 16 from line 7)</H4>
							<FormikTextField
								name='P3_Spirits_End'
								label='Proof Gallons'
								fullWidth
								disabled={true}
								sx={{ ...styles.formikTextField }}
							/>

							<SpiritReport name='part3_15' title='Other Spirits (15)' />
						</>
					)}
					<Stack direction='row' alignItems='center'>
						<H4>Fuel Alcohol Transactions</H4>
						<IconCircleButton
							onClick={() => setExpandedAlcohol((prev) => !prev)}
						>
							{!expandedAlcohol ? (
								<KeyboardArrowRightOutlined />
							) : (
								<ExpandMoreIcon />
							)}
						</IconCircleButton>
					</Stack>
					{expandedAlcohol && (
						<>
							<H4>Manufactured</H4>
							<FormikTextField
								name='P3_FuelAlcohol_Manufactured'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Distributed or Sold for Fuel Purposes</H4>
							<FormikTextField
								name='P3_FuelAlcohol_Distributed'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Received from Customs Custody</H4>
							<FormikTextField
								name='P3_FuelAlcohol_Customs'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Used on Plant Premises for Fuel Purposes</H4>
							<FormikTextField
								name='P3_FuelAlcohol_Used_Fuel'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Destroyed</H4>
							<FormikTextField
								name='P3_FuelAlcohol_Destroyed'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>All Other Dispositions</H4>
							<FormikTextField
								name='P3_FuelAlcohol_Other'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Remarks</H4>
							<FormikTextAreaField
								name='P3_FuelAlcohol_Remarks'
								required
								fullWidth
								multiline={true}
								rows={4}
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>
						</>
					)}
					<Stack direction='row' alignItems='center'>
						<FormikCheckBox
							name='perjury2'
							label={
								<H5 sx={{ ...styles.checkboxLabel }}>
									Under the penalties of perjury, I declare that I have examined
									this report and, to the best of my knowledge and belief, it is
									a true and complete report of operations.{' '}
								</H5>
							}
							disabled={true}
						/>
					</Stack>
				</>
			)}
		</FormikProvider>
	);
}
