import * as yup from 'yup';

import {
  WEIGHT_UNIT_OPTIONS,
} from '@pw/consts/measures';
import UnitField, { unitField } from '@pw/components/admin/SKUs/UnitField';
import NodeModalForm from './NodeModalForm';
import LookupSKU from '../LookupSKU';
import { useNodeDataContext } from '../NodeDataContext';

const GrainForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const { quantity = { value: '', unit: '' }, sku } = nodeData;
  const changeSet = {
    quantity: unitField(quantity),
    sku: [sku || '', yup.string()]
  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <UnitField
        label='Quantity'
        name='quantity'
        options={WEIGHT_UNIT_OPTIONS}
      />

      <LookupSKU type='grain' />
    </NodeModalForm>
  )
}

export default GrainForm;
