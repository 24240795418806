
const styles = {
  '.flex': {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '0.5rem',
    alignItems: 'center',
  },


  '&.timeline-root': {
    '--window-width': {
      xs: 'calc(100vw - 2rem)',
      md: 'calc(100vw - 250px - 2.5rem)'
    },
    '--horizontal-gap': {
      xs: 0,
      md: '3.5rem'
    },
    '--panel-width': {
      xs: 'var(--window-width)',
      md: 'calc( var(--window-width) - (2 * var(--horizontal-gap)) )',
    },

    // fill wrapper space
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  '.top-bar-root': {
    // position: 'absolute',
    boxSizing: 'border-box',
    position: 'relative',
    maxWidth: '100%',
    zIndex: 999,
    top: '2rem',
    left: '2.5rem',
    padding: '1rem',
    borderRadius: '0.3rem',
    width: 'calc( var(--window-width) - var(--horizontal-gap) )',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    columnGap: '3rem',
    backgroundColor: 'white',
    boxShadow: '0px 3px 3px 0px #00000012',
  },
  '.paging-buttons': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    columnGap: 0,

    '.viewing': {
      whiteSpace: 'pre',
      fontWeight: '500',
      fontSize: '14px',
      verticalAlign: 'baseline',
      width: '5rem', // prevent wriggling around as range changes
      transition: 'width 0.3s',
    },

    button: {
      px: '0.5rem'
    },
  },

  '.create-batch-root': {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 0,
    justifyContent: 'flex-end',

    button: {
      flexGrow: 0,
      width: 'fit-content'
    },
  },

  '.viewmode-day': {
    '.viewing': {
      width: '2.5rem',
      flexShrink: 0,
      flexGrow: 0,
    },
  },

  '.sub-header': {
    justifyContent: 'space-between',
    columnGap: '3rem',
  },

  '.pipeline': {
    fontSize: '1.25rem',
  },
  '.recipe': {
    fontSize: '17px',
    textTransform: 'uppercase',
    columnGap: 0,
    marginLeft: 'auto',
  },

  '.recipe-select.MuiInputBase-root': {
    backgroundColor: 'unset',
    border: 'none',
    fontWeight: 400,

    '.MuiSelect-select, fieldset': {
      backgroundColor: 'unset',
      border: 'none',
    },

    '.MuiSelect-select': {
      py: '0.5rem',

      '&::after': {
        display: 'block',
        content: "''",
        position: 'absolute',
        height: '1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '1rem',
        borderRight: '1px solid',
      },

    },

    svg: {
      display: 'block',
      marginRight: '-1rem',
    },
  },

  '.plant-list': {
    columnGap: '1.25rem'
  },
  '.plant-item': {
    backgroundColor: '#00A89D',
    color: 'white',
    borderRadius: '3px',
    padding: '0 0.5rem',
    height: '28px',
    lineHeight: 1,
    fontSize: '12px',

    '.label': {
      paddingRight: '0.25rem',
      textTransform: 'capitalize'
    },

    svg: {
      fontSize: '1.2rem',
    },

    '.count': {
      border: '1px solid #00A89D',
      backgroundColor: 'white',
      height: '100%',
      marginRight: '-0.5rem',
      padding: '0.5rem',
      borderRadius: '3px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      color: '#7B7B7B',
    },
  },

  '.timeline-body-root': {
    top: '5rem',
    bottom: 0,
    right: 0,
    width: 'var(--window-width)',
    position: 'absolute',
    zIndex: 99,
    overflowX: 'auto',
    backgroundColor: '#fafafa',
    columnGap: '0.5rem',
    // background: '#0ff2',
  },

  '.timeframe-buttons': { marginRight: 'auto' },
  '.viewmode, .pan': {
    fontSize: '0.75rem',
    borderRadius: '99rem',
    boxSizing: 'content-box',
    minHeight: 0,
    minWidth: 0,
    padding: '0.125rem 1rem',
    border: '2px solid transparent',

    '&:hover': {
      border: '2px solid #D9D9D9',
    },

    '&.active': {
      backgroundColor: '#D9D9D9',
    },
    '&:not([disabled])': {
      color: 'black',
    },
  },

  '.pan': {},

  '.timeline-positioning-ctx': {
    position: 'relative',
    height: 'calc(100% - 5rem)',
    top: '5rem',
    bottom: 0,
    width: 'var(--panel-width)',
    // hide scrollbar so only have to deal with button-driven nav
    overflow: 'hidden',
    left: 'var(--horizontal-gap)',
    // background: '#0f02',
  },

  '.timeline-window-root': {
    position: 'relative',
    top: '-1.75rem',
    bottom: 0,
    minHeight: '100%'
  },

  '.dropzone-root': {
    position: 'absolute',
    zIndex: 99,
    width: '100%',
    height: '100%',
  },

  '.dropzone-container': {
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
  },

  '.timeline-scrollable': {
    transition: 'all 0.6s',
    transitionBehavior: 'allow-discrete',
    //   transform: `translateX( calc( 
    //   (1 * var(--left-right-gap)) +
    //   var(--pane-width) * (var(--pan-offset) / var(--timeframe))
    //  ) )`,
    transform: `translateX( calc(
      var(--panel-width) * (var(--pan-offset) / var(--timeframe))
     ) )`,
    width: '100%'
  },

  '.dividers-root': {
    position: 'absolute',
    zIndex: 2,
    // left: 0,
    // right: 0,
    top: 0,
    bottom: 0,
    // width: '100%',
    height: '100%',
    transition: 'all 0.6s',
    transitionBehavior: 'allow-discrete',
    // 
    // width: `calc( var( --window-width ) * ( var(--width) / var(--timeframe ) ))`,
    // left: `calc( var( --window-width ) * ( var(--offset) / var(--timeframe ) ))`,
    width: `calc( var( --panel-width ) * ( var(--width) / var(--timeframe ) ))`,
    left: `calc( var( --panel-width ) * ( var(--offset) / var(--timeframe ) ))`,

    boxSizing: 'border-box',

    // marginLeft: 'calc( var(--left-right-gap) / 2)',
    // // '~ .dividers-root': {
    // //   marginLeft: 0,
    // // },

    '.title': {
      marginTop: 0,
      display: 'inline-block',
      width: 'auto',
      // transition: 'transform 0.1s',
      //   transform: `translateX(calc(
      //   -1.15 * var(--pane-width) * (var(--pan-offset) / var(--timeframe))
      // ))`,
      // left: `calc( 
      //   (1 * var(--left-right-gap)) + 
      //   var(--pane-width) * (var(--pan-offset) / var(--timeframe))
      //  )`,
      transformOrigin: 'left center',
    },
  },

  '.divider-items-container': {
    position: 'relative',
    // width: `calc( var( --window-width ) * ( var(--width) / var(--timeframe ) ))`,
    width: `calc( var( --panel-width ) * ( var(--width) / var(--timeframe ) ))`,
    transition: 'all 0.6s',
    transitionBehavior: 'allow-discrete',
    height: '4rem',
  },

  '.divider-item': {
    position: 'absolute',
    height: '100%',
    top: 0,
    paddingTop: '0.25rem',
    transition: 'all 0.3s',
    transitionBehavior: 'allow-discrete',
    // width: `calc( var( --window-width ) * ( var(--width) / var(--timeframe ) ))`,
    // left: `calc( var( --window-width ) * ( var(--offset) / var(--timeframe ) ))`,
    width: `calc( var( --panel-width ) * ( var(--width) / var(--timeframe ) ))`,
    left: `calc( var( --panel-width ) * ( var(--offset) / var(--timeframe ) ))`,

    borderTop: '5px solid #484848',
  },

  '.divider--day': {
    padding: '0.25rem',
    backgroundColor: '#eee',

    ':not(.day-name--Monday)': {
      color: '#aaa',
      // borderLeft: '1px solid #ddd'
    },
    '*': {
      width: '100%',
      textAlign: 'center',
    },
  },

  '.divider--view-day': {
    textAlign: 'center',
  },

  '.day-name': {
    display: 'none'
  },
  '.day-letter': {
    width: '100%',
    textAlign: 'center',
  },
  '.day-name--Sat, .day-name--Sun': {
    backgroundColor: '#fff',
    '*': { color: '#aaa' }
  },

  '.timeline-content': {
    position: 'relative',
    top: '8rem',
  },

  '.timeline-body-root.view-month, .timeline-body-root.view-week': {
    '.now': { display: 'none' }
  },

  '.now': {
    position: 'absolute',
    zIndex: 99,
    top: '3.5rem',
    left: 0,
    width: 'var(--offset)',

    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      zIndex: 1,
      left: 0,
      right: 0,
      top: '100%',
      height: '100vh',
      backgroundColor: '#0000000F',
      borderRight: '3px solid #484848',
      pointerEvents: 'none',
    },

    '.text': {
      position: 'absolute',
      zIndex: 2,
      left: '100%',
      transform: 'translateX(-50%)',
      top: '-1.5rem',
      fontSize: '12px',
      background: '#484848',
      borderRadius: '0.3rem',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: '0.25rem 0.75rem',
      color: 'white',
    },
  },
};

export default styles;
