import * as yup from 'yup';
import NodeModalForm from './NodeModalForm';
import { useNodeDataContext } from '../NodeDataContext';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';

const BeerWellForm = () => {
  const { nodeData = {} } = useNodeDataContext();
  const { policy_check } = nodeData;
  const changeSet = {
    policy_check: [policy_check || false, yup.boolean()]
  };

  return (
    <NodeModalForm changeSet={changeSet}>
      <FormikCheckBox
        label='Policy check (HPLC)'
        name='policy_check'
      />

    </NodeModalForm>
  )
}

export default BeerWellForm;
