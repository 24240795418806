import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import PolicyEditor from '@pw/components/PolicyEditor';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationSKUModal from '@pw/components/SKUSelector/modals/DestinationSKUModal';
import SourceSKUModal from '@pw/components/SKUSelector/modals/SourceSKUModal';
import SampleProperties from '@pw/components/SampleProperties';
import sampleFields from '@pw/components/SampleProperties/sampleFields';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS } from '@pw/consts/units';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function SampleForm() {
	let [searchParams] = useSearchParams();
	const asset = searchParams.get('asset');
	const [fetchAsset] = useAssetLazyQuery();
	const converter = useConverter();

	const [
		[policies, initializePolicies],
		PolicyComponent,
		PolicyModals,
	] = usePolicySelectorHook({
		requestType: REQUEST_TYPES.sample,
		title: 'Policies',
		PolicyModal: PolicyEditor,
	});

	const [
		[],
		[sourceAssets, initSourceAssets],
		initSources,
		SourceInventory,
		SourceModals,
	] = useSourceInventoryHook({
		title: 'Assets',
		filter: {
			asset_types: [
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.filling_tank,
			],
		},
	});

	const [
		[destinationSkus],
		[],
		initDestinations,
		DestinationInventory,
		DestinationModals,
	] = useInventorySelectorHook({
		title: 'Services',
		filter: {
			sku_types: [SKU_TYPES.SERVICE],
		},
		SKUModal: DestinationSKUModal,
	});

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...requestIdFields(initialValues),
			properties: sampleFields(initialValues?.properties),
			client: clientFields(initialValues?.client),
		}),
		[],
	);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback(
		(entity) => {
			initSources({
				...entity,
				assets: entity?.sources,
			});
			initDestinations({ ...entity, skus: entity?.sku_destinations });
			initializePolicies(entity?.policies);
		},
		[initSources, initDestinations, initializePolicies],
	);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sources: sourceAssets,
			sku_destinations: destinationSkus,
			properties: {
				...(entity?.properties ?? {}),
				sample_size: converter.cx(entity?.properties?.sample?.value, entity?.properties?.sample?.unit, UNITS.milliliters),
			},
			policies,
		}),
		[destinationSkus, sourceAssets, policies],
	);

	useEffect(() => {
		if (asset) {
			fetchAsset(asset).then((a) => {
				debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
			});
		}
	}, [asset, initSourceAssets, fetchAsset]);

	return (
		<>
			<RequestForm
				requestLabel='Sample'
				requestType={REQUEST_TYPES.sample}
				changeSetGenerator={changeSetGenerator}
				onInit={handleInit}
				onBeforeSave={handleBeforeSave}
			>
				<SourceInventory />
				<DestinationInventory />

				<SampleProperties name='properties' />

				<PolicyComponent />
			</RequestForm>

			<SourceModals />
			<DestinationModals />
			<PolicyModals />
		</>
	);
}

export default withAppLayout(SampleForm, { title: 'Sample' });
