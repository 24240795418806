import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import { ArrowComponent } from '@pw/components/Pagination';
import RequestListComponent from '@pw/components/RequestListItem';
import SKUItem from '@pw/components/Search/items/SKUItem';
import { Body3, Overline } from '@pw/components/Typography';
import { ASSET_NAMES, ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { REQUEST_NAMES, REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import {
	useSearchAssetQueryPaginated,
	useSearchRequestQueryPaginated,
} from '@pw/utilities/hooks/service/useSearchQuery';
import toPairs from 'lodash.topairs';
import { useMemo } from 'react';
import { SearchAssetListItemComponent } from '../AssetListItem/SearchAssetListItemComponent';
import { FlexBox } from '../Layout/FlexBox';
import ListItemSkeleton from '../sekeletons/ListItemSkeleton';
import PolicyListComponent, { PolicyListItemComponent } from '../admin/Policy/PolicyListItem';

function AssetEntry({
	assetType,
	items,
	buttonIcon,
	onItemClick,
	searchInputValue,
}) {
	const reqParams = useMemo(() => {
		return {
			search: searchInputValue,
			asset_types: [assetType],
			limit: 5,
			assetType: assetType,
		};
	}, [assetType, searchInputValue]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useSearchAssetQueryPaginated(reqParams, items);

	return (
		<Stack>
			<Stack
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Overline className='search-section-title'>
					{ASSET_NAMES[assetType]}
				</Overline>
				<ArrowComponent
					hasNextPage={hasNext}
					hasPrevPage={hasPrev}
					onPressNextPage={loadNext}
					onPressPrevPage={loadPrev}
					size={'small'}
				/>
			</Stack>

			{!isLoading && page && page.length > 0 && (
				<Stack className='list'>
					{page.map((a) => (
						<AssetListComponentV2
							item={a}
							key={a.path}
							buttonIcon={buttonIcon}
							onClick={onItemClick}
						>
							<SearchAssetListItemComponent item={a} />
						</AssetListComponentV2>
					))}
				</Stack>
			)}
			{!isLoading && (!page || page.length === 0) && (
				<Body3>Nothing yet.</Body3>
			)}
			{isLoading && (
				<Stack spacing={0.5}>
					<FlexBox justifyContent='flex-end' gap='2rem'>
						<Skeleton variant='rectangular' width={45} height={45} />
						<Skeleton variant='rectangular' width={60} height={35} />
					</FlexBox>
					<Stack className='list'>
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}

function AssetResults({ items, buttonIcon, onItemClick, searchInputValue }) {
	return (
		<Stack className='search-section' spacing={0.75}>
			{toPairs(items).map(([t, l]) => (
				<AssetEntry
					key={t}
					assetType={t}
					items={l}
					buttonIcon={buttonIcon}
					onItemClick={onItemClick}
					searchInputValue={searchInputValue}
				/>
			))}
		</Stack>
	);
}

function RequestEntry({
	requestType,
	items,
	buttonIcon,
	onItemClick,
	searchInputValue,
}) {
	const reqParams = useMemo(() => {
		return {
			search: searchInputValue,
			request_types: [requestType],
			limit: 5,
			requestType: requestType,
		};
	}, [requestType, searchInputValue]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useSearchRequestQueryPaginated(reqParams, items);

	return (
		<Stack>
			<Stack
				flexDirection={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Overline className='search-section-title'>
					{REQUEST_NAMES[requestType]}
				</Overline>
				<ArrowComponent
					hasNextPage={hasNext}
					hasPrevPage={hasPrev}
					onPressNextPage={loadNext}
					onPressPrevPage={loadPrev}
				/>
			</Stack>
			{!isLoading && page && page.length > 0 && (
				<Stack spacing={0.5}>
					<Stack className='list'>
						{page.map((a) => (
							<RequestListComponent
								item={a}
								key={a.path}
								buttonIcon={buttonIcon}
								onItemClick={() => onItemClick(a)}
							/>
						))}
					</Stack>
				</Stack>
			)}
			{!isLoading && (!page || page.length === 0) && (
				<Body3>Nothing yet.</Body3>
			)}
			{isLoading && (
				<Stack spacing={0.5}>
					<FlexBox justifyContent='flex-end' gap='2rem'>
						<Skeleton variant='rectangular' width={45} height={45} />
						<Skeleton variant='rectangular' width={60} height={35} />
					</FlexBox>
					<Stack className='list'>
						<ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
						<ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
						<ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}

function RequestResults({ items, buttonIcon, onItemClick, searchInputValue }) {
	return (
		<Stack className='search-section'>
			{toPairs(items).map(([t, l]) => (
				<RequestEntry
					key={t}
					requestType={t}
					items={l}
					buttonIcon={buttonIcon}
					onItemClick={onItemClick}
					searchInputValue={searchInputValue}
				/>
			))}
		</Stack>
	);
}

function SKUResults({ items, buttonIcon, onItemClick }) {
	return (
		<Stack className='search-section'>
			<Overline className='search-section-title'>SKU</Overline>
			<Stack className='list'>
				{items.map((a) => (
					<SKUItem item={a} key={a.path} onClick={() => onItemClick(a)} buttonIcon={buttonIcon} />
				))}
			</Stack>
		</Stack>
	);
}

function PolicyResults({ items, buttonIcon = null, onItemClick = null }) {
	return (
		<Stack className='search-section'>
			<Overline className='search-section-title'>Policy</Overline>
			<Stack className='list'>
				{items.map((a) => (
					<PolicyListComponent item={a} key={a.path} onClick={() => onItemClick(a)} buttonIcon={buttonIcon} />
				))}
			</Stack>
		</Stack>
	);
}

function DisplayResults({
	assets = {},
	requests = {},
	batches = [],
	skus = [],
    policies = [],
	...rest
}) {
	const assetCount = useMemo(
		() =>
			toPairs(assets)
				.map(([, l]) => l.length)
				.reduce((c, v) => c + v, 0),
		[assets],
	);
	const requestCount = useMemo(
		() =>
			toPairs(requests)
				.map(([, l]) => l.length)
				.reduce((c, v) => c + v, 0),
		[requests],
	);
	const results = useMemo(
		() => assetCount + requestCount + batches.length + skus.length + policies.length,
		[assetCount, requestCount, batches, skus, policies],
	);

	if (results === 0) {
		return <Body3>Nothing matches</Body3>;
	}

	return (
		<Box className='search-result-contents'>
			{skus.length > 0 && <SKUResults items={skus} {...rest} />}
			{assetCount > 0 && <AssetResults items={assets} {...rest} />}
			{requestCount > 0 && <RequestResults items={requests} {...rest} />}
			{policies.length > 0 && <PolicyResults items={policies} {...rest} />}
		</Box>
	);
}

export default DisplayResults;
