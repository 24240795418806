
import { Box, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from "react";

import FilledButton from '@pw/components/Buttons/FilledButton';
import { H4 } from "@pw/components/Typography";

import DesignerContext from '@pw/context/DesignerContext';
import styles from './Actions.styles';
import useProductionDesignerQuery from '@pw/utilities/hooks/logic/useNavigateProductionDesigner';
import { useNavigate } from 'react-router-dom';

const CREATE_BATCH_CODE = 'create_batch';

const BatchesTabs = () => {
	const navigate = useNavigate();

  const { skuId, batches, batchId, createNewBatch } = useContext(DesignerContext);
  const [labels, setLabels] = useState([]);
  useEffect(() => {
    console.log('batches', batches);
    const lbs = [
      ...batches.map(({ name, path }) => ({ name, path })),
      {name: '+ New Batch', path: CREATE_BATCH_CODE},
    ];
    setLabels(lbs);
  }, [batches]);

  const handleBatchChange = (_, value) => {
    if (value === CREATE_BATCH_CODE) {
      createNewBatch();
    } else {
      const link = useProductionDesignerQuery(skuId, value);
      navigate(link);
    }

  };

  return (
    <Tabs
      value={batchId}
      onChange={handleBatchChange}
      variant="scrollable"
      scrollButtons
      >
      {labels.map(({ name, path }) => (<Tab value={path} label={name} key={path} />))}
    </Tabs>
  )
};

const Actions = ({
  showGlobalMenu,
  hasContent,
}) => {
  const { mode, saveDesignDocument } = useContext(DesignerContext);

  const type = mode === 'schedule' ? 'Batch' : 'Recipe';

  return (
    <Box
      className={`recipe-actions-root
        ${showGlobalMenu ? 'with-global-menu' : ''}`}
      sx={styles}
    >
      {mode === 'schedule' /* recipeBatches?.length > 0 */ && (
        <Box className="tabs">
          <H4 className="heading">Production runs</H4>
          <BatchesTabs />
        </Box>
      )}

      <Box className="actions">
        {hasContent && (
          <FilledButton
            size="small"
            handleClick={saveDesignDocument}
          >Save&nbsp;{type}</FilledButton>
        )}
      </Box>
    </Box >
  );
}

export default Actions;
