import Stack from '@mui/material/Stack';
import { useFormikContext } from 'formik';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';

function CompanyMemberships() {
  const { values } = useFormikContext();

  return (
    <Stack spacing="1.5rem">
      {['GB', 'GB-SCT', 'GB-NIR', 'GB-ENG', 'GB-WLS'].includes(values.country) && (
        <>
          <H5>Duty</H5>
          <FormikCheckBox name="wowgr" label="Registered for WOWGR" />
        </>
      )}
    </Stack>
  );
}

export default CompanyMemberships;
