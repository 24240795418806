function SvgPlus(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				data-name='Union 3'
				className='plus_svg__cls-1'
				d='M27.18 55.1V32.55H4.63c-1.56 0-2.82-1.26-2.82-2.82s1.26-2.82 2.82-2.82h22.55V4.35c0-1.56 1.26-2.82 2.82-2.82s2.82 1.26 2.82 2.82v22.56h22.55c1.56 0 2.82 1.26 2.82 2.82s-1.26 2.82-2.82 2.82H32.82V55.1c0 1.56-1.26 2.82-2.82 2.82s-2.82-1.26-2.82-2.82z'
			/>
		</svg>
	);
}
export default SvgPlus;
