import { PERMISSIONS } from '@pw/consts/permissions';
import { checkPermission as chip } from './checkPermission';

const getPermissions = (canEdit, permission) => ({
	companyAdmin: canEdit && chip(permission, PERMISSIONS.COMPANY_ADMIN),
	userAdmin: canEdit && chip(permission, PERMISSIONS.USER_ADMIN),
	facilityAdmin: canEdit && chip(permission, PERMISSIONS.FACILITY_ADMIN),
    policyAdmin: canEdit && chip(permission, PERMISSIONS.POLICY_ADMIN),
	skuAdmin: canEdit && chip(permission, PERMISSIONS.SKU_ADMIN),
	viewActivity: canEdit && chip(permission, PERMISSIONS.VIEW_ACTIVITY),
	modifyRequest: canEdit && chip(permission, PERMISSIONS.MODIFY_REQUEST),
	editLocation: canEdit && chip(permission, PERMISSIONS.EDIT_LOCATION),
	editOwners: canEdit && chip(permission, PERMISSIONS.EDIT_OWNERS),
	editLiquid: canEdit && chip(permission, PERMISSIONS.EDIT_LIQUID),
	canApprove: canEdit && chip(permission, PERMISSIONS.CAN_APPROVE),
	assignTask: canEdit && chip(permission, PERMISSIONS.ASSIGN_TASK),
	canInstallContract: canEdit && chip(permission, PERMISSIONS.CONTRACT_INSTALL),
	canFundAccount: canEdit && chip(permission, PERMISSIONS.FUND_ACCOUNT),
	canViewCompanyList:
		canEdit && chip(permission, PERMISSIONS.VIEW_COMPANY_LIST),
	canMangeSensors: canEdit && chip(permission, PERMISSIONS.SENSOR_ADMIN),
	canModifyAsset: canEdit && chip(permission, PERMISSIONS.MODIFY_ASSET),
	// log: console.log('selectPermissions:', canEdit, permission),
});

export default getPermissions;
