import Box from '@mui/material/Box';
import { Body3, H5 } from '@pw/components/Typography';
import CSV from '@pw/components/icons/Csv';
import { ba, br3, bw1 } from '@pw/styles/border';
import { bg_near_white } from '@pw/styles/colors';
import { pv5 } from '@pw/styles/padding';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	isExcelFile,
	parseDataFromCsvFile,
	parseDataFromExcelFile,
} from '../utils';

const styles = {
	'&.root': {
		width: '100%',
	},
	'.fileSelector': {
		...ba,
		...bw1,
		borderStyle: 'dashed',
		...pv5,
		...br3,
		textAlign: 'center',
		zIndex: 2,
		borderColor: '#5d7ec1',
		'&[data-active=true]': {
			...bg_near_white,
			transition: 'background 0.1s ease-out',
		},
	},
};

function DropZone({ setData }) {
	const { enqueueSnackbar } = useSnackbar();

	const onDrop = useCallback(
		async (acceptedFiles) => {
			try {
				if (acceptedFiles.length < 1) {
					return;
				}
				const file = acceptedFiles[0];
				let results = null;
				if (isExcelFile(file)) {
					results = await parseDataFromExcelFile(file);
				} else {
					results = await parseDataFromCsvFile(file);
				}
				if (results?.errors?.length === 0) {
					enqueueSnackbar(`${file.name} successfully uploaded`, {
						variant: 'success',
					});
					setData({
						file: file.name,
						data: results,
					});
				} else {
					results.errors.forEach((error) => {
						enqueueSnackbar(`${error.message} line ${error.row}`, {
							variant: 'warning',
						});
					});
				}
			} catch (error) {
				enqueueSnackbar(error?.message, {
					variant: 'error',
				});
			}
		},
		[setData, enqueueSnackbar],
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			'text/csv': ['.csv'],
			'application/vnd.ms-excel': ['.xls'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
				'.xlsx',
			],
		},
		multiple: false,
		onDrop,
	});

	const isMobile = useIsMobile();

	return (
		<Box sx={styles} className='root'>
			<div
				className='fileSelector'
				data-active={!!isDragActive}
				{...getRootProps()}
			>
				<input {...getInputProps()} />
				<CSV height='46px' width='46px' />
				<H5>Select a file to upload</H5>
				<Body3>{isMobile ? 'Tap to select' : 'Or drag and drop a file'}</Body3>
			</div>
		</Box>
	);
}

export default DropZone;
