import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withDialogCard from '@pw/components/Cards/DialogCard';
import ExplorerLocation from '@pw/components/ExplorerLocation';
import { explorerLocationFields } from '@pw/components/ExplorerLocation/explorerLocationFields';
import ExplorerTable, { BrokerTable } from '@pw/components/ExplorerTable';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { paginationFields } from '@pw/components/Pagination/paginationFields';
import { H5 } from '@pw/components/Typography';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import {
	useCompanyFacilities,
	useCompanyHasExternalInventory,
	useCompanyHasOwnInventory,
} from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import { useMemo } from 'react';

function Explorer() {
	const facilities = useCompanyFacilities();
	const hasExternal = useCompanyHasExternalInventory();
	const hasOwn = useCompanyHasOwnInventory();

	const externalSet = useMemo(
		() => ({
			partner_id: [''],
			...paginationFields('12'),
		}),
		[],
	);

	const ownerSet = useMemo(
		() => ({
			location: explorerLocationFields({}),
			...paginationFields('12'),
		}),
		[],
	);

	usePageTitleHook('Explorer');

	return (
		<Box sx={styles} className='root'>
			{hasExternal && (
				<FormikForm changeSet={externalSet}>
					<Stack spacing="1.5rem">
						<H5>External Assets</H5>
						<BrokerTable />
					</Stack>
				</FormikForm>
			)}
			{hasOwn && (
				<FormikForm changeSet={ownerSet}>
					<Stack spacing={3}>
						<Stack spacing="1.5rem">
							<H5>Internal Assets</H5>
							<ExplorerLocation
								name='location'
								label='Location'
								facilities={facilities}
							/>
							<ExplorerTable />
						</Stack>
					</Stack>
				</FormikForm>
			)}
		</Box>
	);
}

export default withAppLayout(withDialogCard(Explorer));
