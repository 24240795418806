import { useAllCompanies, useCompanyId } from '@pw/redux/containers/User/hooks';
import { switchCompanyThunk } from '@pw/redux/thunks/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function CompanySelect({ children }) {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();

	const companies = useAllCompanies();

	const companyOptions = useMemo(() => {
		if (companies) {
			return companies.map((company) => ({
				label: company.company_name,
				value: company.company_id,
			}));
		}
		return [];
	}, [companies]);

	const company_id = useCompanyId();

	const handleSelect = (id) => dispatch(switchCompanyThunk({ company_id: id, i18n }));

	return (
		<>
			{children({
				handleSelect,
				companyOptions,
				company_id,
			})}
		</>
	);
}

export default CompanySelect;
