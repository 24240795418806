function SvgOverview(props) {
	return (
		<svg
			data-name='Group 1499'
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			viewBox='0 0 25.378 21.747'
			className='MuiSvgIcon-root'
			{...props}
		>
			<defs>
				<clipPath id='Overview_svg__a'>
					<path data-name='Rectangle 2248' fill='#393c3c' d='M0 0h25.378v21.746H0z' />
				</clipPath>
			</defs>
			<g data-name='Group 1425' clipPath='url(#Overview_svg__a)' fill='#393c3c'>
				<path
					data-name='Path 10192'
					d='M12.7.015c3.377 0 6.754.028 10.13-.015a2.468 2.468 0 012.547 2.557q-.008 6.176 0 12.352a2.423 2.423 0 01-2.567 2.563h-5.954c-.36 0-.36 0-.257.337l.784 2.577a.952.952 0 01-.994 1.358H8.984a.953.953 0 01-1.018-1.3c.2-.884.4-1.769.619-2.651.06-.246.007-.326-.259-.324-1.915.01-3.831.007-5.747 0A2.423 2.423 0 010 14.914V2.532A2.468 2.468 0 012.541 0C5.927.042 9.314.015 12.7.015m-.012 16.129h10.1c.865 0 1.26-.4 1.26-1.27V2.61a1.134 1.134 0 00-1.305-1.3H2.629a1.133 1.133 0 00-1.3 1.305v12.234c0 .917.378 1.3 1.289 1.3h10.071m-.021 4.313c1.007 0 2.014-.006 3.021 0 .26 0 .332-.063.247-.326q-.386-1.2-.735-2.406a.3.3 0 00-.35-.26q-2.281.011-4.561 0a.31.31 0 00-.362.285c-.174.79-.351 1.579-.547 2.364-.066.265-.017.349.266.345 1.007-.013 2.014-.005 3.021-.005'
				/>
				<path
					data-name='Path 10193'
					d='M8.305 7.33v.947c0 .525 0 .525.537.523.661 0 1.322-.007 1.983-.006.721 0 1.019.317.992 1.043A4.187 4.187 0 117.465 5.27a.82.82 0 01.841.846c.016.4 0 .809 0 1.214M6.981 8.41V7.018c0-.348-.046-.377-.379-.247a2.89 2.89 0 00-1.776 3.255 2.948 2.948 0 002.7 2.313 2.908 2.908 0 002.815-1.877c.108-.308.09-.337-.239-.337-.918 0-1.836-.007-2.754.009-.277 0-.39-.068-.376-.363.022-.453.006-.908.006-1.362'
				/>
				<path
					data-name='Path 10194'
					d='M8.934 6.435c0-.463-.006-.927 0-1.39a.791.791 0 01.825-.816 3.14 3.14 0 013.116 3.015.832.832 0 01-.9.929c-.907 0-1.814-.006-2.721.005-.244 0-.33-.079-.323-.324.014-.473 0-.947 0-1.42zm1.935.419c.108 0 .217-.007.324 0 .256.019.288-.084.183-.3a1.8 1.8 0 00-.733-.784c-.38-.215-.422-.192-.423.232v.206c.01.709-.1.634.648.64'
				/>
				<path
					data-name='Path 10195'
					d='M16.316 8.926v3.14a1.936 1.936 0 01-.02.354.651.651 0 01-1.285.011 1.284 1.284 0 01-.022-.265V5.62c0-.442.192-.721.525-.79.443-.092.8.23.8.749.006 1.116 0 2.231 0 3.347'
				/>
				<path
					data-name='Path 10196'
					d='M18.807 10.109c0 .71.005 1.421 0 2.131a.667.667 0 01-.65.731c-.4.005-.668-.295-.672-.773-.006-.868 0-1.736 0-2.6V8.089c.006-.478.273-.779.671-.774s.651.306.653.787v2.012'
				/>
				<path
					data-name='Path 10197'
					d='M21.298 10.782v1.449a.671.671 0 01-.638.74.651.651 0 01-.677-.7q-.025-1.508 0-3.016a.652.652 0 01.683-.7.676.676 0 01.631.748v1.479'
				/>
			</g>
		</svg>
	);
}
export default SvgOverview;
