function SvgRegauge(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 214.94 191.79'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M207.74 103.36c-3.49-.06-7.11-.11-10.68.03 0-.04 0-.09-.01-.14-.25-3.36-.57-6.11-1-8.64-2.78-16.3-9.73-30.97-20.67-43.6 2.54-2.35 4.97-4.86 7.32-7.28l.28-.29c3.01-3.1 3.19-6.88.46-9.61-2.74-2.74-6.51-2.55-9.61.47-2.36 2.3-5.03 4.9-7.58 7.6-2.95-2.52-5.48-4.49-7.96-6.19-13.39-9.18-28.11-14.36-43.73-15.42-.31-.02-.51-.05-.65-.08 0-.12-.01-.3 0-.54.16-3.03.11-6.07.05-9.01l-.02-1.01c-.04-2.37-.81-4.33-2.22-5.66-1.2-1.14-2.75-1.71-4.48-1.64-3.73.14-6.18 3.01-6.24 7.3-.06 4.15-.06 7.52 0 10.58-3.04.27-6.02.55-9.01 1.07-16.21 2.81-30.78 9.75-43.32 20.62-2.33-2.51-4.81-4.92-7.2-7.25l-.37-.36c-3.1-3.01-6.87-3.2-9.61-.47-2.75 2.74-2.57 6.51.45 9.61 2.64 2.71 5.05 5.16 7.6 7.59-11.69 13.51-18.87 29.47-21.32 47.43-.13.91-.08 1.82-.05 2.7.03.71.08 1.9-.05 2.16-.58.33-2.1.18-3.31.06-.81-.08-1.65-.16-2.5-.16H9.93v.14H8.47c-.23 0-.46 0-.7-.01-1.2-.03-2.69-.07-4.16.69-2.73 1.41-4.04 3.96-3.51 6.82.58 3.18 2.88 5.23 6 5.34l.41.02c3.51.13 7.15.26 10.76.04.27-.02.46-.01.59 0 .03.16.06.41.08.82.1 2.38.4 4.98.95 8.18 2.81 16.24 9.74 30.83 20.61 43.37-2.17 2.04-4.27 4.19-6.3 6.26l-1.07 1.09c-3.83 3.9-2.85 7.63-.65 9.82 1.13 1.12 2.66 1.93 4.4 1.92 1.66 0 3.52-.73 5.42-2.6 2.56-2.52 5.09-5.06 7.63-7.6l2.47-2.47c4.64-4.64 4.69-7.53.23-12.37-17.45-18.93-24.16-41.09-19.92-65.87 3.61-21.11 15.3-39.2 32.93-50.95 17.87-11.91 39.43-15.77 60.72-10.88 37.91 8.71 64.06 47.1 58.29 85.58-2.46 16.43-9.48 30.8-20.85 42.7-3.83 4-3.79 7.25.14 11.19 3.55 3.57 7.09 7.13 10.67 10.66 3.88 3.84 7.61 2.88 9.81.71 2.22-2.19 3.22-5.93-.61-9.83-2.17-2.21-4.73-4.8-7.38-7.36 12.98-15.08 20.26-32.66 21.64-52.28v-.04h.12c3.62.1 7.26.09 10.81-.01 2.16-.06 4.06-.86 5.33-2.24 1.13-1.22 1.7-2.83 1.62-4.54-.18-3.6-3.03-6.06-7.2-6.13z' />
			<path d='M160.25 56.88c-2.82-2.73-6.47-2.44-9.77.76-.34.33-.68.67-1.02 1.01l-8.71 8.71c-15.93 15.93-31.86 31.86-47.77 47.8-1.47-3.95-1.3-8.23.54-12.03 2.17-4.48 6.32-7.6 11.38-8.55 4.44-.83 6.97-3.76 6.44-7.45-.26-1.78-1.1-3.23-2.44-4.22-1.57-1.15-3.69-1.55-6.15-1.15-4.15.67-8.02 2.26-11.52 4.71-12.33 8.64-15.82 24.93-8.1 37.88.12.21.2.36.25.47-.04.04-.09.09-.16.14-1.73 1.48-3.23 3.08-4.48 4.46-2.55 2.82-2.49 6.69.15 9.19 1.26 1.2 2.8 1.8 4.37 1.8s3.18-.61 4.54-1.83c1.77-1.58 3.23-3.04 4.46-4.47.09-.1.16-.17.21-.23.09.04.23.11.4.22 11.2 6.7 25.45 4.99 34.64-4.16 9.26-9.22 11-23.56 4.23-34.86-.07-.11-.12-.21-.16-.28.05-.06.12-.13.21-.22 4.45-4.37 8.93-8.86 13.26-13.19l7.2-7.2c2.49-2.49 4.99-4.97 7.46-7.48 2.13-2.17 2.56-4.17 2.55-5.46-.02-1.62-.71-3.13-2-4.38zM118.11 121c-4.24 4.01-10.8 5.33-15.61 3.47l19.63-19.58c1.94 5.22.41 11.91-4.02 16.11z' />
		</svg>
	);
}
export default SvgRegauge;
