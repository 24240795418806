import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import QRImage from '@pw/components/QRImage';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useAccountId, useCompanyId, useCompanyName } from '@pw/redux/containers/User/hooks';
import { useMemo } from 'react';

export function ShareWithPartnerDialogQR({ open, onClose, partnerId }) {
	const companyId = useCompanyId();
	const companyName = useCompanyName();
	const accountId = useAccountId();

	const qq = btoa(JSON.stringify({ c: companyId, n: companyName, p: partnerId, a: accountId }));

	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const qrUrl = `${origin}/admin/partners?qq=${qq}`;

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title="Connect QR Code">
			<QRImage size={400} boxSize={400} value={qrUrl} />
		</ModalWithClose>
	);
}
