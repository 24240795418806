import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import GeneralDestinationSKU from '@pw/components/SKUSelector/modals/forms/GeneralDestinationSKU';
import TrackedSKU from '@pw/components/SKUSelector/modals/dest/TrackedSKU';
import AmountSKU from '@pw/components/SKUSelector/modals/forms/AmountSKU';
import ServiceSKU from '@pw/components/SKUSelector/modals/forms/ServiceSKU';
import { SKU_TYPES } from '@pw/consts/sku';

function DestinationSKUModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the SKU Item level
	const { sku_name, sku_type } = item ?? {};

	return (
		<ModalWithClose title={sku_name} open={open} onClose={() => onClose()}>
			{[SKU_TYPES.TRACKED].includes(sku_type) && (
				<TrackedSKU item={item} onClose={onClose} {...rest} />
			)}

			{[SKU_TYPES.WASTE].includes(sku_type) && (
				<AmountSKU item={item} onClose={onClose} {...rest} />
			)}

			{[SKU_TYPES.SERVICE].includes(sku_type) && (
				<ServiceSKU item={item} onClose={onClose} {...rest} />
			)}

			{[SKU_TYPES.FINISHED, SKU_TYPES.WIP].includes(sku_type) && (
				<GeneralDestinationSKU item={item} onClose={onClose} {...rest} />
			)}
		</ModalWithClose>
	);
}

export default DestinationSKUModal;
