import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikNumberField, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';
import { useCallback } from 'react';
import * as yup from 'yup';

function BayModal({ item, open, upsert, onClose }) {
	const [entry, index] = item ?? [['', null, null, null], undefined];
	const [bay, rows, levels, editing] = entry ?? ['', null, null, false];

	const changeSet = {
		bay: [bay ?? '', yup.string().min(1).required('Bay is required!')],
		rows: [rows ?? '', yup.number().positive().integer().required('Rows are required!')],
		levels: [levels ?? '', yup.number().positive().integer().required('Levels are required!')],
	};

	const handleSubmit = useCallback(
		(values) => {
			console.log('Values', values);
			upsert([values.bay, values.rows, values.levels, editing], index);
			onClose();
		},
		[editing, index],
	);

	return (
		<Modal open={open} onClose={onClose}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Bay</H5>
					<IconButton onClick={() => onClose()} className='close-btn' aria-label='Close'>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing={1}>
						<FormikTextField
							label='Bay'
							name='bay'
							fullWidth
						/>
						<FlexBox>
							<FormikNumberField label='Rows' name='rows' fullWidth />
							<FormikNumberField label='Levels' name='levels' fullWidth />
						</FlexBox>

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								{editing ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</Stack>
		</Modal>
	);
}

export default BayModal;
