import { downloadFacilityAssets } from '@pw/services/asset.service';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';
import useLoading from '@pw/utilities/hooks/useLoadingHook';

const serviceFunction = (params) => downloadFacilityAssets(params);
export const [
	useDownloadFacilityAssetsLazyQuery,
	useDownloadFacilityAssetsQuery,
] = genServiceCallHooks(serviceFunction, { useLoadingHook: useLoading });

export default useDownloadFacilityAssetsLazyQuery;
