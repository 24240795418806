import mapToOptions from '@pw/utilities/mapToOptions';

export const COMPANY_STATUS = {
	PENDING: 'pending',
	ACTIVE: 'active',
	SUSPENDED: 'suspended',
	READONLY: 'readonly',
};

export const UNIT_FORMAT_TYPES = {
	Imperial: 'Imperial',
	Metric: 'Metric',
};

export const UNIT_OPTIONS = mapToOptions(UNIT_FORMAT_TYPES);

export const COMPANY_TYPE_REVERSE = {
	'0': 'View Only',
	'1': 'Distillery',
	'2': 'Warehouse',
	'3': 'Brand',
	'4': 'Broker',
	'5': 'Co-Packer',
};

export const COMPANY_TYPE = {
	VIEW: '0',
	DISTILLERY: '1',
	WAREHOUSE: '2',
	BRAND: '3',
	BROKER: '4',
	CO_PACKER: '5',
};

export const COMPANY_FEATURES = {
	ALL: ['warehousing', 'production', 'bottling'],
	WAREHOUSING: 'warehousing',
	PRODUCTION: 'production',
	BOTTLING: 'bottling',
};
