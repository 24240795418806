import * as React from "react";
function SvgProofworksIcon(props) {
  return (
    <svg
      width={160}
      height={160}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.008 0h63.976v64.007H64.008v63.972H-.002V0h64.01zM95.99 95.993H160V160H95.99V95.993z"
        fill="currentColor"
      />
    </svg>
  );
}
export default SvgProofworksIcon;
