import { Box } from '@mui/material';
// import ProofworksIcon from '@pw/components/icons/ProofworksIcon';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { styles } from '@pw/components/QRImage/styles';
import CircleLogo from '@pw/components/icons/CircleLogo';
import QRCode from 'react-qr-code';

function QRImage({
	size = 512,
	boxSize = 165,
	value = null,
	title = null,
	isLogoVisible = true,
	...props
}) {
	const logoSize = boxSize / 3.5;

	return (
		<FlexBox justifyContent='center' style={styles.wrapper} {...props}>
			<QRCode
				style={{
					width: boxSize,
					height: boxSize,
				}}
				value={value}
				size={size}
				level='Q'
				title={title}
			/>

			{isLogoVisible && (
				<Box
					style={styles.logo}
					display='flex'
					alignItems='center'
					justifyContent='center'
					className='logoWrapper'
				>
					<CircleLogo height={logoSize} width={logoSize} />
				</Box>
			)}
		</FlexBox>
	);
}

export default QRImage;
