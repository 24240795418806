
import { AccountCircle } from '@mui/icons-material';
import { Collapse, Divider, Stack } from '@mui/material';
import SocialSignin from '@pw/components/Auth/SocialSignin';
import FilledButton from '@pw/components/Buttons/FilledButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
	FormikForm,
	FormikPasswordField,
} from '@pw/components/Forms/FormikForm';
import { Body3 } from '@pw/components/Typography';
import { IDENTITY } from '@pw/consts/account';
import FormikContext from '@pw/context/FormikContext';
import { signinUserThunk, signinWithPasskeyThunk } from '@pw/redux/thunks/signin';
import useInviteHook from '@pw/utilities/hooks/service/useInviteHook';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import 'yup-phone';
import Identity from '../Identity';

function PasswordForm() {
	const { values, errors } = useContext(FormikContext);
	const { type, ident } = values;

	const show = useMemo(() => {
		return ident && ident.length > 0;
	}, [ident, errors]);

	return (
		<Collapse in={!!show} unmountOnExit>
			<Stack spacing={3}>
				<Stack spacing={2}>
					<FormikPasswordField
						name='password'
						label='Password'
						fullWidth
						required
						autoComplete='password'
					/>
					<Body3>
						Click&nbsp;
						<Link to="/recover" state={{ account: { type, ident } }} className='forgotLink'>
							here
						</Link>
						&nbsp;if you have forgotten your password.
					</Body3>
				</Stack>

				<FilledButton
					icon={<AccountCircle />}
					iconPosition='start'
					label='Sign In'
					type='submit'
				/>
			</Stack>
		</Collapse>
	);
}

function LoginForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { i18n } = useTranslation();

	// See if there is an invite
	const { account, invite } = useInviteHook();
	const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};

	const { state } = useLocation();
	const { from = '/' } = state ?? {};
	const changeSet = useMemo(
		() => ({
			type: [
				type ?? IDENTITY.EMAIL,
				yup.string().required('Identity type is required!'),
			],
			ident: [
				ident,
				yup.string().test({
					name: 'valid_identity',
					test: (value, ctx) => {
						// console.log('Testing', value, ctx.parent.type);
						switch (ctx.parent.type) {
							case IDENTITY.EMAIL: {
								if (!value) {
									return ctx.createError({
										path: 'identity',
										message: 'Email is required!',
									});
								}
								const valid = yup.string().email().isValidSync(value);
								// console.log(' -->', value, valid);
								if (!valid) {
									return ctx.createError({
										path: 'identity',
										message: 'Invalid email!',
									});
								}
								break;
							}
							case IDENTITY.PHONE: {
								// regex only phone number with +
								const onlyNumeric = /^\+?\d+$/;
								if (!value || !onlyNumeric.test(value)) {
									return ctx.createError({
										path: 'identity',
										message: 'Phone number is required!',
									});
								}
								const valid = yup.string().phone().isValidSync(value);
								// console.log(' -->', value, valid);
								if (!valid) {
									return ctx.createError({
										path: 'identity',
										message: 'Invalid phone number!',
									});
								}
								break;
							}
							default: {
								break;
							}
						}
						return true;
					},
				}),
			],
			password: ['', yup.string().required('Password is required!')],
		}),
		[ident, type],
	);

	const redirect = useCallback(() => {
		console.log('redirect', from);
		if (['/signin', '/recover', '/register', '/logout', '/not-found', '/not-online', '/upgrade'].includes(from?.pathname)) {
			navigate('/app/overview');
		} else {
			navigate(from);
		}
	}, [from, navigate]);

	const handleSubmit = (values) => {
		dispatch(signinUserThunk({ ...values, invite, i18n }))
			.unwrap()
			.then(redirect);
	};

	const handlePasskeyClick = (type, ident) => {
		dispatch(signinWithPasskeyThunk({ type, ident, invite, i18n }))
			.unwrap()
			.then(redirect);
	};

	return (
		<>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit} enableReinitialize={false}>
				<Stack spacing={3}>
					<Identity
						type='type'
						name='ident'
						handlePasskeyClick={handlePasskeyClick}
					/>

					<PasswordForm />

					<Errors />
				</Stack>
			</FormikForm>

			<Divider>or</Divider>

			<SocialSignin
				// onLogin={handleResponse}
				account={account}
				invite={invite}
				redirect={redirect}
			/>
		</>
	);
}

// function PasswordSignin(props) {
// 	// For this to remain secure, user must not share the registration link!
// 	const { invited } = useContext(SigninContext);
//
// 	return (
// 			<LoginForm />}
// 			{page === 1 && <PasswordForm changeIdentity={() => setPage(0)} {...props} />}
// 		</>
// 	);
// }

export default LoginForm;
