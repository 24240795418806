import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { LocationSelect } from '@pw/components/ExplorerLocation';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import RevertMessage from '../Forms/RevertMessage';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';
import toLocation from '@pw/utilities/adapters/toLocation';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';

function LocationImpl({
	hide,
	setLocation,
	disabled = false,
	showCheckBox = true,
}) {
	const { values, setFieldValue } = useContext(FormikContext);

	// We need map here
	const facilities = useCompanyFacilities();

	// console.log('Facilities', facilities);

	useEffect(() => {
		if (hide) {
			debounce(() => setFieldValue('enable', false), 50);
		}
	}, [hide]);

	const setValues = (facility_id, bay, row, level) => {
		console.log('Setting location', facility_id, bay, row, level);

		debounce(() => {
			setFieldValue('facility_id', facility_id);
			setFieldValue('bay', bay);
			setFieldValue('row', row);
			setFieldValue('level', level);
		}, 25);
	};

	const setLocationValues = useCallback(
		(facility_id, bay, row, level) => {
			console.log('Checking location', facility_id, bay, row, level);
			// First find the facility
			if (!facility_id) {
				setValues('', '', '', '');
				return;
			}
			const facility = facilities[facility_id];

			console.log('Facility', facility);

			// Find the bay
			const facilityBay = facility.bays.find(([b]) => b === bay);
			if (!facilityBay) {
				setValues(facility.path, '', '', '');
				return;
			}
			console.log('Bay, facilityBay', facilityBay);
			setValues(facility_id, bay, row, level);
		},
		[setFieldValue, facilities],
	);

	const handleOnQrSuccess = useCallback(
		(data) => {
			// const { text } = data; // { t: 'loc', l: ['f', 'b', 'r', 'lv'] }
			// try {
			// 	const { t, l } = JSON.parse(text);
			// 	if (t && l && t === 'loc' && Array.isArray(l)) {
			// 		const [f, b, r, lv] = l;
			// 		setLocationValues(f, b, r, lv);
			// 	}
			// } catch (e) {
			// 	console.log('Invalid location!', text, e);
			// }
			const { text } = data; // 'https://test.metacask.com:4430/explorer?dt=1,2,3,4';
			const [facility_id, bay, row, level] = decodeURIComponent(
				text.split('?dt=')[1],
			).split(',');
			setLocationValues(facility_id, bay, row, level);
		},
		[setLocationValues],
	);

	// will remove form data if location is disabled
	useEffect(() => {
		// console.log('Values>', values);
		if (values?.enable === false) {
			setValues('', '', '', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.enable]);

	return (
		<Stack>
			{!!showCheckBox && (
				<FormikCheckBox
					name='enable'
					label={<H5>Location</H5>}
					disabled={hide || disabled}
				/>
			)}

			<Collapse in={showCheckBox ? !!values?.enable : !showCheckBox}>
				<FlexBox alignItems='flex-start' gap={2}>
					<ScanQR
						onSuccess={handleOnQrSuccess}
						width={24}
						height={24}
						withLabel
						disabled={disabled}
						Component={TitledButton}
					/>
					<LocationSelect
						facilities={facilities}
						setLocation={setLocation}
						disabled={disabled}
					/>
				</FlexBox>
			</Collapse>
		</Stack>
	);
}

function LocationWrapper({ entity, ...rest }) {
	const { values, setFieldValue } = useContext(FormikContext);
	const [historyExist, isSame, history] = useAssetRevertHook(
		entity,
		(e, e2) => e?.unique_location_id === e2?.unique_location_id,
	);
	const onRevert = useCallback(() => {
		const { facility_id, bay, row, level } = toLocation(
			history?.unique_location_id,
		);
		setFieldValue('enable', history?.unique_location_id !== '0/0/0/0/0');
		setFieldValue('facility_id', facility_id);
		setFieldValue('bay', bay);
		setFieldValue('row', row);
		setFieldValue('level', level);
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		const { facility_id, bay, row, level } = toLocation(
			entity?.unique_location_id,
		);
		setFieldValue('enable', entity?.unique_location_id !== '0/0/0/0/0');
		setFieldValue('facility_id', facility_id);
		setFieldValue('bay', bay);
		setFieldValue('row', row);
		setFieldValue('level', level);
	}, [entity, setFieldValue]);

	const isReverted = useMemo(() => {
		const unique_location_id = toUniqueLocation({
			facility_id: values?.['facility_id'],
			bay: values?.['bay'],
			row: values?.['row'],
			level: values?.['level'],
		})

		return unique_location_id === history?.unique_location_id
	}, [history, values])
	return (
		<Box sx={{ position: 'relative' }}>
			{historyExist && !isSame && (
				<RevertMessage
					title='Location'
					onRevert={onRevert}
					item={history?.unique_location_id}
					onUndo={onUndo}
					isReverted={isReverted}
					type="location"
				/>
			)}
			<LocationImpl {...rest} />
		</Box>
	);
}

export default function Location({ name, ...rest }) {
	const { readonly } = useContext(FormikContext);
	return (
		<FormikProvider path={name}>
			<LocationWrapper {...rest} disabled={readonly || rest.disabled} />
		</FormikProvider>
	);
}
