import section2ReportFields from './section2ReportFields';

export default function section2Fields(fields = {}) {
	const {
		On_hand_first_month = '',
		Received = '',
		Increase_due_to_redenaturation = '',
		Specify_18 = '',
		Shipped = '',
		Specify_21 = '',
		Losses = '',
		On_hand_end_of_month = '',
	} = fields;

	return {
		On_hand_first_month: section2ReportFields(On_hand_first_month),
		Received: section2ReportFields(Received),
		Increase_due_to_redenaturation: section2ReportFields(
			Increase_due_to_redenaturation,
		),
		Specify_18: section2ReportFields(Specify_18),
		Shipped: section2ReportFields(Shipped),
		Specify_21: section2ReportFields(Specify_21),
		Losses: section2ReportFields(Losses),
		On_hand_end_of_month: section2ReportFields(On_hand_end_of_month),
	};
}
