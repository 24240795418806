import * as yup from 'yup';

export default function part4ReportFields(fields = {}) {
	const { a, b, c, d, e, f } = fields;

	return {
		a: [a, yup.number().min(0, 'Kind value cannot be less than 0')],
		b: [b, yup.number().min(0, 'Bulk Spirits value cannot be less than 0')],
		c: [
			c,
			yup.number().min(0, 'Bottled - Imported value cannot be less than 0'),
		],
		d: [d, yup.number().min(0, 'Bottled value cannot be less than 0')],
		e: [e, yup.number().min(0, 'Bottled in Bond value cannot be less than 0')],
		f: [
			f,
			yup.number().min(0, 'Bottled for Export value cannot be less than 0'),
		],
	};
}
