import Organizations from '@pw/components/Account/Organizations';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function OrganizationsPage() {
	usePageTitleHook('Manage Organizations');

	return (
		<Organizations />
	);
}

export default withAppLayout(withDialogCard(OrganizationsPage));
