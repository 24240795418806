/* eslint-disable no-bitwise */
// TODO: checkPerm is failing on val = b0, perm = n128
const checkPerm = (val, perm) => (BigInt(val) & BigInt(perm)) !== BigInt(0);

const fromPermString = (val) => {
	if (!val)
		return BigInt(0);
	if (typeof val === 'bigint')
		return val;
	return BigInt(val.startsWith('0b') ? val : `0b${val}`);
};

/**
 * A function to check if a user has the proper permission.
 * @param {object} accountPerm - The user permissions.
 * @param {BigInt} perm - The permission to check for.
 * @returns {boolean} - Returns true if the user has the specified permission, otherwise false.
 */
export const checkPermission = (accountPerm, perm) => accountPerm && checkPerm(fromPermString(accountPerm), perm);

export const permStringToArray = (accountPerm, permissions) => {
	return (permissions ?? []).reduce((acc, p)=>{
		if(p?.code && checkPermission(accountPerm, p?.code)){
			return [...acc, p?.code];
		}
		return acc;
	}, []);
};

