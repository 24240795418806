import { withEmptyLayout } from '@pw/components/Layout/EmptyLayout';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { persistor } from '@pw/redux/store';
import { logoutThunk } from '@pw/redux/thunks/user';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Logout() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	usePageTitleHook('Logout');

	useEffect(() => {
		dispatch(logoutThunk())
			.unwrap()
			.finally(() => {
				persistor.purge();
				navigate('/');
			});
	}, []);

	return <></>;
}

export default withEmptyLayout(Logout);
