import { CURRENCIES } from "@pw/consts/currencies";
import { UNITS } from "@pw/consts/units";
import useConverter from "@pw/utilities/hooks/logic/useConverter";
import { useCallback } from "react";

const useUSCompliance = () => {
  const converter = useConverter();
  const currency = CURRENCIES.USD;
  const date = Date.parse('2024-09-01');

  const code = {
    us_whiskey_160_under: {
      date,
      code: 'us/whiskey<=160',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Whiskey 160 Proof and Under',
      unit: UNITS.proof_gallons,
    },
    us_whiskey_160_over: {
      date,
      code: 'us/whiskey>160',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Whiskey Over 160 Proof',
      unit: UNITS.proof_gallons,
    },
    us_brandy_170_under: {
      date,
      code: 'us/brandy<=170',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Brandy 170 Proof and Under',
      unit: UNITS.proof_gallons,
    },
    us_brandy_170_over: {
      date,
      code: 'us/brandy>170',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Brandy Over 170 Proof',
      unit: UNITS.proof_gallons,
    },
    us_rum: {
      date,
      code: 'us/rum',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Rum',
      unit: UNITS.proof_gallons,
    },
    us_gin: {
      date,
      code: 'us/gin',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Gin',
      unit: UNITS.proof_gallons,
    },
    us_vodka: {
      date,
      code: 'us/vodka',
      rate: [{ rate: 2.7 }, { rate: 13.5 } ],
      currency,
      name: 'Vodka',
      unit: UNITS.proof_gallons,
    }
  };

  const getNABCASuffix = (size) => {
    const convertedSize = Number(converter.cx(size.value, size.unit, UNITS.milliliters));

    const sizes = [
      { "ml": 50, "ounces": 1.7, "suffix": "05" },
      { "ml": 100, "ounces": 3.4, "suffix": "01" },
      { "ml": 118, "ounces": 4.0, "suffix": "11" },
      { "ml": 144, "ounces": 4.8, "suffix": "14" },
      { "ml": 148, "ounces": 5.0, "suffix": "13" },
      { "ml": 150, "ounces": 5.1, "suffix": "12" },
      { "ml": 180, "ounces": 6.1, "suffix": "61" },
      { "ml": 187, "ounces": 6.3, "suffix": "18" },
      { "ml": 200, "ounces": 6.8, "suffix": "20" },
      { "ml": 236, "ounces": 8.0, "suffix": "23" },
      { "ml": 237, "ounces": 8.0, "suffix": "24" },
      { "ml": 250, "ounces": 8.5, "suffix": "22" },
      { "ml": 275, "ounces": 9.0, "suffix": "27" },
      { "ml": 281, "ounces": 9.5, "suffix": "26" },
      { "ml": 295, "ounces": 10.0, "suffix": "29" },
      { "ml": 300, "ounces": 10.2, "suffix": "31" },
      { "ml": 330, "ounces": 11.0, "suffix": "33" },
      { "ml": 341, "ounces": 11.5, "suffix": "34" },
      { "ml": 355, "ounces": 12.0, "suffix": "35" },
      { "ml": 375, "ounces": 12.7, "suffix": "37" },
      { "ml": 425, "ounces": 14.4, "suffix": "42" },
      { "ml": 500, "ounces": 16.9, "suffix": "50" },
      { "ml": 650, "ounces": 22.0, "suffix": "65" },
      { "ml": 715, "ounces": 24.2, "suffix": "71" },
      { "ml": 720, "ounces": 24.4, "suffix": "72" },
      { "ml": 750, "ounces": 25.4, "suffix": "75" },
      { "ml": 820, "ounces": 28.0, "suffix": "28" },
      { "ml": 946, "ounces": 32.0, "suffix": "32" },
      { "ml": 1000, "ounces": 33.8, "suffix": "10" },
      { "ml": 1500, "ounces": 50.7, "suffix": "15" },
      { "ml": 1750, "ounces": 59.2, "suffix": "17" },
      { "ml": 1800, "ounces": 60.9, "suffix": "19" },
      { "ml": 2000, "ounces": 67.6, "suffix": "25" },
      { "ml": 3000, "ounces": 101.4, "suffix": "30" },
      { "ml": 4000, "ounces": 135.2, "suffix": "40" },
      { "ml": 4540, "ounces": 145.28, "suffix": "45" },
      { "ml": 5000, "ounces": 169.0, "suffix": "51" },
      { "ml": 6000, "ounces": 202.8, "suffix": "60" },
      { "ml": 9000, "ounces": 304.2, "suffix": "79" },
      { "ml": 10000, "ounces": 338.0, "suffix": "80" },
      { "ml": 12000, "ounces": 405.6, "suffix": "82" },
      { "ml": 15000, "ounces": 507.0, "suffix": "85" },
      { "ml": 18000, "ounces": 608.6, "suffix": "88" },
      { "ml": 19000, "ounces": 642.5, "suffix": "89" },
      { "ml": 20000, "ounces": 676.0, "suffix": "90" },
      { "ml": 29000, "ounces": 980.5, "suffix": "92", "note": "¼ Keg" },
      { "ml": 30000, "ounces": 1014.0, "suffix": "93" },
      { "ml": 58000, "ounces": 1961.0, "suffix": "95", "note": "½ Keg" },
      { "ml": 60000, "ounces": 2028.0, "suffix": "96" }
    ];

    let cc = '';
    for (const size of sizes) {
      if (size.ml === convertedSize) {
        return size.suffix;
      }
      if (size.ml > convertedSize) {
        break;
      }
      cc = size.suffix;
    }
    return cc;
  }

  const getTaxCode = useCallback((liquid, abv) => {
    const convertedProof = Number(converter.cx(abv.value, abv.unit, UNITS.proof));

    // Based on category
    switch (liquid.category) {
      case 'whisky': {
        if (convertedProof <= 160) {
          return code.us_whiskey_160_under;
        } else {
          return code.us_whiskey_160_over;
        }
      }
      case 'brandy': {
        if (convertedProof <= 170) {
          return code.us_brandy_170_under;
        } else {
          return code.us_brandy_170_over;
        }
      }
      case 'rum': {
        return code.us_rum;
      }
      case 'gin': {
        return code.us_gin;
      }
      case 'vodka': {
        return code.us_vodka;
      }
      default:
        break;
    }
    return null;



  }, [converter]);  

  return { getTaxCode, getNABCASuffix }
};

export default useUSCompliance;
