import chunk from 'lodash.chunk';
import { useCallback, useState } from 'react';

const usePaginatedList = ({ list, pageSize = 5 }) => {
	const pages = chunk(list, pageSize);
	const [currentPage, setCurrentPage] = useState(0);

	const prev = useCallback(() => {
		if (currentPage) {
			setCurrentPage(currentPage - 1);
		}
	}, [currentPage]);

	const next = useCallback(async () => {
		if (currentPage + 1 < pages.length) {
			setCurrentPage(currentPage + 1);
		}
	}, [currentPage, pages]);

	return [
		pages[currentPage],
		currentPage > 0,
		currentPage + 1 < pages.length,
		prev,
		next,
	];
};

export default usePaginatedList;
