import { useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { H5 } from '@pw/components/Typography';
import { FormikTimePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DesignerContext from '@pw/context/DesignerContext';
import { getDesignObservations } from '@pw/services/production.service';
import { CircularProgress } from '@mui/material';

export function timestampField(params = {}) {
  const now = new Date().toUTCString();
  const { date = now, label = '' } = params;

  return {
    date: [date, yup.string()],
    // label: [label, yup.string()],
  };
}
const baseFieldNames = Object.keys(timestampField());

const styles = {
  '.flexbox-root': {
    columnGap: '1.5rem',
    '> *': {
      width: '50%',
      flexGrow: 1,
    }
  },
  '&.section': {
    ol: {
      marginBlockStart: '0.25rem',
      paddingInlineStart: '0.5rem',
      listStyle: 'none',
    },
    li: {},
    'li::marker': { display: 'none' },
  },
};

const ScheduledObservation = ({ children }) => {

  return (
    <Box className="section" sx={styles}>
      <H5 className="section-title">Add Measurement</H5>
      <Stack gap="1.5rem">
        <FlexBox sx={{}}>
          <FormikTimePicker
            name='date'
            label='Observed at'
            required
          />
          {/* <FormikTextField
            label="Label"
            name="label"
          /> */}
        </FlexBox>
        {children}
      </Stack>
    </Box>
  )
}

const timeToNum = (time) => Number(time.replaceAll(':', ''));
const dateToNum = (dateString) => {
  const date = new Date(dateString);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return +[y, m, d].join('');
}

// chronological
const sortByTime = (a, b) => timeToNum(a) - timeToNum(b);
const sortByDate = (a, b) => dateToNum(a) - dateToNum(b);
const entriesByDateReducer = (acc, { date, ...rest }) => {
  const d = date.toLocaleDateString();
  const time = date.toLocaleTimeString();
  return {
    ...acc,
    [d]: [...(acc[d] || []), { time, ...rest }],
  }
};

const entriesByTimeReducer = (acc, { time, ...rest }) => {
  return {
    ...acc,
    [time]: [...(acc[time] || []), rest?.values],
  }
};

const readingValueFormatter = (v) => v?.hasOwnProperty('value')
  ? (v.value && v.unit) && `${v.value}${v.unit}`
  : typeof v === 'string' ? v : JSON.stringify(v);

const ObservationGroup = ({ date, entries }) => {
  const grouped = entries.reduce(entriesByTimeReducer, {});

  return (
    <Box as="table">
      <Box as="thead">
        <Box as="tr">
          <Box as="th" className="group-title" colSpan="3"><H5>{date}</H5></Box>
        </Box>
      </Box>
      <Box as="tbody">
        {Object.entries(grouped).map(([time, [readings]], idx) => {
          return (
            <Box as="tr" key={time}
              className={`${idx % 2 === 0 ? 'even' : 'odd'}`}>
              <Box as="th" rowSpan={readings.length}>{time}</Box>
              <Box as="td">
                {readings.map(({ id, _time, value, type, ...rest }) => {
                  const v = readingValueFormatter(value);
                  if (!v) return null;
                  return (
                    <Box as="tr" key={id}>
                      <Box as="td">{type}</Box>
                      <Box as="td">{v}</Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  );
}

const PreviousObservations = ({ fieldNames = [], showLabels = false }) => {
  // const extendedFieldNames = [...baseFieldNames, ...fieldNames];
  let allFields = [];

  const {
    batch,
    selectedNode,
  } = useContext(DesignerContext);
  const [prevData, setPrevData] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadPreviousObservations = async (node_id, {batch_id, recipe_id}) => {
    if (!batch_id || !recipe_id || !batch) {
      return;
    }
    try {
      setLoadingState(true);
      const result = await getDesignObservations({recipe_id, batch_id, node_id});

      const formatted = [];
      result.map((item) => {
        let finalItem = {date: new Date(item.time)};
        let value = item.value;
        const finalItemValues = [];
  
        try {
          value = JSON.parse(item.value);
        } catch(e) {};
  
        fieldNames.filter(it => it !== 'observation_name')
          .forEach((fn) => {
            finalItemValues.push({
              value: value[fn],
              type: fn,
            });
          });
          finalItem['values'] = finalItemValues;
        formatted.push(finalItem);
      });

      setPrevData(formatted);
    } catch (e) {
      console.error('prev observation error', e);
    }

    setIsLoaded(true);
    setLoadingState(false);
  };

  useEffect(() => {
    loadPreviousObservations(selectedNode.id, batch);
  }, [batch, selectedNode]);

  const groupedByDay = prevData.reduce(entriesByDateReducer, {});
  Object.entries(groupedByDay).sort(([k1], [k2]) => sortByDate(k1, k2));

  if (loadingState) {
    return <CircularProgress className='spinner center' sx={{'alignSelf': 'center'}} color='inherit' />;
  }

  if (isLoaded && Object.keys(groupedByDay).length === 0) {
    console.log('groupedByDay', groupedByDay);
    return <H5 sx={{'alignSelf': 'center'}}>No Previous Observations recorded.</H5>
  }

  return Object.keys(groupedByDay).length > 0 && (
    <Box className="section" sx={styles}>
      <H5 className="section-title">Recorded Measurements</H5>
      <Stack gap="1.5rem" className="section-content">
        {Object.entries(groupedByDay).map(([date, entries]) => (
          <ObservationGroup key={date} date={date}
            entries={entries} />
        ))}
      </Stack>
    </Box>
  );
}


const ObervationForm = ({ nodeData, fieldNames, children }) => (
  nodeData?.repeat
    ? (
      <Stack gap="1.5rem">
        <PreviousObservations fieldNames={fieldNames} />
        <ScheduledObservation>
          {children}
        </ScheduledObservation>
      </Stack>
    )
    : children
);

export default ObervationForm;
