/*

 SKINS
 Docs: http://tachyons.io/docs/themes/skins/

 Classes for setting foreground and background colors on elements.
 If you haven't declared a border color, but set border on an element, it will
 be set to the current text color.

 */
/* Text colors */
// export const black_90 = { color: 'rgba( 0, 0, 0, .9 )' };
// export const black_80 = { color: 'rgba( 0, 0, 0, .8 )' };
// export const black_70 = { color: 'rgba( 0, 0, 0, .7 )' };
// export const black_60 = { color: 'rgba( 0, 0, 0, .6 )' };
// export const black_50 = { color: 'rgba( 0, 0, 0, .5 )' };
// export const black_40 = { color: 'rgba( 0, 0, 0, .4 )' };
// export const black_30 = { color: 'rgba( 0, 0, 0, .3 )' };
// export const black_20 = { color: 'rgba( 0, 0, 0, .2 )' };
// export const black_10 = { color: 'rgba( 0, 0, 0, .1 )' };
// export const black_05 = { color: 'rgba( 0, 0, 0, .05 )' };
// export const white_90 = { color: 'rgba( 255, 255, 255, .9 )' };
// export const white_80 = { color: 'rgba( 255, 255, 255, .8 )' };
// export const white_70 = { color: 'rgba( 255, 255, 255, .7 )' };
// export const white_60 = { color: 'rgba( 255, 255, 255, .6 )' };
// export const white_50 = { color: 'rgba( 255, 255, 255, .5 )' };
// export const white_40 = { color: 'rgba( 255, 255, 255, .4 )' };
// export const white_30 = { color: 'rgba( 255, 255, 255, .3 )' };
// export const white_20 = { color: 'rgba( 255, 255, 255, .2 )' };
// export const white_10 = { color: 'rgba( 255, 255, 255, .1 )' };
// export const black = { color: '#000' };
// export const near_black = { color: '#111' };
// export const dark_gray = { color: '#333' };
// export const mid_gray = { color: '#555' };
// export const gray = { color: '#777' };
export const silver = { color: '#999' };
// export const light_silver = { color: '#aaa' };
// export const chinese_silver = { color: '#C8C9C8' };
export const moon_gray = { color: '#ccc' };
export const light_gray = { color: '#eee' };
// export const near_white = { color: '#f4f4f4' };
// export const white = { color: '#fff' };
// export const dark_red = { color: '#e7040f' };
// export const red = { color: '#ff4136' };
// export const light_red = { color: '#ff725c' };
// export const orange = { color: '#ff6300' };
// export const gold = { color: '#ffb700' };
// export const yellow = { color: '#ffd700' };
// export const light_yellow = { color: '#fbf1a9' };
// export const purple = { color: '#5e2ca5' };
// export const light_purple = { color: '#a463f2' };
// export const dark_pink = { color: '#d5008f' };
// export const hot_pink = { color: '#ff41b4' };
// export const pink = { color: '#ff80cc' };
// export const light_pink = { color: '#ffa3d7' };
// export const dark_green = { color: '#137752' };
// export const pearl_dark_grey = { color: '#79797E' };
// export const dark_charcoal = { color: '#2F2E30' };
// export const green = { color: '#19a974' };
// export const light_green = { color: '#9eebcf' };
// export const navy = { color: '#001b44' };
// export const dark_blue = { color: '#00449e' };
// export const blue = { color: '#357edd' };
// export const light_blue = { color: '#96ccff' };
// export const lightest_blue = { color: '#cdecff' };
// export const washed_blue = { color: '#f6fffe' };
// export const washed_green = { color: '#e8fdf5' };
// export const washed_yellow = { color: '#fffceb' };
// export const washed_red = { color: '#ffdfdf' };
// export const color_inherit = { color: 'inherit' };
// /* Background colors */
// export const bg_black_90 = { backgroundColor: 'rgba( 0, 0, 0, .9 )' };
// export const bg_black_80 = { backgroundColor: 'rgba( 0, 0, 0, .8 )' };
// export const bg_black_70 = { backgroundColor: 'rgba( 0, 0, 0, .7 )' };
// export const bg_black_60 = { backgroundColor: 'rgba( 0, 0, 0, .6 )' };
// export const bg_black_50 = { backgroundColor: 'rgba( 0, 0, 0, .5 )' };
// export const bg_black_40 = { backgroundColor: 'rgba( 0, 0, 0, .4 )' };
// export const bg_black_30 = { backgroundColor: 'rgba( 0, 0, 0, .3 )' };
// export const bg_black_20 = { backgroundColor: 'rgba( 0, 0, 0, .2 )' };
// export const bg_black_10 = { backgroundColor: 'rgba( 0, 0, 0, .1 )' };
// export const bg_black_05 = { backgroundColor: 'rgba( 0, 0, 0, .05 )' };
// export const bg_white_90 = { backgroundColor: 'rgba( 255, 255, 255, .9 )' };
// export const bg_white_80 = { backgroundColor: 'rgba( 255, 255, 255, .8 )' };
// export const bg_white_70 = { backgroundColor: 'rgba( 255, 255, 255, .7 )' };
// export const bg_white_60 = { backgroundColor: 'rgba( 255, 255, 255, .6 )' };
// export const bg_white_50 = { backgroundColor: 'rgba( 255, 255, 255, .5 )' };
// export const bg_white_40 = { backgroundColor: 'rgba( 255, 255, 255, .4 )' };
// export const bg_white_30 = { backgroundColor: 'rgba( 255, 255, 255, .3 )' };
// export const bg_white_20 = { backgroundColor: 'rgba( 255, 255, 255, .2 )' };
// export const bg_white_10 = { backgroundColor: 'rgba( 255, 255, 255, .1 )' };
// export const bg_black = { backgroundColor: '#000' };
// export const bg_near_black = { backgroundColor: '#111' };
// export const bg_dark_gray = { backgroundColor: '#333' };
// export const bg_mid_gray = { backgroundColor: '#555' };
// export const bg_gray = { backgroundColor: '#777' };
// export const bg_silver = { backgroundColor: '#999' };
// export const bg_light_silver = { backgroundColor: '#aaa' };
// export const bg_chinese_silver = { backgroundColor: '#C8C9C8' };
// export const bg_moon_gray = { backgroundColor: '#ccc' };
export const bg_light_gray = { backgroundColor: '#eee' };
export const bg_near_white = { backgroundColor: '#f4f4f4' };
// export const bg_white = { backgroundColor: '#fff' };
// export const bg_transparent = { backgroundColor: 'transparent' };
// export const bg_dark_red = { backgroundColor: '#e7040f' };
export const bg_red = { backgroundColor: '#ff4136' };
// export const bg_light_red = { backgroundColor: '#ff725c' };
// export const bg_orange = { backgroundColor: '#ff6300' };
// export const bg_gold = { backgroundColor: '#ffb700' };
// export const bg_yellow = { backgroundColor: '#ffd700' };
// export const bg_light_yellow = { backgroundColor: '#fbf1a9' };
// export const bg_purple = { backgroundColor: '#5e2ca5' };
// export const bg_light_purple = { backgroundColor: '#a463f2' };
// export const bg_dark_pink = { backgroundColor: '#d5008f' };
// export const bg_hot_pink = { backgroundColor: '#ff41b4' };
// export const bg_pink = { backgroundColor: '#ff80cc' };
// export const bg_light_pink = { backgroundColor: '#ffa3d7' };
// export const bg_dark_green = { backgroundColor: '#137752' };
export const bg_green = { backgroundColor: '#19a974' };
// export const bg_light_green = { backgroundColor: '#9eebcf' };
// export const bg_navy = { backgroundColor: '#001b44' };
// export const bg_dark_blue = { backgroundColor: '#00449e' };
// export const bg_blue = { backgroundColor: '#357edd' };
// export const bg_light_blue = { backgroundColor: '#96ccff' };
// export const bg_lightest_blue = { backgroundColor: '#cdecff' };
// export const bg_washed_blue = { backgroundColor: '#f6fffe' };
// export const bg_washed_green = { backgroundColor: '#e8fdf5' };
// export const bg_washed_yellow = { backgroundColor: '#fffceb' };
// export const bg_washed_red = { backgroundColor: '#ffdfdf' };
// export const bg_inherit = { backgroundColor: 'inherit' };
// export const bg_dark_charcoal = { backgroundColor: '#2F2E30' };
/* Theme Colours */
/* Text Colours */
export const txt_primary = { color: 'text.primary', textColor: 'text.primary' };
export const txt_secondary = {
	color: 'text.secondary',
	textColor: 'text.secondary',
};
// export const txt_inherit = { color: 'inherit', textColor: 'inherit' };
export const txt_color_1 = { color: 'text.color_1', textColor: 'text.color_1' };
export const txt_color_2 = { color: 'text.color_2', textColor: 'text.color_2' };
export const txt_color_3 = { color: 'text.color_3', textColor: 'text.color_3' };
export const txt_color_4 = { color: 'text.color_4', textColor: 'text.color_4' };
export const txt_error = { color: 'text.error', textColor: 'text.error' };

/* Background Colours */
export const bg_default = { backgroundColor: 'background.default' };
export const bg_paper = { backgroundColor: 'background.paper' };
export const bg_surface_1 = {
	backgroundColor: 'background.surface_1',
	background: 'background.surface_1',
};
export const bg_surface_2 = {
	backgroundColor: 'background.surface_2',
	background: 'background.surface_2',
};
export const bg_surface_3 = {
	backgroundColor: 'background.surface_3',
	background: 'background.surface_3',
};
export const bg_surface_4 = {
	backgroundColor: 'background.surface_4',
	background: 'background.surface_4',
};
export const bg_surface_5 = {
	backgroundColor: 'background.surface_5',
	background: 'background.surface_5',
};
export const bg_surface_6 = {
	backgroundColor: 'background.surface_6',
	background: 'background.surface_6',
};

export const bg_hover_2 = {
	backgroundColor: 'background.hover_2',
	background: 'background.hover_2',
};
export const bg_hover_3 = {
	backgroundColor: 'background.hover_3',
	background: 'background.hover_3',
};
export const bg_hover_5 = {
	backgroundColor: 'background.hover_5',
	background: 'background.hover_5',
};
export const bg_hover_6 = {
	backgroundColor: 'background.hover_6',
	background: 'background.hover_6',
};

export const teal = '#3A9B92';
export const darkBlue = '#142543';
export const burntOrange = '#E3A528';
export const primary = '#EA4717';
export const lightGrey = '#efede9';
export const stone = '#cec9bf';
export const black = '#1D1D1B';
export const white = '#B1B1B1';
