import { Box } from '@mui/material';
import withDialogCard from '@pw/components/Cards/DialogCard';
import LatestAddedPolicies from '@pw/components/LatestAdded/LatestAddedPolicies';
import PolicySummary from '@pw/components/summary/PolicySummary';
import { POLICY_EDIT } from '@pw/consts/policy';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import { useCompanyPolicies } from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import debounce from '@pw/utilities/debounce';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PolicyForm from './PolicyForm';

const PolicyFormHandlerImpl = withDialogCard(
  ({ children, readonly, onSave, ...rest }) => {
    const navigate = useNavigate();
    const [target, scroll] = useScrollTarget();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleSave = (mutatedPolicy) => {
      console.log('Mutated policy', mutatedPolicy);

      debounce(() => {
        onSave?.(mutatedPolicy);
        navigate(`${POLICY_EDIT}/${mutatedPolicy?.policy_id}`, { replace: true });
      }, 50);
    };

    return (
      <Box sx={styles} className="root" ref={target}>
        <PolicyForm onSave={handleSave} readonly={readonly} {...rest}>
          {children}
        </PolicyForm>
      </Box>
    );
  },
);

function NewForm({ changeSetGenerator, onInit, ...rest }) {
  const [searchParams] = useSearchParams();

  const { policyLabel } = rest;

  usePageTitleHook(`Create ${policyLabel}`);

  const clone = searchParams?.get('clone');

  const formValues = useMemo(() => {
    let obj = {};
    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        obj = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }

    // Always call onInit, but with different arguments
    if (onInit) {
      onInit(clone ? obj : {});
    }

    return { ...obj };
  }, [clone, onInit]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  return (
    <>
      <PolicyFormHandlerImpl changeSet={changeSet} {...rest} />
    </>
  );
}

function ShowPolicy({ changeSetGenerator, onInit, policyLabel, ...rest }) {
  const { id } = rest;

  const policyList = useCompanyPolicies();

  const entity = policyList?.find((s) => s.policy_id === id);

  const readonly = useCheckCompany(entity);

  usePageTitleHook(`${policyLabel} ${entity?.policy_name ?? '...'}`);

  // Generate the change set
  const formValues = useMemo(() => {
    // Specific initializer
    if (onInit) {
      onInit(entity);
    }
    return { ...(entity ?? {}) };
  }, [entity]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  const refreshData = () => {
  };

  return (
    <Box className="adaptive-content">
      {!entity && (
        <NewForm
          changeSetGenerator={changeSetGenerator}
          onInit={onInit}
          policyLabel={policyLabel}
          {...rest}
        />
      )}
      {entity && changeSet && (
        <>
          <PolicySummary entity={entity} refresh={refreshData} />
          <PolicyFormHandlerImpl
            entity={entity}
            changeSet={changeSet}
            edit
            readonly={readonly}
            onSave={refreshData}
            {...rest}
          />
        </>
      )}
      <LatestAddedPolicies />
    </Box>
  );
}

function PolicyFormHandler(props) {
  let { id } = useParams();

  return (
    <>
      {!id && (
        <Box className="adaptive-content">
          <NewForm {...props} />
          <LatestAddedPolicies />
        </Box>
      )}
      {id && <ShowPolicy id={id} {...props} />}
    </>
  );
}

export default PolicyFormHandler;
