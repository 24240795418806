import { getOverview } from '@pw/services/stats.service';
import useLoading from '@pw/utilities/hooks/useLoadingHook';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';

const serviceFunction = (params) => getOverview(params);
export const [useOverviewLazyQuery, useOverviewQuery] = genServiceCallHooks(
	serviceFunction,
	{ useLoadingHook: useLoading },
);

export default useOverviewQuery;
