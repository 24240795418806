import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import { Body2 } from '@pw/components/Typography';
import { Status } from '@pw/consts/admin';
import { useCompanyName, useCompanyPartners } from '@pw/redux/containers/User';
import { connectWithPartnerThunk } from '@pw/redux/thunks/partners';
import useLilly from '@pw/utilities/hooks/ai/useLilly';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

export function ConfirmPartnersInvite({ open, company, onClose }) {
  const dispatch = useDispatch();

  const partners = useCompanyPartners();
  const companyName = useCompanyName();

  const { chat } = useLilly();

  console.log('Company', company);

  const [target, setTarget] = useState(0);
  const [selectedPartners, setSelectedPartners] = useState(null);

  // Check if we have a partner to match this company
  const partnerOptions = useMemo(() => {
    return (partners ?? [])
    .filter(({ status }) => status === Status.CREATED)
    .map(({ company_name }) => company_name);
  }, [partners]);

  console.log('Active partners', partnerOptions.length);

  useEffect(() => {
    if (company?.t) {
      chat(
        { matches: 'boolean, with 95% confidence' },
        `Does the company '${company?.t}' sound like '${companyName}', output json object per the spec with the boolean!`,
      )
      .then((out) => {
        console.log('Tested target', out);
        const { matches = false } = out ?? {};
        if (!matches) {
          // Need to force changing the company
          setTarget(1);
        } else {
          setTarget(2); // We are the target
        }
      });
    } else {
      // Triggered from scan
      setTarget(2);
    }
  }, [company])

  // Check if there is a matching partner in our ist
  // else we will create a new partner
  useEffect(() => {
    if (target === 2) {
      // First check if the intended target is the current company
      if (partnerOptions.length === 0) {
        return setSelectedPartners([]);
      }
      // Use chat to figure out closest match
      chat(
        { matches: 'array of matches, with 95% confidence'},
        `Find an entry that cloely matches '${company?.n}' from the list ${JSON.stringify(partnerOptions)}, output a json array with only the values, if no suitable match, return empty array!`,
      )
      .then((out) => {
        const { matches = [] } = out ?? {};
        if (matches.length > 0) {
          const matchingPartners = (partners ?? []).filter((c) => matches.includes(c.company_name));
          setSelectedPartners(matchingPartners);
        } else {
          setSelectedPartners([]);
        }
      });
    }
  }, [partnerOptions, target, company]);

  console.log('Selected partners', selectedPartners);

  const changeSet = {
    existing_contact: ['', yup.string()],
  };

  const handleConfirm = useCallback((values) => {
        const partnerOptions = selectedPartners ?? [];
        const contact = partnerOptions.length === 1 ? partnerOptions[0] : (values.existing_contact ? selectedPartners.find((c) => c.company_name === values.existing_contact) : undefined);

        const params = {
          partner: company,    // this is their details
          contact,             // this is our details for them (if we have it, else null)
          status: Status.APPROVED,
        };
        console.log('Connecting...', params);

        dispatch(connectWithPartnerThunk(params))
          .unwrap()
          .then(onClose);
      },
    [onClose, dispatch, selectedPartners],
  );

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title='Confirm Connection'>
      <FormikForm changeSet={changeSet} onSubmit={handleConfirm}>
        {target === 0 && (
          <LoadingSmall message="Checking intended company..." />
        )}
        {target === 1 && (
          <Body2>Looks like this invite is for <strong>{company.t}</strong>, please switch to that and click on the invite link again!</Body2>
        )}
        {target === 2 && (
          <>
            {selectedPartners && selectedPartners.length > 1 && (
              <Stack spacing={2}>
                <Instructions>Multiple partners match, please select one that is <strong>{company?.n}</strong></Instructions>
                <FormikSelect
                  name='existing_contact'
                  label='Matching Partner'
                  options={selectedPartners.map((c) => ({ value: c.company_id, label: c.company_name }))}
                />
              </Stack>
            )}
            {selectedPartners && selectedPartners.length === 1 && (
              <Body2>Linking <strong>{company.n}</strong> to <strong>{selectedPartners[0].company_name}</strong>?</Body2>
            )}
            {selectedPartners && selectedPartners.length === 0 && (
              <Body2>Connect to <strong>{company.n}</strong>?</Body2>
            )}
            {!selectedPartners && (
              <LoadingSmall message="Checking partners..." />
            )}
          </>
        )}
        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onClose()}
            color='secondary'
          >
            Cancel
          </TextButton>
          <FilledButton type='submit' size='small' disabled={target !== 2}>
            Connect
          </FilledButton>
        </Box>
      </FormikForm>
    </ModalWithClose>
  );
}
