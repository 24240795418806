const parseIdPattern = (pattern) => {
	if (!pattern || pattern.length === 0) {
		return ['', 0];
	}
	let zerosCount = 0;
	for (let i = pattern.length - 1; i >= 0; i--) {
		if (pattern[i] === '0') {
			zerosCount++;
		} else {
			break;
		}
	}
	const nonZeroPrefix = pattern.slice(0, pattern.length - zerosCount);
	return [nonZeroPrefix, zerosCount];
};

export default parseIdPattern;
