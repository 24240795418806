{
  "sku_id": "some_sku",
  "recipe_name": "Recipe 2",
  "nodes_data": {
    "water-1729771290144": {
      "quantity": {
        "value": "100",
        "unit": "L"
      },
      "temperature": {
        "value": "60",
        "unit": "°C"
      }
    },
    "yeast-1729771534915": {
      "quantity": {
        "value": "2",
        "unit": "kg"
      },
      "sku": ""
    },
    "reactflow__edge-water-1729771290144-handle-bottom-right-source-washback-1729771285846-handle-top-left-source": {
      "offset": {
        "value": "2",
        "unit": "hr"
      },
      "quantity": {
        "value": "",
        "unit": ""
      }
    },
    "reactflow__edge-yeast-1729771534915-handle-top-right-source-washback-1729771285846-handle-bottom-left-source": {
      "offset": {
        "value": "4",
        "unit": "hr"
      },
      "quantity": {
        "value": "",
        "unit": ""
      }
    },
    "water-1729771604565": {
      "quantity": {
        "value": "50",
        "unit": "L"
      },
      "temperature": {
        "value": "75",
        "unit": "°C"
      }
    },
    "reactflow__edge-water-1729771604565-handle-top-right-source-washback-1729771285846-handle-bottom-left-source": {
      "offset": {
        "value": "8",
        "unit": "hr"
      },
      "quantity": {
        "value": "",
        "unit": ""
      }
    },
    "still-spirit-1729771308781": {
      "flow_rate_target": {
        "value": "25",
        "unit": "L"
      },
      "flow_rate": {
        "value": "",
        "unit": ""
      },
      "steam_pressure": {
        "value": "",
        "unit": ""
      },
      "duration": {
        "value": "8",
        "unit": "hr"
      }
    },
    "washback-1729771285846": {
      "duration": {
        "value": "28",
        "unit": "hr"
      }
    }
  },
  "nodes": [
    {
      "id": "washback-1729771285846",
      "data": {
        "category": "process",
        "icon": "Washback",
        "form": "WashbackForm",
        "type": "washback",
        "label": "Washback",
        "inputType": [
          "wort",
          "yeast"
        ],
        "outputType": [
          "_washback_out"
        ],
        "position": {
          "x": 500,
          "y": 250
        }
      },
      "position": {
        "x": 550,
        "y": 275
      },
      "type": "processNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 550,
        "y": 275
      },
      "dragging": false
    },
    {
      "id": "water-1729771290144",
      "data": {
        "category": "material",
        "icon": "Water",
        "form": "WaterForm",
        "type": "water",
        "label": "Water",
        "measuredBy": "volume",
        "outputType": [
          "water"
        ],
        "position": {
          "x": 350,
          "y": 150
        }
      },
      "position": {
        "x": 125,
        "y": 125
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 125,
        "y": 125
      },
      "dragging": false
    },
    {
      "id": "still-spirit-1729771308781",
      "data": {
        "type": "still",
        "category": "process",
        "icon": "SpiritStill",
        "form": "SpiritStillForm",
        "label": "Spirit Still",
        "variant": "spirit",
        "inputType": [
          "spirit"
        ],
        "outputType": [
          "distillant"
        ],
        "position": {
          "x": 900,
          "y": 300
        }
      },
      "position": {
        "x": 825,
        "y": 375
      },
      "type": "processNode",
      "width": 190,
      "height": 190,
      "selected": true,
      "positionAbsolute": {
        "x": 825,
        "y": 375
      },
      "dragging": false
    },
    {
      "id": "mash_tun-1729771376818",
      "data": {
        "category": "process",
        "icon": "Mash",
        "form": "MashForm",
        "type": "mash_tun",
        "label": "Mash Tun",
        "inputType": [
          "grain_milled",
          "water"
        ],
        "outputType": [
          "wort"
        ],
        "position": {
          "x": 300,
          "y": -100
        }
      },
      "position": {
        "x": 350,
        "y": 25
      },
      "type": "processNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 350,
        "y": 25
      },
      "dragging": false
    },
    {
      "id": "yeast-1729771534915",
      "data": {
        "category": "material",
        "icon": "Yeast",
        "form": "YeastForm",
        "type": "yeast",
        "label": "Yeast",
        "measuredBy": "weight",
        "outputType": [
          "yeast"
        ],
        "variants": [],
        "position": {
          "x": 250,
          "y": 400
        }
      },
      "position": {
        "x": 150,
        "y": 325
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 150,
        "y": 325
      },
      "dragging": false
    },
    {
      "id": "water-1729771604565",
      "data": {
        "category": "material",
        "icon": "Water",
        "form": "WaterForm",
        "type": "water",
        "label": "Water",
        "measuredBy": "volume",
        "outputType": [
          "water"
        ],
        "position": {
          "x": 250,
          "y": 450
        }
      },
      "position": {
        "x": 250,
        "y": 500
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 250,
        "y": 500
      },
      "dragging": false
    }
  ],
  "edges": [
    {
      "source": "water-1729771290144",
      "source-handle": "handle-bottom-right-source",
      "target": "washback-1729771285846",
      "target-handle": "handle-top-left-source",
      "id": "reactflow__edge-water-1729771290144-handle-bottom-right-source-washback-1729771285846-handle-top-left-source",
      "selected": false
    },
    {
      "source": "washback-1729771285846",
      "source-handle": "handle-right-source",
      "target": "still-spirit-1729771308781",
      "target-handle": "handle-left-source",
      "id": "reactflow__edge-washback-1729771285846-handle-right-source-still-spirit-1729771308781-handle-left-source"
    },
    {
      "source": "mash_tun-1729771376818",
      "source-handle": "handle-bottom-source",
      "target": "washback-1729771285846",
      "target-handle": "handle-top-source",
      "id": "reactflow__edge-mash_tun-1729771376818-handle-bottom-source-washback-1729771285846-handle-top-source"
    },
    {
      "source": "yeast-1729771534915",
      "source-handle": "handle-top-right-source",
      "target": "washback-1729771285846",
      "target-handle": "handle-bottom-left-source",
      "id": "reactflow__edge-yeast-1729771534915-handle-top-right-source-washback-1729771285846-handle-bottom-left-source",
      "selected": false
    },
    {
      "source": "water-1729771604565",
      "source-handle": "handle-top-right-source",
      "target": "washback-1729771285846",
      "target-handle": "handle-bottom-left-source",
      "id": "reactflow__edge-water-1729771604565-handle-top-right-source-washback-1729771285846-handle-bottom-left-source",
      "selected": false
    }
  ]
}