import { Edit, TaskOutlined } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';

function Policy({ item, onRemove, onEdit, selected }) {
	const { policy_id, policy_name } = item ?? {};

	return (
		<Box className='listItem'>
			{selected && selected(item) && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack spacing={0.5}>
					<IDDisplay value={policy_id} />
					<NameDisplay name={policy_name} />
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
				{onRemove && (
					<IconCircleButton onClick={() => onRemove(item)}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

function PolicyList({ items, onEdit, onRemove }) {
	return (
		<Box className='inventory-contents'>
			<Stack className='list'>
				{items.map((policy) => (
					<Policy
						item={policy}
						onEdit={onEdit}
						onRemove={onRemove}
						key={policy?.policy_name}
					/>
				))}
			</Stack>
		</Box>
	);
}

export default PolicyList;
