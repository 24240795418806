import { useContext } from 'react';
import * as yup from 'yup';
import { useNodeDataContext } from '../NodeDataContext';
import DesignerContext from '@pw/context/DesignerContext';
import { FormikNumberField } from '@pw/components/Forms/FormikForm';
import NodeModalForm from './NodeModalForm';
import ObervationForm from '../ScheduledObservation';
import NodeConfigForm from './NodeConfigForm';

const BrixForm = () => {
  const { mode } = useContext(DesignerContext);
  const { nodeData = {} } = useNodeDataContext();
  const { brix } = nodeData;
  const changeSet = {
    brix: [brix || '', yup.string()],
    observation_name: ['BrixForm', yup.string()],
  };
  const fieldNames = Object.keys(changeSet);

  return mode === 'design'
    ? <NodeConfigForm changeSet={changeSet} />
    : (
      <NodeModalForm changeSet={changeSet}>
        <ObervationForm
          nodeData={nodeData}
          fieldNames={fieldNames}
        >
          <FormikNumberField
            label='Brix'
            name='brix'
          />
        </ObervationForm>
      </NodeModalForm>
    )
}

export default BrixForm;
