import { Grid, Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import CostDisplay from '@pw/components/properties/CostDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import VendorDisplay from '@pw/components/properties/VendorDisplay';
import { SKU_TYPES } from '@pw/consts/sku';
import { useMemo } from 'react';
import SKUStorageItem from './SKUStorageItem';

function SKUEntryItemInfo({ item, unit, sku_type, onSelectStorage }) {
	const {
		expiry,
		vendor,
		duty_paid,
		total_cost,
		amount,
		available_quantity,
		storage = [],
		tracked = [],
	} = item;

	const uu = useMemo(
		() =>
			[
				SKU_TYPES.TRACKED,
				SKU_TYPES.FINISHED,
				SKU_TYPES.WIP,
				SKU_TYPES.SERVICE,
				SKU_TYPES.CONSUMABLE,
			].includes(sku_type)
				? undefined
				: unit,
		[sku_type, unit],
	);

	console.log('SKUEntryItemInfo', unit, item);

	return (
		<Stack spacing={0.5}>
			<Grid container spacing={0.5}>
				{vendor && (
					<Grid item xs={12}>
						<VendorDisplay value={vendor} />
					</Grid>
				)}
				{expiry && (
					<Grid item xs={6}>
						<DateDisplay date={expiry} />
					</Grid>
				)}
				{total_cost && (
					<Grid item xs={6}>
						<CostDisplay label="Total Cost" value={total_cost} />
					</Grid>
				)}
				<Grid item xs={6}>
					<AmountDisplay label='Amount' unit={uu} amount={amount} />
				</Grid>
				<Grid item xs={6}>
					<AmountDisplay
						label='Available'
						unit={uu}
						amount={available_quantity}
					/>
				</Grid>
				{duty_paid && (
					<Grid item xs={12}>
						<DutyPaidDisplay value={duty_paid} />
					</Grid>
				)}
			</Grid>
			{storage && storage.length > 0 && (
				<FlexBox
					className='asset-container'
					justifyContent='start'
					gap='0.75rem'
				>
					{storage.map((_item) => (
						<SKUStorageItem key={_item.asset_id} item={_item} unit={uu} onSelectStorage={onSelectStorage} />
					))}
				</FlexBox>
			)}
			{tracked && tracked.length > 0 && (
				<AmountDisplay amount={tracked.length} label='Tracked Assets' />
			)}
		</Stack>
	);
}

export default SKUEntryItemInfo;
