
const styles = {
  '&.edge-form-root': {
    position: 'absolute',
    zIndex: 9,
    width: 'fit-content',
    pointerEvents: 'all',
    transform: `translate(-50%, -50%)`,
    marginTop: '0.25rem',
    marginLeft: '0.5rem',
  },


  '.edge-button': {
    marginTop: '0.5rem',
    border: '1px solid ',
    background: '#fff',
    borderRadius: '99rem',
    minHeight: 0,
    minWidth: 0,
    width: '1rem',
    height: '1rem',
    padding: '0.5rem',
    minWidth: 'unset',
    boxSizing: 'content-box',
    transition: 'all 0.3s',
    opacity: 0.5,

    '&.with-label': {
      opacity: 1,
      width: 'auto',
    },

    svg: {
      display: 'block',
      lineHeight: 1,
      fontSize: '1.5rem',
      width: '1em',
      height: '1em'
    },
  },
  '&.edge-form-root:hover .edge-button': {
    opacity: 1,
  },
  '.run-mode.edge-button:not(.with-label)': {
    display: 'none'
  },

  '.token': {
    display: 'inline-flex',
    marginRight: '0.5rem',
    alignItems: 'center',
  },
  '.modal-content': {
    width: '100%',
    maxWidth: '16rem',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1.5rem',
  },
  '.header': {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '2rem',
    columnGap: '1rem',
  },
  '.action-buttons': {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    justifyItems: 'space-between'
  },
};

export default styles;
