import { listGateWay, upsertGateWay, upsertSensor } from '@pw/services/sensor.service';

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const refetchSensors = createAsyncThunk(
  'sensors/refetchSensors',
  async (params, { rejectWithValue }) => {
    try {
      return await listGateWay(params);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const upsertGatewayThunk = createAsyncThunk(
  'sensors/upsertGateway',
  async (gateway, { rejectWithValue }) => {
    try {
      return await upsertGateWay(gateway);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const upsertSensorThunk = createAsyncThunk(
  'sensors/upsertSensor',
  async (sensor, { rejectWithValue }) => {
    try {
      return await upsertSensor(sensor);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

