import { ExpandMore as ExpandMoreIcon, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import {
	FormikCheckBox,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { H4, H5 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';

const styles = {
	formikTextField: {
		marginTop: '10px !important',
	},
};

export default function Part2({
	name,
	title = 'Special Categories',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	const [expandedSpirit, setExpandedSpirit] = useState(false);
	const [expandedAlcohol, setExpandedAlcohol] = useState(false);

	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>

			{expanded && (
				<>
					<Stack direction='row' alignItems='center'>
						<H4>Spirit Transactions</H4>
						<IconCircleButton
							onClick={() => setExpandedSpirit((prev) => !prev)}
						>
							{!expandedSpirit ? (
								<KeyboardArrowRightOutlined />
							) : (
								<ExpandMoreIcon />
							)}
						</IconCircleButton>
					</Stack>
					{expandedSpirit && (
						<>
							<H4>Produced</H4>
							<FormikTextField
								name='P2_Spirits_Produced'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Received</H4>
							<FormikTextField
								name='P2_Spirits_Received'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Received from Customs Custody (Medium Plants Only)</H4>
							<FormikTextField
								name='P2_Spirits_Received_Customs'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Used on Premises for Fuel (Do Not Include Fuel Alcohol)</H4>
							<FormikTextField
								name='P2_Spirits_Used_On_Fuel'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Used in Making Fuel Alcohol</H4>
							<FormikTextField
								name='P2_Spirits_Making_Fuel'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Destroyed</H4>
							<FormikTextField
								name='P2_Spirits_Destroyed'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Transferred</H4>
							<FormikTextField
								name='P2_Spirits_Transferred'
								label='Proof Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>
						</>
					)}
					<Stack direction='row' alignItems='center'>
						<H4>Fuel Alcohol Transactions</H4>
						<IconCircleButton
							onClick={() => setExpandedAlcohol((prev) => !prev)}
						>
							{!expandedAlcohol ? (
								<KeyboardArrowRightOutlined />
							) : (
								<ExpandMoreIcon />
							)}
						</IconCircleButton>
					</Stack>
					{expandedAlcohol && (
						<>
							<H4>Produced (Rendered Unfit)</H4>
							<FormikTextField
								name='P2_Fuel_Alcohol_Produced'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Used on Premises for Fuel</H4>
							<FormikTextField
								name='P2_Fuel_Alcohol_Used_For_Fuel'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>

							<H4>Produced (Rendered Unfit)</H4>
							<FormikTextField
								name='P2_Fuel_Alcohol_Transferred'
								label='Wine Gallons'
								fullWidth
								disabled={disabled}
								sx={{ ...styles.formikTextField }}
							/>
						</>
					)}
					<Stack direction='row' alignItems='center'>
						<FormikCheckBox
							name='perjury1'
							label={
								<H5 style={{ maxWidth: '500px', whiteSpace: 'normal' }}>
									Under the penalties of perjury, I declare that I have examined
									this report and, to the best of my knowledge and belief, it is
									a true and complete report of operations.{' '}
								</H5>
							}
							disabled={true}
						/>
					</Stack>
				</>
			)}
		</FormikProvider>
	);
}
