import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Pagination from '@pw/components/Pagination';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import { Body3, H5 } from '@pw/components/Typography';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import {
	ASSET_EDIT_URL,
	ASSET_NAMES,
	ASSET_TYPES_REVERSE,
} from '@pw/consts/asset';
import styles from '@pw/styles/content.styles';
import { usePendingAssetQueryPaginated } from '@pw/utilities/hooks/service/usePendingAssetQuery';
import { useRecentAssetQueryPaginated } from '@pw/utilities/hooks/service/useRecentAssetQuery';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AssetList({ assetType }) {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		return {
			type: assetType,
			limit: Number(pageSize),
		};
	}, [assetType, pageSize]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		useRecentAssetQueryPaginated(reqParams);

	const goToItem = (params) => {
		const id = params?.path ?? params?.asset_id ?? 'new';
		const route = ASSET_EDIT_URL?.[assetType];
		navigate(`${route}/${id}`, { replace: true });
	};

	return (
		<Stack spacing={1}>
			<H5>Latest Added</H5>
			{!isLoading && page && page.length > 0 && (
				<Stack spacing={0.5}>
					<Pagination
						hasNextPage={hasNext}
						hasPrevPage={hasPrev}
						onPressNextPage={loadNext}
						onPressPrevPage={loadPrev}
						onChangePageSize={setPageSize}
						defaultPageSize={pageSize}
					/>
					<Stack className='list'>
						{page.map((i) => (
							<AssetListComponentV2
								key={i.path}
								item={i}
								buttonIcon={<ArrowForward />}
								onClick={() => goToItem(i)}
							/>
						))}
					</Stack>
				</Stack>
			)}
			{!isLoading && (!page || page.length === 0) && (
				<Body3>Nothing yet.</Body3>
			)}
			{isLoading && (
				<Stack spacing={0.5}>
					<FlexBox justifyContent='flex-end' gap='2rem'>
						<Skeleton variant='rectangular' width={45} height={45} />
						<Skeleton variant='rectangular' width={60} height={35} />
					</FlexBox>
					<Stack className='list'>
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}


function PenndingAssetList({ assetType }) {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState('5');

	const reqParams = useMemo(() => {
		return {
			asset_type: assetType,
			limit: Number(pageSize),
		};
	}, [assetType, pageSize]);

	const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
		usePendingAssetQueryPaginated(reqParams);

	const goToItem = (params) => {
		const id = params?.path ?? params?.asset_id ?? 'new';
		const route = ASSET_EDIT_URL?.[assetType];
		navigate(`${route}/${id}`, { replace: true });
	};

	return (
		<Stack spacing={1}>
			<H5>Pending Assets</H5>
			{!isLoading && page && page.length > 0 && (
				<Stack spacing={0.5}>
					<Pagination
						hasNextPage={hasNext}
						hasPrevPage={hasPrev}
						onPressNextPage={loadNext}
						onPressPrevPage={loadPrev}
						onChangePageSize={setPageSize}
						defaultPageSize={pageSize}
					/>
					<Stack className='list'>
						{page.map((i) => (
							<AssetListComponentV2
								key={i.path}
								item={i}
								buttonIcon={<ArrowForward />}
								onClick={() => goToItem(i)}
							/>
						))}
					</Stack>
				</Stack>
			)}
			{!isLoading && (!page || page.length === 0) && (
				<Body3>Nothing yet.</Body3>
			)}
			{isLoading && (
				<Stack spacing={0.5}>
					<FlexBox justifyContent='flex-end' gap='2rem'>
						<Skeleton variant='rectangular' width={45} height={45} />
						<Skeleton variant='rectangular' width={60} height={35} />
					</FlexBox>
					<Stack className='list'>
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
						<ListItemSkeleton type={ASSET_TYPES_REVERSE[assetType]} />
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}


function LatestAddedAssets({ assetType }) {
	const navigate = useNavigate();

	const goToItem = (params) => {
		const route = ASSET_EDIT_URL[assetType];
		const id = params?.path ?? params?.asset_id ?? 'new';
		console.log('Navigating to', params, route, id);
		navigate(`${route}/${id}`);
	};

	return (
		<Stack sx={styles} className='root' spacing={3}>
			<Box className='section'>
				<GeneralSearch
					title='Search'
					types={{ asset_types: [assetType] }}
					label={ASSET_NAMES[assetType]}
					buttonIcon={<ArrowForward />}
					onItemClick={goToItem}
				/>
			</Box>

			<AssetList assetType={assetType} />
			<PenndingAssetList assetType={assetType} />
		</Stack>
	);
}

export default withSmallDialogCard(LatestAddedAssets);

AssetList.propTypes = {
	assetType: PropTypes.string.isRequired,
};
LatestAddedAssets.propTypes = {
	assetType: PropTypes.string.isRequired,
};
