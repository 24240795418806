function SvgDrag(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60.15 60.15'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M12.89 26.83h13.92V12.9H24.5c-1.14 0-2.28-.02-3.42 0-.75.01-1.31-.24-1.61-.95-.3-.72-.05-1.3.47-1.81l7.26-7.26c.95-.95 1.89-1.9 2.87-2.88.15.14.27.23.37.34l9.68 9.68c.14.14.29.28.41.45.36.49.41 1.03.14 1.58-.27.55-.72.85-1.34.85h-5.98v13.91h13.93v-2.18c0-1.19.02-2.37 0-3.56-.01-.75.24-1.31.95-1.61.72-.3 1.3-.06 1.82.46l6.5 6.5 3.63 3.63c-.13.14-.22.26-.33.37-3.24 3.24-6.48 6.49-9.73 9.72-.27.27-.63.53-1 .63-.94.25-1.82-.5-1.83-1.52-.01-1.72 0-3.44 0-5.17v-.76H33.38v13.93h1.83c1.32 0 2.64.01 3.96 0 .73 0 1.26.27 1.55.94.3.71.06 1.3-.46 1.82l-6.45 6.45c-1.22 1.22-2.43 2.43-3.67 3.68-.11-.1-.2-.16-.27-.24-3.29-3.29-6.59-6.57-9.86-9.88-.27-.27-.52-.65-.6-1.01-.2-.94.52-1.75 1.53-1.76 1.72-.01 3.44 0 5.17 0h.76V33.34H12.94v1.97c0 1.27-.01 2.55 0 3.82 0 .73-.27 1.26-.94 1.55-.71.3-1.3.06-1.82-.46-2.37-2.38-4.74-4.75-7.11-7.12-.99-.99-1.99-1.99-3.02-3.01.11-.13.19-.23.28-.32 3.26-3.26 6.51-6.52 9.78-9.76.29-.28.68-.55 1.06-.64.94-.2 1.74.53 1.75 1.53.01 1.74 0 3.49 0 5.23v.68z' />
		</svg>
	);
}
export default SvgDrag;
