import * as yup from 'yup';

export default function productionOfBrandyReportFields(fields = {}) {
	const { a, b } = fields;

	return {
		a: [a, yup.number().min(0, 'Kind value cannot be less than 0')],
		b: [b, yup.number().min(0, 'Proof Gallons value cannot be less than 0')],
	};
}
