import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import LiquidIcon from '@pw/components/icons/Liquid';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import StrengthDisplay from '@pw/components/properties/StrengthDisplay';

function LiquidContent({ liquid = {} }) {
	const { level = {}, weight = {} } = liquid;
	const { enable = false, bl, la, abv, tcf } = level;
	const { enable: enableWeight = false, weightFactor } = weight;


	// console.log('Liquid Display', liquid);
	if (!enable && !enableWeight) return;

	return (
		<Stack spacing={0.5}>
			<LiquidDisplay empty={!enable} value={bl} la={la} />
			<StrengthDisplay value={abv} tcf={tcf} />

			{enableWeight && (
				<Box
					sx={{
						flexDirection: 'row',
						display: 'flex',
						gap: '0.25rem',
					}}
				>
					<LiquidIcon style={{ width: '12px', height: 'auto' }} />
					<Body3>
						<Bolder>Weight Factor:</Bolder>
						&nbsp;
						{weightFactor}
					</Body3>
				</Box>
			)}
		</Stack>
	);
}

export default LiquidContent;
