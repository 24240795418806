import { Edit } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';

function PolicyEntry({ item, onRemove, onEdit }) {
	const { name, description, type } = item ?? {};

	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<Stack spacing={0.5}>
					<NameDisplay name={name} />
					<DescriptionDisplay value={description} />
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
				{onRemove && (
					<IconCircleButton onClick={() => onRemove(item)}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

function PolicyEntryList({ items, onEdit, onRemove }) {
	return (
		<Box className='inventory-contents'>
			<Stack className='list'>
				{items.map((entry) => (
					<PolicyEntry
						item={entry}
						onEdit={onEdit}
						onRemove={onRemove}
						key={entry?.name}
					/>
				))}
			</Stack>
		</Box>
	);
}

export default PolicyEntryList;
