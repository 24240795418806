import * as yup from 'yup';

function parentAssetIdFields(parent_asset_id = '', parent_asset = {}) {
	if (parent_asset_id && !parent_asset?.asset_id) {
		parent_asset = {
			...parent_asset,
			asset_id: parent_asset_id,
		};
	}
	return {
		parent_asset_id: [parent_asset_id, yup.string()],
		parent_asset: [
			parent_asset,
			yup
				.object({
					rw_asset_id: yup.string(),
					asset_type: yup.string(),
					unique_location_id: yup.string(),
				})
				.optional(),
		],
	};
}
export default parentAssetIdFields;
