import { useMemo } from 'react';
import QRImage from '@pw/components/QRImage/index';
import { Body1, Body2 } from '@pw/components/Typography';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { POLICY_EDIT } from '@pw/consts/policy';

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		marginTop: '2rem',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
	},
	content: {
		// alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
		justifyContent: 'space-between',
	},
	qrDetails: {
		width: '100%',
		display: 'flex',
		'justify-content': 'center',
	},
	qrDetails2: {
		width: '100%',
	},
	qrIcon: {
		display: 'flex',
		'flex-direction': 'column',
		'align-items': 'center',
		'justify-content': 'space-evenly',
		border: '2px solid',
		padding: '5px',
		height: '72px',
		width: '72px',
		'border-radius': '50%',
	},
	type: {
		'text-transform': 'uppercase',
		'font-size': '0.8rem',
		'margin-top': '.25rem',
	},
	title: {
		borderTop: '1px solid',
		paddingTop: '0.25rem',
		fontSize: '1rem',
		fontWeight: '600',
	},
	m5: {
		margin: '5px',
	},
};

function QrDetails({ item = {}, companyName }) {
	return (
		<div style={styles.qrDetails2}>
			<Body1 sx={styles.m5}>{companyName}</Body1>
			<Body1 sx={styles.m5}>{item?.product}</Body1>
			<Body1 sx={styles.m5}>{item?.policy_name}</Body1>
		</div>
	);
}

function PolicyQRCode({ policy, companyName, fullScreen = true, withDetails = true }) {
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
	const qrValue = useMemo(
		() => `${origin}${POLICY_EDIT}/${policy?.policy_id}`,
		[policy, origin],
	);

	return (
		<div style={fullScreen ? styles.rootFullScreen : styles.root}>
			<div style={styles.content}>
				<QRImage value={qrValue} />
				{withDetails && (
					<QrDetails
						item={policy}
						companyName={companyName}
					/>
				)}
			</div>
			{!withDetails && (
				<div style={styles.footer}>
					{policy?.policy_name && (
						<Body2 sx={styles.title}>{policy?.policy_name}</Body2>
					)}
				</div>
			)}
		</div>
	);
}

export default PolicyQRCode;
