import {
  useCallback, useEffect, useRef, useState, memo
} from 'react';

import { Box, CircularProgress } from '@mui/material';
// import { getLsItem, removeLsItem, saveToLS } from '../../../functions/localStorage';

import ManagedCountdownTimer from '../ManagedTimer';

import styles from '../Timer.styles';
import createTimeOffset from '../createTimeOffset';

const lsKey = 'PW_designer_timers';

function CountdownTimer({ id, offset, repeat, children }) {
  // const _end = useMemo(() => createTimeOffset(offset)
  // , [offset, repeat]);
  const [running, setRunning] = useState(false);
  const [end, setEnd] = useState(/* _end */);
  const [started, setStarted] = useState(/* new Date().getTime() */);
  const [expires, setExpires] = useState(/* end.getTime() */);
  const [remaining, setRemaining] = useState();
  const intervalId = useRef();
  const [iteration, setIteration] = useState(0);

  // work to do each tick
  const updateRemaining = useCallback(() => {
    const now = Date.now();
    // const rem = Math.floor((expires - now) / 1000);
    const rem = expires - now;
    // console.log({ expires, now, rem })
    setRemaining(rem);
  }, [expires, setRemaining]);

  // handler once time has elapsed
  const handleTimeout = useCallback(() => {
    console.log('handleTimeout. repeat:', repeat);
    console.log('clearing interval');
    clearInterval(intervalId.current);
    setRunning(false);
    // removeLsItem(lsKey, id);
    if (repeat) setNewTimer();
  }, [intervalId]);

  const setNewTimer = useCallback(() => {
    console.log('setNewTimer');
    setRunning(true);
    const now = Date.now();
    const _end = createTimeOffset(offset);
    console.log({ _end })
    const ts = _end.getTime();
    setIteration((prev) => prev + 1)
    setEnd(_end);
    const lsItem = { id, iteration, ts, started: now };
    // saveToLS(lsKey, lsItem);
    setStarted(now);
    setExpires(ts);
    intervalId.current = null;
    console.log('setNewTimer', { lsItem })
  }, [id, offset, iteration])

  useEffect(() => {
    if (!id) return;
    console.log('[id]useEffect');
    // if valid timer for this product, use it
    // const _timer = getLsItem(lsKey, id);
    // if (_timer && _timer.id === id) {
    //   if (_timer.ts > Date.now()) {
    //     setExpires(_timer.ts);
    //     setStarted(_timer.started)
    //     return;
    //   }
    // }
    // else set a new timer
    setNewTimer();
  }, [id]);

  // start our tick function
  useEffect(() => {
    // console.log({ expires, intervalId: intervalId.current });
    if (!expires || intervalId.current) return;
    intervalId.current = setInterval(updateRemaining, 1000);

    return () => clearInterval(intervalId.current);
  }, [expires, updateRemaining, intervalId]);

  useEffect(() => {
    // console.log({ remaining })
    if (!remaining || remaining > 0) return;
    handleTimeout();
  }, [remaining, handleTimeout]);

  const duration = (expires - started);
  //  counts up to 100%
  const percent = 100 * (1 - ((remaining) / (duration)));
  // counts down from 100%
  // const percent = 100 * (remaining / (expires - started));



  return (
    <Box
      sx={styles}
      className={`timer-root ${(!remaining && 'loading')} ${running ? 'running' : ''}`}
    >
      {children && <Box className="child-content">{children}</Box>}
      <Box className="content">
        <Box className="progress-container">
          <CircularProgress
            variant={remaining ? 'determinate' : 'indeterminate'}
            value={percent}
            className={`progress`}
          />
          <Box className="progress-total" />
        </Box>
        <ManagedCountdownTimer
          className="timer"
          time={remaining}
        />
      </Box>
    </Box>
  );
}
export default memo(CountdownTimer);
