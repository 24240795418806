import { Divider } from '@mui/material';
import GeneralInformation from './GeneralInformation';
import MaterialsUsed from './MaterailsUsed/MaterialsUsed';
import ProductionOfAlcohol from './ProductionOfAlcohol/ProductionOfAlcohol';
import ProductionOfBrandy from './ProductionOfBrandy/ProductionOfBrandy';
import ProductionOfWhiskey from './ProductionOfWhiskey/ProductionOfWhiskey';
import Signature from './Signature';
import Transactions from './Transactions/Transactions';
import UsedInRedistillation from './UsedInRedistillation/UsedInRedistillation';
import TransactionsRum from './TransactionsRum/TransactionsRum';
import TransactionsSpirits from './TransactionsSpirits/TransactionsSpirits';

export default function TTBReport() {
	return (
		<>
			<Divider />
			<GeneralInformation
				name='general_information'
				title='General Information'
			/>
			<Divider />
			<Transactions
				title='Part I-Transactions - Whiskey and Brandy'
				name='PartI-Transactions-WhiskeyAndBrandy'
			/>
			<Divider />
			<TransactionsRum
				title='PartI-Transactions-Rum, Gin And Vodka'
				name='PartI-Transactions-Rum,GinAndVodka'
			/>
			<Divider />
			<TransactionsSpirits
				title='Part I-Transactions-Alcohol And Spirits And Other And Totals'
				name='PartI-Transactions-AlcoholAndSpiritsAndOther,AndTotals'
			/>
			<Divider />
			<ProductionOfAlcohol
				title='Part II-Production of Alcohol and Spirits of 190° or more'
				name='PartII-ProductionOfAlcoholAndSpiritsOf190OrMore'
			/>
			<Divider />
			<ProductionOfWhiskey
				title='PartIII-Production Of Whiskey By Kind And Coorperage Used'
				name='PartIII-ProductionOfWhiskeyByKindAndCoorperageUsed'
			/>
			<Divider />
			<ProductionOfBrandy
				title='PartIV-Production Of Brandy'
				name='PartIV-ProductionOfBrandy'
			/>
			<Divider />
			<UsedInRedistillation
				title='PartV-Used In Redistillation'
				name='PartV-UsedInRedistillation'
			/>
			<Divider />
			<MaterialsUsed
				title='PartVI-Materials Used'
				name='PartVI-MaterialsUsed'
			/>
			<Divider />
			<Signature title='Signature' name='Signature' />
		</>
	);
}
