import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import SKUPolicies from '@pw/components/admin/SKUPolicies';
import VendorProperties from '@pw/components/admin/VendorProperties';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Client from '@pw/components/Client';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import { upsertSKUThunk } from '@pw/redux/thunks/sku';
import { toSKURequest } from '@pw/utilities/format';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useDispatch } from 'react-redux';

function SKUForm({
	id,
	entity,
	changeSet,
	readonly = false,
	edit = false,
	onCancel,
	onBeforeSave,
	onSave,
	children,
}) {
	const dispatch = useDispatch();
	const converter = useConverter();

	const handleSubmit = async (values) => {
		const updated = onBeforeSave ? onBeforeSave(values) : values;
		const request = toSKURequest(converter, {
			...(entity ?? {}),
			...updated,
		});
		console.log('Submitting', request);

		dispatch(upsertSKUThunk(request))
			.unwrap()
			.then((response) => onSave(response));
	};

	return (
		<>
			<FormikForm
				changeSet={changeSet}
				onSubmit={handleSubmit}
				edit={edit}
				readonly={readonly}
			>
				<FormWrapper>
					<Stack spacing='1.5rem'>
						<FlexBox alignItems='top'>
							<FormikTextField label='Name' name='sku_name' fullWidth />
						</FlexBox>
						<FormikTextField
							label='Description'
							name='sku_description'
							fullWidth
						/>
					</Stack>

					{children}

					<Client name='client' noCustomers={true} />

					<VendorProperties initialValues={entity?.vendors} />

					<SKUPolicies initialValues={entity?.policies} />

					{!readonly && (
						<>
							<Errors />
							<Box className='action-buttons'>
								{onCancel && (
									<TextButton
										size='small'
										handleClick={onCancel}
										color='secondary'
										label='Cancel'
									/>
								)}
								<FilledButton type='submit' size='small'>
									{id?.length ? 'Update' : 'Add'}
								</FilledButton>
							</Box>
						</>
					)}
				</FormWrapper>
			</FormikForm>
		</>
	);
}

export default SKUForm;
