import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// This theme created for notification component button
const theme = createTheme({
	palette: {
		ochre: {
			main: '#666665',
			light: '#666665',
			dark: '#666665',
			contrastText: '#242105',
		},
	},
});
function OutlinedButton({
	label = '',
	icon = '',
	iconPosition = 'end',
	size = 'medium',
	handleClick = () => {},
	loading = false,
	disabled = false,
	type,
	sx = {},
	className = '',
	color = 'primary',
	children,
}) {
	return (
		<ThemeProvider theme={theme}>
			<Button
				variant='outlined'
				sx={sx}
				disabled={loading || disabled}
				onClick={handleClick}
				className={className}
				size={size}
				{...(iconPosition === 'start' && { startIcon: icon })}
				{...(iconPosition === 'end' && { endIcon: icon })}
				type={type}
				color={color}
			>
				{label}
				{children}
				{loading && <CircularProgress size={24} sx={{ marginLeft: '0.5rem' }} />}
			</Button>
		</ThemeProvider>
	);
}

export default OutlinedButton;
