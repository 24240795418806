import useEncoder from '@pw/utilities/hooks/useEncoder';
import { useMemo } from 'react';
import QRImage from '@pw/components/QRImage/index';
import { Body1, Body2 } from '@pw/components/Typography';
import { ASSET_TYPES_REVERSE } from '@pw/consts/asset';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { SKU_EDIT_URL, SKU_TYPES } from '@pw/consts/sku';
import gzipAndEncodeBase64 from '@pw/utilities/hooks/useEncoder';
import { Box } from '@mui/material';

const styles = {
	'.root': {
		width: '100%',
		height: '100%',

		'.qrIcon': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-evenly',
			border: '2px solid',
			padding: '5px',
			height: '72px',
			width: '72px',
			borderRadius: '50%',
		},
	},
};

function SKULocatorQRCode({ params, type }) {
	const encoder = useEncoder();
	const loc = typeof window !== 'undefined' && window?.location?.origin;
	const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);

	const qq = useMemo(() => encoder.encode(JSON.stringify(params)), [params]);

	const qrValue = useMemo(
		() => `${origin}/s?t=${type}&p=${qq}`,
		[qq, origin],
	);

	return (
		<Box sx={styles}>
			<div className='root'>
					<QRImage value={qrValue} />
			</div>
		</Box>
	);
}

export default SKULocatorQRCode;
