import * as yup from 'yup';

export default function section3Fields(fields = {}) {
	const {
		COMPDENATURED_FN18 = '',
		COMPDENATURED_FN19 = '',
		COMPDENATURED_FN20 = '',
		SPECDENTRDALC_FN1 = '',
		SPECDENTRDALC_FN2B = '',
		SPECDENTRDALC_FN2C = '',
		SPECDENTRDALC_FN3A = '',
		SPECDENTRDALC_FN3B = '',
		SPECDENTRDALC_FN4 = '',
		SPECDENTRDALC_FN6B = '',
		SPECDENTRDALC_FN12A = '',
		SPECDENTRDALC_FN13A = '',
		SPECDENTRDALC_FN17 = '',
		SPECDENTRDALC_FN18 = '',
		SPECDENTRDALC_FN19 = '',
		SPECDENTRDALC_FN20 = '',
		SPECDENTRDALC_FN22 = '',
		SPECDENTRDALC_FN23A = '',
		SPECDENTRDALC_FN23F = '',
		SPECDENTRDALC_FN23H = '',
		SPECDENTRDALC_FN25 = '',
		SPECDENTRDALC_FN25A = '',
		SPECDENTRDALC_FN27 = '',
		SPECDENTRDALC_FN27A = '',
		SPECDENTRDALC_FN27B = '',
		SPECDENTRDALC_FN28A = '',
		SPECDENTRDALC_FN29 = '',
		SPECDENTRDALC_FN30 = '',
		SPECDENTRDALC_FN31A = '',
		SPECDENTRDALC_FN32 = '',
		SPECDENTRDALC_FN33 = '',
		SPECDENTRDALC_FN35 = '',
		SPECDENTRDALC_FN35A = '',
		SPECDENTRDALC_FN36 = '',
		SPECDENTRDALC_FN37 = '',
		SPECDENTRDALC_FN38B = '',
		SPECDENTRDALC_FN38C = '',
		SPECDENTRDALC_FN38D = '',
		SPECDENTRDALC_FN38F = '',
		SPECDENTRDALC_FN39 = '',
		SPECDENTRDALC_FN39A = '',
		SPECDENTRDALC_FN39B = '',
		SPECDENTRDALC_FN39C = '',
		SPECDENTRDALC_FN39D = '',
		SPECDENTRDALC_FN40 = '',
		SPECDENTRDALC_FN40A = '',
		SPECDENTRDALC_FN40B = '',
		SPECDENTRDALC_FN40C = '',
		SPECDENTRDALC_FN42 = '',
		SPECDENTRDALC_FN44 = '',
		SPECDENTRDALC_FN45 = '',
		SPECDENTRDALC_FN46 = '',
		SPECDENTRDALC_FNBLANK1 = '',
		SPECDENTRDALC_FNBLANK1VALUE = '',
		SPECDENTRDALC_FNBLANK2 = '',
		SPECDENTRDALC_FNBLANK2VALUE = '',
		SPECDENTRDALC_FNBLANK3 = '',
		SPECDENTRDALC_FNBLANK3VALUE = '',
		SPECDENTRDALC_FNBLANK4 = '',
		SPECDENTRDALC_FNBLANK4VALUE = '',
		SPECDENTRDALC_FNBLANK5 = '',
		SPECDENTRDALC_FNBLANK5VALUE = '',
		SPECDENTRDALC_FNBLANK6 = '',
		SPECDENTRDALC_FNBLANK6VALUE = '',
		SPECFORM_FNBLANK1 = '',
		SPECFORM_FNBLANK1VALUE = '',
		SPECFORM_FNBLANK2 = '',
		SPECFORM_FNBLANK2VALUE = '',
		SPECDENRUM_FN4 = '',
		SPECDENRUM_FNBLANK1 = '',
		SPECDENRUM_FNBLANK1VALUE = '',
		SPECDENRUM_FNBLANK2 = '',
		SPECDENRUM_FNBLANK2VALUE = '',
	} = fields;

	return {
		COMPDENATURED_FN18: [COMPDENATURED_FN18, yup.string()],
		COMPDENATURED_FN19: [COMPDENATURED_FN19, yup.string()],
		COMPDENATURED_FN20: [COMPDENATURED_FN20, yup.string()],
		SPECDENTRDALC_FN1: [SPECDENTRDALC_FN1, yup.string()],
		SPECDENTRDALC_FN2B: [SPECDENTRDALC_FN2B, yup.string()],
		SPECDENTRDALC_FN2C: [SPECDENTRDALC_FN2C, yup.string()],
		SPECDENTRDALC_FN3A: [SPECDENTRDALC_FN3A, yup.string()],
		SPECDENTRDALC_FN3B: [SPECDENTRDALC_FN3B, yup.string()],
		SPECDENTRDALC_FN4: [SPECDENTRDALC_FN4, yup.string()],
		SPECDENTRDALC_FN6B: [SPECDENTRDALC_FN6B, yup.string()],
		SPECDENTRDALC_FN12A: [SPECDENTRDALC_FN12A, yup.string()],
		SPECDENTRDALC_FN13A: [SPECDENTRDALC_FN13A, yup.string()],
		SPECDENTRDALC_FN17: [SPECDENTRDALC_FN17, yup.string()],
		SPECDENTRDALC_FN18: [SPECDENTRDALC_FN18, yup.string()],
		SPECDENTRDALC_FN19: [SPECDENTRDALC_FN19, yup.string()],
		SPECDENTRDALC_FN20: [SPECDENTRDALC_FN20, yup.string()],
		SPECDENTRDALC_FN22: [SPECDENTRDALC_FN22, yup.string()],
		SPECDENTRDALC_FN23A: [SPECDENTRDALC_FN23A, yup.string()],
		SPECDENTRDALC_FN23F: [SPECDENTRDALC_FN23F, yup.string()],
		SPECDENTRDALC_FN23H: [SPECDENTRDALC_FN23H, yup.string()],
		SPECDENTRDALC_FN25: [SPECDENTRDALC_FN25, yup.string()],
		SPECDENTRDALC_FN25A: [SPECDENTRDALC_FN25A, yup.string()],
		SPECDENTRDALC_FN27: [SPECDENTRDALC_FN27, yup.string()],
		SPECDENTRDALC_FN27A: [SPECDENTRDALC_FN27A, yup.string()],
		SPECDENTRDALC_FN27B: [SPECDENTRDALC_FN27B, yup.string()],
		SPECDENTRDALC_FN28A: [SPECDENTRDALC_FN28A, yup.string()],
		SPECDENTRDALC_FN29: [SPECDENTRDALC_FN29, yup.string()],
		SPECDENTRDALC_FN30: [SPECDENTRDALC_FN30, yup.string()],
		SPECDENTRDALC_FN31A: [SPECDENTRDALC_FN31A, yup.string()],
		SPECDENTRDALC_FN32: [SPECDENTRDALC_FN32, yup.string()],
		SPECDENTRDALC_FN33: [SPECDENTRDALC_FN33, yup.string()],
		SPECDENTRDALC_FN35: [SPECDENTRDALC_FN35, yup.string()],
		SPECDENTRDALC_FN35A: [SPECDENTRDALC_FN35A, yup.string()],
		SPECDENTRDALC_FN36: [SPECDENTRDALC_FN36, yup.string()],
		SPECDENTRDALC_FN37: [SPECDENTRDALC_FN37, yup.string()],
		SPECDENTRDALC_FN38B: [SPECDENTRDALC_FN38B, yup.string()],
		SPECDENTRDALC_FN38C: [SPECDENTRDALC_FN38C, yup.string()],
		SPECDENTRDALC_FN38D: [SPECDENTRDALC_FN38D, yup.string()],
		SPECDENTRDALC_FN38F: [SPECDENTRDALC_FN38F, yup.string()],
		SPECDENTRDALC_FN39: [SPECDENTRDALC_FN39, yup.string()],
		SPECDENTRDALC_FN39A: [SPECDENTRDALC_FN39A, yup.string()],
		SPECDENTRDALC_FN39B: [SPECDENTRDALC_FN39B, yup.string()],
		SPECDENTRDALC_FN39C: [SPECDENTRDALC_FN39C, yup.string()],
		SPECDENTRDALC_FN39D: [SPECDENTRDALC_FN39D, yup.string()],
		SPECDENTRDALC_FN40: [SPECDENTRDALC_FN40, yup.string()],
		SPECDENTRDALC_FN40A: [SPECDENTRDALC_FN40A, yup.string()],
		SPECDENTRDALC_FN40B: [SPECDENTRDALC_FN40B, yup.string()],
		SPECDENTRDALC_FN40C: [SPECDENTRDALC_FN40C, yup.string()],
		SPECDENTRDALC_FN42: [SPECDENTRDALC_FN42, yup.string()],
		SPECDENTRDALC_FN44: [SPECDENTRDALC_FN44, yup.string()],
		SPECDENTRDALC_FN45: [SPECDENTRDALC_FN45, yup.string()],
		SPECDENTRDALC_FN46: [SPECDENTRDALC_FN46, yup.string()],
		SPECDENTRDALC_FNBLANK1: [SPECDENTRDALC_FNBLANK1, yup.string()],
		SPECDENTRDALC_FNBLANK1VALUE: [SPECDENTRDALC_FNBLANK1VALUE, yup.string()],
		SPECDENTRDALC_FNBLANK2: [SPECDENTRDALC_FNBLANK2, yup.string()],
		SPECDENTRDALC_FNBLANK2VALUE: [SPECDENTRDALC_FNBLANK2VALUE, yup.string()],
		SPECDENTRDALC_FNBLANK3: [SPECDENTRDALC_FNBLANK3, yup.string()],
		SPECDENTRDALC_FNBLANK3VALUE: [SPECDENTRDALC_FNBLANK3VALUE, yup.string()],
		SPECDENTRDALC_FNBLANK4: [SPECDENTRDALC_FNBLANK4, yup.string()],
		SPECDENTRDALC_FNBLANK4VALUE: [SPECDENTRDALC_FNBLANK4VALUE, yup.string()],
		SPECDENTRDALC_FNBLANK5: [SPECDENTRDALC_FNBLANK5, yup.string()],
		SPECDENTRDALC_FNBLANK5VALUE: [SPECDENTRDALC_FNBLANK5VALUE, yup.string()],
		SPECDENTRDALC_FNBLANK6: [SPECDENTRDALC_FNBLANK6, yup.string()],
		SPECDENTRDALC_FNBLANK6VALUE: [SPECDENTRDALC_FNBLANK6VALUE, yup.string()],
		SPECFORM_FNBLANK1: [SPECFORM_FNBLANK1, yup.string()],
		SPECFORM_FNBLANK1VALUE: [SPECFORM_FNBLANK1VALUE, yup.string()],
		SPECFORM_FNBLANK2: [SPECFORM_FNBLANK2, yup.string()],
		SPECFORM_FNBLANK2VALUE: [SPECFORM_FNBLANK2VALUE, yup.string()],
		SPECDENRUM_FN4: [SPECDENRUM_FN4, yup.string()],
		SPECDENRUM_FNBLANK1: [SPECDENRUM_FNBLANK1, yup.string()],
		SPECDENRUM_FNBLANK1VALUE: [SPECDENRUM_FNBLANK1VALUE, yup.string()],
		SPECDENRUM_FNBLANK2: [SPECDENRUM_FNBLANK2, yup.string()],
		SPECDENRUM_FNBLANK2VALUE: [SPECDENRUM_FNBLANK2VALUE, yup.string()],
	};
}
