function SvgTick(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 22 22'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				data-name='Path 9342'
				className='tick_svg__cls-1'
				d='M19.4 5.68c.46-.42.49-1.12.07-1.58-.42-.46-1.12-.49-1.58-.07-.03.02-.05.05-.08.08-.35.34-.71.68-1.04 1.05-.19.21-.29.18-.49 0a8.665 8.665 0 00-7.3-2.25 8.846 8.846 0 00-7.35 10.32 8.826 8.826 0 0010.26 7.09 8.826 8.826 0 007.09-10.26c-.17-.93-.49-1.83-.95-2.66-.1-.12-.08-.29.04-.39l.02-.01c.45-.43.89-.87 1.32-1.31zm-3.89 9.94a6.186 6.186 0 01-6.62 2.42 6.309 6.309 0 01-4.98-4.9 6.552 6.552 0 014.87-7.9c.09-.02.18-.04.28-.06 2.02-.41 4.11.17 5.64 1.55.22.19.23.29.01.5-1.45 1.43-2.89 2.87-4.32 4.32-.21.21-.3.18-.48-.01-.4-.43-.83-.85-1.25-1.26-.41-.47-1.11-.52-1.58-.11s-.52 1.11-.11 1.58l.12.12c.73.74 1.46 1.47 2.2 2.2a1.126 1.126 0 001.75 0l4.92-4.92c.09-.09.19-.18.32-.3 1.08 2.22.78 4.86-.77 6.79z'
			/>
		</svg>
	);
}
export default SvgTick;
