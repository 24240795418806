export const CONTRACT_STATUS = {
	NOT_INSTALLED: '0',
	PENDING: '1',
	ACTIVE: '2',
	FAILED: '3',
};

export const TOKEN_TYPE_ENUM = {
	ASSET_TOKEN: 'asset-token',
	REQUEST_TOKEN: 'request-token',
};

export const CONTRACT_STATUS_REVERS = {
	0: 'Not installed',
	1: 'Pending',
	2: 'Active',
	3: '',
};

export const MIN_ACTIVATE_AMOUNT = 100;
