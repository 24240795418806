import { Stack } from '@mui/material';
import { Body1, H5 } from '@pw/components/Typography';

function Instructions({ instruction = null, children }) {
	return (
		<Stack gap={1}>
			{instruction && <H5>{instruction}</H5>}	
			<Body1>{children}</Body1>
		</Stack>
	);
}

export default Instructions;
