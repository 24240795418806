import { checkResponseStatus } from './checkResponseStatus';
import requestHeaders from './requestHeaders';
const fetchPost = async (url, headers = {}, body = {}) => {
	// console.log('Getting url', url);
	const response = await fetch(url, {
		method: 'POST',
		headers: requestHeaders(headers),
		body: JSON.stringify(body),
	});
	checkResponseStatus(response.status);
	const jsonResponse = await response.json();
	if (!response.ok) {
		throw jsonResponse;
	}
	return jsonResponse;
};

export default fetchPost;
