const Split = (props) => (
  <svg
    width='1em'
    height='1em'
    viewBox='0 0 28 28'
    // fill="none"
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g>
      <path d="M0 13.9998C0 11.3949 0 8.7899 0 6.18494C0 4.26127 1.26908 2.99219 3.19275 2.99219C5.10305 2.99219 7.02672 2.99219 8.93702 2.99219C9.69847 2.99219 10.1927 3.68684 9.92557 4.35478C9.76527 4.75555 9.40458 4.996 8.91031 4.996C7.02672 4.996 5.14313 4.996 3.25954 4.996C2.33779 4.996 2.00382 5.34333 2.00382 6.26509C2.00382 11.4483 2.00382 16.6181 2.00382 21.8013C2.00382 22.6296 2.37786 23.0036 3.19275 23.0036C5.10305 23.0036 7.02672 23.0036 8.93702 23.0036C9.69847 23.0036 10.1927 23.6983 9.91221 24.3662C9.75191 24.767 9.4313 24.9941 8.99046 24.9941C6.94657 24.9941 4.90267 25.0208 2.87214 24.9941C1.28244 24.9674 0 23.6315 0 22.0017C0 19.33 0 16.6716 0 13.9998Z" fill="currentColor" />
      <path d="M27.9994 13.9999C27.9994 16.6049 27.9994 19.2099 27.9994 21.8148C27.9994 23.7385 26.7303 25.0076 24.8066 25.0076C22.8963 25.0076 20.9727 25.0076 19.0624 25.0076C18.2608 25.0076 17.7666 24.2461 18.1139 23.5648C18.3143 23.1774 18.6482 23.0171 19.0891 23.0171C20.9727 23.0171 22.8563 23.0171 24.7398 23.0171C25.6616 23.0171 25.9956 22.6698 25.9956 21.748C25.9956 16.5782 25.9956 11.4217 25.9956 6.25185C25.9956 5.38353 25.6349 5.02284 24.7666 5.02284C22.8563 5.02284 20.9593 5.02284 19.049 5.02284C18.3009 5.02284 17.82 4.3549 18.0604 3.68696C18.194 3.29956 18.5414 3.03238 18.9688 3.03238C21.0395 3.03238 23.1101 3.00566 25.1807 3.03238C26.757 3.0591 27.986 4.4217 27.9994 6.05147C27.9994 8.70986 27.9994 11.3683 27.9994 14.0133V13.9999Z" fill="currentColor" />
      <path d="M12.998 13.9864C12.998 9.73835 12.998 5.49026 12.998 1.24217C12.998 1.10858 12.998 0.974992 13.0114 0.841404C13.0916 0.333771 13.5591 -0.0135576 14.0667 0.0131599C14.561 0.0532363 14.9618 0.454 14.9885 0.948274C14.9885 1.01507 14.9885 1.09522 14.9885 1.16201C14.9885 9.72499 14.9885 18.288 14.9885 26.8509C14.9885 27.3319 14.8416 27.7059 14.3874 27.9196C13.7729 28.2002 13.0515 27.7727 12.998 27.078C12.998 26.9578 12.998 26.8509 12.998 26.7307C12.998 22.4826 12.998 18.2345 12.998 13.9864Z" fill="currentColor" />
    </g>

  </svg>
);

export default Split;
