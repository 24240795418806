function SvgIcClose(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 302.8 256.83'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d='M144.13 224.67c46.39 0 92.78.03 139.17-.07 3.12 0 4.34.5 4.21 4.01-.3 8.11-.25 16.24 0 24.36.1 3.25-1.02 3.86-4.02 3.85-54.37-.09-108.74-.06-163.11-.06-38.78 0-77.57-.03-116.35.06-3 0-4.11-.61-4.01-3.86.24-8.24.25-16.49.01-24.73-.09-3.13 1.04-3.63 3.82-3.62 46.76.08 93.53.05 140.29.05zM71.7 32.74c-22.56 0-45.12-.05-67.67.07-3.02.02-4.11-.65-4.02-3.88C.25 20.69.2 12.44.05 4.2 0 1.51.6.52 3.53.53c45.61.09 91.23.08 136.84.02 2.56 0 3.47.66 3.42 3.34-.15 8.49-.18 16.99.03 25.47.07 3-1.08 3.44-3.7 3.43-22.81-.09-45.61-.05-68.42-.05zM71.96 112.87c22.68 0 45.36.05 68.03-.06 2.83-.01 3.9.61 3.82 3.69-.22 7.98-.23 15.98.02 23.97.1 3.27-.75 4.19-4.1 4.18-37.75-.13-75.51-.08-113.26-.08-7.73 0-15.45-.08-23.18.05-2.36.04-3.3-.54-3.25-3.1.15-8.49.17-16.98 0-25.47-.06-2.81 1.09-3.23 3.51-3.22 22.8.07 45.6.04 68.41.04zM301.42 70.56c-7.46-7.35-14.91-14.7-22.36-22.06-.81-.94-1.56-1.93-2.43-2.8-13.65-13.7-27.32-27.38-41-41.05-1.32-1.32-2.87-2.41-4.14-3.77-1.31-1.39-2.21-1-3.38.18-6.4 6.48-12.84 12.92-19.3 19.34-.91.9-1.38 1.54-.2 2.7 9.34 9.16 18.63 18.39 27.93 27.6 6.26 6.38 12.4 12.9 18.85 19.08 2.46 2.36 2.39 3.61-.01 5.97-11.68 11.52-23.15 23.24-34.7 34.89-3.91 3.83-7.78 7.72-11.76 11.48-1.4 1.32-1.03 2.1.15 3.28 6.38 6.31 12.73 12.66 19.01 19.07 1.31 1.33 2.16 1.56 3.6.09 10.82-11.03 21.71-21.99 32.58-32.97 12.54-12.49 25.06-25 37.65-37.43 1.69-1.67.59-2.52-.51-3.6z' />
		</svg>
	);
}
export default SvgIcClose;
