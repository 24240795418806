{
  "sku_id": "some_sku",
  "nodes": [
    {
      "id": "mash_tun-1730990656267",
      "data": {
        "category": "process",
        "icon": "Mash",
        "form": "MashForm",
        "type": "mash_tun",
        "label": "Mash Tun",
        "inputType": [
          "grain_milled",
          "water"
        ],
        "outputType": [
          "wort"
        ],
        "position": {
          "x": 550,
          "y": 300
        }
      },
      "position": {
        "x": 550,
        "y": 300
      },
      "type": "processNode",
      "width": 190,
      "height": 190
    },
    {
      "id": "grain-1730990660285",
      "data": {
        "category": "material",
        "icon": "Grain",
        "form": "GrainForm",
        "type": "grain",
        "label": "Grain",
        "measuredBy": "weight",
        "outputType": [
          "grain"
        ],
        "variants": [
          {
            "label": "Barley",
            "type": "barley"
          },
          {
            "label": "Corn",
            "type": "corn"
          },
          {
            "label": "Rhye",
            "type": "rhye"
          },
          {
            "label": "Wheat",
            "type": "wheat"
          }
        ],
        "position": {
          "x": 400,
          "y": 150
        }
      },
      "position": {
        "x": 400,
        "y": 125
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 400,
        "y": 125
      },
      "dragging": false
    },
    {
      "id": "water-1730990662639",
      "data": {
        "category": "material",
        "icon": "Water",
        "form": "WaterForm",
        "type": "water",
        "label": "Water",
        "measuredBy": "volume",
        "outputType": [
          "water"
        ],
        "position": {
          "x": 300,
          "y": 300
        }
      },
      "position": {
        "x": 300,
        "y": 250
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 300,
        "y": 250
      },
      "dragging": false
    },
    {
      "id": "grain-1730990674630",
      "data": {
        "category": "material",
        "icon": "Grain",
        "form": "GrainForm",
        "type": "grain",
        "label": "Grain",
        "measuredBy": "weight",
        "outputType": [
          "grain"
        ],
        "variants": [
          {
            "label": "Barley",
            "type": "barley"
          },
          {
            "label": "Corn",
            "type": "corn"
          },
          {
            "label": "Rhye",
            "type": "rhye"
          },
          {
            "label": "Wheat",
            "type": "wheat"
          }
        ],
        "position": {
          "x": 300,
          "y": 400
        }
      },
      "position": {
        "x": 225,
        "y": 450
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 225,
        "y": 450
      },
      "dragging": false
    },
    {
      "id": "water-1730990681262",
      "data": {
        "category": "material",
        "icon": "Water",
        "form": "WaterForm",
        "type": "water",
        "label": "Water",
        "measuredBy": "volume",
        "outputType": [
          "water"
        ],
        "position": {
          "x": 400,
          "y": 550
        }
      },
      "position": {
        "x": 350,
        "y": 525
      },
      "type": "materialNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 350,
        "y": 525
      },
      "dragging": false
    },
    {
      "id": "washback-1730990776670",
      "data": {
        "category": "process",
        "icon": "Washback",
        "form": "WashbackForm",
        "type": "washback",
        "label": "Washback",
        "inputType": [
          "wort",
          "yeast"
        ],
        "outputType": [
          "_washback_out"
        ],
        "position": {
          "x": 750,
          "y": 450
        }
      },
      "position": {
        "x": 800,
        "y": 375
      },
      "type": "processNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 800,
        "y": 375
      },
      "dragging": false
    },
    {
      "id": "still-spirit-1730990784135",
      "data": {
        "type": "still",
        "category": "process",
        "icon": "SpiritStill",
        "form": "SpiritStillForm",
        "label": "Spirit Still",
        "variant": "spirit",
        "inputType": [
          "spirit"
        ],
        "outputType": [
          "distillant"
        ],
        "position": {
          "x": 1100,
          "y": 450
        }
      },
      "position": {
        "x": 1050,
        "y": 450
      },
      "type": "processNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 1050,
        "y": 450
      },
      "dragging": false
    },
    {
      "id": "receiver-1730990826418",
      "data": {
        "category": "process",
        "icon": "Receiver",
        "type": "receiver",
        "label": "Receiver",
        "inputType": [
          "distillant",
          "water"
        ],
        "outputType": [
          "spirit_newmake"
        ],
        "position": {
          "x": 1050,
          "y": 650
        }
      },
      "position": {
        "x": 1050,
        "y": 700
      },
      "type": "processNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 1050,
        "y": 700
      },
      "dragging": false
    },
    {
      "id": "ph-1730990838788",
      "data": {
        "category": "observation",
        "icon": "Ph",
        "form": "PhForm",
        "type": "ph",
        "label": "PH",
        "position": {
          "x": 700,
          "y": 200
        }
      },
      "position": {
        "x": 700,
        "y": 150
      },
      "type": "observationNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 700,
        "y": 150
      },
      "dragging": false
    },
    {
      "id": "temperature-1730990845251",
      "data": {
        "category": "observation",
        "icon": "Temperature",
        "form": "TemperatureForm",
        "type": "temperature",
        "label": "Temperature",
        "position": {
          "x": 950,
          "y": 200
        }
      },
      "position": {
        "x": 975,
        "y": 225
      },
      "type": "observationNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 975,
        "y": 225
      },
      "dragging": false
    },
    {
      "id": "og_sg-1730990855969",
      "data": {
        "category": "observation",
        "icon": "OG_SG",
        "form": "OgSgForm",
        "type": "og_sg",
        "label": "OG/SG",
        "position": {
          "x": 1200,
          "y": 300
        }
      },
      "position": {
        "x": 1200,
        "y": 300
      },
      "type": "observationNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "dragging": false
    },
    {
      "id": "brix-1730990860267",
      "data": {
        "category": "observation",
        "icon": "Brix",
        "form": "BrixForm",
        "type": "brix",
        "label": "Brix",
        "position": {
          "x": 1250,
          "y": 500
        }
      },
      "position": {
        "x": 1250,
        "y": 475
      },
      "type": "observationNode",
      "width": 190,
      "height": 190,
      "selected": false,
      "positionAbsolute": {
        "x": 1250,
        "y": 475
      },
      "dragging": false
    },
    {
      "id": "og_sg-1730993159235",
      "data": {
        "category": "observation",
        "icon": "OG_SG",
        "form": "OgSgForm",
        "type": "og_sg",
        "label": "OG/SG",
        "position": {
          "x": 1300,
          "y": 650
        }
      },
      "position": {
        "x": 1300,
        "y": 650
      },
      "type": "observationNode",
      "width": 190,
      "height": 190,
      "selected": true,
      "dragging": false
    }
  ],
  "edges": [
    {
      "source": "grain-1730990660285",
      "sourceHandle": "handle-bottom-right-source",
      "target": "mash_tun-1730990656267",
      "targetHandle": "handle-top-left-source",
      "id": "reactflow__edge-grain-1730990660285handle-bottom-right-source-mash_tun-1730990656267handle-top-left-source"
    },
    {
      "source": "water-1730990662639",
      "sourceHandle": "handle-bottom-right-source",
      "target": "mash_tun-1730990656267",
      "targetHandle": "handle-top-left-source",
      "id": "reactflow__edge-water-1730990662639handle-bottom-right-source-mash_tun-1730990656267handle-top-left-source"
    },
    {
      "source": "grain-1730990674630",
      "sourceHandle": "handle-top-right-source",
      "target": "mash_tun-1730990656267",
      "targetHandle": "handle-bottom-left-source",
      "id": "reactflow__edge-grain-1730990674630handle-top-right-source-mash_tun-1730990656267handle-bottom-left-source",
      "selected": false
    },
    {
      "source": "water-1730990681262",
      "sourceHandle": "handle-top-right-source",
      "target": "mash_tun-1730990656267",
      "targetHandle": "handle-bottom-left-source",
      "id": "reactflow__edge-water-1730990681262handle-top-right-source-mash_tun-1730990656267handle-bottom-left-source",
      "selected": false
    },
    {
      "source": "mash_tun-1730990656267",
      "sourceHandle": "handle-right-source",
      "target": "washback-1730990776670",
      "targetHandle": "handle-left-source",
      "id": "reactflow__edge-mash_tun-1730990656267handle-right-source-washback-1730990776670handle-left-source"
    },
    {
      "source": "washback-1730990776670",
      "sourceHandle": "handle-right-source",
      "target": "still-spirit-1730990784135",
      "targetHandle": "handle-left-source",
      "id": "reactflow__edge-washback-1730990776670handle-right-source-still-spirit-1730990784135handle-left-source"
    },
    {
      "source": "still-spirit-1730990784135",
      "sourceHandle": "handle-bottom-source",
      "target": "receiver-1730990826418",
      "targetHandle": "handle-top-source",
      "id": "reactflow__edge-still-spirit-1730990784135handle-bottom-source-receiver-1730990826418handle-top-source"
    },
    {
      "source": "mash_tun-1730990656267",
      "sourceHandle": "handle-top-right-source",
      "target": "ph-1730990838788",
      "targetHandle": "handle-bottom-left-target",
      "id": "reactflow__edge-mash_tun-1730990656267handle-top-right-source-ph-1730990838788handle-bottom-left-target"
    },
    {
      "source": "washback-1730990776670",
      "sourceHandle": "handle-top-right-source",
      "target": "temperature-1730990845251",
      "targetHandle": "handle-bottom-left-target",
      "id": "reactflow__edge-washback-1730990776670handle-top-right-source-temperature-1730990845251handle-bottom-left-target"
    },
    {
      "source": "still-spirit-1730990784135",
      "sourceHandle": "handle-top-right-source",
      "target": "og_sg-1730990855969",
      "targetHandle": "handle-bottom-left-target",
      "id": "reactflow__edge-still-spirit-1730990784135handle-top-right-source-og_sg-1730990855969handle-bottom-left-target"
    },
    {
      "source": "still-spirit-1730990784135",
      "sourceHandle": "handle-bottom-right-source",
      "target": "brix-1730990860267",
      "targetHandle": "handle-top-left-target",
      "id": "reactflow__edge-still-spirit-1730990784135handle-bottom-right-source-brix-1730990860267handle-top-left-target"
    },
    {
      "source": "receiver-1730990826418",
      "sourceHandle": "handle-top-right-source",
      "target": "og_sg-1730993159235",
      "targetHandle": "handle-top-left-target",
      "id": "reactflow__edge-receiver-1730990826418handle-top-right-source-og_sg-1730993159235handle-top-left-target"
    }
  ],
  "nodes_data": {
    "grain-1730990660285": {
      "quantity": {
        "value": "1",
        "unit": ""
      },
      "sku": ""
    },
    "water-1730990662639": {
      "quantity": {
        "value": "1000",
        "unit": "l"
      },
      "temperature": {
        "value": "70",
        "unit": "°C"
      }
    },
    "grain-1730990674630": {
      "quantity": {
        "value": "250",
        "unit": "kg"
      },
      "sku": ""
    },
    "water-1730990681262": {
      "quantity": {
        "value": "200",
        "unit": "gal"
      },
      "temperature": {
        "value": "150",
        "unit": "°F"
      }
    },
    "reactflow__edge-grain-1730990674630handle-top-right-source-mash_tun-1730990656267handle-bottom-left-source": {
      "offset": {
        "value": "4",
        "unit": "hr"
      },
      "quantity": {
        "value": "",
        "unit": ""
      }
    },
    "reactflow__edge-water-1730990681262handle-top-right-source-mash_tun-1730990656267handle-bottom-left-source": {
      "offset": {
        "value": "7",
        "unit": "hr"
      },
      "quantity": {
        "value": "",
        "unit": ""
      }
    },
    "ph-1730990838788": {
      "repeat": true,
      "interval": {
        "value": "1",
        "unit": "hr"
      }
    },
    "temperature-1730990845251": {
      "repeat": true,
      "interval": {
        "value": "30",
        "unit": "min"
      },
      "temperature": {
        "value": "",
        "unit": ""
      }
    },
    "og_sg-1730990855969": {
      "repeat": true,
      "interval": {
        "value": "20",
        "unit": "min"
      },
      "og": {
        "value": "",
        "unit": ""
      },
      "sg": {
        "value": "",
        "unit": ""
      }
    },
    "brix-1730990860267": {
      "repeat": true,
      "interval": {
        "value": "45",
        "unit": "min"
      }
    }
  }
}