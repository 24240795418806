import { splitTime } from '@pw/providers/CountdownProvider';
import Box from '@mui/material/Box';
import styles from './ManagedTimer.styles';

const pad = (num) => `${num}`.padStart(2, '0');

const digits = (str) => `${str}`.split('').map((d, i) => (
  <Box key={`digit-${i}`} className="digit">{d}</Box>
));

function ManagedCountdownTimer({
  time,
  Icon = null,
  text = null,
  textEnded = null,
  showZeroValues = false,
  showLeadingZeroes = false,
  className,
  sx,
}) {
  const {
    time: {
      days,
      hours,
      minutes,
      seconds,
    },
  } = splitTime(time);

  const d = showLeadingZeroes ? pad(days) : days;
  const h = showLeadingZeroes ? pad(hours) : hours;
  const m = showLeadingZeroes ? pad(minutes) : minutes;
  const s = showLeadingZeroes ? pad(seconds) : seconds;

  return (
    <Box sx={{ ...styles, ...sx }} className={`countdown-root text ${className || ''}`}>
      {Icon && (
        <Icon className="icon countdown" />
      )}

      {
        !(days === 0 && hours === 0 && minutes === 0 && seconds === 0)
          ? (
            <Box component="span" className="timer">
              {text && (
                <Box component="span" className="title">
                  {text}
                </Box>
              )}

              {(days >= 1 || showZeroValues) && (
                <Box className="segment">
                  <Box className="number">{digits(d)}</Box>
                  <Box className="unit"> days</Box>
                </Box>
              )}

              {days < 1 && (
                <>
                  {(hours > 0 || showZeroValues) && (
                    <Box className="segment">
                      <Box className="number">{digits(h)}</Box>
                      <Box className="unit">h</Box>
                    </Box>
                  )}

                  {(minutes > 0 || showZeroValues) && (
                    <Box className="segment">
                      <Box className="number">{digits(m)}</Box>
                      <Box className="unit">m</Box>
                    </Box>
                  )}

                  <Box className="segment">
                    <Box className="number">{digits(s)}</Box>
                    <Box className="unit">s</Box>
                  </Box>
                </>
              )}
            </Box>
          )
          : <Box className="textEnded">{textEnded}</Box>
      }
    </Box>
  );
}

export default ManagedCountdownTimer;
