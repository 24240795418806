import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';

import { Body1, H3 } from '@pw/components/Typography';

const ToggleSectionButton = ({ state, setState }) => (
  <IconButton
    onClick={() => setState((curr) => !curr)}
    className={`expand open-${state}`}
  >
    <ExpandMore />
  </IconButton >
);

const ToolBoxSection = ({ heading, body, className, children }) => {
  const [sectionOpen, setSectionOpen] = useState(true);

  return (
    <Box className={`toolbar-section ${className || ''}`}>
      <ToggleSectionButton state={sectionOpen} setState={setSectionOpen} />
      <H3 className="section_heading">{heading}</H3>
      {body && <Body1 className="section_body">{body}</Body1>}
      {children && (
        <Box className={`children-container open-${sectionOpen}`}>
          {children}
        </Box>
      )}
    </Box >
  )
}
export default ToolBoxSection;
