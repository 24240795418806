import {
	challengeIdent,
	recover,
	registerUser,
	verifyIdent,
	verifySocials,
} from '@pw/services/auth.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleSignin } from './utils/handleSignin';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

export const challengeIdentThunk = createAsyncThunk(
	`register/challengeIdentThunk`,
	async (params, { rejectWithValue }) => {
		try {
			// This has to be loaded dynamically to avoid circular dependency
			const response = await challengeIdent(params);
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const challengeIdentResendThunk = createAsyncThunk(
	`register/challengeIdentResendThunk`,
	async (params, { rejectWithValue }) => {
		try {
			// This has to be loaded dynamically to avoid circular dependency
			const response = await challengeIdent(params);
			return response;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	},
);

export const registerUserThunk = createAsyncThunk(
	`register/registerUserThunk`,
	async (
		{ i18n, ...params },
		{ rejectWithValue, fulfillWithValue, dispatch },
	) => {
		try {
			const response = await registerUser(params);
			return handleSignin(
				i18n,
				response,
				fulfillWithValue,
				rejectWithValue,
				dispatch,
			);
		} catch (err) {
			return rejectWithValue(err.message);
		}
	},
);

export const verifyIdentThunk = createAsyncThunk(
	`register/verifyIdentThunk`,
	async (params, { rejectWithValue }) => {
		try {
			const response = await verifyIdent(params);
			return response;
		} catch (err) {
			return rejectWithValue(err.message);
		}
	},
);

export const verifySocialsThunk = createAsyncThunk(
	`register/verifySocialsThunk`,
	async ({ type, invite }, { rejectWithValue }) => {
		const auth = getAuth();
		auth.useDeviceLanguage();

		const provider = new GoogleAuthProvider();
		provider.setCustomParameters({
			prompt: 'select_account',
		});

		// const defaultEmail = window?.localStorage?.getItem('social-user');
		// provider.setCustomParameters({
		//   'login_hint': defaultEmail
		// });

		try {
			// Google account select..
			const result = await signInWithPopup(auth, provider);
			const credential = GoogleAuthProvider.credentialFromResult(result);
			console.log('Result', result, credential);
			const { user } = result;
			const { email, stsTokenManager } = user;

			// setIdentity([type, email]);

			// Hit the back-end to authenticate this user
			const response = await verifySocials({
				type,
				token: stsTokenManager.accessToken,
				invite,
			});
			console.log('Response', response);

			// Response contains signed identity and name
			return { ...response, email, ident: email };
		} catch (error) {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			// The email of the user's account used.
			// The AuthCredential type that was used.
			const credential = GoogleAuthProvider.credentialFromError(error);
			// ...
			console.log('Error', error, credential, errorCode, errorMessage);
			if (errorCode) {
				switch (errorCode) {
					case 'auth/popup-closed-by-user':
						rejectWithValue('You did not select an account!');
						break;

					default:
						rejectWithValue(
							`There was an error registering, see more details here [${errorCode}, ${errorMessage}]!`,
						);
						break;
				}
			} else {
				rejectWithValue(
					error.message ?? 'There was a problem registering, please try again!',
				);
			}
		}
	},
);

export const recoverThunk = createAsyncThunk(
	`register/recoverThunk`,
	async (params, { rejectWithValue }) => {
		try {
			const response = await recover(params);
			return response;
		} catch (err) {
			return rejectWithValue(err.message);
		}
	},
);
