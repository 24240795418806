import { b__black_10, ba } from '@pw/styles/border';
import { pa2, pa3 } from '@pw/styles/padding';

const styles = {
  '&.root': {
    borderRadius: '5px',
    backgroundColor: '#fff',
    color: '#000',
    fill: '#000',
    padding: {
      xs: '0.5rem',
      md: '1rem',
      lg: '2rem',
    },
    display: 'flex',

    '.page-content': {
      margin: 'auto',
      width: {
        xs: '100%',
        md: '568px',
      },
      display: 'flex',
      flexDirection: 'column',
      gap: {
        xs: '1rem',
        md: '1.5rem',
        lg: '2rem',
      },
    },

    '.identityDisplay': {
      border: '1px solid #a8a8a8',
      padding: '1rem',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '5px',

      '.changeButton': {
        color: 'rgb(48, 111, 219)',
        cursor: 'pointer',
      },
    },

    '.forgotLink': {
      fontWeight: '600',
      cursor: 'pointer',
      textDecoration: 'underline',
    },

    '.countdown': {
      display: 'inline-block',
    },

    '.timer': {
      maxWidth: '15ch',
      gap: '0.2rem',
      display: 'flex',
      flexDirection: 'row',
      fontWeight: 600,
    },
    '.segment': {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.1rem',
    },
    '.number': {
      minWidth: '2ch',
      textAlign: 'right',
    },
    '.unit': {
      minWidth: '1.5ch',
    },
    '.resend': {
      fontWeight: 600,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    '.passkey': {
      color: '#000',
      fill: '#000',
      ...pa3,
      ...ba,
      ...b__black_10,
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      '.passkey-help': {
        ...pa2,
      },
    },
  },
  input: {
    color: '#000',
  },
};

export default styles;
