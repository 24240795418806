import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

export const useRehydrated = () => {
  const [isRehydrated, setIsRehydrated] = useState(false);
  const store = useStore();

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const storeState = store.getState();
      if (storeState._persist?.rehydrated) {
        console.log('rehydrated');
        setIsRehydrated(true);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [store]);

  return isRehydrated;
};