import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';

// Selectors
export const selectAppState = (state) => state[SLICE];

export const selectActiveListItem = createSelector(
	selectAppState,
	(state) => state?.active_list_item,
);

export const selectPageLoading = createSelector(
	selectAppState,
	(state) => state?.page_loading,
);

export const selectLocalLoading = createSelector(
	selectAppState,
	(state) => state?.local_loading,
);

export const selectMessages = createSelector(
	selectAppState,
	(state) => state?.messages,
);

export const isOnline = createSelector(
	selectAppState,
	(state) => state?.online,
);

export const selectPageTitle = createSelector(
	selectAppState,
	(state) => state?.page?.title,
);
