
import { useCallback, useMemo, useState } from 'react';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ProductionDesigner from '@pw/components/ProductionDesigner';
import DesignerProvider from '@pw/providers/DesignerProvider';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useProductionDesignerQuery from '@pw/utilities/hooks/logic/useNavigateProductionDesigner';

export default withAppLayout(() => {
	const navigate = useNavigate();

  const [params] = useSearchParams();
	const sku_id = params.get('skuId');
	const batch_id = params.get('batchId');

	const mode = useMemo(() => batch_id ? 'schedule' : 'design', [batch_id]);
	// const mode = batch_id ? 'schedule' :'design';
	console.log('mode', {mode, sku_id, batch_id});

	const link = batch_id
		? useProductionDesignerQuery(sku_id)
		: useProductionDesignerQuery(sku_id, 'label');
	console.log('production-designer-page', { link });

	return (
		<>
			<FilledButton
				size="small"
				sx={{
					position: 'absolute',
					zIndex: 3,
					right: '10rem',
					top: '1rem'
				}}
				handleClick={ () => navigate(link) }
			>
				{`=>${mode}`}
			</FilledButton>
			<DesignerProvider mode={mode} skuId={sku_id} batchId={batch_id}>
				<ProductionDesigner />
			</DesignerProvider>
		</>
	);

}, { title: 'Production Design' });
