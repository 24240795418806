const styles = {
  '.modal-content': {
    width: '100%',
    maxWidth: '32rem',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    // margin: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -20%)',
    padding: '1.5rem',
  },
  '.header': {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '2rem',
    columnGap: '1rem',
  },

  '.icon-container': {
    fontSize: '3rem',
  },
  svg: {
    display: 'block',
    color: 'inherit',
    fill: 'currentColor'
  },
  '.icon-process': { color: '#00A89D' },
  '.icon-observation': { color: '#C753A8' },

  '.title': {
    lineHeight: '1.25',
    textTransform: 'uppercase',
    color: '#393C3C',
  },
  '.form-root': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
  '.input-root': {
    position: 'relative',
    // width: '100%',
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'stretch',
    '> *': { width: '100%', }
  },
  label: {
    // position: 'absolute',
    // zIndex: 2,
    // top: '4px',
    // left: '0.75rem',
    // fontSize: `${14 / 16}rem`
  },

  '.section': {
    padding: '0.5rem',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    '&:first-of-type': {
      marginTop: 0,
    },

    // '.section-title': {
    //   position: 'relative',
    //   marginTop: '-2ch',
    //   backgroundColor: '#fff',
    //   color: '#7c6c4f',
    //   maxWidth: 'max-content',
    //   paddingLeft: '1ch',
    //   paddingRight: '1ch',
    // },

    '.section-title': {
      position: 'relative',
      marginTop: '-1.75ch',
      backgroundColor: '#fff',
      maxWidth: 'max-content',
      paddingLeft: '1ch',
      paddingRight: '1ch',
    },

    '.section-content': {
      '&:first-of-type': { marginTop: '-0.5rem' }
    },
  },
  '.MuiInputBase-input': {
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '4px',
    lineHeight: 1,
    backgroundColor: '#F0F0F0',
  },


  'table, tbody': {
    width: '100%',
    maxWidth: '100%',
  },
  table: {
    overflowX: 'auto',
    textAlign: 'left',
    borderCollapse: 'collapse',
  },
  tbody: {
    display: 'flex',
    flexDirection: 'column'
  },
  'tr': {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100%',
    flexGrow: 1
  },
  'th, td td': {
    borderWidth: 0,
    padding: '0.25rem 0.75rem',
    flexGrow: 1,
  },
  'th, td': {
    lineHeight: 1.2
  },
  th: {
    fontWeight: 500,
  },
  'td td': { textAlign: 'right', },
  'tbody > tr': {
    display: 'flex',
    flexFlow: 'row nowrap',
    minWidth: '100%',
    justifyItems: 'stretch',
    justifyContent: 'stretch',
    py: '0.5rem',
    transition: 'background-color 0.3s',

    '&.even': { backgroundColor: '#f8f8f8' },
    '&:hover': { backgroundColor: '#eee' },

    '> *': { flexGrow: 1, },

    '> th': { alignSelf: 'flex-start' },

    '> td': {
      width: '100%',
      display: 'flex',
      flexFlow: 'row wrap',
      flexGrow: 1,
      justifyContent: 'stretch',

      '> tr': { width: '100%' },
    },
  },



  '.buttonGroup-root': {
    display: 'flex',
    flexDirection: 'row',

    button: {
      color: '#666',
      border: '0.5px solid',

      '&.active': {
        // borderWidth: '2px',
        backgroundColor: '#6662',
      },
    },
  },
  '.save-button': {
    width: 'fit-content',
    alignSelf: 'flex-end',
  },


}
export default styles;
