export const Status = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CREATED: 'created',
};
export const UK_COUNTRY_CODES = Object.keys({
  'GB-ENG': 'England',
  'GB-NIR': 'Northern Ireland',
  'GB-SCT': 'Scotland',
  'GB-WLS': 'Wales',
  GB: 'United Kingdom',
});
