import Box from '@mui/material/Box';
import styles from './styles';

function SmallDialogCard({ children }) {
	return (
		<Box sx={styles} className='small-dialog-card'>
			{children}
		</Box>
	);
}

const withSmallDialogCard = (WrappedComponent) =>
	function WithDialogCard(props) {
		return (
			<SmallDialogCard>
				<WrappedComponent {...props} />
			</SmallDialogCard>
		);
	};

export default withSmallDialogCard;
