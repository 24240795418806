import * as yup from 'yup';

export default function section1ReportFields(fields = {}) {
	const { a, b, c, d } = fields;

	return {
		a: [a, yup.number().min(0, 'Transaction value cannot be less than 0')],
		b: [
			b,
			yup
				.number()
				.min(0, 'COMPLETELY DENATURED ALCOHOL value cannot be less than 0'),
		],
		c: [
			c,
			yup
				.number()
				.min(0, 'SPECIALLY DENATURED ALCOHOL value cannot be less than 0'),
		],
		d: [
			d,
			yup
				.number()
				.min(0, 'SPECIALLY DENATURED RUM value cannot be less than 0'),
		],
	};
}
