import LHS from './LHS';
import { useContext } from "react";
import DesignerContext from "../../../context/DesignerContext";
import LHSBase from './LHS_Base';

const ToolBox = ({
	operations,
	components,
	ingredients,
	observations,
	showGlobalMenu,
	setShowGlobalMenu,
}) => {
	const { mode, setNodes } = useContext(DesignerContext);

  if (mode === 'design') {
		return <LHS
			className={`left LHS ${showGlobalMenu ? 'with-global-menu' : ''}`}
			{...{
				components,
				ingredients,
				observations,
				operations,
				setNodes,
				showGlobalMenu,
				setShowGlobalMenu,
			}}
		/>;
  }

	if (mode === 'schedule') {
		return <LHSBase
					titleText='Production Schedule'
					className={`left LHS-Schedule ${showGlobalMenu ? 'with-global-menu' : ''}`}
					{...{
						showGlobalMenu,
						setShowGlobalMenu,
					}}
				/>;
	}
	return <></>;
			/* <RHS
        className="right"
        {...{
          itemName, setItemName,
          saveToName, setSaveToName,
          components, ingredients, observations,
          setNodes,
          isBatch,
          batches,
          onLoad,
        }} /> */
};

export default ToolBox;
