import { UnauthorizedError } from "@pw/utilities/checkResponseStatus";

const handleError = (error, rejectWithValue) => {
  console.log('Error', error);
  if (error instanceof UnauthorizedError) {
    // Dynamically load the store and purge the state
    import('@pw/redux/store').then(({ persistor }) => {
      persistor.purge();
    });
  }
  return rejectWithValue(error);
};

export default handleError;