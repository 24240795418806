import { checkResponseStatus } from './checkResponseStatus';
import requestHeaders from './requestHeaders';

const fetchPut = async (url, headers = {}, body = {}) => {
	const response = await fetch(url, {
		method: 'PUT',
		headers: requestHeaders(headers),
		body: JSON.stringify(body),
	});
	checkResponseStatus(response.status);
	const jsonResponse = await response.json();
	if (!response.ok) {
		throw jsonResponse;
	}
	return jsonResponse;
};

export default fetchPut;
