import { useCallback, useRef } from 'react';

const useScrollTarget = () => {
	const ref = useRef(null);
	const scroll = useCallback(() => {
		ref?.current?.scrollIntoView({
			block: 'start',
			inline: 'nearest',
			behavior: 'smooth',
		});
	}, [ref]);

	return [ref, scroll];
};

export default useScrollTarget;
