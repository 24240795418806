/**
 * used to format company address
 * @param {
 * "country": "GB",
 * "city": "The states",
 * "address_1": "The States 2",
 * "address_2": "The States 1",
 * "post_code": "TH3 5T5",
 * "address_3": "The States",
 * "state": ""
 * } address address object
 * @returns string
 */
function formatCompanyAddress(address) {
	return [
		address?.address_1,
		address?.address_2,
		address?.address_3,
		address?.city,
		address?.state,
		address?.post_code,
		address?.country,
	]
		.filter(Boolean)
		.join(', ');
}

export default formatCompanyAddress;
