import { UNIT } from "@pw/consts/units";

function calcAlcohol(level, converter) {
	let { bl, la, abv, tcf = '1.000' } = level ?? {};

	console.log('calcAlcohol', level);

	abv = converter.to(abv, UNIT.ALC);
	la = converter.to(la, UNIT.LA);
	bl = converter.to(bl, UNIT.BL);

	// console.log('calcAlcohol', abv, la, bl, tcf);

	// if (abv && tcf && (bl || la)) {
	// 	const factor = (abv * Number(tcf)) / 100.0;
	// 	if (la && !bl) {
	// 		bl = (la / factor).toFixed(3);
	// 	} else {
	// 		la = (bl * factor).toFixed(3);
	// 	}
	// }

	console.log(' --> calcAlcohol', bl, la, abv, tcf);

	return {
		bl,
		la,
		abv,
		tcf
	};
}

function convertProperties(properties, converter) {
	return {
		...properties,
		...(properties?.liquid
			? {
				liquid: {
					...properties?.liquid,
					...(properties?.liquid?.level
						? {
							level: {
								...(properties?.liquid?.level ?? {}),
								...calcAlcohol(properties?.liquid?.level, converter),
							},
						}
						: {}),
					...(properties?.liquid?.weight
						? {
							weight: {
								...(properties?.liquid?.weight ?? {}),
							},
						}
						: {}),
				},
			}
			: {}),
	};
}

export default convertProperties;
