import { CLIENT_TYPE } from '@pw/consts/contacts';
import * as yup from 'yup';

export function clientFields(client = {}) {
	const {
		enable = false,
		name = '',
		public_name = '',
		id = '',
		hash = '',
		type = CLIENT_TYPE.ACCOUNT,
	} = client;

	return {
		enable: [enable, yup.bool()],
		name: [
			name,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Name is required!'),
				otherwise: (schema) => schema,
			}),
		],
		public_name: [
			public_name,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Public Name is required!'),
				otherwise: (schema) => schema,
			}),
		],
		id: [
			id,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('id is required!'),
				otherwise: (schema) => schema,
			}),
		],
		hash: [hash, yup.string()],
		type: [
			type,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('type is required!'),
				otherwise: (schema) => schema,
			}),
		],
	};
}
