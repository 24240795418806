import Stack from '@mui/material/Stack';
import { LineChart, PieChart } from '@mui/x-charts';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';

function HeadlineWidgetChart({ title, data, lineChartData }) {
	return (
		<Stack className='content-section'>
			<H5 className='content-section-title'>{title}</H5>
			<Stack className='headline-widget-content'>
				<FlexBox>
					<PieChart
						series={[
							{
								data,
								innerRadius: 55,
								outerRadius: 95,
								paddingAngle: 5,
								cornerRadius: 5,
								startAngle: -225,
								endAngle: 45,
								cx: 90,
								cy: 100,
								highlightScope: { faded: 'global', highlighted: 'item' },
								faded: {
									innerRadius: 30,
									additionalRadius: -30,
									color: 'gray',
								},
							},
						]}
						width={250}
						height={220}
					/>
				</FlexBox>
				{lineChartData && (
					<FlexBox>
						<LineChart
							series={[
								{
									curve: 'natural',
									data: lineChartData.bl,
									color: '#1E1C1C',
									area: true,
									showMark: false,
								},
								{
									curve: 'natural',
									data: lineChartData.la,
									color: '#F0AA29',
									area: true,
									showMark: false,
								},
							]}
							height={220}
							xAxis={[
								{
									id: 'Dates',
									data: lineChartData.dates,
									scaleType: 'point',
									valueFormatter: (date) => date.toLocaleDateString(),
								},
							]}
							disableAxisListener
						/>
					</FlexBox>
				)}
			</Stack>
		</Stack>
	);
}

export default HeadlineWidgetChart;
