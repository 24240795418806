import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchDelete from '@pw/utilities/fetchDelete';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import fetchPut from '@pw/utilities/fetchPut';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const PUB_BASE = `${APP_API_GATEWAY}/accounts-pub`;
const PRIV_BASE = `${APP_API_GATEWAY}/accounts`;

// CRUD
export const getCompany = async (params = {}, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company${objectToQueryString(params)}`, headers);
export const createCompany = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company`, headers, request);
export const updateCompany = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/company`, headers, request);

// USERS
export const listUsers = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company/users${objectToQueryString(params)}`, headers);
export const inviteUser = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/user`, headers, request);
export const updateUser = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/user`, headers, request);
export const removeUser = async (params, headers = {}) =>
	fetchDelete(`${PRIV_BASE}/user${objectToQueryString(params)}`, headers);

// ROLES
export const listRoles = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company/roles${objectToQueryString(params)}`, headers);
export const createRole = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/roles`, headers, request);
export const updateRole = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/company/roles`, headers, request);

// TEAMS
export const listTeams = async (params, headers = {}) =>
	fetchGet(`${PRIV_BASE}/company/teams${objectToQueryString(params)}`, headers);
export const createTeam = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/teams`, headers, request);
export const updateTeam = async (request, headers = {}) =>
	fetchPut(`${PRIV_BASE}/company/teams`, headers, request);

// PERMISSIONS
export const listPermissions = async () => fetchGet(`${PUB_BASE}/perm`);

// CUSTOMERS
export const listOwners = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers`, headers, request);
export const createOwner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/create`, headers, request);
export const updateOwner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/update`, headers, request);
export const inviteOwner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/invite`, headers, request);
export const resetOwnerPassword = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/reset`, headers, request);
export const uploadOwners = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers/upload`, headers, request);

// PARTNERS
export const listPartners = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners`, headers, request);
export const searchPartners = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/search`, headers, request);
export const createPartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/create`, headers, request);
export const updatePartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/update`, headers, request);
export const shareWithPartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/share`, headers, request);
export const connectWithPartner = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/partners/connect`, headers, request);


// SEARCH CUSTOMERS AND PARTNERS
export const searchNetwork = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers-partners/search`, headers, request);

export const ownedAssets = async (request, headers = {}) =>
	fetchPost(`${PRIV_BASE}/company/customers-partners/assets`, headers, request);
