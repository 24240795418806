import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import {
  FormikForm,
  FormikSelect,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { Status } from '@pw/consts/admin';
import { useCompanyPartners } from '@pw/redux/containers/User';
import { shareWithPartnerThunk } from '@pw/redux/thunks/partners';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

export function InvitePartnerDialog({ open, onClose, partnerId }) {
  const dispatch = useDispatch();
  const partners = useCompanyPartners();

  const partnerOptions = useMemo(() => {
    return (partners ?? [])
      .filter(({ status }) => {
        return status === Status.CREATED;
      })
      .map(({ partner_id, company_name }) => ({
        value: partner_id,
        label: company_name,
      }));
  }, [partners]);

  const changeSet = useMemo(
    () => ({
      name: ['', yup.string().required('Full name required!')],
      email: [
        '',
        yup.string().email('Invalid email!').required('Email required!'),
      ],
      partner_id: [
        partnerId ?? '',
        yup.string().required('Setup and select partner!'),
      ],
    }),
    [],
  );
  const handleOnClose = useCallback(() => onClose(), [onClose]);

  const handleSubmit = useCallback((values) => {
    dispatch(shareWithPartnerThunk(values))
      .unwrap()
      .then(() => handleOnClose());
  }, []);

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth='xs' scroll='paper'>
      <IconButton
        aria-label='close'
        onClick={handleOnClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <DialogTitle>Connect with partner</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Instructions>
              Exchange your company info with a partner.
            </Instructions>
            <FormikTextField
              name='name'
              label='Full name'
              autoComplete='name'
              required
            />
            <FormikTextField
              name='email'
              label='Contact email'
              autoComplete='email'
              required
            />
            <FormikSelect
              name='partner_id'
              label='Existing partner'
              required
              disabled={partnerId}
              options={partnerOptions}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <TextButton
            size='small'
            handleClick={() => handleOnClose()}
            color='secondary'
          >
            Cancel
          </TextButton>
          <FilledButton type='submit' size='small'>
            Invite
          </FilledButton>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}

InvitePartnerDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  partnerId: PropTypes.string,
};
