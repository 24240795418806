import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Overline } from '@pw/components/Typography';
import formatNumber from '@pw/utilities/formatNumber';

function NumberWidget({ value, unit = null, label }) {
	return (
		<Stack spacing={1} className='widget stats'>
			<FlexBox className='widget-content stats-content'>
				<Body1>{label}</Body1>
				<FlexBox style={{ width: 'auto' }}>
					<Box className='widget-text'>
						<Body1 className='widget-text-primary'>{formatNumber(value)}</Body1>
						{unit && <Overline>{unit}</Overline>}
					</Box>
				</FlexBox>
			</FlexBox>
		</Stack>
	);
}

export default NumberWidget;
