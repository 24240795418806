import Stack from '@mui/material/Stack';
import SKUForm from '@pw/components/admin/SKUForm';
import skuSettingsFields from '@pw/components/admin/SKUs/skuSettingsFields';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5 } from '@pw/components/Typography';
import { SKU_TYPES } from '@pw/consts/sku';
import { UNITS_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext, useMemo } from 'react';
import * as yup from 'yup';
import categories from '@pw/consts/materialCategories';
import kinds from '@pw/consts/expiringMaterials';

function propertyFields(fields = {}) {
  const { unit, fermentable, category } = fields;
  return {
    category: [category, yup.string()],
    fermentable: [fermentable, yup.string()],
    unit: [unit, yup.string().required('Unit is required!')],
  };
}

function Kind() {
  const { values } = useContext(FormikContext);
  const category = values?.category;

  return (
    <>
      <FormikSelect label="Category" name="category" options={categories} />
      {category === 'fermentable' && <FormikSelect label="Fermentable" name="fermentable" options={kinds} />}
    </>
  );
}

function Properties() {
  return (
    <FormikProvider path="properties">
      <Stack spacing={3}>
        <H5>Properties</H5>
        <Kind />
        <FormikSelect label="Unit" name="unit" options={UNITS_OPTIONS} />
      </Stack>
    </FormikProvider>
  );
}

function SKUPage(props) {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...skuSettingsFields(initialValues, SKU_TYPES.EXPIRING),
      properties: propertyFields(initialValues.properties),
    }),
    [],
  );

  const handleBeforeSave = (entity) => entity;

  return (
    <SKUForm
      skuLabel="Expiring Material"
      skuType={SKU_TYPES.EXPIRING}
      changeSetGenerator={changeSetGenerator}
      onBeforeSave={handleBeforeSave}
      {...props}
    >
      <Properties />
    </SKUForm>
  );
}

export default withAppLayout(SKUPage, { title: 'Expiring Material SKUs' });
