import { Divider } from '@mui/material';
import { FormikDatePicker } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ReportAdjustment from './ReportAdjustment';
import ReportQuantity from './ReportQuantity';

export default function MonthlyReport() {
	return (
		<>
			<Divider />
			<FlexBox spacing={2} alignItems='top'>
				<FormikDatePicker
					name='start_date'
					label='Period Start Date'
					fullWidth
					required
				/>
				<FormikDatePicker
					name='end_date'
					label='Period End Date'
					fullWidth
					required
				/>
			</FlexBox>
			<Divider />
			<ReportQuantity name='opening_stock' title='Opening Stock' />
			<Divider />
			<ReportQuantity name='closing_stock' title='Closing Stock' />
			<Divider />
			<ReportAdjustment name='adjustment' title='Adjustment' />
			<Divider />
			<ReportQuantity
				name='receipts_from_importation'
				title='Receipts From Importation'
			/>
			<Divider />
			<ReportQuantity
				name='receipts_from_uk_and_other_warehouses'
				title='Receipts from UK and Other Warehouses'
			/>
			<Divider />
			<ReportQuantity name='gains_in_storage' title='Gains In Storage' />
			<Divider />
			<ReportQuantity
				name='increases_from_operations'
				title='Increases From Operations'
			/>
			<Divider />
			<ReportQuantity
				name='removals_to_home_use'
				title='Removals to Home Use'
			/>
			<Divider />
			<ReportQuantity
				name='removals_to_exportation'
				title='Removals to Exportation'
			/>
			<Divider />
			<ReportQuantity
				name='removals_to_other_uk_warehouses'
				title='Removals to other UK Warehouses'
			/>
			<Divider />
			<ReportQuantity
				name='removals_to_other_duty_free_uses'
				title='Removals to other Duty Free Uses'
			/>
			<Divider />
			<ReportQuantity name='losses_in_storage' title='Losses in Storage' />
			<Divider />
			<ReportQuantity
				name='losses_from_operations'
				title='Losses from Operations'
			/>
		</>
	);
}
