import { useMemo } from 'react';
import useOrigin from '@pw/utilities/hooks/logic/useOrigin';

// 'https://test.metacask.com:4430/app/explorer?dt=1,2,3,4';
export const encodeLocationQrCode = (origin = '', key = 'dt', ...deps) =>
	`${origin}/app/explorer?${key}=${encodeURIComponent(deps.join(','))}`;

const useEncodeLocationQrCode = (key = 'dt', ...deps) => {
	const origin = useOrigin();
	return useMemo(() => encodeLocationQrCode(origin, key, ...deps), [deps, key, origin]);
};

export default useEncodeLocationQrCode;
