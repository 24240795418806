import { CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckCircle } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SmallAvatar from '@pw/components/Avatar/SmallAvatar';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetTag from '@pw/components/Tasks/AssetTag';
import { Body2 } from '@pw/components/Typography';
import { requestAssignThunk } from '@pw/redux/thunks/request';
import { mr1 } from '@pw/styles/margins';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

function User({ name, username, selected, selectUser }) {
	return (
		<FlexBox className='user-entry' justifyContent='start' spacing='0.1rem'>
			{selected ? (
				<CheckBoxRounded
					className='check'
					sx={{ ...mr1 }}
					onClick={() => selectUser(false)}
				/>
			) : (
				<CheckBoxOutlineBlankRounded
					className='check'
					sx={{ ...mr1 }}
					onClick={() => selectUser(true)}
				/>
			)}
			<FlexBox sx={{ flexGrow: 1 }} justifyContent='flex-start'>
				<SmallAvatar linked={false} />
				<Body2 sx={{ flexGrow: 1 }}>{name ??username}</Body2>
			</FlexBox>
		</FlexBox>
	);
}

function Assignees({ tasks, users, setAssignees }) {
	const existingAssignees = useMemo(
		() => [...new Set(tasks.flatMap((t) => t.assignees ?? []))],
		[tasks],
	);
	const [assigneeIds, setAssigneeIds] = useState(existingAssignees);

	const isSelected = useCallback(
		(uid) => !!(assigneeIds ?? []).find(([eid]) => uid === eid),
		[assigneeIds],
	);

	const selectUser = ([uid, un], state) =>
		setAssigneeIds((v) =>
			state ? [...v, [uid, un]] : v.filter(([i]) => i !== uid),
		);

	useEffect(() => setAssignees(assigneeIds), [assigneeIds]);

	return (
		<Stack className='assignees' spacing={1}>
			<Box className='label'>Assignees</Box>
			<Box className='user-list'>
				<Stack spacing='0.2rem'>
					{users.map((u) => (
						<User
							key={u.account_id}
							name={u.account_name}
							username={u.account_username}
							selected={isSelected(u.account_id)}
							selectUser={(state) =>
								selectUser([u.account_id, u.account_name], state)
							}
						/>
					))}
				</Stack>
			</Box>
		</Stack>
	);
}

function AssignModal({ tasks, users, onClose }) {
	const dispatch = useDispatch();

	const [assignees, setAssignees] = useState([]);

	const assignTasks = useCallback(() => {
		if (!assignees || assignees.length === 0) return;

		const requests = tasks.map((t) => ({ path: t.path }));

		dispatch(requestAssignThunk({ requests, assignees }))
			.unwrap()
			.then(() => onClose(true, true));
	}, [tasks, assignees]);

	return (
		<ModalWithClose title='Assign Tasks' open onClose={onClose}>
			<FlexBox className='asset-container' justifyContent='start' spacing={1}>
				{tasks.map((task) => (
					<AssetTag
						key={task.id}
						id={task.id}
						action={task.action}
						type={task.type}
					/>
				))}
			</FlexBox>
			<Assignees
				users={users}
				tasks={tasks}
				setAssignees={setAssignees}
			/>
			<FlexBox justifyContent='end'>
				<TextButton
					size='small'
					handleClick={() => onClose(false, true)}
					color='secondary'
					label='Cancel'
				/>
				<FilledButton
					size='small'
					icon={<CheckCircle />}
					iconPosition='start'
					handleClick={assignTasks}
				>
					Confirm
				</FilledButton>
			</FlexBox>
		</ModalWithClose>
	);
}

export default AssignModal;
