import { ASSET_TYPES } from '@pw/consts/asset';
import { DEFAULT_CURRENCY } from '@pw/consts/config';
import { REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import { TASK_TABLE_STATUS, TASK_TYPES } from '@pw/consts/tasks';
import dayjs from 'dayjs';

export function toDate({ date = Date.now(), format = 'DD/MM/YYYY' }) {
	return dayjs(date).format(format);
}

export function toUniqueLocationId({ facility_id = '', row = '', level = '' }) {
	if (facility_id && row && level) {
		return `${facility_id}/${row}/${level}`;
	}
	if (facility_id && row) {
		return `${facility_id}/${row}`;
	}
	if (facility_id) {
		return `${facility_id}`;
	}
	return '';
}

export function toTasksCardEntity(values, type, isAsset = false) {
	const id = values.rw_request_id ?? values.rw_asset_id ?? values.path;
	const date =
		values?.request_created ??
		values?.asset_nonce ??
		values.asset_checkin_date ??
		new Date().getTime();
	const dueDate = values?.request_due ?? date;
	const status = values?.request_status ?? TASK_TABLE_STATUS.AWAITING_APPROVAL;
	const action = TASK_TYPES[type];
	const assignees = values.assignees ?? [];
	const client = values.client ?? null;
	const sources = values.source_properties ?? null;
	const destinations = values.destination_propertiesnull;
	const location = values.location ?? null;
	const shipAddress = values.properties?.destination;
	let parent_asset_id = null;
	if (type === REQUEST_TYPES_REVERSE[values?.request_type]) {
		parent_asset_id = values?.destinations?.find(
			(asset) => asset?.asset_type === ASSET_TYPES.pallet,
		)?.rw_asset_id;
	}

	return {
		id,
		type,
		isAsset,
		date: new Date(date).toLocaleDateString(),
		dueDate: new Date(dueDate).toLocaleDateString(),
		status,
		action,
		assignees,
		path: values.asset_id ?? values.path,
		client,
		sources,
		destinations,
		location,
		shipAddress,
		parent_asset_id,
	};
}

export function fromReportId(path) {
	const [report_type = '', nonce = ''] = path?.split('/') ?? [];
	return {
		report_type,
		nonce: Number(nonce),
	};
}

export const toPrice = ({ rawPrice, currencyCode = DEFAULT_CURRENCY }) => {
	const price = rawPrice * 0.01;

	if (Number.isNaN(price)) return '--';

	return new Intl.NumberFormat('en', {
		style: 'currency',
		currency: currencyCode,
	}).format(price);
};

export const toSKURequest = (converter, {
	size = {},
	net_weight = {},
	gross_weight = {},
	capacity = {},
	abv = {},
	...rest
}) => {
	const converted = {
		size: { ...size },
		net_weight: { ...net_weight },
		gross_weight: { ...gross_weight },
		capacity: { ...capacity },
		abv: { ...abv },
	};
	if (size?.value) {
		converted.size.value = converter.cx(size?.value, size?.unit);
	}
	if (net_weight?.value) {
		converted.net_weight.value = converter.cx(net_weight?.value, net_weight?.unit);
	}
	if (gross_weight?.value) {
		converted.gross_weight.value = converter.cx(gross_weight?.value, gross_weight?.unit);
	}
	if (capacity?.value) {
		converted.capacity.value = converter.cx(capacity?.value, capacity?.unit);
	}
	if (abv?.value) {
		converted.abv.value = converter.cx(abv?.value, abv?.unit);
	}
	return {
		...rest,
		...converted,
	};
};
