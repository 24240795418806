export const styles = {
	'&.root': {
		position: 'relative',
		borderRadius: '5px',
	},
	'.scanIcon': {
		fontSize: '1rem',
		width: '1.75em',
		height: '1.75em',
		padding: '0.125em',
	},
	'.MuiDialog-paper': {
		borderRadius: '2rem',
	},
	'.scanner': {
		position: 'relative',
		margin: 0,
		width: '100%',
		maxWidth: '600px',
		background: '#fff',
	},
	'.photos-container': {
		display: 'none',
		paddingBottom: '0.5rem',
	},
	'.photos': {
		display: 'flex',
		margin: '1rem',
		maxWidth: '600px',
		background: '#fff',
		gap: '10px',
	},
	'.has-photo': {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	'.photo-item': {
		position: 'relative',
	},
	'.photo-style': {
		borderRadius: '5px',
		width: '100px',
		height: '100px',
	},
	'.photo-limit-warning': {
		display: 'none',
		position: 'absolute',
		color: 'red',
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		bottom: '5rem',
		right: '30%',
		top: 'auto',
		zIndex: '9',
		'&.show': {
			display: 'block',
		},
	},
	'.delete': {
		color: 'red',
		position: 'absolute',
		right: 0,
		top: 0,
	},
	'.modalButton': {
		position: 'absolute',
		zIndex: 1,
		top: '1rem',
		padding: '1rem',
		borderRadius: '9999px',
		background: 'rgba(255,255,255, 0.2)',
		backdropFilter: 'blur(10px)',
	},
	'.dialogClose': {
		left: '1rem',
	},
	'.zoomContainer': {
		right: '1rem',
		top: '40%',
		position: 'absolute',
		zIndex: 1,
		background: 'rgba(255,255,255, 0.2)',
		display: 'flex',
		flexDirection: 'column',
	},
	'.zoomIn': {
		position: 'relative',
	},
	'.zoomOut': {
		position: 'relative',
	},
	'.capture': {
		bottom: '1rem',
		right: '50%',
		top: 'auto',
	},
	'.toggleFacingMode': {
		right: '1rem',
	},
	containerStyle: {},
	videoContainerStyle: {},
	videoStyle: {
		objectFit: 'cover',
	},
	container: {
		width: '100%',
		paddingTop: '100%',
		overflow: 'hidden',
		position: 'relative',
	},
	video: {
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		overflow: 'hidden',
		position: 'absolute',
		transform: undefined,
	},
};
