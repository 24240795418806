function SvgDeFoamer(props) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 28 41'
			width='0.6829268293em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path d="M19.3418 20.4697C24.1631 20.4718 28.0048 24.3138 27.9986 29.124C27.9924 33.8849 24.1196 37.6817 19.3004 37.7023C14.5143 37.7228 10.5856 33.7307 10.6332 29.0418C10.6829 24.283 14.5205 20.4677 19.3418 20.4697ZM26.609 29.087C26.5261 25.0436 23.4072 21.8737 19.3253 21.8676C15.2557 21.8635 12.054 25.0312 12.0519 29.0788C12.0519 33.1202 15.2371 36.2962 19.317 36.2983C23.3948 36.2983 26.5158 33.1449 26.609 29.087Z" fill="currentColor" />
			<path d="M12.7124 13.1915C16.0509 13.2121 18.826 15.611 19.3189 18.9165C19.3935 19.4181 19.1657 19.7922 18.7432 19.8559C18.2938 19.9238 17.9852 19.6545 17.8962 19.1036C17.5814 17.1713 16.5334 15.7755 14.7213 15.019C11.1716 13.541 7.54113 16.1743 7.43551 19.562C7.36717 21.7512 8.30533 23.3732 10.2086 24.4565C10.5337 24.6415 10.774 24.8615 10.716 25.2685C10.6725 25.5748 10.4799 25.7536 10.1879 25.8399C9.97042 25.9057 9.77161 25.8358 9.58936 25.7351C7.36717 24.514 6.13699 22.6557 5.99616 20.1273C5.80977 16.7766 8.29291 13.7363 11.7618 13.2511C12.0766 13.208 12.3976 13.21 12.7145 13.1895L12.7124 13.1915Z" fill="currentColor" />
			<path d="M6.0375 31.6982C8.64904 31.7024 10.7055 33.7683 10.7014 36.381C10.6952 38.9691 8.60969 41.0083 5.9733 41.0001C3.37626 40.9919 1.32182 38.9115 1.3301 36.2967C1.33839 33.7354 3.42803 31.6962 6.0375 31.6982ZM6.00022 39.6146C7.76058 39.6392 9.28277 38.1448 9.30555 36.3666C9.3304 34.6193 7.82478 33.1105 6.03336 33.0838C4.273 33.0591 2.75288 34.5535 2.72596 36.3317C2.70111 38.0769 4.2088 39.5899 5.99815 39.6146H6.00022Z" fill="currentColor" />
			<path d="M20.6803 7.32881C20.6741 9.53864 18.893 11.2859 16.646 11.2839C14.3948 11.2818 12.6344 9.51191 12.6406 7.25686C12.6469 5.03059 14.4383 3.29356 16.7185 3.30179C18.9386 3.31207 20.6865 5.08815 20.6824 7.32676L20.6803 7.32881ZM16.6708 4.6873C15.2543 4.67496 14.0365 5.87752 14.0365 7.29181C14.0365 8.68965 15.2004 9.85726 16.6294 9.89632C18.0315 9.93332 19.2596 8.73899 19.2844 7.31237C19.3093 5.91247 18.1019 4.69963 16.6708 4.6873Z" fill="currentColor" />
			<path d="M11.3647 2.66207C11.3647 4.16475 10.1988 5.33442 8.69314 5.33853C7.16059 5.34264 5.98426 4.17709 5.98633 2.6559C5.99047 1.16144 7.1668 0 8.67864 0C10.1905 0 11.3627 1.1635 11.3647 2.66207ZM9.96888 2.67235C9.97302 2.02071 9.36621 1.40401 8.70142 1.38551C8.03663 1.36495 7.38633 1.99604 7.38426 2.66618C7.38219 3.31782 7.98899 3.93452 8.65379 3.95302C9.32065 3.97358 9.96888 3.34455 9.97095 2.67235H9.96888Z" fill="currentColor" />
			<path d="M24.0078 13.89C24.0078 15.0268 23.1297 15.9025 21.9886 15.9045C20.8433 15.9045 19.9611 15.0329 19.959 13.9003C19.959 12.7635 20.835 11.8878 21.9782 11.8857C23.1235 11.8857 24.0057 12.7553 24.0078 13.89ZM22.5995 13.9085C22.6078 13.5981 22.3116 13.2939 21.9969 13.2856C21.6841 13.2774 21.3776 13.5714 21.3693 13.8838C21.3611 14.1942 21.6572 14.4985 21.972 14.5067C22.2847 14.5149 22.5912 14.221 22.5995 13.9085Z" fill="currentColor" />
			<path d="M4.04882 25.1259C4.04882 26.2585 3.1645 27.1322 2.01923 27.1301C0.876036 27.1301 0 26.2524 0 25.1156C0 23.9829 0.88432 23.1113 2.02959 23.1113C3.17278 23.1113 4.05089 23.9891 4.04882 25.1259ZM2.01923 25.7323C2.33195 25.7343 2.63639 25.4383 2.63846 25.1259C2.64053 24.8155 2.34231 24.5133 2.02751 24.5112C1.71479 24.5092 1.41036 24.8052 1.40828 25.1176C1.40621 25.428 1.70444 25.7302 2.01923 25.7323Z" fill="currentColor" />
			<path d="M24.6683 28.5652C24.6786 29.3751 24.5316 29.6506 24.1733 29.7472C23.726 29.8705 23.3304 29.593 23.2869 29.0853C23.2144 28.2342 23.0115 27.4304 22.4772 26.7398C21.7937 25.8558 20.9011 25.3337 19.7786 25.2103C19.6275 25.1939 19.4763 25.1713 19.3251 25.1507C18.8695 25.0932 18.6189 24.8342 18.6313 24.4354C18.6437 24.0242 18.9316 23.7734 19.4162 23.7734C20.8991 23.7714 22.1354 24.347 23.1627 25.381C24.1091 26.3307 24.5958 27.486 24.6662 28.5652H24.6683Z" fill="currentColor" />
			<path d="M24.4754 2.37448L24.1384 2.16893C23.8138 1.971 23.3891 2.07166 23.1897 2.39377L0.619885 38.8513C0.420481 39.1734 0.521897 39.595 0.846407 39.7929L1.18343 39.9985C1.50794 40.1964 1.93266 40.0957 2.13206 39.7736L24.7019 3.31609C24.9013 2.99398 24.7999 2.57241 24.4754 2.37448Z" fill="currentColor" />
		</svg>
	);
}
export default SvgDeFoamer;
