import { Delete, Edit, TaskOutlined } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { useMemo } from 'react';

function Release({ item, onRemove, onEdit, selected }) {
	const liquidTypes = useLiquidTypes();
	const { id, name, date, skus = [], assets = [], liquid_type } = item ?? {};

	const liquidName = useMemo(() => {
		const liquid = liquidTypes.find((l) => l.value === liquid_type);
		return liquid?.label;
	}, [liquid_type, liquidTypes]);

	return (
		<Box className='listItem'>
			{selected && selected(item) && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack spacing={0.5}>
					<IDDisplay value={id} />
					<NameDisplay label='Release' name={name} />
					<NameDisplay label='Liquid' name={liquidName} />
					<DateDisplay label='Date' date={date} />
					<Grid container spacing={0.5}>
						{!!assets?.length && (
							<Grid item xs={6}>
								<AmountDisplay label='Assets' amount={assets?.length} />
							</Grid>
						)}
						{!!skus?.length && (
							<Grid item xs={6}>
								<AmountDisplay label='SKUs' amount={skus?.length} />
							</Grid>
						)}
					</Grid>
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
				{onRemove && (
					<IconCircleButton onClick={() => onRemove(item)}>
						<Delete />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

function ReleasesList({ items, onEdit, onRemove }) {
	return (
		<Box className='inventory-contents'>
			<Stack className='list'>
				{items.map((release) => (
					<Release
						item={release}
						onEdit={onEdit}
						onRemove={onRemove}
						key={release?.id}
					/>
				))}
			</Stack>
		</Box>
	);
}

export default ReleasesList;
